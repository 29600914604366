import React, { useMemo } from 'react'
import * as Core from 'components/player'
import { PlayerControls } from 'components/player-controls'
import { usePlayerSource } from 'nft-page/media/hooks/use-player-source'
import { useParams } from 'react-router-dom'

const DEFAULT_OPTIONS = { muted: false, controls: false, autoplay: false }

export const Player = ({ player }) => {
  const { identifier } = useParams()
  const sources = usePlayerSource()
  const handlePlayPause = PlayerControls.useToggleCallback(player)

  return useMemo(() => <div onClick={handlePlayPause} onTouchEnd={handlePlayPause}
                            className={'h-100'}>
    <Core.Player sources={[sources]} options={DEFAULT_OPTIONS} instance={player} />

    {/* eslint-disable-next-line */}
  </div>, [handlePlayPause, player, sources, identifier])
}

Player.displayName = 'Player'
