import 'nft-page/controls'
import { ControlButton } from 'nft-page/controls/button/control-button'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { usePrevPath } from 'nft-page/controls/hooks/use-prev-path'
import { useNextPath } from 'nft-page/controls/hooks/use-next-path'
import { useCantLoadMore } from 'nft-page/controls/hooks/use-cant-load-more'
import { usePreviousElementIdentifier } from 'nft-page/controls/hooks/use-previous-element-identifier'

export const Controls = () => {
  const location = useCloseLayerLocation()

  return <>
    <ControlButton type={'close'} to={location} />
    <ControlButton type={'left'} to={usePrevPath()} disabled={!usePreviousElementIdentifier()} />
    <ControlButton type={'right'} to={useNextPath()} disabled={useCantLoadMore()} />
  </>
}
