import { useStateCleanup } from 'modals/collection/asset/hooks/use-state-cleanup'
import { useFetchAccountCollections } from 'modals/account/create-nft/collections/hooks/use-fetch-account-collections'
import { useTransactionWatch } from 'modals/account/create-nft/collections/hooks/use-transaction-watch'

export const Behaviour = () => {
  useStateCleanup()
  useFetchAccountCollections()
  useTransactionWatch()

  return ''
}
