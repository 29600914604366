import { Asset } from 'common/asset'
import { faFile } from '@fortawesome/pro-light-svg-icons'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { Value } from 'nft-page/details/other-details/file-type/value'

export const FileType = () => {
  const fileType = Asset.useFileType(useNftSelector('nftPage.data.assets.edges[0].node'))

  return <KeyPair icon={<FontAwesomeIcon icon={faFile} />} title={'File type'}>
    { fileType ? <Value /> : 'N/A'}
  </KeyPair>
}
