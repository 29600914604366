import { VerifiedBadge } from 'components/verified-badge'
import 'campaign/title/index.scss'
import { useCampaign } from '../hooks/use-campaign'

export const Title = () => {
  const { campaignId } = useCampaign()

  return <h1 className={'campaign-title text-center text-wrap'}>
    {campaignId}
    {' '}
    <VerifiedBadge />
  </h1>
}
