import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { get } from 'lodash'

export const Link = ({ title, link, image }) => {
  const backgroundUrl = get(image, 'data.attributes.url')

  return <div className={'col-12 col-md-6'}>
    <a className={'d-flex justify-content-center subdomain-media-link align-items-end'}
       href={link} target={'_blank'} rel={'noreferrer'}
       style={{ backgroundImage: `url(${backgroundUrl})` }}>
      <div className={'subdomain-media-link-text'}>
        {title}
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    </a>
  </div>
}
