import { FaqsAccordion } from 'subdomain/components/faqs-accordion'
import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section'

export const Faqs = ({ data }) => {
  const title = get(data, 'title', '')
  const faqs = get(data, 'faqs', [])

  return <HomeSection title={title}>
    <FaqsAccordion faqs={faqs} />
  </HomeSection>
}
