import { useLocation } from 'react-router-dom'
import { shouldDisplayLayer } from './should-display'
import { RoutesSurface } from './routes-surface'

export const Layer = ({ layer }) => {
  const location = useLocation()
  const layerLink = location.state?.layerLink

  return shouldDisplayLayer(layer, layerLink) ? <RoutesSurface layer={layer} layerLink={layerLink} /> : ''
}
