import { combineReducers } from 'redux'

import { nft as nftSlice } from 'nft-page/slices/nft'
import { owner } from 'nft-page/owner/slices/owner'
import { auctions } from 'nft-page/details/other-listings/reducers'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import { selectedAuction } from 'nft-page/auction/slices/selected-auction'
import { orders } from 'nft-page/auction/bids/slices/orders'
import { tracking } from 'nft-page/tracking/reducers'
import { reducer as likes } from 'nft-page/likes/reducer'

const config = {
  timeout: 1000,
  key: 'nft:get',
  storage: storageSession,
}

export const nft = combineReducers({
  nftPage: persistReducer(config, nftSlice.reducer),
  nftOwner: owner.reducer,
  auctions,
  selectedAuction: selectedAuction.reducer,
  auctionOrders: orders.reducer,
  tracking,
  likes,
})
