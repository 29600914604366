import { of, from } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { get as slice } from 'modals/account/followees/slices'
import { accounts } from 'repositories/accounts'

import { getUsers as usersSlice } from 'modals/account/followees/slices/get-users'

export const get = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => accounts.social.followees(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  slice.actions.success(payload),
  slice.actions.append(payload.items),
  usersSlice.actions.attempt({ addresses: payload.items.map(item => item.address) }),
])

const failure = payload => of(slice.actions.failure(payload))

