import { useEffect } from 'react'
import { Toast } from 'bootstrap'
import { useOnHide } from 'components/toasts/transaction-toast/hooks/use-on-hide'
import { useOnShow } from 'components/toasts/transaction-toast/hooks/use-on-show'

export const useTxToast = (id, status) => {
  const onHide = useOnHide(id)
  const onShow = useOnShow(id)

  useEffect(() => {
    const element = document.getElementById(id)
    const toastInstance = Toast.getInstance(element)

    if (element) {
      if (!toastInstance) {
        const toast = new Toast(element, { autohide: status === 'success' })
        toast.show()
        onShow()
      }

      element.addEventListener('hidden.bs.toast', onHide)
    }

    return () => {
      element.removeEventListener('hidden.bs.toast', onHide)
    }
  }, [id, onHide, onShow, status])
}
