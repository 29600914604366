import { Title } from 'sidebar/connect/title'
import { Buttons } from 'sidebar/connect/buttons'
import { WalletConnectLogin } from 'sidebar/connect/walletconnect-login'
import { LedgerLogin } from 'sidebar/connect/ledger-login'
import { Context, useValue } from 'sidebar/connect/context'
import { useIsPrinceCharming } from '../../auth/mega-authorized'

export const Connect = () => {
  const isPrinceCharming = useIsPrinceCharming()

  const displayClass = isPrinceCharming ? 'd-none' : 'd-block'

  // this component shouldn't be unmounted because of extension login, so let's hide it by CSS
  return <Context.Provider value={useValue()}>
    <div className={`sidebar-connect ${displayClass}`}>
      <Title />
      <Buttons />
      <WalletConnectLogin.QrCode />
      <LedgerLogin.Ledger />
    </div>
  </Context.Provider>
}
