import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

export const useIsActive = () => {
  const { tab } = useParams()

  return useCallback((index, currentTab) => (
    tab === currentTab || (index === 0 && isEmpty(tab))), [tab])
}
