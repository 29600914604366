import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'explore-nfts/tabs/nfts/auctions/slices/get'
import { useFilters } from 'filters/hooks/use-filters'
import { toPairs } from 'lodash'

export const useForceResetState = () => {
  const dispatch = useDispatch()
  const filters = useFilters()
  const hash = JSON.stringify(toPairs(filters.search).length > 0 ? filters.search : filters.background)

  useEffect(() => {
    dispatch(actions.reset({}))
  }, [dispatch, hash])
}
