import { useFilters } from '../../filters/hooks/use-filters'
import { useGetSignedTransactions } from '@multiversx/sdk-dapp/hooks'
import { useCallback } from 'react'

/* eslint-disable max-lines-per-function, complexity */
export const useIsCanceled = () => {
  const { status, signSession, walletProviderStatus } = useFilters().search
  const { signedTransactions } = useGetSignedTransactions()

  return useCallback(() => {
    const noSignedTransaction = !signedTransactions[signSession]
    const walletNotSigned = walletProviderStatus !== 'transactionsSigned'
    const notSigned = (signSession && noSignedTransaction && walletNotSigned)

    return status === 'cancelled' || notSigned
  }, [status, signSession, signedTransactions, walletProviderStatus])
}
