import { createSlice } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'

const initialState = {
  attempt: '',
  success: {
    data: {
      auctions: {
        edges: [],
        pageInfo: { hasNextPage: true, endCursor: '' },
        pageData: '',
      },
    },
  },
  list: [],
  errors: '',
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, attempt: payload })

const success = (state, { payload }) => ({ ...state, loading: false, success: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: payload })

const append = (state, { payload }) => ({ ...state, list: uniqBy([...state.list, ...payload], 'node.identifier') })

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'collections/auctions/get',
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})

export const { actions } = get
