import { graph } from 'network'
import { FLAG_NFT } from 'repositories/admin/flag-nft'
import { FLAG_COLLECTION } from 'repositories/admin/flag-collection'
import { CLEAR_NFT_SCAM_INFO } from 'repositories/admin/clear-nft-scam-info'
import { CLEAR_NFT_REPORT } from 'repositories/admin/clear-nft-report'
import { SET_NFT_SCAM_INFO } from 'repositories/admin/set-nft-scam-info'
import { SET_COLLECTION_SCAM_INFO } from 'repositories/admin/set-collection-scam-info'
import { CLEAR_COLLECTION_SCAM_INFO } from 'repositories/admin/clear-collection-scam-info'
import { ADD_FEATURED_COLLECTION } from 'repositories/admin/add-featured-collection'
import { REMOVE_FEATURED_COLLECTION } from 'repositories/admin/remove-featured-collection'
import {
  ADD_BLACKLISTED_COLLECTION,
  GET_BLACKLISTED_COLLECTIONS,
  REMOVE_BLACKLISTED_COLLECTION
} from 'repositories/admin/blacklisted-collections'
import { CLEAR_REPORT_COLLECTION } from 'repositories/admin/clear-report-collection'
import { REPORT_COLLECTION } from 'repositories/admin/report-collection'

export const repository = {
  flagNft: variables => graph().mutate({ mutation: FLAG_NFT, variables }),
  flagCollection: variables => graph().mutate({ mutation: FLAG_COLLECTION, variables }),
  clearNftScamInfo: variables => graph().mutate({ mutation: CLEAR_NFT_SCAM_INFO, variables }),
  clearNftReport: variables => graph().mutate({ mutation: CLEAR_NFT_REPORT, variables }),
  setNftScamInfo: variables => graph().mutate({ mutation: SET_NFT_SCAM_INFO, variables }),
  setCollectionScamInfo: variables => graph().mutate({ mutation: SET_COLLECTION_SCAM_INFO, variables }),
  clearCollectionScamInfo: variables => graph().mutate({ mutation: CLEAR_COLLECTION_SCAM_INFO, variables }),
  addFeaturedCollection: variables => graph().mutate({ mutation: ADD_FEATURED_COLLECTION, variables }),
  removeFeaturedCollection: variables => graph().mutate({ mutation: REMOVE_FEATURED_COLLECTION, variables }),
  getBlacklistedCollections: payload => graph().query({ query: GET_BLACKLISTED_COLLECTIONS, variables: payload }, true),
  addBlacklistedCollection: variables => graph().mutate({ mutation: ADD_BLACKLISTED_COLLECTION, variables }, true),
  removeBlacklistedCollection: variables => graph().mutate({ mutation: REMOVE_BLACKLISTED_COLLECTION, variables }, true),
  reportCollection: variables => graph().mutate({ mutation: REPORT_COLLECTION, variables }, true),
  clearReportCollection: variables => graph().mutate({ mutation: CLEAR_REPORT_COLLECTION, variables }, true),
}
