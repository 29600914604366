import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'
import { NotFound } from 'not-found'
import { RenderRoute } from 'components/layered-router/hooks/use-render-route'

export const Routes = ({ layer, location }) => {
  const currentLocation = useLocation()

  return <RouterRoutes location={{ ...currentLocation, ...location }}>
    {layer.routes.map((route, index) => <Route key={index} path={route.path} exact={route.exact}
                                               element={<RenderRoute route={route} />} />)}
    <Route component={NotFound} />
  </RouterRoutes>
}
