import { useCallback } from 'react'
import { Loading } from 'loading/index'

export const useHideGhosts = () => {
  const hideLoadings = Loading.useHideLoadings()

  return useCallback(id => {
    hideLoadings(id, Loading.VARIANT.ghostCard)
  }, [hideLoadings])
}
