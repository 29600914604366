import * as lodash from 'lodash'
import { useCallback } from 'react'
import { issueCollectionSchema } from 'components/forms/create-collection-form/issue-collection-schema'

export const useMakePayload = () => useCallback(values => {
  const transformed = issueCollectionSchema().cast(lodash.clone(values))
  delete transformed.isSemiFungible

  return transformed
}, [])
