import { gql } from 'graphql-tag'

export const SEND = gql`
    mutation sendOffer ($input: CreateOfferArgs!) {
        sendOffer(input: $input){
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
