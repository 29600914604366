import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'
import { uniqBy } from 'lodash/array'

const initialState = {
  loading: false,
  errors: {},
  data: { artists: { edges: [], pageInfo: { hasNextPage: true, endCursor: '' } } },
  list: [],
  cursor: '',
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload.data })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload.errors) })

const append = (state, { payload }) => ({ ...state, list: uniqBy([...state.list, ...payload], 'node.address') })

const reset = () => initialState

export const get = createSlice({
  name: 'explore-nfts/creators/get',
  initialState,
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})

export const { actions } = get
