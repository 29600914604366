import match from '@nerds.sh/js-pattern'

import { isAuctionEnded } from 'auction-flows/conditions/is-auction-ended'
import { handleBuyerAuctionEnded } from 'auction-flows/handlers/handle-buyer-auction-ended'
import { handleNftAuctionOpen } from 'auction-flows/handlers/handle-nft-auction-open'

export const handleNft = input => match(input)
  .with(!isAuctionEnded(input), handleNftAuctionOpen)
  .with(isAuctionEnded(input), handleBuyerAuctionEnded)
  .execute()
