import { useValidationSchema } from 'components/forms/accept-offer-form/sft/hooks/use-validation-schema'

export const useConfig = ({ onSubmit, initialValues, balance, quantity, auctions }) => ({
  initialValues: useValidationSchema(balance, quantity, auctions).cast(initialValues),
  validationSchema: useValidationSchema(balance, quantity, auctions),
  validateOnLoad: true,
  validateOnMount: true,
  validateOnChange: true,
  validateOnBlur: true,
  onSubmit,
})
