import moment from 'moment'
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { KeyPair } from 'nft-page/details/other-details/key-pair'

const formatDate = date => moment(date * 1000).format('MMMM DD, YYYY')

export const CreatedDate = () => {
  const date = useNftSelector('nftPage.data.assets.edges[0].node.creationDate')

  return <KeyPair icon={<FontAwesomeIcon icon={faCalendarAlt} />} title={'Created On'}>
    <span className={'accent'}>{date ? formatDate(date) : 'N/A'}</span>
  </KeyPair>
}
