export const ActionTypes = Object.freeze({
  Like: 'like',
  Bid: 'bid',
  Follow: 'follow',
  Won: 'won',
  MintNft: 'mintNft',
  StartAuction: 'startAuction',
  PhotoChanged: 'photoChanged',
  Buy: 'buy',
  SendOffer: 'sendOffer',
  AcceptOffer: 'acceptOffer',
})
