import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'home/featured-collections/slices'
import { settings } from 'settings/fetching'

export const useFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.attempt({
      filters:
      { type: 'Featured' },
      pagination: { first: settings().home.featuredCollections },
    }))
  }, [dispatch])
}
