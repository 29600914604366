import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section/index'
import ReactPlayer from 'react-player'
import './index.scss'

export const Video = ({ data }) => {
  const video = get(data, 'video', [])

  return <HomeSection className={'subdomain-video-section'}>
    <div className={'subdomain-video-section-video'}>
      <ReactPlayer className={'player'} url={video} width={'100%'} height={'100%'} />
    </div>
  </HomeSection>
}
