import { FreeLabel } from 'components/free-label'
import image from 'startup/assets/fire.svg'

export const ExternalLink = ({ link }) => <div>
  <a href={link.href} className={'footer-section-link'} target={'_blank'} rel={'noreferrer'}>
    {link.title}
  </a>
  {link.freeLabel ? <FreeLabel /> : ''}
  {link.fireLabel ? <img src={image} alt={'...'} className={'footer-fire-image'} /> : ''}
</div>
