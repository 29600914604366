import 'nft-page/offers/actions/watchable-action/button/index.scss'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const ActionButton = ({ onClick, text, className = 'btn-primary', disabled, tooltipMessage }) => {
  const messageRef = useRef(null)
  useTooltip(messageRef)

  return <div data-bs-toggle={'tooltip'} data-bs-classname={'tooltip-inner'} data-bs-placement={'top'}
              title={tooltipMessage} ref={messageRef}>
    <button className={`btn action-button ${className}`} type={'button'} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  </div>
}
