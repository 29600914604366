import { from, map, of } from 'rxjs'
import { logout } from 'network/logout'
import { catchError, mergeMap } from 'rxjs/operators'
import { settings } from 'settings/fetching'
import { getAddress } from '@multiversx/sdk-dapp/utils'
import { getNativeAuthToken } from 'network/get-native-auth-token'

export const makeUrl = () => `${settings().idApi}/api/v1`

const tokenPayload = async() => ({
  address: await getAddress(),
  idApi: makeUrl(),
})

const restPayload = (payload, accessToken) => ({
  ...payload,
  headers: { ...payload.headers, authorization: `Bearer ${accessToken}` },
})

const makePayload = payload => from(tokenPayload())
  .pipe(mergeMap(tokenPayload => of(getNativeAuthToken())))
  .pipe(map(accessToken => restPayload(payload, accessToken)))
  .pipe(catchError(e => logout()))

export const auth = { makePayload }
