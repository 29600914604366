import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'subdomain/modals/account/my-offers/behaviour'
import { ModalContent } from 'subdomain/modals/account/my-offers/modal-content'
import { Title } from 'subdomain/modals/account/my-offers/title'
import { List } from 'subdomain/modals/account/my-offers/list'
import { Failure } from 'failure'
import { Empty } from 'subdomain/modals/account/my-offers/empty'
import 'subdomain/modals/account/my-offers/style.scss'

export const MyOffers = () => <BootstrapModal id={'my-offers'} className={'my-offers-modal'} easyClose>
  <Behaviour />
  <ModalContent>
    <Title />
    <Failure.Partial id={'modal.account.myOffers'}>
      <List />
      <Empty />
    </Failure.Partial>
  </ModalContent>
</BootstrapModal>

export { reducer } from 'subdomain/modals/account/my-offers/reducer'
export { epic } from 'subdomain/modals/account/my-offers/epic'
