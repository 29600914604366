import { useOffers } from 'offers/hooks/use-offers'
import { Body } from 'nft-page/offers/body'
import 'nft-page/offers/index.scss'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'

export const Offers = () => {
  const offers = useOffers()
  const isSubdomain = useIsSubdomain()

  return isSubdomain || offers.length ? <Body offers={offers} /> : ''
}
