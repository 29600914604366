import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const useIsFilterSelected = (name, excludeDefault) => {
  const filterValue = useFilterValue(name)

  return useCallback(({ value }) => {
    if (isEmpty(filterValue) && !excludeDefault) return value === 'all'

    return filterValue === value
  }, [filterValue, excludeDefault])
}
