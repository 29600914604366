import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'
import { Link } from 'react-router-dom'

export const CollectionLink = ({ link }) => {
  const layeredLocation = useLayeredLocation()

  const { pathname, state } = layeredLocation(link, 'middleground')

  return <Link className={'featured-collection-link'} to={pathname} state={state} />
}

