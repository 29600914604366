import { useParams } from 'react-router-dom'
import { useOffers } from 'offers/hooks/use-offers'

export const useSelectedOffer = () => {
  const { tab } = useParams()
  const offers = useOffers()
  const offer = offers.find(({ node }) => node.id === tab)

  return offer?.node
}
