import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../slices'
import { useIsPrinceCharming } from '../../auth/mega-authorized'

export const useSetPreference = () => {
  const dispatch = useDispatch()
  const isPrinceCharming = useIsPrinceCharming()

  return useCallback(payload => {
    dispatch(actions.set(payload))
    isPrinceCharming && dispatch(actions.save({}))
  }, [dispatch, isPrinceCharming])
}
