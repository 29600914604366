import { followers } from 'repositories/followers'
import { from, map, mergeMap, of, toArray } from 'rxjs'

const getFollowsArtist = (edge, ownAddress) => {
  if (edge.node.address === ownAddress) {
    return of({ address: edge.node.address, isFollowed: true })
  }

  return followers.get.follows(edge.node)
    .pipe(mergeMap(response => of(response.data)))
    .pipe(map(response => ({ address: edge.node.address, isFollowed: response.follows })))
}

const buildEdges = (followedArtists, artists) => artists.edges.map(artist => ({
  node: {
    ...artist.node,
    isFollowed: followedArtists.find(item => item.address === artist.node.address)?.isFollowed,
  },
}))

export const getIsFollowed = (isLoggedIn, ownAddress) => ({ data: { artists } }) => {
  if (!isLoggedIn) {
    return of({ data: { artists } })
  }

  return from(artists.edges)
    .pipe(mergeMap(edge => getFollowsArtist(edge, ownAddress)))
    .pipe(toArray())
    .pipe(map(followedArtists => ({
      data: {
        artists: {
          ...artists,
          edges: buildEdges(followedArtists, artists),
        },
      },
    })))
}
