import { useCallback } from 'react'
import { testNumber } from 'components/forms/place-bid-form/hooks/use-tests'
import { useBalance } from 'common/hooks/use-balance'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const useHasInsufficientFunds = (auction, paymentToken) => {
  const balance = useBalance(paymentToken)
  const minimum = useMinimum(auction)
  const tokenDecimals = get(auction, 'minBid.tokenData.decimals', 18)

  return useCallback(value => {
    if (!testNumber(value)) return false

    return parseFloat(balance) <= (parseFloat(denominateAmount(minimum, tokenDecimals)) && value)
  }, [balance, minimum, tokenDecimals])
}
