import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { faDiamond } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { Value } from 'nft-page/details/other-details/type/value'

export const Type = () => {
  const type = useNftSelector('nftPage.data.assets.edges[0].node.type')

  return <KeyPair icon={<FontAwesomeIcon icon={faDiamond} />} title={'ESDT Type'}>
    { type ? <Value /> : 'N/A'}
  </KeyPair>
}
