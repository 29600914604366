import { Wrapper } from 'admin/wrapper'
import { Actions } from 'admin/actions'
import 'admin/style.scss'

export const AdminSidebar = () => <Wrapper>
  <div className={'admin-sidebar-container'}>
    <div className={'admin-sidebar-content px-1'}>
      <div className={'dropdown'}>
        <button className={'btn btn-secondary dropdown-toggle'} type={'button'}
                data-bs-toggle={'dropdown'} aria-expanded={'false'}>
          Admin menu
        </button>
        <ul className={'dropdown-menu'}>
          <Actions />
        </ul>
      </div>

    </div>
  </div>
</Wrapper>

export { reducer } from 'admin/reducer'
export { epic } from 'admin/epic'
