import { NftForm } from 'modals/offers/accept/nft-form'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { get } from 'lodash'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'

export const Nft = ({ offer }) => {
  const { asset, assetOwner } = useAssetWithOwner()
  const supply = get(asset, 'supply')
  const balance = get(assetOwner, 'balance')
  const auction = useNftDefaultAuction()
  const auctions = auction ? [auction] : []

  return offer ? <NftForm offer={offer} supply={supply} balance={balance} auctions={auctions} isNft /> : ''
}
