import { Description } from 'subdomain/home/campaigns/description'
import { Image } from 'subdomain/home/campaigns/image'
import { get } from 'lodash'
import { Button } from 'components/button'
import { useCmsCampaignCtaButton } from 'subdomain/cms-content/hooks/use-cms-campaign-cta-button'

export const Campaign = ({ data }) => {
  // const link = `/campaigns/${data.contract}/${data.campaignId}`
  const link = '/launch'
  const thumbnailUrl = get(data, 'image.data.attributes.formats.medium.url', '')
  const buttonName = useCmsCampaignCtaButton()

  return <div className={'row subdomain-campaign align-items-center'}>
    <div className={'col-12 col-md-5'}>
      <Image thumbnailUrl={thumbnailUrl} link={link} />
    </div>
    <div className={'col-12 col-md-6 offset-md-1 subdomain-campaign-content'}>
      <h2 className={'subdomain-campaign-subtitle'}>{data.subtitle}</h2>
      <Description description={data.description} />
      <Button buttonType={'primary'} icon={'arrow-right'} to={link} isIconRight>{buttonName}</Button>
    </div>
  </div>
}
