import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { get } from 'feed/suggestions/slices'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'

export const useGetMeta = () => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()

  useEffect(() => {
    dispatch(get.actions.getMeta({ address }))
  }, [dispatch, address])
}
