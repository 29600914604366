import * as loginServices from '@multiversx/sdk-dapp/hooks/login'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { useRedirectableLogin } from 'sidebar/connect/hooks/use-redirectable-login'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { useInternalAccessToken } from 'network/axios-interceptor/use-internal-access-token'
import { extras } from 'repositories/extras'

export const useExtensionLogin = () => {
  extras.getAccessToken()
  const callbackRoute = useCallbackRoute()
  const navigate = useLayeredNavigate()
  const localToken = useInternalAccessToken()

  const [onInitiateLogin] = loginServices.useExtensionLogin({
    callbackRoute,
    nativeAuth: { extraRequestHeaders: { Authorization: `Bearer ${localToken}` } },
    onLoginRedirect: route => {
      navigate(route)
    },
  })

  return useRedirectableLogin(() => {
    onInitiateLogin()
  }, [onInitiateLogin])
}
