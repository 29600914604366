import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'tags/slices/auction'
import { useFetchAuctionPayload } from 'tags/hooks/use-fetch-auction-payload'

export const useFetchAuctionTags = () => {
  const dispatch = useDispatch()
  const payload = useFetchAuctionPayload()

  useEffect(() => {
    dispatch(actions.attempt(payload))
  }, [dispatch, payload])
}
