import { Failure } from 'failure'
import { Seo } from 'components/seo'
import { TabHeader } from 'account/tabs/tab-header'
import { Content } from 'account/tabs/assets/collected/content'
import { VisibleIfActive } from 'account/common/visible-if-active'
import { useSeoProps } from 'account/hooks/use-seo-props'
import { Sell } from 'modals/onboarding/sell'
import { VisibleIfOwner } from 'account/common/visible-if-owner'
import { InnerBehaviour } from 'account/tabs/assets/collected/inner-behaviour'

export const Collected = () => <VisibleIfActive tab={'collected'}>
  <InnerBehaviour />
  <Seo {...useSeoProps('collected')} />

  <Failure.Partial id={'account.collected'}>
    <TabHeader />
    <Content />
    <VisibleIfOwner>
      <Sell />
    </VisibleIfOwner>
  </Failure.Partial>
</VisibleIfActive>
