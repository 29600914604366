import { gql } from 'graphql-tag'

export const PRIMARY_SALE = gql`
    query getPrimarySale($filters: PrimarySaleFilter!){
        primarySale(filters: $filters) {
            collectionIdentifier
            maxUnitsPerWallet
            paymentToken
            price
            frozen
            saleTime {
                endClaim
                endSale
                startClaim
                startSale
            }
            status
        }
    }
`
