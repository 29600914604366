import { useDispatch } from 'react-redux'
import { useEndCursor } from 'subdomain/modals/account/my-claimable/hooks/use-end-cursor'
import { useCallback } from 'react'
import { actions } from 'subdomain/modals/account/my-claimable/slices/get'
import { payload } from 'subdomain/modals/account/my-claimable/payload'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const dispatch = useDispatch()
  const cursor = useCursor(customCursor)
  const marketplaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    marketplaceKey && dispatch(actions.attempt(payload({ cursor, marketplaceKey })))
  }, [marketplaceKey, dispatch, cursor])
}
