import { Context, useDefaultContext } from 'modals/account/dropzone/context'
import { Content } from 'modals/account/dropzone/content'

import 'modals/account/dropzone/index.scss'
import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'modals/account/dropzone/behaviour'

export const Dropzone = ({ onSubmit, maxSize, id, dimensions, fileTypeInfo, errors }) => {
  const props = { onSubmit, maxSize, id, dimensions, fileTypeInfo, errors }

  return <Context.Provider value={useDefaultContext(props)}>
    <Behaviour />
    <BootstrapModal id={id}>
      <Content />
    </BootstrapModal>
  </Context.Provider>
}
