const makeProps = ({ id, active, name, onClick }) => ({
  name,
  className: `nav-link ${active && 'active'}`,
  'data-bs-toggle': 'tab',
  'data-bs-target': `#${id}`,
  type: 'button',
  role: 'tab',
  'aria-controls': id,
  'aria-selected': 'true',
  onClick,
})

export const TabButton = props => (
  <button {...makeProps(props)}>
    {props.children}
  </button>
)
