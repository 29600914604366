import { gql } from 'graphql-tag'

export const CREATE_AUCTION = gql`
    mutation ($input: CreateAuctionArgs!) {
      createAuction(input: $input)
  {
    chainID
    data
    gasLimit
    gasPrice
    nonce
    receiver
    sender 
    value
    version
  }
 }
`
