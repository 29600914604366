import { useParams } from 'react-router-dom'
import { useWatchAction } from 'action-statuses/hooks/use-watch-action'
import { useOnChange } from 'modals/account/create-nft/collections/hooks/use-on-change'

export const useTransactionWatch = () => {
  const { identifier } = useParams()
  const onChange = useOnChange()

  useWatchAction({ prefix: `${identifier}_Collection`, onChange })
}
