import { useField } from 'formik'
import { Label } from 'components/forms/nerds-form/fields/label'
import { Loader } from 'components/forms/nerds-form/fields/loader'
import { useIsLoading } from 'components/forms/nerds-form/fields/submit-button/hooks/use-is-loading'
import { useLocation, useNavigate } from 'react-router-dom'

// eslint-disable-next-line max-lines-per-function
export const InputSubmitField = ({ name, label, type, placeholder, callbackUrl, buttonLabel, disabled }) => {
  const [field, meta, { setTouched }] = useField(name)
  const isLoading = useIsLoading()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    if (!location?.state?.hydrated) {
      navigate(-1)
    } else {
      navigate(callbackUrl, { replace: true })
    }
  }

  return <div className={'nerds-form-field'}>
    <Label className={'form-label col-auto'} name={name}>{label}</Label>
    <div className={'nerds-form-field-input align-items-center justify-content-center'}>
      <input className={'form-control px-0 w-100 col-auto'} data-testid={name}
             type={type} name={name} id={name} onChange={field.onChange} onInput={() => setTouched()}
             value={field.value} onBlur={field.onBlur} placeholder={placeholder} autoComplete={'off'} />
      <button type={'submit'} data-testid={'submit-form'} onClick={handleClick}
              className={'btn btn-primary col-auto m-2'} disabled={disabled}>
        <Loader label={buttonLabel} loading={isLoading} />
      </button>
    </div>
    {meta.touched && meta.error && <small className={'text-danger'}>{meta.error}</small>}
  </div>
}
