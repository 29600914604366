import { useCallback } from 'react'
import { useHasInsufficientSupply } from 'components/forms/send-offer-form/hooks/use-has-inssuficient-supply'

export const useTestSupply = asset => {
  const hasInsufficientSupply = useHasInsufficientSupply(asset)

  return useCallback((value, context) => {
    if (!hasInsufficientSupply(value)) return true

    return context.createError({ message: `Insufficient asset supply`, path: context.path })
  }, [hasInsufficientSupply])
}
