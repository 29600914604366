import { Description } from 'holoride-campaign/action/description'
import { Title } from 'holoride-campaign/action/title'

export const Ended = () => <div className={'holoride-action-inner'}>
  <Title>Campaign Ended</Title>
  <Description>
    Unfortunately the sale of the NFTs
    is now complete.
  </Description>
</div>
