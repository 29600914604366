import { useContext } from 'nft-page/offers/content/context'
import { DurationLabel } from 'components/duration-label'

export const Countdown = () => {
  const { timeLeft } = useContext()

  return timeLeft >= 0 && <div className={'nft-page-offer-countdown d-flex align-items-center'}>
    <div className={'nft-page-offer-countdown-inner'}>
      <DurationLabel duration={timeLeft} />
    </div>
  </div>
}
