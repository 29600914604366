import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useCurrentElement } from 'nft-page/controls/hooks/use-current-element'
import { useAssetFromCurrentElement } from 'nft-page/media/hooks/use-asset-from-current-element'

export const useCurrentAsset = () => {
  const assetPath = 'nft.nftPage.data.assets.edges[0].node'
  const asset = useSelector(state => get(state, assetPath, {}))
  const currentElement = useCurrentElement()
  const extractAsset = useAssetFromCurrentElement(currentElement)

  return currentElement ? extractAsset() : asset
}
