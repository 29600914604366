import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: [
    { id: 7, name: 'Discord' },
    { id: 2, name: 'Facebook' },
    { id: 3, name: 'Instagram' },
    { id: 8, name: 'Medium' },
    { id: 6, name: 'Telegram' },
    { id: 1, name: 'Twitter' },
    { id: 4, name: 'Web Page' },
    { id: 5, name: 'Youtube' },
  ],
  input: '',
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload) })

export const socialLinks = createSlice({
  initialState,
  name: 'socialLinks',
  reducers: {
    attempt,
    success,
    failure,
  },
})

export const { actions } = socialLinks
