import { useCallback } from 'react'

export const useOnDrop = props => useCallback(acceptedFiles => {
  const { setBlob, setValue } = props

  if (acceptedFiles?.[0]) {
    setValue(acceptedFiles[0])
    setBlob(URL.createObjectURL(acceptedFiles[0]))
  }
}, [props])
