import { get } from 'lodash'
import { FlexRow } from 'components/flex-row'
import { UserDetails } from 'components/user-details'
import { useHeroTag } from 'components/user-details/hooks/use-hero-tag'
import { Price } from 'components/price'
import { Action } from 'nft-page/offers/content/action'

export const User = ({ offer }) => {
  const price = get(offer, 'price')

  return <div className={'other-auction'}>
    <FlexRow className={'align-items-center'}>
      <UserDetails image={offer.owner.profile} herotag={useHeroTag(offer.owner)}
                   address={offer.ownerAddress} />
      <Price price={price} />
      <div className={'d-none d-sm-block'}>
        <Action offer={offer} />
      </div>
    </FlexRow>
    <FlexRow className={'justify-content-end align-items-center d-sm-none'}>
      <Action offer={offer} />
    </FlexRow>
  </div>
}
