import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'explore-nfts/tabs/nfts/assets/slices/get'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const useForceResetState = () => {
  const dispatch = useDispatch()
  const selectedTag = useFilterValue('tag')
  const selectedFacets = useFilterValue('filter')
  const selectedSort = useFilterValue('sort')

  useEffect(() => {
    dispatch(actions.reset({}))
  }, [dispatch, selectedTag, selectedFacets, selectedSort])
}
