import { settings } from 'settings/fetching'
import { filters } from 'explore-nfts/tabs/collections/filters-logic/filters'
import { sorting } from 'explore-nfts/tabs/collections/filters-logic/sorting'

export const buildFilters = ({ cursor, filter, sort = 'trending' }) => ({
  pagination: { first: settings().listRequestSize, after: cursor },
  filters: { ...filters({ filter }) },
  sorting: sorting(sort),
})

