import { useActualItems } from 'account/tabs/assets/liked/hooks/use-actual-items'
import { useNext } from 'account/tabs/assets/liked/hooks/use-next'
import { isEmpty } from 'lodash'

import { useEffect } from 'react'

export const useGetInitialPage = () => {
  const getInitialPage = useNext('')
  const items = useActualItems()
  const noItems = isEmpty(items)

  return useEffect(() => {
    if (noItems) {
      getInitialPage()
    }
  }, [getInitialPage, noItems])
}
