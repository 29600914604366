import { Button as AppButton } from 'components/button'
import { usePrimarySale } from 'holoride-campaign/hooks/use-primary-sale'
import { useStatus } from 'nft-page/auction/actions/watchable-action/hooks/use-status'
import { actionTypes } from 'action-statuses/actions-types'
import { useData } from 'holoride-campaign/action/redeem/hooks/use-data'
import { useErrors } from 'holoride-campaign/action/redeem/hooks/use-errors'
import { useClaimTicket } from 'holoride-campaign/action/redeem/hooks/use-claim-ticket'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { actions } from 'holoride-campaign/action/redeem/slices/claim-ticket'
import { Errors } from 'components/forms/nerds-form'

export const Button = ({ children }) => {
  const primarySale = usePrimarySale()
  const status = useStatus(primarySale, actionTypes.claimTicket)
  const data = useData()
  const errors = useErrors()
  const claimTicket = useClaimTicket(primarySale)

  const actionIdentifier = createActionIdentifier(primarySale, actionTypes.claimTicket)

  useOnSentPayload({ data, callbackUrl: '/launch', resetAction: actions.reset, actionIdentifier, isModal: false })

  return <div>
    <AppButton onClick={claimTicket} disabled={!!status} buttonType={'primary'}>
      {children}
    </AppButton>
    <Errors values={errors} />
  </div>
}
