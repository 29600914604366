import { useLocation } from 'react-router-dom'

export const useHomeClass = () => {
  const location = useLocation()
  const isHome = location.pathname === '/'

  if (isHome) {
    document.body.classList.add('subdomain-home')
  } else {
    document.body.classList.remove('subdomain-home')
  }

  return ''
}
