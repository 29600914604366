import React, { useState } from 'react'

export const Context = React.createContext({})

export const useContext = () => React.useContext(Context)

export const useValue = ({ player, playingFeedback }) => {
  const [paused, setPaused] = useState(true)
  const [muted, setMuted] = useState(false)

  return ({
    player,
    paused,
    setPaused,
    muted,
    setMuted,
    playingFeedback,
  })
}
