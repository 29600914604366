import { useCallback } from 'react'
import { get } from 'lodash'
import { BN } from 'bn.js'
import { actions } from 'holoride-campaign/modals/buy/slices/buy-tickets'
import { useDispatch } from 'react-redux'

export const useBuyTickets = primarySale => {
  const dispatch = useDispatch()

  return useCallback(({ ticketsNumber }) => {
    const unitPrice = get(primarySale, 'price')
    const price = new BN(unitPrice).mul(new BN(String(ticketsNumber)))
      .toString()
    const paymentToken = get(primarySale, 'paymentToken')

    const payload = {
      collectionIdentifier: primarySale.collectionIdentifier,
      price,
      ticketsNumber,
    }

    dispatch(actions.attempt({ payload, paymentToken }))
  }, [dispatch, primarySale])
}
