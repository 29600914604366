import * as Yup from 'yup'

import * as minBidPrice from 'components/forms/create-nft-auction-form/hooks/use-min-bid-schema'
import * as maxBidPrice from 'components/forms/create-nft-auction-form/hooks/use-max-bid-schema'
import moment from 'moment'
import { settings } from 'settings/fetching'

const maxBidSchema = () => Yup.string()
  .when('checkedMaxBid', {
    is: true,
    then: maxBidPrice.useMaxBidSchema('Required'),
    otherwise: Yup.string()
      .default(''),
  })
  .default('')

const egldLabel = () => settings().dapp.egldLabel

export const useValidationSchema = () => Yup.object().shape({
  deadline: Yup.date()
    .required('Required')
    .default(moment().add(1, 'd')
      .toDate())
    .min(new Date(), 'Deadline cannot be in the past'),
  minBid: minBidPrice.useMinBidSchema('Required'),
  checkedMaxBid: Yup.boolean().default(false),
  maxBid: maxBidSchema(),
  identifier: Yup.string().required(),
  paymentToken: Yup.string().required()
    .default(egldLabel()),
  quantity: Yup.number()
    .required('Required')
    .typeError('Should be a number')
    .min(1, 'Should be greater than 0')
    .integer('Should be an integer')
    .default('1'),
  maxOneSftPerPayment: Yup.boolean().default(false),
})
