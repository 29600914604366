import { mediaQuery } from 'components/collection-card/media-query'
import { calcInViewport } from 'explore-nfts/tabs/collections/list/hooks/calc-in-viewport'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

const root = document.getElementById('root')

const getMaxVisibleIndex = elements => {
  let indexMax = 0
  let max = 0

  elements.forEach((item, index) => {
    const currentPercent = calcInViewport(item)

    if (currentPercent > max) {
      indexMax = index
      max = currentPercent
    }
  })

  return indexMax
}

export const useRunningIndex = elements => {
  const [runningIndex, setRunningIndex] = useState(-1)

  useEffect(() => {
    const update = debounce(() => {
      const index = getMaxVisibleIndex(elements)

      !mediaQuery.matches ? setRunningIndex(index) : setRunningIndex(-1)
    }, 200)

    root.addEventListener('scroll', update)
    window.addEventListener('resize', update)

    update()

    return () => {
      root.removeEventListener('scroll', update)
      window.removeEventListener('resize', update)
    }
  // eslint-disable-next-line
  }, [elements?.length, mediaQuery.matches])

  return runningIndex
}
