import { NerdsForm } from 'components/forms/nerds-form'
import { useConfig } from 'components/forms/accept-offer-form/sft/hooks/use-config'
import { get } from 'lodash'
import { Fields } from 'components/forms/accept-offer-form/sft/fields'

export const Sft = ({
  loading, onSubmit, submitErrors, callbackUrl,
  initialValues, offer, auctions, supply, balance,
}) => {
  const quantity = get(offer, 'quantity')

  return <NerdsForm loading={loading} submit_errors={submitErrors}
                    config={useConfig({ onSubmit, initialValues, supply, balance, quantity, auctions })}>
    <Fields callbackUrl={callbackUrl} offer={offer} auctions={auctions} submitErrors={submitErrors} />
  </NerdsForm>
}
