import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as tagsActions } from 'tags/slices/asset'

export const useResetState = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(tagsActions.reset({}))
  }, [dispatch])
}
