import { useIsBigLayout } from 'components/feed-card/hooks/use-is-big-layout'
import match from '@nerds.sh/js-pattern'
import { previewTypes } from 'components/feed-card/preview-types'

export const usePreviewType = props => {
  const big = useIsBigLayout(props.action.type, props.whom.address)

  return match({})
    .with(big, () => previewTypes.big)
    .with(!big, () => previewTypes.small)
    .with(props.previewHidden, () => previewTypes.none)
    .execute()
}
