import { useOnRightClick } from 'nft-page/controls/hooks/use-on-right-click'
import { useOnLeftClick } from 'nft-page/controls/hooks/use-on-left-click'
import { useNextElement } from 'nft-page/controls/hooks/use-next-element'
import { usePreviousElement } from 'nft-page/controls/hooks/use-previous-element'
import { useMemo } from 'react'

export const useControlHandlers = () => {
  const onRightClick = useOnRightClick()
  const onLeftClick = useOnLeftClick()
  const nextElement = useNextElement()
  const previousElement = usePreviousElement()

  const canGoRight = nextElement.identifier || nextElement.auctionId
  const canGoLeft = previousElement.identifier || previousElement.auctionId

  return useMemo(() => ({
    left: () => canGoLeft && onLeftClick(),
    right: () => canGoRight && onRightClick(),
  }), [canGoLeft, canGoRight, onLeftClick, onRightClick])
}
