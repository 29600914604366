import { List } from 'components/share-button/list'
import 'components/share-button/index.scss'
import { Button } from 'components/button'

const makeProps = () => ({
  className: 'btn btn-secondary share-button ',
  icon: 'share',
  onClick: () => {},
  buttonType: 'secondary',
  type: 'button',
  id: 'shareMenuButton',
  'data-bs-toggle': 'dropdown',
  'aria-haspopup': 'true',
  'aria-expanded': 'false',
})

export const ShareButton = () => <div className={'share-button-container dropdown'}>
  <Button {...makeProps()}>
    Share
  </Button>
  <List />
</div>
