import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Subtitle } from 'components/forms/nerds-form/subtitle'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Errors } from 'components/forms/nerds-form'
import { get } from 'lodash'
import { QuantityField } from 'components/forms/nerds-form/fields/quantity-field'
import { DateTimeField } from 'components/forms/nerds-form/fields/date-time-field'
import { PriceField } from 'components/forms/nerds-form/fields/price-field'
import { AuctionsField } from 'components/forms/nerds-form/fields/auctions-field'

export const Fields = ({ callbackUrl, asset, submitErrors, paymentToken, auctions }) => {
  const isNft = get(asset, 'type') === 'NonFungibleESDT'

  return <div className={'modal-content'}>
    <Title icon={faGavel} title={'Make Offer'} margins={'mb-2'} />
    <Subtitle subtitle={`Make an offer for ${asset.identifier}`} />
    <PriceField name={'paymentAmount'} paymentToken={paymentToken} label={'Offer Amount'} />
    {!isNft && <QuantityField name={'quantity'} label={'How many pieces?'} pieces={asset.supply} />}
    {auctions.length > 0 ? <AuctionsField name={'auctionId'} auctions={auctions} /> : ''}
    <DateTimeField name={'deadline'} label={'Expires on'} disablePast />
    <DefaultActions callbackUrl={callbackUrl} label={'Make Offer'} />
    <Errors values={submitErrors} />
  </div>
}
