import { humanize } from 'components/conversion/humanize/index'

export const Count = ({ count }) => {
  const integer = parseInt(count)
  const suffix = count?.toString().replace(integer, '')

  return <span className={'facet-button-count ms-2'}>
    {humanize(integer?.toString())}
    {suffix}
  </span>
}
