import { SellMore } from 'nft-page/auction/actions/sell-more'
import { Bids } from 'nft-page/auction/bids'

export const Box = ({ children }) => <div className={'nft-page-auction'}>
  <SellMore />
  <div className={'nft-page-auction-border'}>
    <div className={'nft-page-auction-border-inner'}>
      {children}
      <Bids />
    </div>
  </div>
</div>
