import dropzoneIcon from 'startup/assets/dropzone-icon.png'
import { useDropzoneContext } from '../context'
import { useIsPreview } from '../hooks/use-is-preview'

export const Icon = () => {
  const { isDragActive } = useDropzoneContext()
  const isPreview = useIsPreview()

  return !isDragActive && !isPreview && <img className={'mb-4'} src={dropzoneIcon} alt={'picture icon'} />
}
