import { ReactComponent as FeeExampleIcon } from 'startup/assets/fee-example.svg'
import 'components/forms/nerds-form/fields/final-fee-field/example-fee/index.scss'
import { Description } from 'components/forms/nerds-form/fields/final-fee-field/example-fee/description'

const className = 'example-fee-item final-fee-item d-flex align-items-center'

export const ExampleFee = ({ assetFees, exampleValue, paymentToken }) => <div className={className}>
  <FeeExampleIcon className={'final-fee-icon'} />
  <div>
    {!exampleValue && <div className={'example-fee-item-title d-flex justify-content-start'}>
      {`Example with ${exampleValue || 1} ${paymentToken.symbol}`}
    </div>}
    <Description assetFees={assetFees} paymentToken={paymentToken} />
  </div>
</div>
