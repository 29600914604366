import { useOnClick } from 'auction/actions/end/hooks/use-on-click'
import { FlexRow } from 'components/flex-row'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/details/other-listings/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'

export const EndAuction = ({ auction, address }) => {
  const endAuctionClick = useOnClick(auction)
  const onClick = usePermittedAction(endAuctionClick, address)

  return <FlexRow>
    <WatchableAction entity={auction} actionType={actionTypes.end} onClick={onClick} text={'End'}
                     className={'btn btn-secondary other-auction-action'} />
  </FlexRow>
}

