import React from 'react'
import { Description } from 'account/header/description'
import { Cover } from 'account/profile-cover'
import { UserInfo } from 'account/header/user-info'
import { Username } from 'components/username'
import { Links } from 'account/header/links'
import { useViewerUsername } from 'viewer/hooks/use-viewer-username'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import 'account/header/index.scss'
import { useProfileData } from 'account/header/hooks/use-profile-data'

export const Header = () => {
  const herotag = useViewerUsername()
  const data = useProfileData()
  const isPrivate = data.privacy === 'private'

  return <div className={'account-header'}>
    <Cover />
    <UserInfo />
    <Username herotag={isPrivate ? '' : herotag} address={useViewerAddress()} showExplorerLink />
    <Links />
    <Description />
  </div>
}
