import { useList } from 'nft-page/details/other-listings/hooks/use-list'
import { get } from 'lodash'
import defaultAvatar from 'common/default-avatar'

const getHeroTag = asset => {
  const herotag = get(asset, 'owner.herotag')

  return herotag ? herotag.split('.elrond')[0] : ''
}

export const useSftAuctionOwner = () => {
  const auctionsList = useList()
  const asset = get(auctionsList, '[0].node')
  const address = get(auctionsList, '[0].node.ownerAddress', '')
  const profileImage = get(asset, 'owner.profile', defaultAvatar) || defaultAvatar

  return { herotag: getHeroTag(asset), address, profileImage }
}
