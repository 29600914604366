import { Title } from './title'
import { Description } from './description'
import { Infos } from './infos'
import { TiersHeader } from './tiers-header'
import { Tiers } from './tiers'

export const Content = () => <div className={'campaign-content-col col'}>
  <Title />
  <Description />
  <Infos />
  <TiersHeader />
  <Tiers />
</div>
