import { Form, Formik } from 'formik'
import { Provider } from './provider'

const useProps = ({ loading, submitErrors, callbackUrl }) => ({ loading, submitErrors, callbackUrl })

export const NerdsForm = props => <Provider value={useProps(props)}>
  <Formik {...props.config}>
    <Form className={`form nerds-form ${props.className}`}>
      {props.children}
    </Form>
  </Formik>
</Provider>

export * from 'components/forms/nerds-form/errors'
