import { gql } from 'graphql-tag'

export const STATS = gql`
    query collectionStats($filters: CollectionStatsFilter!) {
        collectionStats(filters: $filters) {
            activeAuctions
            auctionsEnded
            identifier
            items
            maxPrice
            minPrice
            saleAverage
            volumeTraded
        }
    }
`
