import { HomeSection } from 'subdomain/components/home-section'
import { get } from 'lodash'
import ReactMarkdown from 'react-markdown'
import 'subdomain/home/columns/index.scss'

export const Columns = ({ data }) => {
  const title = get(data, 'title', '')
  const left = get(data, 'left', '')
  const right = get(data, 'right', '')

  return <HomeSection title={title} className={'two-column-section'}>
    <div className={'two-column-section-row row'}>
      <div className={'col-12 col-md-6'}>
        <ReactMarkdown>{left}</ReactMarkdown>
      </div>
      <div className={'col-12 col-md-6'}>
        <ReactMarkdown>{right}</ReactMarkdown>
      </div>
    </div>
  </HomeSection>
}
