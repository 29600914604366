import PropTypes from 'prop-types'

import { useProps } from 'components/column/hooks/use-props'

export const Column = ({ children, ...props }) => <div {...useProps(props)}>
  {children}
</div>

Column.defaultProps = {
  children: '',
  className: '',
}

Column.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
}
