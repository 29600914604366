import { graph } from 'network'
import { ADD, REMOVE } from 'repositories/likes/mutations'
import { GET } from 'repositories/likes/queries'

export const likes = {
  add: variables => graph().mutate({ mutation: ADD, variables }),

  remove: variables => graph().mutate({ mutation: REMOVE, variables }),

  get: variables => graph().query({ query: GET, variables }),
}
