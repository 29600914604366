import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: '',
  input: '',
  errors: '',
  loading: false,
}

const attempt = (state, action) => ({ ...state, loading: true, attempt: action.payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, failure: serializeErrors(payload) })

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'marketplaces',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = get
