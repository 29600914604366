import match from '@nerds.sh/js-pattern'
import { get } from 'lodash'

export const createActionIdentifier = (entity, action) => match(get(entity, '__typename', ''))
  .with(typename => typename === 'Asset', () => `${entity.identifier}_Asset_${entity.identifier}_${action}`)
  .with(typename => typename === 'Auction', () => `${entity.identifier}_Auction_${entity.id}_${action}`)
  .with(typename => typename === 'Collection', () => `${entity.collection}_Collection_${action}`)
  .with(typename => typename === 'Campaign', () => `${entity.minterAddress}_${entity.campaignId}_Campaign_${action}`)
  .with(typename => typename === 'PrimarySale', () => `${entity.collectionIdentifier}_Campaign_${action}`)
  .with(typename => typename === 'Offer', () => `${entity.identifier}_Offer_${entity.identifier}_${action}`)
  .otherwise(() => `${entity}_${action}`)
