import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'nft-page/details/other-listings/slices/running'
import { useRunningAuctionsPageInfo } from 'nft-page/details/other-listings/hooks/use-running-auctions-page-info'
import { useParams } from 'react-router-dom'
import { useMakePayload } from 'nft-page/details/other-listings/hooks/use-make-payload'

export const useRunningLoadMore = () => {
  const dispatch = useDispatch()
  const { endCursor } = useRunningAuctionsPageInfo()
  const { identifier } = useParams()
  const payload = useMakePayload({ identifier, after: endCursor, status: 'Running' })

  return useCallback(() => dispatch(actions.attempt(payload)), [dispatch, payload])
}
