import { useResetState } from 'holoride-campaign/hooks/use-reset-state'
import { useFetch } from 'holoride-campaign/hooks/use-fetch'
import { useWatchTransaction } from 'holoride-campaign/hooks/use-watch-transaction'

export const Behaviour = () => {
  useResetState()
  useFetch()
  useWatchTransaction()

  return ''
}
