import { useSubdomainCollectionAssets } from 'subdomain/home/collections/hooks/use-subdomain-collection-assets'
import { Title } from 'subdomain/home/collections/title'
import { Description } from 'subdomain/home/collections/description'
import { Images } from 'subdomain/home/collections/images'
import { Button } from 'components/button'

export const Collection = ({ collection }) => {
  const collectionAssets = useSubdomainCollectionAssets(collection)
  const link = `/collections/${collection.identifier}`

  return <div className={'subdomain-collection'}>
    <Images assets={collectionAssets} link={link} />

    <div className={'row align-items-center flex-nowrap'}>
      <div className={'col  subdomain-collection-content'}>
        <Title title={collection.title} link={link} />
        <Description description={collection.description} />
      </div>
      <div className={'col-auto'}>
        <Button buttonType={'primary'} icon={'arrow-right'} to={link} isIconRight>Explore Collection</Button>
      </div>
    </div>
  </div>
}
