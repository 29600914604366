import { useCallback } from 'react'

export const useOnMaxChanged = ({ setMaxVal, onChange, minVal, maxVal }) => useCallback(event => {
  const valid = parseFloat(event.target.value) > parseFloat(minVal)
  const object = {
    value: valid ? event.target.value : maxVal,
    name: event.target.name,
  }

  setMaxVal(object.value)
  onChange(object)
}, [minVal, maxVal, setMaxVal, onChange])

export const useOnMinChanged = ({ setMinVal, onChange, minVal, maxVal }) => useCallback(event => {
  const valid = parseFloat(event.target.value) < parseFloat(maxVal)
  const object = {
    value: valid ? event.target.value : minVal,
    name: event.target.name,
  }

  setMinVal(object.value)
  onChange(object)
}, [setMinVal, maxVal, minVal, onChange])
