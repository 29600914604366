import PropTypes from 'prop-types'

const source = PropTypes.shape({
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
})

const options = PropTypes.shape({ muted: PropTypes.bool })

export const defaultProps = {
  sources: [],
  options: { muted: false },
  instance: undefined,
}

export const propTypes = {
  sources: PropTypes.arrayOf(source),
  options,
  instance: PropTypes.object,
}
