import { useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const useMakeClassName = ({ activePaths }) => {
  const location = useLocation()
  const isActivePaths = activePaths ? activePaths.includes(location.pathname) : false

  return useCallback(({ isActive }) => `nav-link${isActive || isActivePaths ? ' active' : ''}`, [isActivePaths])
}

export const InternalLink = ({ link }) => {
  const makeClassName = useMakeClassName(link)

  return <NavLink to={link.path} className={makeClassName} end>
    {link.label}
  </NavLink>
}

