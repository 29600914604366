import { Empty as EmptyState } from 'components/empty'
import { faRocket } from '@fortawesome/pro-light-svg-icons'
import { useList } from 'trending/hooks/use-list'
import { useIsLoading } from 'trending/hooks/use-is-loading'

const stateProps = {
  icon: faRocket,
  message: 'No auctions found.',
  fullscreen: true,
}

export const Empty = () => {
  const data = useList()
  const isLoading = useIsLoading()

  return !isLoading && !data.length && <EmptyState {...stateProps} />
}
