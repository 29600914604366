import { useCallback } from 'react'
import { actions } from 'holoride-campaign/action/redeem/slices/claim-ticket'
import { useDispatch } from 'react-redux'

export const useClaimTicket = primarySale => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.attempt({ collectionIdentifier: primarySale.collectionIdentifier }))
  }, [dispatch, primarySale])
}
