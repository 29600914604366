import { get } from 'lodash'
import placeholder from 'startup/assets/nft-image-placeholder.png'
import 'components/feed-card/asset/index.scss'

export const Asset = props => {
  const bigClass = props.big ? 'big' : ''

  return <div className={`feed-card-asset ${bigClass}`}>
    <div className={'feed-card-asset-outer'}>
      <div className={'feed-card-asset-inner'}>
        <img src={get(props, 'whom.media[0].thumbnailUrl', placeholder)} />
      </div>
    </div>
  </div>
}
