import match from '@nerds.sh/js-pattern'

import { isAuctionEnded } from 'auction-flows/conditions/is-auction-ended'
import { handleBuyerAuctionEnded } from 'auction-flows/handlers/handle-buyer-auction-ended'
import { handleSftAuctionOpen } from 'auction-flows/handlers/handle-sft-auction-open'

export const handleSft = input => match(input)
  .with(!isAuctionEnded(input), handleSftAuctionOpen)
  .with(isAuctionEnded(input), handleBuyerAuctionEnded)
  .execute()
