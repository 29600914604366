import * as Yup from 'yup'

import * as minBidPrice from 'components/forms/create-sft-auctions/variable-form/hooks/use-min-bid-schema'
import * as maxBidPrice from 'components/forms/create-sft-auctions/variable-form/hooks/use-max-bid-schema'
import moment from 'moment'
import { testBalance } from 'components/forms/create-sft-auctions/test-balance'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const useValidationSchema = () => Yup.object().shape({
  deadline: Yup.date()
    .required('Required')
    .default(moment().add(1, 'd')
      .toDate())
    .min(new Date(), 'Deadline cannot be in the past'),
  minBid: minBidPrice.useMinBidSchema('Required'),
  maxBid: maxBidPrice.useMaxBidSchema(),
  identifier: Yup.string().required(),
  paymentToken: Yup.string().required()
    .default(egldLabel()),
  quantity: Yup.number()
    .required('Required')
    .typeError('Should be a number')
    .min(2, 'Should be greater than 1')
    .integer('Should be an integer')
    .test({ name: 'Has enough balance', test: testBalance })
    .default('2'),
  maxOneSftPerPayment: Yup.boolean().default(true),
})
