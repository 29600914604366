import { accountActions } from 'components/feed-card/account-actions'
import { AccountContent } from 'components/feed-card/reference-name/account-content'
import { AssetName } from 'components/feed-card/reference-name/asset-name'

export const ReferenceName = props => {
  const account = <AccountContent {...props} />
  const asset = <AssetName {...props} />

  return accountActions.includes(props.action.type) ? account : asset
}
