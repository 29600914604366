import { TabContent } from 'components/tabs/tab-content'
import { usePathname } from 'explore-nfts/tabs/hooks/use-pathname'
import { Behaviour } from 'explore-nfts/tabs/creators/behaviour'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { DataCard } from 'components/data-card'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useCreatorsList } from 'explore-nfts/tabs/creators/hooks/use-creators-list'
import { useIsLoading } from 'explore-nfts/tabs/creators/hooks/use-is-loading'
import { useNext } from 'explore-nfts/tabs/creators/hooks/use-next'
import { useHasMore } from 'explore-nfts/tabs/creators/hooks/use-has-more'
import { List } from 'explore-nfts/tabs/creators/list'

const cardProps = () => ({ title: '' })

export const Creators = () => {
  const tab = usePathname()
  const creators = useCreatorsList()
  const isLoading = useIsLoading()

  return <TabContent id={'explore-creators-tab'} active={tab === 'creators'}>
    <Behaviour />
    <ScrollToTopOnMount />
    <DataCard {...cardProps()}>
      <InfiniteScroll next={useNext()} isLoading={isLoading} hasMore={useHasMore()}>
        <List creators={creators} />
      </InfiniteScroll>
    </DataCard>
  </TabContent>
}
