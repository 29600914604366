import { createSlice } from '@reduxjs/toolkit'

const initialState = { data: '' }

const set = (_, { payload: data }) => ({ data })

const reset = () => initialState

export const tag = createSlice({
  initialState,
  name: 'trending/tag',
  reducers: {
    set,
    reset,
  },
})

export const { actions } = tag
