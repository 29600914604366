import { TrimmedText } from 'components/trimmed-text'
import 'campaign/description/index.scss'
import { useCampaign } from '../hooks/use-campaign'

export const Description = () => {
  const campaign = useCampaign()

  return <div className={'campaign-description'}>
    <TrimmedText text={campaign.description} length={175} />
  </div>
}
