import moment from 'moment'

const byTypeFiltersMap = {
  none: { field: 'type', values: ['None'], op: 'EQ' },
  nft: { field: 'type', values: ['Nft', 'NftBid'], op: 'IN' },
  'sft-all': { field: 'type', values: ['SftAll'], op: 'EQ' },
  'sft-one-per-payment': { field: 'type', values: ['SftOnePerPayment'], op: 'EQ' },
}

export const filters = ({ tag, type }) => {
  const filters = [
    { field: 'status', values: ['Running'], op: 'EQ' },
    { field: 'tags', values: [tag], op: 'LIKE' },
    { field: 'startDate', values: [`${moment().unix()}`], op: 'LE' },
  ]
  byTypeFiltersMap[type] && filters.push(byTypeFiltersMap[type])

  return filters
}
