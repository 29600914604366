import { createContext, useContext as useReactContext, useState } from 'react'

export const Context = createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultContext = () => {
  const [timeLeft, setTimeLeft] = useState()

  return { timeLeft, setTimeLeft }
}
