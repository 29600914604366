import { Swiper, SwiperSlide } from 'swiper/react'
import { TabButton } from 'components/tabs/tab-button'
import { useOnClick } from 'components/page-tabs/hooks/use-on-click'
import { humanize } from 'components/conversion/humanize/index'

export const Tabs = ({ tabs, omitFirstTabUrl, baseUrl, isActive }) => {
  const onClick = useOnClick(baseUrl, omitFirstTabUrl)

  return <Swiper freeMode slidesPerView={'auto'} spaceBetween={16} breakpoints={{ 768: { spaceBetween: 32 } }}>
    {tabs.map((item, index) => (<SwiperSlide key={index}>
      <TabButton id={`explore-${item.name}-tab`}
                 active={isActive(index, item.name)}
                 onClick={() => onClick(index, item.name)}>
        {item.label}
        <span className={'page-tabs-counter'}>
          {humanize(item.count?.toString())}
        </span>
      </TabButton>
    </SwiperSlide>))}
  </Swiper>
}
