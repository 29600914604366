import { SetRolesForm } from 'components/forms/set-roles-form'
import { useSetRoles } from 'modals/collection/set-roles/lookup/hooks/use-set-roles'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { useData } from 'modals/collection/set-roles/lookup/hooks/use-data'
import { actions } from 'modals/collection/set-roles/slices/set-roles'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useCollectionIdentifier } from 'modals/collection/set-roles/lookup/hooks/use-collection-identifier'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

export const Form = () => {
  const data = useData()
  const callbackUrl = useCloseLayerLocation()
  const set = useSetRoles()
  const collection = useCollectionIdentifier()

  const actionIdentifier = createActionIdentifier({ collection, __typename: 'Collection' }, actionTypes.setRoles)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <SetRolesForm onSubmit={set} callbackUrl={callbackUrl} />
}
