import { useCallback } from 'react'
import { useField } from 'formik'

export const useOnChange = ({ name, dependentName }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, __, { setValue }] = useField(name)
  const [{ onChange }] = useField(dependentName)

  return useCallback(value => {
    if (value) {
      setValue('')
    }

    onChange(value)
  }, [onChange, setValue])
}
