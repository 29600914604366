import { useUpdateLoading as useDescriptionLoading } from 'modals/account/edit/description/hooks/use-update-loading'
import { useUpdateLoading as useLinksLoading } from 'modals/account/edit/social-links/hooks/use-update-loading'
import { useUpdateLoading as usePrivacyLoading } from 'modals/account/edit/privacy/hooks/use-update-loading'

export const useIsLoading = () => {
  const description = useDescriptionLoading()
  const links = useLinksLoading()
  const privacy = usePrivacyLoading()

  return description || links || privacy
}
