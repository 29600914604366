import { Link } from 'react-router-dom'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

export const LayeredLink = ({ path, layer, className, children }) => {
  const makeLocation = useLayeredLocation()
  const { pathname, state } = makeLocation(path, layer)

  return <Link to={pathname} state={state} className={className}>
    {children}
  </Link>
}
