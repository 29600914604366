export const actionTypes = Object.freeze({
  buyNowSft: 'buyNowSft',
  bid: 'bid',
  withdraw: 'withdraw',
  sftWithdraw: 'sftWithdraw',
  end: 'end',
  claim: 'claim',
  startAuction: 'startAuction',
  startSftAuction: 'startSftAuction',
  createAsset: 'createAsset',
  setRoles: 'setRoles',
  createCollection: 'createCollection',
  buyCampaign: 'buyCampaign',
  buyTickets: 'buyTickets',
  claimTicket: 'claimTicket',
  sendOffer: 'sendOffer',
  withdrawOffer: 'withdrawOffer',
  acceptOffer: 'acceptOffer',
})
