import { gql } from 'graphql-tag'

export const CAMPAIGN = gql`
    query getCampaigns($filters: CampaignsFilter){
        campaigns(filters: $filters) {
            edges {
                cursor
                node{
                    status
                    maxNftsPerTransaction
                    campaignId
                    minterAddress
                    totalNfts
                    status
                    description
                    startDate
                    endDate
                    availableNfts
                    campaignId
                    mediaType
                    coverImage
                    collection{
                        collectionHash
                        collectionName
                        collectionTicker
                        royalties
                    }
                    tiers{
                        status
                        campaignId
                        tierName
                        availableNfts
                        totalNfts
                        mintPrice{
                            amount
                            token
                        }
                    } 
                }
            }
            pageData {
                count
                limit
                offset
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
