import { ofType } from 'redux-observable'
import { actions } from '../slices'
import { catchError, mergeMap } from 'rxjs/operators'
import { preferences as repository } from '../../repositories/preferences'
import { from, of } from 'rxjs'

export const save = (actions$, state$) => actions$
  .pipe(ofType(actions.save))
  .pipe(mergeMap(action => execute(action, state$)))

export const execute = ({ payload }, state) => repository.set(state.value.preferences.data)
  .pipe(mergeMap(result => of(result.data)))
  .pipe(mergeMap(saved))
  .pipe(catchError(failure))

const saved = payload => from([
  actions.saved(payload),
])

const failure = payload => from([
  actions.failure(payload),
])
