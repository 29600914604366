import { Asset } from 'common/asset'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import 'nft-page/details/other-details/file-type/value.scss'

export const Value = () => {
  const fileType = Asset.useFileType(useNftSelector('nftPage.data.assets.edges[0].node')).split('/')

  return <div>
    <span className={'accent file-type-value'}>{`${fileType[0]}`}</span>
    { ' ' }
    <span>{`(.${fileType[1]})`}</span>
  </div>
}
