import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useAssetHistoryPageInfo } from 'asset-history/hooks/use-asset-history-page-info'
import { actions } from 'asset-history/slices/asset-history-slice'

export const useLoadMore = () => {
  const dispatch = useDispatch()
  const { endCursor } = useAssetHistoryPageInfo()

  return useCallback(() => {
    dispatch(actions.setCursor(endCursor))
  }, [dispatch, endCursor])
}
