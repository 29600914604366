import { get } from 'lodash'
import { useWithdraw } from 'modals/auction/withdraw/hooks/use-withdraw'
import { useWithdrawSelector } from 'modals/auction/withdraw/hooks/use-withdraw-selector'
import { WithdrawForm } from 'components/forms/withdraw-form'
import { actions } from 'modals/auction/withdraw/slices/withdraw'
import { useDependenciesLoading } from 'modals/auction/hooks/use-dependecies-loading'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

const makeInitialValues = auction => ({ auctionId: parseInt(get(auction, 'id', '0')) })

export const Form = ({ auction }) => {
  const callbackUrl = useCloseLayerLocation(`/nfts/${auction.identifier}`)
  const withdraw = useWithdraw(auction)
  const { data, loading, errors } = useWithdrawSelector()
  const dependenciesLoading = useDependenciesLoading()
  const actionIdentifier = createActionIdentifier(auction, actionTypes.withdraw)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <WithdrawForm onSubmit={withdraw} submitErrors={errors} loading={loading || dependenciesLoading}
                       callbackUrl={callbackUrl} initialValues={makeInitialValues(auction)} key={auction.id} />
}
