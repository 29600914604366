import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { marketplaces as slice } from 'cms-content/slices/marketplaces'

export const useFetchingCmsMarketplaces = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(slice.actions.attempt())
  }, [dispatch])
}
