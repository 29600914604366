import { Logo } from 'subdomain/components/social-links/logo'
import { Links } from 'subdomain/components/social-links/links'
import './index.scss'

export const SocialLinks = ({ links }) => <div className={'container'}>
  <div className={'subdomain-social-links'}>
    <Logo />
    <div className={'subdomain-social-links-content'}>
      <Links links={links} />
    </div>
  </div>
</div>
