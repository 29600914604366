import { useIsActiveUser } from 'common/hooks/use-is-active-user'
import { Button } from 'account/tabs/create-nft-button/button'
import { useFollowersIsLoading } from 'account/profile/follow/hooks/use-followers-is-loading'
import { useCreationDisabled } from 'account/hooks/use-creation-disabled'

export const CreateNftButton = () => {
  const isActiveUser = useIsActiveUser()
  const isLoading = useFollowersIsLoading()
  const message = 'Minimum 10 followers required to create Nft'
  const creationDisabled = useCreationDisabled()

  return isActiveUser ? <Button disabled={!isLoading && creationDisabled} tooltipMessage={message} /> : ''
}
