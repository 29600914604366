import { useSetRoles } from 'modals/collection/set-roles/existing/hooks/use-set-roles'
import { SetSftRolesForm } from 'components/forms/set--sft-roles-form'
import { useData } from 'modals/collection/set-roles/existing/hooks/use-data'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'
import { actions as collectionsActions } from 'collections/slices/collections'
import { makePayload } from 'collections/hooks/use-fetch-collections'
import { useCallbackUrl } from 'modals/collection/set-roles/existing/hooks/use-callback-url'

export const Form = () => {
  const data = useData()

  const set = useSetRoles()
  const [collection] = useCollectionsSelector()
  const callbackUrl = useCallbackUrl()

  const actionIdentifier = createActionIdentifier(collection?.node, actionTypes.setRoles)
  const resetAction = () => collectionsActions.attempt(makePayload({ collection: collection.node.collection }))

  useOnSentPayload({ data, callbackUrl, resetAction, actionIdentifier })

  return <SetSftRolesForm onSubmit={set} callbackUrl={callbackUrl} />
}
