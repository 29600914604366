import { useAuctionTags } from 'tags/hooks/use-auction-tags'
import { useOnOptionClick } from 'trending/tags-filter/hooks/use-on-option-click'

const makeLabel = label => label !== 'All' ? `#${label.toLowerCase()}` : label

export const Options = () => {
  const tags = useAuctionTags()
  const onClick = useOnOptionClick()

  return <ul className={'dropdown-menu'} aria-labelledby={'trendingTabDropdownMenu'}>
    {tags.map((tag, index) => <li key={index}>
      <a className={'dropdown-item'} onClick={() => onClick(tag.value)}>
        {makeLabel(tag.label)}
      </a>
    </li>)}

  </ul>
}
