import 'nft-page/media/interactive/index.scss'
import { LoadingSpinner } from 'components/loading-spinner'
import { useInteractiveUrl } from 'nft-page/media/hooks/use-interactive-url'

export const Interactive = ({ onMediaLoaded }) => {
  const { interactiveUrl, description } = useInteractiveUrl()

  return (
    <div className={'nft-interactive'}>
      <div id={'spinner'}>
        <LoadingSpinner text={''} />
      </div>
      <iframe title={description}
              loading={'lazy'}
              onLoad={() => {
                onMediaLoaded()
                document.getElementById('spinner').style.display = 'none'
              }}
              scrolling={'no'}
              sandbox={'allow-scripts allow-same-origin'}
              src={interactiveUrl} />
    </div>
  )
}
