import { useRef, useState } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper } from 'swiper/react'
import { settings } from 'settings/fetching'
import { useResponsiveSlidesPerGroup } from 'components/swipper-with-navigation/hooks/use-responsive-slides-per-group'
import 'components/swipper-with-navigation/index.scss'
// eslint-disable-next-line
import 'swiper/css'
// eslint-disable-next-line
import 'swiper/css/navigation'

const breakpoints = { 768: { spaceBetween: 24 } }

const useBeforeInitCallback = (prevRef, nextRef) => swiper => {
  swiper.params.navigation.prevEl = prevRef.current
  swiper.params.navigation.nextEl = nextRef.current
}

export const SwiperWithNavigation = ({ children }) => {
  const swiperRef = useRef(null)
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const [slidesPerGroup, setSlidesPerGroup] = useState(settings().home.itemsPerGroup)

  useResponsiveSlidesPerGroup(swiperRef, setSlidesPerGroup)
  SwiperCore.use([Navigation])

  return (<div className={'swiper-with-navigation'}>
    <div className={'swiper-button-prev'} ref={prevRef} />
    <div className={'swiper-button-next'} ref={nextRef} />
    <div className={'swiper-with-navigation-inner'}>
      <Swiper ref={swiperRef} slidesPerView={'auto'}
              navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
              onBeforeInit={useBeforeInitCallback(prevRef, nextRef)}
              slidesPerGroup={slidesPerGroup} spaceBetween={12} breakpoints={breakpoints}>
        {children}
      </Swiper>
    </div>
  </div>)
}
