import { useAccountCollections } from 'modals/account/create-nft/collections/hooks/use-account-collections'
import { useField } from 'formik'
import { useCallback } from 'react'

export const useOnChanged = name => {
  const [, , { setValue }] = useField(name)
  const collections = useAccountCollections()

  return useCallback(option => {
    const selected = collections.find(({ node }) => node.collection === option.value)
    setValue(selected.node)
  }, [collections, setValue])
}
