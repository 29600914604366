import 'components/toasts/transaction-toast/progress/index.scss'
import { useEffect, useState } from 'react'

export const Progress = ({ className, shown }) => {
  const [statusClass, setStatusClass] = useState(shown ? className : 'none')

  useEffect(() => {
    const timeout = setTimeout(() => setStatusClass(className), 100)

    return () => clearTimeout(timeout)
  }, [className, shown])

  return <div className={`toast-progress ${statusClass}`}>
    <div className={'toast-progress-fill'} />
  </div>
}
