import { combineReducers } from 'redux'
import {
  flagCollection, flagNft,
  setNftScamInfo, clearNftScamInfo, clearNftReport,
  setCollectionScamInfo, clearCollectionScamInfo,
  addFeaturedCollection, removeFeaturedCollection,
  getBlacklistedCollections, addBlacklistedCollection, removeBlacklistedCollection,
  reportCollection, clearReportCollection
} from 'admin/slices'

export const reducer = combineReducers({
  flagNft: flagNft.reducer,
  flagCollection: flagCollection.reducer,
  setNftScamInfo: setNftScamInfo.reducer,
  clearNftScamInfo: clearNftScamInfo.reducer,
  clearNftReport: clearNftReport.reducer,
  setCollectionScamInfo: setCollectionScamInfo.reducer,
  clearCollectionScamInfo: clearCollectionScamInfo.reducer,
  addFeaturedCollection: addFeaturedCollection.reducer,
  removeFeaturedCollection: removeFeaturedCollection.reducer,
  getBlacklistedCollections: getBlacklistedCollections.reducer,
  addBlacklistedCollection: addBlacklistedCollection.reducer,
  removeBlacklistedCollection: removeBlacklistedCollection.reducer,
  reportCollection: reportCollection.reducer,
  clearReportCollection: clearReportCollection.reducer,
})
