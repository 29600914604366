import { useOnChange } from 'collections/actions/on-sale-filter/hooks/use-on-change'
import { useIsOnSale } from 'collections/actions/on-sale-filter/hooks/use-is-on-sale'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'collections/actions/on-sale-filter/index.scss'

export const OnSaleFilter = () => {
  const onChange = useOnChange()
  const isChecked = useIsOnSale()

  return <div className={'on-sale-filter'}>
    <label className={'on-sale-filter-inner'} htmlFor={'onSaleFilterInput'}>
      <div className={'form-check form-switch'}>
        <label className={'form-check-label'} htmlFor={'onSaleFilterInput'}>On Sale</label>
        <div className={'form-check-input-wrapper'}>
          <input className={'form-check-input black'} type={'checkbox'} id={'onSaleFilterInput'} onChange={onChange}
                 checked={isChecked} />
          <FontAwesomeIcon icon={faCheck} className={'form-check-input-on'} />
          <FontAwesomeIcon icon={faTimes} className={'form-check-input-off'} />
        </div>
      </div>
    </label>
  </div>
}
