import { useDispatch } from 'react-redux'
import { useEndCursor } from 'account/tabs/assets/auctions/hooks/use-end-cursor'
import { useCallback } from 'react'
import { actions } from 'account/tabs/assets/auctions/slices/get'
import { useUpdateLoadings } from 'account/tabs/assets/hooks/use-update-loadings'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { payload } from 'account/tabs/assets/auctions/hooks/payload'
import { subdomainTabs } from 'account/tabs/subdomain-tabs'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const dispatch = useDispatch()
  const cursor = useCursor(customCursor)
  const viewerAddress = useViewerAddress()
  const { address } = useGetAccountInfo()
  const isOwner = viewerAddress === address
  const updateLoadings = useUpdateLoadings(subdomainTabs.auctions)
  const marketplaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    const shouldCall = viewerAddress && marketplaceKey
    updateLoadings()
    shouldCall && dispatch(actions.attempt(payload({ viewerAddress, cursor, isOwner, marketplaceKey })))
  }, [updateLoadings, viewerAddress, dispatch, cursor, isOwner, marketplaceKey])
}
