import { useValidationSchema } from 'components/forms/create-sft-auctions/variable-form/hooks/use-validation-schema'

export const useConfig = ({ onSubmit, initialValues }) => ({
  initialValues: useValidationSchema().cast(initialValues),
  validationSchema: useValidationSchema(),
  validateOnChange: true,
  validateOnBlur: true,
  validateOnMount: false,
  onSubmit,
})
