import { faCube } from '@fortawesome/pro-light-svg-icons'
import { Empty as EmptyState } from 'components/empty'
import { useItems } from 'account/tabs/assets/collected/hooks/use-items'
import { useIsLoading } from 'account/tabs/assets/collected/hooks/use-is-loading'
import { isEmpty } from 'lodash'
import { useHasMore } from 'account/tabs/assets/collected/hooks/use-has-more'

const stateProps = {
  icon: faCube,
  message: `No collected assets yet.`,
}

const hasReachedEnd = (loading, hasMore) => !loading && !hasMore

export const Empty = () => {
  const isLoading = useIsLoading()
  const items = useItems()
  const hasMore = useHasMore()
  const reachedEnd = hasReachedEnd(isLoading, hasMore)

  return isEmpty(items) && reachedEnd && <EmptyState {...stateProps} />
}
