import { get } from 'lodash'
import { usePrimarySale } from 'holoride-campaign/hooks/use-primary-sale'
import { useSetTimer } from 'holoride-campaign/action/hooks/use-set-timer'

export const Behaviour = () => {
  const primarySale = usePrimarySale()
  const endClaim = get(primarySale, 'saleTime.endClaim')
  const endSale = get(primarySale, 'saleTime.endSale')
  const startClaim = get(primarySale, 'saleTime.startClaim')
  const startSale = get(primarySale, 'saleTime.startSale')

  useSetTimer(endClaim)
  useSetTimer(endSale)
  useSetTimer(startClaim)
  useSetTimer(startSale)

  return ''
}
