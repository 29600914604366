import { useEffect } from 'react'
import { useGetPercentage } from 'filters/components/multi-range/hooks/use-get-percentage'

export const useUpdateTooltip = (props, tooltipRef) => {
  const getPercentage = useGetPercentage({ max: props.max, min: props.min })

  useEffect(() => {
    const percentage = getPercentage(props.value)

    if (tooltipRef.current) {
      tooltipRef.current.style.left = `calc(${percentage}% + 5px)`
    }
  }, [getPercentage, tooltipRef, props.value])
}
