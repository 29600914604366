export const Label = ({ name, label, children }) => {
  const shouldDisplay = label || children

  if (shouldDisplay) {
    return <label className={'form-label label-field'} htmlFor={name}>
      {label}
      {children}
    </label>
  } else {
    return ''
  }
}
