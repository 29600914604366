import { useCloseModal } from 'components/bootstrap-modal/hooks/use-close-modal'
import { useSetPreference } from 'preferences/hooks/use-set-preference'

import { useCallback } from 'react'

export const useOnCancel = id => {
  const closeModal = useCloseModal(id)
  const setPreferences = useSetPreference()

  return useCallback(() => {
    closeModal()
    setPreferences({ [id]: true })
  }, [closeModal, id, setPreferences])
}
