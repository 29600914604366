import './loading-spinner.scss'

const makeClassName = className => (
  `loading-spinner d-flex justify-content-center align-items-center p-5 ${className || ''}`
)

export const LoadingSpinner = ({ text = 'Loading...', loader = null, className }) => (
  <div className={makeClassName(className)} ref={loader}>
    <div className={'loading-spinner-inner d-flex justify-content-center align-items-center'}>
      <div className={'loading-spinner-color'} />
      <div className={'loading-spinner-text'}>{text}</div>
    </div>
  </div>
)
