import { useMemo } from 'react'
import { NotLoggedIn } from 'feed/not-logged-in'
import { useIsLoggedIn } from 'common/hooks/use-is-logged-in'

export const useIsPrinceCharming = () => {
  const { isLoggedIn } = useIsLoggedIn()

  return useMemo(() => (isLoggedIn), [isLoggedIn])
}

export const MegaAuthorized = ({ children }) => {
  const isPrinceCharming = useIsPrinceCharming()

  return isPrinceCharming ? children : <NotLoggedIn />
}
