import videojs from 'video.js'
import { useEffect } from 'react'
import { useContext } from 'components/player/context'

const showHideSafariFix = (jsPlayer, container) => {
  jsPlayer.on('abort', s => {
    container.current.style.setProperty('display', `none`)
  })
  jsPlayer.on('loadedmetadata', () => {
    container.current.style.setProperty('display', `block`)
  })
}

export const useSetup = () => {
  const { player, container, options, onReady, sources } = useContext()

  useEffect(() => {
    if (player.current) return

    const jsPlayer = videojs(container.current, options, onReady)

    showHideSafariFix(jsPlayer, container)
    player.current = jsPlayer
  }, [player, container, options, onReady, sources])
}
