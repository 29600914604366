import { useField } from 'formik'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CheckSwitchField = ({ name, label, disabled }) => {
  const [field, meta, { setTouched }] = useField(name)

  return <div className={'d-flex justify-content-between m-2 form-check form-switch p-0'}>
    <label className={'form-check-label'} htmlFor={name}>{label}</label>
    <div className={'form-check-input-wrapper'}>
      <input className={'form-check-input clickable'}
             type={'checkbox'}
             id={name} name={name}
             disabled={disabled}
             onChange={field.onChange}
             onInput={() => setTouched()}
             value={field.value}
             checked={field.value}
             onBlur={field.onBlur} />
      <FontAwesomeIcon icon={faCheck} className={'form-check-input-on'} />
      <FontAwesomeIcon icon={faTimes} className={'form-check-input-off'} />
      {meta.touched && meta.error && <div className={'text-warning'}>{meta.error}</div>}
    </div>
  </div>
}
