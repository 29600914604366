import { useMenuLinks } from 'sidebar/my-wallet/menu/hooks/use-menu-links'
import { Link } from 'sidebar/my-wallet/menu/link'

import 'sidebar/my-wallet/menu/menu.scss'

export const Menu = () => {
  const menuLinks = useMenuLinks()

  return <ul className={`sidebar-menu list-unstyled text-center`}>
    {menuLinks.map(link => <Link key={`sidebar-link-${link.label}`} {...link} />)}
  </ul>
}
