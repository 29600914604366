import { createContext, useContext, useState } from 'react'
import { useField } from 'formik'
import { useDropzoneInit } from './hooks/use-dropzone-init'

export const Context = createContext()

export const useDropzoneContext = () => useContext(Context)

export const useDefaultContext = props => {
  const { name } = props
  const [field, meta, { setValue, setTouched }] = useField({ name, type: 'file' })
  const [blob, setBlob] = useState(false)
  const dropzone = useDropzoneInit({ setBlob, setValue, meta, setTouched, ...props })

  return { blob, setBlob, field, meta, ...props, ...dropzone }
}
