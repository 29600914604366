import { shouldUseMinBid } from 'common/should-use-min-bid'
import { Price } from 'nft-page/auction/price'
import { get } from 'lodash'
import 'nft-page/auction/current-bid/index.scss'

export const CurrentBid = ({ auction }) => {
  const title = shouldUseMinBid(auction) ? 'Listing Price' : 'Highest Bid'
  const iconUrl = get(auction, 'marketplace.iconUrl')

  return <div className={'nft-current-bid'}>
    <p className={'nft-current-bid-title'}>{title}</p>
    <div className={'d-flex align-items-center'}>
      {iconUrl ? <img className={'nft-current-bid-image'} src={iconUrl} alt={'...'} /> : ''}
      <Price />
    </div>
  </div>
}
