import { Row } from 'components/row'
import { Container } from 'components/container'
import { NerdsForm } from 'components/forms/nerds-form'
import { config } from 'components/forms/create-nft-form/config'

const useProperties = props => ({
  loading: props.loading,
  config: config({
    onSubmit: props.onSubmit,
    initialValues: props.initialValues,
    initialTouched: { file: true, royalties: true, collection: { collection: true } },
    selectableCollection: props.selectableCollection,
  }),
  submit_errors: props.submitErrors,
  callbackUrl: props.callbackUrl,
})

export const Layout = props => <NerdsForm {...useProperties(props)}>
  <Container>
    <Row className={'justify-content-center py-4 py-xl-5'}>
      {props.children}
    </Row>
  </Container>
</NerdsForm>

