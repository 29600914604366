import { Username } from 'components/username'
import { UserImage } from 'components/user-image'
import { FlexRow } from 'components/flex-row'
import PropTypes from 'prop-types'
import defaultAvatar from 'common/default-avatar'
import 'components/user-details/index.scss'
import { HistoryAction } from 'components/user-details/history-action'
import { Date } from 'components/user-details/date'
import { FlexColumn } from 'components/flex-column'
import { colors } from 'components/username/colors'
import { useIsSubdomainDisabledClass } from 'common/hooks/use-is-subdomain-disabled-class'

export const UserDetails = ({ herotag, action, image, date, address, padding, showTopBidderBadge }) => {
  const disabledClass = useIsSubdomainDisabledClass()

  return <FlexRow className={`user-details ${disabledClass} align-items-center`}>
    <UserImage image={image} herotag={herotag} address={address} />
    <FlexColumn className={'user-details-right justify-content-start flex-grow-1'}>
      <FlexRow>
        {action && <HistoryAction action={action} />}
        <Username herotag={herotag} address={address} color={colors.primary} padding={padding}
                  showTopBidderBadge={showTopBidderBadge} />
      </FlexRow>
      {date && <Date date={date} />}
    </FlexColumn>
  </FlexRow>
}

UserDetails.defaultProps = {
  herotag: '',
  image: defaultAvatar,
}

UserDetails.prototype = {
  herotag: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}
