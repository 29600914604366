import { useParams } from 'react-router-dom'
import { Bid as PlaceBid } from 'modals/auction/bid/bid'
import { Buy } from 'modals/auction/bid/buy'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'

const useIsPlaceBid = () => {
  const { modalName } = useParams()

  return modalName === 'place-bid'
}

export const Bid = () => {
  const auction = useSelectedAuction()
  const isPlaceBid = useIsPlaceBid()

  return isPlaceBid ? <PlaceBid auction={auction} /> : <Buy auction={auction} />
}
