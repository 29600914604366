import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { BuyCampaignForm } from 'components/forms/buy-campaign-form/index'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { actions } from 'modals/campaign/buy/slices/buy-campaign'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useCampaign } from 'campaign/hooks/use-campaign'
import { useData, useErrors } from 'modals/campaign/buy/hooks/use-data'
import { useIsLoading } from 'modals/campaign/buy/hooks/use-is-loading'
import { useBuyCampaign } from './hooks/use-buy-campaign'

export const Form = ({ minterAddress, campaignId, tier }) => {
  const data = useData()
  const loading = useIsLoading()
  const errors = useErrors()
  const buyCampaign = useBuyCampaign(minterAddress, campaignId, tier)
  const callbackUrl = useCloseLayerLocation()

  const campaign = useCampaign()
  const actionIdentifier = createActionIdentifier(campaign, actionTypes.buyCampaign)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <BuyCampaignForm onSubmit={buyCampaign} submitErrors={errors} loading={loading} campaign={campaign}
                          callbackUrl={callbackUrl} initialValues={{ quantity: 1 }} tier={tier} />
}
