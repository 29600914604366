import { gql } from 'graphql-tag'

export const ADD_BLACKLISTED_COLLECTION = gql`
    mutation addBlacklistedCollection($collection: String!) {
        addBlacklistedCollection(collection: $collection)
    }
`

export const REMOVE_BLACKLISTED_COLLECTION = gql`
    mutation removeBlacklistedCollection($collection: String!) {
        removeBlacklistedCollection(collection: $collection)
    }
`
