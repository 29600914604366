import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions as tagActions } from 'trending/slices/tag'
import { actions as listActions } from 'trending/slices/list'
import { actions as trendingActions } from 'trending/slices/trending'
import { actions as cursorActions } from 'trending/slices/cursor'
import { useTag } from 'trending/hooks/use-tag'

export const useSetTag = () => {
  const dispatch = useDispatch()
  const tag = useTag()

  return useCallback(newTag => {
    if (newTag !== tag) {
      dispatch(trendingActions.reset())
      dispatch(tagActions.set(newTag))
      dispatch(listActions.reset())
      dispatch(cursorActions.unSet())
    }
  }, [tag, dispatch])
}
