import { gql } from 'graphql-tag'

export const ASSET_HISTORY = gql`
    query assetHistory($filters: AssetHistoryFilter!, $pagination: HistoryPagination) {
      assetHistory(filters: $filters, pagination: $pagination)
  {
      edges{
          __typename
          cursor
          node{
              action
              actionDate
              address
              account{
                  address
                  herotag
                  profile
              }
              itemCount
              price{
                  amount
                  timestamp
                  token
                  usdAmount
              }
              senderAddress
              senderAccount{
                  address
                  herotag
                  profile
              }
              transactionHash
          }
      }
      pageData{
          count
      }
      pageInfo{
          endCursor
          hasNextPage
      }
  }
 }
`
