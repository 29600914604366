import { ActualNftOwner } from 'nft-page/details/other-details/owners/actual-nft-owner'
import { AuctionOwner } from 'nft-page/details/other-details/owners/auction-owner'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'
import { get } from 'lodash'

export const NftOwner = ({ asset }) => {
  const lowestAuction = get(asset, 'lowestAuction', '')
  const owner = get(asset, 'owner')
  const selectedAuction = useSelectedAuction()
  const auction = lowestAuction || selectedAuction

  return auction ? <AuctionOwner auction={auction} /> : <ActualNftOwner owner={owner} />
}
