import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Subtitle } from 'components/forms/nerds-form/subtitle'
import { Price } from 'components/forms/buy-sft-form/fieldsets/price'
import { QuantityField } from 'components/forms/nerds-form/fields/quantity-field'
import { TotalPriceField } from 'components/forms/nerds-form/fields/total-price-field'
import { FinalFeeField } from 'components/forms/nerds-form/fields/final-fee-field'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Errors } from 'components/forms/nerds-form'
import { get } from 'lodash'
import { useOfferFees } from 'components/forms/nerds-form/fields/hooks/use-offer-fees'
import { usePriceValue } from 'components/forms/nerds-form/fields/hooks/use-price-value'

export const Fields = ({ callbackUrl, initialValues, auction, submitErrors, paymentToken }) => {
  const asset = get(auction, 'asset', {})
  const availableTokens = get(auction, 'availableTokens', 0)
  const assetFees = useOfferFees(asset)
  const exampleValue = usePriceValue()

  return <div className={'modal-content'}>
    <Title icon={faGavel} title={'Buy Sft'} margins={'mb-2'} />
    <Subtitle subtitle={`Setting quantity and price for ${initialValues.identifier}`} />
    <Price paymentToken={paymentToken} />
    <QuantityField name={'quantity'} label={'How many to buy?'} pieces={availableTokens} />
    <TotalPriceField auction={auction} paymentToken={paymentToken} />
    <FinalFeeField asset={asset} labelText={'Owner will receive'} assetFees={assetFees} exampleValue={exampleValue}
                   paymentToken={paymentToken} />
    <DefaultActions callbackUrl={callbackUrl} label={'Buy'} />
    <Errors values={submitErrors} />
  </div>
}
