import 'home/subscribe-newsletter/index.scss'
import { Form, Formik, Field } from 'formik'
import { useOnSubmit } from 'home/subscribe-newsletter/hooks/use-on-submit'

/* eslint-disable */
export const SubscribeNewsletter = () => {
  const { onSubmit, setStatus, status } = useOnSubmit()

  const onFormChange = () => {
    setStatus({})
  }

  return <div className={'container'}>
    <div className={'container'}>
      <div className={'subscribe-newsletter row align-items-center'}>
        <div className={'col-sm-12 col-lg-6'}>
          <div className={'subscribe-newsletter-title'}>
            Stay involved and never miss an update!
          </div>
        </div>
        <div className={'subscribe-newsletter-form col-sm-12 col-lg-6'}>
          <Formik onSubmit={onSubmit} initialValues={{ email: '' }}>
            <div>
              <Form onChange={onFormChange}>
                <Field name={'email'} type={'email'} placeholder={'Subscribe to newsletter'} />
                <button disabled={status.status === 'success'}
                        type={'submit'} data-testid={'submit-form'}
                        className={'btn btn-primary'}>
                  Subscribe
                </button>
              </Form>
            </div>
          </Formik>
        </div>
        <div className={'d-flex justify-content-center justify-content-md-end mt-2'}>
          {(status.status === 'error' || status.status === 'success') && <label className={'alert-danger text-right'}>{status.message}</label>}
        </div>
      </div>
    </div>
  </div>
}
