import { SocialLinkField } from './social-link-field'
import { useSocialLinksSelector } from 'account/profile-social-links/hooks/use-social-links-selector'

export const SocialLinks = () => {
  const data = useSocialLinksSelector()

  return <div className={'mb-3'}>
    {data.map((link, index) => <SocialLinkField key={index} label={link.name} value={link.id} />)}
  </div>
}
