import { useContext } from 'modals/account/dropzone/context'
import { useEffect } from 'react'
import { useResetError } from 'modals/account/dropzone/hooks/use-reset-error'

export const useDisplayExternalError = () => {
  const { externalError, setError } = useContext()
  const resetError = useResetError(setError)

  useEffect(() => {
    if (externalError) {
      setError(externalError)
      resetError()
    }
  }, [externalError, resetError, setError])
}
