import { Media } from 'subdomain/home/media'
import { Collections } from 'subdomain/home/collections'
import { Partners } from 'subdomain/home/partners'
import { Hero } from 'subdomain/home/hero'
import { useAttributesContent } from 'subdomain/home/hooks/use-attributes-content'
import { Roadmap } from 'subdomain/home/roadmap'
import { Campaigns } from 'subdomain/home/campaigns'
import { Awards } from 'subdomain/home/awards'
import { Columns } from 'subdomain/home/columns'
import { Faqs } from 'subdomain/home/faqs'
import { Column } from 'subdomain/home/column'
import { Overview } from 'subdomain/home/overview'
import { Boxes } from 'subdomain/home/boxes'
import { Video } from 'subdomain/home/video'

const components = {
  'sections.media': Media,
  'sections.collections': Collections,
  'sections.partners': Partners,
  'sections.hero': Hero,
  'sections.roadmap': Roadmap,
  'sections.campaign': Campaigns,
  'sections.awards': Awards,
  'sections.columns': Columns,
  'sections.column': Column,
  'sections.overview': Overview,
  'sections.boxes': Boxes,
  'sections.faqs': Faqs,
  'sections.video': Video,
}

export const Content = () => {
  const content = useAttributesContent()

  return content.map(({ __component, ...props }, key) => {
    const content = components[__component]

    return content ? <div key={key} className={'subdomain-home-component'}>{content({ data: props, key })}</div> : ''
  })
}
