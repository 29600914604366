import { Failure } from 'failure'
import { List } from 'nft-page/details/history/list'
import { LoadMore } from 'nft-page/details/history/load-more'
import { TabContent } from 'components/tabs/tab-content'

export const History = () => <TabContent id={'nft-page-history-tab-content'}>
  <Failure.Partial id={'asset.history'}>
    <List />
    <LoadMore />
  </Failure.Partial>
</TabContent>
