import { graph } from 'network'
import { GET_EXPLORE_STATS } from 'repositories/explore/queries/explore-stats'
import { GET_EXPLORE_NFTS_STATS } from 'repositories/explore/queries/explore-nfts-stats'
import { GET_EXPLORE_COLLECTIONS_STATS } from 'repositories/explore/queries/explore-collections-stats'

export const explore = {
  getStats: () => graph().query({ query: GET_EXPLORE_STATS }),
  getNftsStats: () => graph().query({ query: GET_EXPLORE_NFTS_STATS }),
  getCollectionsStats: () => graph().query({ query: GET_EXPLORE_COLLECTIONS_STATS }),
}
