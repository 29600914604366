import { useNext } from './use-next'
import { useEffect } from 'react'

export const useFetchCampaigns = () => {
  const next = useNext()

  useEffect(() => {
    next()
  }, []) // eslint-disable-line
}
