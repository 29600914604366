import { Self } from 'components/button/self'
import { getButtonClassName } from 'components/button/get-button-class-name'
import 'components/button-secondary/index.scss'

export const ButtonSecondary = props => {
  const { icon, isIconRight = false, onClick, to = '/', className, children, disabled } = props
  const buttonClass = getButtonClassName({ buttonType: 'secondary', className, icon, isIconRight })

  return (
    <Self icon={icon} onClick={onClick} isIconRight={isIconRight} to={to} className={buttonClass} disabled={disabled}>
      {children}
    </Self>
  )
}
