import { useCallback } from 'react'
import { useOpenModal } from 'components/bootstrap-modal/hooks/use-open-modal'

export const useOnFiltersClick = () => {
  const openModal = useOpenModal('traits')

  return useCallback(() => {
    openModal()
  }, [openModal])
}
