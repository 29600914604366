import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { findOption } from 'components/dropdown-tokens/find-option'

export const useSetSelected = ({ setSelected, options }) => {
  const { search } = useLocation()
  const token = useFilterValue('token')

  useEffect(() => {
    setSelected(findOption(options, token) || options[0])
  }, [token, options, search, setSelected])
}
