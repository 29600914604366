import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { actions } from 'modals/account/edit/privacy/slices/update-privacy'
import { identity } from 'repositories/identity'

export const updatePrivacy = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => identity().self.updatePrivacy({ privacy: payload })
  .pipe(mergeMap(result => of(result.data)))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = errors => of(actions.failure(errors))
