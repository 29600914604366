import { Behaviour } from 'modals/auction/withdraw/behaviour'
import { Form } from 'modals/auction/withdraw/form'
import { BootstrapModal } from 'components/bootstrap-modal'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'

export const Withdraw = () => {
  const auction = useSelectedAuction()

  return <BootstrapModal id={'withdraw'} title={'Withdraw'}>
    <Behaviour />
    <Form auction={auction} />
  </BootstrapModal>
}
