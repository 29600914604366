import { CreateNftAuctionForm } from 'components/forms/create-nft-auction-form'
import { useCreateSingleAuction } from 'modals/auction/start/hooks/use-create-single-auction'
import { useIsLoading } from 'modals/auction/start/hooks/use-is-loading'
import { useErrors } from 'modals/auction/start/hooks/use-errors'
import { BootstrapModal } from 'components/bootstrap-modal'
import { actions } from './slices/create-auction'
import { useData } from './hooks/use-data'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'

const initialValues = ({ asset, paymentToken }) => ({
  identifier: asset.identifier,
  paymentToken: paymentToken.symbol,
  maxOneSftPerPayment: false,
  quantity: '1',
})

const useProps = (asset, paymentToken, errors) => ({
  onSubmit: useCreateSingleAuction(asset, paymentToken),
  submitErrors: errors,
  loading: useIsLoading(),
  callbackUrl: useCloseLayerLocation(`/nfts/${asset.identifier}`),
  initialValues: initialValues({ asset, paymentToken }),
  asset,
  paymentToken,
})

export const StartNftAuction = ({ asset }) => {
  const data = useData()
  const actionIdentifier = createActionIdentifier(asset, actionTypes.startAuction)
  const errors = useErrors()
  const paymentToken = useFirstAcceptedPaymentToken()

  useOnSentPayload({
    data,
    callbackUrl: useCloseLayerLocation(`/nfts/${asset.identifier}`),
    resetAction: actions.reset,
    actionIdentifier,
  })
  const props = useProps(asset, paymentToken, errors)

  return <BootstrapModal id={'create-nft-auction'}>
    {asset.identifier && <CreateNftAuctionForm {...props} />}
  </BootstrapModal>
}
