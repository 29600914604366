import { uniqBy } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'

const initialState = { data: [] }

const append = ({ data }, { payload }) => ({ data: uniqBy([...data, ...payload], 'node.identifier') })

const reset = () => initialState

export const list = createSlice({
  name: 'account/collected/list',
  initialState,
  reducers: {
    append,
    reset,
  },
})

export const { actions } = list
