import { QuantityField } from 'components/forms/nerds-form/fields/quantity-field'
import { PriceField } from 'components/forms/nerds-form/fields/price-field'
import { DateTimeField } from 'components/forms/nerds-form/fields/date-time-field'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { DependentField } from 'components/forms/nerds-form/fields/dependent-field'
import 'components/forms/create-sft-auctions/bundle-form/fields.scss'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { Errors } from 'components/forms/nerds-form'
import { FinalFeeField } from 'components/forms/nerds-form/fields/final-fee-field'
import { useAssetFees } from 'components/forms/nerds-form/fields/hooks/use-asset-fees'
import { useMinOrMaxBidValue } from 'components/forms/nerds-form/fields/hooks/use-min-or-max-bid-value'

export const Fields = ({ callbackUrl, asset, assetOwner, submitErrors, paymentToken }) => {
  const { resetForm } = useFormikContext()
  const assetFees = useAssetFees(asset)
  const exampleValue = useMinOrMaxBidValue()

  useEffect(() => resetForm, [resetForm])

  return <div className={'bundle-auction-form'}>
    <QuantityField name={'quantity'} label={'How many in bundle?'} pieces={assetOwner.balance} />
    <PriceField name={'minBid'} label={'Minimum bid'} paymentToken={paymentToken} />
    <DependentField name={'maxBid'} dependentName={'checkedMaxBid'} dependentLabel={'Set a Buy Now price?'}>
      <PriceField name={'maxBid'} label={'Buy now price'} paymentToken={paymentToken} />
    </DependentField>
    <DateTimeField name={'deadline'} label={'Expires on'} disablePast />
    <FinalFeeField asset={asset} assetFees={assetFees} exampleValue={exampleValue} labelText={'You will receive'}
                   paymentToken={paymentToken} />
    <DefaultActions callbackUrl={callbackUrl} label={'Start auction'} />
    <Errors values={submitErrors} />
  </div>
}
