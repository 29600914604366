import { useEffect } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useResetList } from 'account/tabs/assets/auctions/behaviour/hooks/use-reset-list'
import { useResetAuctions } from 'account/tabs/assets/auctions/behaviour/hooks/use-reset-auctions'

export const useResetState = () => {
  const resetAuctions = useResetAuctions()
  const resetList = useResetList()
  const address = useViewerAddress()

  useEffect(() => {
    resetAuctions()
    resetList()
  }, [resetAuctions, resetList, address])
}
