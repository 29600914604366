import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { explore as repository } from 'repositories/payment-tokens'
import { actions } from 'collections/payment-tokens/slices/get'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.getCollectionPaymentTokens(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = payload => from([
  actions.failure(payload),
])
