import { InnerBehaviour } from 'collections/inner-behaviour'
import { DataCard } from 'components/data-card'
import { Auctions } from 'collections/auctions'
import { Assets } from 'collections/assets'
import { useCollectionProps } from 'collections/hooks/use-collection-props'
import { Stats } from './stats'

export const CollectionContent = () => <div className={'collection-page'}>
  <InnerBehaviour />
  <DataCard {...useCollectionProps()}>
    <Stats />
    <Assets />
    <Auctions />
  </DataCard>
</div>
