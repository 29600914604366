import { useGetNotifications } from 'notifications/account/hooks/use-get-notifications'
import { Item } from 'notifications/account/item'
import { Empty } from '../../components/empty'
import { isEmpty } from 'lodash'
import { Title } from 'notifications/account/title'
import { ReactComponent as Bell } from 'startup/assets/notification-empty-bell.svg'

const emptyProps = {
  title: 'No notifications yet!',
  message: 'We’ll notify you once we have something for you.',
  illustration: <Bell />,
}

export const List = () => {
  const notifications = useGetNotifications()

  return (
    <div className={'account-notifications-list dropdown-menu dropdown-menu-end'}
         aria-labelledby={'accountNotificationDropdown'}>
      <div className={'account-notifications-list-inner'}>
        <Title />
        <div className={'account-notifications-list-scroll'}>
          {notifications.map(({ node }, index) => <Item key={index} {...node} />)}
          {isEmpty(notifications) && <div className={'p-2'}>
            <Empty {...emptyProps} />
          </div>}
        </div>
      </div>
    </div>
  )
}
