import { gql } from 'graphql-tag'

export const OWNERS = gql`
    query owners($filters: OwnersFilters!, $pagination: ConnectionArgs) {
        owners(filters: $filters, pagination: $pagination) {
            edges {
                cursor
                node{
                    account{
                        address
                        herotag
                        profile
                    }
                    address
                    balance
                    identifier
                }
            }
            pageData{
                count
            }
            pageInfo{
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
