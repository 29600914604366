import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: '',
  input: '',
  errors: [],
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true, errors: [] })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false, errors: [] })

const failure = (state, { payload }) => ({ ...state, errors: payload, loading: false })

const reset = () => initialState

export const updateSocialLinks = createSlice({
  initialState,
  name: 'modals/account/edit/updateSocialLinks',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = updateSocialLinks
