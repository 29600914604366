import { useField } from 'formik'
import { Label } from 'components/forms/nerds-form/fields/label'

export const CheckSwitchField = ({ name, label, onChange }) => {
  const [field, meta] = useField(name)

  return <div className={'d-flex justify-content-between align-items-center form-check form-switch switch-field'}>
    <Label className={'form-label label-field'} name={name}>{label}</Label>
    <div>
      <input className={'form-check-input clickable'}
             type={'checkbox'}
             id={name}
             name={name}
             onChange={onChange}
             value={field.value}
             checked={field.value}
             onBlur={field.onBlur} />
      {meta.touched && meta.error && <div className={'text-warning'}>{meta.error}</div>}
    </div>
  </div>
}
