import { settings } from '../../../../../settings/fetching'
import { useEndCursor } from './use-end-cursor'
import { useCmsMarketplaceKey } from '../../../../../subdomain/cms-content/hooks/use-cms-marketplace-key'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const usePayload = customCursor => {
  const cursor = useCursor(customCursor)
  const marketplaceKey = useCmsMarketplaceKey()

  return {
    pagination: { first: settings().listRequestSize, after: cursor },
    filters: { marketplaceKey },
  }
}
