import 'collections/style.scss'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Seo } from 'components/seo'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Content } from 'collections/content'
import { Behaviour } from 'collections/behaviour'
import { LoadingWrapper } from 'collections/loading-wrapper'
import { TraitsModal } from 'modals/traits'
import { useCollectionTraits } from 'collections/hooks/use-collection-traits'

const namePath = 'collections.collections.data.edges[0].node.name'
const imagePath = 'collections.collections.data.edges[0].node.collectionAsset.assets[0].thumbnailUrl'

const useSeoProps = () => (
  {
    title: useSelector(state => get(state, namePath)),
    image: useSelector(state => get(state, imagePath)),
  })

export const Collections = () => {
  const traits = useCollectionTraits()

  return <>
    <Seo {...useSeoProps()} />
    <ScrollToTopOnMount />
    <Behaviour />
    <LoadingWrapper>
      <Content />
    </LoadingWrapper>
    <TraitsModal traits={traits} />
  </>
}
