import { get } from 'lodash'
import { useAssetTags } from 'tags/hooks/use-asset-tags'
import { useFilterValue } from '../../filters/hooks/use-filter-value'

const useGetTagDisplay = tag => {
  const tags = useAssetTags()
  const registeredTag = tags.find(item => item.value === tag)

  return get(registeredTag, 'label') || tag
}

export const useSeoProps = () => {
  const tagsFilter = useFilterValue('tag')
  const tagDisplay = useGetTagDisplay(tagsFilter)

  return { title: tagsFilter ? `${tagDisplay} NFTs` : 'Explore' }
}
