import { useFetchCollection } from 'collections/hooks/use-fetch-collection'
import { useFetchCollectionStats } from 'collections/stats/hooks/use-fetch-collection-stats'
import { useFetchPaymentTokens } from 'collections/payment-tokens/hooks/use-fetch-payment-tokens'

export const Behaviour = () => {
  useFetchCollectionStats()
  useFetchPaymentTokens()
  useFetchCollection()

  return ''
}
