import { faExternalLink } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { settings } from 'settings/fetching'

const getBaseUrl = () => settings().explorer

export const buildUrl = exploreRoute => `${getBaseUrl()}${exploreRoute}`

export const ExploreLink = ({ exploreLink }) => exploreLink && <div>
  <a href={buildUrl(exploreLink)} target={'_blank'} rel={'noreferrer'}>
    <FontAwesomeIcon icon={faExternalLink} color={'#838C9A'} />
  </a>
</div>
