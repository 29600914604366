import { combineReducers } from 'redux'

import { createAuction as start } from 'modals/auction/start'
import { endAuction as end } from 'modals/auction/end'
import { claimAuction as claim } from 'modals/auction/claim'
import { buySft } from 'modals/auction/buy-sft/slices/buy-sft'
import { withdraw } from 'modals/auction/withdraw/slices/withdraw'
import { placeBid } from 'modals/auction/bid/slices/place-bid'

export const reducers = combineReducers({
  start: start.reducer,
  end: end.reducer,
  claim: claim.reducer,
  buySft: buySft.reducer,
  withdraw: withdraw.reducer,
  placeBid: placeBid.reducer,
})
