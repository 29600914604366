import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { actions } from 'auction/get/slices/count'
import { mergeMap, catchError } from 'rxjs/operators'
import { auctions as repository } from 'repositories/auctions'

export const count = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.count(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = errors => of(actions.failure(errors))
