import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import 'components/forms/nerds-form/fields/price-field/balance/index.scss'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { get } from 'lodash'
import { useMyToken } from 'my-tokens/hooks/use-my-token'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const Balance = () => {
  const { account } = useGetAccountInfo()
  const formatEgldBalance = account.balance !== '...' && denominateAmount(account.balance, 18, true)
  const acceptedPaymentToken = useFirstAcceptedPaymentToken()
  const identifier = get(acceptedPaymentToken, 'identifier')
  const { balance, decimals } = useMyToken(identifier)
  const formatTokenBalance = denominateAmount(balance, decimals, true)

  return <div className={'d-flex justify-content-between'}>
    <small className={'price-field-balance w-100 text-end'}>
      {identifier !== 'EGLD' ? formatTokenBalance : formatEgldBalance}
    </small>
  </div>
}
