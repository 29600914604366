import { faExternalLink } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import './link.scss'

const makeProps = marketplace => ({
  className: 'market-link-button btn btn-primary btn-lg',
  target: '_blank',
  rel: 'noreferrer',
  href: get(marketplace, 'url', ''),
})

export const Link = ({ marketplace }) => {
  const marketplaceName = get(marketplace, 'name', '')

  return <a {...makeProps(marketplace)}>
    <FontAwesomeIcon icon={faExternalLink} />
    {' '}
    {`View `}
    <span className={'d-none d-sm-inline'}>auction</span>
    {` on ${marketplaceName}`}
  </a>
}

