import { gql } from 'graphql-tag'

export const SET_COLLECTION_ROLES = gql`
    mutation setRoles($input:  SetNftRolesArgs!) {
        setRoles(input:$input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
