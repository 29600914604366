import { faGavel } from '@fortawesome/free-solid-svg-icons'
import { Title } from 'components/forms/nerds-form/title'
import { TabsContent } from 'modals/auction/start/tabs-content'
import { TabsHeader } from 'modals/auction/start/tabs-header'
import { BootstrapModal } from 'components/bootstrap-modal'

export const StartSftAuction = ({ asset, assetOwner }) => <BootstrapModal id={'create-sft-auction'}>
  <div className={'modal-content'}>
    <Title icon={faGavel} title={'Start an auction'} />
    <TabsHeader />
    {asset.identifier && <TabsContent asset={asset} assetOwner={assetOwner} />}
  </div>
</BootstrapModal>
