import 'components/loading-cards/index.scss'
import { Card } from 'components/loading-cards/card'

const makeArray = length => Array.from({ length })

export const LoadingCards = props => <div className={'loading-cards'} ref={props.loader}>
  <div className={'row'}>
    {makeArray(props.ghostCardNumber).map((_, index) => <Card key={index} {...props} />)}
  </div>
</div>

