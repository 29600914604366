import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { FreeMode } from 'swiper'
import { Tab } from 'modals/auction/start/tabs-header/tab'
import { ReactComponent as SingleIcon } from 'startup/assets/single-icon.svg'
import { ReactComponent as BundleIcon } from 'startup/assets/bundle-icon.svg'
import { ReactComponent as VariableIcon } from 'startup/assets/variable-icon.svg'
import 'modals/auction/start/tabs-header/index.scss'

SwiperCore.use([FreeMode])

export const TabsHeader = () => <div className={'auctions-tabs-header'}>
  <Swiper freeMode slidesPerView={'auto'} spaceBetween={10} centerInsufficientSlides>
    <SwiperSlide><Tab icon={<SingleIcon />} tab={'single'} title={'Single'} /></SwiperSlide>
    <SwiperSlide><Tab icon={<VariableIcon />} tab={'variable'} title={'Variable'} /></SwiperSlide>
    <SwiperSlide><Tab icon={<BundleIcon />} tab={'bundle'} title={'Bundle'} /></SwiperSlide>
  </Swiper>
</div>
