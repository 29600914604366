import { useEffect } from 'react'

export const useInputEventListeners = (tagifyRef, field) => {
  useEffect(() => {
    if (tagifyRef.current.DOM.originalInput) {
      tagifyRef.current.DOM.originalInput.onblur = field.onBlur
      tagifyRef.current.DOM.originalInput.onchange = field.onChange
    }
  }, [tagifyRef, field])
}
