import { ofType } from 'redux-observable'
import { follow as followSlice } from 'feed/suggestions/slices'
import { followers } from 'repositories/followers'
import { from, of, catchError, mergeMap } from 'rxjs'

export const follow = actions$ => actions$
  .pipe(ofType(followSlice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => followers.post.follow({ address: payload.address })
  .pipe(mergeMap(result => of(result.data)))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = response => from([
  followSlice.actions.success(response),
])

const failure = response => from([
  followSlice.actions.failure(response),
])
