import { createContext, useContext, useState } from 'react'

export const TraitsContext = createContext(null)

export const useTraitsContext = () => useContext(TraitsContext)

export const useTraitsContextValue = () => {
  const [selectedTraits, setSelectedTraits] = useState([])

  return { selectedTraits, setSelectedTraits }
}
