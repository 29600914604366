import { NotFound } from 'not-found'
import { useAddressLoading } from 'viewer/hooks/use-address-loading'
import { useViewerFetching } from 'viewer/hooks/use-viewer-fetching'
import match from '@nerds.sh/js-pattern'
import { usePrivacyLoading } from 'viewer/hooks/use-viewer-privacy-loading'
import { useFound } from 'viewer/hooks/use-found'
import { LoadingSpinner } from 'components/loading-spinner'

export const Viewer = ({ children }) => {
  useViewerFetching()

  const isAddressLoading = useAddressLoading()
  const isPrivacyLoading = usePrivacyLoading()
  const found = useFound()

  return match({})
    .with(!found, () => <NotFound />)
    .with(found, () => children)
    .with(isAddressLoading || isPrivacyLoading, () => <LoadingSpinner className={'flex-grow-1'} />)
    .execute()
}

