import { Content } from 'nft-page/offers/content'
import { RenderIf } from 'account/common/render-if'
import { isEmpty } from 'lodash'
import { LoadMore } from 'nft-page/offers/load-more'

export const SubdomainList = ({ offers }) => <RenderIf isTrue={!isEmpty(offers)}>
  <div className={'nft-page-offers'}>
    <div className={'nft-page-offers-title'}>Offers</div>

    <div className={'nft-page-offers-content'}>
      {offers.map(({ node }, index) => <Content offer={node} key={index} />)}
      <LoadMore />
    </div>
  </div>
</RenderIf>
