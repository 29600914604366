import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { unfollow } from 'feed/suggestions/slices'

export const useOnUnfollow = (setFollowed, account) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(unfollow.actions.attempt({ address: account.address }))
    setFollowed(false)
  }, [account.address, dispatch, setFollowed])
}
