import moment from 'moment'
import { useEffect } from 'react'
import { useContext } from 'components/campaign-status/context'

export const useInitializeTimeLeft = date => {
  const { setTimeLeft } = useContext()

  useEffect(() => {
    if (date) {
      const eventDate = moment(date * 1000)
      setTimeLeft(moment.duration(eventDate.diff(moment(), 'milliseconds', true)))
    }
  }, [date, setTimeLeft])
}
