import 'sidebar/sidebar.scss'
import { useAppContext } from 'startup/context'
import { Connect } from 'sidebar/connect'
import { MyWallet } from 'sidebar/my-wallet'
import { Behaviour } from 'sidebar/behaviour'
import { useSidebarClass } from 'sidebar/hooks/use-sidebar-class'

export const Sidebar = () => {
  const { setSidebarOpened } = useAppContext()
  const closeSidebar = () => setSidebarOpened(false)
  const stopPropagation = evt => evt.stopPropagation()

  return <>
    <Behaviour />
    <div className={`sidebar ${useSidebarClass()} `} onClick={closeSidebar}>
      <div className={'sidebar-inner'} onClick={stopPropagation}>
        <div className={'sidebar-scrollable'}>
          <Connect />
          <MyWallet />
        </div>
      </div>
    </div>
  </>
}
