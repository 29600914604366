import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'collections/assets/slices/get'
import { useParams } from 'react-router-dom'
import { useFilters } from 'filters/hooks/use-filters'
import { toPairs } from 'lodash'

export const useResetState = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()
  const filters = useFilters()
  const hash = JSON.stringify(toPairs(filters.search).length > 0 ? filters.search : filters.background)

  useEffect(() => () => {
    dispatch(actions.reset({}))
  }, [dispatch, identifier, hash])
}
