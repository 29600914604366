import { Title } from './title'
import { Description } from './description'
import { useIsPrinceCharming } from 'auth/mega-authorized'
import { ActionContent } from 'holoride-campaign/action-content'
import { Connect } from 'holoride-campaign/connect'

export const Content = () => {
  const isPrinceCharming = useIsPrinceCharming()

  return <div className={'col-12 col-md-6'}>
    <div className={'px-md-4'}>
      <Title />
      <Description />
      {isPrinceCharming ? <ActionContent /> : <Connect />}
    </div>
  </div>
}
