import './index.scss'
import { get } from 'lodash'

export const Award = ({ award }) => {
  const logoUrl = get(award, 'logo.data.attributes.url', '')

  return <div className={'col-12 col-md-6 col-lg-4 mb-5'}>
    <div className={'award d-flex justify-content-center flex-column h-100 text-center '}>
      <div className={'award-image'}>
        <img src={logoUrl} alt={'...'} />
      </div>
      <h3 className={'award-title'}>{award.title}</h3>
      <div className={'award-icon'} />
    </div>
  </div>
}
