import { ButtonSecondary } from 'components/button-secondary'
import 'components/data-card/header/view-all/index.scss'

export const ViewAll = ({ showAllLink = '', showAllLinkText = 'View all' }) => (
  <ButtonSecondary icon={'arrow-right'}
                   to={showAllLink}
                   isIconRight>
    {showAllLinkText}
  </ButtonSecondary>
)
