import { useMemo } from 'react'
import { useLastCursor } from 'explore-nfts/tabs/collections/hooks/use-last-cursor'
import { useFiltersHash } from 'explore-nfts/tabs/nfts/auctions/hooks/use-filters-hash'
import { buildFilters } from 'explore-nfts/tabs/collections/filters-logic'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const cursor = useCursor(customCursor)
  const filtersHash = useFiltersHash()

  return useMemo(() => {
    const parsed = JSON.parse(filtersHash)

    return buildFilters({ ...parsed, cursor })
  }, [cursor, filtersHash])
}
