import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: '',
  data: '',
  errors: '',
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: payload })

const reset = () => initialState

export const price = createSlice({
  name: 'auction/price',
  initialState,
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = price
