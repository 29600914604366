import { gql } from 'graphql-tag'

export const ISSUE_NFT_COLLECTION = gql`
    mutation IssueNftCollection($input: IssueCollectionArgs!) {
        issueNftCollection(input:$input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
