import * as Yup from 'yup'

import * as paymentAmount from 'components/forms/send-offer-form/hooks/use-amount-schema'
import * as quantity from 'components/forms/send-offer-form/hooks/use-quantity-schema'
import moment from 'moment/moment'

const auctionsSchema = () => Yup.string()
  .when('auctions', {
    is: auctions => auctions.length > 0,
    then: schema => schema.required('Please select an active auction'),
    otherwise: schema => schema.notRequired(),
  })
  .default('')

export const useValidationSchema = (asset, paymentToken) => Yup.object().shape({
  paymentAmount: paymentAmount.useAmountSchema(asset, paymentToken),
  identifier: Yup.string().required(),
  quantity: quantity.useQuantitySchema(asset, paymentToken),
  deadline: Yup.date()
    .required('Required')
    .default(moment().add(1, 'd')
      .toDate())
    .min(new Date(), 'Deadline cannot be in the past'),
  auctionId: auctionsSchema(),
})
