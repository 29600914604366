import { useFollowsCurrent } from 'components/feed-card/hooks/use-follows-current'
import { useHerotag } from 'components/feed-card/hooks/use-herotag'
import { Username } from 'components/username/index'

export const AccountContent = props => {
  const followsCurrent = useFollowsCurrent(props.action.type, props.whom.address)
  const account = <Username herotag={useHerotag(props.whom.herotag)}
                            address={props.whom.address} color={'primary'} openNewLayer />

  return followsCurrent ? 'you' : account
}
