/* eslint-disable max-lines-per-function */
import { createContext, useContext as useReactContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useOnDrop } from 'modals/account/dropzone/hooks/use-on-drop'

export const Context = createContext({})

export const useContext = () => useReactContext(Context)

const accept = 'image/jpeg, image/jpg, image/png'

const makeDropzoneParams = ({ props, onDrop, image }) => ({
  ...props,
  accept,
  multiple: false,
  onDrop,
  noClick: !!image,
})

export const useDefaultContext = ({ maxSize, ...props }) => {
  const [error, setError] = useState('')
  const [image, setImage] = useState(null)
  const [croppedArea, setCroppedArea] = useState(null)
  const onDrop = useOnDrop({ maxSize, setError, setImage, ...props })
  const dropzoneParms = makeDropzoneParams({ props, onDrop, image })
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone(dropzoneParms)

  return {
    id: props.id,
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    fileTypeInfo: props.fileTypeInfo || '',
    error,
    setError,
    externalError: props.errors,
    image,
    setImage,
    onSubmit: props.onSubmit,
    dimensions: props.dimensions,
    croppedArea,
    setCroppedArea,
  }
}
