import { get } from 'lodash'
import 'components/collection-card/length/index.scss'
import { humanize } from 'components/conversion/humanize/index'

const numberOfAssets = collection => humanize(get(collection, 'collectionAsset.totalCount', '0'))

export const Length = ({ collection }) => {
  const isSft = collection?.type === 'SemiFungibleESDT'

  return <div className={'flex-grow-1 text-end d-none d-md-block'}>
    <div className={'collection-card-length'}>
      {numberOfAssets(collection)}
      {' '}
      {isSft ? 'SFTs' : 'NFTs'}
    </div>
  </div>
}

