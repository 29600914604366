import { faStarOfLife } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTotalValue } from 'components/forms/nerds-form/fields/total-price-field/hooks/use-total-value'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import 'components/forms/nerds-form/fields/total-price-field/index.scss'
import { get } from 'lodash'

export const TotalPriceField = ({ auction, paymentToken }) => {
  const totalValue = useTotalValue(auction)
  const price = get(paymentToken, 'priceUsd')
  const symbol = get(paymentToken, 'symbol')
  const decimals = useDenominationDecimals()
  const totalUsdPrice = parseFloat(`${price * totalValue}`).toFixed(decimals)
  const totalUsdText = price ? `(~$${totalUsdPrice})` : ''

  return <div className={'total-price-field d-flex align-items-center mt-2 mb-2'}>
    <FontAwesomeIcon icon={faStarOfLife} className={'total-price-field-image ms-2 me-2'} size={'2x'} />
    <div>
      <div className={'total-price-field-title d-flex justify-content-start'}>
        Total price per pieces
      </div>
      <div className={'d-flex justify-content-start'}>
        {`${totalValue} ${symbol} ${totalUsdText}`}
      </div>
    </div>
  </div>
}
