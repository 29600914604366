import React from 'react'
import { coerceUrlType, SocialLink } from 'components/social-link'
import { Title } from 'nft-page/artist-account/title'
import 'nft-page/artist-account/social-icons/index.scss'

export const SocialIcons = ({ socialLinks }) => <div className={'artist-account-social mb-4'}>
  <Title>Links:</Title>
  <div className={'artist-account-social-icons'}>
    {socialLinks.map(link => <div key={link.type - 1} className={'artist-account-social-icon'}>
      <SocialLink url={link.url || ''} type={coerceUrlType(link)} />
    </div>)}
  </div>
</div>
