import { MenuLink } from './menu-link'
import { useMenuLinks } from 'components/top-navigation/components/menu/hooks/use-menu-links'

const className = 'd-flex navbar-nav'

export const MenuList = () => {
  const links = useMenuLinks()

  return <ul className={`${className} justify-content-start order-0`}>
    {links.map((link, index) => <MenuLink key={index} link={link} />)}
  </ul>
}
