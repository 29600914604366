import { useMarketplaceCutPercentage } from 'marketplaces/hooks/use-marketplace-cut-percentage'
import { useRoyalties } from 'components/forms/nerds-form/fields/hooks/use-royalties'
import { useField } from 'formik'
import { usePriceValue } from 'components/forms/nerds-form/fields/hooks/use-price-value'

export const useOfferFees = asset => {
  const exampleValue = usePriceValue() || 1
  const cutPercentage = parseFloat(useMarketplaceCutPercentage()) / 100
  const royaltiesPercentage = parseFloat(useRoyalties(asset))
  const totalPercentage = cutPercentage + royaltiesPercentage
  const [{ value: quantity }] = useField('quantity')
  const totalFees = exampleValue - (exampleValue * (totalPercentage / 100))

  return quantity ? totalFees * quantity : totalFees
}
