import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-light-svg-icons'

const onClick = () => window.navigator.clipboard.writeText(window.location.href)

export const Copy = () => <button onClick={onClick} className={'dropdown-item'}>
  <FontAwesomeIcon icon={faLink} />
  {' '}
  Copy Link
</button>
