import { useOnClick } from 'auction/actions/claim/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { CurrentBid } from 'nft-page/auction/current-bid'
import 'nft-page/auction/actions/claim/index.scss'
import { WatchableAction } from '../watchable-action'
import { actionTypes } from 'action-statuses/actions-types'

export const ClaimAuction = ({ auction, address }) => {
  const claimClick = useOnClick(auction)
  const onClick = usePermittedAction(claimClick, address)

  return <div className={'col nft-page-auction-inner'}>
    <p className={'claim-auction-text'}>Current auction has reached the deadline or the maximum bid price.</p>
    <CurrentBid auction={auction} />
    <WatchableAction entity={auction} actionType={actionTypes.claim} text={'Claim'} onClick={onClick} />
  </div>
}

ClaimAuction.displayName = 'ClaimAuction'
