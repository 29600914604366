import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'modals/offers/accept/behaviour'
import { useSelectedOffer } from 'nft-page/offers/hooks/use-selected-offer'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { get } from 'lodash'
import { Nft } from 'modals/offers/accept/nft'
import { Sft } from 'modals/offers/accept/sft'

export const Accept = () => {
  const selectedOffer = useSelectedOffer()
  const { asset } = useAssetWithOwner()
  const type = get(asset, 'type')
  const isNft = type === 'NonFungibleESDT'

  return <BootstrapModal id={'accept-offer'} title={'Accept Offer'}>
    <Behaviour />
    {isNft ? <Nft offer={selectedOffer} /> : <Sft offer={selectedOffer} />}
  </BootstrapModal>
}
