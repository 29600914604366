import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'
import { filter, get } from 'lodash'

export const useCmsCampaignCollectionName = () => {
  const data = useCmsData()
  const content = get(data, 'data.data.attributes.content', [])
  const [campaign] = filter(content, { __component: 'sections.campaign' })

  return get(campaign, 'collectionName', '')
}
