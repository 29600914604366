import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Errors } from 'components/forms/nerds-form'
import { QuantityField } from 'components/forms/nerds-form/fields/quantity-field'
import { get } from 'lodash'
import { Field } from 'holoride-campaign/action/buy/field'
import { ConsentField } from 'components/forms/nerds-form/fields/consent-field'
import { ConsentLabel } from 'holoride-campaign/modals/buy/form/consent-label'
import { Price } from 'holoride-campaign/action/buy/field/price'

export const Fields = ({ callbackUrl, submitErrors, primarySale, nftsBought }) => {
  const maxUnitsPerWallet = get(primarySale, 'maxUnitsPerWallet')
  const available = maxUnitsPerWallet - nftsBought

  return <div className={'modal-content'}>
    <Title icon={faGavel} title={'Buy tickets'} />
    {primarySale && <Price primarySale={primarySale} />}
    <Field label={'Max amount:'} value={maxUnitsPerWallet} />
    <Field label={'Tickets bought:'} value={`${nftsBought} / ${maxUnitsPerWallet}`} />
    <div className={'mb-4'} />
    <QuantityField name={'ticketsNumber'} label={'How many tickets?'} pieces={available} />
    <ConsentField label={<ConsentLabel />} name={'consent'} />
    <DefaultActions callbackUrl={callbackUrl} label={'Buy'} />
    <Errors values={submitErrors} />
  </div>
}
