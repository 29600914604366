import PropTypes from 'prop-types'
import { Link } from 'components/asset-card/link'
import { Header } from 'components/asset-card/header'
import { Details } from 'components/asset-card/details'
import { get } from 'lodash'
import { Badges } from 'components/asset-card/badges'
import { useGetLink } from 'common/hooks/use-get-link'
import 'components/asset-card/content.scss'

const assetType = asset => get(asset, 'type', '')

export const Content = props => {
  const isSft = assetType(props.asset) !== 'NonFungibleESDT'
  const sftClass = isSft ? 'is-sft' : ''
  const identifier = get(props, 'asset.identifier')
  const link = useGetLink(props.auction, identifier)

  return <div className={`asset-card card ${sftClass} ${props.className}`}>
    <div className={'asset-card-shadow'} />
    <Link link={link} previewSource={props.previewSource} />
    <Badges {...props} />
    <Header previewSource={props.previewSource} asset={props.asset} auction={props.auction} />
    <Details asset={props.asset} />
  </div>
}

Content.defaultProps = {
  className: '',
  previewSource: 'explore',
  auction: {},
  asset: {},
  link: '',
  endDate: undefined,
  ghost: false,
  topBidder: false,
  showEndedBadge: false,
  outbid: false,
}

Content.propTypes = {
  auction: PropTypes.object,
  asset: PropTypes.object,
  className: PropTypes.string,
  previewSource: PropTypes.string,
  link: PropTypes.string,
  endDate: PropTypes.number,
  ghost: PropTypes.bool,
  topBidder: PropTypes.bool,
  showEndedBadge: PropTypes.bool,
  outbid: PropTypes.bool,
}
