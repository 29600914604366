import './index.scss'
import { LeftSide } from 'featured-pages/components/featured-roadmap/left-side'
import { Quarter } from 'featured-pages/components/featured-roadmap/quarter'
import { RightSide } from 'featured-pages/components/featured-roadmap/right-side'

const steps = [
  {
    buttonText: 'Submit your project',
    buttonType: 'btn-primary',
    title: 'Be a Beacon of Light',
    description: `You can now submit your project to be featured on xSpotlight.com! Before you do, however, please take 
    a close look at the selection criteria below. We aim to identify and recognize the most impactful, well-executed,
     and innovative projects from the MultiversX ecosystem.`,
    link: 'https://submit.xspotlight.com/',
  },
  {
    buttonText: 'Starting in 2 weeks',
    buttonType: 'btn-secondary',
    title: 'Lighthouse Series',
    description: `As we weather the present times, our goal is more important than ever: be the beacon of light for the
     ecosystem, provide a guide during uncertain times, offer helpful resources, sage advice, and unwavering support. 
     All with the aim of better navigating the waters, and emerging more fortified on the other side.`,
  },
  {
    buttonText: 'Coming soon',
    buttonType: 'btn-secondary',
    title: 'Understanding NFTs and Web3 Communities',
    description: `You have no doubt heard the term Web3 thrown around a lot lately, but do you actually understand what
     it means? Or maybe you just need a refresher on its principal building blocks. Have no fear, we got you covered.`,
  }]

export const FeaturedRoadmap = () => <div className={'featured-roadmap-steps'}>
  <div className={'container'}>
    {steps.map((step, index) => <div className={'featured-roadmap-step'} key={index}>
      <div className={'row align-items-center'}>
        <LeftSide data={step} shouldShow={index % 2 === 0} />
        <Quarter />
        <RightSide data={step} shouldShow={index % 2 === 1} />
      </div>
    </div>)}
  </div>
</div>
