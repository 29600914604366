import { useCampaign } from 'holoride-campaign/hooks/use-campaign'
import 'holoride-campaign/description/index.scss'

export const Description = () => {
  const { description } = useCampaign()

  return <div className={'holoride-campaign-description'}>
    {description}
  </div>
}
