import 'components/page-tabs/index.scss'
import { Tabs } from 'components/page-tabs/tabs'

export const PageTabs = ({ tabs = [], baseUrl = '', centered = false, omitFirstTabUrl, isActive, filters = '' }) => {
  const centeredClass = centered ? 'centered' : ''

  return <nav className={`page-tabs nav nav-tabs ${centeredClass}`} role={'tablist'}>
    <div className={'page-tabs-tabs'}>
      <div className={'swiper-gradient-start'} />
      <div className={'swiper-gradient-end'} />
      <Tabs tabs={tabs} isActive={isActive} baseUrl={baseUrl} omitFirstTabUrl={omitFirstTabUrl} />
    </div>
    <div className={'page-tabs-filters'}>
      {filters}
    </div>
  </nav>
}
