import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useViewerUsername } from 'viewer/hooks/use-viewer-username'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useSeoProps = (tab = '') => {
  const username = useViewerUsername()
  const address = useViewerAddress()
  const profile = username || address
  const imagePath = 'account.profile.data.profile.url'
  const descriptionPath = 'account.profile.data.description'

  return {
    title: `${profile}'s ${tab}`,
    image: useSelector(state => get(state, imagePath)),
    description: useSelector(state => get(state, descriptionPath)),
  }
}
