// import { ReactComponent as Logo } from 'startup/assets/egld-price-logo.svg'
import { Price as PriceComponent } from 'components/price'
import 'campaign/infos/price/index.scss'
import { useRealTimeUsdPrice } from 'common/hooks/use-real-time-price'

export const Price = ({ mintPrice }) => {
  const price = {
    amount: mintPrice.amount,
    token: mintPrice.token,
    usdAmount: useRealTimeUsdPrice(mintPrice.amount),
  }

  return <div className={'mint-price d-flex align-items-center'}>
    {/* <Logo className={'mint-price-logo'} /> */}
    <span className={'mint-price-text flex-grow-1'}>Mint Price</span>
    <PriceComponent price={price} />
  </div>
}
