import * as Yup from 'yup'
import { testBalance } from 'components/forms/create-sft-auctions/test-balance'

export const useQuantityTest = () => Yup.number()
  .required('Required')
  .typeError('Should be a number')
  .min(1, 'Should be greater than 0')
  .integer('Should be an integer')
  .test({ name: 'Has enough balance', test: testBalance })
  .default('1')
