import { ofType } from 'redux-observable'
import { followers as slice } from 'account/profile/follow/slices/followers'
import { followers as repo } from 'repositories/followers'
import { from, of, catchError, mergeMap } from 'rxjs'

export const followers = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repo.get.followersCount({ address: payload.address })
  .pipe(mergeMap(result => of(result.data)))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = response => from([
  slice.actions.success(response),
])

const failure = response => from([
  slice.actions.failure(response),
])
