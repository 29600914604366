import { createContext, useContext, useState } from 'react'

export const RouterContext = createContext({})

export const useRouterContext = () => useContext(RouterContext)

export const useRouterContextValue = () => {
  const [layersNames, setLayersNames] = useState([])

  return { layersNames, setLayersNames }
}
