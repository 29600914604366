import { useResetState } from 'account/tabs/assets/bids/behaviour/hooks/use-reset-state'
import { usePartialFailure } from 'account/tabs/assets/bids/behaviour/hooks/use-partial-failure'
import { useTransactionWatch } from 'account/tabs/assets/bids/behaviour/hooks/use-transaction-watch'

export const Behaviour = () => {
  usePartialFailure()
  useResetState()
  useTransactionWatch()

  return ''
}
