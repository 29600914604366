import { useNftScamInfo } from 'nft-page/hooks/nft/use-nft-scam-info'
import { get } from 'lodash'

const getText = scamInfo => {
  const type = get(scamInfo, 'type', '')
  const info = get(scamInfo, 'info', '')

  return type === 'potentialScam' ? `Potential Scam - ${info}` : `Scam - ${info}`
}

export const useScamInfoName = () => {
  const scamInfo = useNftScamInfo()

  return getText(scamInfo)
}
