import { of, mergeMap, tap, from } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'
import { settings } from 'settings/fetching'
import { isExpired } from './is-expired'

export const internalAccessApiKey = 'inspire-art-internal-access-api'

const fetchAccessToken = () => fromFetch(`${settings().extrasApi}/access`, {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
}).pipe(mergeMap(response => from(response.text())))
  .pipe(tap(data => window.localStorage.setItem(internalAccessApiKey, data)))

export const extras = {
  getAccessToken: () => {
    const previousToken = window.localStorage.getItem(internalAccessApiKey)

    return isExpired(previousToken) ? fetchAccessToken() : of(previousToken)
  },
}
