import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from '../slices/profile'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useShowLoading } from 'loading/hooks/use-show-loading'
import { Loading } from 'loading/index'

export const useProfile = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()
  const showLoading = useShowLoading()

  useEffect(() => {
    if (address) {
      showLoading({}, { id: Loading.ID.account.profile, variant: Loading.VARIANT.partial })
      dispatch(actions.attempt(address))
    }
  }, [address, dispatch, showLoading])
}
