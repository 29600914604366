import { useEffect } from 'react'
import { Tooltip } from 'bootstrap'

export const useTooltip = ref => {
  useEffect(() => {
    // eslint-disable-next-line no-new
    const instance = new Tooltip(ref.current, { trigger: 'hover' })

    return () => instance.dispose()
  }, [ref])
}
