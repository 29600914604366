import { Icon } from 'components/forms/nerds-form/fields/icon'
import { useField } from 'formik'
import { Input } from 'components/forms/nerds-form/fields/quantity-field/input'
import { Button } from 'components/forms/nerds-form/fields/quantity-field/button'
import { faImage } from '@fortawesome/pro-light-svg-icons'
import { useOnClick } from 'components/forms/nerds-form/fields/quantity-field/hooks/use-on-click'
import { Label } from 'components/forms/nerds-form/fields/label'

const makeClassname = classname => `quantity-form-field nerds-form-field ${classname || ''}`

export const QuantityField = ({ name, label, placeholder, defaultValue, disabled, className, pieces }) => {
  const [, meta] = useField(name)

  return <div className={makeClassname(className)}>
    <Label className={'form-label'} name={name}>{label}</Label>
    <div className={'position-relative'}>
      <Icon icon={faImage} />
      <Input name={name} placeholder={placeholder} defaultValue={defaultValue} disabled={disabled} />
      <Button pieces={pieces} onClick={useOnClick(name, pieces)} />
    </div>
    {meta.touched && meta.error && <small className={'text-danger'}>{meta.error}</small>}
  </div>
}
