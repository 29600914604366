import { combineReducers } from 'redux'
import { metaspaces } from 'cms-content/slices/metaspaces'
import { marketplaces } from 'cms-content/slices/marketplaces'
import { tweets } from 'cms-content/slices/tweets'

export const reducer = combineReducers({
  metaspaces: metaspaces.reducer,
  marketplaces: marketplaces.reducer,
  tweets: tweets.reducer,
})

