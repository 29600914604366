import { useSetFooterVisible } from 'components/footer/hooks/use-set-footer-visible'
import { useEffect } from 'react'

export const useToggleFooterVisibility = ({ isLoading, hasMore }) => {
  const setFooterVisible = useSetFooterVisible()

  useEffect(() => {
    setFooterVisible(!isLoading && !hasMore)

    return () => setFooterVisible(true)
  }, [hasMore, isLoading, setFooterVisible])
}
