import { gql } from 'graphql-tag'

export const COUNT = gql`
    query($filters: CollectionsFilter) {
        collections(filters: $filters) {
            pageData {
                count
            }
        }
    }
`
