import { auth } from 'network/rest/auth'
import { mergeMap } from 'rxjs/operators'
import { internal } from './internal'
import { from } from 'rxjs'
import axios from 'axios'

const authorizedGet = (url, options) => auth.makePayload(options)
  .pipe(mergeMap(payload => from(axios.get(url, payload))))

const nonAuthorizedGet = (url, options) => internal.makePayload(options)
  .pipe(mergeMap(payload => from(axios.get(url, payload))))

export const get = () => (url, options = {}, authorize = false) => {
  if (authorize) return authorizedGet(url, options)

  return nonAuthorizedGet(url, options)
}
