import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'
import { useContext } from 'nft-page/auction/countdown/timer/context'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { get } from 'lodash'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { get as slice } from 'offers/slices'

export const useFetchEndedAuction = () => {
  const dispatch = useDispatch()
  const { timeLeft } = useContext()
  const { identifier } = useParams()
  const acceptedToken = useFirstAcceptedPaymentToken()
  const priceToken = get(acceptedToken, 'identifier')
  const marketplaceKey = useCmsMarketplaceKey()
  const unixTime = parseInt(moment(timeLeft).unix()
    .toString())

  useEffect(() => {
    if (unixTime === 1) {
      dispatch(slice.actions.attempt({ filters: { identifier, priceToken, status: 'Active', marketplaceKey } }))
    }
  }, [dispatch, identifier, marketplaceKey, priceToken, unixTime])
}
