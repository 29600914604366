import { useCreationsStats } from 'account/stats/hooks/use-creations-stats'
import { useCollections } from 'account/tabs/assets/creations/hooks/use-collections'
import { FacetsSelector } from 'filters/components/facets-selector/index'

export const Filters = () => {
  const collections = useCollections()
  const allNftsCount = useCreationsStats()

  const all = {
    label: 'All',
    name: 'all',
    value: 'all',
    count: allNftsCount,
    selected: true,
  }

  const facets = collections.map(collection => ({
    label: collection?.node?.name,
    name: collection?.node?.collection,
    value: collection?.node?.collection,
    count: collection?.node?.nftsCount,
  }))

  return allNftsCount ? <FacetsSelector facets={[all, ...facets]} name={'collection'} /> : ''
}
