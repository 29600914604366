import { useSuggestionsType } from 'feed/hooks'
import { suggestionTypes } from 'common/suggestion-types'
import { Initial } from 'feed/initial'
import { Active } from 'feed/active'

export const FeedBySuggestionsType = () => {
  const type = useSuggestionsType()

  return type === suggestionTypes.initial ? <Initial /> : <Active />
}
