import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Errors } from 'components/forms/nerds-form'
import { InputSubmitField } from 'components/forms/nerds-form/fields/input-submit-field'
import { ListField } from 'components/forms/add-remove-featured-collection-form/list-field'
import { CancelButton } from 'components/forms/nerds-form/fields/cancel-button'
import { settings } from 'settings/fetching'

export const Fields = ({ callbackUrl, submitErrors, featuredCollections }) => (
  <div className={'modal-content'}>
    <Title icon={faGavel} title={'Add or remove featured collections'} margins={'mb-4'} />
    <InputSubmitField label={'Collection Identifier'} name={'collection'}
                      callbackUrl={callbackUrl} buttonLabel={'Add featured collection'}
                      disabled={featuredCollections?.length >= settings().home.featuredCollections} />
    {featuredCollections.length ? <ListField collections={featuredCollections} callbackUrl={callbackUrl} /> : ''}
    <CancelButton callbackUrl={callbackUrl} />
    <Errors values={submitErrors} />
  </div>
)
