import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { auctions as repository } from 'repositories/auctions'
import { actions } from 'modals/auction/buy-sft/slices/buy-sft'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'
import { throwOnInsufficientFunds } from 'modals/throw-on-insufficient-funds'

export const buySft = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(({ payload }) => execute(payload)))

const execute = payload => repository.buySft(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.buySft })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = result => of(actions.failure(extractGraphqlErrors(result)))
