import { Left } from 'feed/initial/left'
import { Right } from 'feed/initial/right'
import { DataCard } from 'components/data-card'
import { List } from 'feed/initial/list'
import { EmptyWrapper } from 'feed/initial/empty-wrapper'
import 'react-circular-progressbar/dist/styles.css'
import 'feed/initial/style.scss'

export const Initial = () => <div className={'container'}>
  <div className={'d-flex flex-column flex-md-row justify-content-between'}>
    <Left />
    <Right />
  </div>
  <DataCard disableContentContainer>
    <EmptyWrapper>
      <List />
    </EmptyWrapper>
  </DataCard>
</div>
