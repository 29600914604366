import { get } from 'lodash'
import { nominateAmount } from 'common/conversion/nominate-amount'

export const customSortFilters = {
  'highest-price': { direction: 'DESC', field: 'CURRENTPRICE' },
  'lowest-price': { direction: 'ASC', field: 'CURRENTPRICE' },
}

export const customFilters = (filters, dPriceRange) => [...currentPrice(filters, dPriceRange)]

const currentPrice = (filters, dPriceRange) => shouldAddCustomFilter(filters) ? [price(filters, dPriceRange)] : []
export const shouldAddCustomFilter = filters => hasValues(filters) || hasSortByHighestOrLowest(filters)
const sortByPrice = sort => get(customSortFilters, sort, { direction: 'ASC', field: 'CURRENTPRICE' })
const hasValues = ({ min, max }) => (min || max)
const hasSortByHighestOrLowest = ({ sort }) => (sort === 'highest-price' || sort === 'lowest-price')
const sort = filters => hasSortByHighestOrLowest(filters) && { sort: sortByPrice(filters.sort) }

const price = (filters, dPriceRange) => {
  const minVal = filters.min ? nominateAmount(filters.min, dPriceRange.min.decimals) : dPriceRange.min.amount
  const maxVal = filters.max ? nominateAmount(filters.max, dPriceRange.max.decimals) : dPriceRange.max.amount

  return {
    field: 'CURRENTPRICE',
    op: 'BETWEEN',
    values: [minVal, maxVal],
    ...sort(filters),
  }
}
