import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useSetTag } from 'trending/hooks/use-set-tag'

export const useQueryFilter = () => {
  const { search } = useLocation()
  const setTag = useSetTag()

  useEffect(() => {
    const parsedQuery = new URLSearchParams(search)

    if (parsedQuery.has('trending')) {
      setTag(parsedQuery.get('trending'))
    }
  }, [search, setTag])
}
