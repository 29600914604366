import { useEffect, useRef } from 'react'
import { useContext } from 'components/collection-card/context'

export const useTimer = () => {
  const { loadedThumbs, setCurrent, running } = useContext()
  const interval = useRef(null)

  useEffect(() => {
    if (running) {
      setCurrent(1)
      interval.current = setInterval(() => {
        const updateCallback = current => current + 1 > loadedThumbs.length - 1 ? 0 : current + 1
        interval.current && setCurrent(updateCallback)
      }, 300)
    } else {
      clearInterval(interval.current)
      setCurrent(0)
    }

    return () => clearInterval(interval.current)
  }, [interval, setCurrent, loadedThumbs.length, running])
}
