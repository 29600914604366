import { Background } from 'featured-pages/components/featured-boxes/background'
import { Box } from 'featured-pages/components/featured-boxes/box'
import { Title } from 'featured-pages/components/featured-boxes/title'
import 'featured-pages/components/featured-boxes/index.scss'

export const FeaturedBoxes = ({ boxes }) => <div className={'featured-boxes'}>
  <Background />
  <div className={'container'}>
    <Title />
    <div className={'featured-boxes-list row'}>
      {boxes.map((props, index) => <div key={index} className={'col-12 col-md-6 d-flex'}>
        <Box {...props} />
      </div>)}
    </div>
  </div>
</div>
