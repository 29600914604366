import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons'
import 'components/explorer-link/index.scss'
import { settings } from 'settings/fetching'

const getBaseUrl = () => settings().explorer

const makeUrl = link => `${getBaseUrl()}${link}`

export const ExplorerLink = ({ link }) => <a href={makeUrl(link)} target={'_blank'} rel={'noreferrer'}
                                             className={'explorer-link'}>
  <FontAwesomeIcon icon={faExternalLink} color={'#838C9A'} />
</a>
