import 'explore-nfts/tabs/collections/list/index.scss'
import { Item } from 'explore-nfts/tabs/collections/list/item'
import React, { useRef } from 'react'
import { useRunningIndex } from 'explore-nfts/tabs/collections/list/hooks/use-running-index'

export const List = ({ collections }) => {
  const itemsRef = useRef([])
  const runningIndex = useRunningIndex(itemsRef.current)

  return <div className={'row'}>
    {collections.map((item, index) => (
      <Item key={index} ref={el => (itemsRef.current[index] = el)}
            item={item} running={index === runningIndex} />
    ))}
  </div>
}
