import { useMakeProps } from './hooks/use-make-props'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Button = ({ id, selected, disabled }) => <button {...useMakeProps(id)} disabled={disabled}>
  {' '}
  {selected.value}
  {' '}
  <FontAwesomeIcon icon={faChevronDown} />
</button>
