import { useMakeProps } from 'components/dropdown-tokens/hooks/use-make-props'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { Token } from 'components/dropdown-tokens/token'

const isSelectedPaymentToken = token => token !== 'All' && token !== ''

export const Button = ({ id, selected, disabled, actualCount }) => {
  const token = useFilterValue('token')
  const tokenSelected = isSelectedPaymentToken(token)

  return <button {...useMakeProps(id)} disabled={disabled}>
    {tokenSelected ? <Token option={selected} actualCount={actualCount} /> : 'Payment Token'}
    {' '}
    <div className={'dropdown-tokens-icon'}>
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  </button>
}
