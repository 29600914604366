import { ReactComponent as FeeMarketCutIcon } from 'startup/assets/fee-market-cut.svg'
import { useMarketplaceCutPercentage } from 'marketplaces/hooks/use-marketplace-cut-percentage'
import 'components/forms/nerds-form/fields/final-fee-field/cut-percentage/index.scss'

const lastPart = `of this auction final price.`

export const CutPercentage = () => {
  const cutPercentage = useMarketplaceCutPercentage() / 100

  return <div className={'cut-percentage-item final-fee-item d-flex align-items-center'}>
    <FeeMarketCutIcon className={'final-fee-icon'} />
    <div>
      <div className={'cut-percentage-item-title d-flex justify-content-start'}>
        {`${cutPercentage}% Marketplace Cut`}
      </div>
      <div>
        <span>Marketplace will get</span>
        {` `}
        <span className={'cut-percentage-item-text'}>{`${cutPercentage}% cut`}</span>
        {` `}
        <span>{lastPart}</span>
      </div>
    </div>
  </div>
}
