import { from } from 'rxjs'
import { history } from 'network'
import { ofType } from 'redux-observable'
import { catchError, mergeMap } from 'rxjs/operators'
import { actions } from 'subdomain/home/collections/slices/collections'
import { failure as failureSlice } from 'failure/slices'
import { nftCollections as repository } from 'repositories/nft-collections'

export const collections = action$ => action$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.get(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.append(payload.data.collections.edges),
  actions.success(payload),
])

const failure = payload => from([
  actions.failure(payload),
  failureSlice.actions.show({ route: history.location.pathname }),
])
