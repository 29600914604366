import { Loading } from 'loading'
import { useDispatch } from 'react-redux'
import { actions } from 'collections/assets/slices/get'
import { useCallback } from 'react'
import { useFetchPayload } from 'collections/assets/hooks/use-fetch-payload'
import { settings } from 'settings/fetching'

export const useIssueServer = customCursor => {
  const dispatch = useDispatch()
  const payload = useFetchPayload(customCursor)

  return useCallback(() => {
    dispatch(actions.attempt(payload))
  }, [dispatch, payload])
}

export const useNext = customCursor => {
  const issueServer = useIssueServer(customCursor)
  const showGhosts = Loading.useShowGhosts(Loading.ghosts.collections)
  const hideGhosts = Loading.useHideGhosts()

  return useCallback(() => {
    hideGhosts(Loading.ID.collection.page)
    showGhosts(Loading.ID.collection.page, settings().listRequestSize)
    issueServer()
  }, [hideGhosts, issueServer, showGhosts])
}
