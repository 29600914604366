import { useShouldShowMakeOffer } from 'nft-page/offers/hooks/use-should-show-make-offer'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { Send } from 'nft-page/offers/actions/send'
import { SubdomainList } from 'nft-page/offers/subdomain-list'
import { List } from 'nft-page/offers/list'

export const Body = ({ offers }) => {
  const shouldShowMakeOffer = useShouldShowMakeOffer()
  const isSubdomain = useIsSubdomain()

  return <>
    {shouldShowMakeOffer ? <Send /> : ''}
    {isSubdomain ? <SubdomainList offers={offers} /> : <List offers={offers} />}
  </>
}
