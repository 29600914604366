import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'
import { Actions } from 'collections/actions'
import { ReactComponent as Picture } from 'startup/assets/picture.svg'

const activeProps = collection => ({
  icon: <Picture />,
  title: `${collection.node.name}`,
  titlePrefix: `Collection`,
  exploreLink: `/collections/${collection.node.collection}`,
  headerAction: <Actions />,
  verified: collection.node.verified,
})

const inactiveProps = () => ({
  icon: <Picture />,
  hiddenHeader: false,
  titlePrefix: `Collection`,
})

export const useCollectionProps = () => {
  const [collection] = useCollectionsSelector()
  const collectionFetched = useCollectionsSelector('collections.data.pageInfo')

  return collectionFetched && collection ? activeProps(collection) : inactiveProps()
}
