import { home } from 'home/reducers'
import { viewer } from 'viewer/slices'
import { combineReducers } from 'redux'
import { failure } from 'failure/slices'
import { nft } from 'nft-page/reducers'
import { owners } from 'owners/reducers'
import { loading } from 'loading/slices'
import { search } from 'search/reducers'
import { account } from 'account/reducers'
import { trending } from 'trending/slices'
import { auction } from 'auction/reducers'
import { reducers as modals } from 'modals'
import { economics } from 'economics/reducers'
import { exploreNfts } from 'explore-nfts/slices'
import { collections } from 'collections/reducers'
import { sidebarReducers } from 'sidebar/reducers'
import { assetHistory } from 'asset-history/reducers'
import { marketplaces } from 'marketplaces/reducers'
import { currentProfile } from 'current-profile/slices/current-profile'
import { reducer as transaction } from 'transaction/slices/persisted'
import { notifications } from 'notifications/reducers'
import { actionStatuses } from 'action-statuses/slices/persisted'
import { preferences } from 'preferences/reducer'
import { report } from 'report/reducers'
import { reducer as feed } from 'feed'
import { reducer as campaigns } from 'campaigns/reducers'
import { reducer as campaign } from 'campaign/reducers'
import { reducer as holorideCampaign } from 'holoride-campaign/reducers'
import { reducer as tags } from 'tags/reducers'
import { subdomainCollections } from 'subdomain/home/collections/slices'
import { reducer as admin } from 'admin'
import { reducer as subdomainContent } from 'subdomain/cms-content/reducer'
import { reducers as subdomainModals } from 'subdomain/modals'
import { reducer as inspireContent } from 'cms-content/reducer'
import { reducer as myTokens } from 'my-tokens/reducer'
import { reducer as offers } from 'offers/reducer'

export const reducers = combineReducers({
  nft,
  account,
  home,
  exploreNfts,
  auction,
  collections,
  assetHistory,
  economics,
  search,
  trending,
  owners,
  currentProfile: currentProfile.reducer,
  failure: failure.reducer,
  sidebar: sidebarReducers,
  loading: loading.reducer,
  modals,
  viewer,
  marketplaces,
  transaction,
  notifications,
  actionStatuses,
  preferences,
  report,
  feed,
  campaigns,
  campaign,
  holorideCampaign,
  tags,
  subdomainCollections,
  admin,
  subdomainContent,
  subdomainModals,
  inspireContent,
  myTokens,
  offers,
})

export default reducers
