import { Nft } from 'auction/actions/start/nft'
import { Sft } from 'auction/actions/start/sft'

const condition = id => id === `create-nft-auction`

const nft = props => <Nft {...props} />

const sft = props => <Sft {...props} />

export const StartAuctionModal = ({ id, asset, address, title }) => {
  const isNft = condition(id)

  return isNft ? nft({ asset, address, title }) : sft({ asset, id, address, title })
}

