import { useValidationSchema } from
  'components/forms/add-remove-blacklisted-collections-form/hooks/use-validation-schema'

export const useConfig = ({ onSubmit, initialValues, onRemoveClick }) => ({
  initialValues: useValidationSchema().cast(initialValues),
  validationSchema: useValidationSchema(),
  validateOnChange: true,
  onSubmit,
  onRemoveClick,
})
