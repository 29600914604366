import { Title } from 'components/campaign-card/title'
import { Price } from 'components/campaign-card/price'
import { Image } from 'components/campaign-card/image'
import { CampaignStatus } from 'components/campaign-status'
import { Link } from 'react-router-dom'
import 'components/campaign-card/index.scss'

export const CampaignCard = ({ campaign }) => <div className={'campaign-card'}>
  <Link className={'campaign-card-link'} to={`/campaigns/${campaign.minterAddress}/${campaign.campaignId}`} />
  <Title campaign={campaign} />
  <Image campaign={campaign} />
  <div className={'campaign-card-bottom'}>
    <CampaignStatus campaign={campaign} />
    <Price tiers={campaign.tiers} />
  </div>
</div>
