import { rest } from 'network'
import { getBaseUrl } from 'repositories/followers/get-base-url'

export const unfollow = address => rest().post(`${getBaseUrl()}/api/v1/followers/unfollow`, {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(address),
}, true)
