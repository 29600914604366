import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions as primarySale } from 'holoride-campaign/slices/primary-sale'
import { actions as isWhitelisted } from 'holoride-campaign/slices/is-whitelisted'
import { actions as myTickets } from 'holoride-campaign/slices/my-tickets'
import { useFetchCallback } from 'holoride-campaign/hooks/use-fetch-callback'

export const useRefetch = () => {
  const dispatch = useDispatch()
  const fetch = useFetchCallback()

  return useCallback(() => {
    dispatch(primarySale.reset({}))
    dispatch(isWhitelisted.reset({}))
    dispatch(myTickets.reset({}))
    fetch()
  }, [dispatch, fetch])
}
