import { useNftName } from 'nft-page/hooks/nft/use-nft-name'
import { useNftScamInfo } from 'nft-page/hooks/nft/use-nft-scam-info'
import { useScamInfoName } from 'nft-page/hooks/nft/use-scam-info-name'

export const useAssetName = () => {
  const assetName = useNftName()
  const scamInfo = useNftScamInfo()
  const scamInfoName = useScamInfoName()

  return scamInfo ? scamInfoName : assetName
}
