import { Form } from 'modals/collection/set-roles/existing/nft/form'
import { Errors } from 'modals/collection/set-roles/errors'
import { Behaviour } from 'modals/collection/set-roles/behaviour'
import { Content } from '../../../create/content'
import { Suspense } from '../../../create/suspense'
import { useParams } from 'react-router-dom'

export const SetRoles = () => {
  const collection = useParams().identifier

  return <div className={'modal-content p-4'}>
    <Behaviour />
    <Suspense after={collection}>
      <Content collection={collection}>
        <Form />
      </Content>
    </Suspense>
    <Errors />
  </div>
}

