import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { followers } from 'account/profile/follow/slices/followers'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useFollowers = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()

  useEffect(() => {
    address && dispatch(followers.actions.attempt({ address }))
  }, [dispatch, address])
}
