import { settings } from 'settings/fetching'

export const makePayload = ({ address, cursor, marketplaceKey }) => ({
  pagination: { first: settings().listRequestSize, after: cursor },
  filters: {
    operator: 'AND',
    filters: [
      { field: 'OrderEntity.ownerAddress', op: 'EQ', values: [address] },
      { field: 'OrderEntity.status', op: 'IN', values: ['Inactive', 'Active'] },
      { field: 'OrderEntity.marketPlaceKey', op: 'EQ', values: [marketplaceKey] },
    ],
    childExpressions: [{
      operator: 'OR',
      filters: [{
        field: 'AuctionEntity.status',
        relationField: 'OrderEntity.auction',
        op: 'EQ',
        values: ['Running'],
      }],
    }],
  },
})
