import { from } from 'rxjs'
import { history } from 'network'
import { Loading } from 'loading'
import { loading } from 'loading/slices'
import { ofType } from 'redux-observable'
import { assets } from 'repositories/assets'
import { actions } from 'nft-page/slices/nft'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'

export const getNft = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = action => assets.getAsset(action.payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  loading.actions.hide({ id: Loading.ID.nft.media, variant: Loading.VARIANT.partial }),
])

const failure = errors => from([
  actions.failure(errors),
  failureSlice.actions.show({ route: history.location.pathname }),
  loading.actions.hide({ id: Loading.ID.nft.media, variant: Loading.VARIANT.partial }),
])
