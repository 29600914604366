import { useRemoveFeaturedCollection } from 'admin/hooks/actions/use-remove-featured-collection'
import { useLocation, useNavigate } from 'react-router-dom'
import './index.scss'

// eslint-disable-next-line max-lines-per-function
export const ListField = ({ collections, callbackUrl }) => {
  const onRemoveClick = useRemoveFeaturedCollection()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    if (!location?.state?.hydrated) {
      navigate(-1)
    } else {
      navigate(callbackUrl, { replace: true })
    }
  }

  return <ul className={'list-group mb-4'}>
    {collections.map(({ node }, index) => <div className={'d-flex justify-content-between mb-2'} key={index}>
      <li className={'list-group-item featured-collections-list-group text-primary me-4'}>
        {`${node.name} - ${node.collection}`}
      </li>
      <button type={'button'} className={'btn btn-primary'}
              onClick={() => onRemoveClick(node.collection)} onClickCapture={handleClick}>
        Remove
      </button>
    </div>)}
  </ul>
}
