import { Button } from 'components/button'
import { useIsActiveUser } from 'common/hooks/use-is-active-user'
import { useOnEditClick } from 'account/profile-cover/hooks/use-on-edit-click'

export const Edit = () => {
  const isActiveUser = useIsActiveUser()
  const onClick = useOnEditClick()

  return isActiveUser && <div className={'hero-cover-container-edit'}>
    <Button icon={'camera'} onClick={onClick}>
      Change cover
    </Button>
  </div>
}
