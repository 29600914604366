import { useCallback } from 'react'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'

export const useOnClick = identifier => {
  const navigate = useLayeredNavigate()

  return useCallback(() => {
    const path = `/nfts/${identifier}/modal/create-sft-auction/single`
    navigate(path, 'modal')
  }, [identifier, navigate])
}
