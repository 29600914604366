import { Loading } from 'loading'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useIsLoading } from 'explore-nfts/tabs/creators/hooks/use-is-loading'

const path = 'exploreNfts.creators.get.list'

export const useCreatorsList = () => {
  const items = useSelector(state => get(state, path, []))
  const ghosts = Loading.usePayloads(Loading.ID.explore.page)
  const isLoading = useIsLoading()

  return isLoading ? [...items, ...ghosts] : [...items]
}
