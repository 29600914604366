import { Errors, NerdsForm } from 'components/forms/nerds-form'
import { Title } from 'components/forms/nerds-form/title'
import { useConfig } from 'components/forms/buy-campaign-form/hooks/use-config'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Tier } from 'components/forms/buy-campaign-form/tier'
import { QuantityField } from 'components/forms/nerds-form/fields/quantity-field'
import { Total } from 'components/forms/buy-campaign-form/total'

export const BuyCampaignForm = ({ loading, onSubmit, submitErrors, callbackUrl, initialValues, tier, campaign }) => (
  <NerdsForm loading={loading}
             config={useConfig({ onSubmit, initialValues, tier, campaign })}
             submit_errors={submitErrors}>
    <div className={'modal-content'}>
      <Title title={`Buy ${tier.campaignId}`} />
      <Tier tier={tier} />
      <QuantityField name={'quantity'} label={'How many pieces?'} pieces={tier.availableNfts} />
      <Total tier={tier} campaign={campaign} />
      <DefaultActions callbackUrl={callbackUrl} label={'Buy Now'} />
      <Errors values={submitErrors} />
    </div>
  </NerdsForm>
)
