import { useHeroTag } from 'components/user-details/hooks/use-hero-tag'
import { UserDetails } from 'components/user-details'
import { Price } from 'components/price'
import * as _ from 'lodash'
import moment from 'moment'
import './index.scss'

const fromAsset = asset => (path, defaultValue = '') => _.get(asset, path, defaultValue)

const getDate = date => moment(date * 1000).format('DD/MM/YYYY, HH:mm')

export const HistoryItem = ({ asset }) => {
  const get = fromAsset(asset)
  const amount = get('node.price.amount')
  const usdAmount = get('node.price.usdAmount')
  const price = get('node.price')
  const herotag = useHeroTag(get('node.account'))
  const date = getDate(get('node.actionDate', 0))

  return <div className={'history-item d-flex align-items-center w-100'}>
    <UserDetails date={date} image={get('node.account.profile')} herotag={herotag}
                 action={get('node.action')} address={get('node.account.address')} />
    {amount && usdAmount && <Price price={price} /> }
  </div>
}
