import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { setNftScamInfo } from 'admin/slices'

export const useSetScamNft = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    if (window.confirm('Are you sure?') === true) {
      dispatch(setNftScamInfo.actions.attempt({ identifier, info: 'Scam', type: 'scam' }))
    }
  }, [dispatch, identifier])
}
