import { useSelector } from 'react-redux'
import { filter, get } from 'lodash'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'

const defaultPath = 'nft.auctions.list.data'

const getStatus = auction => get(auction, 'status', '')

const isRunning = auction => getStatus(auction) !== 'Claimable'

const isClaimable = auction => getStatus(auction) === 'Claimable'

const getTopBidder = auction => get(auction, 'topBidder.address', '')

const getOwner = auction => get(auction, 'ownerAddress', '')

const isViewerClaimable = (auction, address) => isClaimable(auction) && getTopBidder(auction) === address

const isOwnerEndable = (auction, address) => isClaimable(auction) && getOwner(auction) === address

const auctionStatus = (auction, address) => {
  const isRunningAuction = isRunning(auction)
  const isClaimableAuction = isViewerClaimable(auction, address)
  const isEndableAuction = isOwnerEndable(auction, address)

  return isRunningAuction || isClaimableAuction || isEndableAuction
}

export const useCurrentAuction = () => {
  const { address } = useGetAccountInfo()
  const auctionsList = useSelector(state => get(state, defaultPath, []))
  const result = filter(auctionsList, ({ node }) => auctionStatus(node, address))

  return get(result, '[0].node', '')
}
