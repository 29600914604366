import PropTypes from 'prop-types'

import { useProps } from 'components/row/hooks/use-props'

export const Row = ({ children, ...props }) => <div {...useProps(props)}>
  {children}
</div>

Row.defaultProps = {
  children: '',
  className: '',
}

Row.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
}
