import { Column } from 'components/column'
import 'components/forms/create-nft-form/fieldsets/dropzone/dropzone.scss'
import { DropzoneField } from 'components/forms/nerds-form/fields/dropzone-field'

const fileTypeInfo = 'PNG, JPEG, JPG, WebP, GIF, AAC, FLAC, M4A, MPEG, WAV, MOV, QUICKTIME, MP4 or WEBM. Max 100 mb.'

export const Dropzone = () => <Column className={'dropzone-outer h-100 col-12 col-md-8 col-xl-5'}>
  <div className={'modal-content mb-5 rounded'}>
    <DropzoneField buttonText={'Drop here or select file'} buttonTextAlt={'Select another file'}
                   name={'file'} fileTypeInfo={fileTypeInfo} />
  </div>
</Column>

