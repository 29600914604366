import { useIsFollowed } from 'account/profile/follow/hooks/use-is-followed'
import { useContext } from 'account/profile/follow/mutate-follow/context'
import { useEffect } from 'react'

export const useMatchReduxStatus = () => {
  const isFollowed = useIsFollowed()
  const { setIsFollowed } = useContext()

  useEffect(() => {
    setIsFollowed(isFollowed)
  }, [setIsFollowed, isFollowed])
}
