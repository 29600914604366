import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  input: '',
  data: { },
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: payload })

const reset = () => initialState

export const search = createSlice({
  initialState,
  name: 'search/search',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions, reducer } = search
