import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { faCoins } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { humanize } from 'components/conversion/humanize/index'

export const Supply = () => {
  const supply = useNftSelector('nftPage.data.assets.edges[0].node.supply')

  return <KeyPair icon={<FontAwesomeIcon icon={faCoins} />} title={'Quantity'}>
    <span className={'accent'}>{humanize(supply?.toString()) || 'N/A'}</span>
  </KeyPair>
}
