import { Link } from 'react-router-dom'
import { Content } from './content'
import { LayeredLink } from '../layered-router/layered-link'
import { forwardRef } from 'react'

export const Self = forwardRef(({
  icon, isIconRight = false, onClick, to, layer,
  className, children, disabled, buttonType, ...extra
}, ref) => {
  const buttonContent = <Content icon={icon} isIconRight={isIconRight}>{children}</Content>

  if (onClick) {
    return <button ref={ref} type={'button'} className={className} onClick={onClick}
                   disabled={disabled} {...extra}>
      {buttonContent}
    </button>
  } else if (typeof to === 'object') {
    return <LayeredLink ref={ref} path={to} layer={layer} className={className}>{buttonContent}</LayeredLink>
  } else {
    return <Link ref={ref} to={to} className={className}>{buttonContent}</Link>
  }
})

Self.displayName = 'Self'
