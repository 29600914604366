import moment from 'moment'
import { useEffect } from 'react'

import { useContext } from 'nft-page/auction/countdown/timer/context'

export const useInitializeTimeLeft = auction => {
  const { setTimeLeft } = useContext()

  useEffect(() => {
    if (auction.endDate) {
      const eventDate = moment(auction.endDate * 1000)
      setTimeLeft(moment.duration(eventDate.diff(moment(), 'milliseconds', true)))
    }
  }, [auction.endDate, setTimeLeft])
}
