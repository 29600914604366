import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const useCallbackUrl = () => {
  const [collection] = useCollectionsSelector()
  const callbackUrl = `/collections/${collection.node.collection}`
  const requieredCallback = useFilterValue('callbackUrl')

  return requieredCallback || callbackUrl
}
