import { ReactComponent as PowerOffIcon } from 'startup/assets/power-off.svg'
import './disconnect-link.scss'
import { Link as RouterLink } from 'react-router-dom'

export const DisconnectLink = () => (
  <RouterLink to={'/logout'} className={`nav-link-outline btn disconnect-link btn-secondary align-items-center`}>
    <PowerOffIcon />
    <span className={'d-none d-md-inline'}>Disconnect</span>
  </RouterLink>
)
