import { extract } from 'components/conversion/humanize/extract'
import { decide } from 'components/conversion/humanize/decide'
import { commas } from 'components/conversion/humanize/commas'

export const humanize = denominatedValue => {
  const integers = extract.integers(denominatedValue)
  const decimals = decide.decimals(denominatedValue)

  return `${commas.insert(integers)}${decimals}`
}
