import { useField } from 'formik'
import { useOnChanged } from 'components/forms/nerds-form/fields/auctions-field/hooks/use-on-changed'
import Select from 'react-select'

const makeOptions = auctions => auctions.map(auction => (
  {
    value: auction?.id,
    label: <>
      {auction?.identifier}
      {' '}
      <span className={'text-muted'}>
        {`(Auctioned tokens: ${auction?.nrAuctionedTokens})`}
      </span>
    </>,
  }
))

export const AuctionsSelector = ({ name, auctions }) => {
  const [field, meta, { setTouched }] = useField(name)
  const onSelectionChanged = useOnChanged(name, auctions)

  return auctions && <Select data-testid={name} name={name} id={name} contentEditable={false}
                             classNamePrefix={'react-select'} onChange={onSelectionChanged}
                             onMenuClose={() => setTouched()}
                             defaultValue={meta.initialValue}
                             onBlur={field.onBlur} options={makeOptions(auctions)} />
}
