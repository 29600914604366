import { useEffect, useRef } from 'react'

export const useViewportWidth = () => {
  const observer = useRef(null)

  useEffect(() => {
    const root = document.getElementById('root')
    const element = document.createElement('div')

    element.classList.add('viewport-checker')
    root.append(element)

    observer.current = new window.ResizeObserver(() => {
      root.style.setProperty('--viewport-width', `${element.offsetWidth}px`)
    })

    observer.current?.observe(element)

    return () => observer.current?.disconnect()
  }, [])

  return ''
}
