import { useTraitsContext } from 'modals/traits/context'
import { isEmpty, reduce } from 'lodash'
import { useResetAllTraits } from 'modals/traits/hooks/use-reset-all-traits'
import { AllFiltersCount } from 'modals/traits/header/all-filters-count'
import { ClearAllFilterButton } from 'modals/traits/header/clear-all-filter-button'

const hasTraits = (selectedTraits, totalTraits) => isEmpty(selectedTraits) || totalTraits === 0

export const Header = () => {
  const { selectedTraits } = useTraitsContext()
  const totalTraits = reduce(selectedTraits, (sum, trait) => sum + trait.values?.length, 0)
  const resetAllTraits = useResetAllTraits()
  const shouldShow = !hasTraits(selectedTraits, totalTraits)

  return <div className={'mb-4 d-flex align-items-center'}>
    <div className={'w-50 d-flex'}>
      <div className={'traits-modal-title'}>Filters</div>
      {shouldShow && <AllFiltersCount totalTraits={totalTraits} />}
    </div>
    {shouldShow && <ClearAllFilterButton resetAllTraits={resetAllTraits} /> }
  </div>
}
