import { from } from 'rxjs'
import { Failure } from 'failure'
import { history } from 'network'
import { Loading } from 'loading'
import { loading } from 'loading/slices'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { actions } from 'account/tabs/assets/creations/slices/collections'
import { nftCollections } from 'repositories/nft-collections'

export const collections = $actions => $actions
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => nftCollections.getAccountCollectionsFilters(payload)
  .pipe(mergeMap(response => success(response)))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  loading.actions.hide({ id: Loading.ID.account.tab.creations, variant: Loading.VARIANT.ghostCard }),
])

const failure = payload => from([
  actions.failure(payload),
  failureSlice.actions.show(failurePayload()),
  loading.actions.hide({ id: Loading.ID.account.tab.creations, variant: Loading.VARIANT.ghostCard }),
])

const failurePayload = () => ({
  route: history.location.pathname,
  variant: Failure.VARIANT.partial,
  id: 'account.creations',
})
