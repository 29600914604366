import { useReportCollection } from 'admin/hooks/actions/use-report-collection'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { ReportButton } from 'components/report-button'
import { useIsLoading } from 'admin/hooks/use-is-report-collection-loading'
import { useGetReportCollection } from 'admin/hooks/use-get-report-collection'

export const ReportCollection = () => {
  const { address } = useGetAccountInfo()
  const reportCollection = useReportCollection()
  const onClick = usePermittedAction(reportCollection, address)
  const report = useGetReportCollection()

  return <ReportButton onClick={onClick} isLoading={useIsLoading()} isReported={!!report} />
}
