import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

const makePathname = ({ identifier, variant }) => `/collections/${identifier}/modal/${variant}`

export const useActionPath = variant => {
  const urlParams = useParams()

  return useMemo(() => makePathname({ ...urlParams, variant }), [urlParams, variant])
}
