import { from, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { auth } from 'network/graph/auth'

const authorizedMutation = client => payload => auth.makePayload(payload)
  .pipe(mergeMap(payload => from(client.mutate(payload))))

const nonAuthorizedMutation = client => payload => of(payload)
  .pipe(mergeMap(payload => from(client.mutate(payload))))

export const mutate = client => (payload, authorize = true) => {
  if (authorize) return authorizedMutation(client)(payload)

  return nonAuthorizedMutation(client)(payload)
}
