import { useRef } from 'react'
import { useIsOnTopLayer } from 'components/layered-router/hooks/use-is-on-top-layer'
import { Content } from 'components/seo/content'
import { useLocation } from 'react-router-dom'

export const Seo = props => {
  const seo = useRef()
  const location = useLocation()
  const isOnTopLayer = useIsOnTopLayer(seo)

  return <div ref={seo} key={location.key}>
    {isOnTopLayer ? <Content {...props} /> : ''}
  </div>
}
