import { Modal } from 'bootstrap'
import { useEffect, useRef } from 'react'
import { useIsVisible } from 'components/bootstrap-modal/hooks/use-is-visible'
import { useOnHide } from 'components/bootstrap-modal/hooks/use-on-hide'

const makeBackdrop = easyClose => easyClose || 'static'

export const useModal = (id, easyClose, routeModal) => {
  const modalRef = useRef(null)
  const visible = useIsVisible(id)
  const onHide = useOnHide(easyClose, routeModal)

  useEffect(() => {
    const element = document.getElementById(id)

    if (element) {
      modalRef.current = new Modal(element, {
        focus: easyClose,
        keyboard: easyClose,
        backdrop: makeBackdrop(easyClose),
      })
      if (visible) modalRef.current?.show()

      element.addEventListener('hide.bs.modal', onHide)
    }

    return () => {
      modalRef.current?.dispose()
    }
  }, [modalRef, id, visible, onHide, easyClose])
}
