import { useFetchPageDependencies } from 'collections/hooks/use-fetch-page-dependencies'
import { useWatchAction } from 'action-statuses/hooks/use-watch-action'
import { useParams } from 'react-router-dom'

export const useWatchChanges = () => {
  const fetchPageDependencies = useFetchPageDependencies()
  const { identifier } = useParams()

  useWatchAction({ prefix: `${identifier}_Collection`, onChange: fetchPageDependencies })
}
