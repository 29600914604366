import { debounce } from 'lodash'
import { useEffect } from 'react'
import { settings } from 'settings/fetching'
import { isSlideVisible } from 'components/swipper-with-navigation/is-slide-visible'

export const useResponsiveSlidesPerGroup = (swiperRef, setSlidesPerGroup) => {
  useEffect(() => {
    const onResize = () => {
      const slides = swiperRef?.current?.querySelectorAll('.swiper-slide')
      let visibleSlides = 0

      for (const slide of slides) {
        visibleSlides += isSlideVisible(slide, swiperRef?.current)
      }

      const settingsValue = settings().home.itemsPerGroup
      const responsiveValue = visibleSlides < settingsValue ? visibleSlides : settingsValue

      setSlidesPerGroup(responsiveValue)
    }

    const debounced = debounce(onResize, 300)

    window.addEventListener('resize', debounced)
    onResize()

    return () => window.removeEventListener('resize', debounced)
  }, [swiperRef, setSlidesPerGroup])
}
