import { BootstrapModal } from 'components/bootstrap-modal'
import { Cancel } from 'components/onboarding-modal/cancel'
import { useOnClick } from 'components/onboarding-modal/hooks/use-on-click'
import { useShowModal } from 'components/onboarding-modal/hooks/use-show-modal'
import 'components/onboarding-modal/index.scss'

export const Content = ({ id, children, buttonText, callback, showCancel }) => {
  useShowModal(id)
  const onClick = useOnClick(id, callback)

  return <BootstrapModal id={id} className={'onboarding-modal'} routeModal={false}>
    <div className={' modal-content text-center'}>
      {children}
      <div className={'d-flex flex-column flex-sm-row'}>
        {showCancel && <Cancel id={id} />}
        <button className={'btn btn-primary w-100'} onClick={onClick}>{buttonText}</button>
      </div>
    </div>
  </BootstrapModal>
}
