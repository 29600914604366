import { useMemo } from 'react'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { get } from 'lodash'
import { useActionStatus } from 'action-statuses/hooks/use-action-status'
import { ActionButton } from 'nft-page/details/other-listings/actions/action-button'

export const WatchableAction = ({ entity, actionType, text, onClick, className, price }) => {
  const identifiers = useMemo(() => [createActionIdentifier(entity, actionType)], [actionType, entity])
  const status = get(useActionStatus(identifiers), '[0].status', '')

  return <ActionButton className={className} onClick={onClick} text={text} disabled={!!status} tooltipPrice={price} />
}
