import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons'
import { get } from 'lodash'
import spinner from 'startup/assets/spinner.png'

const makeTitle = (transactionStatus, transactionsInfo) => {
  const infos = {
    success: get(transactionsInfo, 'successMessage'),
    fail: get(transactionsInfo, 'errorMessage'),
    timedOut: get(transactionsInfo, 'errorMessage'),
    signed: get(transactionsInfo, 'processingMessage'),
    sent: get(transactionsInfo, 'processingMessage'),
    pending: get(transactionsInfo, 'processingMessage'),
  }

  return get(infos, transactionStatus)
}

const makeIcon = transactionStatus => {
  const icons = {
    success: <FontAwesomeIcon icon={faCheck} />,
    fail: <FontAwesomeIcon icon={faTimes} />,
    timedOut: <FontAwesomeIcon icon={faTimes} />,
    signed: <img src={spinner} />,
    sent: <img src={spinner} />,
    pending: <img src={spinner} />,
  }

  return get(icons, transactionStatus)
}

export const statuses = (transactionStatus, transactionsInfo) => ({
  title: makeTitle(transactionStatus, transactionsInfo),
  icon: makeIcon(transactionStatus),
  className: transactionStatus,
})
