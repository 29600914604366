import { usePredefinedRoles } from './use-predefined-roles'

export const useInitialValues = () => {
  const result = {}

  usePredefinedRoles().forEach(role => {
    result[role.id] = role.default
  })

  return result
}
