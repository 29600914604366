import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { actions } from 'nft-page/slices/nft'

export const useFetchMainNft = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    dispatch(actions.attempt({ filters: { identifier } }))
  }, [dispatch, identifier])
}
