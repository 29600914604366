import { useParams } from 'react-router-dom'

import { useIsSubdomain } from '../../../startup/hooks/use-is-subdomain'
import { publicTabs } from 'account/tabs/public-tabs'
import { subdomainTabs } from '../subdomain-tabs'

const useQuery = () => useParams().tab

export const useIsExistingTab = () => {
  const tab = useQuery() || 'collected'
  const tabs = useIsSubdomain() ? subdomainTabs : publicTabs

  return Object.values(tabs).find(({ hash }) => tab === hash)
}
