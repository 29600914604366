import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { setNftScamInfo as slice } from 'admin/slices'
import { mergeMap, catchError } from 'rxjs/operators'
import { repository } from 'repositories/admin'

export const setNftScamInfo = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.setNftScamInfo(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(slice.actions.success(payload))

const failure = errors => of(slice.actions.failure(errors))
