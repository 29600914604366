import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { stringify, parse } from 'common/query-string'

const setFiltersResetAll = ({ navigate, pathname, name, value }) => {
  const resetSearch = {}

  if (value) {
    resetSearch[name] = value
  }

  navigate({ pathname, search: stringify(resetSearch), state: { forceReset: true } })
}

const setFiltersResetSome = ({ navigate, pathname, search, name, value, resetOthers }) => {
  const parsedSearch = parse(search)

  resetOthers.forEach(name => delete parsedSearch[name])

  if (value) {
    value === 'All' ? delete parsedSearch[name] : parsedSearch[name] = value
  }

  navigate({ pathname, search: stringify(parsedSearch), state: { forceReset: true } })
}

const setFilters = ({ navigate, pathname, search, name, value }) => {
  const parsedSearch = parse(search)

  if (value) {
    parsedSearch[name] = value
  } else {
    delete parsedSearch[name]
  }

  navigate({ pathname, search: stringify(parsedSearch), state: { forceReset: true } })
}

export const useSetLocationFilters = () => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  return useCallback(({ name, value }, resetOthers) => {
    if (resetOthers === true) {
      setFiltersResetAll({ navigate, pathname, name, value })
    } else if (Array.isArray(resetOthers)) {
      setFiltersResetSome({ navigate, pathname, search, name, value, resetOthers })
    } else {
      setFilters({ navigate, pathname, search, name, value })
    }
  }, [navigate, search, pathname])
}
