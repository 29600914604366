import { Behaviour } from 'modals/auction/claim/behaviour'
import { Form } from 'modals/auction/claim/form'
import { BootstrapModal } from 'components/bootstrap-modal'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'

export const Claim = () => {
  const auction = useSelectedAuction()

  return <BootstrapModal id={'claim-auction'}>
    <Behaviour />
    <Form auction={auction} />
  </BootstrapModal>
}

export * from 'modals/auction/claim/slices/claim-auction'
export * from 'modals/auction/claim/epics/claim'
