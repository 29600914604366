import { Loading } from 'loading'
import { useCallback } from 'react'
import { settings } from 'settings/fetching'
import { useSelector } from 'react-redux'

export const useUpdateLoadings = tab => {
  const showGhosts = Loading.useShowGhosts(tab.loadingFactory)
  const hideGhosts = Loading.useHideGhosts()
  const total = tab.selector()
  const fetched = useSelector(tab.itemsSelector).length

  return useCallback(() => {
    const ghostsCount = settings().listRequestSize
    const ghostsToDisplay = fetched + ghostsCount > total ? total - fetched : ghostsCount

    hideGhosts()
    showGhosts(tab.loadingID, ghostsToDisplay)
  }, [tab, fetched, hideGhosts, showGhosts, total])
}
