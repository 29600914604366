import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { accounts as repository } from 'repositories/accounts'
import { actions } from 'my-tokens/slices/get'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.paymentTokens(payload)
  .pipe(mergeMap(result => of(result.data)))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = payload => from([
  actions.failure(payload),
])
