import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'explore-nfts/tabs/collections/slices/get'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const useForceResetState = () => {
  const dispatch = useDispatch()
  const selectedFacets = useFilterValue('filter')
  const selectedSortBy = useFilterValue('sort')

  useEffect(() => {
    dispatch(actions.reset({}))
  }, [dispatch, selectedFacets, selectedSortBy])
}
