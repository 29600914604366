import { FlexRow } from 'components/flex-row'
import { useWatchActions } from 'nft-page/details/other-listings/other-auction/hooks/use-watch-actions'
import { UserDetails } from 'components/user-details'
import { useHeroTag } from 'components/user-details/hooks/use-hero-tag'
import { Price } from 'components/price'
import moment from 'moment'
import { useCurrentPrice } from 'nft-page/auction/hooks/use-current-price'
import { Content } from 'nft-page/details/other-listings/content'
import { get } from 'lodash'
import 'nft-page/details/other-listings/other-auction/index.scss'

const getTimeLeft = date => {
  const endDate = moment(date * 1000)
  const timeLeft = endDate.fromNow(true)

  return endDate < moment.now() ? `Ended ${timeLeft} ago` : `${timeLeft} left`
}

export const OtherAuction = ({ auction, asset }) => {
  useWatchActions(auction)
  const endDate = get(auction, 'endDate', '')
  const date = endDate !== 0 ? getTimeLeft(auction.endDate) : ''
  const price = useCurrentPrice(auction)

  return <div className={'other-auction'}>
    <FlexRow className={'align-items-center'}>
      <UserDetails date={date} image={auction.owner.profile} herotag={useHeroTag(auction.owner)}
                   address={auction.ownerAddress} />
      <Price price={price} />
      <div className={'d-none d-sm-block'}>
        <Content auction={auction} asset={asset} />
      </div>
    </FlexRow>
    <FlexRow className={'justify-content-end align-items-center d-sm-none'}>
      <Content auction={auction} asset={asset} />
    </FlexRow>
  </div>
}

