import { useCallback } from 'react'
import { useHasInsufficientFunds } from 'components/forms/place-bid-form/hooks/use-has-insufficient-funds'

export const useTestBalance = (auction, paymentToken) => {
  const hasInsufficientFunds = useHasInsufficientFunds(auction, paymentToken)

  return useCallback((value, context) => {
    if (!hasInsufficientFunds(value)) return true

    return context.createError({ message: `Insufficient ${paymentToken.symbol} funds`, path: context.path })
  }, [hasInsufficientFunds, paymentToken])
}
