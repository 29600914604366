import devnet from 'settings/environments/devnet.json'
import testnet from 'settings/environments/testnet.json'
import mainnet from 'settings/environments/mainnet.json'
import staging from 'settings/environments/staging.json'

export const environments = () => ({
  devnet,
  testnet,
  staging,
  mainnet,
})
