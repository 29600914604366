import { Link } from 'react-router-dom'
import { colors } from 'components/username/colors'
import { accountPath } from 'common/account-path'
import { ExplorerLink } from 'components/username/explorer-link'
import { Badge } from 'components/username/badge'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'
import { usePurgedHerotag } from 'components/username/hooks/use-purged-herotag'
import { useIsSubdomainDisabledClass } from 'common/hooks/use-is-subdomain-disabled-class'
import { LinkContent } from 'components/username/link-content'

const useProps = ({ color, className }) => ({ className: `username color-${colors[color]} ${className}` })

const makeLocation = (path, layeredLocation, openNewLayer) => (
  openNewLayer ? layeredLocation(path, 'middleground') : { pathname: path }
)

const makeLinkProps = ({ path, layeredLocation, openNewLayer, disabledClass }) => {
  const { pathname, state } = makeLocation(path, layeredLocation, openNewLayer)

  return {
    to: pathname,
    state,
    className: `username-link ${disabledClass}`,
  }
}

export const Content = props => {
  const purgedHerotag = usePurgedHerotag(props.herotag)
  const path = accountPath(props.address, purgedHerotag && `@${purgedHerotag}`)
  const layeredLocation = useLayeredLocation()
  const disabledClass = useIsSubdomainDisabledClass()
  const linkProps = makeLinkProps({ path, layeredLocation, disabledClass, openNewLayer: props.openNewLayer })

  return <div {...useProps({ color: props.color, className: props.className })}>
    {props.isPrivate ? <LinkContent {...props} /> : <Link {...linkProps}><LinkContent {...props} /></Link>}
    <Badge showBadge={props.showTopBidderBadge} />
    <ExplorerLink address={props.address} showExplorerLink={props.showExplorerLink} />
  </div>
}
