import { combineReducers } from 'redux'
import { get } from 'feed/slices'
import { reducer as latestTimestamp } from 'feed/refresh'
import { reducer as suggestions } from 'feed/suggestions'

export const reducer = combineReducers({
  get: get.reducer,
  latestTimestamp,
  suggestions,
})

