import { ofType } from 'redux-observable'
import { actions } from 'holoride-campaign/slices/has-claimed-tickets'
import { catchError, mergeMap } from 'rxjs/operators'
import { campaigns as repository } from 'repositories/campaigns'
import { from } from 'rxjs'
import { loading } from 'loading/slices'
import { Loading } from 'loading'
import { failure as failureSlice } from 'failure/slices'
import { history } from 'network'

export const hasClaimedTickets = action$ => action$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.hasClaimedTickets(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  loading.actions.hide({ id: Loading.ID.campaign.page, variant: Loading.VARIANT.partial }),
])

const failure = payload => from([
  actions.failure(payload),
  loading.actions.hide({ id: Loading.ID.campaign.page, variant: Loading.VARIANT.partial }),
  failureSlice.actions.show({ route: history.location.pathname }),
])
