import { combineEpics } from 'redux-observable'
import {
  flagCollection, flagNft,
  setNftScamInfo, clearNftScamInfo, clearNftReport,
  setCollectionScamInfo, clearCollectionScamInfo,
  addFeaturedCollection, removeFeaturedCollection,
  getBlacklistedCollections, addBlacklistedCollection, removeBlacklistedCollection
} from 'admin/epics'
import { reportCollection } from 'admin/epics/report-collection'
import { clearReportCollection } from 'admin/epics/clear-report-collection'

const epics = [
  flagNft, flagCollection, setNftScamInfo, clearNftScamInfo, clearNftReport, setCollectionScamInfo,
  clearCollectionScamInfo, addFeaturedCollection, removeFeaturedCollection, getBlacklistedCollections,
  addBlacklistedCollection, removeBlacklistedCollection, reportCollection, clearReportCollection,
]

export const epic = combineEpics(...epics)

