import 'account/divider/style.scss'
import { Behaviour } from 'account/behaviour'
import { RenderWrapper } from 'account/render-wrapper'
import { AccountWrapper } from 'account/account-wrapper'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Content } from 'account/content'
import { LoadingWrapper } from 'account/content/loading-wrapper'

export const Account = () => <AccountWrapper>
  <ScrollToTopOnMount />
  <Behaviour />
  <LoadingWrapper>
    <RenderWrapper>
      <Content />
    </RenderWrapper>
  </LoadingWrapper>
</AccountWrapper>
