import * as Yup from 'yup'
import * as price from 'components/forms/buy-sft-form/hooks/use-schema'

export const useValidationSchema = (auction, paymentToken) => Yup.object()
  .shape({
    price: price.useSchema(auction, paymentToken),
    identifier: Yup.string()
      .required(),
    auctionId: Yup.number()
      .required(),
    quantity: Yup.number()
      .required('Required')
      .typeError('Should be a number')
      .integer('Should be an integer')
      .min(1, 'Should be greater or equal than 1')
      .max(auction.availableTokens, 'Should be less than available tokens'),
  })
