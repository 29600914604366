import { ActionButton } from 'nft-page/offers/actions/watchable-action/button'
import { useStatus } from 'nft-page/offers/actions/watchable-action/hooks/use-status'

export const WatchableAction = ({ entity, actionType, text, onClick, className, tooltipMessage, canAcceptOffer }) => {
  const status = useStatus(entity, actionType)
  const disabled = !!status || !canAcceptOffer

  return <ActionButton className={className} onClick={onClick} text={text} disabled={disabled}
                       tooltipMessage={disabled ? tooltipMessage : undefined} />
}
