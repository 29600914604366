import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { findOption } from 'components/dropdown/find-option'

export const useSetSelected = ({ setSelected, options, name }) => {
  const { search } = useLocation()
  const value = useFilterValue(name)

  useEffect(() => {
    setSelected(findOption(options, value) || options[0])
  }, [options, search, setSelected, value])
}
