import { createContext, useContext as useReactContext, useState } from 'react'
import { useIsLiked } from 'nft-page/likes/hooks'

export const Context = createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultContext = () => {
  const [isLiked, setIsLiked] = useState(useIsLiked())

  return {
    isLiked,
    setIsLiked,
  }
}
