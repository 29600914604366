import 'sidebar/my-wallet/balance/item/index.scss'

export const Item = ({ title, amount, usdAmount, symbol }) => (
  <div className={'sidebar-balance-item d-flex flex-column align-items-center'}>
    <div className={'sidebar-balance-item-title'}>{title}</div>
    <div className={'sidebar-balance-item-egld'}>
      <span className={'sidebar-balance-item-amount'}>{`${amount}`}</span>
      <span className={'sidebar-balance-item-token'}>{symbol}</span>
    </div>
    {usdAmount && <div className={'sidebar-balance-item-usd'}>{`~ ${usdAmount} USD`}</div>}
  </div>
)
