import { FacetButton } from 'components/facet-button/index'
import { useFilterSetter } from 'filters/hooks/use-filter-setter'
import { useFilterValue } from 'filters/hooks/use-filter-value'

const hasFilter = filter => filter && filter !== 'all'

export const TagButton = ({ disabled }) => {
  const tag = useFilterValue('tag')
  const setFilter = useFilterSetter('tag')
  const filter = useFilterValue('filter')

  return tag && !hasFilter(filter) && <FacetButton label={tag} onClick={() => setFilter({})} selected
                                                   showClose disabled={disabled} />
}
