export const extractGraphqlErrors = result => {
  const errors = []

  if (result.networkError) {
    errors.push(...result.networkError.result.errors)
  } else {
    errors.push(...result.graphQLErrors)
  }

  return errors
}
