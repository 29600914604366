import { useOnClick } from 'auction/actions/end/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { CurrentBid } from 'nft-page/auction/current-bid'
import 'nft-page/auction/actions/end-auction/index.scss'
import { actionTypes } from 'action-statuses/actions-types'
import { WatchableAction } from '../watchable-action'

export const EndAuction = ({ auction, address }) => {
  const endClick = useOnClick(auction)
  const onClick = usePermittedAction(endClick, address)

  return <div className={'nft-page-auction-inner'}>
    <p className={'end-auction-text'}>Current auction has reached the deadline or the maximum bid price.</p>
    <CurrentBid auction={auction} />
    <WatchableAction entity={auction} actionType={actionTypes.end} text={'End'} onClick={onClick} />
  </div>
}

EndAuction.displayName = 'EndAuction'
