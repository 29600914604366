import { useOnClick } from 'auction/actions/place-bid/bid/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/details/other-listings/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { get } from 'lodash'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const Bid = ({ auction, address }) => {
  const bidClick = useOnClick(auction)
  const onClick = usePermittedAction(bidClick, address)
  const minBidTokenSymbol = get(auction, 'minBid.tokenData.symbol')
  const minBidTokenDecimals = get(auction, 'maxBid.tokenData.decimals')
  const amount = useMinimum(auction)
  const price = `${denominateAmount(amount, minBidTokenDecimals, true)} ${minBidTokenSymbol}`

  return <WatchableAction entity={auction} actionType={actionTypes.bid} onClick={onClick} text={'Bid'}
                          className={'btn btn-secondary other-auction-action'} price={price} />
}
