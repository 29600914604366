import Tags from '@yaireo/tagify/dist/react.tagify' // React-wrapper file
import '@yaireo/tagify/dist/tagify.css'
import { useField } from 'formik'
import { useRef } from 'react'
import { config } from './config'
import { useHandleTagifyBlur } from './hooks/use-handle-tagify-blur'
import { useHandleTagifyChange } from './hooks/use-handle-tagify-change'
import { useInputEventListeners } from './hooks/use-input-listeners'
import './tags-field.scss'
import { Label } from 'components/forms/nerds-form/fields/label'

export const TagsField = ({ name, label }) => {
  const [field, meta, { setTouched }] = useField(name)
  const tagifyRef = useRef()

  useInputEventListeners(tagifyRef, field)

  return <div className={'tags-field nerds-form-field'}>
    <Label className={'form-label'} name={name}>{label}</Label>
    <Tags tagifyRef={tagifyRef} name={name} onChange={useHandleTagifyChange(tagifyRef, setTouched)}
          settings={config} value={field.value} onBlur={useHandleTagifyBlur(tagifyRef)} />
    {meta.touched && meta.error && <div className={'text-danger'}>{meta.error}</div>}
  </div>
}
