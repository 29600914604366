import { gql } from 'graphql-tag'

export const GET_EXPLORE_STATS = gql`
    query exploreStats {
        exploreStats
        {
            artists
            collections
            nfts
        }
    }
`
