import { faDatabase } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { KeyPair } from 'nft-page/details/other-details/key-pair'

export const Identifier = () => {
  const identifier = useNftSelector('nftPage.data.assets.edges[0].node.identifier')

  return <KeyPair icon={<FontAwesomeIcon icon={faDatabase} />} title={'Identifier'}>
    <span className={'accent'}>{identifier}</span>
  </KeyPair>
}
