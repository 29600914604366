import PropTypes from 'prop-types'

export const FlexRow = ({ className, children }) => <div className={`${className} d-flex flex-row`}>
  {children}
</div>

FlexRow.prototype = {
  className: PropTypes.string,
  children: PropTypes.element,
}

FlexRow.defaultProps = { className: '' }
