import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'modals/account/followers/behaviour'
import { ModalContent } from 'modals/account/followers/modal-content'
import { Title } from 'modals/account/followers/title'
import { List } from 'modals/account/followers/list'
import { Close } from 'modals/account/followers/close'
import 'modals/account/followers/style.scss'

export const Followers = () => <BootstrapModal id={'followers'} className={'followers-modal'}>
  <Behaviour />
  <ModalContent>
    <Title />
    <List />
    <Close />
  </ModalContent>
</BootstrapModal>

export { reducer } from 'modals/account/followers/reducer'
export { epic } from 'modals/account/followers/epic'
