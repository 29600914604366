import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

const makePathname = ({ address, variant }) => `/${address}/modal/${variant}`

export const useProfileModalPath = variant => {
  const { address } = useParams()

  return useMemo(() => makePathname({ address, variant }), [address, variant])
}
