import { useDispatch } from 'react-redux'
import { useGetSignedTransactions, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks'
import { useEffect } from 'react'
import { actions } from 'collections/slices/look-up-transaction'
import { get } from 'lodash'
import { useTransaction } from 'transaction/hooks'

const hasEnqueued = transactions => get(transactions, 'hasPendingTransactions', true)

const base = transaction => `${transaction.sessionId}.transactions[0]`

const matchSession = (tracked, signed) => get(signed, base(tracked), { hash: '' })

const useLastTransaction = () => {
  const trackedTransaction = useTransaction()
  const { signedTransactions } = useGetSignedTransactions()
  const pendingTransactions = useGetPendingTransactions()

  return !hasEnqueued(pendingTransactions) && matchSession(trackedTransaction, signedTransactions)
}

export const useLookUpTransaction = () => {
  const dispatch = useDispatch()
  const lastTransaction = useLastTransaction()

  useEffect(() => {
    if (lastTransaction.hash) {
      dispatch(actions.attempt(lastTransaction.hash))
    }
  }, [dispatch, lastTransaction])
}
