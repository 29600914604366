import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Errors } from 'components/forms/nerds-form'
import { InputSubmitField } from 'components/forms/nerds-form/fields/input-submit-field'
import { ListField } from 'components/forms/add-remove-blacklisted-collections-form/list-field'
import { CancelButton } from 'components/forms/nerds-form/fields/cancel-button'

export const Fields = ({ callbackUrl, submitErrors, blacklistedCollections }) => (
  <div className={'modal-content'}>
    <Title icon={faGavel} title={'Add or remove blacklisted collections'} margins={'mb-4'} />
    <InputSubmitField label={'Collection Identifier'} name={'collection'}
                      callbackUrl={callbackUrl} buttonLabel={'Add blacklisted collection'} disabled={false} />
    {blacklistedCollections.length ? <ListField collections={blacklistedCollections} callbackUrl={callbackUrl} /> : ''}
    <CancelButton callbackUrl={callbackUrl} />
    <Errors values={submitErrors} />
  </div>
)
