import { useCallback } from 'react'
import { get } from 'lodash'

export const useTestHasEnoughTokens = (balance, quantity, auctions) => {
  const hasAuctions = auctions.length !== 0

  return useCallback((value, context) => {
    const [auction] = auctions.filter(item => item.id === value)
    const auctionedTokens = get(auction, 'nrAuctionedTokens')
    const canAccept = parseInt(auctionedTokens) === parseInt(quantity)
    if (hasAuctions && canAccept) return true

    return context.createError({ message: `Not enough tokens`, path: context.path })
  }, [auctions, hasAuctions, quantity])
}
