import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions } from '../slices/social-links'

export const useSocialLinks = () => {
  const dispatch = useDispatch()

  return useCallback(() => dispatch(actions.attempt()), [dispatch])
}
