import { get } from 'lodash'
import { ButtonSecondary } from 'components/button-secondary'
import { useOnNftClick } from 'collections/actions/create-nft/hooks/use-on-nft-click'

export const CreateNft = ({ collection }) => {
  const collectionType = get(collection, 'node.type', '')
  const isSft = collectionType === 'SemiFungibleESDT'
  const message = isSft ? 'SFT' : 'NFT'

  return <ButtonSecondary icon={'add'} onClick={useOnNftClick()}>
    {`Create ${message}`}
  </ButtonSecondary>
}
