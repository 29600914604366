import { graph } from 'network'
import { TAGS } from 'repositories/tags/queries/tags'
import { mergeMap, toArray, map } from 'rxjs/operators'
import { uppercaseFirstLetter } from 'common/uppercase-first-letter'

const getTags = payload => graph().query({ query: TAGS, variables: payload })
  .pipe(mergeMap(({ data }) => data.tags.edges))
  .pipe(map(({ node }) => ({ label: uppercaseFirstLetter(node.tag), value: node.tag, count: node.count })))
  .pipe(toArray())
  .pipe(map(results => [{ label: 'All', value: '' }, ...results]))

export const tags = { getTags }
