import { useResetCollected } from 'account/tabs/assets/collected/behaviour/hooks/use-reset-collected'
import { useResetList } from 'account/tabs/assets/collected/hooks/use-reset-list'
import { useActiveTab } from 'account/tabs/hooks/use-active-tab'
import { useEffect } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useResetState = () => {
  const resetCollected = useResetCollected()
  const resetList = useResetList()
  const address = useViewerAddress()
  const activeTab = useActiveTab()

  useEffect(() => {
    resetList()
    resetCollected()
  }, [resetCollected, resetList, address, activeTab])
}
