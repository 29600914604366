import { ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { actions } from '../slices/buy-campaign'
import { from, of } from 'rxjs'
import { extractGraphqlErrors } from '../../../../repositories/extract-graphql-errors'
import { campaigns } from '../../../../repositories/campaigns'
import { throwOnInsufficientFunds } from '../../../throw-on-insufficient-funds'

export const buyCampaign = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(({ payload }) => execute(payload)))

const execute = payload => campaigns.buyCampaign(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.buyRandomNft, factor: 2 })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = result => of(actions.failure(extractGraphqlErrors(result)))
