import { FlexColumn } from 'components/flex-column'
import { CheckSwitchField } from 'components/forms/nerds-form/fields/check-switch-field'

export const Attributes = () => <fieldset className={'mb-4'}>
  <FlexColumn>
    <CheckSwitchField label={'Can Freeze'} name={'canFreeze'} />
    <CheckSwitchField label={'Can Wipe'} name={'canWipe'} />
    <CheckSwitchField label={'Can Pause'} name={'canPause'} />
    <CheckSwitchField label={'Can Transfer'} name={'canTransferNFTCreateRole'} />
  </FlexColumn>
</fieldset>
