import { useAuctionTimings } from 'nft-page/hooks/use-auction-pooling/use-auction-timings'
import { useTimeDiff } from 'nft-page/hooks/use-auction-pooling/use-time-diff'
import { get, sortedIndex } from 'lodash'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'

const extractIntervals = timings => timings.map(({ left }) => left)

const findIndex = (timings, timeDiff) => sortedIndex(extractIntervals(timings), timeDiff)

export const useIntervalPooling = () => {
  const timings = useAuctionTimings()
  const timeDiff = useTimeDiff()
  const auction = useNftDefaultAuction()
  const endDate = get(auction, 'endDate')

  return endDate !== 0 ? get(timings, `[${findIndex(timings, timeDiff)}].pooling`, 0) : 600000
}
