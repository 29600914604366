import { useEffect } from 'react'
import { useResetList } from 'account/tabs/assets/bids/hooks/use-reset-list'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useResetBids } from 'account/tabs/assets/bids/hooks/use-reset-bids'

export const useResetState = () => {
  const address = useViewerAddress()
  const resetList = useResetList()
  const resetBids = useResetBids()

  useEffect(() => {
    resetList()
    resetBids()
  }, [resetBids, resetList, address])
}
