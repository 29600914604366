import 'sidebar/connect/ledger-login/ledger-container/style.scss'
import { LedgerLoginContainer } from '@multiversx/sdk-dapp/UI'
import { useInternalAccessToken } from 'network/axios-interceptor/use-internal-access-token'
import { extras } from 'repositories/extras'

export const LedgerContainer = ({ callbackRoute }) => {
  extras.getAccessToken()
  const localToken = useInternalAccessToken()
  const nativeAuth = { extraRequestHeaders: { Authorization: `Bearer ${localToken}` } }

  return (
    <LedgerLoginContainer className={'ledger-container'}
                          wrapContentInsideModal={false}
                          callbackRoute={callbackRoute}
                          nativeAuth={nativeAuth} />
  )
}
