import { useEffect, useRef } from 'react'
import { useContext } from 'components/asset-card/time-left/context'

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
}

export const useVisibleItem = itemRef => {
  const observer = useRef(null)
  const { isVisible, setVisible } = useContext()

  useEffect(() => {
    if (setVisible) {
      observer.current = new window.IntersectionObserver(entries => {
        entries[0].isIntersecting ? setVisible(true) : setVisible(false)
      }, observerOptions)

      itemRef.current && observer.current?.observe(itemRef.current)
    }

    return () => observer.current?.disconnect()
  }, [isVisible, setVisible, observer, itemRef])
}
