import { from } from 'rxjs'
import { Failure } from 'failure'
import { mergeMap, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { actions } from 'subdomain/modals/account/my-auctions/slices/get'
import { auctions as repository } from 'repositories/auctions'
import { failure as failureSlice } from 'failure/slices'

export const get = action$ => action$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(action => execute(action.payload)))

const execute = payload => repository.getAccountAuctions(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  actions.append(payload.data.auctions.edges),
])

const failure = errors => from([
  actions.failure(errors),
  failureSlice.actions.show({ variant: Failure.VARIANT.partial, id: 'modal.account.myAuctions' }),
])
