import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { Row } from 'components/row'
import { Item } from 'collections/assets/item'
import { Empty } from 'collections/assets/empty'
import { useActualList } from 'collections/assets/hooks/use-actual-list'
import { useHasMore } from 'collections/assets/hooks/use-has-more'
import { useNext } from 'collections/assets/hooks/use-next'
import { useCollectionAssetsLoading } from 'collections/assets/hooks/use-collection-assets-loading'

export const Content = () => {
  const isLoading = useCollectionAssetsLoading()

  return <InfiniteScroll hasMore={useHasMore()} next={useNext()} isLoading={isLoading}>
    <Row>
      {useActualList().map((item, index) => <Item key={index} item={item} />)}
    </Row>
    <Empty />
  </InfiniteScroll>
}
