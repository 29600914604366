import * as Yup from 'yup'

import { useTests } from 'components/forms/send-offer-form/hooks/use-tests'

export const useAmountSchema = (asset, paymentToken) => {
  const tests = useTests(asset, paymentToken)

  return Yup.string()
    .required('Offer amount is a required field')
    .test('comma', 'Use dot for decimals', tests.testComma)
    .test('number', 'Must be a number', tests.testNumber)
    .test('decimals', 'Too many decimals', tests.testDecimals)
    .test('startDot', 'Must not start with dot', tests.testStartDot)
    .test('endDot', 'Must not end with dot', tests.testEndDot)
    .test('leadingZeros', 'Leading zero is not allowed', tests.testLeadingZeros)
    .test('balance', tests.testBalance)
    .default('')
}
