import { isEmpty } from 'lodash'
import { Failure } from 'failure'
import { useLocation } from 'react-router-dom'
import { useFailures } from 'failure/hooks/use-failures'

const useIds = () => useFailures().map(failure => failure.id)

const useRoutes = () => useFailures().map(failure => failure.route)

const useVariants = () => useFailures().map(failure => failure.variant)

export const useShouldShow = (desiredVariant = Failure.VARIANT.absolute, desiredId = undefined) => {
  const location = useLocation()
  const desiredIds = useIds().filter(id => id === desiredId || !desiredId)
  const desiredRoutes = useRoutes().filter(route => route === location.pathname)
  const desiredVariants = useVariants().filter(variant => variant === desiredVariant)

  return !isEmpty(desiredVariants) && !isEmpty(desiredRoutes) && !isEmpty(desiredIds)
}
