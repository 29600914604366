import { useActOnSocialLinksSuccess } from 'modals/account/edit/hooks/use-act-on-social-links-success'
import { useActOnDescriptionSuccess } from 'modals/account/edit/hooks/use-act-on-description-success'
import { useEffect } from 'react'
import { useActOnPrivacySuccess } from 'modals/account/edit/hooks/use-act-on-privacy-success'
import { actions as currentProfileActions } from 'current-profile/slices/current-profile'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useDispatch } from 'react-redux'

export const useActOnSuccess = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()
  const actOnSocialLinksSuccess = useActOnSocialLinksSuccess()
  const actOnDescriptionSuccess = useActOnDescriptionSuccess()
  const actOnPrivacySuccess = useActOnPrivacySuccess()

  useEffect(() => () => {
    actOnSocialLinksSuccess()
    actOnDescriptionSuccess()
    actOnPrivacySuccess()
    dispatch(currentProfileActions.attempt(address))
  }, [actOnSocialLinksSuccess, actOnDescriptionSuccess, actOnPrivacySuccess, dispatch, address])
}
