import { useFailures } from 'failure/hooks/use-failures'

const DEFAULT_MESSAGE = '...'

export const useMessage = id => {
  const failures = useFailures()
  const failure = failures.find(failure => failure.id === id)

  return failure?.message || DEFAULT_MESSAGE
}
