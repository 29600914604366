import jwtDecode from 'jwt-decode'
import moment from 'moment'

export const isExpired = jwt => {
  try {
    const { exp } = jwtDecode(jwt)
    const currentUnix = +moment().subtract(1, 'minute').unix()

    return exp < currentUnix
  } catch (e) {
    return true
  }
}
