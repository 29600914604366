import { useErrorCallback } from 'components/collection-card/hooks/use-error-callback'

export const Image = ({ image, active }) => {
  const onError = useErrorCallback()
  const activeClass = active ? 'active' : ''

  return <div className={`collection-card-image ${activeClass}`}>
    <img src={image} alt={'...'} onError={onError} />
  </div>
}
