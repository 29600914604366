import { home } from 'home/epics'
import { nft } from 'nft-page/epics'
import { search } from 'search/epics'
import { orders } from 'account/tabs/assets/bids/epics'
import { auction } from 'auction/epics'
import { account } from 'account/epics'
import { network } from 'network/epics'
import { collections } from 'collections/epics'
import { combineEpics } from 'redux-observable'
import { assetHistory } from 'asset-history/epics'
import { currentProfile } from 'current-profile/epics/current-profile'
import { owners } from 'owners/epics'
import { sidebarEpics } from 'sidebar/epics'
import { explore } from 'explore-nfts/epics'
import { epics as modals } from 'modals'
import { address } from 'viewer/epics/address'
import { privacy } from 'viewer/epics/privacy'
import { marketplaces } from 'marketplaces/epics'
import { trending } from 'trending/epics'
import { preferences } from 'preferences/epics'
import { report } from 'report/epics'
import { epic as feed } from 'feed'
import { campaigns } from 'campaigns/epics/campaigns'
import { campaign } from 'campaign/epics/campaign'
import { holorideCampaign } from 'holoride-campaign/epics'
import { tags } from 'tags/epics'
import { notifications } from 'notifications/account/epics'
import { subdomainCollections } from 'subdomain/home/collections/epics'
import { epic as admin } from 'admin'
import { epic as subdomainContent } from 'subdomain/cms-content/epic'
import { epics as subdomainModals } from 'subdomain/modals'
import { epic as inspireContent } from 'cms-content/epic'
import { get as myTokens } from 'my-tokens/epics/get'
import { epic as offers } from 'offers/epic'

const epics = [
  nft,
  account,
  trending,
  home,
  auction,
  collections,
  orders,
  assetHistory,
  search,
  currentProfile,
  network,
  owners,
  sidebarEpics,
  explore,
  modals,
  address,
  privacy,
  marketplaces,
  preferences,
  report,
  feed,
  campaigns,
  campaign,
  holorideCampaign,
  tags,
  notifications,
  subdomainCollections,
  admin,
  subdomainContent,
  subdomainModals,
  inspireContent,
  myTokens,
  offers,
]

export default combineEpics(...epics)
