import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { actions } from 'nft-page/owner/slices/owner'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'

export const useFetchNftWithOwner = () => {
  const { identifier } = useParams()
  const { address } = useGetAccountInfo()
  const dispatch = useDispatch()
  const nft = useNftSelector()

  useEffect(() => {
    if (address && nft.type === 'SemiFungibleESDT') {
      dispatch(actions.attempt({ filters: { identifier, ownerAddress: address } }))
    }
  }, [address, dispatch, identifier, nft])
}
