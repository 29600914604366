import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useOnHide = (easyClose, routeModal) => {
  const location = useCloseLayerLocation()
  const navigate = useNavigate()

  return useCallback(() => {
    easyClose && routeModal && navigate(location, { replace: true })
  }, [easyClose, location, navigate, routeModal])
}
