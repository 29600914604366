import { Loading } from '../../loading'
import { useAuctionsStats } from '../stats/hooks/use-auctions-stats'
import { selector as auctionsSelector } from './assets/auctions/hooks/use-items'
import { useOrdersStats } from '../stats/hooks/use-orders-stats'
import { selector as bidsSelector } from './assets/bids/hooks/use-items'
import { useClaimableStats } from '../stats/hooks/use-claimable-stats'
import { selector as claimableSelector } from './assets/claimable/hooks/use-items'

export const subdomainTabs = {
  auctions: {
    hash: 'auctions',
    label: 'Auctions',
    selector: useAuctionsStats,
    previewSource: 'account-auctions',
    itemsSelector: auctionsSelector,
    loadingID: Loading.ID.account.tab.auctions,
    loadingFactory: Loading.ghosts.auctions,
  },
  bids: {
    hash: 'bids',
    label: 'Bids',
    selector: useOrdersStats,
    previewSource: 'account-bids',
    itemsSelector: bidsSelector,
    loadingID: Loading.ID.account.tab.bids,
    loadingFactory: Loading.ghosts.orders,
  },
  claimable: {
    hash: 'claimable',
    label: 'Claimable',
    selector: useClaimableStats,
    previewSource: 'account-claimable',
    itemsSelector: claimableSelector,
    loadingID: Loading.ID.account.tab.claimable,
    loadingFactory: Loading.ghosts.auctions,
    isAuthenticated: true,
  },
}
