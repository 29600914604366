import { UserInfo } from 'components/user-info'
import { useHeroTag } from 'components/collection-card/hooks/use-hero-tag'
import { useProfileImage } from 'components/collection-card/hooks/use-profile-image'
import { colors } from 'components/username/colors'
import { get } from 'lodash'
import 'components/collection-card/creator/index.scss'

export const useInfoProps = collection => {
  const collectionOwnerAddress = get(collection, 'artist.address')

  return ({
    herotag: useHeroTag(collection),
    image: useProfileImage(collection),
    size: 'small',
    address: collectionOwnerAddress,
    color: colors.primary,
  })
}

export const Creator = ({ collection }) => <div className={'collection-card-creator'}>
  <UserInfo {...useInfoProps(collection)} />
</div>
