import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { denominateAmount } from 'common/conversion/denominate-amount'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const useEgldBalance = () => {
  const { account } = useGetAccountInfo()
  const { price } = useEconomicsSelector()
  const amountWithCommas = account.balance !== '...' ? denominateAmount(account.balance, 18, true) : 0
  const amount = account.balance !== '...' ? denominateAmount(account.balance, 18) : 0
  const decimals = useDenominationDecimals()

  return {
    amount: amountWithCommas,
    usdAmount: parseFloat(`${price * amount}`).toFixed(decimals),
    symbol: egldLabel(),
  }
}
