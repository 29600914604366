import { getCurrentPrice } from 'subdomain/components/my-asset-card/hooks/get-current-price'
import { ReactComponent as MetaspaceIcon } from 'startup/assets/metaspace.svg'
import 'subdomain/components/my-asset-card/price/price.scss'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

const getAuctionPrice = auction => {
  const bid = getCurrentPrice(auction)
  const symbol = get(bid, 'tokenData.symbol')
  const decimals = get(bid, 'tokenData.decimals')
  const egldAmount = denominateAmount(bid.amount, decimals, true)

  return { amount: egldAmount, symbol, usdAmount: bid.usdAmount }
}

const getOfferPrice = offer => {
  const symbol = get(offer, 'price.tokenData.symbol')
  const decimals = get(offer, 'price.tokenData.decimals')
  const amount = get(offer, 'price.amount', '0')
  const usdAmount = get(offer, 'price.usdAmount')
  const egldAmount = denominateAmount(amount, decimals, true)

  return { amount: egldAmount, symbol, usdAmount }
}

export const Price = ({ auction, offer }) => {
  const price = auction ? getAuctionPrice(auction) : getOfferPrice(offer)

  return <div className={'my-asset-card-price justify-content-between'}>
    <div className={'my-asset-card-prices'}>
      <div className={'my-asset-card-price-value'}>
        <MetaspaceIcon />
        <span>
          {`${price.amount} `}
          {` ${price.symbol}`}
        </span>
      </div>
      {price.usdAmount && <div className={'my-asset-card-price-usd'}>{`~ ${price.usdAmount} USD`}</div>}
    </div>
  </div>
}

