import { SetRoles } from 'components/forms/nerds-form/fields/collection-field/set-roles'
import { useField } from 'formik'

export const RolesErrors = ({ name }) => {
  const [field, meta] = useField(name)
  const hasErrors = meta.touched && meta.error?.canCreate

  if (hasErrors) {
    return <div className={'d-flex align-items-center'}>
      <div className={'text-danger position-relative bottom-0 me-2'}>{meta.error?.canCreate}</div>
      <SetRoles collection={field.value} />
    </div>
  } else {
    return ''
  }
}
