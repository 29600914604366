import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions } from 'modals/auction/end/slices/end-auction'
import { useParams } from 'react-router-dom'

export const useEndAuction = auction => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    const variables = { auctionId: parseInt(auction.id), identifier }
    dispatch(actions.attempt(variables))
  }, [auction.id, identifier, dispatch])
}
