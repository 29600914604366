import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setRoles } from 'modals/collection/set-roles/slices/set-roles'

export const useStateCleanup = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(setRoles.actions.reset({}))
  }, [dispatch])
}
