import { from, map } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { actions } from 'search/slices'
import { repository } from 'search/repository'
import { loading } from 'loading/slices'
import { Loading } from 'loading'
import { cleanPayload, filterResults, isHashtag, isHerotag } from 'search/epics/filter-search-term'

export const search = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository(cleanPayload(payload))
  .pipe(map(filterResults(isHashtag(payload.filters.searchTerm), ({ kind }) => kind === 'tag')))
  .pipe(map(filterResults(isHerotag(payload.filters.searchTerm), ({ kind }) => kind === 'account')))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  loading.actions.hide({ id: Loading.ID.search.search, variant: Loading.VARIANT.partial }),
])

const failure = payload => from([
  actions.failure(payload),
  loading.actions.hide({ id: Loading.ID.search.search, variant: Loading.VARIANT.partial }),
])
