import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: '',
  data: [],
  errors: '',
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, data: payload.data, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: payload, loading: false })

const reset = () => initialState

export const tweets = createSlice({
  initialState,
  name: 'home/section/tweets',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})
