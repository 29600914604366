import PropTypes from 'prop-types'
import 'components/player-controls/button/index.scss'
import { Icon } from 'components/player-controls/button/icon'

export const Button = ({ onClick, type }) => {
  const className = `player-controls__button type-${type} d-flex align-items-center justify-content-center`

  return <button onClick={onClick} className={className}>
    <Icon type={type} />
  </button>
}

Button.defaultProps = { onClick: () => null }

Button.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

