import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { address } from 'viewer/slices/address'
import { actions as profileActions } from 'account/profile/slices/profile'
import { actions as bidsActions } from 'account/tabs/assets/bids/slices/get'
import { actions as auctionActions } from 'account/tabs/assets/auctions/slices/get'
import { actions as collectedActions } from 'account/tabs/assets/collected/slices/get'
import { actions as creationsActions } from 'account/tabs/assets/creations/slices/get'
import { actions as claimableActions } from 'account/tabs/assets/claimable/slices/get'
import { actions as collectionsActions } from 'account/tabs/assets/collections/slices/get'
import { followers } from 'account/profile/follow/slices/followers'
import { followees } from 'account/profile/follow/slices/followees'
import { follows } from 'account/profile/follow/slices/follows'

export const usePageCleanUp = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(auctionActions.cleanUp())
    dispatch(bidsActions.cleanUp())
    dispatch(collectedActions.cleanUp())
    dispatch(collectionsActions.cleanUp())
    dispatch(creationsActions.cleanUp())
    dispatch(address.actions.cleanUp())
    dispatch(claimableActions.cleanUp())
    dispatch(profileActions.cleanUp())
    dispatch(followers.actions.cleanUp())
    dispatch(followees.actions.cleanUp())
    dispatch(follows.actions.cleanUp())
  }, [dispatch])
}
