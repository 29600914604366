import 'account/profile-cover/image/index.scss'
import defaultCover from 'startup/assets/cover.jpg'
import { useErrorCallback } from 'account/profile-cover/image/hooks/use-error-callback'
import { get } from 'lodash'
import { useProfileData } from 'account/header/hooks/use-profile-data'

export const Image = () => {
  const currentProfileData = useProfileData()
  const coverImage = get(currentProfileData, 'cover.url') || defaultCover

  return <img className={`hero-cover-image`}
              alt={'cover_image'} src={coverImage} onError={useErrorCallback()} />
}

