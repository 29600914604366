import { icons } from 'components/report-button/icons'

import 'components/report-button/index.scss'

const useGetReportData = isReported => {
  const reported = { icon: icons.check, text: 'Reported', disabled: true }
  const report = { icon: icons.report, text: 'Report', disabled: false }

  return isReported ? reported : report
}

export const ReportButton = ({ onClick, isLoading, isReported }) => {
  const report = useGetReportData(isReported)

  return <button className={'btn report-button'} onClick={onClick} disabled={isLoading || report.disabled}>
    {report.icon}
    {report.text}
  </button>
}

