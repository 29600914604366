import { Line } from 'components/dropdown-traits/dropdown-button/line'

export const ClearButton = ({ isSelectedOptionEmpty, name, onChange }) => <div className={'clear-button'}>
  {!isSelectedOptionEmpty && <button className={'clear-button-btn'} onClick={event => {
    event.stopPropagation()
    onChange({ name, values: [] })
  }}>
    Clear
  </button>}
  <Line isSelectedOptionEmpty={isSelectedOptionEmpty} />
</div>
