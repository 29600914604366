import PropTypes from 'prop-types'
import { Layout } from './layout'
import { Dropzone } from './fieldsets/dropzone/dropzone'
import { Fields } from './fieldsets/fields'

export const CreateNftForm = props => <Layout {...props}>
  <Dropzone />
  <Fields submitErrors={props.submitErrors} selectableCollection={props.selectableCollection} />
</Layout>

CreateNftForm.defaultProps = { selectableCollection: false }

CreateNftForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.any.isRequired,
  initialValues: PropTypes.any.isRequired,
  selectableCollection: PropTypes.bool,
  callbackUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}
