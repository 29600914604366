import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export const useOnLaunchClick = () => {
  const navigate = useNavigate()

  return useCallback(() => {
    navigate(0)
  }, [navigate])
}
