import { useCallback } from 'react'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'

export const useOnClickAcceptOffer = (asset, offer) => {
  const navigate = useLayeredNavigate()

  return useCallback(() => {
    const path = `/nfts/${asset.identifier}/modal/accept-offer/${offer.id}`
    navigate(path, 'modal')
  }, [asset.identifier, navigate, offer.id])
}
