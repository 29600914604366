import { Errors, NerdsForm } from 'components/forms/nerds-form'
import { config } from 'components/forms/create-collection-form/config'
import { Type } from 'components/forms/create-collection-form/fieldsets/type'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Attributes } from 'components/forms/create-collection-form/fieldsets/attributes'
import { Identifiers } from 'components/forms/create-collection-form/fieldsets/identifiers'

const useFormProperties = props => ({
  loading: props.loading,
  config: config({ onSubmit: props.onSubmit }),
  submitErrors: props.submitErrors,
})

export const CreateCollectionForm = props => <NerdsForm {...useFormProperties(props)}>
  <Identifiers />
  <Type />
  <Attributes />
  <DefaultActions callbackUrl={props.callbackUrl} label={'Create'} />
  <Errors values={props.submitErrors} />
</NerdsForm>

