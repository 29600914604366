import { useRef } from 'react'
import { useVisibleItem } from 'subdomain/components/my-asset-card/time-left/hooks/use-visible-item'

export const Content = ({ label, suffix, type }) => {
  const itemRef = useRef(null)
  useVisibleItem(itemRef)

  return <div className={`my-asset-card-badge badge-${type.className}`} ref={itemRef}>
    <div className={'my-asset-card-badge-inner'}>
      <span className={'my-asset-card-badge-label'}>{label}</span>
      {suffix && <span className={'my-asset-card-badge-suffix'}>{suffix}</span>}
    </div>
  </div>
}

