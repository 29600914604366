import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useSearchReset } from 'search/hooks/use-search-reset'
import { actions } from 'search/slices'

export const useRunSearch = () => {
  const dispatch = useDispatch()
  const resetSearch = useSearchReset()

  return useCallback(searchTerm => {
    resetSearch()

    searchTerm && dispatch(actions.attempt({ filters: { searchTerm } }))
  }, [dispatch, resetSearch])
}
