import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const Value = ({ value }) => {
  const valueRef = useRef(null)
  useTooltip(valueRef)

  return <div className={'nft-page-trait-value'} data-bs-toggle={'tooltip'} data-bs-placement={'top'} title={value}
              ref={valueRef}>
    {value}
  </div>
}
