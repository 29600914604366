import { Loading } from 'loading'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'campaigns/slices/campaigns'
import { settings } from 'settings/fetching'
import { useFetchPayload } from 'campaigns/hooks/use-fetch-payload'

const useIssueServer = () => {
  const dispatch = useDispatch()
  const payload = useFetchPayload()

  return useCallback(() => {
    dispatch(actions.attempt(payload))
  }, [dispatch, payload])
}

export const useNext = () => {
  const hideGhosts = Loading.useHideGhosts()
  const showGhosts = Loading.useShowGhosts()
  const issueServer = useIssueServer()

  return useCallback(() => {
    hideGhosts(Loading.ID.campaigns.page)
    showGhosts(Loading.ID.campaigns.page, settings().listRequestSize)
    issueServer()
    // eslint-disable-next-line
    }, [ hideGhosts, issueServer, showGhosts])
}
