import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { Row } from 'components/row'
import { Item } from 'collections/auctions/item'
import React from 'react'
import { Empty } from 'collections/auctions/empty'
import { useHasMore } from 'collections/auctions/hooks/use-has-more'
import { useNext } from 'collections/auctions/hooks/use-next'
import { useActualList } from 'collections/auctions/hooks/use-actual-list'
import { useCollectionAuctionsLoading } from 'collections/auctions/hooks/use-collection-auctions-loading'

export const Content = () => {
  const isLoading = useCollectionAuctionsLoading()

  return <InfiniteScroll hasMore={useHasMore()} next={useNext()} isLoading={isLoading}>
    <Row>
      {useActualList().map((item, index) => <Item key={index} item={item} />)}
    </Row>
    <Empty />
  </InfiniteScroll>
}
