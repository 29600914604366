import { createSlice } from '@reduxjs/toolkit'

const initialState = { data: '' }

const set = (_, { payload: data }) => ({ data })

const unSet = () => initialState

export const cursor = createSlice({
  initialState,
  name: 'trending/cursor',
  reducers: {
    set,
    unSet,
  },
})

export const { actions } = cursor
