import { useAppContext } from 'startup/context'
import { useCallback } from 'react'

export const useSetFooterVisible = () => {
  const { setFooterVisible } = useAppContext()

  return useCallback(value => {
    setFooterVisible(value)
  }, [setFooterVisible])
}
