import { map } from 'rxjs'
import { getMainnetThumbnailApi } from 'repositories/feed/common/get-mainnet-thumbnail-api'

const format = ({ timestamp, extraInfo, who, count, reference }) => ({
  who,
  when: timestamp,
  whom: {
    count,
    identifier: extraInfo.identifier,
    collection: {
      name: extraInfo.collectionName,
      id: reference,
    },
    name: extraInfo.identifier,
    media: [
      { thumbnailUrl: `${getMainnetThumbnailApi()}/nfts/${extraInfo.identifier}/thumbnail` },
    ],
  },
  action: { type: 'mintNft' },
})

export const mintNft = group => group
  .pipe(map(format))
