import { useField } from 'formik'
import { useCallback } from 'react'

export const useOnClick = (name, value) => {
  const [,, { setValue }] = useField(name)

  return useCallback(() => {
    setValue(value)
  }, [setValue, value])
}

