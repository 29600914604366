import { AccountLink } from './account-link/account-link'
import { ConnectLink } from '../connect-link'
import { useIsPrinceCharming } from '../../../../auth/mega-authorized'

export const Connect = () => {
  const isPrinceCharming = useIsPrinceCharming()

  return <>
    {isPrinceCharming && <AccountLink />}
    {!isPrinceCharming && <ConnectLink />}
  </>
}

