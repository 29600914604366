import { Empty as EmptyState } from 'components/empty'
import { ExploreLink } from 'account/content/empty/explore-link'
import { ReactComponent as PrivateIcon } from 'startup/assets/private-account.svg'
import 'account/content/empty/index.scss'

const stateProps = {
  message: 'This user chose to make their account private.',
  Action: ExploreLink,
  illustration: <PrivateIcon />,
  title: 'This account is private',
}

export const Empty = () => <div className={'profile-private-empty'}>
  <EmptyState {...stateProps} />
</div>
