import { useParams } from 'react-router-dom'
import { CreateCollectionForm } from 'components/forms/create-collection-form'
import { useIsLoading } from 'modals/collection/create/issue/hooks/use-is-loading'
import { useServerErrors } from 'modals/collection/create/issue/hooks/use-server-errors'
import { useSubmitCallback } from 'modals/collection/create/issue/hooks/use-submit-callback'
import { useData, useNftData } from 'modals/collection/create/issue/hooks/use-data'
import { actions as nftActions } from 'modals/collection/create/issue/slices/nft'
import { actions as sftActions } from 'modals/collection/create/issue/slices/sft'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { actionTypes } from 'action-statuses/actions-types'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

const useProperties = () => ({
  loading: useIsLoading(),
  onSubmit: useSubmitCallback(),
  submitErrors: useServerErrors(),
  callbackUrl: useCloseLayerLocation(),
})

const useResetAction = () => {
  const nftData = useNftData()

  return nftData ? nftActions.reset : sftActions.reset
}

const useCallbackUrl = () => {
  const { address } = useParams()
  const nftData = useNftData()
  const path = `/${address}/modal/create-collection/${nftData ? 'setRoles' : 'setSftRoles'}`
  const makeLocation = useLayeredLocation()

  return makeLocation(path, 'modal')
}

export const Form = () => {
  const data = useData()
  const callbackUrl = useCallbackUrl()
  const resetAction = useResetAction()
  const { address } = useParams()

  const actionIdentifier = createActionIdentifier(address, actionTypes.createCollection)

  useOnSentPayload({ data, callbackUrl, resetAction, actionIdentifier })

  return <CreateCollectionForm {...useProperties()} />
}
