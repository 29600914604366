import { useOnClick } from 'auction/actions/place-bid/buy/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/details/other-listings/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const Buy = ({ auction, address }) => {
  const buyClick = useOnClick(auction)
  const onClick = usePermittedAction(buyClick, address)
  const maxBidAmount = get(auction, 'maxBid.amount')
  const maxBidTokenSymbol = get(auction, 'maxBid.tokenData.symbol')
  const maxBidTokenDecimals = get(auction, 'maxBid.tokenData.decimals')
  const price = `${denominateAmount(maxBidAmount, maxBidTokenDecimals, true)} ${maxBidTokenSymbol}`

  return <WatchableAction entity={auction} actionType={actionTypes.bid} text={'Buy'} onClick={onClick}
                          className={'btn btn-secondary other-auction-action'} price={price} />
}
