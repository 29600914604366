import { useFolloweesThreshold } from 'feed/initial/hooks/use-followees-threshold'

export const Left = () => <div className={'d-flex flex-column justify-content-center mb-3 text-center text-md-start'}>
  <span className={'mb-1 initial-heading'}>Build out your feed</span>
  <span>
    Here are some suggestions. Follow at least
    {' '}
    <span className={'initial-bold'}>
      {useFolloweesThreshold()}
      {' '}
      creators
    </span>
    {' '}
    to build your feed.
  </span>
</div>
