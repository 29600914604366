import 'components/labeled-progress/index.scss'

const makeLeftLabel = available => available > 0 ? `${available} Left` : 'Sold out'
const makeClass = (status, size) => `labeled-progress ${size} ${status && status.toLowerCase()}`

export const LabeledProgress = ({
  status, total,
  available, size = 'normal',
}) => <div className={makeClass(status, size)}>
  <div className={'labeled-progress-labels'}>
    <span className={'labeled-progress-pieces'}>
      {total}
      {' '}
      Pieces
    </span>
    <span className={'labeled-progress-left'}>
      {makeLeftLabel(available)}
    </span>
  </div>
  <div className={'labeled-progress-bar'} style={{ '--progress': `${100 * (total - available) / total}%` }} />
</div>
