import defaultAvatar from 'common/default-avatar'
import { UserInfo } from 'components/user-info'
import { get } from 'lodash'
import { useGetUserInfo } from 'modals/account/followers/hooks/use-get-user-info'

export const Account = ({ address }) => {
  const userInfo = useGetUserInfo(address)
  const image = get(userInfo, 'profile.url', '') || defaultAvatar
  const herotag = get(userInfo, 'herotag')
  const isPrivate = get(userInfo, 'privacy') === 'private'

  return <div className={'followers-item'}>
    <UserInfo image={image} herotag={herotag} address={address} color={'primary'} className={'followers-modal-item'}
              isPrivate={isPrivate} />
  </div>
}
