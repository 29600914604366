import { get } from 'lodash'
import { useFilters } from 'filters/hooks/use-filters'

export const useIsOnSale = () => {
  const filters = useFilters()
  const backgroundOnSale = get(filters, 'background[on-sale]', false) === 'true'
  const foregroundOnSale = get(filters, 'foreground[on-sale]', false) === 'true'
  const onSale = get(filters.search, 'on-sale', false) === 'true'

  return onSale || backgroundOnSale || foregroundOnSale
}
