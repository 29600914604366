import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import moment from 'moment'
import { actions } from 'modals/auction/start/slices/create-auction'
import { nominateAmount } from 'common/conversion/nominate-amount'

export const useCreateVariableAuction = (asset, paymentToken) => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    const deadline = moment(payload.deadline).unix()
      .toString()

    const { minBid, quantity } = payload

    const variables = {
      identifier: asset.identifier,
      quantity: `${quantity}`,
      minBid: nominateAmount(minBid, paymentToken.decimals),
      maxBid: nominateAmount(minBid, paymentToken.decimals),
      deadline,
      paymentToken: paymentToken.identifier,
      maxOneSftPerPayment: true,
    }

    return dispatch(actions.attempt(variables))
  }, [asset.identifier, dispatch, paymentToken.decimals, paymentToken.identifier])
}
