import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/pro-light-svg-icons'
import { useOnClick } from 'feed/refresh/hooks'
import 'feed/refresh/style.scss'

const buttonClassname = 'btn btn-primary rounded-pill refresh-button d-flex align-items-center p-2'

export const Refresh = () => <div className={'d-flex justify-content-center'}>
  <div className={'refresh-button-wrapper'}>
    <div role={'button'} className={buttonClassname} onClick={useOnClick()}>
      <div className={'refresh-button-icon-container'}>
        <FontAwesomeIcon icon={faRedo} />
      </div>
      <div className={'refresh-button-message'}>
        New updates
      </div>
    </div>
  </div>
</div>

export * from 'feed/refresh/reducer'
export * from 'feed/refresh/epic'
export * from 'feed/refresh/hooks'
export * from 'feed/refresh/visibility-wrapper'
