import { useUnfollow } from 'explore-nfts/tabs/creators/hooks/use-unfollow'
import { useCallback } from 'react'

export const useOnUnFollow = (setIsFollowedLocal, address) => {
  const unfollow = useUnfollow(address)

  return useCallback(() => {
    unfollow()
    setIsFollowedLocal(false)
  }, [setIsFollowedLocal, unfollow])
}
