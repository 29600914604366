import { useCallback } from 'react'
import { useOffersPageInfo } from 'offers/hooks/use-offers-page-info'
import { Button } from 'nft-page/offers/button'
import { useOffersLoadMore } from 'offers/hooks/use-offers-load-more'

const useOnClick = () => {
  const { hasNextPage } = useOffersPageInfo()
  const offersLoadMore = useOffersLoadMore()

  return useCallback(() => {
    hasNextPage && offersLoadMore()
  }, [hasNextPage, offersLoadMore])
}

export const LoadMore = () => {
  const { hasNextPage } = useOffersPageInfo()
  const onClick = useOnClick()

  return hasNextPage ? <Button onClick={onClick} /> : ''
}
