import { ofType } from 'redux-observable'
import { get as slice } from 'feed/refresh/slices'
import { latestTimestamp } from 'repositories/feed'
import { of, mergeMap, catchError } from 'rxjs'

export const get = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = () => latestTimestamp()
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(slice.actions.success(payload))

const failure = payload => of(slice.actions.failure(payload))
