import { useCallback } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { settings } from 'settings/fetching'

export const useFetchPayload = () => {
  const ownerAddress = useViewerAddress()

  return useCallback(({ cursor }) => ({
    pagination: { first: settings().listRequestSize, after: cursor },
    filters: { ownerAddress },
  }), [ownerAddress])
}
