import * as Yup from 'yup'

const maxNftsMessage = max => `Should be less or equal to maximum  ${max} allowed per transactions`

export const useValidationSchema = (tier, campaign) => Yup.object().shape({
  quantity: Yup.number()
    .required('Required')
    .typeError('Should be a number')
    .integer('Should be an integer')
    .min(1, 'Should be greater or equal than 1')
    .max(tier.availableNfts, 'Should be less than available tokens')
    .max(campaign.maxNftsPerTransaction, maxNftsMessage(campaign.maxNftsPerTransaction)),
})
