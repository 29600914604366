import { useCallback } from 'react'
import { logout } from '@multiversx/sdk-dapp/utils'

const makeUrl = () => `${window.location.origin}${'/'}`

const useCleanUp = () => useCallback(() => window.localStorage.clear(), [])

export const useDappLogout = () => {
  const cleanUp = useCleanUp()

  return useCallback(() => logout(makeUrl())
    .then(cleanUp)
    .catch(cleanUp), [cleanUp])
}
