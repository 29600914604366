import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { removeBlacklistedCollection } from 'admin/slices'

export const useRemoveBlacklistedCollection = () => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    dispatch(removeBlacklistedCollection.actions.attempt({ collection: payload }))
  }, [dispatch])
}
