import { useTransaction } from 'transaction/hooks'
import { useCallback, useEffect } from 'react'
import { useResetTransaction } from 'transaction/hooks/use-reset-transaction'
import { useNavigate } from 'react-router-dom'
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks'
import { useFilters } from 'filters/hooks/use-filters'
import { useIsCanceled } from './use-is-canceled'

const useOnCancelled = ({ previousLocation }) => {
  const navigate = useNavigate()
  const reset = useResetTransaction()
  const isCancelled = useIsCanceled()

  return useCallback(() => {
    reset()
    isCancelled() && navigate(previousLocation)
  }, [reset, isCancelled, navigate, previousLocation])
}

export const useTransactionRedirect = () => {
  const transaction = useTransaction()
  const { search } = useFilters()
  const { status } = search
  const navigate = useNavigate()
  const isCanceled = useIsCanceled()

  useEffect(() => {
    isCanceled() && navigate(transaction.previousLocation.pathname, { state: { ...transaction.previousLocation.state, hydrated: true }, replace: true })
  }, [navigate, status, transaction.previousLocation, isCanceled])

  useTrackTransactionStatus({
    transactionId: transaction.sessionId,
    onCancelled: useOnCancelled(transaction),
  })
}
