import { filter, get } from 'lodash'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCampaign = () => {
  const data = useCmsData()
  const content = get(data, 'data.data.attributes.content', [])
  const [campaign] = filter(content, { __component: 'sections.campaign' })

  const title = get(campaign, 'subtitle')
  const description = get(campaign, 'description')
  const video = get(campaign, 'campaignVideo.data.attributes.url')

  return (
    {
      title,
      description,
      video,
    }
  )
}
