import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions } from 'modals/account/edit/privacy/slices/update-privacy'
import { usePrivacyStatus } from 'viewer/hooks/use-viewer-privacy-status'

export const useUpdatePrivacy = () => {
  const dispatch = useDispatch()
  const isPrivate = usePrivacyStatus()

  return useCallback(({ privacy }) => {
    const privacyValue = privacy === true ? 'private' : 'public'

    return isPrivate !== privacy && dispatch(actions.attempt(privacyValue))
  }, [dispatch, isPrivate])
}
