import { ReactComponent as TagIcon } from 'startup/assets/gradient-tag-icon.svg'
import { OnboardingModal } from 'components/onboarding-modal'
import { Step } from 'modals/onboarding/sell/step'
import { ReactComponent as Pointer } from 'startup/assets/sell-pointer.svg'
import { ReactComponent as Squares } from 'startup/assets/sell-squares.svg'
import { ReactComponent as Gavel } from 'startup/assets/sell-gavel.svg'
import 'modals/onboarding/sell/index.scss'

const steps = [
  {
    icon: <Squares />,
    title: <>
      Expand the
      {' '}
      <br />
      {' '}
      collection
    </>,
  },
  {
    icon: <Pointer />,
    title: <>
      Click on
      {' '}
      <br />
      {' '}
      the NFT
    </>,
  },
  {
    icon: <Gavel />,
    title: <>
      Start an
      {' '}
      <br />
      {' '}
      auction
    </>,
  },
]

export const Sell = () => <OnboardingModal id={'onboarding-sell'} buttonText={'Got it'}>
  <TagIcon className={'onboarding-modal-icon'} />
  <h4 className={'onboarding-modal-title'}>Sell as easy as 1, 2, 3!</h4>
  <div className={'onboarding-modal-content'}>
    <p>Every NFT that you own can be sold on the xSpotlight marketplace.</p>
    <div className={'onboarding-sell-steps'}>
      {steps.map((step, index) => <Step key={index} {...step} />)}
    </div>
    <p>💡 Tip: Set a higher Buy Now price for impatient collectors.</p>
  </div>
</OnboardingModal>
