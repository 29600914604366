import './index.scss'
import { Collection } from 'subdomain/home/collections/collection'
import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section/index'

export const Collections = ({ data }) => {
  const collections = get(data, 'collections', [])
  const title = get(data, 'title', '')

  return <HomeSection title={title}>
    {collections.map((collection, index) => <Collection collection={collection} key={index} />)}
  </HomeSection>
}
