import { Form } from 'modals/auction/buy-sft/form'
import { Behaviour } from 'modals/auction/buy-sft/behaviour'
import { BootstrapModal } from 'components/bootstrap-modal'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'

export const BuySft = () => {
  const auction = useSelectedAuction()

  return <BootstrapModal id={'buy-sft'} title={'Buy SFT'}>
    <Behaviour />
    {auction ? <Form auction={auction} /> : ''}
  </BootstrapModal>
}
