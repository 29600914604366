import { DurationLabel } from 'components/duration-label/index'
import { useContext } from 'nft-page/auction/countdown/timer/context'
import 'nft-page/auction/countdown/timer/index.scss'

export const CountdownTimer = () => {
  const { timeLeft } = useContext()

  return <div className={'details-countdown-timer d-flex align-items-center'}>
    <DurationLabel duration={timeLeft} />
  </div>
}
