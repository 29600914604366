import { formatTraitsList } from 'filters/collection-filters/filters-logic/traits/format-traits-list'
import { isEmpty } from 'lodash'

export const traitsFilter = traits => {
  if (isEmpty(traits)) return {}

  const list = formatTraitsList(traits)

  return !isEmpty(list) ? { traits: list } : {}
}
