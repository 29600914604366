import { gql } from 'graphql-tag'

export const EXPLORE_CREATORS = gql`
    query exploreCreators($filters: ArtistFilters!, $pagination: ConnectionArgs) {
        artists(filters: $filters, pagination: $pagination)
        {
            edges {
                cursor
                node{
                    address
                    cover
                    description
                    herotag
                    privacy
                    profile
                }
            }
            pageData{
                count
            }
            pageInfo{
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
