import { gql } from '@apollo/client'

export const AUCTION_ORDERS = gql`
    query auctionOrders($filters: FiltersExpression, $pagination: ConnectionArgs, $sorting: [Sorting!]) {
        orders(filters: $filters, pagination: $pagination, sorting: $sorting){
            edges {
                node{
                    id
                    auctionId
                    ownerAddress
                    creationDate
                    from{
                        address
                        herotag
                        profile
                    }
                    price{
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                }
                cursor
            }
            pageData {
                count
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`
