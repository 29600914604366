import { useHasMore, useList, useNext } from 'modals/account/followers/hooks'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { Account } from 'modals/account/followers/account'
import { useIsLoading } from 'modals/account/followers/hooks/use-is-loading'
import { Loader } from 'modals/account/followers/loader'

export const ListContent = () => {
  const list = useList()
  const hasMore = useHasMore()
  const next = useNext()
  const isLoading = useIsLoading()

  return <InfiniteScroll next={next} className={'followers-modal-list'} hasMore={hasMore} isLoading={isLoading}>

    {list.map(({ address }) => <Account key={address} address={address} />)}
    <Loader />
  </InfiniteScroll>
}
