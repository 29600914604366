import { Swiper, SwiperSlide } from 'swiper/react'
import { MediaComponent } from 'nft-page/media/media-component'
import { useRef, useState } from 'react'
import { useUpdateSwiper } from 'nft-page/media/hooks/use-update-swiper'
import { useResetLoaded } from 'nft-page/media/hooks/use-reset-loaded'

const makeOnMediaLoaded = (index, setLoaded, medias) => () => {
  setLoaded(old => {
    const loaded = [...old]

    loaded[index] = 1

    if (medias.length - 1 > index) {
      loaded.push(0)
    }

    return loaded
  })
}

const breakpoints = {
  0: { spaceBetween: 15 },
  768: { spaceBetween: 40 },
}

export const Carousel = ({ medias }) => {
  const swiperRef = useRef()
  const [loaded, setLoaded] = useState([0])
  const [swiper, setSwiper] = useState()

  useUpdateSwiper(loaded, swiperRef)
  useResetLoaded(medias, setLoaded)

  return <Swiper ref={swiperRef} centeredSlides slideToClickedSlide className={'nft-media-carousel'}
                 slidesPerView={'auto'} observer breakpoints={breakpoints} initialSlide={0} onSwiper={setSwiper}>
    {!!loaded.length && loaded.map((_, index) => {
      const loadedClass = loaded[index] === 1 ? 'loaded' : 'loading'

      return <SwiperSlide className={`swiper-no-swiping ${loadedClass}`} key={index}
                          onClick={() => swiper?.slideTo(index)}>
        <MediaComponent media={medias[index]}
                        onMediaLoaded={makeOnMediaLoaded(index, setLoaded, medias)} />
      </SwiperSlide>
    })}
  </Swiper>
}
