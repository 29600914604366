import { useField } from 'formik'
import { useCallback } from 'react'

export const useOnChanged = (name, auctions) => {
  const [, , { setValue }] = useField(name)

  return useCallback(option => {
    const selected = auctions.find(item => item.id === option.value)
    setValue(selected.id)
  }, [auctions, setValue])
}
