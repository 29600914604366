import { decodeData } from 'repositories/decode-data'

const buildResults = transaction => transaction.results.map(result => ({
  ...result,
  data: decodeData(result.data),
}))

export const decodeTransactionData = transaction => ({
  ...transaction,
  data: decodeData(transaction.data),
  results: transaction.results ? buildResults(transaction) : [],
})
