import { useParams } from 'react-router-dom'
import { useOnChange } from 'collections/auctions/behaviour/hooks/use-on-change'
import { useWatchAction } from 'action-statuses/hooks/use-watch-action'

export const useTransactionWatch = () => {
  const { identifier } = useParams()
  const onChange = useOnChange()

  useWatchAction({ prefix: `${identifier}_Collection`, onChange })
}
