import { Form, Formik } from 'formik'
import { useInitialValues } from 'components/forms/set-roles-form/hooks/use-initial-values'
import { useSubmitCallback } from 'components/forms/set-roles-form/hooks/use-submit-callback'
import { Roles } from 'components/forms/set-roles-form/roles'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'

export const SetRolesForm = props => <Formik initialValues={useInitialValues()} onSubmit={useSubmitCallback(props)}>
  <Form>
    <Roles />
    <DefaultActions callbackUrl={props.callbackUrl} label={'Set Roles'} />
  </Form>
</Formik>

