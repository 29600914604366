import { settings } from '../../settings/fetching'
import { rest } from '../../network'

const preferencesApi = () => settings().preferencesApi

export const preferences = {
  set: preferences => rest().post(`${preferencesApi()}`, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(preferences),
  }, true),
  get: () => rest().get(`${preferencesApi()}`, {}, true),
}
