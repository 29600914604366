import { from } from 'rxjs'
import { Failure } from 'failure'
import { ofType } from 'redux-observable'
import { failure as failureSlice } from 'failure/slices'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { actions } from 'asset-history/slices/asset-history-slice'
import { assetHistory as repository } from 'repositories/asset-history/index'

export const assetHistory = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.get(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = payload => from([
  actions.failure(payload),
  failureSlice.actions.show({ variant: Failure.VARIANT.partial, id: 'asset.history' }),
])
