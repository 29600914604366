import { useNerdsFormContext } from '../../../nerds-form/use-nerds-form-context'
import { Layout } from './layout'
import { Identity } from './identity'
import { Details } from './details'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Errors } from 'components/forms/nerds-form'
import { InputField } from 'components/forms/nerds-form/fields/input-field'
import { Textarea } from 'components/forms/nerds-form/fields/textarea'
import { TagsField } from 'components/forms/nerds-form/fields/tags-field'
import { CollectionField } from 'components/forms/nerds-form/fields/collection-field'

export const Fields = ({ submitErrors, selectableCollection }) => {
  const { callbackUrl } = useNerdsFormContext()

  return <Layout>
    <Identity />
    {selectableCollection && <CollectionField name={'collection'} />}
    <InputField label={'Name'} name={'name'} />
    <Textarea label={'Description'} name={'description'} />
    <TagsField label={'Tags'} name={'tags'} />
    <Details />
    <DefaultActions callbackUrl={callbackUrl} />
    <Errors values={submitErrors} />
  </Layout>
}
