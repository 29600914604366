import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { get } from 'lodash'
import { useAssetOrdersPageInfo } from 'nft-page/auction/bids/hooks/use-asset-orders-page-info'
import { actions } from 'nft-page/auction/bids/slices/orders'
import { useMakePayload } from 'nft-page/auction/bids/hooks/use-make-payload'

export const useLoadMore = () => {
  const dispatch = useDispatch()
  const auction = useNftDefaultAuction()
  const { endCursor } = useAssetOrdersPageInfo()
  const id = get(auction, 'id', '')
  const payload = useMakePayload(endCursor)

  return useCallback(() => {
    id && dispatch(actions.attempt(payload()))
  }, [dispatch, id, payload])
}
