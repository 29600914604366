import { filter } from 'lodash'
import { useParamsGlobal } from 'components/layered-router/hooks/use-params-global'
import { useCurrentPreviewSource } from 'nft-page/controls/hooks/use-current-preview-source'
import { useAuctionsMatch } from 'nft-page/controls/hooks/use-auctions-match'

export const useCurrentElement = () => {
  const { identifier, auctionId } = useParamsGlobal()
  const currentPreview = useCurrentPreviewSource()
  const auctionsMatch = useAuctionsMatch(auctionId)

  if (auctionId) {
    return filter(currentPreview.selector, item => auctionsMatch(item))[0]
  } else {
    return filter(currentPreview.selector, item => item.node.identifier === identifier)[0]
  }
}
