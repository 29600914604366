import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: '',
  followees: 0,
  type: '',
  accounts: [],
  errors: '',
  loading: false,
}

const incrementFollowees = state => ({ ...state, followees: state.followees + 1 })

const decrementFollowees = state => ({ ...state, followees: state.followees - 1 })

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: !payload.preventLoading })

const success = (state, { payload: accounts }) => ({ ...state, accounts, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: payload, loading: false })

const getMeta = (state, { payload }) => ({ ...state, input: payload, loading: true })

const setMeta = (state, { payload: meta }) => ({ ...state, ...meta, loading: false })

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'feed/suggestions/get',
  reducers: {
    incrementFollowees,
    decrementFollowees,
    attempt,
    success,
    failure,
    getMeta,
    setMeta,
    reset,
  },
})
