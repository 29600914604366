import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Loading } from '../../loading'

const path = 'campaigns.get.list'

export const useCampaigns = () => {
  const items = useSelector(state => get(state, path, []))
  const ghosts = Loading.usePayloads(Loading.ID.campaigns.page)

  return [...items, ...ghosts]
}
