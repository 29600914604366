import { combineEpics } from 'redux-observable'
import { search as defaultSearch } from 'search/epics/search'
import { dynamic } from 'search/epics/dynamic'

const epics = [
  defaultSearch,
  dynamic,
]

export const search = combineEpics(...epics)
