import { useFollow } from 'explore-nfts/tabs/creators/hooks/use-follow'
import { useCallback } from 'react'

export const useOnFollow = (setIsFollowedLocal, address) => {
  const follow = useFollow(address)

  return useCallback(() => {
    follow()
    setIsFollowedLocal(true)
  }, [setIsFollowedLocal, follow])
}
