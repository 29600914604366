import { useMarketplaceCutPercentage } from 'marketplaces/hooks/use-marketplace-cut-percentage'
import { useRoyalties } from 'components/forms/nerds-form/fields/hooks/use-royalties'
import { useIsCreator } from 'components/forms/nerds-form/fields/hooks/use-is-creator'
import { useMinOrMaxBidValue } from 'components/forms/nerds-form/fields/hooks/use-min-or-max-bid-value'

export const useAssetFees = asset => {
  const isCreator = useIsCreator(asset)
  const exampleValue = useMinOrMaxBidValue() || 1
  const cutPercentage = parseFloat(useMarketplaceCutPercentage()) / 100
  const royaltiesPercentage = parseFloat(useRoyalties(asset))
  const sellerTotalPercentage = cutPercentage + royaltiesPercentage
  const totalPercentage = !isCreator ? sellerTotalPercentage : cutPercentage

  return exampleValue - (exampleValue * (totalPercentage / 100))
}
