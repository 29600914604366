import { of, from } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { nftCollections } from 'repositories/nft-collections'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'
import { actions } from 'modals/collection/set-roles/slices/set-roles'

export const setRoles = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(({ payload }) => execute(payload)))

const execute = payload => nftCollections.setRoles(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = ({ setRoles: payload }) => from([
  actions.success(payload),
])

const failure = result => of(actions.failure(extractGraphqlErrors(result)))

