import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'subdomain/modals/account/my-claimable/behaviour'
import { ModalContent } from 'subdomain/modals/account/my-claimable/modal-content'
import { Title } from 'subdomain/modals/account/my-claimable/title'
import { List } from 'subdomain/modals/account/my-claimable/list'
import { Failure } from 'failure/index'
import { Empty } from 'subdomain/modals/account/my-claimable/empty'
import 'subdomain/modals/account/my-claimable/style.scss'

export const MyClaimable = () => <BootstrapModal id={'my-claimable'} className={'my-claimable-modal'} easyClose>
  <Behaviour />
  <ModalContent>
    <Title />
    <Failure.Partial id={'modal.account.myClaimable'}>
      <List />
      <Empty />
    </Failure.Partial>
  </ModalContent>
</BootstrapModal>

export { reducer } from 'subdomain/modals/account/my-claimable/reducer'
export { epic } from 'subdomain/modals/account/my-claimable/epic'
