import { ReactComponent as MVXSvg } from 'startup/assets/logo.svg'
import 'components/top-navigation/components/logo/index.scss'
import { useOnClick } from 'components/top-navigation/components/logo/hooks/use-on-click'

export const Logo = () => {
  const onClick = useOnClick()

  return <div className={`site-logo d-flex`}>
    <a href={'/'} onClick={onClick} className={'site-logo-link'}>
      <MVXSvg className={'site-logo-img'} />
    </a>
  </div>
}
