import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { Loading } from 'loading'
import { useCollectionAuctionsLoading } from 'collections/auctions/hooks/use-collection-auctions-loading'

const path = 'collections.auctions.get.list'

export const useActualList = () => {
  const items = useSelector(state => get(state, path, []))
  const ghosts = Loading.usePayloads(Loading.ID.collection.page)
  const isLoading = useCollectionAuctionsLoading()

  return isLoading ? [...items, ...ghosts] : [...items]
}
