import { rest } from 'network'
import { get } from 'lodash'
import { getBaseUrl } from 'repositories/followers/get-base-url'

const makeQuery = page => {
  const search = new URLSearchParams()
  search.set('page', page)

  return search.toString()
}

const makeUrl = payload => {
  const page = get(payload, 'page')
  const initial = `${getBaseUrl()}/api/v1/followers`

  return page ? `${initial}?${makeQuery(page)}` : initial
}

export const followers = payload => rest().get(makeUrl(payload), {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
}, true)
