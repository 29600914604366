export const isHashtag = string => string.charAt(0) === '#'

export const isHerotag = string => string.charAt(0) === '@'

export const cleanPayload = payload => ({
  ...payload,
  filters: { searchTerm: payload.filters.searchTerm.replace(/[@#]/g, '') },
})

export const filterResults = (shouldApply, filterFunction) => ({ searchTerm, results }) => {
  const filteredResults = shouldApply ? results.filter(filterFunction) : results

  return ({ searchTerm, results: filteredResults })
}
