import { find } from 'lodash'

export const addIfNotExisting = (items, item) => {
  const newItems = [...items]

  const existingTag = find(items, o => o.value === item.value)
  if (!existingTag) newItems.splice(1, 0, item)

  return newItems
}
