import { useCallback } from 'react'
import match from '@nerds.sh/js-pattern'

export const useAssetFromCurrentElement = currentElement => useCallback(() => match(currentElement?.__typename)
  .with(typeName => typeName === 'Asset', () => currentElement)
  .with(typeName => typeName === 'AssetEdge', () => currentElement.node)
  .with(typeName => typeName === 'Auction', () => currentElement.asset)
  .with(typeName => typeName === 'AuctionEdge', () => currentElement.node.asset)
  .with(typeName => typeName === 'Order', () => currentElement.auction.asset)
  .with(typeName => typeName === 'OrderEdge', () => currentElement.node.auction.asset)
  .otherwise(() => ''), [currentElement])
