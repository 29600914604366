import { FacetButton } from 'components/facet-button'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useFilterSetter } from 'filters/hooks/use-filter-setter'
import { useIsFilterSelected } from 'filters/hooks/use-is-filter-selected'
import './index.scss'
import { TagButton } from 'filters/components/facets-selector/tag-button'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const FacetsSelector = ({ facets, isActive, isLoading, resetOthers = false, name = 'filter' }) => {
  const tag = useFilterValue('tag')
  const isSelected = useIsFilterSelected(name, !!tag)
  const setFilter = useFilterSetter(name)

  return <div className={'facets-selector'}>
    <Swiper freeMode slidesPerView={'auto'}>
      {facets.map((facet, index) => <SwiperSlide key={index}>
        <FacetButton {...facet} disabled={isLoading} selected={isSelected(facet) && !tag}
                     onClick={() => setFilter(facet, resetOthers)} />
      </SwiperSlide>)}
      <SwiperSlide>
        <TagButton disabled={isLoading} />
      </SwiperSlide>
    </Swiper>
  </div>
}
