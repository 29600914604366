import 'components/player-controls/playing.scss'
import * as Icons from '@fortawesome/pro-solid-svg-icons'
import { useContext } from 'components/player-controls/context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePlayCallback } from 'components/player-controls/hooks/use-play-callback'

const useIconProperties = () => ({
  icon: Icons.faMusicAlt,
  className: 'player-controls__playing',
  onClick: usePlayCallback(),
  onTouchEnd: usePlayCallback(),
})

export const Playing = () => {
  const { paused, playingFeedback } = useContext()
  const shouldShow = !paused && playingFeedback
  const iconProperties = useIconProperties()

  return shouldShow && <FontAwesomeIcon {...iconProperties} />
}
