import { useEffect } from 'react'
import { useContext } from 'components/player-controls/context'

export const useMonitorPlay = () => {
  const { player, setPaused } = useContext()

  useEffect(() => {
    if (!player?.current) return

    const playerInstance = player.current
    const onPlay = () => setPaused(false)
    playerInstance.on('play', onPlay)

    return () => playerInstance.off('play', onPlay)
  }, [player, setPaused])
}
