import * as Yup from 'yup'

import * as minBidPrice from 'components/forms/create-sft-auctions/bundle-form/hooks/use-min-bid-schema'
import * as maxBidPrice from 'components/forms/create-sft-auctions/bundle-form/hooks/use-max-bid-schema'
import moment from 'moment'
import { testBalance } from 'components/forms/create-sft-auctions/test-balance'
import { settings } from 'settings/fetching'

const deadlineSchema = () => Yup.date()
  .required('Required')
  .default(moment().add(1, 'd')
    .toDate())
  .min(new Date(), 'Deadline cannot be in the past')

const maxBidSchema = () => Yup.string()
  .when('checkedMaxBid', {
    is: true,
    then: maxBidPrice.useMaxBidSchema('Required'),
    otherwise: Yup.string()
      .default(''),
  })
  .default('')

const egldLabel = () => settings().dapp.egldLabel

export const useValidationSchema = () => Yup.object().shape({
  deadline: deadlineSchema(),
  minBid: minBidPrice.useMinBidSchema('Required'),
  checkedMaxBid: Yup.boolean().default(false),
  maxBid: maxBidSchema(),
  identifier: Yup.string().required(),
  paymentToken: Yup.string().required()
    .default(egldLabel()),
  quantity: Yup.number()
    .required('Required')
    .typeError('Should be a number')
    .min(2, 'Should be greater than 1')
    .integer('Should be an integer')
    .test({ name: 'Has enough balance', test: testBalance })
    .default(2),
  maxOneSftPerPayment: Yup.boolean().default(false),
})
