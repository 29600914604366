import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: '',
  input: '',
  errors: '',
  loading: false,
  cursor: '',
}

const setCursor = (state, { payload }) => ({
  ...state,
  cursor: payload,
})

const reset = state => ({ ...state, cursor: '' })

const cleanUp = () => initialState

export const get = createSlice({
  initialState,
  name: 'account/auctions/get',
  reducers: {
    attempt: (state, { payload }) => ({ ...state, input: payload, loading: true }),
    success: (state, { payload }) => ({ ...state, data: payload.data, loading: false }),
    failure: (state, { payload }) => ({ ...state, errors: payload, loading: false }),
    setCursor,
    reset,
    cleanUp,
  },
})
export const { actions, reducer } = get

