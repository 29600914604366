import { CountdownTimer } from 'nft-page/auction/countdown/timer'
import { Behaviour } from 'nft-page/auction/countdown/behaviour'
import { useDefaultContext, Context } from 'nft-page/auction/countdown/timer/context'
import { get } from 'lodash'
import 'nft-page/auction/countdown/index.scss'

export const Countdown = ({ auction }) => {
  const assetType = get(auction, 'asset.type')
  const type = assetType === 'SemiFungibleESDT' ? 'SFT' : 'NFT'

  return <Context.Provider value={useDefaultContext()}>
    <div className={'details-countdown'}>
      <Behaviour auction={auction} />
      <p className={'details-countdown-title'}>
        {type}
        {' '}
        auction:
      </p>
      <CountdownTimer />
      <p className={'details-countdown-label'}>Time left</p>
    </div>
  </Context.Provider>
}
