import { useGetInitialPage, useResetFeed, useGetMeta, useGetSuggestions } from 'feed/behaviour/hooks'

export const Behaviour = () => {
  useGetInitialPage()
  useResetFeed()
  useGetMeta()
  useGetSuggestions()

  return ''
}
