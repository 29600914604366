import { from } from 'rxjs'
import { history } from 'network'
import { ofType } from 'redux-observable'
import { assets } from 'repositories/assets'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { actions } from 'account/collection-assets/slices/collection-assets'

export const collectionAssets = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => assets.get(payload)
  .pipe(mergeMap(result => success(result)))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = errors => from([
  actions.failure(errors),
  failureSlice.actions.show({ route: history.location.pathname }),
])
