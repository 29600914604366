import { Behaviour } from 'nft-page/behaviour'
import { Content } from 'nft-page/content'
import { Media } from 'nft-page/media'
import 'nft-page/nft-page.scss'
import { Seo } from 'components/seo'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { Existing } from 'nft-page/existing'
import { Whitelisted } from 'nft-page/whitelisted'

const namePath = 'nft.nftPage.data.assets.edges[0].node.name'
const imagePath = 'nft.nftPage.data.assets.edges[0].node.media[0].thumbnailUrl'
const descriptionPath = 'nft.nftPage.data.assets.edges[0].node.metadata.description'

const useSeoProps = () => ({
  title: useSelector(state => get(state, namePath)),
  image: useSelector(state => get(state, imagePath)),
  description: useSelector(state => get(state, descriptionPath)),
})

export const NftPage = () => <>
  <Behaviour />
  <Existing>
    <Whitelisted>
      <div id={'nftPage'} className={'nft-page d-lg-flex'}>
        <Seo {...useSeoProps()} />
        <Media />
        <Content />
      </div>
    </Whitelisted>
  </Existing>
</>
