import { map, mergeMap, toArray } from 'rxjs'
import { accounts } from 'repositories/feed/accounts'
import { emitArrayElements } from 'repositories/feed/common'

const format = ({ who, whom, timestamp }) => ({
  who,
  whom,
  when: timestamp,
  action: { type: 'follow' },
})

export const follow = group => group
  .pipe(toArray())
  .pipe(mergeMap(events => accounts({ events, field: 'whom', target: 'reference' })))
  .pipe(emitArrayElements())
  .pipe(map(format))
