import { useRunningAuctionsPageInfo } from 'nft-page/details/other-listings/hooks/use-running-auctions-page-info'
import { Button } from '../other-listings/button'
import { useRunningLoadMore } from 'nft-page/details/other-listings/hooks/use-running-load-more'
import { useCallback } from 'react'

const useOnClick = () => {
  const { hasNextPage: runningHasNextPage } = useRunningAuctionsPageInfo()
  const runningLoadMore = useRunningLoadMore()

  return useCallback(() => {
    runningHasNextPage && runningLoadMore()
  }, [runningHasNextPage, runningLoadMore])
}

export const LoadMore = () => {
  const { hasNextPage: runningHasNextPage } = useRunningAuctionsPageInfo()
  const onClick = useOnClick()

  return runningHasNextPage ? <Button onClick={onClick} /> : ''
}
