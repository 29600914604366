import { usePreloadImages } from 'components/collection-card/hooks/use-preload-images'
import { useTimer } from 'components/collection-card/hooks/use-timer'
import { useUpdateOutsideRunning } from 'components/collection-card/hooks/use-update-outside-running'

export const Behaviour = ({ thumbs, running }) => {
  useUpdateOutsideRunning(running)
  usePreloadImages(thumbs)
  useTimer()

  return ''
}
