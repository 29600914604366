import { PriceField } from 'components/forms/nerds-form/fields/price-field'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const Price = ({ auction, paymentToken }) => {
  const minimum = useMinimum(auction)
  const price = denominateAmount(minimum, paymentToken.decimals, true)

  return <fieldset>
    <legend>Price</legend>
    <PriceField name={'price'} placeholder={'Set the amount you want to bid'} price={price}
                paymentToken={paymentToken} />
  </fieldset>
}
