import { useCallback } from 'react'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { useParams } from 'react-router-dom'

export const useOnClick = tierName => {
  const navigate = useLayeredNavigate()
  const { minterAddress, campaignId } = useParams()

  return useCallback(() => {
    const path = `/campaigns/${minterAddress}/${campaignId}/${tierName}/modal/buy-campaign`
    navigate(path, 'modal')
  }, [navigate, minterAddress, campaignId, tierName])
}
