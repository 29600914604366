import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { auctions } from 'repositories/auctions'
import { actions } from 'home/trending/slices/get'
import { failure as failureSlice } from 'failure/slices'
import { mergeMap, map, catchError } from 'rxjs/operators'

export const trending = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => auctions.getHomeTrendingSortedByBids(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = error => from([
  actions.failure(error),
  failureSlice.actions.show({}),
])
