import { useCallback } from 'react'
import { useSetLocationFilters } from '../../../../filters/hooks/use-set-location-filters'

export const useOnChange = () => {
  const setLocationFilters = useSetLocationFilters()

  return useCallback(event => {
    setLocationFilters({ name: 'on-sale', value: event.target.checked }, true)
  }, [setLocationFilters])
}
