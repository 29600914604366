import { Context, useDefaultContext } from 'holoride-campaign/action/before/context'
import { Countdown } from 'holoride-campaign/action/before/countdown'
import { get } from 'lodash'
import './index.scss'

export const Before = ({ primarySale }) => {
  const startSale = get(primarySale, 'saleTime.startSale')

  return <Context.Provider value={useDefaultContext()}>
    <div className={'holoride-action-inner d-flex align-items-center flex-column'}>
      <span className={'holoride-campaign-before-label'}>Campaign starts in:</span>
      <Countdown endDate={startSale} />
    </div>
  </Context.Provider>
}
