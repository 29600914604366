import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { actions } from 'nft-page/details/other-listings/slices/running'
import { useParams } from 'react-router-dom'
import { useMakePayload } from 'nft-page/details/other-listings/hooks/use-make-payload'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { Loading } from 'loading'

export const useExecute = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()
  const nft = useNftSelector()
  const isSft = nft && nft.type !== 'NonFungibleESDT'
  const showLoading = Loading.useShowLoading()
  const payload = useMakePayload({ identifier, after: '', status: 'Running' })

  return useCallback(() => {
    if (isSft && identifier) {
      showLoading({}, { id: Loading.ID.nft.auctions, variant: Loading.VARIANT.partial })
      dispatch(actions.attempt(payload))
    }
  }, [dispatch, identifier, isSft, payload, showLoading])
}

export const useFetchRunningAuctions = () => {
  const { auctionId } = useParams()
  const execute = useExecute()
  useEffect(() => execute(), [execute, auctionId])
}
