import { get } from 'lodash'
import { parse } from 'common/query-string'
import { useLocation } from 'react-router-dom'

const useBackgroundFilterValue = key => {
  const { state } = useLocation()
  const link = get(state, 'layerLink.background', '')

  return link && get(parse(link.split('?')[1]), key, '')
}

const useModalFilterValue = key => {
  const { state } = useLocation()
  const link = get(state, 'layerLink.modal', '')

  return link && get(parse(link.split('?')[1]), key, '')
}

export const useFilterValue = name => {
  const parsed = parse(useLocation().search)
  const backgroundTag = useBackgroundFilterValue(name)
  const modal = useModalFilterValue(name)

  return get(parsed, name, '') || backgroundTag || modal
}
