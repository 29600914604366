import { combineEpics } from 'redux-observable'

import { create as start } from 'modals/auction/start'
import { end } from 'modals/auction/end'
import { claim } from 'modals/auction/claim'
import { buySft } from 'modals/auction/buy-sft/epics/buy-sft'
import { withdraw } from 'modals/auction/withdraw/epics/withdraw'
import { placeBid } from 'modals/auction/bid/epics/place-bid'

export const epics = combineEpics(start, end, claim, buySft, withdraw, placeBid)
