import { Creator } from 'components/collection-card/creator'
import { Title } from 'components/collection-card/title'
import { Length } from 'components/collection-card/length'
import { get } from 'lodash'
import 'components/collection-card/details/details.scss'

export const Details = ({ collection }) => {
  const collectionName = get(collection, 'name')
  const collectionDescription = get(collection, 'description')
  const collectionVerified = get(collection, 'verified')

  return <div className={'collection-card-details'}>
    <Title value={collectionName} verified={collectionVerified} />
    <p className={'collection-card-description'}>{collectionDescription}</p>
    <div className={'d-flex flex-sm-nowrap align-items-center'}>
      <Creator collection={collection} />
      <Length collection={collection} />
    </div>
  </div>
}

