import { Loading } from 'loading'
import { isMatch, get } from 'lodash'
import { useSelector } from 'react-redux'

export const usePayloads = (id, variant = Loading.VARIANT.ghostCard) => {
  const loadings = useSelector(state => get(state, 'loading', []))
  const filtered = loadings.filter(item => isMatch(item, { id, variant }))

  return filtered.map(loading => loading.payload)
}
