import { useMemo } from 'react'
import { useLastCursor } from 'collections/assets/hooks/use-last-cursor'
import { settings } from 'settings/fetching'
import { useParams } from 'react-router-dom'
import { useCmsMarketplaceKey } from '../../../subdomain/cms-content/hooks/use-cms-marketplace-key'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { traitsFilter } from 'filters/collection-filters/filters-logic/traits'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const { identifier: collection } = useParams()
  const cursor = useCursor(customCursor)
  const marketplaceKey = useCmsMarketplaceKey()
  const traits = useFilterValue('traits')

  return useMemo(() => ({
    pagination: { first: settings().listRequestSize, after: cursor },
    filters: { collection, ...traitsFilter(traits) },
    marketFilters: marketplaceKey ? { marketplaceKey } : undefined,
  }), [cursor, collection, marketplaceKey, traits])
}
