import { useLoadMore } from 'nft-page/auction/bids/hooks/use-load-more'
import 'nft-page/auction/bids/load-more/button.scss'

export const Button = () => {
  const loadMore = useLoadMore()

  return <div className={'d-flex justify-content-center mt-3'}>
    <a className={'bids-load-more-button'} onClick={loadMore}>Load more</a>
  </div>
}
