import { useDispatch } from 'react-redux'
import { actions } from 'collections/stats/slices/get'
import { useEffect } from 'react'
import { useMakePayload } from 'collections/stats/hooks/use-make-payload'

export const useFetchCollectionStats = () => {
  const dispatch = useDispatch()
  const makePayload = useMakePayload()

  useEffect(() => {
    dispatch(actions.attempt(makePayload()))
  }, [dispatch, makePayload])
}
