import 'notifications/account/index.scss'
import { useIsPrinceCharming } from 'auth/mega-authorized/index'
import { Content } from 'notifications/account/content'
import { usePoolNotifications } from './hooks/use-pool-notifications'

export const AccountNotifications = () => {
  const isPriceCharming = useIsPrinceCharming()
  usePoolNotifications()

  return isPriceCharming ? <Content /> : ''
}
