import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: null,
  data: null,
  errors: null,
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: payload })

export const clearCollectionScamInfo = createSlice({
  initialState,
  name: 'admin/clearCollectionScamInfo',
  reducers: {
    attempt,
    success,
    failure,
  },
})
