import { Behaviour } from 'home/trending-collections/behaviour'
import { Wrapper } from 'home/trending-collections/wrapper'
import { Empty } from 'home/trending-collections/empty'
import { HomeSection } from 'components/home-section'
import { useTrendingCollections } from 'home/trending-collections/hooks/use-trending-collections'
import { Carousel as CollectionCarousel } from 'components/collection-carousel'

const description = 'Most on-demand collections in the entire ecosystem.'

export const TrendingCollections = () => {
  const collections = useTrendingCollections()

  return <HomeSection title={'Trending Collections'} description={description}
                      showAllLink={'/collections?sort=trending'}>
    <div className={'container'}>
      <Behaviour />
      <Wrapper>
        <Empty />
        <CollectionCarousel items={collections} />
      </Wrapper>
    </div>
  </HomeSection>
}
