import { HomeSection } from 'components/home-section'
import { Behaviour } from 'home/featured-collections/behaviour'
import { Content } from 'home/featured-collections/content'
import 'home/featured-collections/index.scss'

const sectionProps = {
  title: 'Featured Collections',
  description: 'Collections with hyper-engaged communities.',
}

export const FeaturedCollections = () => <HomeSection {...sectionProps}>
  <div className={'featured-collections-section'}>
    <Behaviour />
    <Content />
  </div>
</HomeSection>
