import { TimeLeft } from 'subdomain/components/my-asset-card/time-left'
import { get } from 'lodash'

const showBadge = (auctionEnded, endDate) => !!endDate && !auctionEnded

export const TimeLeftBadge = props => {
  const endDate = get(props, 'auction.endDate')

  return showBadge(props.auctionEnded, endDate) && <TimeLeft endDate={endDate} auction={props.auction}
                                                             condition={props.timeLeft} />
}
