import { useShouldRedirect } from 'account/account-wrapper/hooks/use-should-redirect'
import { useDestination } from 'account/account-wrapper/hooks/use-destination'
import { Navigate } from 'react-router-dom'

export const RedirectToHerotag = ({ children }) => {
  const shouldRedirect = useShouldRedirect()
  const destination = useDestination()

  return shouldRedirect ? <Navigate to={destination} /> : children
}
