import { gql } from 'graphql-tag'

export const GET_EXPLORE_NFTS_STATS = gql`
    query exploreNftsStats {
        exploreNftsStats
        {
            allNftsCount
            buyNowCount
            liveAuctionsCount
        }
    }
`
