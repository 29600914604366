import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Title } from 'components/forms/nerds-form/title'
import { PriceField } from 'components/forms/nerds-form/fields/price-field'
import { DateTimeField } from 'components/forms/nerds-form/fields/date-time-field'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { DependentField } from 'components/forms/nerds-form/fields/dependent-field'
import { Errors } from 'components/forms/nerds-form'
import { FinalFeeField } from 'components/forms/nerds-form/fields/final-fee-field'
import { useAssetFees } from 'components/forms/nerds-form/fields/hooks/use-asset-fees'
import { useMinOrMaxBidValue } from 'components/forms/nerds-form/fields/hooks/use-min-or-max-bid-value'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export const Fields = ({ callbackUrl, asset, submitErrors, paymentToken }) => {
  const { resetForm } = useFormikContext()
  const assetFees = useAssetFees(asset)
  const exampleValue = useMinOrMaxBidValue()

  useEffect(() => resetForm, [resetForm])

  return <div className={'modal-content'}>
    <Title icon={faGavel} title={'Start an auction'} />
    <PriceField name={'minBid'} label={'Minimum bid'} paymentToken={paymentToken} />
    <DependentField name={'maxBid'} dependentName={'checkedMaxBid'}
                    dependentLabel={'Set a Buy Now price?'}>
      <PriceField name={'maxBid'} label={'Buy now price'} paymentToken={paymentToken} />
    </DependentField>
    <DateTimeField name={'deadline'} label={'Expires on'} disablePast />
    <FinalFeeField asset={asset} labelText={'You will receive'} assetFees={assetFees} exampleValue={exampleValue}
                   paymentToken={paymentToken} />
    <DefaultActions callbackUrl={callbackUrl} label={'Start auction'} />
    <Errors values={submitErrors} />
  </div>
}
