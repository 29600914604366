import { get } from 'lodash'
import { PotentialScam } from 'nft-page/media/potential-scam'
import { useScamInfoName } from 'nft-page/hooks/nft/use-scam-info-name'

export const ScamChecker = ({ asset, children }) => {
  const scamInfoType = get(asset, 'scamInfo.type', '')
  const info = useScamInfoName()
  const isScam = scamInfoType === 'potentialScam' || scamInfoType === 'scam'

  return isScam ? <PotentialScam info={info} /> : children
}
