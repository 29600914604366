import { useIsAuctionEnded } from 'nft-page/hooks/use-is-auction-ended'
import { Content } from 'nft-page/auction/editions/content'
import { isAuctionTypeNft } from 'common/is-auction-type-nft'

const isSft = (auction, assetType) => (
  auction && !isAuctionTypeNft(auction.type) && assetType === 'SemiFungibleESDT'
)

export const Editions = ({ auction, assetType }) => {
  const isAuctionEnded = useIsAuctionEnded()
  const shouldDisplay = isSft(auction, assetType) && !isAuctionEnded

  return shouldDisplay ? <Content auction={auction} assetType={assetType} /> : ''
}
