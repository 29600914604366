import { Label } from 'components/forms/nerds-form/fields/label'
import { AuctionsSelector } from 'components/forms/nerds-form/fields/auctions-field/auctions-selector'
import { AuctionsErrors } from 'components/forms/nerds-form/fields/auctions-field/auctions-errors'

export const AuctionsField = ({ name, auctions }) => <div className={'nerds-form-field mb-3'}>
  <Label label={'Select active auction'} />
  <AuctionsSelector name={name} auctions={auctions} />
  <AuctionsErrors name={name} />
</div>

