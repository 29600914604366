import 'trending/tags-filter/tags-filter.scss'
import { Behaviour } from 'trending/tags-filter/behaviour'
import { CurrentTag } from 'trending/tags-filter/current-tag'
import { Options } from 'trending/tags-filter/options'

export const TagsFilter = ({ disabled }) => <div className={'period-filter'}>
  <Behaviour />
  <CurrentTag disabled={disabled} />
  <Options />
</div>
