import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { get as slice } from 'subdomain/cms-content/slices/get'

export const useFetchingCms = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(slice.actions.attempt())
  }, [dispatch])
}
