import { settings } from 'settings/fetching'
import { sorting } from 'filters/explore-filters/nfts/assets/filters-logic/sorting'
import { filters } from 'filters/explore-filters/nfts/assets/filters-logic/filters'

export const buildFilters = ({ cursor, sort, tag }) => ({
  pagination: { first: settings().listRequestSize, after: cursor },
  ...filters(tag),
  ...sorting(sort),
})

