import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { flagCollection } from 'admin/slices'

export const useFlagCollection = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    if (window.confirm('Are you sure?') === true) {
      dispatch(flagCollection.actions.attempt({ input: { collection: identifier, nsfwFlag: 0.99 } }))
    }
  }, [dispatch, identifier])
}
