import { gql } from 'graphql-tag'

export const ISSUE = gql`
    mutation IssueSftCollection($input: IssueCollectionArgs!) {
        issueSftCollection(input:$input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
