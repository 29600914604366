import { useParams } from 'react-router-dom'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { useCallback } from 'react'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { get } from 'lodash'

const isTokenSelected = token => token !== '' && token !== 'All'

export const useMakePayload = () => {
  const { identifier } = useParams()
  const isSubdomain = useIsSubdomain()
  const marketplaceKey = useCmsMarketplaceKey()
  const token = useFilterValue('token')
  const firstAcceptedPaymentToken = useFirstAcceptedPaymentToken()
  const acceptedTokenIdentifier = get(firstAcceptedPaymentToken, 'identifier')

  return useCallback(() => {
    const paymentToken = isTokenSelected(token) ? token : acceptedTokenIdentifier
    const mainStatsFilters = ({ filters: { identifier, paymentToken } })
    const subdomainStatsFilters = ({ filters: { identifier, marketplaceKey, paymentToken } })

    return isSubdomain ? subdomainStatsFilters : mainStatsFilters
  }, [acceptedTokenIdentifier, identifier, isSubdomain, marketplaceKey, token])
}
