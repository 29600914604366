import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { filter } from 'lodash'
import { menuLinks } from 'components/top-navigation/components/menu/menu-links'

export const useMenuLinks = () => {
  const { address } = useGetAccountInfo()

  if (!address) {
    return filter(menuLinks, item => item.authenticated)
  } else {
    return menuLinks
  }
}
