import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/bids/slices/get'
import { useEndCursor } from 'account/tabs/assets/bids/hooks/use-end-cursor'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useUpdateLoadings } from 'account/tabs/assets/hooks/use-update-loadings'
import { subdomainTabs } from 'account/tabs/subdomain-tabs'
import { makePayload } from 'account/tabs/assets/bids/hooks/make-payload'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const cursor = useCursor(customCursor)
  const dispatch = useDispatch()
  const address = useViewerAddress()
  const updateLoadings = useUpdateLoadings(subdomainTabs.bids)
  const marketPlaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    updateLoadings()
    address && marketPlaceKey && dispatch(actions.attempt(makePayload({ address, cursor, marketPlaceKey })))
  }, [updateLoadings, address, dispatch, cursor, marketPlaceKey])
}
