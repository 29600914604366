import { gql } from 'graphql-tag'

export const GET_CLAIMABLE = gql`
    query accountClaimable($pagination: ConnectionArgs, $filters: MyClaimableAuctionsFilters) {
        myClaimableAuctions(pagination: $pagination, filters: $filters)
        {
            edges {
                node {
                    asset{
                        artist {
                            address
                            profile
                            herotag
                        }
                        identifier
                        name
                        ownerAddress
                        owner{
                            address
                            profile
                            herotag
                        }
                        media{
                            thumbnailUrl
                            fileType
                            url
                        }
                        type
                        scamInfo{
                            info
                            type
                        }
                        isNsfw
                        verified
                    }
                    endDate
                    id
                    identifier
                    maxBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    ownerAddress
                    owner{
                        address
                        profile
                        herotag
                    }
                    topBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    topBidder{
                        address
                        profile
                        herotag
                    }
                    type
                }
                cursor
            }
            pageInfo{
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            pageData{
                count
            }
        }
    }
`
