import { useCallback } from 'react'
import { testNumber } from 'components/forms/place-bid-form/hooks/use-tests'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const useShouldThrow = auction => {
  const minimum = useMinimum(auction)
  const tokenDecimals = get(auction, 'minBid.tokenData.decimals', 18)

  return useCallback(value => {
    if (!testNumber(value)) return false

    return parseFloat(value) < parseFloat(denominateAmount(minimum, tokenDecimals))
  }, [minimum, tokenDecimals])
}
