import { useValidationSchema } from 'components/forms/buy-sft-form/hooks/use-validation-schema'

export const useConfig = ({ onSubmit, initialValues, auction, initialTouched, paymentToken }) => ({
  initialValues: useValidationSchema(auction, paymentToken).cast(initialValues),
  validationSchema: useValidationSchema(auction, paymentToken),
  validateOnLoad: true,
  validateOnMount: true,
  validateOnChange: true,
  validateOnBlur: true,
  initialTouched,
  onSubmit,
})
