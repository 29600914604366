import { Failure } from 'failure'
import { Seo } from 'components/seo'
import { TabHeader } from 'account/tabs/tab-header'
import { Content } from 'account/tabs/assets/liked/content'
import { VisibleIfActive } from 'account/common/visible-if-active'
import { useSeoProps } from 'account/hooks/use-seo-props'
import { InnerBehaviour } from 'account/tabs/assets/liked/inner-behaviour'

export const Liked = () => <VisibleIfActive tab={'liked'}>
  <InnerBehaviour />
  <Seo {...useSeoProps('liked')} />

  <Failure.Partial id={'account.liked'}>
    <TabHeader />
    <Content />
  </Failure.Partial>
</VisibleIfActive>
