import { ofType } from 'redux-observable'
import { get as slice } from 'nft-page/likes/slices'
import { likes } from 'repositories/likes'
import { from, catchError, mergeMap } from 'rxjs'

export const get = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => likes.get(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = response => from([
  slice.actions.success(response),
])

const failure = response => from([
  slice.actions.failure(response),
])
