import { useResetState } from 'explore-nfts/tabs/collections/behaviour/hooks/use-reset-state'
import { useForceResetState } from 'explore-nfts/tabs/collections/behaviour/hooks/use-force-reset-state'
import { useFetchStats } from 'explore-nfts/tabs/collections/stats/hooks/use-fetch-stats'

export const Behaviour = () => {
  useResetState()
  useForceResetState()
  useFetchStats()

  return ''
}
