import { gql } from 'graphql-tag'

export const TRENDING_COLLECTIONS = gql`
    query homeTrendingCollections($pagination: ConnectionArgs, $sorting: CollectionsSortingEnum) {
        collections(pagination: $pagination, sorting: $sorting) {
            edges{
                cursor
                node{
                    collection
                    name
                    type
                    ownerAddress
                    description
                    artist{
                        address
                        herotag
                        profile
                    }
                    owner {
                        address
                        herotag
                        profile
                    }
                    collectionAsset {
                        totalCount
                        assets (input: { size: 10 }) {
                            identifier
                            thumbnailUrl
                            isNsfw
                            scamInfo {
                                info
                                type
                            }
                        }
                    }
                    verified
                }
            }
            pageData {
                count
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
