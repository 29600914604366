import { toArray, map } from 'rxjs'
import { emitArrayElements } from 'repositories/feed/common'

const format = ({ who, reference, timestamp }) => ({
  who,
  whom: { ...who, target: reference },
  when: timestamp,
  action: { type: 'photoChanged' },
})

export const photoChanged = group => group
  .pipe(toArray())
  .pipe(emitArrayElements())
  .pipe(map(format))
