import iconHome from 'startup/assets/icon-home.svg'
import iconVerified from 'startup/assets/icon-verified.svg'
import iconSearch from 'startup/assets/icon-search.svg'
import iconWorld from 'startup/assets/icon-world.svg'
import iconMegaphone from 'startup/assets/icon-megaphone.svg'

export const boxes = [
  {
    icon: iconHome,
    subtitle: <>
      Enhanced aggregation and curation of Web3 projects, through additional featured & trending
      areas of our homepage.
    </>,
  },
  {
    icon: iconVerified,
    subtitle: <>
      The source of truth, through the verified badge applied to verified NFTs, creators and collections.
    </>,
  },
  {
    icon: iconSearch,
    subtitle: <>
      Improved Explore section for easily finding specific NFTs, collections and creators with advanced
      filters and sorting options.
    </>,
  },
  {
    icon: iconWorld,
    subtitle: <>
      Social dynamics, through asynchronous user connectivity, public profiles linked to herotags, and
      customizable Web3 feed.
    </>,
  },
  {
    icon: iconMegaphone,
    subtitle: <>
      Promotion of ecosystem partners, through integration of their existing auctions and
      marketplace listings.
    </>,
  },
]
