import { NerdsForm } from 'components/forms/nerds-form'
import { useConfig } from 'components/forms/add-remove-featured-collection-form/hooks/use-config'
import { Fields } from 'components/forms/add-remove-featured-collection-form/fields'

const useFormProperties = props => ({
  loading: props.loading,
  config: useConfig({
    onSubmit: props.onSubmit,
    initialValues: props.initialValues,
    onRemoveClick: props.onRemoveClick,
  }),
  submitErrors: props.submitErrors,
  callbackUrl: props.callbackUrl,
})

export const AddRemoveFeaturedCollectionForm = props => <NerdsForm {...useFormProperties(props)}>
  <Fields callbackUrl={props.callbackUrl} submitErrors={props.submitErrors} onSubmit={props.onSubmit}
          featuredCollections={props.featuredCollections} />
</NerdsForm>
