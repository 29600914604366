import { useIsPreview } from '../../hooks/use-is-preview'
import { usePreviewComponent } from 'components/forms/nerds-form/fields/dropzone-field/hooks/use-preview-component'
import './index.scss'

export const Preview = () => {
  const PreviewComponent = usePreviewComponent()

  return (
    useIsPreview() && (
      <div className={'dropzone-field-preview-image'}>
        <PreviewComponent />
      </div>
    )
  )
}
