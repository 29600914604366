import { DropdownTokens } from 'components/dropdown-tokens'
import { PriceRange } from 'filters/components/price-range'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { useSetLocationFilters } from 'filters/hooks/use-set-location-filters'
import './index.scss'

const useShouldShowPriceRange = () => {
  const token = useFilterValue('token')

  return token !== '' && token !== 'All'
}

const getTokenOptions = tokens => {
  const options = tokens.map(item => ({ name: item.symbol, value: item.identifier, count: item.activeAuctions }))
  options.unshift({ name: 'All', value: 'All' })

  return options
}

export const PriceRangeToken = ({ minBid, maxBid, paymentTokens, resetOthers = [], actualCount }) => {
  const shouldShow = useShouldShowPriceRange()
  const setLocationFilters = useSetLocationFilters()
  const reset = resetOthers === true ? true : ['min', 'max', ...resetOthers]

  return <div className={'price-range-token'}>
    {shouldShow && <PriceRange minBid={minBid} maxBid={maxBid} />}
    <DropdownTokens options={getTokenOptions(paymentTokens)} actualCount={actualCount} onChange={option => {
      setLocationFilters({ name: 'token', value: option.value }, reset)
    }} />
  </div>
}
