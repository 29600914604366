import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useViewerUsername } from 'viewer/hooks/use-viewer-username'
import { useLocation } from 'react-router-dom'
import { useBackground } from 'account/account-wrapper/hooks/use-background'
import { useMemo } from 'react'
import { get } from 'lodash'
import { useCurrentLayer } from 'components/layered-router/hooks/use-current-layer'

export const useDestination = () => {
  const address = useViewerAddress()
  const username = useViewerUsername()
  const location = useLocation()
  const layerLink = get(location, 'state.layerLink', {})
  const background = useBackground()
  const currentLayer = useCurrentLayer()
  const path = location.pathname.replace(address, username)

  return useMemo(() => {
    if (!background) return path

    const newLayerLink = { ...layerLink }
    newLayerLink[currentLayer] = path

    return ({
      pathname: path,
      state: { layerLink: { ...layerLink } },
    })
  }, [background, path, layerLink, currentLayer])
}
