import { Item } from 'nft-page/details/other-listings/item'
import { useActualAuctions } from 'nft-page/details/other-listings/hooks/use-actual-auctions'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useHaveOtherListings } from 'nft-page/details/other-listings/hooks/use-have-other-listings'
import 'nft-page/details/other-listings/index.scss'
import { useMarketplaceContractIsPaused } from 'marketplaces/hooks/use-marketplace-contract-is-paused'

export const OtherListings = () => {
  const actualAuctions = useActualAuctions()
  const { asset } = useAssetWithOwner()
  const haveOtherListings = useHaveOtherListings()
  const isContractPaused = useMarketplaceContractIsPaused()
  const shouldDisplay = haveOtherListings && !isContractPaused

  return shouldDisplay ? <Item actualAuctions={actualAuctions} asset={asset} /> : ''
}
