import moment from 'moment'

export const byTypeFiltersMap = { 'buy-now': { field: 'maxBid', values: ['0.01'], op: 'GE' } }

export const filters = ({ filter }) => {
  const filters = [
    { field: 'status', values: ['Running'], op: 'EQ' },
    { field: 'startDate', values: [`${moment().unix()}`], op: 'LE' },
  ]
  byTypeFiltersMap[filter] && filters.push(byTypeFiltersMap[filter])

  return filters
}
