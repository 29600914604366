import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { useCallback } from 'react'

export const useOnClick = () => {
  const navigate = useLayeredNavigate()

  return useCallback(() => {
    navigate('/launch/modal/buy', 'modal')
  }, [navigate])
}
