import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-light-svg-icons'

import { CenteredContent } from 'components/centered-content'
import { useIsActiveUser } from 'common/hooks/use-is-active-user'
import { useOnEditClick } from 'account/profile-photo/hooks/use-on-edit-click'

const avatarClassname = 'd-flex justify-content-center profile-photo-container-edit-avatar'

export const Edit = () => {
  const isActiveUser = useIsActiveUser()
  const onClick = useOnEditClick()

  return isActiveUser && <CenteredContent className={'profile-photo-container-edit-container'}>
    <a className={avatarClassname} onClick={onClick}>
      <FontAwesomeIcon icon={faCamera} />
    </a>
  </CenteredContent>
}
