import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions as primarySale } from 'holoride-campaign/slices/primary-sale'
import { actions as isWhitelisted } from 'holoride-campaign/slices/is-whitelisted'
import { actions as myTickets } from 'holoride-campaign/slices/my-tickets'
import { useCmsCampaignCollectionName } from 'subdomain/cms-content/hooks/use-cms-campaign-collection'
import { actions as hasClaimedTickets } from 'holoride-campaign/slices/has-claimed-tickets'

export const useFetchCallback = () => {
  const dispatch = useDispatch()
  const collectionName = useCmsCampaignCollectionName()

  return useCallback(() => {
    dispatch(primarySale.attempt({ filters: { collectionName } }))
    dispatch(isWhitelisted.attempt())
    dispatch(myTickets.attempt({ collectionIdentifier: collectionName }))
    dispatch(hasClaimedTickets.attempt({ collectionIdentifier: collectionName }))
  }, [collectionName, dispatch])
}

