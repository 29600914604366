import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { useStatus } from 'nft-page/auction/actions/watchable-action/hooks/use-status'
import { actionTypes } from 'action-statuses/actions-types'

const useOnClick = ({ collection }) => {
  const navigate = useLayeredNavigate()
  const { pathname } = useLocation()

  return useCallback(() => {
    const path = `/collections/${collection}/modal/set-collection-roles?callbackUrl=${pathname}`
    navigate(path, 'modal')
  }, [collection, pathname, navigate])
}

export const SetRoles = ({ collection }) => {
  const onClick = useOnClick(collection)
  const status = useStatus(collection, actionTypes.setRoles)

  return <button type={'button'} className={'btn btn-link ps-2'} onClick={onClick} disabled={!!status}>
    Set Roles
  </button>
}
