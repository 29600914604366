import { Badge, BadgeType } from 'components/asset-card/badge'
import { DurationLabel } from 'components/duration-label/index'
import { useContext } from 'components/asset-card/time-left/context'

export const Content = props => {
  const { auctionTimeLeft } = useContext()

  return <Badge className={'badge-time-left'} label={<DurationLabel duration={auctionTimeLeft} />}
                suffix={'left'} type={BadgeType.TimeLeft} condition={props.condition} />
}
