import { PublicAccount } from 'account/content/public-account'
import { PrivateAccount } from 'account/content/private-account'
import { useIsActiveUser } from 'common/hooks/use-is-active-user'
import { useIsPublicProfile } from 'account/profile/hooks/use-is-public-profile'

export const Content = () => {
  const isActiveUser = useIsActiveUser()
  const isPublic = useIsPublicProfile()
  const shouldBePrivate = !isPublic && !isActiveUser

  return <div className={'container'}>
    {shouldBePrivate ? <PrivateAccount /> : <PublicAccount />}
  </div>
}
