import { useContext } from 'react'
import { MultiRangeContext } from 'filters/components/multi-range/context'
import { SliderRange } from 'filters/components/multi-range/slider-range'
import { useOnMaxChanged, useOnMinChanged } from 'filters/components/multi-range/inputs/hooks/use-on-max-changed'
import { Input } from 'filters/components/multi-range/inputs/input'

export const Inputs = ({ step, max, min, formatLabel }) => {
  const { onChange, setMaxVal, setMinVal, maxVal, minVal } = useContext(MultiRangeContext)
  const onMaxChanged2 = useOnMaxChanged({ setMaxVal, onChange, minVal, maxVal })
  const onMinChanged2 = useOnMinChanged({ setMinVal, onChange, minVal, maxVal })

  return <>
    <SliderRange max={max} min={min} maxVal={maxVal} minVal={minVal} />
    <Input min={min} max={max} value={String(minVal)} step={step} formatLabel={formatLabel}
           inputName={'min'} onChanged={onMinChanged2} className={'thumb thumb-left'} />
    <Input min={min} max={max} value={String(maxVal)} step={step} formatLabel={formatLabel}
           inputName={'max'} onChanged={onMaxChanged2} className={'thumb thumb-right'} />
  </>
}
