import { Username } from 'components/username'
import { Cover } from 'components/profile-card/cover'
import { Description } from 'components/profile-card/description'
import { Action } from 'components/profile-card/action'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { UserImage } from 'components/user-image'
import defaultAvatar from 'common/default-avatar'
import { accountPath } from 'common/account-path'
import { usePurgedHerotag } from 'components/username/hooks/use-purged-herotag'
import 'components/profile-card/style.scss'
import { LayeredLink } from 'components/layered-router/layered-link'

export const Content = props => {
  const purgedHerotag = usePurgedHerotag(props.herotag)
  const to = accountPath(props.address, purgedHerotag && `@${purgedHerotag}`)

  return <div className={'col-12 col-sm-6 col-lg-4 col-xl-3 d-flex'}>
    <div className={'profile-card card-item w-100 d-flex'}>
      <LayeredLink className={'profile-card-link'} path={to} layer={'middleground'} />
      <div className={'d-flex flex-column align-items-center flex-grow-1 w-100'}>
        <Cover address={props.address} cover={props.cover} />
        <div className={'profile-card-content flex-grow-1'}>
          <UserImage image={get(props.profile, 'url', defaultAvatar)} />
          <Username herotag={props.herotag} address={props.address} />
          <Description description={props.description} />
          <Action address={props.address} isFollowed={props.isFollowed} />
        </div>
      </div>
    </div>
  </div>
}

Content.defaultProps = {
  isFollowed: false,
  cover: {},
  description: '',
  herotag: '',
  profile: {},
}

Content.propTypes = {
  address: PropTypes.string.isRequired,
  cover: PropTypes.object,
  description: PropTypes.string,
  herotag: PropTypes.string,
  profile: PropTypes.object,
  isFollowed: PropTypes.bool,
}
