import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

export const useLayeredNavigate = () => {
  const navigate = useNavigate()
  const layeredLocation = useLayeredLocation()

  return useCallback((path, layerName, previewSource) => {
    const { pathname, state } = layeredLocation(path, layerName, previewSource)
    navigate(pathname, { state })
  }, [navigate, layeredLocation])
}
