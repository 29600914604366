import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: { assets: { edges: [] } },
  input: '',
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload) })

export const collectionAssets = createSlice({
  initialState,
  name: 'collectionAssets',
  reducers: {
    attempt,
    success,
    failure,
  },
})

export const { actions } = collectionAssets
