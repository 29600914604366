import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useHasMore } from 'account/tabs/assets/liked/hooks/use-has-more'
import { useNext } from 'account/tabs/assets/liked/hooks/use-next'
import { Row } from 'components/row'
import { Item } from 'account/common/item'
import { useItems } from 'account/tabs/assets/liked/hooks/use-items'
import { CardItem } from 'account/tabs/assets/liked/card-item'
import { useIsLoading } from 'account/tabs/assets/liked/hooks/use-is-loading'

export const List = () => <InfiniteScroll hasMore={useHasMore()} next={useNext()} isLoading={useIsLoading()}>
  <Row className={'assets-container'}>
    {useItems().map((item, index) => <Item key={index}>
      <CardItem item={item} />
    </Item>)}
  </Row>
</InfiniteScroll>
