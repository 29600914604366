import 'account/tabs/assets/style.scss'
import { DataCard } from 'components/data-card'
import { Creations } from 'account/tabs/assets/creations'
import { Collected } from 'account/tabs/assets/collected'
import { Liked } from 'account/tabs/assets/liked'
import { Behaviour as CollectedBehaviour } from 'account/tabs/assets/collected/behaviour'
import { Behaviour as CreationsBehaviour } from 'account/tabs/assets/creations/behaviour'
import { Behaviour as AuctionsBehaviour } from 'account/tabs/assets/auctions/behaviour'
import { Behaviour as BidsBehaviour } from 'account/tabs/assets/bids/behaviour'
import { Behaviour as ClaimableBehaviour } from 'account/tabs/assets/claimable/behaviour'
import { Behaviour as LikedBehaviour } from 'account/tabs/assets/liked/behaviour'

export const Assets = () => <div>
  <CollectedBehaviour />
  <CreationsBehaviour />
  <AuctionsBehaviour />
  <BidsBehaviour />
  <ClaimableBehaviour />
  <LikedBehaviour />

  <DataCard disableContentContainer>
    <Collected />
    <Creations />
    {/* <Auctions /> */}
    {/* <Bids /> */}
    {/* <Claimable /> */}
    <Liked />
  </DataCard>
</div>
