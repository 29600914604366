import 'components/collection-card/link/index.scss'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'
import { Link as RouterLink } from 'react-router-dom'

export const Link = ({ collection, openNewLayer }) => {
  const path = `/collections/${collection?.collection}`
  const layeredLocation = useLayeredLocation()
  const to = openNewLayer ? layeredLocation(path, 'middleground') : { pathname: path }

  return (
    <RouterLink className={'collection-card-link'} to={to.pathname} state={to.state} />
  )
}
