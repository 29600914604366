import { get } from 'lodash'

const sortFilters = {
  oldest: { direction: 'ASC', field: 'creationDate' },
  newest: { direction: 'DESC', field: 'creationDate' },
  'ending-first': { direction: 'ASC', field: 'endDate' },
}

export const sorting = sort => {
  const isLowestOrHighest = sort === 'lowest-price' || sort === 'highest-price'

  return isLowestOrHighest ? [] : [get(sortFilters, sort, sortFilters.newest)]
}
