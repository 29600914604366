import { useContext } from 'components/collection-card/context'
import { mediaQuery } from 'components/collection-card/media-query'

export const useMouseHandlers = () => {
  const { setRunning } = useContext()

  return {
    onMouseEnter: () => {
      mediaQuery.matches && setRunning(true)
    },
    onMouseLeave: () => {
      mediaQuery.matches && setRunning(false)
    },
  }
}
