import { Icon } from 'failure/component/icon'
import { Retry } from 'failure/component/retry'
import { Message } from 'failure/component/message'

const CLASS_NAME = 'failure pt-4 py-5 w-100 d-flex justify-content-center flex-column'

export const Component = ({ id }) => <div className={CLASS_NAME}>
  <Icon />
  <Message id={id} />
  <Retry />
</div>
