import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { notifications as repository } from 'repositories/notifications'
import { actions } from 'notifications/account/slices'
import { mergeMap, catchError } from 'rxjs/operators'

export const notifications = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = action => repository.get(action.payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = errors => from([
  actions.failure(errors),
])
