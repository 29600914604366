import { gql } from 'graphql-tag'

export const GET_AUCTIONS = gql`
    query auctions($sorting: [Sorting!], $filters: FiltersExpression, $grouping: Grouping, $pagination: ConnectionArgs) {
      auctions(sorting: $sorting, filters: $filters, grouping: $grouping, pagination: $pagination)
  {
    edges {
      node {
        asset{
          attributes
          balance
          collection
          creationDate
          artist {
              address
              profile
              herotag
          }
          identifier
          metadata{
            description
          }
          name
          ownerAddress
          owner{
            address
            profile
            herotag
          }
          royalties
          tags
          media{
              thumbnailUrl
              fileType
              url
          }  
          type
          uris
          isNsfw
          verified
        }
        availableTokens
        collection
        endDate
        id
        identifier
        marketplaceKey  
        marketplace{
            name
            url
            iconUrl
        }  
        maxBid {
          amount
          token
          usdAmount
          token{
              decimals
              symbol
          }    
        }
        minBid {
          amount
          token
          usdAmount
          token{
              decimals
              symbol
          }
        }
        nrAuctionedTokens
        orders{
          edges{
              cursor
              node{
                  auctionId
                  from{
                      address
                      herotag
                      profile
                  }
                  id
                  ownerAddress
                  creationDate
                  price{
                      amount
                      token
                      usdAmount
                      tokenData{
                          decimals
                          symbol
                      }
                  }
              }
          }
          pageData{
              count
          }  
          pageInfo{
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
          }  
        }
        ownerAddress
        owner{
          address
          profile
          herotag
        }
        startDate
        status
        tags
        topBid {
          amount
          token
          usdAmount
          tokenData{
              decimals
              symbol
          }  
        }
        topBidder{
          address
          profile
          herotag
        }
        type
      }
      cursor
    }
    pageInfo{
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    pageData{
      count
    }
  }
 }
`
