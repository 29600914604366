import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export const useOnClick = () => {
  const navigate = useNavigate()

  return useCallback(tag => {
    const parsed = new URLSearchParams('')
    parsed.append('tag', tag)

    navigate({ pathname: `/nfts`, search: parsed.toString(), state: { forceReset: true } })
  }, [navigate])
}
