import { useRef } from 'react'
import { Player } from 'nft-page/media/video/player'
import { PlayerControls } from 'components/player-controls'

export const Video = ({ onMediaLoaded }) => {
  const player = useRef(null)

  return <>
    <Player player={player} onMediaLoaded={onMediaLoaded} />
    <PlayerControls player={player} />
  </>
}
