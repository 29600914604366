import { isEmpty } from 'lodash'

const integers = denominatedValue => denominatedValue.split('.')[0]

const decimals = denominatedValue => denominatedValue.split('.')[1] || ''

const delimiter = denominatedValue => isEmpty(decimals(denominatedValue)) ? '' : '.'

export const extract = {
  integers,
  decimals,
  delimiter,
}
