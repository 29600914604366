import { get } from 'lodash'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { useSettings } from 'settings/hooks/use-settings'

const testDecimals = decimals => value => get(value?.split('.'), '[1].length', 0) <= decimals

const testComma = value => !value?.includes(',')

const testStartDot = value => !value?.startsWith('.')

const testEndDot = value => !value?.endsWith('.')

const testValue = value => value > 0

const testMaxMaxBidValue = limit => value => parseFloat(value) <= limit

const testMaxMinBidValue = limit => value => parseFloat(value) <= limit

const testLeadingZeros = (value, context) => {
  const leadingZerosCondition = !context.originalValue.startsWith('0') || context.originalValue.startsWith('0.')

  return context.originalValue && leadingZerosCondition
}

export const testNumber = value => value?.match(/^\d*\.?\d*$/)

export const useTests = () => {
  const decimals = useDenominationDecimals()
  const { maxBidLimit, minBidLimit } = useSettings()

  return {
    testValue,
    testDecimals: testDecimals(decimals),
    testComma,
    testStartDot,
    testEndDot,
    testLeadingZeros,
    testMaxMaxBidValue: testMaxMaxBidValue(maxBidLimit),
    testMaxMinBidValue: testMaxMinBidValue(minBidLimit),
    testNumber,
  }
}
