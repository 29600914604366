import { useList } from 'subdomain/modals/account/my-bids/hooks/use-list'
import { flatten, forEach, groupBy, takeRight } from 'lodash'

export const useFlattenBidsItems = () => {
  const bids = useList()
  const groupedBids = groupBy(bids, 'node.auctionId')

  const newList = []
  forEach(groupedBids, element => {
    newList.push(takeRight(element))
  })

  return flatten(newList)
}
