import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'subdomain/home/collections/slices/collections'
import { useSubdomainIdentifiers } from 'subdomain/home/collections/hooks/use-subdomain-identifiers'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'

export const useFetchSubdomainCollections = () => {
  const dispatch = useDispatch()
  const isSubdomain = useIsSubdomain()
  const subdomainIdentifiers = useSubdomainIdentifiers()

  useEffect(() => {
    isSubdomain && subdomainIdentifiers
      .map(identifier => dispatch(actions.attempt({ filters: { collection: identifier }, input: { size: 8 } })))
  }, [dispatch, isSubdomain, subdomainIdentifiers])
}
