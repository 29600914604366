import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/creations/slices/collections'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useEffect } from 'react'

const makePayload = address => (
  {
    filters: { artistAddress: address },
    pagination: { first: 100 },
  }
)

export const useFetchCollections = () => {
  const dispatch = useDispatch()
  const viewerAddress = useViewerAddress()

  return useEffect(() => {
    dispatch(actions.attempt(makePayload(viewerAddress)))
  }, [dispatch, viewerAddress])
}
