import { useErrors } from 'account/tabs/assets/collected/behaviour/hooks/use-errors'
import { isEmpty } from 'lodash'
import { Failure } from 'failure'
import { useEffect } from 'react'

export const usePartialFailure = () => {
  const errors = useErrors()
  const showFailure = Failure.useShowFailure()

  useEffect(() => {
    if (isEmpty(errors)) return

    showFailure({ id: 'account.collected', variant: Failure.VARIANT.partial })
  }, [errors, showFailure])
}
