import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { addBlacklistedCollection } from 'admin/slices'
import { useParams } from 'react-router-dom'

export const useAddBlacklistedCollection = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    if (window.confirm('Are you sure?') === true) {
      dispatch(addBlacklistedCollection.actions.attempt({ collection: identifier }))
    }
  }, [dispatch, identifier])
}
