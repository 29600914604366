import { useNavigate, useLocation } from 'react-router-dom'

export const CancelButton = ({ callbackUrl }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    if (!location?.state?.hydrated) {
      navigate(-1)
    } else {
      navigate(callbackUrl, { replace: true })
    }
  }

  return <button className={'btn btn-lg btn-secondary w-100'} type={'button'} onClick={handleClick}>
    Close
  </button>
}
