import { useFollowAccount, useUnfollowAccount } from 'feed/initial/hooks'
import { ProfileCard } from 'components/profile-card'
import { useState } from 'react'

export const Item = ({ account }) => {
  const [followed, setFollowed] = useState(false)
  const follow = useFollowAccount(setFollowed, account)
  const unfollow = useUnfollowAccount(setFollowed, account)

  return <ProfileCard {...account} cover={{ url: account.cover }} isFollowed={followed}
                      onFollow={follow} onUnfollow={unfollow} />
}
