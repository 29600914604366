import { useState } from 'react'
import { ReadMore } from 'components/trimmed-text/read-more'
import 'components/trimmed-text/index.scss'

export const TrimmedText = ({ text, length = 10 }) => {
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => setIsReadMore(!isReadMore)

  return <div className={'trimmed-text-read-more accent'}>
    {isReadMore ? text.slice(0, length) : text}
    {text.length >= length ? <ReadMore isReadMore={isReadMore} toggleReadMore={toggleReadMore} /> : ''}
  </div>
}
