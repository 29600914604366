import { get } from 'lodash'
import { Header } from 'components/featured-collection/header'
import { Content } from 'components/featured-collection/content'
import { Background } from 'components/featured-collection/header/background'
import './index.scss'
import { CollectionLink } from 'components/featured-collection/header/collection-link'

export const FeaturedCollection = ({ collection }) => {
  const items = get(collection, 'node.assets.edges', [])
  const name = get(collection, 'node.name', '')
  const identifier = get(collection, 'node.collection', '')
  const description = get(collection, 'node.description')
  const thumbnailUrl = get(collection, 'node.assets.edges[0].node.media[0].thumbnailUrl')

  return <div className={'featured-collection'}>
    <Background thumbnailUrl={thumbnailUrl} />
    <div className={'container'}>
      <CollectionLink link={`/collections/${identifier}`} />
      <Header name={name} description={description} thumbnailUrl={thumbnailUrl} />
      <Content items={items} />
    </div>
  </div>
}
