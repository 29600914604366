import { gql } from 'graphql-tag'

export const NOTIFICATIONS = gql`
    query notification( $pagination: ConnectionArgs, $filters: NotificationsFilters) {
        notifications(pagination: $pagination, filters: $filters) {
            edges {
                cursor
                node {
                    auctionId
                    type
                    identifier
                    name
                }
            }
            pageData {
                count
                limit
                offset
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
