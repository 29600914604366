import { Media } from 'holoride-campaign/media'
import { Content } from 'holoride-campaign/content'
import { Row } from 'components/row'
import { Container } from 'components/container'
import { Seo } from 'components/seo'
import { useSeoProps } from 'holoride-campaign/hooks/use-seo-props'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import 'holoride-campaign/index.scss'

export const HolorideCampaign = () => <div className={'holoride-campaign'}>
  <Container>
    <ScrollToTopOnMount />
    <Seo {...useSeoProps()} />
    <Row className={'align-items-center'}>
      <Media />
      <Content />
    </Row>
  </Container>
</div>

