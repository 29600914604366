import { useSelector } from 'react-redux'
import { get } from 'lodash'

const path = 'viewer.address.data.username'

export const useViewerUsername = () => {
  const username = useSelector(state => get(state, path, ''))

  return !username ? '' : `@${username?.split('.')[0]}`
}

