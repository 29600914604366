import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { followees } from 'account/profile/follow/slices/followees'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useFollowees = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()

  useEffect(() => {
    address && dispatch(followees.actions.attempt({ address }))
  }, [dispatch, address])
}
