import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Subtitle } from 'components/forms/nerds-form/subtitle'
import { DependentField } from 'components/forms/nerds-form/fields/dependent-field'
import { AuctionsField } from 'components/forms/nerds-form/fields/auctions-field'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Errors } from 'components/forms/nerds-form'
import { get } from 'lodash'
import { getPriceDetails } from 'common/get-price-details'

export const Fields = ({ submitErrors, callbackUrl, offer, auctions }) => {
  const offerPrice = get(offer, 'price')
  const { millyfiedAmount, token } = getPriceDetails(offerPrice)
  const quantity = get(offer, 'quantity')
  const pieces = quantity === '1' ? 'piece' : 'pieces'

  return <div className={'modal-content'}>
    <Title icon={faGavel} title={'Accept Offer'} margins={'mb-2'} />
    <Subtitle align={'center'} subtitle={`Offer amount ${millyfiedAmount} ${token} for ${quantity} ${pieces}`} />
    <DependentField name={'auctionId'} dependentName={'checkedAuction'} dependentLabel={'Withdraw active auction?'}
                    showSwitch={auctions.length}>
      {auctions.length ? <AuctionsField name={'auctionId'} auctions={auctions} /> : ''}
    </DependentField>
    <DefaultActions callbackUrl={callbackUrl} label={'Accept Offer'} />
    <Errors values={submitErrors} />
  </div>
}
