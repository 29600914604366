import { TransactionToast } from 'components/toasts/transaction-toast'
import { useGetTransactionDisplayInfo } from '@multiversx/sdk-dapp/hooks'
import { useShownTransactions } from 'notifications/transactions/hooks/use-shown-transactions'
import { useUnseenToasts } from 'notifications/transactions/hooks/use-unseen-toasts'

export const Transactions = () => {
  const transactionInfo = useGetTransactionDisplayInfo()
  const shownTransactionsIds = useShownTransactions()
  const unseenToasts = useUnseenToasts()

  return unseenToasts
    .map(([id, transaction]) => <TransactionToast key={id} id={id} shown={shownTransactionsIds.includes(id)}
                                                  transaction={transaction} transactionsInfo={transactionInfo} />)
}

