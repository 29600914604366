const createImage = url => new Promise((resolve, reject) => {
  const image = new window.Image()
  image.addEventListener('load', () => resolve(image))
  image.addEventListener('error', error => reject(error))
  image.src = url
})

export default async function getCroppedImg(imageSrc, pixelCrop, dimensions) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = dimensions.width
  canvas.height = dimensions.height

  const source = [pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height]
  const destination = [0, 0, dimensions.width, dimensions.height]

  ctx.drawImage(image, ...source, ...destination)

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file)
    }, 'image/png')
  })
}
