import { get, reject } from 'lodash'
import { useParams } from 'react-router-dom'
import { useList } from 'nft-page/details/other-listings/hooks/use-list'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'

const matchesParameter = auctionId => ({ node }) => get(node, 'id') === auctionId

export const useActualAuctions = () => {
  const { auctionId } = useParams()
  const auctions = useList()
  const auction = useNftDefaultAuction()
  const id = get(auction, 'id', '')

  return reject(auctions, matchesParameter(auctionId || id))
}
