import { useSelector } from 'react-redux'
import { get } from 'lodash'

const listPath = 'feed.get.list.length'

const hasMorePath = 'feed.get.data.hasMore'

export const useIsEmpty = () => {
  const listEmpty = useSelector(state => !get(state, listPath, 0))
  const hasMoreItems = useSelector(state => get(state, hasMorePath, true))

  return listEmpty && !hasMoreItems
}
