import { useResetCreations } from 'account/tabs/assets/creations/behaviour/hooks/use-reset-creations'
import { useResetList } from 'account/tabs/assets/creations/hooks/use-reset-list'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { useEffect } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useResetState = () => {
  const resetCreations = useResetCreations()
  const resetList = useResetList()
  const address = useViewerAddress()
  const collectionFilter = useFilterValue('collection')

  useEffect(() => {
    resetCreations()
    resetList()
  }, [resetCreations, resetList, address, collectionFilter])
}
