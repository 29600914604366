import { AccordionItem } from 'components/accordion/accordion-item'
import { LoadMore } from 'nft-page/auction/bids/load-more'
import { List } from 'nft-page/auction/bids/list'
import 'nft-page/auction/bids/orders.scss'

export const Orders = () => <div className={'accordion orders-accordion'}>
  <AccordionItem title={'Bids'} id={'Bids'}>
    <List />
    <LoadMore />
  </AccordionItem>
</div>
