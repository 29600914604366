import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/collected/slices/get'
import { useEndCursor } from 'account/tabs/assets/collected/hooks/use-end-cursor'
import { publicTabs } from 'account/tabs/public-tabs'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useUpdateLoadings } from 'account/tabs/assets/hooks/use-update-loadings'
import { settings } from 'settings/fetching'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const cursor = useCursor(customCursor)
  const dispatch = useDispatch()
  const updateLoadings = useUpdateLoadings(publicTabs.collected)
  const address = useViewerAddress()

  return useCallback(() => {
    updateLoadings()

    if (address) {
      dispatch(actions.attempt({
        pagination: { first: settings().listRequestSize, after: cursor },
        filters: { ownerAddress: address },
      }))
    }
  }, [updateLoadings, address, dispatch, cursor])
}
