import { Button } from './button'
import { get } from 'lodash'
import { Title } from 'holoride-campaign/action/title'
import { Field } from './field/index'
import { Context, useDefaultContext } from 'holoride-campaign/action/buy/context'
import { Counter } from 'holoride-campaign/action/buy/counter'
import './index.scss'
import { Price } from 'holoride-campaign/action/buy/field/price'

export const Buy = ({ primarySale, myTickets }) => {
  const maxUnitsPerWallet = get(primarySale, 'maxUnitsPerWallet')
  const allTicketsBought = maxUnitsPerWallet === myTickets.length

  return <Context.Provider value={useDefaultContext()}>
    <div className={'holoride-action-inner holoride-campaign-buy'}>
      <Title>SALE IS LIVE</Title>
      <Counter primarySale={primarySale} />
      {primarySale && <Price primarySale={primarySale} />}
      <Field label={'Max amount:'} value={maxUnitsPerWallet} />
      <hr />
      {!allTicketsBought ? <Button>Buy</Button> : ''}
      <Field label={'Tickets bought:'} value={`${myTickets.length} / ${maxUnitsPerWallet}`} />
    </div>
  </Context.Provider>
}
