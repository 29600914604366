import { useCallback } from 'react'

import { useShouldThrow } from 'components/forms/place-bid-form/hooks/use-should-throw'
import { useMinimumMessage } from 'components/forms/place-bid-form/hooks/use-minimum-message'

export const useTestMinimum = (auction, paymentToken) => {
  const shouldThrow = useShouldThrow(auction)
  const makeMessage = useMinimumMessage(auction, paymentToken)

  return useCallback((value, context) => {
    if (!shouldThrow(value)) return true

    return context.createError({ message: makeMessage(context), path: context.path })
  }, [shouldThrow, makeMessage])
}
