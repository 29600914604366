import { BootstrapModal } from 'components/bootstrap-modal'
import { useComponent } from 'modals/collection/create/hooks/use-component'
import { useRedirectAccountModals } from 'modals/hooks/use-redirect-account-modals'

export const CreateCollection = () => {
  useRedirectAccountModals()
  const Component = useComponent()

  return <BootstrapModal id={'create-collection'}>
    <Component />
  </BootstrapModal>
}
