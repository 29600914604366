import { useEffect } from 'react'
import { useContext } from 'components/player/context'

export const useTeardown = () => {
  const { player } = useContext()

  useEffect(() => () => {
    if (!player.current) return

    player.current.dispose()
    player.current = null
  }, [player])
}
