import { Username } from 'components/username'
import { Title } from 'components/user-info/title'
import { UserImage } from 'components/user-image'
import { FlexRow } from '../flex-row'
import PropTypes from 'prop-types'
import defaultAvatar from 'common/default-avatar'
import 'components/user-info/index.scss'
import { useIsSubdomainDisabledClass } from 'common/hooks/use-is-subdomain-disabled-class'

export const UserInfo = ({
  herotag, label, image, isPrivate,
  className, size, address, linkDestination = 'collected', color, showExplorerLink = false,
}) => {
  const disabledClass = useIsSubdomainDisabledClass()

  return (
    <FlexRow className={`user-info align-items-center ${disabledClass} ${className} size-${size}`}>
      <UserImage image={image} herotag={herotag} address={address} linkDestination={linkDestination}
                 isPrivate={isPrivate} />
      <FlexRow className={'user-info-right align-items-center flex-grow-1'}>
        <Username herotag={herotag} address={address} linkDestination={linkDestination} color={color}
                  showExplorerLink={showExplorerLink} isPrivate={isPrivate} />
        <Title label={label} />
      </FlexRow>
    </FlexRow>
  )
}

UserInfo.defaultProps = {
  herotag: '',
  image: defaultAvatar,
}

UserInfo.prototype = {
  herotag: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}
