import { useHasMore, useFlattenBidsItems, useNext } from 'subdomain/modals/account/my-bids/hooks'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useIsLoading } from 'subdomain/modals/account/my-bids/hooks/use-is-loading'
import { Loader } from 'subdomain/modals/account/my-bids/loader'
import { MyAssetCard } from 'subdomain/components/my-asset-card'

export const List = () => {
  const list = useFlattenBidsItems()
  const hasMore = useHasMore()
  const next = useNext()
  const isLoading = useIsLoading()

  return <InfiniteScroll next={next} className={'my-bids-modal-list'} hasMore={hasMore} isLoading={isLoading}>
    {list.map(({ node }) => <MyAssetCard key={node.auctionId}
                                         auction={node.auction}
                                         asset={node.auction.asset} />)}
    <Loader />
  </InfiniteScroll>
}
