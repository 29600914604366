import { useExtensionLogin } from 'sidebar/connect/extension-login/hooks/use-extension-login'
import { useNavigateToStore } from 'sidebar/connect/extension-login/hooks/use-navigate-to-store'
import { useIsExtensionInstalled } from 'sidebar/connect/extension-login/hooks/use-is-extension-installed'

export const usePickCallback = () => {
  const extensionLogin = useExtensionLogin()
  const navigateToStore = useNavigateToStore()

  return useIsExtensionInstalled() ? extensionLogin : navigateToStore
}
