import { ReactComponent as Arrow } from 'startup/assets/section-arrow-down.svg'
import 'featured-pages/components/header/index.scss'
import { Visuals } from 'featured-pages/components/header/visuals/index'

export const Header = ({ children, label, visuals }) => <div className={'featured-page-header'}>
  <Visuals visuals={visuals} />
  <div className={'container'}>
    <div className={'featured-page-header-label'}>{label}</div>
    {children}
  </div>
  <Arrow className={'featured-page-header-arrow'} />
</div>

