import { Filters } from 'explore-nfts/tabs/nfts/filters'
import { DataCard } from 'components/data-card'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { TabContent } from 'components/tabs/tab-content'
import { usePathname } from 'explore-nfts/tabs/hooks/use-pathname'
import { Assets } from 'explore-nfts/tabs/nfts/assets'
import { Auctions } from 'explore-nfts/tabs/nfts/auctions'
import './index.scss'

const cardProps = () => ({ title: '' })

export const Nfts = () => {
  const tab = usePathname()

  return <TabContent id={'explore-nfts-tab'} active={tab === 'nfts'}>
    <ScrollToTopOnMount />
    <DataCard {...cardProps()}>
      <Filters />
      <Assets />
      <Auctions />
    </DataCard>
  </TabContent>
}
