import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { auctions } from 'repositories/auctions'
import { actions } from 'trending/slices/trending'
import { failure as failureSlice } from 'failure/slices'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { loading } from 'loading/slices'
import { Loading } from 'loading'

export const trending = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => auctions.getTrendingSortedByBids(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  loading.actions.hide({ id: Loading.ID.trending.page, variant: Loading.VARIANT.ghostCard }),
])

const failure = error => from([
  actions.failure(error),
  loading.actions.hide({ id: Loading.ID.trending.page, variant: Loading.VARIANT.ghostCard }),
  failureSlice.actions.show({}),
])
