import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { follow } from 'feed/suggestions/slices'

export const useOnFollow = (setFollowed, account) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(follow.actions.attempt({ address: account.address }))
    setFollowed(true)
  }, [account.address, dispatch, setFollowed])
}
