import { useIsSubdomainNfts } from 'nft-page/existing/hooks/use-is-subdomain-nfts'
import { NotFound } from 'nft-page/existing/not-found'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import match from '@nerds.sh/js-pattern'

export const Whitelisted = ({ children }) => {
  const isSubdomain = useIsSubdomain()
  const isSubdomainNfts = useIsSubdomainNfts()

  return match({})
    .with(() => !isSubdomain, () => children)
    .with(() => isSubdomainNfts, () => children)
    .otherwise(() => NotFound())
}
