import { map, tap, mergeMap } from 'rxjs'
import { combine } from 'settings/combine'
import { fetch } from 'settings/repositories/fetch'
import { bridge } from 'settings/repositories/bridge'

let fetchedSettings = null
let fetchedBridgeAddresses = null

export const settings = () => {
  const wereFetched = !!fetchedSettings && !!fetchedBridgeAddresses

  if (!wereFetched) throw new Error('It seems that settings were not fetched yet...')

  return combine({ fetchedSettings, fetchedBridgeAddresses })
}

export const setup = () => fetch()
  .pipe(tap(response => { fetchedSettings = response }))
  .pipe(mergeMap(bridge.fetchAddresses))
  .pipe(tap(response => { fetchedBridgeAddresses = response }))
  .pipe(map(() => settings()))
