import { get } from 'lodash'
import { useDefaultContext, Context } from 'nft-page/offers/content/context'
import { Behaviour } from 'nft-page/offers/content/behaviour'
import { Countdown } from 'nft-page/offers/content/countdown'
import { User } from 'nft-page/offers/content/user'
import './index.scss'

export const Content = ({ offer }) => {
  const quantity = get(offer, 'quantity')
  const pieces = quantity === '1' ? 'piece' : 'pieces'

  return <div className={'nft-page-offer'}>
    <Context.Provider value={useDefaultContext()}>
      <Behaviour offer={offer} />
      <div className={'d-flex justify-content-between mb-1 align-items-center'}>
        {`Offer for ${quantity} ${pieces}`}
        <Countdown />
      </div>
      <User offer={offer} />
    </Context.Provider>
  </div>
}
