import { DataCard } from 'components/data-card'
import { ReactComponent as Bomb } from 'startup/assets/bomb.svg'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { useNext } from 'campaigns/hooks/use-next'
import { useHasMore } from 'campaigns/hooks/use-has-more'
import { List } from 'campaigns/list'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useIsLoading } from 'campaigns/hooks/is-loading'
import { Behaviour } from './behaviour'
import { Seo } from 'components/seo'

const campaignsProps = {
  title: 'Live & Upcoming NFT Launches',
  icon: <Bomb />,
}

export const Campaigns = () => {
  const isLoading = useIsLoading()

  return <>
    <Seo title={'Campaigns'} />
    <DataCard {...campaignsProps}>
      <Behaviour />
      <ScrollToTopOnMount />
      <InfiniteScroll next={useNext()} hasMore={useHasMore()} isLoading={isLoading}>
        <List />
      </InfiniteScroll>
    </DataCard>
  </>
}

