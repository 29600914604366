import { useEffect } from 'react'
import moment from 'moment/moment'
import { useRefetch } from 'holoride-campaign/action/hooks/use-refetch'

export const useSetTimer = date => {
  const refetch = useRefetch()
  const eventDate = moment(date * 1000)
  const dateTimer = moment.duration(eventDate.diff(moment(), 'milliseconds', true))
  const miliseconds = dateTimer.asMilliseconds()

  useEffect(() => {
    let timer = 0

    if (miliseconds >= 0 && miliseconds < 172800000) {
      timer = setTimeout(() => {
        refetch()
      }, miliseconds)
    }

    return () => timer && clearTimeout(timer)
  }, [date, miliseconds, refetch])
}
