import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { addBlacklistedCollection } from 'admin/slices'

export const useAddBlacklistedCollectionInModal = () => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    dispatch(addBlacklistedCollection.actions.attempt({ collection: payload.collection }))
  }, [dispatch])
}
