import { Description } from 'holoride-campaign/action/description'
import { Title } from 'holoride-campaign/action/title'
import { useIsWhitelistedMessage } from 'holoride-campaign/hooks/use-is-whitelisted-message'

export const NotWhitelisted = () => {
  const description = useIsWhitelistedMessage()

  return <div className={'holoride-action-inner'}>
    <Title>NOT ELIGIBLE</Title>
    <Description>
      {description}
    </Description>
    <Description className={'mt-3'}>
      {'All sale participants need to have created a '}
      <a href={'https://my.holoride.com/'} target={'_blank'} rel={'noreferrer noopener'}>holoride account</a>
      {' and connected their wallet to be eligible to purchase tickets.'}
    </Description>
    <Description className={'mt-3'}>
      {`If you're on the whitelist and have just recently created a holoride account and connected your wallet, 
      your current status might not have been updated. Please allow for up to 24 hours for this to take effect.`}
    </Description>
  </div>
}
