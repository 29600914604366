import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'
import { useContext } from 'nft-page/auction/countdown/timer/context'
import { actions } from 'nft-page/slices/nft'

export const useFetchEndedAuction = () => {
  const dispatch = useDispatch()
  const { timeLeft } = useContext()
  const { identifier } = useParams()
  const unixTime = parseInt(moment(timeLeft).unix()
    .toString())

  useEffect(() => {
    if (unixTime === 1) {
      dispatch(actions.attempt({ filters: { identifier } }))
    }
  }, [dispatch, identifier, unixTime])
}
