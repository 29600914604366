import { ActionIcon } from 'components/feed-card/action-icon'
import { UserImage as Image } from 'components/user-image'
import { get } from 'lodash'
import 'components/feed-card/user-image/index.scss'
import { useHerotag } from 'components/feed-card/hooks/use-herotag'

export const UserImage = props => <div className={'feed-card-user-image position-relative'}>
  <Image image={get(props, 'who.profile.url')} herotag={useHerotag(props.who.herotag)}
         address={props.who.address} openNewLayer />
  <ActionIcon actionType={props.action.type} />
</div>
