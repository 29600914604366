import { Item } from 'components/collection-stats/item'
import { name } from 'components/collection-stats/name'
import 'components/collection-stats/index.scss'

export const CollectionStats = ({ collectionStats, ...rest }) => <div className={'collection-stats'}>
  <div className={'row justify-content-center'}>
    <Item label={name.items} value={collectionStats.items} />
    <Item label={name.activeAuctions} value={collectionStats.activeAuctions} {...rest} />
    <Item label={name.minPrice} value={collectionStats.minPrice} isPrice {...rest} />
  </div>
</div>
