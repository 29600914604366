/* eslint-disable max-lines-per-function */
import { useHexToHsl } from 'common/hooks/use-hex-to-hsl'
import { useHexToRgb } from 'common/hooks/use-hex-to-rgb'
import { get } from 'lodash'
import { useEffect } from 'react'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCmsFeatureColor = () => {
  const data = useCmsData()
  let featureColor = get(data, 'data.data.attributes.featureColor')

  featureColor = featureColor.match(/^#([0-9a-f]{3}){1,2}$/i) ? featureColor : '#fbca1c'

  const { h, s, l } = useHexToHsl(featureColor)
  const { r, g, b } = useHexToRgb(featureColor)

  useEffect(() => {
    document.body.style.setProperty('--bs-primary', featureColor)
    document.body.style.setProperty('--bs-primary-rgb', `${r}, ${g}, ${b}`)

    const primaryHover = `hsl(${h},${s}%, calc(${l}% + 6%))`
    document.body.style.setProperty('--bs-primary-hover', primaryHover)
  }, [b, featureColor, g, h, l, r, s])
}
