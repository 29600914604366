import { useMyToken } from 'my-tokens/hooks/use-my-token'
import { getMillifiedAmount } from 'sidebar/my-wallet/balance/hooks/get-millified-amount'
import { getMillifiedUsdAmount } from 'sidebar/my-wallet/balance/hooks/get-millified-usd-amount'

export const useTokenBalance = (identifier, symbol) => {
  const { balance, decimals, valueUsd } = useMyToken(identifier)

  return {
    amount: getMillifiedAmount(balance, decimals),
    usdAmount: getMillifiedUsdAmount(valueUsd),
    symbol,
  }
}
