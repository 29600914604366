import * as Yup from 'yup'

import { useTests } from 'components/forms/create-nft-auction-form/hooks/use-tests'
import { settings } from 'settings/fetching'

const maxMaxBid = () => settings().maxBidLimit

export const useMaxBidSchema = (requiredText = '') => Yup.string()
  .required(requiredText)
  .test('comma', 'Use dot for decimals', useTests().testComma)
  .test('number', 'Must be a number', useTests().testNumber)
  .test('number', `Must be lower than ${maxMaxBid()}`, useTests().testMaxMaxBidValue)
  .test('decimals', 'Too many decimals', useTests().testDecimals)
  .test('startDot', 'Must not start with dot', useTests().testStartDot)
  .test('endDot', 'Must not end with dot', useTests().testEndDot)
  .test('minBid', 'Buy now price must be greater than minimum bid', useTests().testMinBidRelation)
  .test('leadingZeros', 'Leading zero is not allowed', useTests().testLeadingZeros)
  .default('')
