import { useNextElement } from 'nft-page/controls/hooks/use-next-element'
import { get } from 'lodash'

export const useNextElementIdentifier = () => {
  const nextElement = useNextElement()

  const assetIdentifier = get(nextElement, 'identifier', undefined)
  const auctionIdentifier = get(nextElement, 'auction.identifier', undefined)

  return assetIdentifier ?? auctionIdentifier
}
