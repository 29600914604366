import { Button } from 'components/button'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useTriggerMouseover } from 'components/unfollow-button/hooks/use-trigger-mouse-over'
import 'components/unfollow-button/style.scss'

const useProps = ({ onClick, setText, icon, setIcon }) => ({
  className: 'unfollow-button',
  icon,
  onClick,
  onMouseOver: () => {
    setIcon('minus')
    setText('Unfollow')
  },
  onMouseLeave: () => {
    setIcon('checked')
    setText('Following')
  },
})

export const UnfollowButton = props => {
  const [text, setText] = useState('Following')
  const [icon, setIcon] = useState('checked')
  const btnRef = useRef(null)

  useTriggerMouseover(btnRef)

  return <Button ref={btnRef} {...useProps({ ...props, setText, icon, setIcon })}>
    {text}
  </Button>
}

UnfollowButton.propTypes = { onClick: PropTypes.func.isRequired }
