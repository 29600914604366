import { useSubdomainCollections } from 'subdomain/home/hooks/use-subdomain-collections'
import { get } from 'lodash'
import { useMemo } from 'react'

export const useSubdomainIdentifiers = () => {
  const { collections } = useSubdomainCollections()
  const subdomainCollections = get(collections, 'collections', [])

  return useMemo(() => subdomainCollections.map(collection => collection.identifier), [subdomainCollections])
}
