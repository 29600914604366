import { get } from 'lodash'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { useTestBalance } from 'components/forms/send-offer-form/hooks/use-test-balance'
import { useTestSupply } from 'components/forms/send-offer-form/hooks/use-test-supply'

const testDecimals = decimals => value => get(value?.split('.'), '[1].length', 0) <= decimals

const testComma = value => !value?.includes(',')

const testStartDot = value => !value?.startsWith('.')

const testEndDot = value => !value?.endsWith('.')

const testLeadingZeros = (value, context) => {
  const leadingZerosCondition = !context.originalValue.startsWith('0') || context.originalValue.startsWith('0.')

  return context.originalValue && leadingZerosCondition
}

const testValue = value => value > 0

export const testNumber = value => value?.match(/^\d*\.?\d*$/)

export const useTests = (asset, paymentToken) => {
  const decimals = useDenominationDecimals()

  return {
    testDecimals: testDecimals(decimals),
    testLeadingZeros,
    testComma,
    testStartDot,
    testEndDot,
    testValue,
    testNumber,
    testBalance: useTestBalance(asset, paymentToken),
    testSupply: useTestSupply(asset, paymentToken),
  }
}
