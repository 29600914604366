import { useOnClick } from 'auction/actions/start/nft/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/auction/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'

export const Nft = ({ asset, address, title }) => {
  const startClick = useOnClick(asset.identifier)
  const onClick = usePermittedAction(startClick, address)

  return <WatchableAction entity={asset} actionType={actionTypes.startAuction} text={title} onClick={onClick} />
}

