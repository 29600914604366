import 'explore-nfts/tabs/collections/list/item.scss'
import { CollectionCard } from 'components/collection-card'
import { forwardRef } from 'react'

export const Item = forwardRef(({ item, running }, ref) => (
  <div ref={ref} className={'col-12 col-sm-6 col-lg-4 card-item'}>
    <CollectionCard collection={item.node} isGhost={item.isGhost} openNewLayer running={running} />
  </div>
))

Item.displayName = 'Item'
