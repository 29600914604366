import * as Yup from 'yup'

import { useTests } from 'components/forms/send-offer-form/hooks/use-tests'

export const useQuantitySchema = (asset, paymentToken) => {
  const tests = useTests(asset, paymentToken)

  return Yup.string()
    .required('Quantity is a required field')
    .test('number', 'Must be higher than 0', tests.testValue)
    .test('number', 'Must be a number', tests.testNumber)
    .test('supply', tests.testSupply)
    .default(1)
}
