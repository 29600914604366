import { includes } from 'lodash'
import { useSubdomainIdentifiers } from 'subdomain/home/collections/hooks/use-subdomain-identifiers'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'

export const useIsCollectionWhitelisted = identifier => {
  const subdomainIdentifiers = useSubdomainIdentifiers()
  const isSubdomain = useIsSubdomain()

  return includes(subdomainIdentifiers, identifier) || !isSubdomain
}
