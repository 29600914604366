import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { campaigns as repository } from 'repositories/campaigns'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { actions } from 'holoride-campaign/action/redeem/slices/claim-ticket'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'
import { throwOnInsufficientFunds } from 'modals/throw-on-insufficient-funds'

export const claimTicket = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(({ payload }) => execute(payload)))

const execute = payload => repository.claimTicket(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.claimTicket })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = result => of(actions.failure(extractGraphqlErrors(result)))
