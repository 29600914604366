import { useEffect } from 'react'
import match from '@nerds.sh/js-pattern'
import { useControlHandlers } from 'nft-page/hooks/use-control-handlers'
import { useAppContext } from 'startup/context'
import { useIsModalOpen } from 'startup/hooks/use-is-modal-open'

export const useControls = () => {
  const handlers = useControlHandlers()
  const { sidebarOpened } = useAppContext()
  const hasModalOpen = useIsModalOpen()

  useEffect(() => {
    const handleOtherwise = () => {}

    const onDocumentKeyDown = event => {
      if (!sidebarOpened && !hasModalOpen) {
        match(event)
          .like({ key: 'ArrowRight' }, handlers.right)
          .like({ key: 'ArrowLeft' }, handlers.left)
          .otherwise(handleOtherwise)
      }
    }

    document.addEventListener('keydown', onDocumentKeyDown)

    return () => document.removeEventListener('keydown', onDocumentKeyDown)
  }, [handlers.right, handlers.left, sidebarOpened, hasModalOpen])
}
