import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { accept } from 'offers/slices/accept'
import { get } from 'lodash'
import { useAcceptOffer } from 'offers/hooks/use-accept-offer'
import { useAcceptOfferSelector } from 'offers/hooks/use-accept-offer-selector'
import { Nft } from 'components/forms/accept-offer-form/nft'

const makeInitialValues = (offer, auctions) => ({ offerId: parseInt(get(offer, 'id', 0)), auctions })

export const NftForm = ({ offer, auctions, supply, balance }) => {
  const acceptOffer = useAcceptOffer()
  const { data, loading, errors } = useAcceptOfferSelector()
  const callbackUrl = useCloseLayerLocation()
  const actionIdentifier = createActionIdentifier(offer, actionTypes.acceptOffer)

  useOnSentPayload({ data, callbackUrl, resetAction: accept.actions.reset, actionIdentifier })

  return <Nft onSubmit={acceptOffer} submitErrors={errors} loading={loading} auctions={auctions}
              callbackUrl={callbackUrl} initialValues={makeInitialValues(offer, auctions)} offer={offer}
              supply={supply} balance={balance} />
}
