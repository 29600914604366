import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useActionableFeed, useFetchFeed, useHasMore } from 'feed/hooks'
import { FeedCard } from 'components/feed-card'
import { ListLoader } from 'feed/list-loader'

const noPreviewEvents = ['photoChanged']

const shouldHideReference = item => {
  const isPhotoChanged = item.action.type === 'photoChanged'
  const isMultipleNfts = item.action.type === 'mintNft' && item.whom.count > 1

  return isPhotoChanged || isMultipleNfts
}

const cardProps = item => ({
  ...item,
  shouldHideReference: shouldHideReference(item),
  previewHidden: noPreviewEvents.includes(item.action.type),
})

export const List = () => <InfiniteScroll next={useFetchFeed()} hasMore={useHasMore()}>
  {useActionableFeed().map((item, index) => <FeedCard key={index} {...cardProps(item)} />)}
  <ListLoader />
</InfiniteScroll>
