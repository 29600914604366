import { gql } from 'graphql-tag'

export const EXPLORE_PAYMENT_TOKENS = gql`
    query explorePaymentTokens($filters: CurrentPaymentTokensFilters) {
        currentPaymentTokens(filters: $filters) {
            activeAuctions
            symbol
            identifier
            decimals
        }
    }
`
