import { of, from } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { nftCollections } from 'repositories/nft-collections'
import { actions } from 'modals/collection/create/issue/slices/nft'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'
import { throwOnInsufficientFunds } from 'modals/throw-on-insufficient-funds'

export const issueNft = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(({ payload }) => execute(payload)))

const execute = payload => nftCollections.issueNftCollection(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.issueNftCollection, factor: 2 })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = result => of(actions.failure(extractGraphqlErrors(result)))

