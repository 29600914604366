import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../slices/economics'
import { settings } from 'settings/fetching'

const economicsInterval = () => settings().economicsInterval

export const useEconomics = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    loadEconomics(dispatch)
    const interval = setInterval(() => {
      loadEconomics(dispatch)

      return () => clearInterval(interval)
    }, economicsInterval())
  }, [dispatch])
}

const loadEconomics = dispatch => dispatch(actions.attempt({}))
