import { useParams } from 'react-router-dom'
import { StartNftAuction as Nft } from 'modals/auction/start/start-nft-auction'
import { StartSftAuction as Sft } from 'modals/auction/start/start-sft-auction'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'

const useIsNftAuction = () => {
  const { modalName } = useParams()

  return modalName === 'create-nft-auction'
}

export const Start = () => {
  const { asset, assetOwner } = useAssetWithOwner()
  const isNftAuction = useIsNftAuction()

  return isNftAuction ? <Nft asset={asset} assetOwner={assetOwner} /> : <Sft asset={asset} assetOwner={assetOwner} />
}

export * from 'modals/auction/start/slices/create-auction'
export * from 'modals/auction/start/epics/create'
