import { useCallback } from 'react'
import { useBalance } from 'common/hooks/use-balance'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const useHasInsufficientFunds = (auction, paymentToken) => {
  const balance = useBalance(paymentToken)
  const amount = get(auction, 'maxBid.amount', '0')
  const decimals = get(auction, 'maxBid.tokenData.decimals', 18)
  const maxBid = denominateAmount(amount, decimals)

  return useCallback((value, context) => {
    const totalValue = !isNaN(context.parent.quantity) ? parseFloat(value) * parseFloat(context.parent.quantity) : value

    return parseFloat(balance) <= (parseFloat(maxBid) && totalValue)
  }, [balance, maxBid])
}
