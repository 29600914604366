import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section/index'
import './index.scss'
import { LeftSide } from 'subdomain/home/roadmap/left-side'
import { Quarter } from 'subdomain/home/roadmap/quarter'
import { RightSide } from 'subdomain/home/roadmap/right-side'

export const Roadmap = ({ data }) => {
  const steps = get(data, 'steps', [])
  const title = get(data, 'title', '')

  return <HomeSection title={title}>
    <div className={'roadmap-steps'}>
      {steps.map((step, index) => <div className={'roadmap-step'} key={index}>
        <div className={'row align-items-center'}>
          <LeftSide description={index % 2 === 0 ? '' : step.description} />
          <Quarter quarter={step.quarter} />
          <RightSide description={index % 2 === 0 ? step.description : ''} />
        </div>
      </div>)}
    </div>
  </HomeSection>
}
