import { matchPath, useLocation } from 'react-router-dom'

import { routes } from 'startup/routes'

export const useParamsGlobal = () => {
  const { pathname } = useLocation()
  const route = routes.find(route => matchPath({ path: route.path }, pathname))

  const match = matchPath({ path: route?.path }, pathname)

  return match?.params
}
