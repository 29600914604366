import { DataCard } from 'components/data-card'
import { TagsFilter } from 'trending/tags-filter'
import { List } from 'trending/list'
import { useIsLoading } from 'trending/hooks/use-is-loading'
import { Empty } from 'trending/empty'
import { ContractWrapper } from 'components/contract-wrapper'
import { useMarketplaceContractIsPaused } from 'marketplaces/hooks/use-marketplace-contract-is-paused'
import { Behaviour } from 'trending/behaviour'

const makeProps = (isLoading, isContractPaused) => ({
  title: 'Trending',
  filterComponent: !isContractPaused && <TagsFilter disabled={isLoading} />,
})

export const Content = () => {
  const isLoading = useIsLoading()
  const isContractPaused = useMarketplaceContractIsPaused()

  return <div className={'hottest-page'}>
    <DataCard {...makeProps(isLoading, isContractPaused)}>
      <ContractWrapper fullscreen>
        <Behaviour />
        <div className={'mt-2 trending-section'}>
          <List />
        </div>
        <Empty />
      </ContractWrapper>
    </DataCard>
  </div>
}
