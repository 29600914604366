import { useSelector } from 'react-redux'
import { get } from 'lodash'

const path = 'myTokens.get.data'

export const useMyToken = tokenIdentifier => {
  const myTokens = useSelector(state => get(state, path, []))
  const token = myTokens.find(item => item.identifier === tokenIdentifier)
  const balance = get(token, 'balance', '0')
  const decimals = get(token, 'decimals')
  const valueUsd = get(token, 'valueUsd')

  return { balance, decimals, valueUsd }
}

