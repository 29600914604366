import { useAcceptOffer } from 'offers/hooks/use-accept-offer'
import { useAcceptOfferSelector } from 'offers/hooks/use-accept-offer-selector'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { accept } from 'offers/slices'
import { get } from 'lodash'
import { Sft } from 'components/forms/accept-offer-form/sft'

const makeInitialValues = offer => ({ offerId: parseInt(get(offer, 'id', 0)) })

export const SftForm = ({ offer, auctions, supply, balance, isNft }) => {
  const acceptOffer = useAcceptOffer()
  const { data, loading, errors } = useAcceptOfferSelector()
  const callbackUrl = useCloseLayerLocation()
  const actionIdentifier = createActionIdentifier(offer, actionTypes.acceptOffer)

  useOnSentPayload({ data, callbackUrl, resetAction: accept.actions.reset, actionIdentifier })

  return <Sft onSubmit={acceptOffer} submitErrors={errors} loading={loading} auctions={auctions}
              callbackUrl={callbackUrl} initialValues={makeInitialValues(offer)} offer={offer}
              supply={supply} balance={balance} />
}
