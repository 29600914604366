import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'trending/slices/cursor'
import { useEndCursor } from 'trending/hooks/use-end-cursor'

export const useNext = () => {
  const dispatch = useDispatch()
  const endCursor = useEndCursor()

  return useCallback(() => {
    dispatch(actions.set(endCursor))
  }, [dispatch, endCursor])
}
