import { Infos } from 'components/feed-card/infos'
import { SmallPreview } from 'components/feed-card/small-preview'
import { BigPreview } from 'components/feed-card/big-preview'
import { useIsBigLayout } from 'components/feed-card/hooks/use-is-big-layout'
import { Link } from 'components/feed-card/link'
import { usePreviewType } from 'components/feed-card/hooks/use-preview-type'
import 'components/feed-card/index.scss'

export const Content = props => {
  const big = useIsBigLayout(props.action.type, props.whom.address)
  const previewType = usePreviewType(props)

  return <div className={'feed-card'}>
    <Link {...props} />
    <div className={'feed-card-top d-flex align-items-start align-items-md-center justify-content-between'}>
      <Infos {...props} />
      {previewType === 'small' && <SmallPreview {...props} />}
    </div>
    {previewType === 'big' && <BigPreview {...props} big={big} />}
  </div>
}
