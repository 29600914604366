import { Asset } from 'common/asset'
import { useCurrentAsset } from 'nft-page/media/hooks/use-current-asset'
import { useMemo } from 'react'

export const usePlayerSource = () => {
  const asset = useCurrentAsset()

  const src = Asset.useFileUrl(asset)
  const assetType = Asset.useFileType(asset) || 'video/mp4'
  const type = assetType === 'video/quicktime' ? 'video/mp4' : assetType

  return useMemo(() => ({ src, type }), [src, type])
}
