import { useCampaign } from '../hooks/use-campaign'
import { useIsLoading } from '../hooks/use-is-loading'
import { LoadingSpinner } from '../../components/loading-spinner'

const isLoading = (loading, campaign) => loading && !campaign

export const LoadingWrapper = ({ children }) => {
  const campaign = useCampaign()
  const loading = useIsLoading()

  return isLoading(loading, campaign) ? <LoadingSpinner text={'Loading...'} /> : campaign && children
}
