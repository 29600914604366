import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: undefined,
  input: undefined,
  errors: undefined,
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'explore/stats/get',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = get
