import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: '',
  input: '',
  errors: [],
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true, errors: [] })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false, errors: [] })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload), loading: false })

const reset = () => initialState

export const view = createSlice({
  initialState,
  name: 'tracking/view/post',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = view
