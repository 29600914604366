import { useIsLiked } from 'nft-page/likes/hooks'
import { useContext } from 'nft-page/mutate-like/context'
import { useEffect } from 'react'

export const useMatchReduxStatus = () => {
  const isLiked = useIsLiked()
  const { setIsLiked } = useContext()

  useEffect(() => {
    setIsLiked(isLiked)
  }, [setIsLiked, isLiked])
}
