import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { marketplaces as repository } from 'repositories/marketplaces/index'
import { actions } from 'marketplaces/slices/get'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { history } from 'network'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.getMarketplaces(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = error => from([
  actions.failure(error),
  failureSlice.actions.show({ route: history.location.pathname }),
])
