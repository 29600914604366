import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useNextElement } from 'nft-page/controls/hooks/use-next-element'
import { useLayeredPath } from 'nft-page/controls/hooks/use-layered-path'

export const useOnRightClick = () => {
  const navigate = useNavigate()
  const nextElement = useNextElement()
  const layerPath = useLayeredPath()

  return useCallback(() => {
    const { pathname, state } = layerPath(nextElement)

    if (nextElement) {
      navigate(pathname, { state, replace: true })
    }
  }, [layerPath, nextElement, navigate])
}
