import { useCloseModal } from 'components/bootstrap-modal/hooks/use-close-modal'
import { useSetPreference } from 'preferences/hooks/use-set-preference'
import { useCallback } from 'react'

export const useOnClick = (id, callback = () => {}) => {
  const closeModal = useCloseModal(id)
  const setPreferences = useSetPreference()

  return useCallback(() => {
    closeModal()
    callback()
    setPreferences({ [id]: true })
  }, [closeModal, callback, setPreferences, id])
}
