import { useCallback } from 'react'
import { useResetList } from 'modals/account/create-nft/collections/hooks/use-reset-list'
import { useIssueServer } from 'modals/account/create-nft/collections/hooks/use-fetch-account-collections'

export const useOnChange = () => {
  const resetList = useResetList()
  const issueServer = useIssueServer()

  return useCallback(() => {
    resetList()
    issueServer()
  }, [issueServer, resetList])
}
