import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { get } from 'feed/slices'
import { useCursor } from 'feed/hooks/use-cursor'

export const useFetchFeed = () => {
  const dispatch = useDispatch()
  const cursor = useCursor()

  return useCallback(() => {
    dispatch(get.actions.attempt({ cursor }))
  }, [dispatch, cursor])
}
