import { UserImage } from 'components/user-image'
import { useOnClick } from './hooks/use-on-click'

import './account-link.scss'
import { useCurrentProfilePhoto } from 'current-profile/hooks/use-current-profile-photo'

export const AccountLink = () => <button className={'account-link '}
                                         onClick={useOnClick()}>
  <UserImage image={useCurrentProfilePhoto()} />
</button>
