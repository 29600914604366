import { ReactComponent as Badge } from 'startup/assets/verified-badge.svg'
import 'components/verified-badge/index.scss'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const VerifiedBadge = ({ typename = '' }) => {
  const ref = useRef(null)
  useTooltip(ref)

  return <span className={'verified-badge'} data-bs-toggle={'tooltip'} ref={ref} data-bs-placement={'top'}
               title={`Verified ${typename}`}>
    <Badge />
  </span>
}
