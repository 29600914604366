import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const useUrlValue = () => {
  const { search } = useLocation()
  const parsed = new URLSearchParams(search)

  return parsed.get('trending')
}

export const useTag = () => {
  const urlValue = useUrlValue()
  const selectorValue = useSelector(({ trending }) => trending.tag.data)

  return urlValue || selectorValue
}
