import { from } from 'rxjs'
import { Loading } from 'loading'
import { loading } from 'loading/slices'
import { ofType } from 'redux-observable'
import { actions } from 'explore-nfts/tabs/nfts/auctions/slices/get'
import { catchError, mergeMap } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { auctions as repository } from 'repositories/auctions'

export const exploreAuctions = action$ => action$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.getExploreAuctions(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  actions.append(payload.data.auctions.edges),
  loading.actions.hide({ id: Loading.ID.explore.page, variant: Loading.VARIANT.ghostCard }),
])

const failure = payload => from([
  actions.failure(payload),
  loading.actions.hide({ id: Loading.ID.explore.page, variant: Loading.VARIANT.ghostCard }),
  failureSlice.actions.show({ route: '/nfts' }),
])
