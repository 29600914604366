import { useOnClick } from 'auction/actions/start/sft/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { actionTypes } from 'action-statuses/actions-types'
import { WatchableAction } from 'nft-page/auction/actions/watchable-action'

export const Sft = ({ asset, address, title }) => {
  const startClick = useOnClick(asset.identifier)
  const onClick = usePermittedAction(startClick, address)

  return <WatchableAction entity={asset} actionType={actionTypes.startSftAuction} text={title} onClick={onClick} />
}

