import { useCollectionPaymentTokens } from 'collections/payment-tokens/hooks/use-collection-payment-tokens'
import { useCollectionStats } from 'collections/stats/hooks/use-collection-stats'
import { useIsLoading } from 'collections/stats/hooks/use-is-loading'
import { CollectionStats } from 'components/collection-stats'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { get } from 'lodash'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const Stats = () => {
  const collectionStats = useCollectionStats()
  const token = useFilterValue('token')
  const paymentTokens = useCollectionPaymentTokens()
  const paymentToken = paymentTokens.find(item => item.identifier === token)
  const acceptedPaymentToken = useFirstAcceptedPaymentToken()
  const acceptedTokenSymbol = get(acceptedPaymentToken, 'symbol', egldLabel())
  const acceptedTokenDecimals = get(acceptedPaymentToken, 'decimals', 18)
  const symbol = get(paymentToken, 'symbol', acceptedTokenSymbol)
  const decimals = get(paymentToken, 'decimals', acceptedTokenDecimals)
  const isLoading = useIsLoading()

  return collectionStats && <CollectionStats collectionStats={collectionStats} symbol={symbol} isLoading={isLoading}
                                             decimals={decimals} />
}
