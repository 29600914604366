import { DisplayPrice } from 'nft-page/auction/actions/display-price'
import { BuyOrBid } from 'nft-page/auction/actions/buy-or-bid'
import { ClaimAuction } from 'nft-page/auction/actions/claim'
import { PlaceBid } from 'nft-page/auction/actions/place-bid'
import { EndAuction } from 'nft-page/auction/actions/end-auction'
import { BuySft } from 'nft-page/auction/actions/buy-sft'
import { Withdraw } from 'nft-page/auction/actions/withdraw'
import { Sell } from 'nft-page/auction/actions/sell'
import { SftWithdraw } from 'nft-page/auction/actions/sft-withdraw'
import { TopBidder } from 'nft-page/auction/actions/top-bidder'
import { BuyNft } from 'nft-page/auction/actions/buy-nft'
import { ViewOnOtherMarket } from 'nft-page/auction/actions/view-on-other-market'

export const statuses = () => ({
  notForSale: () => '',
  endedAuction: () => '',
  topBidder: TopBidder,
  displayPrice: DisplayPrice,
  claim: ClaimAuction,
  sell: Sell,
  withdraw: Withdraw,
  sftWithdraw: SftWithdraw,
  end: EndAuction,
  buySft: BuySft,
  buyOrBid: BuyOrBid,
  bid: PlaceBid,
  buyNft: BuyNft,
  viewOnOtherMarket: ViewOnOtherMarket,
})
