import { HomeSection } from 'subdomain/components/home-section'
import { get } from 'lodash'
import ReactMarkdown from 'react-markdown'
import 'subdomain/home/column/column.scss'

export const Column = ({ data, key }) => {
  const title = get(data, 'title', '')
  const content = get(data, 'content', '')
  const image = get(data, 'image.data.attributes.formats.medium.url', '')

  return <HomeSection title={title} className={'column-section'}>
    <div className={'column-section-row row align-items-center'}>
      <div className={`column-section-image col-12 col-md-6 ${key % 2 ? 'order-md-1' : 'order-md-0'} mb-3 mb-md-0`}>
        <img src={image} />
      </div>
      <div className={'column-section-content col-12 col-md-6 mt-2 mt-md-0'}>
        <ReactMarkdown>
          {content}
        </ReactMarkdown>
      </div>
    </div>
  </HomeSection>
}
