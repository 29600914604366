import { Body } from 'components/toasts/transaction-toast/body'
import { Header } from 'components/toasts/transaction-toast/header'
import { Progress } from 'components/toasts/transaction-toast/progress'
import { Behaviour } from './behavior'
import { statuses } from 'components/toasts/transaction-toast/statuses'
import { get } from 'lodash'

const makeProps = id => ({
  id,
  className: 'toast fade',
  role: 'alert',
  'aria-live': 'assertive',
  'aria-atomic': true,
})

const getActualStatus = transaction => get(transaction, 'transactions[0].status', '')

export const TransactionToast = ({ id, transaction, shown, transactionsInfo }) => {
  const status = getActualStatus(transaction)
  const statusData = statuses(status, transactionsInfo)

  return <div {...makeProps(id)}>
    <Behaviour id={id} status={status} />
    <Header {...statusData} />
    <Body transaction={transaction} />
    <Progress {...statusData} shown={shown} />
  </div>
}
