import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'

const initialState = { seen: [], shown: [] }

const seen = (state, { payload }) => ({ ...state, seen: uniq([...state.seen, payload]) })
const shown = (state, { payload }) => ({ ...state, shown: uniq([...state.shown, payload]) })

const reset = () => initialState

export const transactions = createSlice({
  initialState,
  name: 'notifications/transactions',
  reducers: {
    seen,
    shown,
    reset,
  },
})

export const { actions } = transactions
