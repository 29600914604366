import 'components/price/index.scss'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'
import { getPriceDetails } from 'common/get-price-details'

export const Price = ({ price }) => {
  const { token, amount, millyfiedAmount, usdAmount } = getPriceDetails(price)
  const priceRef = useRef(null)
  const tooltipTitle = `${amount} ${token}`
  useTooltip(priceRef)

  return <div className={'price flex-column align-items-end'}>
    <span className={'price-amount'} data-bs-toggle={'tooltip'} ref={priceRef} data-bs-placement={'top'}
          title={tooltipTitle}>
      {`${millyfiedAmount}`}
      {' '}
      <span className={'price-token'}>{token}</span>
    </span>
    {usdAmount && <span className={'price-usd'}>{`~$ ${usdAmount}`}</span>}
  </div>
}
