import { gql } from 'graphql-tag'

export const IS_WHITELISTED = gql`
    query getIsWhitelisted{
        isWhitelisted {
            isWhitelisted
            message
        }
    }
`
