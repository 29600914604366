import { useEffect } from 'react'
import { useContext } from 'components/player/context'

export const usePlayback = () => {
  const { sources, player } = useContext()

  useEffect(() => {
    if (!player.current) return

    player.current.src(sources)
  }, [player, sources])
}
