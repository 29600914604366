import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { usePathname } from 'explore-nfts/tabs/hooks/use-pathname'

export const useIsActive = () => {
  const tab = usePathname()

  return useCallback((index, currentTab) => (
    tab === currentTab || (index === 0 && isEmpty(tab))), [tab])
}

