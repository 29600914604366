import { ConnectButton } from 'sidebar/connect/connect-button'
import { usePickCallback } from 'sidebar/connect/extension-login/hooks/use-pick-callback'

export const ExtensionLogin = () => {
  const callback = usePickCallback()

  return <ConnectButton LeftIcon={ConnectButton.Icon.Exchange} onClick={callback}>
    MultiversX DeFi Wallet
  </ConnectButton>
}
