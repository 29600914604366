import { useTag } from 'trending/hooks/use-tag'
import { useCursor } from 'trending/hooks/use-cursor'
import { useMemo } from 'react'
import { settings } from 'settings/fetching'
import moment from 'moment'

export const useFetchingPayload = () => {
  const tag = useTag()
  const cursor = useCursor()

  return useMemo(() => ({
    pagination: { first: settings().listRequestSize, after: cursor },
    grouping: { groupBy: 'IDENTIFIER' },
    filters: {
      operator: 'AND',
      filters: [
        { field: 'tags', values: [tag], op: 'LIKE' },
        { field: 'status', values: ['Running'], op: 'EQ' },
        { field: 'startDate', values: [`${moment().unix()}`], op: 'LE' },
      ],
    },
  }), [tag, cursor])
}
