import { useSetTag } from 'trending/hooks/use-set-tag'
import { useCallback } from 'react'
import { useSetLocationFilters } from 'filters/hooks/use-set-location-filters'

export const useOnOptionClick = () => {
  const setTag = useSetTag()
  const setLocationFilters = useSetLocationFilters()

  return useCallback(option => {
    setLocationFilters({ name: 'trending', value: option })
    setTag(option)
  }, [setLocationFilters, setTag])
}
