import { useWatchAction } from 'action-statuses/hooks/use-watch-action'
import { useCampaign } from './use-campaign'
import { useOnChange } from './use-on-change'

export const useWatchTransaction = () => {
  const campaign = useCampaign()
  const onChange = useOnChange()

  useWatchAction({ prefix: `${campaign.minterAddress}_${campaign.campaignId}_Campaign`, onChange })
}
