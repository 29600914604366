import { gql } from 'graphql-tag'

export const MARKETPLACES = gql`
    query marketplaces($pagination: ConnectionArgs, $filters: MarketplaceFilters) {
        marketplaces(pagination: $pagination, filters: $filters) {
            edges{
                node{
                    acceptedPaymentTokens{
                        decimals
                        identifier
                        name
                        priceUsd
                        symbol
                    }
                    acceptedCollectionIdentifiers
                    address
                    isPaused
                    key
                    marketplaceCutPercentage
                    name
                    type
                    url
                }
            }
        }
    }
`
