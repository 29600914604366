import { Behaviour } from './behaviour'
import './index.scss'
import { DurationLabel } from 'components/duration-label/index'
import { useContext } from 'holoride-campaign/action/redeem/context'

export const Countdown = ({ endDate }) => {
  const { timeLeft } = useContext()

  return <div className={'holoride-campaign-countdown'}>
    <Behaviour date={endDate} />

    <div className={'d-flex align-items-center'}>
      <DurationLabel duration={timeLeft} />
    </div>
  </div>
}
