import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: '',
  input: '',
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload) })

const setCursor = (state, { payload }) => ({ ...state, cursor: payload })

const resetCursor = state => ({ ...state, cursor: undefined })

const cleanUp = () => initialState

export const get = createSlice({
  initialState,
  name: 'account/liked/get',
  reducers: {
    attempt,
    success,
    failure,
    setCursor,
    resetCursor,
    cleanUp,
  },
})

export const { actions } = get
