import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { follow } from 'account/profile/follow/slices/follow'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'

export const useFollow = address => {
  const dispatch = useDispatch()
  const { address: loggedInAddress } = useGetAccountInfo()

  return useCallback(() => {
    dispatch(follow.actions.attempt({ address, loggedInAddress }))
  }, [dispatch, address, loggedInAddress])
}
