import { useCampaign } from './hooks/use-campaign'

export const Media = () => {
  const { video } = useCampaign()

  return <div className={'col-12 col-md-6 mb-4 mb-md-0'}>
    <div className={'holoride-campaign-media'}>
      <video autoPlay loop muted playsInline>
        <source src={video} type={'video/mp4'} />
      </video>
    </div>
  </div>
}
