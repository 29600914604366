import { useEffect } from 'react'
import { useContext } from 'components/collection-card/context'

export const useUpdateOutsideRunning = outsideRunning => {
  const { setRunning } = useContext()

  useEffect(() => {
    setRunning(outsideRunning)
  }, [outsideRunning, setRunning])
}
