import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions as transactionActions } from 'transaction/slices'
import { actions as lookupActions } from 'collections/slices/look-up-transaction'

export const useResetTransactionOnMount = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(transactionActions.reset({}))
    dispatch(lookupActions.reset({}))
  }, [dispatch])
}
