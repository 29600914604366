import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { privacy as slice } from 'viewer/slices/privacy'
import { repository } from 'viewer/repository'

export const privacy = actions$ => actions$
  .pipe(ofType(slice.actions.attempt.type))
  .pipe(mergeMap(execute))

const execute = payload => repository(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  slice.actions.success(payload),
])

const failure = payload => from([
  slice.actions.failure(payload),
])
