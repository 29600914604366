import { filter, get } from 'lodash'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useSeoProps = (tab = '') => {
  const data = useCmsData()
  const content = get(data, 'data.data.attributes.content', [])
  const [hero] = filter(content, { __component: 'sections.hero' })

  const title = get(hero, 'title', '')
  const image = get(hero, 'background.data.attributes.url', '')

  return {
    title: undefined,
    image,
    description: title,
  }
}
