import { combineEpics } from 'redux-observable'
import { updateCover } from 'modals/account/cover/epics/update-cover'
import { updateProfilePhoto } from 'modals/account/photo/epics/update-profile-photo'
import { epics as edit } from 'modals/account/edit'
import { epic as followers } from 'modals/account/followers'
import { epic as followees } from 'modals/account/followees'
import { createNft } from 'modals/account/create-nft/epics/create-nft'
import { collections } from 'modals/account/create-nft/collections/epics/collections'

export const epics = combineEpics(updateCover, updateProfilePhoto, edit, followers, followees, collections, createNft)
