import { calculateFeeLimit } from '@multiversx/sdk-dapp/utils'
import { settings } from 'settings/fetching'

const gasPrice = () => settings().dapp.gasPrice

const gasPriceModifier = () => settings().dapp.gasPriceModifier

const gasPerDataByte = () => settings().dapp.gasPerDataByte

export const calculateFee = data => calculateFeeLimit({
  gasLimit: `${data.gasLimit}`,
  gasPrice: `${data.gasPrice}`,
  data: window.atob(data.data),
  gasPerDataByte: gasPerDataByte(),
  defaultGasPrice: `${gasPrice()}`,
  gasPriceModifier: gasPriceModifier(),
  chainId: data.chainID,
})
