import { Button } from 'components/button'
import PropTypes from 'prop-types'
import { humanize } from 'components/conversion/humanize/index'
import 'components/like-button/style.scss'

const label = count => count === 1 ? 'like' : 'likes'

const useProps = ({ onClick }) => ({
  className: 'like-button',
  icon: 'heart',
  onClick,
  buttonType: 'secondary',
})

export const LikeButton = props => <Button {...useProps(props)}>
  {humanize(props.count?.toString())}
  {' '}
  <span className={'d-none d-sm-inline'}>{label(props.count)}</span>
</Button>

LikeButton.propTypes = { onClick: PropTypes.func.isRequired }
