import { useOnClick } from 'components/asset-tag/hooks/use-on-click'
import 'components/asset-tag/index.scss'
import { useIsSubdomainDisabledClass } from 'common/hooks/use-is-subdomain-disabled-class'

export const AssetTag = ({ tag }) => {
  const onClick = useOnClick()
  const disabledClass = useIsSubdomainDisabledClass()

  return <a className={`asset-tag ${disabledClass}`} role={'button'} onClick={() => onClick(tag)}>
    {`#${tag}`}
  </a>
}
