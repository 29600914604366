import { useOwnerBalance } from 'nft-page/owner/hooks/use-owner-balance'
import { useList } from 'nft-page/details/other-listings/hooks/use-list'

export const useCanCreateMore = asset => {
  const balance = useOwnerBalance()
  const isSft = asset.type === 'SemiFungibleESDT'
  const hasBalance = parseInt(balance) !== 0
  const auctions = useList()
  const hasAuctions = auctions.length !== 0

  return isSft && hasBalance && hasAuctions
}
