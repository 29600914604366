import { List } from 'explore-nfts/tabs/collections/list'
import { useNext } from 'explore-nfts/tabs/collections/hooks/use-next'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useExploreCollectionsList } from 'explore-nfts/tabs/collections/hooks/use-explore-collections-list'
import { DataCard } from 'components/data-card'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Empty } from 'explore-nfts/tabs/collections/empty'
import { useIsLoading } from 'explore-nfts/tabs/collections/hooks/use-is-loading'
import { useHasMore } from 'explore-nfts/tabs/collections/hooks/use-has-more'
import { Behaviour } from 'explore-nfts/tabs/collections/behaviour'
import { TabContent } from 'components/tabs/tab-content'
import { usePathname } from 'explore-nfts/tabs/hooks/use-pathname'
import 'explore-nfts/tabs/collections/index.scss'
import { Filters } from 'explore-nfts/tabs/collections/filters'

const cardProps = () => ({ title: '' })

export const Collections = () => {
  const collections = useExploreCollectionsList()
  const isLoading = useIsLoading()
  const tab = usePathname()

  return <TabContent id={'explore-collections-tab'} active={tab === 'collections'}>
    <Behaviour />
    <ScrollToTopOnMount />
    <DataCard {...cardProps()}>
      <Filters />
      <InfiniteScroll next={useNext()} hasMore={useHasMore()} isLoading={isLoading}>
        <List collections={collections} />
      </InfiniteScroll>
      <Empty />
    </DataCard>
  </TabContent>
}
