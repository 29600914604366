import { combineReducers } from 'redux'
import { updateDescription } from 'modals/account/edit/description/slices/update-description'
import { updateSocialLinks } from 'modals/account/edit/social-links/slices/update-social-links'
import { updatePrivacy } from 'modals/account/edit/privacy/slices/update-privacy'

export const reducers = combineReducers({
  updateDescription: updateDescription.reducer,
  updateSocialLinks: updateSocialLinks.reducer,
  updatePrivacy: updatePrivacy.reducer,
})
