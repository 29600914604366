import { BootstrapModal } from 'components/bootstrap-modal'
import { Cancel } from 'components/simple-modal/cancel'
import { useOnClick } from 'components/simple-modal/hooks/use-on-click'
import 'components/simple-modal/index.scss'

export const SimpleModal = ({ id, children, buttonText, callback, showCancel, easyClose = true, className }) => {
  const onClick = useOnClick(id, callback)

  return <BootstrapModal id={id} className={`simple-modal ${className}`} routeModal={false} easyClose>
    <div className={' modal-content text-center'}>
      {children}
      <div className={'d-flex flex-column flex-sm-row mt-5  '}>
        {showCancel && <Cancel id={id} />}
        <button className={'btn btn-primary w-100'} onClick={onClick}>{buttonText}</button>
      </div>
    </div>
  </BootstrapModal>
}
