import 'campaign/image/index.scss'
import StickyBox from 'react-sticky-box'

export const Image = ({ campaign }) => <StickyBox offsetTop={153}>
  <div className={'campaign-image'}>
    <div className={'campaign-image-inner'}>
      <img className={'campaign-image-img'}
           src={campaign.coverImage} />
      <img className={'campaign-image-blur'}
           src={campaign.coverImage} />
    </div>
  </div>
</StickyBox>
