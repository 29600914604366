import { useCallback } from 'react'
import { testNumber } from 'components/forms/place-bid-form/hooks/use-tests'
import { useBalance } from 'common/hooks/use-balance'

export const useHasInsufficientFunds = paymentToken => {
  const balance = useBalance(paymentToken)

  return useCallback(value => {
    if (!testNumber(value)) return false

    return parseFloat(balance) <= value
  }, [balance])
}
