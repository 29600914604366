import { useCallback } from 'react'
import { useLastCursor } from 'explore-nfts/tabs/nfts/auctions/hooks/use-last-cursor'
import { useFiltersHash } from 'explore-nfts/tabs/nfts/auctions/hooks/use-filters-hash'
import { buildFilters } from 'filters/explore-filters/nfts/auctions/filters-logic'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { usePriceRange } from 'explore-nfts/tabs/nfts/auctions/hooks/use-price-range'
import { useTokenDecimals } from 'explore-nfts/tabs/nfts/auctions/hooks/use-token-decimals'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const cursor = useCursor(customCursor)
  const filtersHash = useFiltersHash()
  const filter = useFilterValue('filter')
  const token = useFilterValue('token')
  const priceRange = usePriceRange()
  const priceRangeHash = JSON.stringify(priceRange)
  const decimals = useTokenDecimals()

  return useCallback(() => {
    const parsed = JSON.parse(filtersHash)
    const parsedPriceRange = JSON.parse(priceRangeHash)
    const defPriceRange = {
      min: { amount: parsedPriceRange.minBid?.amount, decimals },
      max: { amount: parsedPriceRange.maxBid?.amount, decimals },
    }

    return buildFilters({ cursor, ...parsed, filter, token }, defPriceRange)
  }, [cursor, decimals, filter, filtersHash, priceRangeHash, token])
}
