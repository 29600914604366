import { useBuySft } from 'modals/auction/buy-sft/hooks/use-buy-sft'
import { useBuySftSelector } from 'modals/auction/buy-sft/hooks/use-buy-sft-selector'
import { actions } from 'modals/auction/buy-sft/slices/buy-sft'
import { get } from 'lodash'
import { BuySftForm } from 'components/forms/buy-sft-form'
import { useDependenciesLoading } from 'modals/auction/hooks/use-dependecies-loading'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { denominateAmount } from 'common/conversion/denominate-amount'

const makeInitialValues = auction => {
  const amount = get(auction, 'maxBid.amount', '0')
  const tokenDecimals = get(auction, 'maxBid.tokenData.decimals', 18)

  return ({
    price: denominateAmount(amount, tokenDecimals) * 1,
    identifier: get(auction, 'identifier', ''),
    auctionId: parseInt(get(get, 'id', '0')),
    quantity: 1,
  })
}

export const Form = ({ auction }) => {
  const paymentToken = useFirstAcceptedPaymentToken()
  const buySft = useBuySft(auction, paymentToken)
  const { loading, errors, data } = useBuySftSelector()
  const callbackUrl = useCloseLayerLocation()
  const dependenciesLoading = useDependenciesLoading()
  const actionIdentifier = createActionIdentifier(auction, actionTypes.buyNowSft)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <BuySftForm onSubmit={buySft} submitErrors={errors} loading={loading || dependenciesLoading}
                     callbackUrl={callbackUrl} initialValues={makeInitialValues(auction)}
                     auction={auction} key={auction.id} paymentToken={paymentToken} />
}
