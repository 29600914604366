import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FlexRow } from '../../../flex-row'
import { useProps } from './hooks/use-props'
import './title.scss'

export const Title = ({ title, icon, align = 'center', margins = 'mb-5' }) => <FlexRow {...useProps(align, margins)}>
  {icon && <FontAwesomeIcon className={'me-3'} icon={icon} />}
  <span className={'nerds-form-title'}>
    {title}
  </span>
</FlexRow>
