import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { clearReportCollection } from 'admin/slices'

export const useClearReportCollection = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    if (window.confirm('Are you sure?') === true) {
      dispatch(clearReportCollection.actions.attempt({ input: { collectionIdentifier: identifier } }))
    }
  }, [dispatch, identifier])
}
