import { accountPath } from 'common/account-path'
import { LayeredLink } from 'components/layered-router/layered-link'
import { Content } from 'components/user-image/content'
import 'components/user-image/index.scss'
import { useIsSubdomainDisabledClass } from 'common/hooks/use-is-subdomain-disabled-class'

const nftClass = isNft => isNft ? 'is-nft' : ''

const checkHasLink = (path, isPrivate) => path && !isPrivate

export const UserImage = ({ image, size, herotag, address, openNewLayer, isNft, isPrivate = false }) => {
  const path = accountPath(address, herotag)

  const content = <Content image={image} isNft={isNft} isPrivate={isPrivate} />
  const disabledClass = useIsSubdomainDisabledClass()
  const hasLink = checkHasLink(path, isPrivate)

  return <div className={`user-image ${disabledClass} size-${size} ${nftClass(isNft)}`}>
    {hasLink ? <LayeredLink path={path} layer={openNewLayer ? 'middleground' : ''}>{content}</LayeredLink> : content}
  </div>
}
