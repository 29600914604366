import DateTime from 'react-datetime'
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons'
import { Icon } from 'components/forms/nerds-form/fields/icon'
import { useField } from 'formik'
import { Errors } from 'components/forms/nerds-form/fields/date-time-field/errors'
import { Input } from 'components/forms/nerds-form/fields/date-time-field/input'
import 'react-datetime/css/react-datetime.css'
import 'components/forms/nerds-form/fields/date-time-field/date-time-field.scss'
import moment from 'moment'
import { Label } from 'components/forms/nerds-form/fields/label'

const disablePastDates = disablePast => {
  const yesterday = moment().subtract(1, 'day')

  return disablePast ? current => current.isAfter(yesterday) : () => true
}

export const DateTimeField = ({ label, name, disablePast = false }) => {
  const [field, meta, { setValue }] = useField(name)

  const onDateChange = value => {
    const now = moment()
    const checkedValue = disablePast && value.isSameOrBefore(now) ? now.add(1, 'minute') : value

    setValue(checkedValue)
  }

  return <div className={'nerds-form-field date-time-field'}>
    <Label className={'form-label'} name={name}>{label}</Label>
    <div className={'position-relative'}>
      <Icon icon={faCalendarAlt} />
      <DateTime value={field.value} initialValue={new Date()} isValidDate={disablePastDates(disablePast)}
                onChange={onDateChange} dateFormat={'MMMM D, YYYY,'}
                renderInput={(props, open) => <Input props={props} openCalendar={open} name={name} />} />
    </div>
    <Errors meta={meta} />
  </div>
}
