import { useParams } from 'react-router-dom'
import { some, method, split } from 'lodash'
import { useSubdomainIdentifiers } from 'subdomain/home/collections/hooks/use-subdomain-identifiers'

export const useIsNftWhitelisted = () => {
  const subdomainIdentifiers = useSubdomainIdentifiers()
  const { identifier } = useParams()
  const collection = identifier && split(identifier, '-', 2).join('-')

  return collection ? some(subdomainIdentifiers, method('match', collection)) : false
}
