import { useLoadMore } from 'asset-history/hooks/use-load-more'
import 'nft-page/details/history/button.scss'

export const Button = () => {
  const loadMore = useLoadMore()

  return <div className={'d-flex justify-content-center mt-3'}>
    <a className={'history-load-more-button'} onClick={loadMore}>Load more</a>
  </div>
}
