import { Create } from 'modals/collection/asset/create'
import { Behaviour } from 'modals/collection/asset/behaviour'
import { BootstrapModal } from 'components/bootstrap-modal'
import { useCollection } from 'modals/collection/asset/hooks/use-collection'

export const CreateNft = () => {
  const collection = useCollection()

  return <BootstrapModal id={'createNft'} modalSize={'modal-fullscreen'}>
    <Behaviour />
    <Create collection={collection} />
  </BootstrapModal>
}
