import { Empty as EmptyState } from 'components/empty'
import { faCubes } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'collections/actions/link'
import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'
import { useActualList } from 'collections/assets/hooks/use-actual-list'

const makeProps = collection => ({
  icon: faCubes,
  message: 'No tokens found.',
  Action: <Link collection={collection} />,
  fullscreen: true,
})

export const Empty = () => {
  const assetCount = useActualList().length
  const [collection] = useCollectionsSelector()

  return !assetCount && <EmptyState {...makeProps(collection)} />
}
