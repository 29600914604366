import { isMatch } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'

const show = (state, action) => ([...state, ...action.payload])

const hide = (state, action) => state.filter(item => !isMatch(item, action.payload))

export const loading = createSlice({
  name: 'loading',
  initialState: [],
  reducers: {
    show,
    hide,
  },
})
