import { Form } from 'modals/campaign/buy/form'
import { BootstrapModal } from 'components/bootstrap-modal'
import { useCampaign } from 'campaign/hooks/use-campaign'
import { useParams } from 'react-router-dom'
import { find } from 'lodash'
import { useIsLoading } from 'campaign/hooks/use-is-loading'
import { LoadingSpinner } from 'components/loading-spinner'
import { Behaviour } from './behaviour'

export const Buy = () => {
  const campaign = useCampaign()
  const loading = useIsLoading()
  const { minterAddress, campaignId, tier } = useParams()
  const selectedTier = find(campaign.tiers, x => x.tierName === tier)

  return <BootstrapModal id={'buy-campaign'}>
    <Behaviour />
    {loading && <LoadingSpinner text={'Loading...'} />}
    {campaign && <Form minterAddress={minterAddress} campaignId={campaignId} tier={selectedTier} />}
  </BootstrapModal>
}
