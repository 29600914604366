import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { shouldUseMinBid } from 'common/should-use-min-bid'
import { settings } from 'settings/fetching'

const base = 'auction.price.data.data.auctions.edges[0].node'

const egldLabel = () => settings().dapp.egldLabel

const defaultValue = () => ({ amount: '0', usdAmount: '0', token: egldLabel() })

const minBidPath = `${base}.minBid`

const topBidPath = `${base}.topBid`

const typePath = `${base}.type`

export const usePrice = () => {
  const minBid = useSelector(state => get(state, minBidPath, defaultValue()))
  const topBid = useSelector(state => get(state, topBidPath, defaultValue()))
  const type = useSelector(state => get(state, typePath, ''))

  return shouldUseMinBid({ topBid, type }) ? minBid : topBid
}
