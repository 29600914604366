import { StartAuctionModal } from 'auction/actions/start'

export const Variants = ({ asset, address }) => {
  const title = 'Start another auction'
  const id = 'create-sft-auction'

  return <div className={'col mb-5'}>
    <StartAuctionModal id={id} asset={asset} address={address} title={title} />
  </div>
}
