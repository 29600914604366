import { from } from 'rxjs'
import { history } from 'network'
import { ofType } from 'redux-observable'
import { get as slice } from 'feed/suggestions/slices'
import * as suggestions from 'repositories/feed/suggestions'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'

export const get = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => suggestions.get(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  slice.actions.success(payload),
])

const failure = payload => from([
  slice.actions.failure(payload),
  failureSlice.actions.show({ route: history.location.pathname }),
])
