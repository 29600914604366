import { Label } from 'components/forms/nerds-form/fields/label'
import { ReactComponent as Icon4 } from 'startup/assets/tier-icon-4.svg'
import { LabeledPrice } from 'components/labeled-price'
import { useRealTimeUsdPrice } from 'common/hooks/use-real-time-price'
import { get } from 'lodash'
import 'components/forms/buy-campaign-form/index.scss'

const useProps = tier => {
  const mintPriceAmount = get(tier, 'mintPrice.amount', 0)

  return {
    icon: <Icon4 />,
    label: tier.tierName,
    price: {
      amount: mintPriceAmount,
      token: tier.token,
      usdAmount: useRealTimeUsdPrice(mintPriceAmount),
    },
  }
}

export const Tier = ({ tier }) => <div className={'buy-campaign-form-tier nerds-form-field'}>
  <Label>Selected tier and price:</Label>
  <div className={'d-flex align-items-center'}>
    <LabeledPrice {...useProps(tier)} />
  </div>
</div>

