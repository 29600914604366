import PropTypes from 'prop-types'
import { Tooltip } from 'filters/components/multi-range/inputs/input/tooltip'
import { useRef } from 'react'
import { useUpdateTooltip } from 'filters/components/multi-range/inputs/hooks/use-update-tooltip'

const onMouseEnter = tooltipRef => {
  tooltipRef.current.style.opacity = 1
}

const onMouseLeave = tooltipRef => {
  tooltipRef.current.style.opacity = 0
}

export const Input = props => {
  const tooltipRef = useRef(null)
  useUpdateTooltip(props, tooltipRef)

  return <>
    <Tooltip formatLabel={props.formatLabel} tooltipRef={tooltipRef} value={props.value} />
    <input type={'range'} name={props.inputName} min={props.min} max={props.max}
           value={props.value} step={props.step}
           onMouseEnter={() => onMouseEnter(tooltipRef)}
           onMouseLeave={() => onMouseLeave(tooltipRef)}
           onChange={props.onChanged} className={props.className} />
  </>
}

Input.propTypes = {
  inputName: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  onChanged: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  formatLabel: PropTypes.func.isRequired,
}

