import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { tags as repository } from 'repositories/tags/index'
import { actions } from 'tags/slices/asset'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { history } from 'network'

export const asset = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.getTags(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = error => from([
  actions.failure(error),
  failureSlice.actions.show({ route: history.location.pathname }),
])
