import { useEffect, useRef } from 'react'

import { useContext } from 'nft-page/auction/countdown/timer/context'

const time = subtractSeconds => subtractSeconds - 1000

export const useDecrementTimeLeft = () => {
  const ref = useRef()
  const { timeLeft, setTimeLeft } = useContext()

  useEffect(() => {
    if (timeLeft) {
      ref.current = setInterval(() => {
        setTimeLeft(time)
      }, 1000)
    }

    return () => clearInterval(ref.current)
  }, [timeLeft, setTimeLeft])
}
