import * as Yup from 'yup'

export const useValidationSchema = (primarySale, nftsBought) => {
  const availableToBuy = primarySale.maxUnitsPerWallet - nftsBought

  return Yup.object().shape({
    ticketsNumber: Yup.number()
      .required('Required')
      .typeError('Should be a number')
      .integer('Should be an integer')
      .min(1, 'Should be greater or equal than 1')
      .max(availableToBuy, `You can buy ${availableToBuy} more NFTs`),
    consent: Yup.bool()
      .required()
      .oneOf([true], 'Please agree Terms and Conditions'),
  })
}
