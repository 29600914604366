import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { explore as repository } from 'repositories/explore'
import { actions } from 'explore-nfts/tabs/collections/stats/slices/get'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = () => repository.getCollectionsStats()
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = payload => from([
  actions.failure(payload),
])
