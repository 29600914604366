import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { send } from 'offers/slices/send'
import { get } from 'lodash'
import { useSendOffer } from 'offers/hooks/use-send-offer'
import { useSendOfferSelector } from 'offers/hooks/use-send-offer-selector'
import { SendOfferForm } from 'components/forms/send-offer-form'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { useList as useRunningAuctions } from 'nft-page/details/other-listings/hooks/use-list'

const makeInitialValues = (asset, auctions) => ({
  identifier: get(asset, 'identifier', ''),
  quantity: '1',
  auctions,
})

export const Form = ({ asset }) => {
  const paymentToken = useFirstAcceptedPaymentToken()
  const sendOffer = useSendOffer(paymentToken)
  const { data, loading, errors } = useSendOfferSelector()
  const callbackUrl = useCloseLayerLocation()
  const actionIdentifier = createActionIdentifier(asset, actionTypes.sendOffer)
  const auction = useNftDefaultAuction()
  const isNft = get(asset, 'type') === 'NonFungibleESDT'
  const runningAuctions = useRunningAuctions()
  const filteredAuctions = runningAuctions.filter(({ node }) => node.orders?.edges?.length === 0)
  const extractedAuctions = filteredAuctions.map(({ node }) => node)
  const nftAuction = auction ? [auction] : []
  const auctions = isNft ? nftAuction : extractedAuctions

  useOnSentPayload({ data, callbackUrl, resetAction: send.actions.reset, actionIdentifier })

  return <SendOfferForm onSubmit={sendOffer} submitErrors={errors} loading={loading}
                        callbackUrl={callbackUrl} initialValues={makeInitialValues(asset, auctions)}
                        paymentToken={paymentToken} asset={asset} auctions={auctions} />
}
