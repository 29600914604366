import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

export const FaqsAccordion = ({ faqs }) => <div className={'subdomain-faq accordion w-100'}>

  {faqs.map((faq, index) => <div className={'accordion-item mt-0'} key={index}>
    <h2 className={'accordion-header'} id={'headingOne'}>
      <button className={'accordion-button px-2 collapsed'} type={'button'} data-bs-toggle={'collapse'}
              data-bs-target={`#collapse${index}`}
              aria-expanded={'true'}
              aria-controls={`collapse${index}`}>
        {faq.question}
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
    </h2>
    <div id={`collapse${index}`} className={'accordion-collapse collapse'}>
      <div className={'accordion-body'}>
        {faq.answer}
      </div>
    </div>
  </div>)}
</div>
