import { combineReducers } from 'redux'

import { get as getAssets } from 'explore-nfts/tabs/nfts/assets/slices/get'
import { get as getAuctions } from 'explore-nfts/tabs/nfts/auctions/slices/get'
import { get as getCollections } from 'explore-nfts/tabs/collections/slices/get'
import { get as getArtists } from 'explore-nfts/tabs/creators/slices/get'
import { get as getStats } from 'explore-nfts/stats/slices/get'
import { get as getNftsStats } from 'explore-nfts/tabs/nfts/stats/slices/get'
import { get as getCollectionsStats } from 'explore-nfts/tabs/collections/stats/slices/get'
import { get as getCurrentPaymentTokens } from 'explore-nfts/current-payment-tokens/slices/get'

export const exploreNfts = combineReducers({
  nfts: combineReducers({
    assets: getAssets.reducer,
    auctions: getAuctions.reducer,
  }),
  collections: combineReducers({ get: getCollections.reducer }),
  creators: combineReducers({ get: getArtists.reducer }),
  stats: combineReducers({
    all: getStats.reducer,
    nfts: getNftsStats.reducer,
    collections: getCollectionsStats.reducer,
  }),
  paymentTokens: getCurrentPaymentTokens.reducer,
})
