import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { actions } from 'nft-page/auction/slices/selected-auction'
import moment from 'moment'

export const useExecute = () => {
  const dispatch = useDispatch()

  return useCallback((identifier, auctionId) => {
    if (identifier && auctionId) {
      dispatch(actions.attempt({
        filters: {
          filters: [
            { field: 'id', op: 'EQ', values: [auctionId] },
            { field: 'identifier', op: 'EQ', values: [identifier] },
            { field: 'status', op: 'IN', values: ['Running', 'Claimable'] },
            { field: 'startDate', values: [`${moment().unix()}`], op: 'LE' },
          ],
          operator: 'AND',
        },
      }))
    }
  }, [dispatch])
}

export const useFetchSelectedAuction = () => {
  const { identifier, auctionId } = useParams()
  const { pathname } = useLocation()

  const execute = useExecute()
  useEffect(() => {
    execute(identifier, auctionId)
  }, [auctionId, execute, identifier, pathname])
}
