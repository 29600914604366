import { FlexRow } from 'components/flex-row'
import { useProps } from 'components/forms/nerds-form/subtitle/hooks/use-props'
import './subtitle.scss'

export const Subtitle = ({ subtitle, align = 'center', margins = 'mb-5' }) => <div>
  <FlexRow {...useProps(align, margins)}>
    <span className={'nerds-form-subtitle'}>
      {subtitle}
    </span>
  </FlexRow>
</div>
