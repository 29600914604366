import { DurationLabel } from 'components/duration-label/index'
import { useContext } from 'components/campaign-status/context'
import './index.scss'

export const CountdownTimer = () => {
  const { timeLeft } = useContext()

  return <div className={'d-flex align-items-center'}>
    <DurationLabel duration={timeLeft} />
  </div>
}

