import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import './footer.scss'
import { MainFooter } from 'components/footer/main-footer'
import { Footer as SubdomainFooter } from 'subdomain/components/footer'

export const FooterEnabled = () => {
  const isSubdomain = useIsSubdomain()
  const className = !isSubdomain ? 'footer mt-auto' : ''

  return <footer className={className}>
    {isSubdomain ? <SubdomainFooter /> : <MainFooter />}
  </footer>
}
