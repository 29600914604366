import { useCallback } from 'react'
import { useLastCursor } from 'explore-nfts/tabs/nfts/assets/hooks/use-last-cursor'
import { buildFilters } from 'filters/explore-filters/nfts/assets/filters-logic'
import { useFilterValue } from 'filters/hooks/use-filter-value'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const cursor = useCursor(customCursor)
  const tag = useFilterValue('tag')
  const sort = useFilterValue('sort')

  return useCallback(() => buildFilters({ cursor, sort, tag }), [cursor, sort, tag])
}
