import { exploreNfts as assets } from 'explore-nfts/tabs/nfts/assets/epics'
import { exploreAuctions as auctions } from 'explore-nfts/tabs/nfts/auctions/epics'
import { exploreCollections as collections } from 'explore-nfts/tabs/collections/epics/explore-collections'
import { exploreCreators as creators } from 'explore-nfts/tabs/creators/epics/explore-creators'
import { get as stats } from 'explore-nfts/stats/epics/get'
import { get as nftsStats } from 'explore-nfts/tabs/nfts/stats/epics/get'
import { get as collectionsStats } from 'explore-nfts/tabs/collections/stats/epics/get'
import { get as paymentTokens } from 'explore-nfts/current-payment-tokens/epics/get'
import { combineEpics } from 'redux-observable'

const exploreStats = combineEpics(stats, nftsStats, collectionsStats)

export const explore = combineEpics(assets, auctions, collections, creators, exploreStats, paymentTokens)
