import React from 'react'

export const Context = React.createContext({})

export const useContext = () => React.useContext(Context)

const DEFAULT_OPTIONS = {
  autoplay: false,
  controls: false,
  loop: true,
  responsive: true,
}

export const useValue = props => {
  const player = React.useRef(null)

  return ({
    player: props.instance ?? player,
    container: React.useRef(null),
    options: { ...DEFAULT_OPTIONS, ...props.options },
    sources: props.sources,
  })
}
