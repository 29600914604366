import { history } from 'network'
import { Failure } from 'failure/index'

const make = ({ id, route, variant = Failure.VARIANT.absolute }) => ({
  id,
  route: route ?? history.location.pathname,
  variant,
  message: 'Unable to load. Something went wrong.',
})

export const factory = { make }
