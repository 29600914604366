import { useIsLoadingSale } from '../hooks/use-is-loading-sale'
import { useIsLoadingMyTickets } from '../hooks/use-is-loading-my-tickets'
import { useIsLoadingWhitelisted } from '../hooks/use-is-loading-whitelisted'

export const useIsLoading = () => {
  const isLoadingSale = useIsLoadingSale()
  const isLoadingMyTickets = useIsLoadingMyTickets()
  const isLoadingWhitelisted = useIsLoadingWhitelisted()

  return isLoadingSale || isLoadingMyTickets || isLoadingWhitelisted
}
