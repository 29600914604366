import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { equalityFunction } from 'nft-page/hooks/use-invalid-redirect/equality-function'

export const useWasRequestExecuted = () => {
  const wasDispatched = useSelector(state => !!get(state, 'nft.selectedAuction.input'), equalityFunction)
  const isLoading = useSelector(state => !!get(state, 'nft.selectedAuction.loading'), equalityFunction)

  return wasDispatched && !isLoading
}
