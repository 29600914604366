import { ofType } from 'redux-observable'
import { remove as slice, get as getSlice } from 'nft-page/likes/slices'
import { likes } from 'repositories/likes'
import { from, catchError, mergeMap } from 'rxjs'

export const remove = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => likes.remove({ input: payload.input })
  .pipe(mergeMap(success(payload)))
  .pipe(catchError(failure))

const success = payload => response => from([
  slice.actions.success(response),
  getSlice.actions.attempt({ filters: payload.input, address: payload.address }),
])

const failure = response => from([
  slice.actions.failure(response),
])
