import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  loading: false,
  errors: {},
  data: { },
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload.errors) })

const reset = () => initialState

export const isWhitelisted = createSlice({
  name: 'is-whitelisted/get',
  initialState,
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = isWhitelisted
