import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'explore-nfts/tabs/nfts/stats/slices/get'

export const useFetchStats = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.attempt())
  }, [dispatch])
}
