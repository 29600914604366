import TweetEmbed from 'react-tweet-embed'
import { HomeSection } from 'components/home-section'
import { content } from 'home/twitter-previews/content'
// import { useFetchingCmsTweets } from 'cms-content/hooks/use-fetching-cms-tweets'
// import { useTweetsContent } from 'home/twitter-previews/hooks/use-tweets-content'
import './index.scss'

export const TwitterPreviews = () => {
  // useFetchingCmsTweets()
  // const tweets = useTweetsContent()
  const tweets = [{ tweetId: '1616107998316822528' }, { tweetId: '1615444737544052745' }]

  return <HomeSection title={content.title} description={content.description}>
    <div className={'container'}>
      <div className={'twitter-ceva'}>
        <div className={'twitter-preview row justify-content-center'}>
          {tweets.length !== 0 && tweets.map((item, index) => <TweetEmbed className={'twitter-wrapper col-12 col-md-6'}
                                                                          tweetId={item.tweetId} key={index}
                                                                          options={{ theme: 'dark', height: 600 }} />)}
        </div>
      </div>
    </div>
  </HomeSection>
}
