import { SimpleModal } from 'components/simple-modal'
import { Header } from 'modals/traits/header/header'
import { Body } from 'modals/traits/body'
import { TraitsContext, useTraitsContextValue } from 'modals/traits/context'
import { Behaviour } from 'modals/traits/behaviour'
import { RenderIf } from 'account/common/render-if'
import { isEmpty } from 'lodash'
import 'modals/traits/index.scss'

export const TraitsModal = ({ traits }) => <RenderIf isTrue={!isEmpty(traits)}>
  <SimpleModal id={'traits'} buttonText={'Done'} className={'traits-modal'}>
    <TraitsContext.Provider value={useTraitsContextValue()}>
      <Behaviour />
      <Header />
      <Body traits={traits} />
    </TraitsContext.Provider>
  </SimpleModal>
</RenderIf>

