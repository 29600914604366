import { useContext } from 'react'
import { MultiRangeContext } from 'filters/components/multi-range/context'
import millify from 'millify'

const getValue = value => {
  const isLargeNumber = parseInt(value) >= Number.MAX_SAFE_INTEGER

  return isLargeNumber ? '1m+' : millify(value, { precision: 2, lowercase: true })
}

export const SliderValues = ({ formatLabel }) => {
  const { maxVal, minVal } = useContext(MultiRangeContext)
  const min = parseFloat(minVal)
  const max = parseFloat(maxVal)

  return <div className={'multi-range-slider-values'}>
    {`${getValue(formatLabel(min, 4))} - ${getValue(formatLabel(max, 2))}`}
  </div>
}
