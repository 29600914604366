import { useField } from 'formik'
import { Icon } from './icon'
import { Label } from 'components/forms/nerds-form/fields/label'

const makeClassname = classname => `nerds-form-field ${classname || ''}`
const makeInputClassname = icon => icon ? `form-control pe-0 with-icon` : 'form-control px-0 w-100'

export const InputField = ({ name, label, type, placeholder, defaultValue, disabled, className, icon }) => {
  const [field, meta, { setTouched }] = useField(name)

  return <div className={makeClassname(className)}>
    <Label className={'form-label'} name={name}>{label}</Label>
    <div className={'nerds-form-field-input position-relative'}>
      <Icon icon={icon} />
      <input className={makeInputClassname(icon)} data-testid={name}
             type={type} name={name} id={name}
             onChange={field.onChange}
             onInput={() => setTouched()}
             value={field.value}
             onBlur={field.onBlur}
             placeholder={placeholder}
             defaultValue={defaultValue} disabled={disabled} autoComplete={'off'} />
    </div>
    {meta.touched && meta.error && <small className={'text-danger'}>{meta.error}</small>}
  </div>
}
