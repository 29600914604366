import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { denominateAmount } from 'common/conversion/denominate-amount'

// TODO: this should be used sitewide

export const useRealTimeUsdPrice = egld => {
  const { price } = useEconomicsSelector()
  const decimals = useDenominationDecimals()
  const formattedAmount = denominateAmount(egld)

  return egld ? parseFloat(`${price * formattedAmount}`).toFixed(decimals) : 0
}
