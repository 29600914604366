import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { useLayeredReplaceNavigate } from 'components/layered-router/hooks/use-layered-replace-navigate'

export const useOnTabClick = tab => {
  const { identifier, modalName } = useParams()
  const navigate = useLayeredReplaceNavigate()

  return useCallback(() => {
    const path = `/nfts/${identifier}/modal/${modalName}/${tab}`
    navigate(path, 'modal')
  }, [identifier, modalName, navigate, tab])
}
