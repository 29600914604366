import { useRouteConfig } from 'components/layered-router/hooks/use-route-config'
import { useAppContext } from 'startup/context'

export const useSidebarClass = () => {
  const { sidebarOpened } = useAppContext()
  const currentRoute = useRouteConfig()

  const openedClass = sidebarOpened ? 'sidebar-opened' : 'sidebar-closed'
  const onTopClass = currentRoute?.sidebarOnTop ? 'on-top' : ''

  return `${openedClass} ${onTopClass}`
}
