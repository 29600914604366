import match from '@nerds.sh/js-pattern'
import { isClaimPeriod } from 'holoride-campaign/action/holoride-flow/conditions/is-claim-period'
import { isSalePeriod } from 'holoride-campaign/action/holoride-flow/conditions/is-sale-period'
import { isEndedPeriod } from 'holoride-campaign/action/holoride-flow/conditions/is-ended-period'
import { isBetweenPeriod } from 'holoride-campaign/action/holoride-flow/conditions/is-between-period'
import { handleClaimPeriod } from 'holoride-campaign/action/holoride-flow/handlers/handle-claim-period'

export const handleCampaign = input => match(input)
  .with(isClaimPeriod(input), handleClaimPeriod)
  .with(isSalePeriod(input), () => input.statuses.buy)
  .with(isEndedPeriod(input), () => input.statuses.ended)
  .with(isBetweenPeriod(input), () => input.statuses.between)
  .otherwise(() => () => '')
