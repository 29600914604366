import { useRef } from 'react'
import { Player } from 'nft-page/media/audio/player'
import { PlayerControls } from 'components/player-controls'

export const Audio = () => {
  const player = useRef(null)

  return <>
    <Player player={player} />
    <PlayerControls player={player} playingFeedback />
  </>
}
