import { useIsActiveUser } from 'common/hooks/use-is-active-user'
import { useEffect } from 'react'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { useNavigate } from 'react-router-dom'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useRedirectOnNotActiveUser = () => {
  const isActiveUser = useIsActiveUser()
  const address = useViewerAddress()
  const location = useCloseLayerLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const shouldRedirect = address && !isActiveUser

    shouldRedirect && navigate(location, { replace: true })
  }, [address, isActiveUser, location, navigate])
}
