import { Loading } from '../../../../../../loading'
import { useNerdsFormContext } from '../../../use-nerds-form-context'
import { isEmpty } from 'lodash'

export const useIsLoading = () => {
  const transactionLoading = Loading.usePayloads(Loading.ID.transaction.pending, Loading.VARIANT.partial)
  const { loading } = useNerdsFormContext()

  return loading || !isEmpty(transactionLoading)
}
