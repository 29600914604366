import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'

export const CloseButton = () => {
  const navigate = useCloseLayerNavigate()

  return <div className={'dropzone-close d-flex justify-content-end px-0 py-3'}>
    <a style={{ cursor: 'pointer' }} onClick={() => navigate()}>
      <FontAwesomeIcon icon={faTimes} />
    </a>
  </div>
}
