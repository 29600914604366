import { useCurrentAuction } from 'nft-page/hooks/nft/use-current-auction'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'

const useGetLowestAuction = () => {
  const { asset } = useAssetWithOwner()
  const currentAuction = useCurrentAuction()

  return asset.lowestAuction || currentAuction
}

export const useNftDefaultAuction = () => {
  const selectedAuction = useSelectedAuction()
  const lowestAuction = useGetLowestAuction()

  return selectedAuction || lowestAuction
}
