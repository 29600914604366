import { useDispatch } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { get } from 'nft-page/likes/slices'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useParams } from 'react-router-dom'

const usePayload = () => {
  const { identifier } = useParams()
  const { address } = useGetAccountInfo()

  return useMemo(() => ({ filters: { identifier }, address }), [address, identifier])
}

export const useFetchLikes = () => {
  const dispatch = useDispatch()
  const payload = usePayload()
  const { address } = useGetAccountInfo()

  useEffect(() => {
    address && dispatch(get.actions.attempt(payload))
  }, [address, dispatch, payload])
}
