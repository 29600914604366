import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'subdomain/modals/account/my-auctions/behaviour'
import { ModalContent } from 'subdomain/modals/account/my-auctions/modal-content'
import { Title } from 'subdomain/modals/account/my-auctions/title'
import { List } from 'subdomain/modals/account/my-auctions/list'
import { Failure } from 'failure/index'
import { Empty } from 'subdomain/modals/account/my-auctions/empty'
import 'subdomain/modals/account/my-auctions/style.scss'

export const MyAuctions = () => <BootstrapModal id={'my-auctions'} className={'my-auctions-modal'} easyClose>
  <Behaviour />
  <ModalContent>
    <Title />
    <Failure.Partial id={'modal.account.myAuctions'}>
      <List />
      <Empty />
    </Failure.Partial>
  </ModalContent>
</BootstrapModal>

export { reducer } from 'subdomain/modals/account/my-auctions/reducer'
export { epic } from 'subdomain/modals/account/my-auctions/epic'
