import { AssetDescription } from 'nft-page/header/description/asset-description'
import { useNftScamInfo } from 'nft-page/hooks/nft/use-nft-scam-info'
import { useScamInfoName } from 'nft-page/hooks/nft/use-scam-info-name'
import 'nft-page/header/description/index.scss'

export const Description = () => {
  const scamInfo = useNftScamInfo()
  const scamInfoName = useScamInfoName()

  return <div className={'nft-page-description'}>
    {scamInfo ? scamInfoName : <AssetDescription />}
  </div>
}
