import { get, split } from 'lodash'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

export const useFeaturedCollectionAssets = () => {
  const collections = useSelector(state => get(state, 'home.featuredCollections.data', []))
  const { identifier } = useParams()
  const collectionIdentifier = identifier && split(identifier, '-', 2).join('-')

  return useMemo(() => {
    const collection = collections.find(item => item.node.collection === collectionIdentifier)

    return get(collection, 'node.assets.edges', [])
  }, [collections, collectionIdentifier])
}
