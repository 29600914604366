import { accountActions } from 'components/feed-card/account-actions'
import { Asset } from 'components/feed-card/asset'
import 'components/feed-card/small-preview/index.scss'
import { UserImage } from 'components/user-image/index'
import { get } from 'lodash'

const getUserProps = props => ({
  herotag: get(props, 'whom.address'),
  address: get(props, 'whom.herotag'),
  image: get(props, 'whom.profile.url'),
})

export const SmallPreview = props => <div className={'feed-card-small-preview'}>
  <div className={'feed-card-small-preview-inner'}>
    {accountActions.includes(props.action.type) ? <UserImage {...getUserProps(props)} /> : <Asset {...props} big />}
  </div>
</div>
