import { useEffect } from 'react'
import { useAppContext } from 'startup/context'

export const useResetKey = setKey => {
  const { shouldRefresh, setShouldRefresh } = useAppContext()

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false)
      setKey(Math.random())
    }
  }, [setKey, setShouldRefresh, shouldRefresh])
}
