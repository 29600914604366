import { HomeSection } from 'subdomain/components/home-section'
import { get } from 'lodash'
import 'subdomain/home/overview/index.scss'
import { Box } from 'subdomain/home/overview/box'

export const Overview = ({ data }) => {
  const title = get(data, 'title', '')
  const subtitle = get(data, 'subtitle', '')
  const description = get(data, 'description', '')
  const boxes = get(data, 'boxes', [])

  return <HomeSection title={title} className={'overview-section'}>
    <div className={'overview-section-row'}>
      <div className={'overview-section-subtitle m-2'}>{subtitle}</div>
      <div className={'overview-section-boxes m-2'}>
        <div className={'row my-4'}>
          {boxes.map((box, index) => <Box key={index} box={box} />)}
        </div>
      </div>
      <div className={'overview-section-description mx-5'}>{description}</div>
    </div>
  </HomeSection>
}
