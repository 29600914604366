import { filter, get } from 'lodash'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCmsCampaignContract = () => {
  const data = useCmsData()
  const content = get(data, 'data.data.attributes.content', [])
  const [campaign] = filter(content, { __component: 'sections.campaign' })

  return get(campaign, 'contract', '')
}
