import { useDispatch } from 'react-redux'
import { actions } from 'collections/assets/slices/get'
import { useNext } from 'collections/assets/hooks/use-next'
import { useIsNftPageOpened } from 'common/hooks/use-is-nft-page-opened'
import { useCallback } from 'react'
import { useFetchNotifications } from '../../../../notifications/account/hooks/use-fetch-notifications'

export const useOnChange = () => {
  const dispatch = useDispatch()
  const getInitialPage = useNext('')
  const isNftPageOpened = useIsNftPageOpened()
  const fetchNotifications = useFetchNotifications()

  return useCallback(() => {
    if (isNftPageOpened) {
      getInitialPage()
      fetchNotifications()
      dispatch(actions.reset({}))
    }
  }, [isNftPageOpened, getInitialPage, dispatch, fetchNotifications])
}
