import { useWatchAction } from 'action-statuses/hooks/use-watch-action'
import { useOnChange } from 'holoride-campaign/hooks/use-on-change'
import { usePrimarySale } from 'holoride-campaign/hooks/use-primary-sale'

export const useWatchTransaction = () => {
  const primarySale = usePrimarySale()
  const onChange = useOnChange()

  useWatchAction({ prefix: `${primarySale.collectionIdentifier}_Campaign`, onChange })
}
