import { Context, useDefaultContext } from 'holoride-campaign/action/between/context'
import { Countdown } from 'holoride-campaign/action/between/countdown'
import { get } from 'lodash'
import './index.scss'

export const Between = ({ primarySale }) => {
  const startClaim = get(primarySale, 'saleTime.startClaim')

  return <Context.Provider value={useDefaultContext()}>
    <div className={'holoride-action-inner d-flex align-items-center flex-column'}>
      <span className={'holoride-campaign-between-label'}>Claim starts in: </span>
      <Countdown endDate={startClaim} />
    </div>
  </Context.Provider>
}
