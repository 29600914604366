import { Behaviour } from 'home/behaviour'
import { Seo } from 'components/seo'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { CTASection } from 'home/cta-section'
// import { Metaspaces } from 'home/metaspaces'
import { Marketplaces } from 'home/marketplaces'
import { FeaturedCollections } from 'home/featured-collections'
import { TrendingCollections } from 'home/trending-collections'
import { SubscribeNewsletter } from 'home/subscribe-newsletter/subscribe-newsletter'
import { TwitterPreviews } from 'home/twitter-previews/twitter-previews'
import 'home/style.scss'

export const HomePage = () => <>
  <ScrollToTopOnMount />
  <Seo title={'Home'} />
  <Behaviour />
  <CTASection />
  {/* <Metaspaces /> */}
  <TrendingCollections />
  <FeaturedCollections />
  <Marketplaces />
  <TwitterPreviews />
  <SubscribeNewsletter />
</>
