import { useAccountCollections } from 'modals/account/create-nft/collections/hooks/use-account-collections'
import { useField } from 'formik'
import { Loading } from 'feed/loading'
import { useIsLoading } from 'modals/account/create-nft/collections/hooks/use-is-loading'
import { useOnChanged } from 'components/forms/nerds-form/fields/collection-field/hooks/use-on-changed'
import Select from 'react-select'
import { useStatus } from 'nft-page/auction/actions/watchable-action/hooks/use-status'
import { actionTypes } from 'action-statuses/actions-types'

const makeOptions = collections => collections.map(({ node }) => (
  {
    value: node.collection,
    label: <>
      {node.name}
      {' '}
      <span className={'text-muted'}>
        (
        {node.collection}
        )
      </span>
    </>,
  }
))

export const CollectionSelector = ({ name }) => {
  const collections = useAccountCollections()
  const [field, meta, { setTouched }] = useField(name)
  const loading = useIsLoading()
  const onSelectionChanged = useOnChanged(name)
  const status = useStatus(field.value, actionTypes.setRoles)

  if (loading) return <Loading />

  return collections && <Select data-testid={name} isDisabled={!!status}
                                name={name}
                                id={name} contentEditable={false}
                                classNamePrefix={'react-select'}
                                onChange={onSelectionChanged}
                                onInput={() => setTouched()}
                                defaultValue={meta.initialValue.collection}
                                onBlur={field.onBlur}
                                options={makeOptions(collections)} />
}
