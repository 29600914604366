import { useField } from 'formik'

export const Input = ({ props, name, openCalendar }) => {
  const [field,, { setTouched }] = useField(name)

  return <input {...props} className={'form-control pe-0 with-icon'}
                type={'text'} name={name} id={name}
                onChange={field.onChange}
                onInput={() => setTouched()}
                onBlur={field.onBlur} onClick={openCalendar} />
}
