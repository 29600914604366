import { of, from } from 'rxjs'
import { nft } from 'repositories/nft'
import { ofType } from 'redux-observable'
import { catchError, mergeMap, map } from 'rxjs/operators'
import { actions } from 'modals/account/create-nft/slices/create-nft'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'
import { throwOnInsufficientFunds } from 'modals/throw-on-insufficient-funds'

export const createNft = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(({ payload }) => execute(payload)))

const execute = payload => nft.verifyContent(payload)
  .pipe(mergeMap(() => nft.createNft(payload)))
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.createNft })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = result => of(actions.failure(extractGraphqlErrors(result)))
