import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'modals/account/cover/slices/update-cover'
import { useUpdateCoverResult } from 'modals/account/cover/hooks/use-update-cover-result'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'

export const useHandleResult = () => {
  const data = useUpdateCoverResult()
  const dispatch = useDispatch()
  const closeLayer = useCloseLayerNavigate()

  useEffect(() => {
    if (data) {
      closeLayer()
      dispatch(actions.reset({}))
    }
  }, [closeLayer, data, dispatch])
}
