import { Cover } from 'modals/account/cover'
import { Photo } from 'modals/account/photo'
import { Edit } from 'modals/account/edit'
import { Followers } from 'modals/account/followers'
import { useRedirectAccountModals } from 'modals/hooks/use-redirect-account-modals'
import { Followees } from 'modals/account/followees'
import { CreateNft } from 'modals/account/create-nft'
import { CreateCollection } from 'modals/collection/create/create-collection'
import { FeaturedCollection } from 'modals/account/admin/featured-collection'
import { BlacklistedCollections } from 'modals/account/admin/blacklisted-collections'

export const Account = () => {
  useRedirectAccountModals()

  return <>
    <Cover />
    <Photo />
    <Edit />
    <Followers />
    <Followees />
    <CreateNft />
    <CreateCollection />
    <FeaturedCollection />
    <BlacklistedCollections />
  </>
}

export * from 'modals/account/reducers'
export * from 'modals/account/epics'
