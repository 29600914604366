import { useNftLoadingSelector } from 'nft-page/hooks/nft/use-nft-loading-selector'
import { useIsNftAuctionsLoading } from 'nft-page/details/other-listings/hooks/use-is-nft-auctions-loading'
import { Loading } from 'nft-page/content/loading'

const useIsLoading = () => {
  const loading = useNftLoadingSelector()
  const nftAuctionsLoading = useIsNftAuctionsLoading()

  return loading || nftAuctionsLoading
}

export const Wrapper = ({ children }) => {
  const isLoading = useIsLoading()

  return <div className={'nft-content'}>
    <div className={'nft-content-inner'}>{isLoading ? <Loading /> : children}</div>
  </div>
}
