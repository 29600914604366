import { UserInfo } from 'components/user-info'
import { useHeroTag } from 'components/asset-card/hooks/use-hero-tag'
import { useProfileImage } from 'components/asset-card/hooks/use-profile-image'
import { colors } from 'components/username/colors'
import { get } from 'lodash'
import defaultAvatar from 'common/default-avatar'
import 'components/asset-card/creator/index.scss'

export const Creator = ({ asset }) => {
  const artistAddress = get(asset, 'artist.address', '')
  const image = useProfileImage(asset)

  return <div className={'asset-card-creator'}>
    <UserInfo label={'Creator'} address={artistAddress} herotag={useHeroTag(asset)}
              image={image || defaultAvatar} color={colors.primary} />
  </div>
}
