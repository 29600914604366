import { useEffect } from 'react'
import { useContext } from 'components/asset-card/time-left/context'
import { useIsAuctionEnded } from 'components/asset-card/badges/hooks/use-is-auction-ended'
import { useInitialState } from 'components/asset-card/time-left/hooks/use-initial-state'

export const useDecrementTimeLeft = (auction, endDate) => {
  const { auctionTimeLeft, setAuctionTimeLeft, isVisible } = useContext()
  const isAuctionEnded = useIsAuctionEnded(auction, endDate)
  const initialTimeLeft = useInitialState(endDate)
  const shouldTick = auctionTimeLeft && !isAuctionEnded && isVisible

  useEffect(() => {
    let interval

    if (shouldTick) {
      interval = setInterval(() => {
        setAuctionTimeLeft(initialTimeLeft)
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [auctionTimeLeft, isAuctionEnded, setAuctionTimeLeft, isVisible, initialTimeLeft, shouldTick])
}
