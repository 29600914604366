import { gql } from 'graphql-tag'

export const HERO_COLLECTIONS = gql`
query heroCollections($filters: FeaturedCollectionsFilter, $pagination: ConnectionArgs) {
  featuredCollections(filters: $filters, pagination: $pagination) {
    edges {
      cursor
      node {
        name
        collection
        collectionAsset {
            assets (input: { size: 10}) {
                identifier
                thumbnailUrl
            }
        }  
      }
    }
    pageData {
      count
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
`
