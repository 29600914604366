import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { get } from 'lodash'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useMarketplaceAddress } from 'marketplaces/hooks/use-marketplace-address'
import { Address } from '@multiversx/sdk-core/out'

export const useIsAuctionOwner = () => {
  const auction = useNftDefaultAuction()
  const { address } = useGetAccountInfo()
  const auctionOwnerAddress = get(auction, 'ownerAddress')

  return address === auctionOwnerAddress
}

export const isContractAddress = address => {
  const addressCheck = new Address(address)

  return addressCheck.isContractAddress()
}

const useIsMarketplaceOwner = () => {
  const { asset } = useAssetWithOwner()
  const assetOwnerAddress = get(asset, 'ownerAddress')
  const marketplaceAddress = useMarketplaceAddress()
  const isContract = isContractAddress(assetOwnerAddress)

  return assetOwnerAddress === marketplaceAddress || isContract
}

export const useIsAuctionContractOwner = () => {
  const auction = useNftDefaultAuction()
  const isAuctionOwner = useIsAuctionOwner()
  const isMarketplaceOwner = useIsMarketplaceOwner()

  return auction ? isAuctionOwner : isMarketplaceOwner
}
