import moment from 'moment'
import { useEffect } from 'react'
import { useContext } from 'holoride-campaign/action/before/context'

export const useInitializeTimeLeft = date => {
  const { setTimeLeft } = useContext()

  useEffect(() => {
    if (date) {
      const eventDate = moment(date * 1000)
      const durationLeft = moment.duration(eventDate.diff(moment(), 'milliseconds', true))
      setTimeLeft(durationLeft.asMilliseconds())
    }
  }, [date, setTimeLeft])
}
