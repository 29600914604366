import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions } from 'modals/auction/withdraw/slices/withdraw'
import { useParams } from 'react-router-dom'

export const useWithdraw = auction => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    const input = { auctionId: parseInt(auction.id), identifier }

    dispatch(actions.attempt(input))
  }, [dispatch, auction.id, identifier])
}
