export const brands = [
  { id: 7, name: 'Discord', value: 'discord' },
  { id: 2, name: 'Facebook', value: 'facebook' },
  { id: 3, name: 'Instagram', value: 'instagram' },
  { id: 8, name: 'Medium', value: 'medium' },
  { id: 6, name: 'Telegram', value: 'telegram' },
  { id: 1, name: 'Twitter', value: 'twitter' },
  { id: 4, name: 'Web Page', value: 'default' },
  { id: 5, name: 'YouTube', value: 'youtube' },
]

export const genericBrand = brands.pop()
