import { rest } from 'network'
import { settings } from 'settings/fetching'

const getBaseUrl = () => settings().tools

const views = payload => rest().post(`${getBaseUrl()}/stats-api/api/v1/views`, {
  headers: {
    accept: '*/*',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
}, false)

export const tools = () => ({ views })
