import { get } from 'lodash'
import { BN } from 'bn.js'

const getAmountToUse = auction => {
  const topBidAmount = get(auction, 'topBid.amount', '')
  const maxBidAmount = get(auction, 'maxBid.amount', '')
  const minBidDiffAmount = get(auction, 'minBidDiff.amount', '')
  const topBidPlusDiff = new BN(String(topBidAmount)).add(new BN(String(minBidDiffAmount)))
    .toString()
  const isGreater = new BN(String(topBidPlusDiff)).gte(new BN(String(maxBidAmount)))

  return isGreater && maxBidAmount !== '0' ? maxBidAmount : topBidPlusDiff
}

export const useMinimum = auction => {
  const minBidAmount = get(auction, 'minBid.amount', '')
  const topBidAmount = get(auction, 'topBid.amount', '')
  const amountToUse = getAmountToUse(auction)

  return topBidAmount ? amountToUse : minBidAmount
}
