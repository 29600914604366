import { useFollowsCurrent } from 'components/feed-card/hooks/use-follows-current'

const bigLayoutActions = ['won', 'follow', 'mintNft', 'startAuction', 'buy', 'sendOffer', 'acceptOffer']

export const useIsBigLayout = (actionType, address) => {
  const followsCurrent = useFollowsCurrent(actionType, address)

  return bigLayoutActions.includes(actionType) && !followsCurrent
}

