import { NerdsForm } from 'components/forms/nerds-form'
import { config } from 'components/forms/edit-profile-form/config'
import { useData } from 'components/forms/edit-profile-form/hooks/use-data'
import { Description } from 'components/forms/edit-profile-form/fields/description'
import { Title } from 'components/forms/nerds-form/title'
import { SocialLinks } from 'components/forms/edit-profile-form/fields/social-links'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Privacy } from 'components/forms/edit-profile-form/fields/privacy'

export const EditProfileForm = ({ loading, onSubmit, submitErrors, callbackUrl }) => {
  const data = useData()

  return <NerdsForm loading={loading}
                    submitErrors={submitErrors}
                    config={config({ onSubmit, ...data })}>
    <Title title={'Profile bio'} align={'left'} />
    <Description />
    <Title title={'Social links'} align={'left'} margins={'mb-4'} />
    <SocialLinks />
    <Privacy />
    <DefaultActions callbackUrl={callbackUrl} />
  </NerdsForm>
}
