import { useCurrentLayer } from 'components/layered-router/hooks/use-current-layer'
import { useEffect, useState } from 'react'

export const ScrollToTopOnMount = () => {
  const [currentLayer] = useState(useCurrentLayer())

  useEffect(() => {
    if (!currentLayer) {
      document.getElementById('root').scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [currentLayer])

  return null
}
