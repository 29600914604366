import { createContext, useContext as useReactContext, useState } from 'react'
import { useInitialState } from 'subdomain/components/my-asset-card/time-left/hooks/use-initial-state'

export const Context = createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultContext = endDate => {
  const initialState = useInitialState(endDate)
  const [auctionTimeLeft, setAuctionTimeLeft] = useState(initialState)
  const [isVisible, setVisible] = useState(false)

  return { auctionTimeLeft, setAuctionTimeLeft, isVisible, setVisible }
}
