import { of, groupBy, map, mergeMap, toArray, filter } from 'rxjs'
import { accounts } from 'repositories/feed/accounts'
import { emitArrayElements } from 'repositories/feed/common'
import { handleByGroup } from 'repositories/feed/handle-by-group'
import { orderBy } from 'lodash'

export const populateData = ({ cursor, items }) => of(items)
  .pipe(filter(data => !!data.length))
  .pipe(mergeMap(events => accounts({ events, field: 'who', target: 'actor' })))
  .pipe(emitArrayElements())
  .pipe(groupBy(({ event }) => event))
  .pipe(mergeMap(handleByGroup))
  .pipe(toArray())
  .pipe(map(results => orderBy(results, 'when', 'desc')))
  .pipe(map(results => ({ cursor, items: results, hasMore: !!results.length })))
