import { useDispatch } from 'react-redux'
import { useFetchPayload } from './use-fetch-payload'
import { useCallback } from 'react'
import { actions } from '../slices/campaign'
import { Loading } from '../../loading'

export const useFetchCallback = () => {
  const useIssueServer = () => {
    const dispatch = useDispatch()
    const payload = useFetchPayload()

    return useCallback(() => {
      dispatch(actions.attempt(payload))
    }, [dispatch, payload])
  }

  const showLoading = Loading.useShowLoading()
  const hideLoading = Loading.useHideLoadings()
  const issueServer = useIssueServer()

  return useCallback(() => {
    hideLoading(Loading.ID.campaign.page)
    showLoading(Loading.ID.campaign.page)
    issueServer()
  }, [hideLoading, issueServer, showLoading])
}

