import { humanize } from 'components/conversion/humanize'

export const Count = ({ count }) => {
  const integer = parseInt(count)

  return <span className={'traits-button-count'}>
    {humanize(integer?.toString())}
    {' '}
    applied
  </span>
}
