import { useSetLocationFilters } from 'filters/hooks/use-set-location-filters'
import { useCallback } from 'react'

export const useResetAllTraits = () => {
  const setLocationFilters = useSetLocationFilters()

  return useCallback(() => {
    setLocationFilters({ name: 'traits' })
  }, [setLocationFilters])
}
