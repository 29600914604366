export const isSlideVisible = (slide, swiperElem) => {
  if (slide) {
    const rect = slide.getBoundingClientRect()
    const swiperRect = swiperElem.getBoundingClientRect()

    const swiperLeft = swiperRect.left
    const swiperRight = swiperLeft + swiperRect.width

    const leftCond = rect.left >= swiperRect.left - 1
    const rightCond = rect.left + rect.width <= swiperRight + 1

    return leftCond && rightCond
  } else {
    return false
  }
}
