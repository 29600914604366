import { SliderLabel } from 'filters/components/multi-range/slider-label'
import { Sliders } from 'filters/components/multi-range/sliders'
import { SliderValues } from 'filters/components/multi-range/slider-values'

const className = 'd-none d-md-flex align-items-center'

export const ContentLarge = props => <div className={className}>
  <SliderLabel />
  <SliderValues max={props.initialMax} min={props.initialMin} formatLabel={props.formatLabel} />
  <div className={'mx-2'}>
    <Sliders max={props.max} min={props.min} initialMin={props.initialMin} initialMax={props.initialMax}
             step={props.step} formatLabel={props.formatLabel} />
  </div>
</div>
