import { useFilters } from 'filters/hooks/use-filters'
import { get } from 'lodash'
import { usePathname } from 'explore-nfts/tabs/hooks/use-pathname'

export const useIsSortByDisabled = () => {
  const { search } = useFilters()
  const facets = get(search, 'filter', 'all')
  const tab = usePathname()

  return facets !== 'all' && tab === 'nfts'
}
