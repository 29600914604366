import { Button as AppButton } from 'components/button'
import { useStatus } from 'nft-page/auction/actions/watchable-action/hooks/use-status'
import { actionTypes } from 'action-statuses/actions-types'
import { usePrimarySale } from 'holoride-campaign/hooks/use-primary-sale'
import { useOnClick } from 'holoride-campaign/action/buy/hooks/use-on-click'

export const Button = () => {
  const primarySale = usePrimarySale()
  const status = useStatus(primarySale, actionTypes.buyTickets)

  return <AppButton onClick={useOnClick()} disabled={!!status} buttonType={'primary'}>Buy</AppButton>
}
