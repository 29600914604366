import React from 'react'
import { STEP } from 'sidebar/connect/constants'

export const Context = React.createContext({})

export const useContext = () => React.useContext(Context)

export const useValue = () => {
  const [step, setStep] = React.useState(STEP.loginButtons)

  return { step, setStep }
}
