import './menu.scss'
import { MenuList } from './menu-list'
import { useRef } from 'react'
import { useUpdateAppContext } from './hooks/use-update-app-context'

export const Menu = ({ className = '' }) => {
  const collapse = useRef()

  useUpdateAppContext(collapse.current)

  return <div ref={collapse} className={`navbar-collapse flex-grow-0 me-0 me-md-3 justify-content-end ${className}`}
              id={'navbarMenu'}>
    <MenuList />
  </div>
}
