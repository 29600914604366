import { useTraitsContext } from 'modals/traits/context'
import { useEffect } from 'react'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const Behaviour = () => {
  const { setSelectedTraits } = useTraitsContext()
  const traitsHash = JSON.stringify(useFilterValue('traits'))

  useEffect(() => {
    setSelectedTraits(JSON.parse(traitsHash) || [])
  }, [traitsHash, setSelectedTraits])

  return ''
}
