import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useViewerUsername } from 'viewer/hooks/use-viewer-username'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { useCallback } from 'react'

export const useOnClick = () => {
  const address = useViewerAddress()
  const username = useViewerUsername()
  const navigate = useLayeredNavigate()

  return useCallback(() => {
    const path = `/${username || address}/modal/account-create-nft`
    navigate(path, 'modal')
  }, [address, navigate, username])
}
