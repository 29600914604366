import { useCallback } from 'react'
import { useResetError } from 'modals/account/dropzone/hooks/use-reset-error'

export const useOnDrop = input => {
  const { setError, maxSize, setImage } = input
  const resetError = useResetError(setError)

  return useCallback(([file]) => {
    if (file.size > maxSize) {
      resetError()

      return setError('File too large')
    }

    const image = new window.Image()
    image.src = window.URL.createObjectURL(file)

    image.onload = () => {
      setImage(image.src)
    }
  }, [maxSize, setError, resetError, setImage])
}
