import { gql } from 'graphql-tag'

export const GET_EXPLORE_COLLECTIONS_STATS = gql`
    query exploreCollectionsStats {
        exploreCollectionsStats
        {
            activeLast30DaysCount
            allCollectionsCount
            verifiedCount
        }
    }
`
