import { useAddLike, useRemoveLike, useIsLiked } from 'nft-page/likes/hooks'
import { useAuthorizedAction } from 'common/hooks/use-authorized-action'

export const useOnClick = () => {
  const addLike = useAddLike()
  const removeLike = useRemoveLike()
  const isLiked = useIsLiked()

  return useAuthorizedAction(isLiked ? removeLike : addLike)
}
