import epics from 'startup/epics'
import { reducers } from 'startup/reducers'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { createEpicMiddleware } from 'redux-observable'

let store = ''
let persistor = ''
const middlewareConfig = { serializableCheck: false }

export const useStore = () => {
  if (!store) {
    const epicMiddleware = createEpicMiddleware()

    store = configureStore({
      middleware: getDefaultMiddleware => getDefaultMiddleware(middlewareConfig)
        .concat(epicMiddleware),
      reducer: reducers,
    })
    epicMiddleware.run(epics)
    persistor = persistStore(store)
  }

  return {
    store,
    persistor,
  }
}
