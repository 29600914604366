import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { actions } from 'owners/slices/owners'

export const useFetchOwners = () => {
  const { identifier, auctionId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.attempt({ filters: { identifier } }))
  }, [dispatch, identifier, auctionId])
}
