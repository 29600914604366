import { useCallback } from 'react'
import { useActionPath } from 'collections/actions/create-nft/hooks/use-action-path'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'

export const useOnNftClick = () => {
  const navigate = useLayeredNavigate()
  const path = useActionPath('createNft')

  return useCallback(() => {
    navigate(path, 'modal')
  }, [navigate, path])
}
