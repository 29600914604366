import { useCallback } from 'react'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const useMinimumMessage = (auction, paymentToken) => {
  const minimum = useMinimum(auction)
  const decimals = get(paymentToken, 'decimals', 18)
  const amount = denominateAmount(minimum, decimals, true)

  return useCallback(() => (
    `Must be greater or equal than ${amount} ${paymentToken.symbol}`
  ), [amount, paymentToken.symbol])
}
