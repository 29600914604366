import { useEffect } from 'react'
import { useNext } from 'subdomain/modals/account/my-claimable/hooks/use-next'

export const useGetInitialPage = () => {
  const getInitialPage = useNext('')

  useEffect(() => {
    getInitialPage()
  }, [getInitialPage])
}
