import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'modals/collection/set-roles/slices/set-roles'
import { useCollectionIdentifier } from 'modals/collection/set-roles/lookup/hooks/use-collection-identifier'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useSetRoles = () => {
  const collection = useCollectionIdentifier()
  const dispatch = useDispatch()
  const address = useViewerAddress()

  return useCallback(payload => {
    dispatch(actions.attempt({ collection, addressToTransfer: address, roles: payload.roles }))
  }, [collection, address, dispatch])
}
