import { useSource } from 'components/asset-card/hooks/use-source'
import { useErrorCallback } from 'components/asset-card/hooks/use-error-callback'
import { Price } from 'components/asset-card/price'
import 'components/asset-card/header/header.scss'
import { useMarketplaceContractIsPaused } from 'marketplaces/hooks/use-marketplace-contract-is-paused'

export const Header = props => {
  const isContractPaused = useMarketplaceContractIsPaused()

  return <div className={'asset-card-header'}>
    <div className={'asset-card-img'}>
      <img src={useSource(props)} alt={'...'} onError={useErrorCallback()} />
    </div>
    {props.auction && !isContractPaused && <Price auction={props.auction} />}
  </div>
}
