import { Buy } from 'nft-page/details/other-listings/actions/buy-or-bid/buy'
import { Bid } from 'nft-page/details/other-listings/actions/buy-or-bid/bid'
import { FlexRow } from 'components/flex-row'

export const BuyOrBid = ({ auction, address }) => <div className={'d-flex ms-auto'}>
  <FlexRow className={'ms-1'}>
    <Buy auction={auction} address={address} />
    <Bid auction={auction} address={address} />
  </FlexRow>
</div>
