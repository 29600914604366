import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { get } from 'feed/slices'

const scrollOptions = {
  top: 0,
  left: 0,
  behavior: 'instant',
}

const scrollToTop = () => document.getElementById('root').scrollTo(scrollOptions)

export const useOnClick = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(get.actions.reset({}))
    dispatch(get.actions.attempt({}))
    scrollToTop()
  }, [dispatch])
}
