import { useCallback } from 'react'
import getCroppedImg from 'modals/account/dropzone/get-cropped-img'
import { useContext } from 'modals/account/dropzone/context'

export const useOnSubmit = () => {
  const { image, croppedArea, onSubmit, dimensions } = useContext()

  return useCallback(async() => {
    try {
      const croppedImage = await getCroppedImg(image, croppedArea, dimensions)

      onSubmit(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedArea, image, onSubmit, dimensions])
}
