import { get } from 'lodash'
import { AssetCard } from 'components/asset-card'

export const CardItem = ({ item }) => {
  const asset = get(item, 'node', {})
  const auction = get(item, 'node.lowestAuction', {})
  const isGhost = get(item, 'isGhost')

  return <AssetCard asset={asset} auction={auction} previewSource={'account-liked'}
                    isGhost={isGhost} showEndedBadge />
}
