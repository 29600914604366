import { Errors, NerdsForm } from 'components/forms/nerds-form'
import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { useConfig } from 'components/forms/end-auction-form/hooks/use-config'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'

export const ClaimAuctionForm = ({ loading, onSubmit, submitErrors, callbackUrl, initialValues }) => (
  <NerdsForm loading={loading}
             config={useConfig({ onSubmit, initialValues })}
             submit_errors={submitErrors}>
    <div className={'modal-content'}>
      <Title icon={faGavel} title={'Claim Auction'} />
      <DefaultActions callbackUrl={callbackUrl} label={'Claim Auction'} />
      <Errors values={submitErrors} />
    </div>
  </NerdsForm>
)
