import { get } from 'lodash'
import React from 'react'

export const Box = ({ box }) => {
  const image = get(box, 'image.data.attributes.formats.small.url')
  const caption = get(box, 'caption')

  return <div className={'col-6 col-md-3 d-flex flex-wrap justify-content-center  mb-4'}>
    <div className={'overview-section-box'}>
      <div className={'overview-section-box-image-wrapper'}>
        <img className={'overview-section-box-image'} src={image} />
      </div>
      <p className={'my-2 text-center col-12'}>{caption}</p>
    </div>
  </div>
}
