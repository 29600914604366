import PropTypes from 'prop-types'
import { Button } from 'components/facet-button/button'
import './index.scss'

export const FacetButton = ({ image, label, count, options, onClick, selected, disabled, showClose }) => {
  const buttonProps = { label, count, image, disabled, onClick, selected, showClose }

  return <Button {...buttonProps} />
}

FacetButton.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

FacetButton.defaultProps = { onClick: () => {}, image: '' }
