import 'components/user-details/history-action/index.scss'

export const actions = {
  Added: 'Listed by',
  Bought: 'Purchased by',
  ClosedAuction: 'Closed by',
  Created: 'Minted by',
  EndedAuction: 'Ended by',
  Received: 'Received by',
  StartedAuction: `Started by`,
}

export const HistoryAction = ({ action }) => <div className={`user-details-action text-link-color`}>
  {actions[action]}
</div>
