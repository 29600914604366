import 'components/forms/nerds-form/fields/price-field/usd-price/index.scss'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { get } from 'lodash'
import { UsdPrice as Price } from 'components/forms/nerds-form/fields/price-field/usd-price/usd-price'

const useUsdPrice = (price, inputValue) => {
  const decimals = useDenominationDecimals()

  return parseFloat(`${price * inputValue}`).toFixed(decimals)
}

export const UsdPrice = ({ inputValue, paymentToken }) => {
  const price = get(paymentToken, 'priceUsd')
  const usdPrice = useUsdPrice(price, inputValue)

  return inputValue && !isNaN(inputValue) && <Price price={price} usdPrice={usdPrice} inputValue={inputValue} />
}
