import { Content } from 'subdomain/components/my-asset-card/badge/content'
import 'subdomain/components/my-asset-card/badge/index.scss'

export const BadgeType = {
  TimeLeft: { className: 'time-left' },
  Ended: { className: 'ended' },
  Outbidded: { className: 'outbidded' },
  TopBidder: { className: 'top-bidder' },
  OfferExpired: { className: 'offer-expired' },
  OfferActive: { className: 'offer-active' },
}

export const Badge = ({
  label, suffix,
  type = BadgeType.Outbidded,
  condition,
}) => condition ? <Content label={label} suffix={suffix} type={type} /> : ''

