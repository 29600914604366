import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useIsAssetOwner } from 'nft-page/auction/actions/sell-more/hooks/use-is-asset-owner'
import { useCanCreateMore } from 'nft-page/auction/actions/sell-more/hooks/use-can-create-more'
import { useIsScamOrNsfw } from 'nft-page/auction/hooks/use-is-scam-or-nsfw'

export const useShouldHideSellMore = () => {
  const { asset } = useAssetWithOwner()
  const { address } = useGetAccountInfo()
  const isOwner = useIsAssetOwner(address)
  const canCreateMore = useCanCreateMore(asset)
  const isScamOrNotSafeForWork = useIsScamOrNsfw(asset)

  return isOwner && canCreateMore && !isScamOrNotSafeForWork
}
