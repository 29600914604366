import 'components/toasts/transaction-toast/header/index.scss'
import { DismissButton } from 'components/toasts/transaction-toast/header/dismiss-button'

export const Header = ({ title, icon, className }) => {
  const isProcessing = title === 'Processing transaction'

  return <div className={`toast-header`}>
    <div className={`toast-header-icon me-2 ${className}`}>{icon}</div>
    <div className={'toast-header-title me-auto'}>{title}</div>
    {isProcessing ? '' : <DismissButton />}
  </div>
}
