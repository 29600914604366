import { useLocation } from 'react-router-dom'

const isHerotagAccount = pathname => pathname.startsWith('/@')
const isAddressAccount = pathname => pathname.startsWith('/erd')

export const useShouldUseAccountState = () => {
  const { pathname } = useLocation()

  return isHerotagAccount(pathname) || isAddressAccount(pathname)
}
