import { useEndCursor } from 'account/tabs/assets/creations/hooks/use-end-cursor'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'account/tabs/assets/creations/slices/get'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useUpdateLoadings } from 'account/tabs/assets/hooks/use-update-loadings'
import { publicTabs } from 'account/tabs/public-tabs'
import { useFetchPayload } from 'account/tabs/assets/creations/hooks/use-fetch-payload'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const cursor = useCursor(customCursor)
  const dispatch = useDispatch()
  const address = useViewerAddress()
  const updateLoadings = useUpdateLoadings(publicTabs.creations)
  const makePayload = useFetchPayload({ cursor })

  return useCallback(() => {
    updateLoadings()
    address && dispatch(actions.attempt(makePayload({ cursor })))
  }, [updateLoadings, address, dispatch, makePayload, cursor])
}
