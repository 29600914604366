import { useLayerNames } from 'components/layered-router/hooks/use-layer-names'
import { get, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useCurrentLayer } from 'components/layered-router/hooks/use-current-layer'

const pathOrPath = (path1, path2) => path1 || path2

const removeCurrentLayer = (layerLink, currentLayer) => {
  const newlayerLink = { ...layerLink }
  delete newlayerLink[currentLayer]

  return newlayerLink
}

const makeNewLocation = (path, state, layerlink) => ({
  pathname: path,
  state: {
    ...state,
    forceReset: false,
    layerLink: layerlink,
  },
})

const getNextLayerPath = (layerNames, layerLink) => {
  const reversedLayers = [...layerNames].reverse()

  const nextLayer = reversedLayers.find(layerName => layerLink[layerName])

  return layerLink[nextLayer] || '/'
}

const stillHaveLayers = (destinationPath, backgroundPath, currentLayer) => (
  destinationPath !== backgroundPath && currentLayer !== 'background'
)

export const useCloseLayerLocation = path => {
  const layerNames = useLayerNames()

  const { state } = useLocation()
  const layerLink = get(state, 'layerLink', {})
  const currentLayer = useCurrentLayer()

  return useMemo(() => {
    if (isEmpty(layerLink)) return pathOrPath(path, '/')

    const newLayerLink = removeCurrentLayer(layerLink, currentLayer)
    const nextLayerPath = getNextLayerPath(layerNames, newLayerLink)
    const destinationPath = pathOrPath(path, nextLayerPath)
    const newLocation = makeNewLocation(destinationPath, state, newLayerLink)
    const shouldUseLayers = stillHaveLayers(destinationPath, newLayerLink.background, currentLayer)

    return shouldUseLayers ? newLocation : { pathname: destinationPath }
  }, [currentLayer, layerLink, layerNames, path, state])
}
