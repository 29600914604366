import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'
import 'components/modal-close/index.scss'

export const ModalClose = () => {
  const navigate = useCloseLayerNavigate()

  return <div className={'modal-close d-flex justify-content-end px-0 py-3'}>
    <span onClick={() => navigate()}>
      <FontAwesomeIcon icon={faTimes} />
    </span>
  </div>
}
