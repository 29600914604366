import { useMemo } from 'react'
import { settings } from 'settings/fetching'
import { useLastCursor } from 'campaigns/hooks/use-last-cursor'
import { useCmsCampaignContract } from 'subdomain/cms-content/hooks/use-cms-campaign-contract'

export const useFetchPayload = () => {
  const cursor = useLastCursor()
  const contract = useCmsCampaignContract()

  return useMemo(() => ({
    pagination: { first: settings().listRequestSize, after: cursor },
    filters: { minterAddress: contract },
  }), [contract, cursor])
}
