import { get } from 'lodash'
import { GhostCard } from '../components/ghost-card'
import { CampaignCard } from '../components/campaign-card'

const useComponent = props => {
  const isGhost = get(props, 'isGhost', false)

  if (isGhost) return GhostCard

  return CampaignCard
}

export const Item = ({ campaign }) => {
  const Component = useComponent(campaign)

  return <Component campaign={campaign.node} />
}
