import { statuses } from 'components/marketplaces-carousel/constants/statuses'

export const useValidateItems = items => {
  if (items.length === 0) {
    return statuses.loading
  }

  return statuses.valid
}

