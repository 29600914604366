import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { nft } from 'modals/collection/create/issue/slices/nft'
import { sft } from 'modals/collection/create/issue/slices/sft'

export const useStateCleanup = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(nft.actions.reset({}))
    dispatch(sft.actions.reset({}))
  }, [dispatch])
}
