import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCamera, faArrowLeft, faCube, faHeart,
  faArrowRight, faCubes, faGavel, faPlusCircle, faPen, faPlus, faCheck, faMinus,
  faShare
} from '@fortawesome/pro-light-svg-icons'
import { faHeart as filledHeart } from '@fortawesome/pro-solid-svg-icons'

export const getIcon = icon => {
  const icons = {
    heart: <FontAwesomeIcon icon={faHeart} />,
    pen: <FontAwesomeIcon icon={faPen} />,
    'arrow-right': <FontAwesomeIcon icon={faArrowRight} />,
    cube: <FontAwesomeIcon icon={faCube} />,
    cubes: <FontAwesomeIcon icon={faCubes} />,
    gavel: <FontAwesomeIcon icon={faGavel} />,
    camera: <FontAwesomeIcon icon={faCamera} />,
    add: <FontAwesomeIcon icon={faPlusCircle} />,
    back: <FontAwesomeIcon icon={faArrowLeft} />,
    'heart-filled': <FontAwesomeIcon icon={filledHeart} />,
    plus: <FontAwesomeIcon icon={faPlus} />,
    minus: <FontAwesomeIcon icon={faMinus} />,
    checked: <FontAwesomeIcon icon={faCheck} />,
    share: <FontAwesomeIcon icon={faShare} />,
  }

  return typeof icon === 'string' ? icons[icon] : icon
}
