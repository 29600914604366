import { Title } from 'components/forms/nerds-form/title'
import { faCube } from '@fortawesome/pro-light-svg-icons'
import { useField } from 'formik'

export const Identity = () => {
  const [field] = useField('collection.type')
  const message = field.value === 'SemiFungibleESDT' ? 'SFT' : 'NFT'

  return <Title title={`Create ${message}`} icon={faCube} />
}
