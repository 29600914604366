import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'modals/offers/send/behaviour'
import { Form } from 'modals/offers/send/form'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'

export const Send = () => {
  const { asset, assetOwner } = useAssetWithOwner()

  return <BootstrapModal id={'make-offer'} title={'Make Offer'}>
    <Behaviour />
    {asset ? <Form asset={asset} assetOwner={assetOwner} /> : ''}
  </BootstrapModal>
}
