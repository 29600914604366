import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section'
import { Box } from 'subdomain/home/boxes/box'
import 'subdomain/home/boxes/index.scss'

const columnsMap = {
  one: 12,
  two: 6,
  three: 4,
  four: 3,
}

export const Boxes = ({ data }) => {
  const title = get(data, 'title', '')
  const boxes = get(data, 'boxes', [])
  const columns = get(data, 'columns', 'two')

  return <HomeSection title={title} className={'boxes-section '}>
    <div className={'boxes-section-row row'}>
      {boxes.map((box, key) => <Box key={key} box={box} bootstrapColumns={columnsMap[columns]} />)}
    </div>
  </HomeSection>
}
