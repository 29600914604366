import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field } from 'formik'

const buildClassName = className => `boolean-buttons-field-button flex-grow-1 ${className}`

export const Button = ({ name, label, value, className }) => <div className={buildClassName(className)}>
  <Field type={'radio'} name={name} id={`${name}-${value}`} value={value} />
  <label htmlFor={`${name}-${value}`} className={'d-flex align-items-center'}>
    <span><FontAwesomeIcon icon={faCheck} className={'mb-3 me-2'} /></span>
    {label}
  </label>
</div>
