import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'
import { uniqBy } from 'lodash'

const initialState = {
  loading: false,
  errors: '',
  data: { collections: { edges: [], pageInfo: { hasNextPage: false, endCursor: '' } } },
  cursor: '',
  list: [],
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload.data })

const append = (state, { payload }) => ({ ...state, list: uniqBy([...state.list, ...payload], 'node.collection') })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload.errors) })

const reset = () => initialState

export const collections = createSlice({
  name: 'home-subdomain/collections',
  initialState,
  reducers: {
    attempt,
    success,
    append,
    failure,
    reset,
  },
})

export const { actions } = collections
