import 'nft-page/media/image/index.scss'
import { useEffect, useRef } from 'react'

export const Image = ({ media, onMediaLoaded }) => {
  const source = media.url
  const imgRef = useRef()
  const imgBlurRef = useRef()

  useEffect(() => {
    if (imgRef) {
      imgRef.current.removeAttribute('src')
      imgRef.current.src = source
      imgBlurRef.current.removeAttribute('src')
      imgBlurRef.current.src = source
    }
  }, [source, imgRef])

  return <>
    <img className={'nft-image'} ref={imgRef} onLoad={() => onMediaLoaded()} />
    <img className={'nft-image-blur'} ref={imgBlurRef} />
  </>
}
