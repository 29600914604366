import { Auctions } from 'nft-page/details/other-listings/auctions'
import { LoadMore } from 'nft-page/details/other-listings/load-more'

export const Item = ({ asset, actualAuctions }) => <div className={'nft-page-other-listings'}>
  <p className={'nft-page-other-listings-title'}>Other Listings</p>
  <div className={'nft-page-other-listings-content'}>
    <Auctions auctions={actualAuctions} asset={asset} />
    <LoadMore />
  </div>
</div>
