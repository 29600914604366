import { get } from 'lodash'
import { useEffect } from 'react'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCmsGtm = () => {
  const data = useCmsData()
  const code = get(data, 'data.data.attributes.analytics')

  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    // eslint-disable-next-line prefer-rest-params, max-statements-per-line, no-undef
    function gtag() { dataLayer.push(arguments) }

    if (code) {
      gtag('js', new Date())
      gtag('config', code)
    }
  }, [code])

  return ''
}
