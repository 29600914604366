import { gql } from '@apollo/client'

export const ORDERS = gql`
    query accountOrders($filters: FiltersExpression, $pagination: ConnectionArgs) {
        orders(filters: $filters, pagination: $pagination){
            edges {
                node {
                    id
                    auctionId
                    ownerAddress
                    auction{
                        id
                        identifier
                        asset {
                            name
                            identifier
                            creatorAddress
                            creator{
                                herotag
                                profile
                            }
                            media{
                                fileType
                                thumbnailUrl
                                url
                            }
                            scamInfo{
                                info
                                type
                            }
                            isNsfw
                            verified
                        }
                        topBid {
                            token
                            amount
                            usdAmount
                            tokenData{
                                decimals
                                symbol
                            }
                        }
                        topBidder{
                            address
                        }
                        type
                        endDate
                    }
                    status
                }
                cursor
            }
            pageData {
               count
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`
