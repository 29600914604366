import { useDropzoneContext } from '../context'
import { useIsPreview } from './use-is-preview'

const defaultClassname = 'dropzone d-flex flex-column align-items-center justify-content-center'

const addIfTrue = (classname, condition) => computed => (condition ? `${computed} ${classname}` : computed)

export const useClassName = () => {
  const { isDragActive, loading, disabled, meta } = useDropzoneContext()

  return [defaultClassname]
    .map(addIfTrue('disabled', disabled))
    .map(addIfTrue('loading', loading))
    .map(addIfTrue('dragging', isDragActive))
    .map(addIfTrue('preview', useIsPreview()))
    .map(addIfTrue('invalid', meta.error && meta.touched))
    .pop()
}
