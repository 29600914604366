export const makeOptions = token => {
  const hasToken = token !== '' && token !== 'All'

  const tokenOptions = [
    { value: 'Lowest price', name: 'lowest-price' },
    { value: 'Highest price', name: 'highest-price' },
  ]

  return [
    { value: 'Newest', name: 'newest' },
    { value: 'Oldest', name: 'oldest' },
    ...(hasToken ? tokenOptions : []),
    { value: 'Ending first', name: 'ending-first' },
  ]
}
