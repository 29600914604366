import { difference, get, some } from 'lodash'

const nftRoles = ['ESDTRoleNFTCreate', 'ESDTRoleNFTBurn']
const sftRoles = ['ESDTRoleNFTCreate', 'ESDTRoleNFTBurn', 'ESDTRoleNFTAddQuantity']

const collectionRequiredRoles = collection => {
  const type = get(collection, 'node.type')

  return type === 'NonFungibleESDT' ? nftRoles : sftRoles
}

export const hasRequiredRoles = collection => {
  const roles = get(collection, 'node.roles', [])
  const requiredRoles = collectionRequiredRoles(collection)

  return some(roles, role => difference(role.roles, requiredRoles).length === 0)
}
