import { useEffect } from 'react'
import { failure } from 'failure/slices'
import { useDispatch } from 'react-redux'

export const useStateCleanup = id => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(failure.actions.hide({ id }))
  }, [dispatch, id])
}
