import { useMonitorPlay } from 'components/player-controls/behaviour/hooks/use-monitor-play'
import { useMonitorPause } from 'components/player-controls/behaviour/hooks/use-monitor-pause'
import { useMonitorVolume } from 'components/player-controls/behaviour/hooks/use-monitor-volume'

export const Behaviour = () => {
  useMonitorPlay()
  useMonitorPause()
  useMonitorVolume()

  return ''
}
