import { get } from 'lodash'
import defaultAvatar from 'common/default-avatar'
import { Username } from 'components/username'
import { Action } from 'components/suggestion-card/action'
import { UserImage } from 'components/user-image/index'
import { accountPath } from 'common/account-path'
import { usePurgedHerotag } from 'components/username/hooks/use-purged-herotag'
import { LayeredLink } from 'components/layered-router/layered-link'
import 'components/suggestion-card/style.scss'

export const SuggestionCard = ({ account, isFollowed, onFollow, onUnfollow }) => {
  const purgedHerotag = usePurgedHerotag(account.herotag)
  const path = accountPath(account.address, purgedHerotag && `@${purgedHerotag}`)

  return <div className={'suggestion-card'}>
    <LayeredLink className={'suggestion-card-link'} path={path} layer={'middleground'} />
    <div className={'d-flex align-items-center h-100 w-100'}>
      <UserImage image={get(account.profile, 'url', defaultAvatar)} />
      <div className={'d-flex flex-column suggestion-card-text-container flex-grow-1'}>
        <Username herotag={account.herotag} address={account.address} />
        <span className={'suggestion-card-description'}>
          {account.description}
        </span>
      </div>
      <Action onUnfollow={onUnfollow} onFollow={onFollow} isFollowed={isFollowed} />
    </div>
  </div>
}
