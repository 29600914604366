import { useProps } from './use-props'

import PropTypes from 'prop-types'

export const CenteredContent = ({ children, ...props }) => <div {...useProps(props)}>
  {children}
</div>

CenteredContent.defaultProps = {
  style: {},
  className: '',
}

CenteredContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
}
