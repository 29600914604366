import * as loginServices from '@multiversx/sdk-dapp/hooks/login'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { useInternalAccessToken } from 'network/axios-interceptor/use-internal-access-token'
import { extras } from 'repositories/extras'

export const useWalletLogin = () => {
  extras.getAccessToken()
  const callbackRoute = useCallbackRoute()
  const localToken = useInternalAccessToken()

  const [onInitiateLogin] = loginServices.useCrossWindowLogin({
    callbackRoute,
    nativeAuth: { extraRequestHeaders: { Authorization: `Bearer ${localToken}` } },
    redirectDelayMilliseconds: 100,
  })

  return onInitiateLogin
}
