import { useLocation } from 'react-router-dom'
import { Content } from 'subdomain/components/top-navigation/back-button/content'

export const BackButton = () => {
  const location = useLocation()
  const isCollectionPage = location.pathname !== '/' && location.pathname !== '/logout'

  return <div>
    {isCollectionPage ? <Content /> : ''}
  </div>
}
