import { gql } from 'graphql-tag'

export const GET_EXPLORE_AUCTIONS = gql`
    query exploreAuctions($customFilters: [AuctionCustomFilter],$sorting: [Sorting!], $filters: FiltersExpression, $grouping: Grouping, $pagination: ConnectionArgs) {
        auctions(customFilters:$customFilters,sorting: $sorting, filters: $filters, grouping: $grouping, pagination: $pagination)
        {
            edges {
                node {
                    asset{
                        artist{
                            address
                            profile
                            herotag
                        }
                        identifier
                        name
                        ownerAddress
                        owner{
                            address
                            profile
                            herotag
                        }
                        media{
                            thumbnailUrl
                            fileType
                            url
                        }
                        type
                        scamInfo{
                            info
                            type
                        }
                        isNsfw
                        verified
                    }
                    endDate
                    id
                    identifier
                    marketplaceKey
                    marketplace{
                        iconUrl
                    }
                    maxBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    ownerAddress
                    owner{
                        address
                        profile
                        herotag
                    }
                    status
                    topBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    topBidder{
                        address
                    }
                    type
                }
                cursor
            }
            pageInfo{
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            pageData{
                count
            }
            priceRange{
                maxBid{
                    amount
                    token
                    usdAmount
                    tokenData{
                        decimals
                        symbol
                    }
                }
                minBid{
                    amount
                    token
                    usdAmount
                    tokenData{
                        decimals
                        symbol
                    }
                }
            }
        }
    }
`
