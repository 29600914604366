import { Title } from 'nft-page/header/title'
import { Description } from 'nft-page/header/description'
import { Collection } from 'nft-page/header/collection'
import 'nft-page/header/index.scss'

export const Header = () => <div className={'nft-page-header'}>
  <Title />
  <Collection />
  <Description />
</div>
