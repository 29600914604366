import { schema } from 'components/forms/create-nft-form/schema'
import { collectionSchema } from 'components/forms/create-nft-form/collection-schema'

const getInitialValues = (selectableCollection, initialValues) => {
  const account = schema().cast(initialValues, { assert: false })
  const collection = collectionSchema().cast(initialValues)

  return selectableCollection ? account : collection
}

export const config = ({ onSubmit, initialValues, initialTouched, selectableCollection }) => ({
  initialValues: getInitialValues(selectableCollection, initialValues),
  validationSchema: selectableCollection ? schema() : collectionSchema(),
  validateOnChange: true,
  enableReinitialize: true,
  initialTouched,
  onSubmit,
})
