import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useOnShare } from 'components/share-button/hooks/use-on-share'

export const Twitter = () => {
  const onClick = useOnShare('twitter')

  return <button onClick={onClick} className={'dropdown-item'}>
    <FontAwesomeIcon icon={faTwitter} />
    {' '}
    Share on Twitter
  </button>
}
