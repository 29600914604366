/* eslint-disable max-lines-per-function, complexity */

import { useMemo } from 'react'

export const useHexToRgb = H => useMemo(() => {
  const color = H.indexOf('#') !== -1 ? H.slice(1) : H
  const bigint = parseInt(color, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}, [H])
