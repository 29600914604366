import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'asset-history/slices/list'
import { useAssetHistory } from 'asset-history/hooks/use-asset-history'

export const useAppendHistoryData = () => {
  const dispatch = useDispatch()
  const data = useAssetHistory()

  useEffect(() => {
    dispatch(actions.append(data))
  }, [data, dispatch])
}
