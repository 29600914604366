import { customFilters } from 'filters/collection-filters/auctions/filters-logic/custom-filters'
import { settings } from 'settings/fetching'
import { sorting } from 'filters/collection-filters/auctions/filters-logic/sorting'
import { filters } from 'filters/collection-filters/auctions/filters-logic/filters'
import { marketPlaceFilter } from 'filters/collection-filters/auctions/filters-logic/marketplace-filter'
import { paymentTokenFilter } from 'filters/collection-filters/auctions/filters-logic/token'

/* eslint-disable */
export const buildFilters = ({ min, max, cursor, sort, type, tag, token }, collection, marketplaceKey, dPriceRange) =>
  ({
    customFilters: customFilters({ min, max, sort }, dPriceRange),
    pagination: { first: settings().listRequestSize, after: cursor },
    sorting: sorting(sort),
    grouping: { groupBy: 'IDENTIFIER' },
    filters: {
      operator: 'AND',
      filters: [
        ...filters({ type, tag }),
        { field: 'collection', values: [`${collection}`], op: 'EQ' },
        ...marketPlaceFilter(marketplaceKey),
        ...paymentTokenFilter(token)
      ]
    }
  })
/* eslint-enable */
