import { useOnClick } from 'auction/actions/claim/hooks/use-on-click'
import { FlexRow } from 'components/flex-row'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/details/other-listings/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'

export const ClaimAuction = ({ auction, address }) => {
  const claimAuctionClick = useOnClick(auction)
  const onClick = usePermittedAction(claimAuctionClick, address)

  return <FlexRow>
    <WatchableAction entity={auction} actionType={actionTypes.claim} onClick={onClick} text={'Claim'}
                     className={'btn btn-secondary other-auction-action'} />
  </FlexRow>
}
