import { get } from 'lodash'
import { Bundle } from 'nft-page/auction/bundle'
import { isAuctionTypeNft } from 'common/is-auction-type-nft'

export const SftBundle = ({ auction, assetType }) => {
  const auctionType = get(auction, 'type', '')
  const shouldShow = !isAuctionTypeNft(auctionType) && assetType === 'SemiFungibleESDT'

  return shouldShow ? <Bundle auction={auction} /> : ''
}
