import { get } from 'lodash'
import { useMemo } from 'react'
import { Content } from 'components/profile-card/content'
import { GhostCreatorCard } from 'components/ghost-creator-card'

const useComponent = props => {
  const isGhost = get(props, 'isGhost', false)

  if (isGhost) return GhostCreatorCard

  return Content
}

export const ProfileCard = props => {
  const Component = useComponent(props)

  // eslint-disable-next-line
  return useMemo(() => <Component {...props} />, [props.isGhost])
}
