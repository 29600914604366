import { FlexColumn } from 'components/flex-column'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const Content = ({ token, amount, millyfiedAmount, usdAmount }) => {
  const priceRef = useRef(null)
  const tooltipTitle = `${amount} ${token}`
  useTooltip(priceRef)

  return <FlexColumn>
    <span className={`nft-current-bid-egld-price`} data-bs-toggle={'tooltip'} ref={priceRef} data-bs-placement={'top'}
          title={tooltipTitle}>
      {`${millyfiedAmount}`}
      {' '}
      <span className={'nft-current-bid-egld-token'}>{`${token.split('-')[0]}`}</span>
    </span>
    {usdAmount && <small className={`nft-current-bid-price`}>{`~ ${usdAmount} USD`}</small>}
  </FlexColumn>
}
