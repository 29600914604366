import { Empty as EmptyComponent } from 'components/empty'
import { faCube } from '@fortawesome/pro-light-svg-icons'
import { useList } from 'subdomain/modals/account/my-nfts/hooks/use-list'
import { useIsLoading } from 'subdomain/modals/account/my-nfts/hooks/use-is-loading'

const emptyProps = {
  icon: faCube,
  message: 'No NFTs found.',
}

export const Empty = () => {
  const items = useList()
  const isLoading = useIsLoading()
  const shouldShow = items.length === 0 && !isLoading

  return shouldShow ? <EmptyComponent {...emptyProps} /> : ''
}
