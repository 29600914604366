import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from 'modals/collection/asset/slices/create-nft'

export const useStateCleanup = () => {
  const dispatch = useDispatch()

  return useEffect(() => () => {
    dispatch(actions.reset({}))
  }, [dispatch])
}
