import { useCurrentLayer } from 'components/layered-router/hooks/use-current-layer'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useOnClick = (baseUrl, omitFirstTabUrl) => {
  const navigate = useNavigate()
  const layer = useCurrentLayer()
  const layeredLocation = useLayeredLocation()

  return useCallback((index, tab) => {
    const theTab = (index === 0 && omitFirstTabUrl) ? '' : `/${tab}`
    const { pathname, state } = layeredLocation(`${baseUrl}${theTab}`, layer)

    navigate(pathname, { state, replace: true })
  }, [baseUrl, layer, layeredLocation, omitFirstTabUrl, navigate])
}
