import { get } from 'lodash'
import { Loading } from 'loading'
import { useSelector } from 'react-redux'

export const selector = state => get(state, 'account.tabs.creations.data', [])

export const useItems = () => {
  const items = useSelector(selector)
  const ghosts = Loading.usePayloads(Loading.ID.account.tab.creations, Loading.VARIANT.ghostCard)

  return [...items, ...ghosts]
}
