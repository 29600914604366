import { useLatestTimestampPoolingInterval } from 'feed/refresh/hooks/use-latest-timestamp-pooling-interval'
import { useGetLatestTimestamp } from 'feed/refresh/hooks/use-get-latest-timestamp'
import { useEffect } from 'react'

export const useLatestTimestampPooling = () => {
  const interval = useLatestTimestampPoolingInterval()
  const getLatestTimestamp = useGetLatestTimestamp()

  useEffect(() => {
    const pool = setInterval(() => {
      getLatestTimestamp()
    }, interval)

    return () => clearInterval(pool)
  }, [getLatestTimestamp, interval])
}
