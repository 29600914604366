import { useCallback } from 'react'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'
import match from '@nerds.sh/js-pattern'

export const useLayeredPath = () => {
  const makeLocation = useLayeredLocation()

  return useCallback(element => {
    let assetPath = `/nfts/${element.identifier}`

    if (element.lowestAuction) {
      assetPath = `/nfts/${element.identifier}/auction/${element.lowestAuction?.id}`
    }

    const auctionPath = `/nfts/${element.identifier}/auction/${element.id}`
    const orderPath = `/nfts/${element.auction?.identifier}/auction/${element.auction?.id}`

    return match(element)
      .like({ __typename: 'Auction' }, () => makeLocation(auctionPath, 'foreground'))
      .like({ __typename: 'Order' }, () => makeLocation(orderPath, 'foreground'))
      .otherwise(() => makeLocation(assetPath, 'foreground'))
  }, [makeLocation])
}

