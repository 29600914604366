import { useRef } from 'react'
import { useVisibleItem } from 'components/asset-card/time-left/hooks/use-visible-item'

export const Content = ({ label, suffix, type }) => {
  const itemRef = useRef(null)
  useVisibleItem(itemRef)

  return <div className={`asset-card-badge badge-${type.className}`} ref={itemRef}>
    <div className={'asset-card-badge-inner'}>
      <span className={'asset-card-badge-label'}>{label}</span>
      {suffix && <span className={'asset-card-badge-suffix'}>{suffix}</span>}
    </div>
  </div>
}

