import { useDispatch } from 'react-redux'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useParams } from 'react-router-dom'
import { useContext } from 'nft-page/mutate-like/context'
import { useCallback } from 'react'
import { remove } from 'nft-page/likes/slices'

export const useRemoveLike = () => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()
  const { identifier } = useParams()
  const { setIsLiked } = useContext()

  return useCallback(() => {
    setIsLiked(false)
    dispatch(remove.actions.attempt({ input: { identifier }, address }))
  }, [setIsLiked, dispatch, identifier, address])
}
