import { Button } from 'components/button'
import PropTypes from 'prop-types'
import 'components/follow-button/style.scss'

const useProps = ({ onClick }) => ({
  className: 'follow-button btn-primary',
  icon: 'plus',
  onClick,
})

export const FollowButton = props => <Button {...useProps(props)}>
  Follow
</Button>

FollowButton.propTypes = { onClick: PropTypes.func.isRequired }
