import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../slices'
import { useIsPrinceCharming } from 'auth/mega-authorized'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'

export const useFetchPreferences = () => {
  const dispatch = useDispatch()
  const isPrinceCharming = useIsPrinceCharming()
  const isSubdomain = useIsSubdomain()

  useEffect(() => {
    if (isPrinceCharming && !isSubdomain) {
      dispatch(actions.load({}))
    }
  }, [dispatch, isPrinceCharming, isSubdomain])
}
