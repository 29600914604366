import 'video.js/dist/video-js.css'
import { Content } from 'components/player/content'
import { Behaviour } from 'components/player/behaviour'
import { Context, useValue } from 'components/player/context'
import { defaultProps, propTypes } from 'components/player/properties'

export const Player = ({ sources, onMediaLoaded, ...rest }) => <Context.Provider value={useValue({ sources, ...rest })}>
  <Content onMediaLoaded={onMediaLoaded} />
  <Behaviour />
</Context.Provider>

Player.defaultProps = defaultProps

Player.propTypes = propTypes
