import { useEffect } from 'react'
import { useContext } from 'components/player-controls/context'

export const useMonitorVolume = () => {
  const { player, muted, setMuted } = useContext()

  useEffect(() => {
    if (!player?.current) return

    const playerInstance = player.current
    const onVolumeChanged = () => setMuted(!muted)
    playerInstance.on('volumechange', onVolumeChanged)

    return () => playerInstance.off('volumechange', onVolumeChanged)
  }, [player, setMuted, muted])
}
