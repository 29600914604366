import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { actions } from 'my-tokens/slices/get'

export const useFetchMyTokens = () => {
  const { address } = useGetAccountInfo()
  const dispatch = useDispatch()

  useEffect(() => {
    address && dispatch(actions.attempt({ address }))
  }, [address, dispatch])
}
