import { useResetList } from 'account/tabs/assets/bids/hooks/use-reset-list'
import { useNext } from 'account/tabs/assets/bids/hooks/use-next'
import { useCallback } from 'react'
import { useIsNftPageOpened } from 'common/hooks/use-is-nft-page-opened'
import { useFetchNotifications } from '../../../../../../notifications/account/hooks/use-fetch-notifications'

export const useOnChange = () => {
  const isNftPageOpened = useIsNftPageOpened()
  const getInitialPage = useNext('')
  const resetList = useResetList()
  const fetchNotifications = useFetchNotifications()

  return useCallback(() => {
    if (isNftPageOpened) {
      fetchNotifications()
      getInitialPage()
      resetList()
    }
  }, [isNftPageOpened, getInitialPage, resetList, fetchNotifications])
}
