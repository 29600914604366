import { from } from 'rxjs'
import { history } from 'network'
import { ofType } from 'redux-observable'
import { orders } from 'repositories/orders'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { actions } from 'nft-page/auction/bids/slices/orders'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = action => orders.auctionOrders(action.payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  actions.append(payload.data.orders.edges),
])

const failure = errors => from([
  actions.failure(errors),
  failureSlice.actions.show({ route: history.location.pathname }),
])
