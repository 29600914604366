import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { faTag } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { TagsButtons } from 'nft-page/details/other-details/tags/tags-buttons'
import 'nft-page/details/other-details/tags/index.scss'

export const Tags = () => {
  const asset = useNftSelector()
  const tags = get(asset, 'tags', '')

  return <KeyPair icon={<FontAwesomeIcon icon={faTag} />} title={'Tags'}>
    {tags ? <TagsButtons tags={tags} /> : 'N/A'}
  </KeyPair>
}
