import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'notifications/account/slices'
import { usePayload } from './use-payload'
import { useCmsMarketplaceKey } from '../../../subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useFetchNotifications = () => {
  const dispatch = useDispatch()
  const marketplaceKey = useCmsMarketplaceKey()
  const payload = usePayload({ marketplaceKey })

  return useCallback(() => dispatch(actions.attempt(payload)), [dispatch, payload])
}
