import { gql } from 'graphql-tag'

export const END_AUCTION = gql`
    mutation EndAuction($auctionId: Int!) {
      endAuction(auctionId: $auctionId)
  {
    chainID
    data
    gasLimit
    gasPrice
    nonce
    receiver
    sender
    value
    version
  }
 }
`
