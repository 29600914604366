import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { useTooltip } from 'common/hooks/use-tooltip'
import { useRef } from 'react'

export const Warning = () => {
  const ref = useRef(null)
  useTooltip(ref)

  return <div ref={ref} className={'nft-page-key-pair-warning'} data-bs-toggle={'tooltip'}
              data-bs-placement={'top'} title={'Hosting not whitelisted'}>
    <FontAwesomeIcon icon={faWarning} />
  </div>
}
