import './index.scss'
import { BackButton } from 'subdomain/components/top-navigation/back-button'
import { useIsNftWhitelisted } from 'startup/hooks/use-is-nft-whitelisted'
import { useIsAssetNotFound } from 'nft-page/existing/hooks/use-is-asset-not-found'
import { Connect as AccountConnect } from 'components/top-navigation/components/connect'
import { AccountNotifications } from 'notifications/account'
import { Connect as SimpleConnect } from 'subdomain/components/top-navigation/connect'

const className = 'navbar navbar-expand-md justify-content-start navbar-light navigation'

const makeNotFoundClass = (isWhitelisted, assetNotFound) => !isWhitelisted && assetNotFound ? 'not-found' : ''

export const TopNavigation = ({ hideConnect, hideAccountSidebar }) => {
  const isWhitelisted = useIsNftWhitelisted()
  const assetNotFound = useIsAssetNotFound()
  const notFoundClass = makeNotFoundClass(isWhitelisted, assetNotFound)

  return <div className={`top-navigation-wrapper subdomain ${notFoundClass}`}>
    <nav className={className}>
      <div className={'container-fluid'}>
        <div className={'d-flex justify-content-between align-items-center w-100'}>
          <BackButton />
          <div className={'d-flex'}>
            {!hideAccountSidebar && <AccountNotifications />}
            {!hideAccountSidebar ? <AccountConnect hideConnect={hideConnect} /> : <SimpleConnect />}
          </div>
        </div>
      </div>
    </nav>
  </div>
}
