import { gql } from 'graphql-tag'

export const PLACE_BID = gql`
    mutation Bid($input: BidActionArgs!) {
        bid(input: $input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
