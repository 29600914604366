import { hasRequiredRoles } from './has-required-roles'
import { SetRolesButton } from './set-roles'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { CreateNft } from 'collections/actions/create-nft'
import { useHasRoles } from 'collections/actions/link/hooks/use-has-roles'

const useCanCreate = collection => {
  const hasRoles = useHasRoles(collection)
  const isSubdomain = useIsSubdomain()

  return !isSubdomain && hasRoles
}

export const ActionSelector = ({ collection, address }) => {
  const hasRequired = hasRequiredRoles(collection, address)
  const canCreate = useCanCreate(collection)

  if (!hasRequired) {
    return <SetRolesButton collection={collection} />
  } else {
    return canCreate ? <CreateNft collection={collection} /> : ''
  }
}

