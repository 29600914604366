import { gql } from 'graphql-tag'

export const COLLECTIONS = gql`
query collections($filters: CollectionsFilter, $pagination: ConnectionArgs, $input: CollectionAssetsRetriveCount, $sorting: CollectionsSortingEnum) {
  collections(filters: $filters, pagination: $pagination, sorting: $sorting) {
    edges {
      node {
        collection
        name
        type
        canCreate
        canBurn
        canWipe
        canPause
        canAddQuantity
        canTransferRole
        ownerAddress
        description  
        nftsCount
        owner {
            address
            herotag
            profile
        }
        roles {
            address
            roles
            canCreate
        }
        traits {
            name
            values{
                occurrences
                value
            }
        }  
        collectionAsset {
            totalCount
            assets (input: $input) {
                identifier
                thumbnailUrl
                isNsfw
                scamInfo {
                    info
                    type
                }
            }
        }
        verified
      }
    }
    pageData {
      count 
      offset
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    } 
  }
}
`
