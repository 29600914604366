import { useCallback } from 'react'
import { useFetchCallback } from 'holoride-campaign/hooks/use-fetch-callback'

export const useOnChange = () => {
  const fetch = useFetchCallback()

  return useCallback(() => {
    fetch()
  }, [fetch])
}
