import { gql } from 'graphql-tag'

export const WITHDRAW = gql`
    mutation withdrawOffer ($offerId: Int!) {
        withdrawOffer(offerId: $offerId){
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
