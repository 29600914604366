import { useOnClick } from 'auction/actions/withdraw/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { CurrentBid } from 'nft-page/auction/current-bid'
import 'nft-page/auction/actions/sft-withdraw/index.scss'
import { WatchableAction } from '../watchable-action'
import { actionTypes } from 'action-statuses/actions-types'

export const SftWithdraw = ({ auction, address }) => {
  const sftWithdrawClick = useOnClick(auction)
  const onClick = usePermittedAction(sftWithdrawClick, address)

  return <div className={'nft-page-auction-inner'}>
    <p className={'sft-withdraw-auction-text'}>
      Current auction has reached the deadline or the maximum bid
      price.
    </p>
    <CurrentBid auction={auction} />
    <WatchableAction entity={auction} actionType={actionTypes.sftWithdraw} text={'Withdraw'} onClick={onClick} />
  </div>
}

SftWithdraw.displayName = 'SftWithdraw'
