import React from 'react'
import { get } from 'lodash'
import { useOnNftClick } from 'collections/actions/create-nft/hooks/use-on-nft-click'
import { useHasRoles } from 'collections/actions/link/hooks/use-has-roles'

const makeAction = (onClick, title) => ({ onClick, title })

const useAction = collection => {
  const isSft = get(collection, 'node.type', '') === 'SemiFungibleESDT'
  const onNftClick = useOnNftClick()

  return isSft ? makeAction(onNftClick, 'Create SFT') : makeAction(onNftClick, 'Create NFT')
}

export const Asset = ({ collection }) => {
  const { onClick, title } = useAction(collection)
  const hasRoles = useHasRoles(collection)

  return hasRoles && <a onClick={onClick} className={'action-link'} role={'button'}>
    {title}
  </a>
}

