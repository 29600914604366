import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useRetryCallback = () => {
  const navigate = useNavigate()

  return useCallback(() => {
    navigate(0)
  }, [navigate])
}
