import { graph, rest } from 'network'
import { settings } from 'settings/fetching'
import { STATS } from 'repositories/accounts/queries/stats'
import { social } from 'repositories/accounts/social'
import { EXPLORE_CREATORS } from 'repositories/accounts/queries/artists'
import { getIsFollowed } from 'repositories/accounts/get-is-followed'
import { mergeMap } from 'rxjs/operators'

const byUsername = payload => rest().get(`${settings().apiAddress}/usernames/${payload}`)
const byAddress = payload => rest().get(`${settings().apiAddress}/accounts/${payload}`)
const getStats = payload => graph().query({ query: STATS, variables: payload })

const byIdApiUsername = payload => rest().get(`${settings().idApi}/api/v1/herotags/${payload}/address`, {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const byIdApiAddress = payload => rest().get(`${settings().idApi}/api/v1/users/${payload}`)

const getArtists = ({ isLoggedIn, ownAddress, ...payload }) => graph().query({ query: EXPLORE_CREATORS, variables: payload })
  .pipe(mergeMap(getIsFollowed(isLoggedIn, ownAddress)))

const paymentTokens = ({ address }) => rest().get(`${settings().apiAddress}/accounts/${address}/tokens`)

export const accounts = { byUsername, byAddress, getStats, byIdApiUsername, byIdApiAddress, social, getArtists, paymentTokens }

