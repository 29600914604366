import { ReactComponent as LeftIcon } from 'startup/assets/left-icon.svg'
import { ReactComponent as CloseIcon } from 'startup/assets/close-icon.svg'
import { ReactComponent as RightIcon } from 'startup/assets/right-icon.svg'
import { ReactComponent as SettingsIcon } from 'startup/assets/settings-icon.svg'

export const getIcon = icon => {
  const icons = {
    close: <CloseIcon />,
    settings: <SettingsIcon />,
    left: <LeftIcon />,
    right: <RightIcon />,
  }

  return typeof icon === 'string' ? icons[icon] : icon
}
