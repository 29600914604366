import { get } from 'lodash'
import { Countdown } from 'holoride-campaign/action/buy/countdown/index'

export const Counter = ({ primarySale }) => {
  const endSale = get(primarySale, 'saleTime.endSale')

  return <div className={'d-flex align-items-center flex-column mb-3'}>
    <span className={'holoride-campaign-buy-label'}>Sale ends in: </span>
    <Countdown endDate={endSale} />
  </div>
}
