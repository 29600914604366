import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useOffersPageInfo } from 'offers/hooks/use-offers-page-info'
import { get as slice } from 'offers/slices'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { get } from 'lodash'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useOffersLoadMore = () => {
  const dispatch = useDispatch()
  const { endCursor } = useOffersPageInfo()
  const { identifier } = useParams()
  const acceptedToken = useFirstAcceptedPaymentToken()
  const priceToken = get(acceptedToken, 'identifier')
  const marketplaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    const status = ['Active', 'Expired']
    dispatch(slice.actions.attempt({
      filters: { identifier, priceToken, status, marketplaceKey },
      pagination: { first: 3, after: endCursor },
    }))
  }, [dispatch, endCursor, identifier, marketplaceKey, priceToken])
}
