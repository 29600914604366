import { Component } from 'failure/component'
import { Container } from 'components/container'
import { Behaviour } from 'failure/absolute/behaviour'

export const Content = () => <>
  <Behaviour />
  <Container className={'d-flex flex-grow-1'}>
    <Component />
  </Container>
</>
