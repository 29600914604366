import { makeNotification } from 'notifications/account/make-notification'
import { LayeredLink } from 'components/layered-router/layered-link'

const isAsset = ({ type }) => type === 'Ended' || type === 'Bought' || type === 'Sold'

const isOffer = ({ type }) => type === 'OfferReceived' || type === 'OfferExpired'

export const Item = ({ identifier, name, auctionId, type }) => {
  const assetDestination = `/nfts/${identifier}`
  const auctionDestination = `/nfts/${identifier}/auction/${auctionId}`
  const destination = isAsset(type) || !isOffer(type) ? assetDestination : auctionDestination

  return <LayeredLink path={destination} layer={'foreground'}
                      className={`account-notifications-item dropdown-item ${type.toLowerCase()}`}>
    {makeNotification(name, type)}
  </LayeredLink>
}
