import { useSearchParam } from 'search/hooks/use-search-param'
import { useEffect } from 'react'
import { useRunSearch } from 'search/hooks/use-run-search'

export const useSearchFromParams = () => {
  const searchTerm = useSearchParam()
  const runSearch = useRunSearch()

  useEffect(() => runSearch(searchTerm), [searchTerm, runSearch])
}
