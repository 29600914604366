import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from 'account/tabs/assets/collected/slices/list'

export const useResetList = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.reset())
  }, [dispatch])
}
