import { get } from 'lodash'
import { settings } from 'settings/fetching'
import millify from 'millify'
import { denominateAmount } from 'common/conversion/denominate-amount'

const egldLabel = () => settings().dapp.egldLabel

const decimals = () => settings().dapp.decimals

const getData = price => {
  const tokenDataDecimals = get(price, 'tokenData.decimals', 18)
  const tokenSymbol = get(price, 'tokenData.symbol', egldLabel())
  const amount = get(price, 'amount', '')
  const usdAmount = get(price, 'usdAmount')

  return { amount, usdAmount, tokenDataDecimals, tokenSymbol }
}

const getHumanizedValue = (fullAmount, amountWithCommas) => {
  const parsedAmount = parseFloat(fullAmount)
  const minimumValue = 1 / (Math.pow(10, decimals()))

  return parsedAmount < minimumValue ? `< ${minimumValue}` : amountWithCommas
}

const getMillifiedValue = value => {
  const parsedValue = parseFloat(value)
  const isLargeNumber = parseInt(value) >= Number.MAX_SAFE_INTEGER

  return isLargeNumber ? '1m+' : millify(parsedValue, { precision: decimals(), lowercase: true })
}

const getValue = (fullAmount, amountWithCommas) => {
  const parsedValue = parseFloat(fullAmount)

  if (isNaN(parsedValue)) return '...'

  return parsedValue <= 10000 ? getHumanizedValue(fullAmount, amountWithCommas) : getMillifiedValue(fullAmount)
}

const getUsdValue = usdAmount => {
  const parsedUsdAmount = parseFloat(usdAmount).toFixed(decimals())

  return usdAmount && usdAmount !== '0' ? getValue(usdAmount, parsedUsdAmount) : null
}

export const getPriceDetails = price => {
  const { amount, usdAmount, tokenDataDecimals, tokenSymbol } = getData(price)
  const fullAmount = denominateAmount(amount, tokenDataDecimals)
  const amountWithCommas = denominateAmount(amount, tokenDataDecimals, true)

  return {
    token: tokenSymbol,
    amount: fullAmount,
    millyfiedAmount: getValue(fullAmount, amountWithCommas),
    usdAmount: getUsdValue(usdAmount),
  }
}
