import { useDispatch } from 'react-redux'
import { useCurrentPage } from 'modals/account/followees/hooks/use-current-page'
import { get } from 'modals/account/followees/slices'
import { useCallback } from 'react'

export const useNext = () => {
  const dispatch = useDispatch()
  const currentPage = useCurrentPage()

  return useCallback(() => {
    dispatch(get.actions.attempt({ page: currentPage + 1 }))
  }, [dispatch, currentPage])
}
