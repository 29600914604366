import { PriceRangeToken } from 'filters/components/price-range-token'
import SwiperCore, { FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Type } from 'filters/components/type'
import { SortBy } from 'filters/components/sort-by'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { makeOptions } from 'filters/components/sort-by/make-options'

const defaultTypeOptions = [
  { value: 'All', name: '' },
  { value: 'Single', name: 'nft' },
  { value: 'Bundles', name: 'sft-all' },
  { value: 'Variable', name: 'sft-one-per-payment' },
]

SwiperCore.use([FreeMode])

export const Filters = ({ typeOptions = defaultTypeOptions, paymentTokens, minBid, maxBid, actualCount }) => {
  const token = useFilterValue('token')
  const options = makeOptions(token)

  return <div className={'auctions-filters'}>
    <PriceRangeToken maxBid={maxBid} minBid={minBid} paymentTokens={paymentTokens} resetOthers={['sort']}
                     actualCount={actualCount} />
    <div className={'auctions-filters-sorting'}>
      <Swiper freeMode slidesPerView={'auto'} spaceBetween={20}>
        <SwiperSlide><Type options={typeOptions} /></SwiperSlide>
        <SwiperSlide><SortBy options={options} /></SwiperSlide>
      </Swiper>
    </div>
  </div>
}
