import { useDropzone } from 'react-dropzone'

import { useDropzoneParams } from './use-dropzone-params'

export const useDropzoneInit = props => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone(useDropzoneParams(props))

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  }
}
