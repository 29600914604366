import { auth } from 'network/graph/auth'
import { mergeMap } from 'rxjs/operators'
import { from, of, throwError } from 'rxjs'

const chooseFlow = result => result.errors ? throwError(result) : of(result)

const authorizedQuery = client => payload => auth.makePayload(payload)
  .pipe(mergeMap(payload => from(client.query(payload))))
  .pipe(mergeMap(chooseFlow))

const nonAuthorizedQuery = client => payload => of(payload)
  .pipe(mergeMap(payload => from(client.query(payload))))
  .pipe(mergeMap(chooseFlow))

export const query = client => (payload, authorize = false) => {
  if (authorize) return authorizedQuery(client)(payload)

  return nonAuthorizedQuery(client)(payload)
}
