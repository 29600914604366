import { graph } from 'network'
import { COLLECTIONS, COUNT, STATS, HERO_COLLECTIONS, FEATURED_COLLECTIONS } from 'repositories/nft-collections/queries'
import { ISSUE_NFT_COLLECTION } from 'repositories/nft-collections/mutations/issue-nft-collection'
import { SET_COLLECTION_ROLES } from 'repositories/nft-collections/mutations/set-collection-roles'
import { TRENDING_COLLECTIONS } from 'repositories/nft-collections/queries/trending-collections'
import { EXPLORE_COLLECTIONS } from 'repositories/nft-collections/queries/explore-collections'
import { ACCOUNT_COLLECTIONS_FILTERS } from 'repositories/nft-collections/queries/account-collections-filters'

export const nftCollections = {
  setRoles: input => graph().mutate({ mutation: SET_COLLECTION_ROLES, variables: { input } }),
  get: input => graph().query({ query: COLLECTIONS, variables: input }),
  getAccountCollectionsFilters: input => graph().query({ query: ACCOUNT_COLLECTIONS_FILTERS, variables: input }),
  getExploreCollections: input => graph().query({ query: EXPLORE_COLLECTIONS, variables: input }),
  stats: input => graph().query({ query: STATS, variables: input }),
  issueNftCollection: payload => graph().mutate({ mutation: ISSUE_NFT_COLLECTION, variables: { input: payload } }),
  count: variables => graph().query({ query: COUNT, variables }),
  heroCollections: variables => graph().query({ query: HERO_COLLECTIONS, variables }),
  featuredCollections: variables => graph().query({ query: FEATURED_COLLECTIONS, variables }),
  trendingCollections: variables => graph().query({ query: TRENDING_COLLECTIONS, variables }),
}
