import { combineEpics } from 'redux-observable'

import { exploreEpics } from 'home/explore-nfts/epics'
import { trending } from 'home/trending/epics'
import { featured } from 'home/featured/epics'
import { campaigns } from 'home/campaigns/epics/campaigns'
import { trendingCollections } from 'home/trending-collections/epics'
import { featuredCollections } from 'home/featured-collections/epics'

export const home = combineEpics(exploreEpics, trending, featured, campaigns, trendingCollections, featuredCollections)
