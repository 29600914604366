import { combineReducers } from 'redux'
import { tag } from 'trending/slices/tag'
import { cursor } from 'trending/slices/cursor'
import { list } from 'trending/slices/list'
import { trending as get } from 'trending/slices/trending'

export const trending = combineReducers({
  tag: tag.reducer,
  cursor: cursor.reducer,
  list: list.reducer,
  trending: get.reducer,
})
