import { BN } from 'bn.js'
import { get } from 'lodash'

export const useIsEqual = (minBid, maxBid) => {
  const min = get(minBid, 'amount', '')
  const max = get(maxBid, 'amount', '')

  return new BN(String(min)).eq(new BN(String(max)))
}

