import PropTypes from 'prop-types'
import { useValidateItems } from 'components/marketplaces-carousel/hooks/use-validate-items'
import { usePickContent } from 'components/marketplaces-carousel/hooks/use-pick-content'
import './index.scss'

export const MarketplacesCarousel = ({ items }) => {
  const status = useValidateItems(items)
  const Content = usePickContent(status)

  return <Content status={status} items={items} />
}

MarketplacesCarousel.propTypes = { items: PropTypes.array.isRequired }
