import { useDispatch } from 'react-redux'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useCallback } from 'react'
import { accept } from 'offers/slices'
import { useSelectedOffer } from 'nft-page/offers/hooks/use-selected-offer'
import { get } from 'lodash'

export const useAcceptOffer = () => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()
  const offer = useSelectedOffer()
  const offerId = parseInt(get(offer, 'id'))

  return useCallback(payload => {
    const input = payload.auctionId ? { offerId, auctionId: parseInt(payload.auctionId) } : { offerId }
    address && dispatch(accept.actions.attempt({ input }))
  }, [address, dispatch, offerId])
}
