import { createContext, useState } from 'react'

export const MultiRangeContext = createContext({})

export const useValue = ({ onChange, initialMin, initialMax }) => {
  const [minVal, setMinVal] = useState(initialMin)
  const [maxVal, setMaxVal] = useState(initialMax)

  return { onChange, minVal, maxVal, setMaxVal, setMinVal }
}
