import { useState } from 'react'
import { useOnFollow, useOnUnfollow } from 'feed/suggestions/hooks'
import { SuggestionCard } from 'components/suggestion-card'

export const Item = ({ account }) => {
  const [followed, setFollowed] = useState(false)
  const onFollow = useOnFollow(setFollowed, account)
  const onUnfollow = useOnUnfollow(setFollowed, account)

  return <SuggestionCard account={account} onFollow={onFollow} isFollowed={followed} onUnfollow={onUnfollow} />
}
