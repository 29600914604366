import { useFetchTrending } from 'trending/hooks/use-fetch-trending'
import { useAppendData } from 'trending/hooks/use-append-data'
import { useResetList } from 'trending/hooks/use-reset-list'
import { useFetchAuctionTags } from 'tags/hooks/use-fetch-auction-tags'

export const Behaviour = () => {
  useFetchTrending()
  useAppendData()
  useFetchAuctionTags()
  useResetList()

  return ''
}
