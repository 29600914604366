import { get } from 'lodash'
import { GhostCard } from 'components/ghost-card'
import { Content } from 'subdomain/components/my-asset-card/content'
import { useMemo } from 'react'

const useComponent = props => {
  const isGhost = get(props, 'isGhost', false)

  if (isGhost) return GhostCard

  return Content
}

export const MyAssetCard = props => {
  const Component = useComponent(props)

  // eslint-disable-next-line
  return useMemo(() => <Component {...props} />, [props.auctionId, props.isGhost])
}
