import { Award } from 'subdomain/home/awards/award'
import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section/index'

export const Awards = ({ data }) => {
  const awards = get(data, 'awards', [])
  const title = get(data, 'title', '')

  return <HomeSection title={title}>
    <div className={'row justify-content-center'}>
      {awards.map((item, index) => <Award award={item} key={index} />)}
    </div>
  </HomeSection>
}
