import { useSelector } from 'react-redux'
import { get } from 'lodash'

const listPath = 'feed.get.list.length'

const hasMorePath = 'feed.get.data.hasMore'

const suggestionsLoadingPath = 'feed.suggestions.get.loading'

export const useIsLoading = () => {
  const listEmpty = useSelector(state => !get(state, listPath, 0))
  const hasMoreItems = useSelector(state => get(state, hasMorePath, true))
  const suggestionsLoading = useSelector(state => get(state, suggestionsLoadingPath, true))

  return suggestionsLoading || (listEmpty && hasMoreItems)
}
