import 'components/surfaces/background/index.scss'
import { useIsNftView } from 'startup/hooks/use-is-nft-view'

export const Background = ({ layer, children }) => {
  const blurredClass = useIsNftView() ? 'blurred' : ''

  return <div className={`background-surface ${blurredClass}`} style={style(layer)}>
    {children}
  </div>
}

const style = layer => ({ zIndex: layer.zIndex })
