import { useField } from 'formik'
import { Input } from 'components/forms/nerds-form/fields/percent-field/input'
import { Label } from 'components/forms/nerds-form/fields/label'

export const PercentField = ({ name, label }) => {
  const [, meta] = useField(name)

  return <div className={'nerds-form-field'}>
    <div className={'percent-field'}>
      <Label className={'form-label'} name={name}>{label}</Label>
      <Input name={name} />
      {meta.touched && meta.error && <div className={'text-danger'}>{meta.error}</div>}
    </div>
  </div>
}
