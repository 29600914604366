import { useFeaturedCollections } from 'home/featured-collections/hooks/use-featured-collections'
import { AddRemoveFeaturedCollectionForm } from 'components/forms/add-remove-featured-collection-form'
import { useAddFeaturedCollection } from 'admin/hooks/actions/use-add-featured-collection'

const makeInitialValues = featuredCollections => ({ collection: '', featuredCollections })

export const Form = () => {
  const featuredCollections = useFeaturedCollections()
  const onSubmit = useAddFeaturedCollection()

  return <AddRemoveFeaturedCollectionForm initialValues={makeInitialValues(featuredCollections)} submitErrors={[]}
                                          featuredCollections={featuredCollections} onSubmit={onSubmit} />
}
