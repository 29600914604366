import { get } from 'lodash'
import { useOwnerHeroTag } from 'nft-page/details/other-details/owners/hooks/use-owner-hero-tag'
import defaultAvatar from 'common/default-avatar'

export const useActualNftOwner = owner => {
  const address = get(owner, 'address', '')
  const profileImage = get(owner, 'profile', defaultAvatar) || defaultAvatar

  return { herotag: useOwnerHeroTag(owner), address, profileImage }
}
