import { useMemo } from 'react'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { get } from 'lodash'
import { useActionStatus } from 'action-statuses/hooks/use-action-status'

export const useStatus = (entity, actionType) => {
  const identifiers = useMemo(() => [createActionIdentifier(entity, actionType)], [actionType, entity])

  return get(useActionStatus(identifiers), '[0].status', '')
}
