import { useDispatch } from 'react-redux'
import { actions } from 'marketplaces/slices/get'
import { useEffect } from 'react'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useFetchMarketplace = () => {
  const dispatch = useDispatch()
  const currentMarketplaceKey = useCmsMarketplaceKey()

  useEffect(() => {
    if (currentMarketplaceKey) {
      dispatch(actions.attempt({ filters: { marketplaceKey: currentMarketplaceKey } }))
    }
  }, [currentMarketplaceKey, dispatch])
}
