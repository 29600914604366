import { Link } from 'components/collection-card/link'
import { Images } from 'components/collection-card/images'
import { Details } from 'components/collection-card/details'
import { get, isEmpty } from 'lodash'
import { Behaviour } from 'components/collection-card/behaviour'
import { getThumbs } from 'components/collection-card/hooks/get-thumbs'
import { useMouseHandlers } from 'components/collection-card/hooks/use-mouse-handlers'
import { Empty } from 'components/collection-card/empty/index'
import 'components/collection-card/index.scss'

export const Content = props => {
  const isSft = get(props.collection, 'type', '') === 'SemiFungibleESDT'
  const thumbs = getThumbs(props.collection)
  const sftClass = isSft ? 'is-sft' : ''

  return <div className={`collection-card card ${sftClass} ${props.className}`} {...useMouseHandlers()}>
    <Behaviour thumbs={thumbs} running={props.running} />
    <Link collection={props.collection} openNewLayer={props.openNewLayer} />
    {isEmpty(thumbs) ? <Empty /> : <Images thumbs={thumbs} />}
    <Details collection={props.collection} />
  </div>
}

