import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useIsNftWhitelisted } from 'startup/hooks/use-is-nft-whitelisted'
import { useCallback } from 'react'
import { actions } from 'nft-page/slices/nft'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useFetchSubdomainNft = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()
  const isWhitelisted = useIsNftWhitelisted()
  const marketplaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    isWhitelisted && dispatch(actions.attempt({
      filters: { identifier },
      marketplaceFilters: { marketplaceKey },
    }))
  }, [dispatch, identifier, isWhitelisted, marketplaceKey])
}
