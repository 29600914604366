import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { get as slice } from 'feed/refresh/slices'

export const useGetLatestTimestamp = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(slice.actions.attempt({}))
  }, [dispatch])
}
