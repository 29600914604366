import { useActualItems } from 'account/tabs/assets/creations/hooks/use-actual-items'
import { useNext } from 'account/tabs/assets/creations/hooks/use-next'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { isEmpty } from 'lodash'

import { useEffect } from 'react'

export const useGetInitialPage = () => {
  const getInitialPage = useNext('')
  const collectionFilter = useFilterValue('collection')
  const items = useActualItems()
  const noItems = isEmpty(items)

  return useEffect(() => {
    if (noItems) {
      getInitialPage()
    }
  }, [getInitialPage, noItems, collectionFilter])
}
