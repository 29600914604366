import { ofType } from 'redux-observable'
import { follow as followSlice } from 'account/profile/follow/slices/follow'
import { followers as followersCount } from 'account/profile/follow/slices/followers'
import { follows } from 'account/profile/follow/slices/follows'
import { followers } from 'repositories/followers'
import { get as getFeed } from 'feed/slices'
import { get as getSuggestions } from 'feed/suggestions/slices'
import { get as latestTimestamp } from 'feed/refresh/slices'
import { from, of, catchError, mergeMap } from 'rxjs'

export const follow = actions$ => actions$
  .pipe(ofType(followSlice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => followers.post.follow({ address: payload.address })
  .pipe(mergeMap(result => of(result.data)))
  .pipe(mergeMap(success(payload)))
  .pipe(catchError(failure))

const success = payload => response => from([
  followSlice.actions.success(response),
  followersCount.actions.attempt({ address: payload.address }),
  follows.actions.attempt({ address: payload.address }),
  getFeed.actions.reset({}),
  getFeed.actions.attempt({}),
  getSuggestions.actions.reset({}),
  getSuggestions.actions.attempt({ preventLoading: true }),
  getSuggestions.actions.getMeta({ address: payload.loggedInAddress }),
  latestTimestamp.actions.reset({}),
])

const failure = response => from([
  followSlice.actions.failure(response),
])
