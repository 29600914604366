import { useInitializeTimeLeft } from 'nft-page/auction/countdown/hooks/use-initialize-time-left'
import { useDecrementTimeLeft } from 'nft-page/auction/countdown/hooks/use-decrement-time-left'
import { useFetchEndedAuction } from 'nft-page/auction/countdown/hooks/use-fetch-ended-auction'

export const Behaviour = ({ auction }) => {
  useInitializeTimeLeft(auction)
  useDecrementTimeLeft()
  useFetchEndedAuction()

  return ''
}
