import { indexOf, get } from 'lodash'

import { useCurrentPreviewSource } from 'nft-page/controls/hooks/use-current-preview-source'
import { useCurrentElement } from 'nft-page/controls/hooks/use-current-element'

export const useNextElement = () => {
  const currentPreview = useCurrentPreviewSource()
  const currentElement = useCurrentElement()
  const nextElementIndex = indexOf(currentPreview.selector, currentElement) + 1

  return get(currentPreview, `selector[${nextElementIndex}].node`, '')
}
