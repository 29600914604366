import { Behaviour } from 'feed/behaviour'
import { LoadingWrapper } from 'feed/loading-wrapper'
import { FeedBySuggestionsType } from 'feed/feed-by-suggestions-type'
import { Follow } from 'modals/onboarding/follow'

export const Content = () => <>
  <Behaviour />
  <LoadingWrapper>
    <FeedBySuggestionsType />
  </LoadingWrapper>
  <Follow />
</>
