import { Title } from 'components/accordion/title'
import 'components/accordion/accordion-item/index.scss'

const makeProps = id => ({
  className: 'accordion-item',
  id: `accordion${id}`,
})

export const AccordionItem = ({ id, title, children, className = '' }) => <div {...makeProps(id)}>
  <Title id={id} title={title} />
  <div id={`collapse${id}`} className={'accordion-collapse collapse'} aria-labelledby={`heading${id}`}>
    <div className={`accordion-body-content ${className}`}>
      {children}
    </div>
  </div>
</div>
