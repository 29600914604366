import { useMakeProps } from 'components/dropdown-tokens/hooks/use-make-props'
import { isEmpty } from 'lodash'
import { TraitName } from 'components/dropdown-traits/dropdown-button/trait-name'
import { Icon } from 'components/dropdown-traits/dropdown-button/icon'

export const DropdownButton = ({ id, name, selectedOptions }) => {
  const isSelectedOptionEmpty = isEmpty(selectedOptions)

  return <div {...useMakeProps(id)}>
    <TraitName name={name} isSelectedOptionEmpty={isSelectedOptionEmpty} selectedOptions={selectedOptions} />
    <div className={'d-flex align-items-center'}>
      <Icon />
    </div>
  </div>
}
