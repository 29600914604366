import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'modals/auction/claim/slices/claim-auction'

export const useResetState = () => {
  const dispatch = useDispatch()

  return useEffect(() => {
    dispatch(actions.reset())
  }, [dispatch])
}
