import { combineEpics } from 'redux-observable'
import { auctions } from 'account/tabs/assets/auctions/epics/get'
import { economics } from 'economics/epics/economics'
import { profile } from 'account/profile/epics/profile'
import { socialLinks } from 'account/profile-social-links/epics/social-links'
import { collectionAssets } from 'account/collection-assets/epics/collection-assets'
import { collections } from 'account/tabs/assets/collections/epics'
import { get as collected } from 'account/tabs/assets/collected/epics/get'
import { creations } from 'account/tabs/assets/creations/epics'
import { get as claimable } from 'account/tabs/assets/claimable/epics/get'
import { get as liked } from 'account/tabs/assets/liked/epics/get'
import { get as stats } from 'account/stats/epics/get'
import { epic as follow } from 'account/profile/follow/epic'

const epics = [
  auctions,
  collectionAssets,
  profile,
  socialLinks,
  economics,
  collections,
  collected,
  creations,
  claimable,
  liked,
  stats,
  follow,
]

export const account = combineEpics(...epics)
