import { useEffect } from 'react'
import { useContext } from 'components/player-controls/context'

export const useMonitorPause = () => {
  const { player, setPaused } = useContext()

  useEffect(() => {
    if (!player?.current) return

    const onPause = () => setPaused(true)
    const playerInstance = player.current
    playerInstance.on('pause', onPause)

    return () => playerInstance.off('pause', onPause)
  }, [player, setPaused])
}
