import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: { owners: [] },
  input: '',
  errors: [],
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

const reset = () => initialState

export const owners = createSlice({
  initialState,
  name: 'owners',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = owners
