import { useContext } from 'modals/account/dropzone/context'

export const FileTypeInfo = () => {
  const { fileTypeInfo, disabled } = useContext()

  const className = `dropzone-filetype${disabled ? '-disabled' : ''}`

  return <span className={className}>
    {fileTypeInfo}
  </span>
}
