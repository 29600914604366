import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { get as slice } from 'offers/slices'
import { useParams } from 'react-router-dom'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { get } from 'lodash'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useExecute = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()
  const acceptedToken = useFirstAcceptedPaymentToken()
  const priceToken = get(acceptedToken, 'identifier')
  const marketplaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    const status = ['Active', 'Expired']
    dispatch(slice.actions.attempt({
      filters: { identifier, priceToken, status, marketplaceKey },
      pagination: { first: 3, after: '' },
    }))
  }, [dispatch, identifier, marketplaceKey, priceToken])
}

export const useFetchOffers = () => {
  const execute = useExecute()
  useEffect(() => execute(), [execute])
}
