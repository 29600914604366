import { get } from 'lodash'
import { fromFetch } from 'rxjs/fetch'
import { from, map, mergeMap } from 'rxjs'

const providerUrl = settings => {
  const path = 'dapp.walletConnectBridgeProvider'

  return get(settings, path, '')
}

const fetchAddresses = settings => fromFetch(providerUrl(settings))
  .pipe(map(response => response.json()))
  .pipe(mergeMap(from))
  .pipe(map(response => get(response, 'online', [])))

export const bridge = { fetchAddresses }
