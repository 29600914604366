import { SocialLinks } from 'subdomain/components/social-links'
import { useAttributesSocial } from 'subdomain/home/hooks/use-attributes-social'
import { useFooterLinks } from 'subdomain/home/hooks/use-footer-links'
import { FooterLinks } from 'subdomain/components/footer/footer-links'
import 'subdomain/components/footer/index.scss'

export const Footer = () => {
  const social = useAttributesSocial()
  const footerLinks = useFooterLinks()

  return <div>
    {social && <SocialLinks links={social} />}
    {footerLinks && <FooterLinks links={footerLinks} />}
  </div>
}
