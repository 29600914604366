import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { actions } from 'asset-history/slices/asset-history-slice'
import { useLastCursor } from 'asset-history/hooks/use-last-cursor'

export const useFetchAssetHistory = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()
  const endCursor = useLastCursor()

  useEffect(() => {
    const parsedCursor = parseInt(endCursor)

    dispatch(actions.attempt({ filters: { identifier }, pagination: { first: 3, timestamp: parsedCursor } }))
  }, [dispatch, identifier, endCursor])
}
