import match from '@nerds.sh/js-pattern'

import { handleOwnerAuctionEnded } from 'auction-flows/handlers/handle-owner-auction-ended'
import { handleActiveAuction } from 'auction-flows/handlers/handle-active-auction'
import { isAuctionEnded } from 'auction-flows/conditions/is-auction-ended'

export const handleOwner = input => match(input)
  .with(isAuctionEnded(input), handleOwnerAuctionEnded)
  .with(!isAuctionEnded(input), handleActiveAuction)
  .execute()
