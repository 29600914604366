import { createSlice } from '@reduxjs/toolkit'

const initialState = { data: [] }

const append = ({ data }, { payload }) => ({ data: [...data, ...payload] })

const reset = () => initialState

export const list = createSlice({
  initialState,
  name: 'assetHistory/list',
  reducers: {
    append,
    reset,
  },
})

export const { actions } = list
