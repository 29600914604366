import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'explore-nfts/tabs/creators/slices/get'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const useForceResetState = () => {
  const dispatch = useDispatch()

  const selectedTag = useFilterValue('sort')
  useEffect(() => {
    dispatch(actions.reset({}))
  }, [dispatch, selectedTag])
}
