import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: { price: 0 },
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload), loading: false })

export const economics = createSlice({
  initialState,
  name: 'economics',
  reducers: {
    attempt,
    success,
    failure,
  },
})

export const { actions } = economics
