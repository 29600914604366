import { Wrapper } from 'explore-nfts/tabs/nfts/filters/wrapper'
import { FacetsSelector } from 'filters/components/facets-selector'
import { useIsLoading } from 'explore-nfts/tabs/collections/hooks/use-is-loading'
import { useFacets } from 'explore-nfts/tabs/collections/filters/hooks/use-facets'

export const Filters = () => {
  const isLoading = useIsLoading()
  const facets = useFacets()

  return <Wrapper>
    <FacetsSelector facets={facets} isLoading={isLoading} resetOthers />
  </Wrapper>
}
