import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/offers/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnClickAcceptOffer } from 'nft-page/offers/actions/hooks/use-on-click-accept-offer'
import { useCanAcceptOffer } from 'nft-page/offers/actions/hooks/use-can-accept-offer'

export const Accept = ({ offer }) => {
  const { asset } = useAssetWithOwner()
  const acceptOffer = useOnClickAcceptOffer(asset, offer)
  const { address } = useGetAccountInfo()
  const onClick = usePermittedAction(acceptOffer, address)
  const canAcceptOffer = useCanAcceptOffer(offer)
  const tooltipMessage = 'Not enough balance to accept this offer'

  return <div className={'ms-3'}>
    <WatchableAction entity={asset} actionType={actionTypes.acceptOffer} text={'Accept Offer'} onClick={onClick}
                     canAcceptOffer={canAcceptOffer} tooltipMessage={tooltipMessage}
                     className={'btn-primary nft-page-offer-action'} />
  </div>
}
