import { useMarketplaceAcceptedPaymentTokens } from 'marketplaces/hooks/use-marketplace-accepted-payment-tokens'
import { head, get } from 'lodash'
import { useDefaultToken } from 'marketplaces/hooks/use-default-token'

const firstToken = paymentTokens => ({
  identifier: get(head(paymentTokens), 'identifier'),
  symbol: get(head(paymentTokens), 'symbol'),
  priceUsd: get(head(paymentTokens), 'priceUsd'),
  decimals: get(head(paymentTokens), 'decimals'),
})

export const useFirstAcceptedPaymentToken = () => {
  const paymentTokens = useMarketplaceAcceptedPaymentTokens()
  const defaultToken = useDefaultToken()

  return paymentTokens ? firstToken(paymentTokens) : defaultToken
}
