import { Label } from './label'
import { useField } from 'formik'

export const Textarea = ({ name, label, placeholder }) => {
  const [field, meta, { setTouched }] = useField(name)

  return <div className={'nerds-form-field'}>
    <Label className={'form-label'} name={name}>{label}</Label>
    <textarea className={'form-control'} name={name}
              id={name}
              onChange={field.onChange}
              onInput={() => setTouched()}
              value={field.value}
              onBlur={field.onBlur}
              placeholder={placeholder} />
    {meta.touched && meta.error && <div className={'text-danger'}>{meta.error}</div>}
  </div>
}
