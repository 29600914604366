import { Failure } from 'failure'
import { Content } from 'collections/auctions/content'
import { RenderIf } from 'account/common/render-if'
import { Behaviour } from 'collections/auctions/behaviour'
import { useIsOnSale } from 'collections/actions/on-sale-filter/hooks/use-is-on-sale'
import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'
import { useShouldHideFilters } from 'collections/auctions/hooks/use-should-hide-filters'
import { ContractWrapper } from 'components/contract-wrapper'
import { AuctionsFilter } from 'filters/collection-filters/auctions'
import { useCollectionPaymentTokens } from 'collections/payment-tokens/hooks/use-collection-payment-tokens'
import { useCollectionAuctionsPriceRange } from 'collections/auctions/hooks/use-collection-auctions-price-range'
import { useCollectionActualCount } from 'collections/auctions/hooks/use-collection-actual-count'
import { useShouldRender } from 'collections/auctions/hooks/use-should-render'

const getTypeOptionsForCollection = collection => {
  const typeOptions = {
    NonFungibleESDT: [],
    SemiFungibleESDT: [
      { value: 'All', name: 'all' },
      { value: 'Single', name: 'nft' },
      { value: 'Bundles', name: 'sft-all' },
      { value: 'Variable', name: 'sft-one-per-payment' },
    ],
  }

  return typeOptions[collection ? collection.node.type : 'SemiFungibleESDT']
}

export const Auctions = () => {
  const [collection] = useCollectionsSelector()
  const isOnSale = useIsOnSale()
  const filterByTypeOptions = getTypeOptionsForCollection(collection)
  const shouldHideFilters = useShouldHideFilters()
  const paymentTokens = useCollectionPaymentTokens()
  const { minBid, maxBid } = useCollectionAuctionsPriceRange()
  const actualCount = useCollectionActualCount()
  const shouldRender = useShouldRender()

  return <ContractWrapper fullscreen isOnSale={isOnSale}>
    <RenderIf isTrue={shouldRender}>
      <Behaviour />
      { !shouldHideFilters && <AuctionsFilter typeOptions={filterByTypeOptions} paymentTokens={paymentTokens}
                                              minBid={minBid} maxBid={maxBid} actualCount={actualCount} />}
      <Failure.Partial id={'collection.auctions'}>
        <Content />
      </Failure.Partial>
    </RenderIf>
  </ContractWrapper>
}
