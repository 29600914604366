import { indexOf } from 'lodash'
import { useCurrentPreviewSource } from 'nft-page/controls/hooks/use-current-preview-source'
import { useCurrentElement } from 'nft-page/controls/hooks/use-current-element'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const shouldFetchMore = (currentPreview, currentElementIndex) => {
  const hasElements = currentPreview.selector.length
  const shouldFetchInAdvance = currentPreview.selector.length - 1 < currentElementIndex + 4
  const { hasMore } = currentPreview

  return hasElements && shouldFetchInAdvance && hasMore
}

export const useFetchMore = () => {
  const currentPreview = useCurrentPreviewSource()
  const currentElement = useCurrentElement()
  const currentElementIndex = indexOf(currentPreview.selector, currentElement)
  const dispatch = useDispatch()
  const json = JSON.stringify(currentPreview)

  useEffect(() => {
    if (shouldFetchMore(currentPreview, currentElementIndex)) {
      dispatch(currentPreview.action(currentPreview.payload(currentElement)))
    }
    // eslint-disable-next-line
  }, [json, currentElementIndex, currentElement, dispatch])
}
