import { useCreate } from 'modals/account/create-nft/hooks/use-create'
import { useIssueSelector } from 'modals/account/create-nft/hooks/use-issue-selector'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { actions } from 'modals/account/create-nft/slices/create-nft'
import { CreateNftForm } from 'components/forms/create-nft-form'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useCollection } from 'modals/account/create-nft/hooks/use-collection'

export const Create = () => {
  const create = useCreate()
  const { loading, errors, data } = useIssueSelector()
  const address = useViewerAddress()
  const callbackUrl = useCloseLayerLocation(`/${address}/collected`)
  const actionIdentifier = createActionIdentifier(address, actionTypes.createAsset)

  const collection = useCollection()
  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <CreateNftForm onSubmit={create} submitErrors={errors}
                        loading={loading}
                        callbackUrl={callbackUrl}
                        initialValues={{ collection }}
                        selectableCollection />
}
