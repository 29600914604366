import { useCreationsStats } from 'account/stats/hooks/use-creations-stats'
// import { useCollectionsStats } from 'account/stats/hooks/use-collections-stats'
import { useCollectedStats } from 'account/stats/hooks/use-collected-stats'
import { useLikedStats } from 'account/stats/hooks/use-liked-stats'

import { selector as creationsSelector } from 'account/tabs/assets/creations/hooks/use-items'
import { selector as collectedSelector } from 'account/tabs/assets/collected/hooks/use-items'
// import { selector as collectionsSelector } from 'account/tabs/assets/collections/hooks/use-items'
import { selector as likedSelector } from 'account/tabs/assets/liked/hooks/use-items'

import { Loading } from 'loading'

export const publicTabs = {
  collected: {
    hash: 'collected',
    label: 'Collected',
    omitTabInDestination: true,
    selector: useCollectedStats,
    previewSource: 'account-collected',
    itemsSelector: collectedSelector,
    loadingID: Loading.ID.account.tab.collected,
    loadingFactory: Loading.ghosts.assets,
  },
  creations: {
    hash: 'creations',
    label: 'Created',
    omitTabInDestination: false,
    selector: useCreationsStats,
    previewSource: 'account-creations',
    itemsSelector: creationsSelector,
    loadingID: Loading.ID.account.tab.creations,
    loadingFactory: Loading.ghosts.assets,
  },
  liked: {
    hash: 'liked',
    label: 'Liked',
    selector: useLikedStats,
    previewSource: '',
    itemsSelector: likedSelector,
    loadingID: Loading.ID.account.tab.liked,
    loadingFactory: Loading.ghosts.assets,
  },

  // collections: {
  //   hash: 'collections',
  //   label: 'Collections',
  //   selector: useCollectionsStats,
  //   previewSource: '',
  //   itemsSelector: collectionsSelector,
  //   loadingID: Loading.ID.account.tab.collections,
  //   loadingFactory: Loading.ghosts.collections,
  // },
}
