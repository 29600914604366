import { useCallback } from 'react'
import { useFilters } from 'filters/hooks/use-filters'
import { buildFilters } from 'filters/explore-filters/nfts/assets/filters-logic'
import { useLastCursor } from 'explore-nfts/tabs/nfts/assets/hooks/use-last-cursor'

export const useFetchPayloadPreview = () => {
  const cursor = useLastCursor()
  const { background } = useFilters()

  return useCallback(() => buildFilters({ cursor, ...background }), [background, cursor])
}
