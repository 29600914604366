import { useField } from 'formik'
import { useOnChange } from 'components/forms/nerds-form/fields/dependent-field/hooks/use-on-change'
import { CheckSwitchField } from 'components/forms/nerds-form/fields/dependent-field/check-switch-field'

export const DependentField = ({ name, dependentName, dependentLabel, children, showSwitch = true }) => {
  const [{ value }] = useField(dependentName)
  const onChange = useOnChange({ name, dependentName })

  return <>
    {showSwitch ? <CheckSwitchField name={dependentName} label={dependentLabel} onChange={onChange} /> : ''}
    {value ? children : ''}
  </>
}
