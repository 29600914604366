import dropzoneIcon from 'startup/assets/dropzone-icon.png'
import { FileTypeInfo } from 'modals/account/dropzone/file-type-info'
import { Error } from 'modals/account/dropzone/error'
import { Button } from 'modals/account/dropzone/button'
import { useContext } from 'modals/account/dropzone/context'

export const DefaultContent = () => {
  const { image } = useContext()

  return !image && <>
    <img className={'mb-4'} src={dropzoneIcon} alt={'picture icon'} />
    <FileTypeInfo />
    <Error />
    <Button />
  </>
}
