import { useIsPrinceCharming } from 'auth/mega-authorized/index'
import { useRouteConfig } from 'components/layered-router/hooks/use-route-config'
import { get } from 'lodash'
import { useCallback } from 'react'

const isAuthOk = (authenticatedRoute, isPrinceCharming) => !authenticatedRoute || isPrinceCharming

export const useIsRouteHydratable = () => {
  const currentRoute = useRouteConfig()
  const isPrinceCharming = useIsPrinceCharming()

  return useCallback(() => {
    const layered = get(currentRoute, 'layered', false)
    const authenticatedRoute = get(currentRoute, 'authenticatedRoute', false)
    const preventDirectNavigation = get(currentRoute, 'preventDirectNavigation', false)
    const authOk = isAuthOk(authenticatedRoute, isPrinceCharming)

    return layered && authOk && !preventDirectNavigation
  }, [currentRoute, isPrinceCharming])
}
