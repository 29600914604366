import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'
import { uniqBy } from 'lodash'

const initialState = {
  attempt: '',
  data: '',
  errors: [],
  list: [],
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, attempt: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload.data })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

const append = (state, { payload }) => ({
  ...state,
  list: uniqBy([...state.list, ...payload], 'node.id'),
  loading: false,
})

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'offers/get',
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})
