import PropTypes from 'prop-types'
import { Mute } from 'components/player-controls/mute'
import { Play } from 'components/player-controls/play'
import { Playing } from 'components/player-controls/playing'
import { Behaviour } from 'components/player-controls/behaviour'
import { Context, useValue } from 'components/player-controls/context'
import { useToggleCallback } from 'components/player-controls/hooks/use-toggle-callback'

export const PlayerControls = ({ player, ...rest }) => <Context.Provider value={useValue({ player, ...rest })}>
  <Behaviour />
  <Playing />
  <Play />
  <Mute />
</Context.Provider>

PlayerControls.useToggleCallback = useToggleCallback

PlayerControls.propTypes = {
  player: PropTypes.object.isRequired,
  playingFeedback: PropTypes.bool,
}

PlayerControls.defaultProps = { playingFeedback: false }
