import * as Yup from 'yup'
import { settings } from 'settings/fetching'

const tagLimit = () => settings().tagLimit

const maxSupply = () => settings().maxSupply

const mimeFileTypes = () => settings().mimeFileTypes

const description = () => Yup.string()
  .max(255, 'Too Long!')
  .default('')

const tags = () => Yup.string()
  .max(tagLimit(), `Should be less than ${tagLimit()} characters`)
  .default('')

const collection = () => Yup.object().shape({
  collection: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  canCreate: Yup.boolean()
    .oneOf([true], 'No roles set')
    .typeError('No roles set'),
})

const quantity = () => Yup.number().required('Required')
  .typeError('Should be a number')
  .min(1, 'Should be greater than 0')
  .max(maxSupply(), `Should be lower than ${maxSupply()}`)
  .default('1')

const name = () => Yup.string()
  .min(3, 'Too Short!')
  .max(20, 'Too Long!')
  .required('Required')
  .default('')

const royalties = () => Yup.number()
  .typeError('Should be a number')
  .min(0, 'Should be greater than 0')
  .max(50, 'Should be less than or equal to 50%')
  .required('Required')
  .default('5')

const file = () => Yup.mixed()
  .required('A file is required')
  .test('type', 'Unsupported file type', value => value && mimeFileTypes().includes(value.type))

export const schema = () => Yup.object().shape({
  description: description(),
  tags: tags(),
  collection: collection(),
  quantity: quantity(),
  name: name(),
  royalties: royalties(),
  file: file(),
})
