import { AboutArtist } from 'nft-page/details/about-artist'
import { OtherDetails } from 'nft-page/details/other-details'
import { History } from 'nft-page/details/history'
import { Button as DetailsButton } from 'nft-page/details/other-details/button'
import { Button as HistoryButton } from 'nft-page/details/history/tab-button'
import { Button as ArtistButton } from 'nft-page/details/about-artist/button'
import 'nft-page/details/index.scss'

export const Details = () => <div className={'nft-page-details'}>
  <nav>
    <div className={'nav nav-tabs'} id={'nav-tab'} role={'tablist'}>
      <DetailsButton />
      <HistoryButton />
      <ArtistButton />
    </div>
  </nav>
  <div className={'tab-content'} id={'nav-tabContent'}>
    <OtherDetails />
    <AboutArtist />
    <History />
  </div>
</div>
