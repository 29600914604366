import { Collections } from 'explore-nfts/tabs/collections'
import { Nfts } from 'explore-nfts/tabs/nfts'
import { Creators } from 'explore-nfts/tabs/creators'
import { PageTabs } from 'components/page-tabs'
import { IsActive } from 'explore-nfts/tabs/is-active'
import { useTabs } from 'explore-nfts/use-tabs'
import { useIsActive } from 'explore-nfts/tabs/hooks/use-is-active'
import { ExploreFilters } from 'filters/explore-filters'

export const Body = () => {
  const isActive = useIsActive()
  const tabs = useTabs()

  return <div className={'container'}>
    <PageTabs tabs={tabs} isActive={isActive} filters={<ExploreFilters />} />
    <div className={'tab-content'} id={'nav-tabContent'}>
      <IsActive tabName={'collections'}><Collections /></IsActive>
      <IsActive tabName={'nfts'}><Nfts /></IsActive>
      <IsActive tabName={'creators'}><Creators /></IsActive>
    </div>
  </div>
}
