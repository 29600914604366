import { useSuggestedAccounts } from 'feed/initial/hooks'
import { Empty } from 'feed/initial/empty'
import PropTypes from 'prop-types'

export const EmptyWrapper = ({ children, fullscreen }) => {
  const suggestedAccounts = useSuggestedAccounts()

  return suggestedAccounts.length ? children : <Empty fullscreen={fullscreen} />
}

EmptyWrapper.defaultProps = { fullscreen: true }

EmptyWrapper.propTypes = {
  fullscreen: PropTypes.bool,
  children: PropTypes.any.isRequired,
}
