import { useCallback } from 'react'
import { useContext } from 'components/player-controls/context'

export const useMuteCallback = () => {
  const { player } = useContext()

  return useCallback(() => {
    if (!player.current) return

    player.current.muted(!player.current.muted())
  }, [player])
}
