import { createSlice } from '@reduxjs/toolkit'
import { omitBy } from 'lodash'

const initialState = { actions: {} }

const includesSession = sessionIds => sessionId => sessionIds.includes(sessionId)

const add = (state, { payload }) => ({ actions: { ...state.actions, ...payload } })

const clearSessions = ({ actions }, { payload }) => ({ actions: { ...omitBy(actions, includesSession(payload)) } })

export const actionStatuses = createSlice({
  initialState,
  name: 'actionsStatuses',
  reducers: {
    add,
    clearSessions,
  },
})

export const { actions } = actionStatuses
