import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getBlacklistedCollections } from 'admin/slices'

export const useFetchBlacklistedCollections = () => {
  const dispatch = useDispatch()

  return useEffect(() => {
    dispatch(getBlacklistedCollections.actions.attempt())
  }, [dispatch])
}
