import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { get as feed } from 'feed/slices'
import { get as suggestions } from 'feed/suggestions/slices'

export const useResetFeed = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(feed.actions.reset({}))
    dispatch(suggestions.actions.reset({}))
  }, [dispatch])
}
