import { get } from 'lodash'

const makeProps = auction => ({
  className: 'btn btn-secondary other-auction-action',
  target: '_blank',
  rel: 'noreferrer',
  href: get(auction, 'marketplace.url', ''),
})

export const ViewOnOtherMarket = ({ auction }) => <a {...makeProps(auction)}>
  View
</a>
