import { filter, get } from 'lodash'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useSubdomainCollections = () => {
  const data = useCmsData()
  const content = get(data, 'data.data.attributes.content', [])
  const [collections] = filter(content, { __component: 'sections.collections' })

  return { collections }
}
