import match from '@nerds.sh/js-pattern'

const hasOrders = ({ auction }) => !!auction.orders?.edges?.length

const hasNoOrders = ({ auction }) => !auction.orders?.edges?.length

export const handleActiveAuction = input => match(input)
  .with(hasNoOrders(input), () => input.statuses.withdraw)
  .with(hasOrders(input), () => input.statuses.displayPrice)
  .execute()
