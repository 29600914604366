import { useCurrentAsset } from 'nft-page/media/hooks/use-current-asset'

export const useInteractiveUrl = () => {
  const asset = useCurrentAsset()

  const interactiveUrl = asset?.metadata?.interactiveUrl ?? ''
  const description = asset?.metadata?.description ?? ''

  return { interactiveUrl, description }
}
