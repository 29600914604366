import { useCallback } from 'react'
import { useMaximum } from 'components/forms/place-bid-form/hooks/use-maximum'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const useMaximumMessage = (auction, paymentToken) => {
  const maximum = useMaximum(auction)
  const decimals = get(paymentToken, 'decimals', 18)
  const amount = denominateAmount(maximum, decimals, true)

  return useCallback(() => (
    `Must be less or equal than ${amount} ${paymentToken.symbol}`
  ), [amount, paymentToken.symbol])
}
