import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useUsernameFound } from 'viewer/hooks/use-found'
import { useParams } from 'react-router-dom'

export const useShouldRedirect = () => {
  const viewerAddress = useViewerAddress()
  const usernameFound = useUsernameFound()
  const { address } = useParams()

  return usernameFound && (viewerAddress === address)
}
