import { useField } from 'formik'
import { useAutoFocus } from '../hooks/use-auto-focus'

export const Input = ({ name, placeholder, defaultValue, disabled, autofocus }) => {
  const [field, , { setTouched }] = useField(name)
  const ref = useAutoFocus(autofocus)

  return <input ref={ref} className={'form-control pe-0 with-icon'}
                type={'text'} name={name} id={name}
                onChange={field.onChange} value={field.value}
                onInput={() => setTouched()}
                onBlur={field.onBlur}
                placeholder={placeholder}
                defaultValue={defaultValue} disabled={disabled} autoComplete={'off'} />
}
