import { createPortal } from 'react-dom'
import { useModalProps } from 'components/bootstrap-modal/hooks/use-modal-props'
import { useModalElement } from 'components/bootstrap-modal/modal-portal/hooks/use-modal-element'

export const ModalPortal = ({ modalSize, id, children, className }) => {
  const el = useModalElement(id)

  return createPortal(<div {...useModalProps({ id, className })}>
    <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
      <div className={'modal-body transparent-content'}>
        {children}
      </div>
    </div>
  </div>, el.current)
}

