import { useOnCancel } from 'components/simple-modal/hooks/use-on-cancel'

export const Cancel = ({ id }) => {
  const onCancel = useOnCancel(id)

  return <>
    <button className={'btn btn-secondary w-100'} onClick={onCancel}>Close</button>
    <div className={'me-2 my-2'} />
  </>
}
