import { useEffect } from 'react'
import { useFetchStatsCallback } from 'account/stats/hooks/use-fetch-stats'
import { useParams } from 'react-router-dom'

export const useRefreshCounters = () => {
  const stats = useFetchStatsCallback()
  const { tab } = useParams()

  useEffect(() => stats(), [stats, tab])

  return ''
}
