import { Element } from 'components/duration-label/element'
import 'components/duration-label/index.scss'
import moment from 'moment'

const hasMinutes = data => data.days || data.hours || data.minutes
const hasHours = data => data.days || data.hours

export const DurationLabel = ({ duration }) => {
  let theDuration = moment.isDuration(duration) ? duration : moment.duration(duration, 'milliseconds')

  if (theDuration.asMilliseconds() < 0) {
    theDuration = moment.duration(0, 'miliseconds')
  }

  const days = Math.floor(theDuration.asDays())
  const hours = theDuration.hours()
  const minutes = theDuration.minutes()
  const seconds = theDuration.seconds()
  const data = { days, hours, minutes, seconds }

  return <span className={'duration-label'}>
    <Element number={days} type={'d'} condition={days} />
    <Element number={hours} type={'h'} condition={hasHours(data)} />
    <Element number={minutes} type={'m'} condition={hasMinutes(data)} />
    <Element number={seconds} type={'s'} />
  </span>
}
