import { settings } from 'settings/fetching'

const statusFilter = () => ({
  field: 'status',
  values: ['Running', 'Claimable'],
  op: 'IN',
})

const ownerFilter = address => ({
  field: 'ownerAddress',
  values: [address],
  op: 'EQ',
})

const marketPlaceFilters = marketplaceKey => ({
  field: 'marketplaceKey',
  values: [marketplaceKey],
  op: 'EQ',
})

const getFilters = (viewerAddress, marketplaceKey) => {
  const filters = [ownerFilter(viewerAddress), statusFilter(), marketPlaceFilters(marketplaceKey)]

  return filters
}

export const payload = ({ address, cursor, marketplaceKey }) => ({
  pagination: { first: settings().listRequestSize, after: cursor },
  sorting: [{
    field: 'endDate',
    direction: 'DESC',
  }],
  filters: {
    operator: 'AND',
    filters: getFilters(address, marketplaceKey),
  },
})
