import { useEffect } from 'react'
import { useAppContext } from 'startup/context'

export const useUpdateAppContext = collapseElem => {
  const { setMobileMenuOpen } = useAppContext()

  useEffect(() => {
    if (collapseElem) {
      const onShow = () => {
        setMobileMenuOpen(true)
      }

      const onHide = () => {
        setMobileMenuOpen(false)
      }

      collapseElem.addEventListener('show.bs.collapse', onShow)
      collapseElem.addEventListener('hide.bs.collapse', onHide)

      return () => {
        collapseElem.removeEventListener('show.bs.collapse', onShow)
        collapseElem.removeEventListener('hide.bs.collapse', onHide)
      }
    }
  }, [collapseElem, setMobileMenuOpen])
}
