import { get } from 'lodash'

import { useTestMinimum } from 'components/forms/place-bid-form/hooks/use-test-minimum'
import { useTestMaximum } from 'components/forms/place-bid-form/hooks/use-test-maximum'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { useTestBalance } from 'components/forms/place-bid-form/hooks/use-test-balance'

const testDecimals = decimals => value => get(value?.split('.'), '[1].length', 0) <= decimals

const testComma = value => !value?.includes(',')

const testStartDot = value => !value?.startsWith('.')

const testEndDot = value => !value?.endsWith('.')

const testLeadingZeros = (value, context) => {
  const leadingZerosCondition = !context.originalValue.startsWith('0') || context.originalValue.startsWith('0.')

  return context.originalValue && leadingZerosCondition
}

export const testNumber = value => value?.match(/^\d*\.?\d*$/)

export const useTests = (auction, paymentToken) => {
  const decimals = useDenominationDecimals()

  return {
    testDecimals: testDecimals(decimals),
    testLeadingZeros,
    testComma,
    testStartDot,
    testEndDot,
    testNumber,
    testMinimum: useTestMinimum(auction, paymentToken),
    testMaximum: useTestMaximum(auction, paymentToken),
    testBalance: useTestBalance(auction, paymentToken),
  }
}
