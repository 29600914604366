import { Content } from 'components/card-with-header/content'
import { Header } from 'components/card-with-header/header'
import { CardWithHeader } from 'components/card-with-header'
import { LabeledProgress } from 'components/labeled-progress'
import { Price } from 'components/price'
import { ReactComponent as Icon0 } from 'startup/assets/tier-icon-0.svg'
import { ReactComponent as Icon1 } from 'startup/assets/tier-icon-1.svg'
import { ReactComponent as Icon2 } from 'startup/assets/tier-icon-2.svg'
import { ReactComponent as Icon3 } from 'startup/assets/tier-icon-3.svg'
import { ReactComponent as Icon4 } from 'startup/assets/tier-icon-4.svg'
import 'campaign/tier/index.scss'
import { Action } from 'campaign/action'
import { useRealTimeUsdPrice } from 'common/hooks/use-real-time-price'
import { useOnClick } from '../hooks/use-on-click'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'

const iconClass = 'campaign-tier-card-icon'
const icons = [
  <Icon0 key={0} className={iconClass} />,
  <Icon1 key={1} className={iconClass} />,
  <Icon2 key={2} className={iconClass} />,
  <Icon3 key={3} className={iconClass} />,
  <Icon4 key={4} className={iconClass} />,
]

export const Tier = ({ tier, status }) => {
  const buyClick = useOnClick(tier.tierName)
  const { address } = useGetAccountInfo()
  const onClick = usePermittedAction(buyClick, address)
  const usdAmount = useRealTimeUsdPrice(tier.mintPrice.amount)
  const price = { amount: tier.mintPrice.amount, token: tier.mintPrice.token, usdAmount }

  return <CardWithHeader className={'campaign-tier-card'}>
    <Header>
      {icons[2]}
      <div className={'campaign-tier-card-title'}>{tier.tierName}</div>
      <Price price={price} />
    </Header>

    <Content>
      <div className={'campaign-tier-card-description'}>{tier.description}</div>
      <LabeledProgress status={tier.status} total={tier.totalNfts} available={tier.availableNfts} size={'small'} />
      {status === 'Running' && <Action onClick={onClick} tier={tier} disabled={tier.availableNfts === 0} />}
    </Content>
  </CardWithHeader>
}
