import { Column } from 'components/column'
import { Behaviour } from 'filters/collection-filters/auctions/behaviour'
import { Filters } from 'filters/collection-filters/auctions/filters'
import './index.scss'

export const AuctionsFilter = ({ typeOptions, paymentTokens, minBid, maxBid, actualCount }) => <Column>
  <Behaviour />
  <Filters minBid={minBid} maxBid={maxBid} typeOptions={typeOptions} paymentTokens={paymentTokens}
           actualCount={actualCount} />
</Column>
