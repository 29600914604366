import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'modals/account/photo/slices/update-profile-photo'

export const useOnSubmit = () => {
  const dispatch = useDispatch()

  return useCallback(file => {
    dispatch(actions.attempt(file))
  }, [dispatch])
}
