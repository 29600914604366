import { Image } from 'components/facet-button/image'
import { Text } from 'components/facet-button/text'
import { Count } from 'components/facet-button/count'
import { Close } from 'components/facet-button/close'

export const Button = ({ image, label, count, onClick, selected, disabled, showClose }) => <div>
  <button type={'button'}
          className={`btn facet-button me-2 ${selected && 'active'}`}
          onClick={() => onClick(label)} disabled={disabled}>
    <Image image={image} />
    <Text label={label} />
    {showClose ? <Close /> : <Count count={count} />}
  </button>
</div>
