// eslint-disable-next-line complexity
export const calcInViewport = el => {
  if (!el) return 0

  const rect = el.getBoundingClientRect()
  let visible = rect.height

  // 80 is header height
  if (rect.y < 80) visible += rect.y - 80
  if (rect.bottom > window.innerHeight) visible -= rect.bottom - window.innerHeight

  return visible * 100 / rect.height
}
