import { useIncrementFollowees } from 'feed/initial/hooks/use-increment-followees'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { follow } from 'feed/suggestions/slices'

export const useFollowAccount = (setFollowed, account) => {
  const incrementFollowees = useIncrementFollowees()
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(follow.actions.attempt({ address: account.address }))
    incrementFollowees()
    setFollowed(true)
  }, [account, dispatch, incrementFollowees, setFollowed])
}
