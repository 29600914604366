import { get } from 'lodash'
import { useIsPublicProfile } from 'account/profile/hooks/use-is-public-profile'
import { useProfileData } from 'account/header/hooks/use-profile-data'

export const useData = () => {
  const data = useProfileData()
  const isPublic = useIsPublicProfile()
  const urls = Array(6).join('.')
    .split('.')

  const socialLinks = get(data, 'socialLinks', [])

  socialLinks.forEach(item => {
    urls[item.type] = item.url
  })

  return { urls, description: data.description || '', privacy: !isPublic }
}
