import { combineReducers } from 'redux'
import { updateCover } from 'modals/account/cover/slices/update-cover'
import { updateProfilePhoto } from 'modals/account/photo/slices/update-profile-photo'
import { reducers as edit } from 'modals/account/edit'
import { reducer as followers } from 'modals/account/followers'
import { reducer as followees } from 'modals/account/followees'
import { reducer as collections } from 'modals/account/create-nft/collections/slices/collections'
import { reducer as createNft } from 'modals/account/create-nft/slices/create-nft'

export const reducers = combineReducers({
  updateCover: updateCover.reducer,
  updateProfilePhoto: updateProfilePhoto.reducer,
  edit,
  followers,
  followees,
  collections,
  createNft,
})
