import { useIsEmpty } from 'feed/hooks'
import { Empty } from 'components/empty'
import { ExploreLink } from 'feed/explore-link'
import { ReactComponent as FeedEmptyIcon } from 'startup/assets/feed-empty.svg'

const emptyProps = () => ({
  message: 'Explore amazing collections and follow emerging artists.',
  Action: ExploreLink,
  illustration: <FeedEmptyIcon />,
  title: 'Your feed is empty.',
  fullscreen: true,
})

export const EmptyWrapper = ({ children }) => useIsEmpty() ? <Empty {...emptyProps()} /> : children
