import { Footer } from 'components/footer'
import 'components/surfaces/middleground/index.scss'
import { useIsNftView } from 'startup/hooks/use-is-nft-view'
import { MobileMenu } from 'components/top-navigation/components/mobile-menu'

export const Middleground = ({ layer, children }) => {
  const blurredClass = useIsNftView() ? 'blurred' : ''

  return <div className={`middleground-surface ${blurredClass}`} style={style(layer)}>
    <MobileMenu />
    {children}
    <Footer />
  </div>
}

const style = layer => ({ zIndex: layer.zIndex })
