import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { useFetchSubdomainNft } from 'nft-page/hooks/use-fetch-subdomain-nft'
import { useFetchMainNft } from 'nft-page/hooks/use-fetch-main-nft'

export const useExecute = () => {
  const dispatch = useDispatch()
  const { identifier, auctionId } = useParams()
  const isSubdomain = useIsSubdomain()
  const subdomainFetch = useFetchSubdomainNft()
  const mainFetch = useFetchMainNft()

  return useCallback(() => {
    if (!identifier) return

    return isSubdomain ? subdomainFetch() : mainFetch()

    // eslint-disable-next-line
  }, [dispatch, identifier, auctionId, isSubdomain, subdomainFetch, mainFetch])
}

export const useFetchNft = () => {
  const execute = useExecute()
  useEffect(() => execute(), [execute])
}
