import * as Yup from 'yup'
import * as maxBidPrice from 'components/forms/create-sft-auctions/single-form/hooks/use-max-bid-schema'

export const maxBidSchema = () => Yup.string()
  .when('checkedMaxBid', {
    is: true,
    then: maxBidPrice.useMaxBidSchema('Required'),
    otherwise: Yup.string()
      .default(''),
  })
  .default('')
