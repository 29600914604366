import { useOpenModal } from 'components/bootstrap-modal/hooks/use-open-modal'

import { useEffect } from 'react'

export const useShowModal = id => {
  const openModal = useOpenModal(id)

  useEffect(() => {
    openModal()
  }, [id, openModal])
}
