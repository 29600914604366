export const Nft = ({ image }) => (
  <svg xmlns={'http://www.w3.org/2000/svg'} version={'1.1'} viewBox={'0 0 132 134'}>
    <defs>
      <clipPath id={'user-space'} clipPathUnits={'userSpaceOnUse'}>
        <path xmlns={'http://www.w3.org/2000/svg'}
              // eslint-disable-next-line max-len
              d={'M56 5.7735C62.188 2.20085 69.812 2.20085 76 5.7735L113.158 27.2265C119.346 30.7992 123.158 37.4017 123.158 44.547V87.453C123.158 94.5983 119.346 101.201 113.158 104.774L76 126.227C69.812 129.799 62.188 129.799 56 126.226L18.8423 104.773C12.6543 101.201 8.84232 94.5983 8.84232 87.453V44.547C8.84232 37.4017 12.6543 30.7992 18.8423 27.2265L56 5.7735Z'}
              fill={'#C4C4C4'} />
      </clipPath>
    </defs>
    <path xmlns={'http://www.w3.org/2000/svg'}
          // eslint-disable-next-line max-len
          d={'M56 5.7735C62.188 2.20085 69.812 2.20085 76 5.7735L113.158 27.2265C119.346 30.7992 123.158 37.4017 123.158 44.547V87.453C123.158 94.5983 119.346 101.201 113.158 104.774L76 126.227C69.812 129.799 62.188 129.799 56 126.226L18.8423 104.773C12.6543 101.201 8.84232 94.5983 8.84232 87.453V44.547C8.84232 37.4017 12.6543 30.7992 18.8423 27.2265L56 5.7735Z'}
          stroke={'white'} strokeWidth={'14'} />
    <image width={'100%'} height={'100%'} preserveAspectRatio={'xMinYMin slice'}
           xlinkHref={image}
           clipPath={'url(#user-space)'} />
  </svg>
)
