import { useCallback } from 'react'
import { useLastCursor } from 'collections/auctions/hooks/use-last-cursor'
import { useParams } from 'react-router-dom'
import { useFilters } from 'filters/hooks/use-filters'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { usePriceRange } from 'collections/auctions/hooks/use-price-range'
import { useTokenDecimals } from 'collections/auctions/hooks/use-token-decimals'
import { buildFilters } from 'filters/collection-filters/auctions/filters-logic'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const { identifier: collection } = useParams()
  const cursor = useCursor(customCursor)
  const filters = useFilters()
  const hash = JSON.stringify(filters.search)
  const marketplaceKey = useCmsMarketplaceKey()
  const priceRange = usePriceRange()
  const priceRangeHash = JSON.stringify(priceRange)
  const decimals = useTokenDecimals()

  return useCallback(() => {
    const parsed = JSON.parse(hash)
    const parsedPriceRange = JSON.parse(priceRangeHash)

    const defPriceRange = {
      min: { amount: parsedPriceRange.minBid?.amount, decimals },
      max: { amount: parsedPriceRange.maxBid?.amount, decimals },
    }

    return buildFilters({ ...parsed, cursor }, collection, marketplaceKey, defPriceRange)
  }, [collection, cursor, decimals, hash, marketplaceKey, priceRangeHash])
}

