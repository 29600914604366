import SwiperCore, { Mousewheel } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { SwiperWithNavigation } from 'components/swipper-with-navigation'
import { CollectionCard } from 'components/collection-card'

SwiperCore.use([Mousewheel])

const pickParameter = ({ node }) => ({ collection: node })

export const Content = ({ items, previewSource }) => <SwiperWithNavigation>
  {
    items.map((item, index) => <SwiperSlide key={index}>
      <CollectionCard {...pickParameter(item)} previewSource={previewSource} openNewLayer />
    </SwiperSlide>)
  }
</SwiperWithNavigation>
