import { from } from 'rxjs'
import { Failure } from 'failure'
import { Loading } from 'loading'
import { loading } from 'loading/slices'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { actions } from 'account/tabs/assets/auctions/slices/get'
import { actions as listActions } from 'account/tabs/assets/auctions/slices/list'
import { auctions as repository } from 'repositories/auctions'

export const auctions = action$ => action$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(action => execute(action.payload)))

const execute = payload => repository.getAccountAuctions(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  listActions.append(payload.data.auctions.edges),
  loading.actions.hide({ id: Loading.ID.account.tab.auctions, variant: Loading.VARIANT.ghostCard }),
])

const failure = errors => from([
  actions.failure(errors),
  loading.actions.hide({ id: Loading.ID.account.tab.auctions, variant: Loading.VARIANT.ghostCard }),
  failureSlice.actions.show({ variant: Failure.VARIANT.partial, id: 'account.auctions' }),
])
