import { createSlice } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'

const initialState = {
  data: null,
  list: [],
  errors: null,
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, data: payload.data })

const failure = (state, { payload }) => ({ ...state, errors: payload, loading: false })

const append = (state, { payload }) => ({
  ...state,
  list: uniqBy([...state.list, ...payload], 'node.id'),
  loading: false,
})

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'subdomain/modals/account/myBids/get',
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})

export const { actions } = get
