import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { removeFeaturedCollection } from 'admin/slices'

export const useRemoveFeaturedCollection = () => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    payload && dispatch(removeFeaturedCollection.actions.attempt({ input: { collection: payload, type: 'Featured' } }))
  }, [dispatch])
}
