import { settings } from 'settings/fetching'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { useCallback } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

const isAllSelected = collectionFilter => collectionFilter !== 'all' && collectionFilter !== ''

export const useFetchPayload = () => {
  const collectionFilter = useFilterValue('collection')
  const address = useViewerAddress()

  return useCallback(({ cursor }) => {
    const collection = isAllSelected(collectionFilter) ? collectionFilter : undefined
    const artistAddress = !isAllSelected(collectionFilter) ? address : undefined

    return ({
      pagination: { first: settings().listRequestSize, after: cursor },
      filters: { collection, artistAddress },
    })
  }, [address, collectionFilter])
}
