import { settings } from 'settings/fetching'
import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'

const egldLabel = () => settings().dapp.egldLabel

const getDenominationDecimals = () => settings().dapp.denomination

export const useDefaultToken = () => {
  const { price } = useEconomicsSelector()

  return ({
    identifier: 'EGLD',
    symbol: egldLabel(),
    priceUsd: price,
    decimals: getDenominationDecimals(),
  })
}
