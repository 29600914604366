import { STEP } from 'sidebar/connect/constants'
import 'sidebar/connect/walletconnect-login/qr-code.scss'
import { useContext } from 'sidebar/connect/context'
import { ConnectButton } from 'sidebar/connect/connect-button'
import { useSetStep } from 'sidebar/connect/hooks/use-set-step'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { WalletConnectLoginContainer } from '@multiversx/sdk-dapp/UI'
import { useInternalAccessToken } from 'network/axios-interceptor/use-internal-access-token'
import { extras } from 'repositories/extras'

export const QrCode = () => {
  extras.getAccessToken()
  const callbackRoute = useCallbackRoute()
  const localToken = useInternalAccessToken()

  const { step } = useContext()
  const handleBack = useSetStep(STEP.loginButtons)
  const nativeAuth = { extraRequestHeaders: { Authorization: `Bearer ${localToken}` } }

  return step === STEP.walletConnectQrCode && <div className={'walletconnect-qr-code'}>
    <WalletConnectLoginContainer wrapContentInsideModal={false}
                                 callbackRoute={callbackRoute} nativeAuth={nativeAuth}
                                 loginButtonText={'xPortal App'}
                                 title={'xPortal App Login'}
                                 lead={'Scan using the xPortal App'} />
    <ConnectButton LeftIcon={ConnectButton.Icon.ArrowLeft} RightIcon={ConnectButton.Icon.None} onClick={handleBack}>
      Back
    </ConnectButton>
  </div>
}
