import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'account/tabs/assets/claimable/slices/get'
import { useUpdateLoadings } from 'account/tabs/assets/hooks/use-update-loadings'
import { subdomainTabs } from 'account/tabs/subdomain-tabs'

import { useIsPrinceCharming } from '../../../../../auth/mega-authorized'
import { usePayload } from './use-payload'

export const useNext = customCursor => {
  const dispatch = useDispatch()

  const updateLoadings = useUpdateLoadings(subdomainTabs.claimable)
  const isPrinceCharming = useIsPrinceCharming()
  const payload = usePayload(customCursor)

  return useCallback(() => {
    updateLoadings()
    isPrinceCharming && dispatch(actions.attempt(payload))
  }, [dispatch, isPrinceCharming, updateLoadings, payload])
}
