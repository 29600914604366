import { AddRemoveBlacklistedCollectionForm } from 'components/forms/add-remove-blacklisted-collections-form'
import { useAddBlacklistedCollectionInModal } from 'admin/hooks/actions/use-add-blacklisted-collection-in-modal'
import { useBlacklistedCollections } from 'admin/hooks/actions/use-blacklisted-collections'

const makeInitialValues = blacklistedCollections => ({ collection: '', blacklistedCollections })

export const Form = () => {
  const blacklistedCollections = useBlacklistedCollections()
  const onSubmit = useAddBlacklistedCollectionInModal()

  return <AddRemoveBlacklistedCollectionForm initialValues={makeInitialValues(blacklistedCollections)} submitErrors={[]}
                                             blacklistedCollections={blacklistedCollections} onSubmit={onSubmit} />
}
