import { Countdown } from 'nft-page/auction/countdown'
import { CurrentBid } from 'nft-page/auction/current-bid'
import { Editions } from 'nft-page/auction/editions'
import { get } from 'lodash'
import { SftBundle } from 'nft-page/auction/details/sft-bundle'
import './content.scss'

export const Content = ({ auction, assetType }) => {
  const endDate = get(auction, 'endDate', '')

  return <div className={'nft-auction-details'}>
    <Editions auction={auction} assetType={assetType} />
    <div className={'nft-auction-details-inner'}>
      <CurrentBid auction={auction} />
      {endDate !== 0 && <Countdown auction={auction} />}
    </div>
    <SftBundle auction={auction} assetType={assetType} />
  </div>
}
