import { Buy } from 'nft-page/auction/actions/buy-or-bid/buy'
import { Bid } from 'nft-page/auction/actions/buy-or-bid/bid'
import { Details } from 'nft-page/auction/details'
import 'nft-page/auction/actions/buy-or-bid/index.scss'

export const BuyOrBid = ({ auction, address, assetType }) => (
  <div className={'nft-page-auction-inner buy-or-bid-actions'}>
    <Details auction={auction} assetType={assetType} />
    <div className={'d-flex'}>
      <Bid auction={auction} address={address} />
      <Buy auction={auction} address={address} />
    </div>
  </div>
)

BuyOrBid.displayName = 'BuyOrBid'
