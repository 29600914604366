import { ofType } from 'redux-observable'
import { send as slice } from 'offers/slices'
import { offers } from 'repositories/offers'
import { from, catchError, mergeMap, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { throwOnInsufficientFunds } from 'modals/throw-on-insufficient-funds'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'

export const send = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => offers.send(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.sendOffer })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = response => from([
  slice.actions.success(response),
])

// const failure = response => from([
//   slice.actions.failure(response),
// ])

const failure = result => of(slice.actions.failure(extractGraphqlErrors(result)))
