import { Link } from 'react-router-dom'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

export const Close = () => {
  const callbackUrl = useCloseLayerLocation()

  return <Link className={'btn btn-secondary mt-1'} to={callbackUrl}>
    Close
  </Link>
}
