import { InputField } from 'components/forms/nerds-form/fields/input-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook, faInstagram, faTwitter, faYoutube, faDiscord,
  faTelegram, faMedium
} from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/pro-light-svg-icons'

import './social-link-field.scss'

const icons = [undefined, faTwitter, faFacebook, faInstagram, faGlobe, faYoutube, faTelegram, faDiscord, faMedium]

export const SocialLinkField = ({ label, value }) => <div className={'social-link-field d-flex align-items-center'}>
  <FontAwesomeIcon icon={icons[value]} className={'me-2'} />
  <InputField name={`urls[${value}]`} label={label} className={'d-flex align-items-center w-100'} />
</div>
