import React from 'react'
import { Settings } from 'settings'
import { Network } from 'network'
import { DappProviderWrapper } from 'startup/dapp-provider-wrapper'
import { OneColumnLayout } from 'layouts/one-column-layout'
import { AppContext, useAppContextValue } from 'startup/context'
import { HelmetProvider } from 'react-helmet-async'
import { ReduxWrapper } from 'startup/redux-wrapper'
import { CmsContent } from 'subdomain/cms-content'
import { SubdomainLayout } from 'subdomain/layout'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
/* eslint-disable react/jsx-max-depth */

export const Startup = () => {
  const isSubdomain = useIsSubdomain()

  return <Settings>
    <Network>
      <ReduxWrapper>
        <DappProviderWrapper>
          <HelmetProvider>
            <AppContext.Provider value={useAppContextValue()}>
              <CmsContent>
                {isSubdomain ? <SubdomainLayout /> : <OneColumnLayout />}
              </CmsContent>
            </AppContext.Provider>
          </HelmetProvider>
        </DappProviderWrapper>
      </ReduxWrapper>
    </Network>
  </Settings>
}

export * from 'startup/initialize-sentry'
