import { Button } from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import image from 'startup/assets/welcome-image.svg'
import './welcome.scss'

export const Welcome = () => <div className={'welcome'}>
  <div className={'row align-items-center'}>
    <div className={'col-12 col-lg-6 col-md-6'}>
      <p className={'welcome-highlight'}>EDITORIAL</p>
      <p className={'welcome-title'}>Welcome to xSpotlight</p>
      <p className={'welcome-description'}>
        Here you will find amazing content from across the MultiversX,
        created by people all over the world pushing the boundaries of creativity and technology.
      </p>
      <Button to={'/welcome'} buttonType={'secondary'} className={'welcome-button'}>
        Read More
        {' '}
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </div>
    <div className={'col-12 col-lg-6 col-md-6'}>
      <img src={image} alt={'...'} className={'welcome-image'} />
    </div>
  </div>
</div>
