import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { useProfileModalPath } from 'account/hooks/use-profile-modal-path'
import { useCallback } from 'react'

export const useOnClickBlacklistedCollection = () => {
  const navigate = useLayeredNavigate()
  const path = useProfileModalPath('add-or-remove-blacklisted-collection')

  return useCallback(() => {
    navigate(path, 'modal')
  }, [navigate, path])
}
