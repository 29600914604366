import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'modals/account/photo/slices/update-profile-photo'
import { useProfilePhotoResult } from 'modals/account/photo/hooks/use-profile-photo-result'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'

export const useHandleResult = () => {
  const data = useProfilePhotoResult()
  const dispatch = useDispatch()
  const closeLayer = useCloseLayerNavigate()

  useEffect(() => {
    if (data) {
      closeLayer()
      dispatch(actions.reset({}))
    }
  }, [closeLayer, data, dispatch])
}
