import { combineReducers } from 'redux'
import { transactions } from 'notifications/transactions/slices/transactions'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import { account } from '../account/slices'

const transactionsPersistConfig = {
  key: 'notification:transactions',
  storage: storageSession,
  timeout: 5 * 24 * 60 * 60 * 1000,
}

export const notifications = combineReducers({
  transactions: persistReducer(transactionsPersistConfig, transactions.reducer),
  account: account.reducer,
})

