import { ReactComponent as Launching } from 'startup/assets/mint-launching.svg'
import { ReactComponent as Live } from 'startup/assets/mint-live.svg'
import { ReactComponent as Ended } from 'startup/assets/mint-ended.svg'
import { useCampaignStatus } from 'components/campaign-status/hooks/use-campaign-status'
import { Time } from './time'

const props = {
  NotStarted: {
    icon: <Launching />,
    text: 'Launching',
  },
  Running: {
    icon: <Live />,
    text: 'Live',
  },
  Ended: {
    icon: <Ended />,
    text: 'Ended',
  },
}

export const Content = ({ campaign }) => {
  const status = useCampaignStatus(campaign)

  return <div className={'campaign-status d-flex align-items-center w-100'}>
    <div className={'campaign-status-icon'}>{props[status].icon}</div>
    <div className={'d-flex flex-column flex-grow-1'}>
      <span className={'campaign-status-label'}>Status</span>
      <span className={'campaign-status-text flex-grow-1'}>{props[status].text}</span>
    </div>
    {status !== 'Ended' && <div className={'d-flex flex-column align-items-end'}>
      <span className={'campaign-status-label'}>Time left</span>
      <Time campaign={campaign} />
    </div> }
  </div>
}

