import 'nft-page/details/other-details/key-pair.scss'
import { Warning } from 'nft-page/details/other-details/warning'

export const KeyPair = ({ icon, title, children, showWarning = false }) => <div className={`nft-page-key-pair`}>
  <div className={'nft-page-key-pair-title'}>
    <div className={'nft-page-key-pair-icon'}>{icon}</div>
    <span>{title}</span>
    {showWarning && <Warning />}
  </div>
  <div className={'nft-page-key-pair-value'}>
    {children}
  </div>
</div>
