import { PlaceBid } from 'modals/auction/bid/content/place-bid'
import { BuyNow } from 'modals/auction/bid/content/buy-now'
import { useParams } from 'react-router-dom'

const condition = id => id === `place-bid`

const placeBid = auction => auction ? <PlaceBid auction={auction} /> : ''

const buyNow = auction => auction ? <BuyNow auction={auction} /> : ''

export const Form = ({ auction }) => {
  const { modalName } = useParams()

  return condition(modalName) ? placeBid(auction) : buyNow(auction)
}
