import { gql } from 'graphql-tag'

export const CAMPAIGNS = gql`
    query getCampaigns($pagination: ConnectionArgs, $filters: CampaignsFilter){
        campaigns(pagination: $pagination, filters: $filters) {
            edges {
                cursor
                node{
                    status
                    maxNftsPerTransaction
                    minterAddress
                    availableNfts
                    totalNfts
                    campaignId
                    startDate
                    coverImage
                    mediaType
                    endDate
                    startDate
                    tiers{
                        tierName
                        campaignId
                        availableNfts
                        mintPrice{
                            amount
                            token
                        }
                        totalNfts
  
                    }

                }
            }
            pageData {
                count
                limit
                offset
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
