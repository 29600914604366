import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'subdomain/modals/account/my-nfts/behaviour'
import { ModalContent } from 'subdomain/modals/account/my-nfts/modal-content'
import { Title } from 'subdomain/modals/account/my-nfts/title'
import { List } from 'subdomain/modals/account/my-nfts/list'
import { Failure } from 'failure/index'
import { Empty } from 'subdomain/modals/account/my-nfts/empty'
import 'subdomain/modals/account/my-nfts/style.scss'

export const MyNfts = () => <BootstrapModal id={'my-nfts'} className={'my-nfts-modal'} easyClose>
  <Behaviour />
  <ModalContent>
    <Title />
    <Failure.Partial id={'modal.account.myNfts'}>
      <List />
      <Empty />
    </Failure.Partial>
  </ModalContent>
</BootstrapModal>

export { reducer } from 'subdomain/modals/account/my-nfts/reducer'
export { epic } from 'subdomain/modals/account/my-nfts/epic'
