import { useActionStatus } from 'action-statuses/hooks/use-action-status'
import { identifiersForPrefix } from 'action-statuses/hooks/identifiers-for-prefix'

const matchesStatuses = statuses => ({ status }) => statuses.includes(status)

export const useStatusesByPrefix = (prefix, statuses = ['success']) => {
  const identifiers = identifiersForPrefix(prefix)

  return useActionStatus(identifiers).filter(matchesStatuses(statuses))
}
