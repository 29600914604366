import { AtSign } from 'components/username/at-sign'
import { highlightText } from 'common/highlight-text'
import { useUsername } from 'components/username/hooks/use-username'
import { usePurgedHerotag } from 'components/username/hooks/use-purged-herotag'

export const LinkContent = ({ herotag, address, padding, highlightedText, isPrivate }) => {
  const username = useUsername({ address, padding, herotag, isPrivate })
  const purgedHerotag = usePurgedHerotag(herotag)

  return <>
    {!isPrivate && <AtSign herotag={purgedHerotag} />}
    {username && <span className={'username-username'}
      // eslint-disable-next-line react/no-danger
                       dangerouslySetInnerHTML={{ __html: highlightText(highlightedText, username) }} />}

  </>
}
