import * as Yup from 'yup'

const tokenName = Yup.string()
  .min(3, 'too short')
  .max(20, 'too long')
  .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters are allowed')
  .required('required')
  .default('')

const tokenTicker = Yup.string()
  .min(3, 'too short')
  .max(10, 'too long')
  .required('required')
  .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters are allowed')
  .transform(value => value?.toUpperCase())
  .default('')

export const issueCollectionSchema = () => Yup.object().shape({
  isSemiFungible: Yup.string().oneOf(['true', 'false'])
    .default('false'),
  canFreeze: Yup.boolean().default(true),
  canWipe: Yup.boolean().default(true),
  canPause: Yup.boolean().default(true),
  canTransferNFTCreateRole: Yup.boolean().default(true),
  tokenName,
  tokenTicker,
})
