import { VisibleIfActive } from 'modals/auction/start/tabs-content/visible-if-active'
import { Single } from 'modals/auction/start/tabs-content/single'
import { Variable } from 'modals/auction/start/tabs-content/variable'
import { Bundle } from 'modals/auction/start/tabs-content/bundle'

export const TabsContent = ({ asset, assetOwner }) => <div>
  <VisibleIfActive tab={'single'}>
    <Single asset={asset} assetOwner={assetOwner} />
  </VisibleIfActive>
  <VisibleIfActive tab={'variable'}>
    <Variable asset={asset} assetOwner={assetOwner} />
  </VisibleIfActive>
  <VisibleIfActive tab={'bundle'}>
    <Bundle asset={asset} assetOwner={assetOwner} />
  </VisibleIfActive>
</div>
