import { useInitializeTimeLeft } from 'nft-page/offers/content/hooks/use-initialize-time-left'
import { useDecrementTimeLeft } from 'nft-page/offers/content/hooks/use-decrement-time-left'
import { useFetchEndedAuction } from 'nft-page/offers/content/hooks/use-fetch-ended-offers'

export const Behaviour = ({ offer }) => {
  useInitializeTimeLeft(offer)
  useDecrementTimeLeft()
  useFetchEndedAuction()

  return ''
}
