import { DataCard } from 'components/data-card'
import { List } from 'feed/suggestions/list'
import { EmptyWrapper } from 'feed/initial/empty-wrapper'
import { useHasMore } from 'feed/hooks'

const useClassName = () => {
  const hasMore = useHasMore()
  const initial = 'd-none d-lg-block'

  return hasMore ? initial : ''
}

export const Suggestions = () => <DataCard title={'Suggestions'} smallTitle className={useClassName()}>
  <EmptyWrapper fullscreen={false}>
    <List />
  </EmptyWrapper>
</DataCard>

export * from 'feed/suggestions/reducer'
export * from 'feed/suggestions/epic'
