import { ReactComponent as Cup } from 'startup/assets/cup.svg'
import { ReactComponent as Triangle } from 'startup/assets/outbidded-badge.svg'
import { ReactComponent as Ended } from 'startup/assets/ended-badge.svg'

const ended = name => <>
  <span className={'account-notifications-item-icon'}><Ended /></span>
  <span className={'account-notifications-item-text'}>
    Auction for
    {' '}
    <span className={'account-notifications-item-name'}>{name}</span>
    {' '}
    has ended.
  </span>
</>

const outbidded = name => <>
  <span className={'account-notifications-item-icon'}><Triangle /></span>
  <span className={'account-notifications-item-text'}>
    You were outbidded in the
    {' '}
    <span className={'account-notifications-item-name'}>{name}</span>
    {' '}
    auction.
  </span>
</>

const won = name => <>
  <span className={'account-notifications-item-icon'}><Cup /></span>
  <span className={'account-notifications-item-text'}>
    You won the auction for
    {' '}
    <span className={'account-notifications-item-name'}>{name}</span>
    {' '}
    !
  </span>
</>

const bought = name => <>
  <span className={'account-notifications-item-icon'}><Cup /></span>
  <span className={'account-notifications-item-text'}>
    You bought
    {' '}
    <span className={'account-notifications-item-name'}>{name}</span>
    {' '}
    !
  </span>
</>

const sold = name => <>
  <span className={'account-notifications-item-icon'}><Cup /></span>
  <span className={'account-notifications-item-text'}>
    You sold
    {' '}
    <span className={'account-notifications-item-name'}>{name}</span>
    {' '}
    !
  </span>
</>

const offerExpired = name => <>
  <span className={'account-notifications-item-icon'}><Cup /></span>
  <span className={'account-notifications-item-text'}>
    Your offer for
    {' '}
    <span className={'account-notifications-item-name'}>{name}</span>
    {' '}
    expired!
  </span>
</>

const offerReceived = name => <>
  <span className={'account-notifications-item-icon'}><Cup /></span>
  <span className={'account-notifications-item-text'}>
    You received an offer for
    {' '}
    <span className={'account-notifications-item-name'}>{name}</span>
    {' '}
    !
  </span>
</>

export const makeNotification = (name, type) => {
  const texts = {
    Ended: ended(name),
    Outbidded: outbidded(name),
    Won: won(name),
    Bought: bought(name),
    Sold: sold(name),
    OfferExpired: offerExpired(name),
    OfferReceived: offerReceived(name),
  }

  return texts[type]
}
