import { useAuthorizedAction } from 'common/hooks/use-authorized-action'
import { useOnUnFollow } from 'explore-nfts/tabs/creators/hooks/use-on-unfollow'
import { useOnFollow } from 'explore-nfts/tabs/creators/hooks/use-on-follow'

export const useOnClick = (setIsFollowedLocal, address, isFollowedLocal) => {
  const onUnfollow = useOnUnFollow(setIsFollowedLocal, address)
  const onFollow = useOnFollow(setIsFollowedLocal, address)

  return useAuthorizedAction(isFollowedLocal ? onUnfollow : onFollow)
}
