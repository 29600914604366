import { Button } from 'nft-page/auction/actions/sell-more/button'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useShouldHideSellMore } from 'nft-page/auction/actions/sell-more/hooks/use-should-hide-sell-more'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'

export const SellMore = () => {
  const { asset } = useAssetWithOwner()
  const { address } = useGetAccountInfo()
  const shouldHideSellMore = useShouldHideSellMore()
  const isSubdomain = useIsSubdomain()

  return shouldHideSellMore && isSubdomain ? <Button asset={asset} address={address} /> : ''
}
