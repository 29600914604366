import { useCallback } from 'react'
import { useSetLocationFilters } from 'filters/hooks/use-set-location-filters'

export const useFilterSetter = name => {
  const setLocationFilters = useSetLocationFilters()

  return useCallback(({ value }, resetOthers) => {
    setLocationFilters({ name, value }, resetOthers)
  }, [setLocationFilters, name])
}
