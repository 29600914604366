import { useSelectedAuctionLoading } from 'nft-page/auction/hooks/use-selected-auction-loading'
import { useMarketplacesLoading } from 'marketplaces/hooks/use-marketplaces-loading'
import { useOwnersLoading } from 'owners/hooks/use-owners-loading'

export const useDependenciesLoading = () => {
  const marketplacesLoading = useMarketplacesLoading()
  const selectedAuctionLoading = useSelectedAuctionLoading()
  const ownersLoading = useOwnersLoading()

  return marketplacesLoading || selectedAuctionLoading || ownersLoading
}
