import { Link as AssetLink } from 'react-router-dom'
import 'components/asset-card/link/index.scss'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

export const Link = props => {
  const layeredLocation = useLayeredLocation()
  const { pathname, state } = layeredLocation(props.link, 'foreground', props.previewSource)

  return (
    <AssetLink className={'asset-card-link'}
               to={pathname} state={state} />)
}
