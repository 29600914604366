import { filter } from 'lodash'

import { usePreviewSources } from 'nft-page/controls/hooks/use-preview-sources'
import { usePreviewSource } from 'nft-page/controls/hooks/use-preview-source'

export const useCurrentPreviewSource = () => {
  const currentPreviewSource = usePreviewSource()
  const previewSources = usePreviewSources()

  return filter(previewSources, item => item.name === currentPreviewSource)[0]
}
