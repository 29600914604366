import { useCallback, useEffect, useRef } from 'react'

const makeShouldLoad = (hasMore, isLoading) => hasMore && !isLoading

export const useIntersectionObserver = ({
  loader, hasMore, next, scrollThreshold,
  scrollableArea = null, isLoading,
}) => {
  const observer = useRef(null)
  const shouldLoad = makeShouldLoad(hasMore, isLoading)

  const onIntersect = useCallback(entries => {
    if (entries[0].isIntersecting && shouldLoad) next()
  }, [next, shouldLoad])

  useEffect(() => {
    observer.current = createObserver(onIntersect, scrollableArea, scrollThreshold)
    if (loader.current !== null) observer.current.observe(loader.current)

    return () => observer.current.disconnect()
  }, [loader, scrollableArea, onIntersect, scrollThreshold])
}

const createObserver = (onIntersect, scrollableArea, scrollThreshold) => new window.IntersectionObserver(onIntersect, {
  root: scrollableArea?.current,
  rootMargin: '10px',
  threshold: scrollThreshold,
})
