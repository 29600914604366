import { get } from 'lodash'
import { useSelector } from 'react-redux'

const namePath = 'holorideCampaign.get.data.campaigns.edges[0].node.campaignId'
const imagePath = 'holorideCampaign.get.data.campaigns.edges[0].node.coverImage'
const descriptionPath = 'holorideCampaign.get.data.campaigns.edges[0].node.description'

export const useSeoProps = () => ({
  title: useSelector(state => get(state, namePath)),
  image: useSelector(state => get(state, imagePath)),
  description: useSelector(state => get(state, descriptionPath)),
})
