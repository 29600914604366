import { useField } from 'formik'
import './boolean-buttons-field.scss'
import { Button } from './button'

export const BooleanButtonsField = ({ name, labelTrue = 'True', labelFalse = 'false' }) => {
  const [, meta] = useField(name)

  return <div className={'nerds-form-field boolean-buttons-field d-flex'}>
    <Button name={name} label={labelFalse} className={'me-2'} value={'false'} />
    <Button name={name} label={labelTrue} value={'true'} />

    {meta.touched && meta.error && <div className={'text-warning'}>{meta.error}</div>}
  </div>
}
