import {
  faTwitter, faFacebook, faYoutube, faInstagram, faTelegram,
  faDiscord, faMedium
} from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/pro-light-svg-icons'

export const icons = Object.freeze({
  telegram: faTelegram,
  discord: faDiscord,
  twitter: faTwitter,
  facebook: faFacebook,
  youtube: faYoutube,
  instagram: faInstagram,
  medium: faMedium,
  default: faGlobe,
})
