import match from '@nerds.sh/js-pattern'

import { handleBuyer } from 'auction-flows/handlers/handle-buyer'
import { handleOwner } from 'auction-flows/handlers/handle-owner'
import { isAuctionOwner } from 'auction-flows/conditions/is-auction-owner'

export const handleAuction = input => match(input)
  .with(!isAuctionOwner(input), handleBuyer)
  .with(isAuctionOwner(input), handleOwner)
  .execute()
