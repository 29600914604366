import { TransactionItem } from 'components/toasts/transaction-toast/transaction-item'
import 'components/toasts/transaction-toast/body/index.scss'
import { get } from 'lodash'

export const Body = ({ transaction }) => {
  const transactions = get(transaction, 'transactions', [])

  return <div className={'toast-body'}>
    {transactions.map((tx, id) => <TransactionItem key={id} hash={tx.hash} />)}
  </div>
}
