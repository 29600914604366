import { BN } from 'bn.js'
import { useRealTimeUsdPrice } from 'common/hooks/use-real-time-price'
import { LabeledPrice } from 'components/labeled-price'
import { useField } from 'formik'
import { get } from 'lodash'

const useProps = (tier, campaign) => {
  const [field] = useField('quantity')
  const unitPrice = get(tier, 'mintPrice.amount')
  const total = new BN(unitPrice).mul(new BN(String(field.value)))
    .toString()

  return {
    icon: <img src={campaign.coverImage} alt={'...'} />,
    label: `${field.value} ${tier.tierName}`,
    price: {
      amount: total,
      token: tier.mintPrice.token,
      usdAmount: useRealTimeUsdPrice(total),
    },
  }
}

export const Total = ({ tier, campaign }) => <div className={'buy-campaign-form-total nerds-form-field'}>
  <LabeledPrice {...useProps(tier, campaign)} />
</div>
