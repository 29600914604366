import { Button } from 'account/create-collection/button/button'
import { useIsActiveUser } from 'common/hooks/use-is-active-user'
import { useFollowersIsLoading } from 'account/profile/follow/hooks/use-followers-is-loading'
import { useCreationDisabled } from 'account/hooks/use-creation-disabled'

export const CreateCollection = () => {
  const isActiveUser = useIsActiveUser()

  const isLoading = useFollowersIsLoading()
  const message = 'Minimum 10 followers required to create collection'
  const creationDisabled = useCreationDisabled()

  return isActiveUser ? <Button disabled={!isLoading && creationDisabled} tooltipMessage={message} /> : ''
}
