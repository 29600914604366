import { useCallback } from 'react'
import { useUpdateSocialLinks } from 'modals/account/edit/social-links/hooks/use-update-social-links'
import { useUpdateDescription } from 'modals/account/edit/description/hooks/use-update-description'
import { useUpdatePrivacy } from 'modals/account/edit/privacy/hooks/use-update-privacy'

export const useOnSubmit = () => {
  const updateDescription = useUpdateDescription()
  const updateSocialLinks = useUpdateSocialLinks()
  const updatePrivacy = useUpdatePrivacy()

  return useCallback(data => {
    updateSocialLinks(data)
    updateDescription(data)
    updatePrivacy(data)
  }, [updateDescription, updatePrivacy, updateSocialLinks])
}
