import { combineReducers } from 'redux'
import { primarySale } from 'holoride-campaign/slices/primary-sale'
import { isWhitelisted } from 'holoride-campaign/slices/is-whitelisted'
import { myTickets } from 'holoride-campaign/slices/my-tickets'
import { buyTickets } from 'holoride-campaign/modals/buy/slices/buy-tickets'
import { claimTicket } from 'holoride-campaign/action/redeem/slices/claim-ticket'
import { hasClaimedTickets } from 'holoride-campaign/slices/has-claimed-tickets'

export const reducer = combineReducers({
  get: primarySale.reducer,
  whitelisted: isWhitelisted.reducer,
  myTickets: myTickets.reducer,
  buyTickets: buyTickets.reducer,
  claimTicket: claimTicket.reducer,
  hasClaimedTickets: hasClaimedTickets.reducer,
})
