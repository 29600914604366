import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'
import { addIfNotExisting } from './add-if-not-existing'
import { mergeItems } from './merge-items'

const initialState = {
  success: [],
  attempt: '',
  failure: '',
  loading: false,
}

const attempt = (state, action) => ({ ...state, loading: true, attempt: action.payload })

const success = (state, { payload }) => ({ ...state, loading: false, success: mergeItems(payload, state) })

const append = (state, { payload }) => ({ ...state, loading: false, success: addIfNotExisting(state.success, payload) })

const failure = (state, { payload }) => ({ ...state, loading: false, failure: serializeErrors(payload) })

const reset = () => initialState

export const asset = createSlice({
  initialState,
  name: 'tags/asset',
  reducers: {
    attempt,
    success,
    append,
    failure,
    reset,
  },
})

export const { actions } = asset
