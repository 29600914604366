import { Loading } from 'loading'
import { loading } from 'loading/slices'
import { ofType } from 'redux-observable'
import { auctions } from 'repositories/auctions'
import { actions } from 'account/tabs/assets/claimable/slices/get'
import { actions as listActions } from 'account/tabs/assets/claimable/slices/list'
import { delay, from, mergeMap, catchError } from 'rxjs'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt.type))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => auctions.claimable(payload)
  .pipe(delay(800))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = response => from([
  actions.success(response),
  listActions.append(response.data.myClaimableAuctions.edges),
  loading.actions.hide({ id: Loading.ID.account.tab.claimable, variant: Loading.VARIANT.ghostCard }),
])

const failure = payload => from([
  actions.failure(payload),
  loading.actions.hide({ id: Loading.ID.account.tab.claimable, variant: Loading.VARIANT.ghostCard }),
])
