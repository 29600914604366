import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions as listActions } from 'trending/slices/list'
import { actions as tagActions } from 'trending/slices/tag'
import { actions as tagsActions } from 'tags/slices/auction'
import { actions as trendingActions } from '../slices/trending'

export const useResetList = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tagActions.reset())
    dispatch(tagsActions.reset())
    dispatch(listActions.reset())
    dispatch(listActions.reset())
    dispatch(trendingActions.reset())
  }, [dispatch])
}
