import { useDispatch } from 'react-redux'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { useCallback } from 'react'
import { actions } from 'auction/price/slices'
import { get } from 'lodash'

const makePayload = id => ({
  filters: {
    operator: 'AND',
    filters: [
      { field: 'id', op: 'EQ', values: [`${id}`] },
    ],
  },
})

export const useFetchPrice = () => {
  const dispatch = useDispatch()
  const auction = useNftDefaultAuction()
  const id = get(auction, 'id', '')

  return useCallback(() => {
    id && dispatch(actions.attempt(makePayload(id)))
  }, [dispatch, id])
}
