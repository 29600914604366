import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: '',
  errors: '',
  loading: false,
}

const attempt = state => ({ ...state, loading: true })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload), loading: false })

const reset = () => initialState

export const addReport = createSlice({
  initialState,
  name: 'addReport',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})
export const { actions, reducer } = addReport
