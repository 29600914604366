import { useCallback } from 'react'
import { useHasInsufficientFunds } from 'components/forms/send-offer-form/hooks/use-has-insufficient-funds'

export const useTestBalance = (asset, paymentToken) => {
  const hasInsufficientFunds = useHasInsufficientFunds(paymentToken)

  return useCallback((value, context) => {
    if (!hasInsufficientFunds(value)) return true

    return context.createError({ message: `Insufficient ${paymentToken.symbol} funds`, path: context.path })
  }, [hasInsufficientFunds, paymentToken])
}
