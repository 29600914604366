import { denominateAmount } from 'common/conversion/denominate-amount'

export const ActionLabel = ({ whom }) => <>
  bidded
  {' '}
  <strong>{denominateAmount(whom.price.amount, whom.price.decimals, true)}</strong>
  {' '}
  {whom.price.token}
  {' '}
  on
</>
