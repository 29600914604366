import { of, tap, map } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { history, actions } from 'network'

export const navigate = actions$ => actions$
  .pipe(ofType(actions.TYPES.navigate))
  .pipe(mergeMap(perform))

const perform = action => of({})
  .pipe(tap(() => history.push(action.payload.path, action.payload.state)))
  .pipe(map(() => actions.navigateSuccess()))
