import { useTitleButtonProps } from 'components/accordion/title/hooks/use-title-button-props'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'components/accordion/title/index.scss'

export const Title = ({ id, title }) => <h2 className={'accordion-header'} id={`heading${id}`}>
  <button {...useTitleButtonProps(id)}>
    {title}
    <FontAwesomeIcon icon={faChevronDown} />
  </button>
</h2>
