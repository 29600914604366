import { useExploreAuctionsList } from 'explore-nfts/tabs/nfts/auctions/hooks/use-explore-auctions-list'
import { useIsLoading } from 'explore-nfts/tabs/nfts/auctions/hooks/use-is-loading'
import { Behaviour } from 'explore-nfts/tabs/nfts/auctions/behaviour'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useNext } from 'explore-nfts/tabs/nfts/auctions/hooks/use-next'
import { useHasMore } from 'explore-nfts/tabs/nfts/auctions/hooks/use-has-more'
import { List } from 'explore-nfts/tabs/nfts/auctions/list'
import { Empty } from 'explore-nfts/tabs/nfts/auctions/empty'
import { RenderIf } from 'account/common/render-if'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { useIsPaymentTokensLoading } from 'explore-nfts/current-payment-tokens/hooks/use-is-payment-tokens-loading'

export const Auctions = () => {
  const auctions = useExploreAuctionsList()
  const isLoading = useIsLoading()
  const filterValue = useFilterValue('filter')
  const shouldRender = filterValue !== 'all' && filterValue !== ''
  const isPaymentTokensLoading = useIsPaymentTokensLoading()

  return <RenderIf isTrue={shouldRender && !isPaymentTokensLoading}>
    <Behaviour />
    <InfiniteScroll next={useNext()} hasMore={useHasMore()} isLoading={isLoading}>
      <List auctions={auctions} />
    </InfiniteScroll>
    <Empty />
  </RenderIf>
}
