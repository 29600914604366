import { BootstrapModal } from 'components/bootstrap-modal'
import { Form } from 'modals/account/admin/blacklisted-collections/form'
import { useFetchBlacklistedCollections } from 'admin/hooks/actions/use-fetch-blacklisted-collections'

export const BlacklistedCollections = () => {
  useFetchBlacklistedCollections()

  return <BootstrapModal id={'add-or-remove-blacklisted-collection'} title={'Add or Remove Blacklisted Collections'}>
    <Form />
  </BootstrapModal>
}
