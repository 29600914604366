import { useWatchTransaction } from 'campaign/hooks/use-watch-transaction'
import { useFetch } from 'campaign/hooks/use-fetch'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useResetState } from 'campaign/hooks/use-reset-state'

const useCleanSearch = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.search) {
      navigate(location.pathname, { replace: true })
    }
  }, [navigate, location])
}

export const Behaviour = () => {
  useResetState()
  useCleanSearch()
  useWatchTransaction()
  useFetch()

  return ''
}
