import { get } from 'lodash'
import { shouldUseMinBid } from 'common/should-use-min-bid'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

const defaultValue = () => ({ amount: '0', usdAmount: '0', token: egldLabel() })

export const useCurrentPrice = auction => {
  const minBid = get(auction, 'minBid', defaultValue())
  const topBid = get(auction, 'topBid', defaultValue())

  return shouldUseMinBid(auction) ? minBid : topBid
}

