import { get } from 'lodash'
import { Countdown } from 'holoride-campaign/action/redeem/countdown'

export const Counter = ({ primarySale }) => {
  const endClaim = get(primarySale, 'saleTime.endClaim')

  return <div className={'d-flex align-items-center flex-column mb-3'}>
    <span className={'holoride-campaign-buy-label'}>Claim ends in: </span>
    <Countdown endDate={endClaim} />
  </div>
}
