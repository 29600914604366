import { useSelector } from 'react-redux'
import { get, orderBy } from 'lodash'

const path = 'nft.auctions.list.data'

export const useList = () => {
  const auctionList = useSelector(state => get(state, path, []))

  return orderBy(auctionList, ['node.status', 'node.endDate'], ['desc', 'desc'])
}
