import { get } from 'lodash'
import { useSelector } from 'react-redux'

const path = 'currentProfile.data.herotag'

export const useCurrentProfileHerotag = () => {
  const herotag = useSelector(state => get(state, path, ''))

  return !herotag ? '' : `@${herotag?.split('.')[0]}`
}
