import { Background } from 'home/cta-section/background'
import { Box } from 'home/cta-section/box'
import { Subtitle } from 'home/cta-section/subtitle'
import { Title } from 'home/cta-section/title'
import { Welcome } from 'home/cta-section/welcome'
import image1 from 'startup/assets/welcome-visual-1.png'
import image2 from 'startup/assets/welcome-visual-2.png'

import 'home/cta-section/index.scss'

const boxes = [
  {
    title: 'Explore',
    subtitle: 'Discover the best, go beyond the surface, learn about what matters.',
  },
  {
    title: 'Collect',
    subtitle: 'Choose favorite creators and projects, make them part of your collection.',
  },
  {
    title: 'Engage',
    subtitle: 'Get involved with the community, represent the projects you believe in.',
  },
]

export const CTASection = () => <div className={'cta-section'}>
  <Background />
  <div className={'cta-section-top'}>
    <img src={image1} className={'welcome-visual-1'} alt={'...'} />
    <img src={image2} className={'welcome-visual-2'} alt={'...'} />
    <div className={'container position-relative'}>
      <Title />
      <Subtitle />
    </div>
  </div>
  <div className={'container'}>
    <Welcome />
    <div className={'cta-section-boxes row'}>
      {boxes.map((props, index) => <div key={index} className={'col-12 col-sm-12 col-md-4 col-lg-4 d-flex'}>
        <Box {...props} />
      </div>)}
    </div>
  </div>
</div>
