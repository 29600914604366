import { useLatestTimestamp } from 'feed/refresh/index'
import { get } from 'lodash'
import { useFeed } from 'feed/hooks'

export const useRefreshVisibility = () => {
  const lastTimestamp = useLatestTimestamp()
  const event = get(useFeed(), '0', { when: Number.MAX_SAFE_INTEGER })

  return event.when < lastTimestamp
}
