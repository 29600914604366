import { useIsLoading as useStatsIsLoading } from 'collections/stats/hooks/use-is-loading'
import { useIsLoading as useCollectionsIsLoading } from 'collections/hooks/use-is-loading'
import { LoadingSpinner } from 'components/loading-spinner'

export const LoadingWrapper = ({ children }) => {
  const statsIsLoading = useStatsIsLoading()
  const collectionsIsLoading = useCollectionsIsLoading()

  return statsIsLoading && collectionsIsLoading ? <LoadingSpinner className={'flex-grow-1'} /> : children
}
