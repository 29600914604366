import { Empty as EmptyState } from 'components/empty'
import { faRocket } from '@fortawesome/pro-light-svg-icons'
import { useTrendingCollections } from 'home/trending-collections/hooks/use-trending-collections'

const stateProps = {
  icon: faRocket,
  message: 'No trending collections found.',
}

export const Empty = () => {
  const collectionsCount = useTrendingCollections().length

  return !collectionsCount && <div className={'carousel-empty'}>
    <EmptyState {...stateProps} />
  </div>
}
