import { Field } from 'holoride-campaign/action/buy/field/index'
import { get } from 'lodash'
import { formatAmount } from '@multiversx/sdk-dapp/utils'

const getAmount = price => price && formatAmount({
  input: price,
  decimals: 18,
  digits: 4,
  showLastNonZeroDecimal: false,
})

export const Price = ({ primarySale }) => {
  const price = get(primarySale, 'price')
  const paymentToken = get(primarySale, 'paymentToken', '')
  const tokenName = paymentToken?.split('-')[0]
  const amount = price ? getAmount(price) : ''

  return <Field label={'Price per NFT:'} value={`${amount} ${tokenName}`} />
}
