import { PlaceBidForm } from 'components/forms/place-bid-form'
import { usePlaceBid } from 'modals/auction/bid/hooks/use-place-bid'
import { usePlaceBidSelector } from 'modals/auction/bid/hooks/use-place-bid-selector'
import { actions } from 'modals/auction/bid/slices/place-bid'
import { get } from 'lodash'
import { useDependenciesLoading } from 'modals/auction/hooks/use-dependecies-loading'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'

const makeInitialValues = auction => ({
  identifier: get(auction, 'identifier', ''),
  auctionId: parseInt(get(auction, 'id', '0')),
})

export const PlaceBid = ({ auction }) => {
  const paymentToken = useFirstAcceptedPaymentToken()
  const placeBid = usePlaceBid(auction, paymentToken)
  const { data, loading, errors } = usePlaceBidSelector()
  const callbackUrl = useCloseLayerLocation()
  const dependenciesLoading = useDependenciesLoading()
  const actionIdentifier = createActionIdentifier(auction, actionTypes.bid)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <PlaceBidForm onSubmit={placeBid} submitErrors={errors} loading={loading || dependenciesLoading}
                       callbackUrl={callbackUrl} initialValues={makeInitialValues(auction)} auction={auction}
                       key={auction.id} paymentToken={paymentToken} />
}
