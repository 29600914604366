import { Price } from '../price'
import { Action } from '../../action'
import { useOnClick } from '../../hooks/use-on-click'
import { usePermittedAction } from '../../../auction-flows/hooks/use-permitted-action'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'

export const FastBuyInfo = ({ tiers }) => {
  const shouldDisplay = tiers.length === 1
  const buyClick = useOnClick(tiers[0].tierName)
  const { address } = useGetAccountInfo()
  const onClick = usePermittedAction(buyClick, address)

  if (shouldDisplay) {
    return <>
      <Price mintPrice={tiers[0].mintPrice} />
      <Action onClick={onClick} disabled={tiers[0].availableNfts < 0} />
    </>
  } else {
    return ''
  }
}
