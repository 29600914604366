import { useLocation } from 'react-router-dom'
import { parse } from 'common/query-string'
import { useMemo } from 'react'

export const useFilters = () => {
  const { state, search } = useLocation()

  return useMemo(() => ({
    search: parse(search),
    background: parse(state?.layerLink?.background?.split('?')[1]),
    foreground: parse(state?.layerLink?.foreground?.split('?')[1]),
  }), [search, state])
}
