import { Loading } from 'loading'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { useFetchCollections } from 'collections/hooks/use-fetch-collections'
import { settings } from 'settings/fetching'

export const useIssueServer = () => {
  const { identifier: collection } = useParams()
  const fetchCollection = useFetchCollections()

  return useCallback(() => {
    fetchCollection({ collection })
  }, [fetchCollection, collection])
}

export const useFetchCollection = () => {
  const issueServer = useIssueServer()
  const hideGhosts = Loading.useHideGhosts()
  const showGhosts = Loading.useShowGhosts(Loading.ghosts.collections)

  useEffect(() => {
    hideGhosts(Loading.ID.collection.page)
    showGhosts(Loading.ID.collection.page, settings().listRequestSize)
    issueServer()
  }, [hideGhosts, issueServer, showGhosts])
}
