import { get } from 'lodash'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { useCurrentPaymentTokens } from 'explore-nfts/current-payment-tokens/hooks/use-current-payment-tokens'

export const useTokenDecimals = () => {
  const filterTokenValue = useFilterValue('token')
  const collectionPaymentTokens = useCurrentPaymentTokens()
  const token = collectionPaymentTokens.find(item => item.identifier === filterTokenValue)

  return get(token, 'decimals')
}
