import { from, throwError, of } from 'rxjs'
import { getAddress } from '@multiversx/sdk-dapp/utils'
import { catchError, mergeMap } from 'rxjs/operators'
import { settings } from 'settings/fetching'
import { rest } from 'network'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

const hasEnoughBalance = ({ data, balance, decimals, payload, paymentToken }) => {
  const price = get(payload, 'price')
  const tokenName = paymentToken?.split('-')[0]
  const totalCosts = parseFloat(denominateAmount(price, decimals))
  const formattedTotalValue = denominateAmount(price, decimals, true)
  const error = {
    graphQLErrors:
      [{ message: `Not enough token balance - Required ${formattedTotalValue} ${tokenName}` }],
  }
  const parsedBalance = balance ? parseFloat(denominateAmount(balance, decimals)) : 0

  return totalCosts <= parsedBalance ? of(data) : throwError(error)
}

const makeRestError = error => {
  if (error.graphQLErrors) {
    return error
  }

  return ({ result: { networkError: { result: { errors: [{ message: error.statusText }] } } } })
}

export const throwOnTokenInsufficientFunds = ({ data, payload, paymentToken }) => from(getAddress())
  .pipe(mergeMap(address => rest().get(`${settings().apiAddress}/accounts/${address}/tokens/${paymentToken}`)))
  .pipe(mergeMap(response => of(response.data)))
  .pipe(mergeMap(({ balance, decimals }) => hasEnoughBalance({ data, balance, decimals, payload, paymentToken })))
  .pipe(catchError(error => throwError(makeRestError(error))))
