import { useList } from 'trending/hooks/use-list'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useHasMore } from 'trending/hooks/use-has-more'
import { useNext } from 'trending/hooks/use-next'
import { Row } from 'components/row'
import { Item } from 'trending/item'
import { useIsLoading } from 'trending/hooks/use-is-loading'

export const List = () => {
  const data = useList()
  const isLoading = useIsLoading()

  return <InfiniteScroll hasMore={useHasMore()} next={useNext()} isLoading={isLoading}>
    <Row>
      {data.map((auction, index) => <Item item={auction} key={index} />)}
    </Row>
  </InfiniteScroll>
}
