import 'account/header/index.scss'
import { Username } from 'components/username'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { Empty } from 'account/content/empty'
import { Cover } from 'account/content/private-account/cover'
import { Avatar } from 'account/content/private-account/avatar'

export const PrivateAccount = () => <div className={'account-header'}>
  <Cover />
  <Avatar />
  <Username address={useViewerAddress()} />
  <Empty />
</div>
