import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { useGetNotifications } from 'notifications/account/hooks/use-get-notifications'

export const Button = () => {
  const notifications = useGetNotifications()
  const bulletClass = notifications?.length > 0 ? 'has-notifications' : ''

  return <div className={`account-notifications-button dropdown-toggle ${bulletClass}`}
              data-bs-toggle={'dropdown'}
              type={'button'} id={'accountNotificationDropdown'}>
    <FontAwesomeIcon icon={faBell} />
  </div>
}
