import { useProfile } from 'account/profile/hooks/use-profile'
import { useSocialLinks } from 'account/profile-social-links/hooks/use-social-links'
import { usePageCleanUp } from 'account/hooks/use-page-clean-up'
import { useFetchStats } from 'account/stats/hooks/use-fetch-stats'
import { useFollowers } from 'account/profile/follow/hooks/use-followers'
import { useFollowees } from 'account/profile/follow/hooks/use-followees'
import { useFollows } from 'account/profile/follow/hooks/use-follows'

export const Behaviour = () => {
  useProfile()
  useSocialLinks()
  useFollowers()
  useFollowees()
  useFollows()
  usePageCleanUp()
  useFetchStats()

  return ''
}
