import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  attempt: undefined,
  data: {
    assets: {
      edges: [],
      pageInfo: '',
      pageData: '',
    },
  },
  failure: undefined,
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload.data })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

const reset = () => initialState

export const nft = createSlice({
  initialState,
  name: 'nft-page/nft',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = nft
