import PropTypes from 'prop-types'

import { useProps } from 'components/container/hooks/use-props'

export const Container = ({ children, ...props }) => <div {...useProps(props)}>
  {children}
</div>

Container.defaultProps = { className: '' }

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
}
