import { usePredefinedRoles } from 'components/forms/set-roles-form/hooks/use-predefined-roles'

export const useInitialValues = () => {
  const result = {}

  usePredefinedRoles().forEach(role => {
    result[role.id] = role.default
  })

  return result
}
