import { graph } from 'network'
import { CREATE_NFT } from 'repositories/nft/mutations/create-nft'
import { VERIFY_CONTENT } from 'repositories/nft/mutations/verify-content'
import { ADD_REPORT } from 'repositories/nft/mutations/add-report'

export const nft = {
  createNft: payload => graph().mutate({ mutation: CREATE_NFT, variables: payload }),
  verifyContent: payload => graph().mutate({ mutation: VERIFY_CONTENT, variables: payload }),
  addReport: payload => graph().mutate({ mutation: ADD_REPORT, variables: payload }),
}

