import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { get } from 'lodash'
import { useCallback } from 'react'

export const useMakePayload = cursor => {
  const auction = useNftDefaultAuction()
  const id = get(auction, 'id', '')
  const marketplaceKey = get(auction, 'marketplaceKey', '')

  return useCallback(() => ({
    pagination: { first: 10, after: cursor },
    filters: {
      operator: 'AND',
      filters: [
        { field: 'auctionId', op: 'EQ', values: [id] },
        { field: 'marketplaceKey', values: [marketplaceKey], op: 'EQ' }],
    },
    sorting: { direction: 'DESC', field: 'id' },
  }), [cursor, id, marketplaceKey])
}
