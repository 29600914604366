import moment from 'moment/moment'

export const subdomainFilters = ({ identifier, after, status, marketplaceKey }) => ({
  pagination: { first: 3, after },
  filters: {
    operator: 'AND',
    filters: [
      { field: 'identifier', op: 'EQ', values: [identifier] },
      { field: 'status', op: 'EQ', values: [status] },
      { field: 'startDate', values: [`${moment().unix()}`], op: 'LE' },
      { field: 'marketplaceKey', values: [marketplaceKey], op: 'EQ' },
    ],
  },
  grouping: { groupBy: 'IDENTIFIER' },
})
