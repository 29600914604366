import { get } from 'lodash'
import { useSelector } from 'react-redux'

const NFT_PATH = 'modals.collection.create.issue.nft.data'
const SFT_PATH = 'modals.collection.create.issue.sft.data'

export const useData = () => {
  const nft = useNftData()
  const sft = useSftData()

  return nft || sft
}

export const useSftData = () => useSelector(state => get(state, SFT_PATH, ''))

export const useNftData = () => useSelector(state => get(state, NFT_PATH, ''))
