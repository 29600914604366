import jsonp from 'jsonp'
import { useCallback, useState } from 'react'

/* eslint-disable */
export const useOnSubmit = () => {
  const [status, setStatus] = useState(false)
  const url = 'https://multiversx.us21.list-manage.com/subscribe/postJson?u=a7f36798632e562d82d2065cd&amp;id=f1ce621bf9&amp;f_id=00edd0e1f0&'

  const onSubmit = useCallback(values => {
    const data = { EMAIL: values.email }
    const params = new URLSearchParams(data).toString()
    setStatus({ status: 'sending', message: null })

    jsonp(`${url}${params}`, { param: 'c' }, (err, data) => {
      console.log(err, data)

      if (err) {
        setStatus({
          status: 'error',
          message: err,
        })
      } else if (data.result !== 'success') {
        setStatus({
          status: 'error',
          message: data.msg,
        })
      } else {
        setStatus({
          status: 'success',
          message: data.msg,
        })
      }
    })
  }, [])

  return { status, onSubmit, setStatus }
}
