import { createContext, useContext as useReactContext, useState } from 'react'
import { useIsFollowed } from 'account/profile/follow/hooks/use-is-followed'

export const Context = createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultContext = () => {
  const [isFollowed, setIsFollowed] = useState(useIsFollowed())

  return {
    isFollowed,
    setIsFollowed,
  }
}
