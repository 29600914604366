import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMakeAction } from 'modals/collection/create/issue/hooks/use-submit-callback/use-make-action'
import { useMakePayload } from 'modals/collection/create/issue/hooks/use-submit-callback/use-make-payload'

export const useSubmitCallback = () => {
  const dispatch = useDispatch()
  const makeAction = useMakeAction()
  const makePayload = useMakePayload()

  return useCallback(values => {
    const action = makeAction(values)
    const payload = makePayload(values)

    dispatch(action(payload))
  }, [dispatch, makeAction, makePayload])
}
