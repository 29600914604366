import { Title } from 'components/forms/nerds-form/title'
import { Form } from 'modals/collection/create/issue/form'
import { faCubes } from '@fortawesome/pro-light-svg-icons'
import { Behaviour } from 'modals/collection/create/issue/behaviour'

export const Issue = () => <div className={'modal-content'}>
  <Behaviour />
  <Title icon={faCubes} title={`Create Collection`} />
  <Form />
</div>
