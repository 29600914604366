import { useField } from 'formik'
import './input.scss'
import { parseInt } from 'lodash'

export const Input = ({ name, placeholder, defaultValue, disabled }) => {
  const [field,, { setTouched, setValue }] = useField(name)
  const onIntegerCheck = e => setValue(parseInt(e.target.value || 0))

  return <input className={'form-control pe-0 with-icon'}
                type={'text'} name={name} id={name}
                onChange={onIntegerCheck} value={field.value}
                onInput={() => setTouched()}
                onBlur={field.onBlur}
                placeholder={placeholder}
                defaultValue={defaultValue} disabled={disabled} autoComplete={'off'} />
}
