import { useGetSignedTransactions } from '@multiversx/sdk-dapp/hooks'
import { useSeenTransactions } from 'notifications/transactions/hooks/use-seen-transactions'

export const useUnseenToasts = () => {
  const { signedTransactions: toasts } = useGetSignedTransactions()
  const seenTransactionIds = useSeenTransactions()

  return Object.entries(toasts).filter(toast => toast[1].status !== 'cancelled')
    .filter(toast => !seenTransactionIds.includes(toast[0]))
}
