import { ReactComponent as MetaspaceIcon } from 'startup/assets/metaspace.svg'
import 'components/forms/nerds-form/fields/price-field/currency/index.scss'
import { Balance } from 'components/forms/nerds-form/fields/price-field/balance'

export const Currency = ({ paymentToken }) => <div className={'price-field-currency d-flex align-items-center'}>
  <MetaspaceIcon />
  <div>
    <div className={'price-field-currency-currency'}>{paymentToken.symbol}</div>
    <Balance />
  </div>
</div>
