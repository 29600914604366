import { Loading } from 'loading'
import { useCallback } from 'react'
import { settings } from 'settings/fetching'
import { useIssueServer } from 'explore-nfts/tabs/creators/hooks/use-issue-server'

export const useNext = customCursor => {
  const hideGhosts = Loading.useHideGhosts()
  const showGhosts = Loading.useShowGhosts()
  const issueServer = useIssueServer(customCursor)

  return useCallback(() => {
    hideGhosts(Loading.ID.explore.page)
    showGhosts(Loading.ID.explore.page, settings().listRequestSize)
    issueServer()
  }, [hideGhosts, issueServer, showGhosts])
}
