import { settings } from 'settings/fetching'
import moment from 'moment'

const statusFilter = () => ({
  field: 'status',
  values: ['Running', 'Claimable'],
  op: 'IN',
})

const ownerFilter = address => ({
  field: 'ownerAddress',
  values: [address],
  op: 'EQ',
})

const startDateFilter = () => ({
  field: 'startDate',
  values: [`${moment().unix()}`],
  op: 'LE',
})

const marketPlaceFilters = marketplaceKey => ({
  field: 'marketplaceKey',
  values: [marketplaceKey],
  op: 'EQ',
})

const getFilters = (isOwner, viewerAddress, marketplaceKey) => {
  const allFilters = [ownerFilter(viewerAddress), statusFilter(), startDateFilter(), marketPlaceFilters(marketplaceKey)]
  const ownerFilters = [ownerFilter(viewerAddress), statusFilter(), marketPlaceFilters(marketplaceKey)]

  return !isOwner ? allFilters : ownerFilters
}

export const payload = ({ viewerAddress, cursor, isOwner, marketplaceKey }) => ({
  pagination: { first: settings().listRequestSize, after: cursor },
  sorting: [{
    field: 'endDate',
    direction: 'DESC',
  }],
  filters: {
    operator: 'AND',
    filters: getFilters(isOwner, viewerAddress, marketplaceKey),
  },
})
