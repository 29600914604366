import { Row } from 'components/row'
import { Empty } from 'account/header/user-info/empty'
import { ProfilePhoto } from 'account/profile-photo'
import { Profile } from 'account/profile'

export const UserInfo = () => <Row>
  <Empty />
  <ProfilePhoto />
  <Profile />
</Row>
