export const menuLinks = [
  {
    label: 'Home',
    path: '/',
    authenticated: true,
  },
  {
    label: 'Feed',
    path: '/feed',
    authenticated: false,
  },
  {
    label: 'Blog',
    path: 'https://blog.xspotlight.com/',
    authenticated: true,
    external: true,
    blog: true,
  },
  {
    label: 'Explore',
    path: '/collections?filter=verified',
    activePaths: ['/nfts', '/collections', '/creators'],
    authenticated: true,
  },
]
