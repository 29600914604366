import React from 'react'
import { useOnClick } from 'collections/actions/set-roles/hooks/use-on-click'
import { useIsOwner, useRolesClassname } from 'collections/actions/link/hooks'
import { useStatus } from 'nft-page/auction/actions/watchable-action/hooks/use-status'
import { actionTypes } from 'action-statuses/actions-types'

export const Role = ({ collection }) => {
  const isOwner = useIsOwner(collection)
  const onClick = useOnClick()
  const status = useStatus(collection.node, actionTypes.setRoles)
  const className = useRolesClassname(collection, !!status)

  return isOwner && <a onClick={onClick} className={className} role={'button'}>
    Set roles
  </a>
}
