import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { accounts as repository } from 'repositories/accounts'
import { actions } from 'account/stats/slices/get'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.getStats(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = payload => from([
  actions.failure(payload),
])
