import 'collections/actions/link/style.scss'
import { hasRequiredRoles } from 'collections/actions/has-required-roles'
import { Asset } from 'collections/actions/link/asset'
import { Role } from 'collections/actions/link/role'

export const Link = ({ collection }) => {
  const hasRoles = hasRequiredRoles(collection)

  return hasRoles ? <Asset collection={collection} /> : <Role collection={collection} />
}
