import { ofType } from 'redux-observable'
import { unfollow as unfollowSlice } from 'feed/suggestions/slices'
import { followers } from 'repositories/followers'
import { from, of, catchError, mergeMap } from 'rxjs'

export const unfollow = actions$ => actions$
  .pipe(ofType(unfollowSlice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => followers.post.unfollow({ address: payload.address })
  .pipe(mergeMap(result => of(result.data)))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = response => from([
  unfollowSlice.actions.success(response),
])

const failure = response => from([
  unfollowSlice.actions.failure(response),
])
