import confetti from 'startup/assets/icon-confetti.svg'
import chart from 'startup/assets/icon-chart.svg'
import connected from 'startup/assets/icon-connected.svg'
import clock from 'startup/assets/icon-clock.svg'
import edit from 'startup/assets/icon-edit-alt.svg'
import globe from 'startup/assets/icon-globe.svg'

export const boxes = [
  {
    icon: confetti,
    title: 'Creativity and originality',
    subtitle: <>
      The degree to which the project demonstrates creative and original thinking, and how it uses Web3
      culture and technology to push boundaries and create something new.
    </>,
  },
  {
    icon: chart,
    title: 'Potential for growth and scalability',
    subtitle: <>
      The project&apos;s ability to grow and adapt to new users, markets, and technologies, as well as its
      ability to be scaled up and replicated in other contexts.
    </>,
  },
  {
    icon: connected,
    title: 'Community engagement',
    subtitle: <>
      How well the project engages with and builds a community around it, and how it encourages
      participation, feedback, and contributions from the community.
    </>,
  },
  {
    icon: clock,
    title: 'Sustainability',
    subtitle: <>
      The project&apos;s ability to be sustained over time, including its potential for long-term viability,
      and its ability to generate revenue or secure funding.
    </>,
  },
  {
    icon: edit,
    title: 'Implementation',
    subtitle: <>
      The project&apos;s execution, including the quality of the deliverables, the feasibility and the
      effectiveness of the project, and how it meets the project&apos;s original goals and expectations.
    </>,
  },
  {
    icon: globe,
    title: 'Relevance to Web3',
    subtitle: <>
      How well the project is aligned with the core principles and values of Web3 and making use of Web3 technologies.
    </>,
  },
]
