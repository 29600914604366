import { useFilters } from '../../filters/hooks/use-filters'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as auctionActions } from 'tags/slices/auction'
import { actions as assetActions } from 'tags/slices/asset'
import { toUpper } from 'lodash'
import { useIsLoadingAssetTags } from './use-is-loading-asset-tags'
import { useIsLoadingAuctionTags } from './use-is-loading-auction-tags'

export const useAppendSearchTag = entity => {
  const { tag } = useFilters().search
  const dispatch = useDispatch()
  const isLoadingAssetTags = useIsLoadingAssetTags()
  const isLoadingAuctionTags = useIsLoadingAuctionTags()

  useEffect(() => {
    const tagEntry = { label: toUpper(tag), value: tag }
    // eslint-disable-next-line complexity
    setTimeout(() => {
      if (entity === 'auctions') {
        tag && !isLoadingAuctionTags && dispatch(auctionActions.append(tagEntry))
      } else {
        tag && !isLoadingAssetTags && dispatch(assetActions.append(tagEntry))
      }
    }, 333)
  }, [tag, entity, dispatch, isLoadingAssetTags, isLoadingAuctionTags])
}
