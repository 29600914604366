import { useIsAssetOwner } from 'nft-page/offers/hooks/use-is-asset-owner'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { get } from 'lodash'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useIsAuctionContractOwner } from 'nft-page/offers/hooks/use-is-auction-contract-owner'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'

const useIsNft = () => {
  const { asset } = useAssetWithOwner()
  const assetType = get(asset, 'type', '')

  return assetType === 'NonFungibleESDT'
}

const useIsOwnerOrHasOrders = () => {
  const isAssetOwner = useIsAssetOwner()
  const auction = useNftDefaultAuction()
  const orders = get(auction, 'orders.edges', [])
  const isAuctionContractOwner = useIsAuctionContractOwner()

  return !isAssetOwner && !isAuctionContractOwner && !orders.length
}

const useShowMakeOffer = (isNft, isOwnerOrHasOrders) => isNft ? isOwnerOrHasOrders : true

export const useShouldShowMakeOffer = () => {
  const isNft = useIsNft()
  const isOwnerOrHasOrders = useIsOwnerOrHasOrders()
  const isSubdomain = useIsSubdomain()
  const showMakeOffer = useShowMakeOffer(isNft, isOwnerOrHasOrders)

  return isSubdomain ? showMakeOffer : false
}
