import { useIsAuctionEnded } from 'subdomain/components/my-asset-card/badges/hooks/use-is-auction-ended'
import { useIsAuctionWithinDelta } from 'subdomain/components/my-asset-card/badges/hooks/use-is-auction-within-delta'
import { get } from 'lodash'
import { useIsTopBidder } from 'common/hooks/use-is-top-bidder'

export const useBadgeVisibility = ({ auction, outbid, offer }) => {
  const endDate = get(auction, 'endDate')
  const auctionEnded = useIsAuctionEnded(auction, endDate)
  const isAuctionWithinDelta = useIsAuctionWithinDelta(endDate)
  const topBidderAddress = get(auction, 'topBidder.address')
  const isTopBidder = useIsTopBidder(topBidderAddress)
  const isOfferExpired = get(offer, 'status') === 'Expired'
  const isOfferActive = get(offer, 'status') === 'Active'

  return {
    timeLeft: isAuctionWithinDelta,
    outBidded: outbid,
    auctionEnded,
    topBidder: isTopBidder,
    offerExpired: isOfferExpired,
    offerActive: isOfferActive,
  }
}
