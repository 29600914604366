import { useCallback } from 'react'
import { formatAmount } from '@multiversx/sdk-dapp/utils'
import { useFilters } from 'filters/hooks/use-filters'
import { useDebounceChange } from 'filters/hooks/use-debounce-change'
import { useMaxValue } from 'filters/components/price-range/hooks/use-max-value'
import { useMinValue } from 'filters/components/price-range/hooks/use-min-value'

const getRoundValue = value => Math.round(parseFloat(value) * 100) / 100

const getFloorValue = value => {
  const parsedValue = parseFloat(value)

  return parsedValue < 0.01 ? parsedValue : Math.floor(parseFloat(value) * 100) / 100
}

export const useMultiRangeProps = (minBid, maxBid) => {
  const { search } = useFilters()
  const onChange = useDebounceChange(500)
  const maxValue = useMaxValue(search, maxBid)
  const minValue = useMinValue(search, minBid)

  return useCallback(() => {
    const denominatedMin = formatAmount({ input: minBid?.amount, decimals: minBid?.tokenData?.decimals })
    const denominatedMax = formatAmount({ input: maxBid?.amount, decimals: maxBid?.tokenData?.decimals })

    return ({
      step: 1,
      min: getFloorValue(denominatedMin),
      max: getRoundValue(denominatedMax),
      initialMin: getFloorValue(minValue()),
      initialMax: getRoundValue(maxValue()),
      onChange,
    })
  }, [maxBid?.amount, maxBid?.tokenData?.decimals, minBid?.amount, minBid?.tokenData?.decimals,
    minValue, maxValue, onChange])
}
