import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: { assetHistory: { edges: [], pageInfo: { hasNextPage: false, endCursor: '' } } },
  input: '',
  errors: [],
  loading: false,
  cursor: null,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

const reset = () => initialState

const setCursor = (state, { payload }) => ({ ...state, cursor: payload })

export const assetHistory = createSlice({
  initialState,
  name: 'assetHistory',
  reducers: {
    attempt,
    success,
    failure,
    reset,
    setCursor,
  },
})

export const { actions } = assetHistory
