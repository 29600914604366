import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: { page: 1 },
  current: { items: [], hasMore: false },
  list: [],
  errors: null,
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, current: payload })

const failure = (state, { payload }) => ({ ...state, errors: payload, loading: false })

const append = (state, { payload }) => ({ ...state, list: [...state.list, ...payload], loading: false })

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'modals/account/followers/get',
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})
