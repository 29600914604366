import { Controls } from 'nft-page/controls'
import { useSwipeControls } from 'nft-page/media/hooks/use-swipe-controls'
import { useCurrentAsset } from 'nft-page/media/hooks/use-current-asset'

import { ScamChecker } from 'nft-page/media/scam-checker'
import 'nft-page/media/index.scss'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'
import { NotSafeForWorkChecker } from 'nft-page/media/not-safe-for-work-checker'
import { get } from 'lodash'
import { Carousel } from 'nft-page/media/carousel'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { useAddReport } from 'report/hooks/use-add-report'
import { useIsLoading } from 'report/hooks/use-is-loading'
import { ReportButton } from 'components/report-button'

export const Media = () => {
  const currentAsset = useCurrentAsset()
  const medias = get(currentAsset, 'media', [])
  const navigate = useCloseLayerNavigate()

  const { address } = useGetAccountInfo()
  const addReport = useAddReport()
  const onClick = usePermittedAction(addReport, address)

  return <div {...useSwipeControls()} className={'nft-media'}>
    <div className={'nft-media-background'} onClick={() => navigate()} />
    <Controls />
    <ScamChecker asset={currentAsset}>
      <NotSafeForWorkChecker asset={currentAsset}>
        <Carousel medias={medias} />
        <ReportButton onClick={onClick} isLoading={useIsLoading()} isReported={false} />
      </NotSafeForWorkChecker>
    </ScamChecker>
  </div>
}
