import { gql } from 'graphql-tag'

export const GET = gql`
    query likes($filters: AssetsFilter, $address: String!) {
        assets(filters: $filters) {
            edges {
                node {
                    likesCount
                    isLiked(byAddress: $address)
                }
            }
        }
    }
`
