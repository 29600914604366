import { gql } from 'graphql-tag'

export const ASSET_WITH_OWNER = gql`
    query getAssetWithOwner($filters: AssetsFilter, $pagination: ConnectionArgs) {
        assets(filters: $filters, pagination: $pagination)
        {
            edges {
                node{
                    balance
                    ownerAddress
                }
            }
        }
    }
`
