import { Failure } from 'failure'
import { BrowserRouter } from 'react-router-dom'
import { Footer } from 'components/footer'
import { Layers } from 'components/layered-router/layers'
import { RouterContext, useRouterContextValue } from 'components/layered-router/context'
import { Behaviour } from 'components/layered-router/behaviour'
import { PreferencesBehaviour } from 'components/layered-router/preferences-behaviour'

export const LayeredRouter = ({ layers, children }) => <RouterContext.Provider value={useRouterContextValue()}>
  <Behaviour layers={layers} />
  <BrowserRouter>
    {children}
    <Failure.Absolute>
      <PreferencesBehaviour />
      <Layers layers={layers} />
    </Failure.Absolute>
    <Footer />
  </BrowserRouter>
</RouterContext.Provider>

