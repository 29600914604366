import { useField } from 'formik'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Label } from 'components/forms/nerds-form/fields/label'
import { Icon } from 'components/forms/nerds-form/fields/icon'
import { Input } from 'components/forms/nerds-form/fields/price-field/input'
import { ErrorMessage } from 'components/forms/nerds-form/fields/price-field/error-message'
import { Info } from 'components/forms/nerds-form/fields/price-field/info'

const makeClassname = classname => `nerds-form-field ${classname || ''}`

export const PriceField = ({ name, label, placeholder, defaultValue, disabled, className, price, paymentToken }) => {
  const [, meta] = useField(name)

  return <div className={makeClassname(className)}>
    <div className={'price-field'}>
      <Label className={'form-label'} name={name}>{label}</Label>
      <div className={'position-relative'}>
        <Icon icon={faGavel} />
        <Input name={name} placeholder={placeholder} defaultValue={defaultValue} disabled={disabled} autofocus />
        <Info name={name} paymentToken={paymentToken} />
      </div>
    </div>
    <ErrorMessage meta={meta} price={price} paymentToken={paymentToken} />
  </div>
}
