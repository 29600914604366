import { useFollowersCount } from 'account/profile/follow/hooks/use-followers-count'
import { Followers } from 'components/followers'
import { Follow } from 'account/profile/follow'
import { useIsLoggedInUser } from 'account/profile/hooks/use-is-logged-in-user'
import { EditProfile } from 'account/profile/edit-profile'
import 'account/profile/index.scss'
import { useFolloweesCount } from 'account/profile/follow/hooks/use-followees-count'
import { useOnClickFollowers } from 'account/profile/hooks/use-on-click-followers'
import { useOnClickFollowing } from 'account/profile/hooks/use-on-click-following'

const useClassName = () => 'col-12 col-xl profile-container'

const useProps = () => ({ className: useClassName() })

const canClick = (isLoggedInUser, callback) => isLoggedInUser ? callback : null

export const Profile = () => {
  const followersCount = useFollowersCount()
  const followeesCount = useFolloweesCount()
  const isLoggedInUser = useIsLoggedInUser()
  const onFollowersClick = useOnClickFollowers()
  const onFollowingClick = useOnClickFollowing()

  return <div {...useProps()}>
    <div className={'d-flex me-2 follow-buttons'}>
      <Followers count={followeesCount} label={'Following'} onClick={canClick(isLoggedInUser, onFollowingClick)} />
      <span className={'follow-buttons-separator'} />
      <Followers count={followersCount} onClick={canClick(isLoggedInUser, onFollowersClick)} />
    </div>
    {!isLoggedInUser ? <Follow /> : ''}
    {isLoggedInUser ? <EditProfile /> : ''}
  </div>
}
