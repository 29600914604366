import 'components/empty/style.scss'
import { Message } from 'components/empty/message'
import PropTypes from 'prop-types'
import { Actionable } from 'components/empty/actionable'
import { Icon } from 'components/empty/icon'
import { Illustration } from 'components/empty/illustration'
import { Title } from 'components/empty/title'

const makeContainerClassname = props => {
  const fullscreen = props.fullscreen ? 'fullscreen' : ''
  const delayedDisplay = props.delayedDisplay ? 'delayed-display' : ''

  return ` empty-container ${fullscreen} ${delayedDisplay}`
}

export const Empty = props => <div className={makeContainerClassname(props)}>
  {props.icon && <Icon {...props} />}
  {props.illustration && <Illustration {...props} />}
  <Title {...props} />
  <Message message={props.message} />
  <Actionable Action={props.Action} />
</div>

Empty.defaultProps = {
  message: '',
  Action: () => '',
  fullscreen: false,
  icon: undefined,
  illustration: undefined,
  title: '',
}

Empty.propTypes = {
  icon: PropTypes.object,
  illustration: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Action: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  fullscreen: PropTypes.bool,
}
