import { get } from 'lodash'
import DefaultThumbnail from 'startup/assets/nft-image-placeholder.png'

const DEFAULT_OPTIONS = Object.freeze({ useDefault: true })

const getDefaultThumbnailUrl = options => options?.useDefault ? DefaultThumbnail : ''

const getActualThumbnail = (asset, options) => {
  const thumbnailUrl = get(asset, 'media[0].thumbnailUrl', '')
  const deprecatedThumbnailUrl = get(asset, 'thumbnailUrl', '')

  return thumbnailUrl || deprecatedThumbnailUrl || getDefaultThumbnailUrl(options)
}

export const getThumbnailUrl = (asset, options = DEFAULT_OPTIONS) => {
  const isNsfw = get(asset, 'isNsfw', false)
  const scamInfo = get(asset, 'scamInfo.type', '')

  return scamInfo || isNsfw ? getDefaultThumbnailUrl(options) : getActualThumbnail(asset, options)
}
