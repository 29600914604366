import { useAppContext } from 'startup/context'
import { useCallback } from 'react'

export const useOpenSidebar = () => {
  const { setSidebarOpened } = useAppContext()

  return useCallback(() => {
    setSidebarOpened(previous => !previous)
  }, [setSidebarOpened])
}
