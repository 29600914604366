import { Button } from 'components/player-controls/button'
import { useContext } from 'components/player-controls/context'
import { usePlayCallback } from 'components/player-controls/hooks/use-play-callback'

export const Play = () => {
  const { paused } = useContext()
  const handlePlay = usePlayCallback()

  return paused && <Button type={'play'} onClick={handlePlay} />
}
