import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const Type = ({ type }) => {
  const typeRef = useRef(null)
  useTooltip(typeRef)

  return <div className={'nft-page-trait-type'} data-bs-toggle={'tooltip'} data-bs-placement={'top'} title={type}
              ref={typeRef}>
    {type}
  </div>
}
