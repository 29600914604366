export const OptionButton = ({ disabled, selectOption, option }) => {
  const onClick = event => {
    event.stopPropagation()
    selectOption(option)
  }

  return (
    <button disabled={disabled} className={'dropdown-item'} onClick={onClick}>
      <span>
        {option.value}
      </span>
      <span className={'dropdown-count ms-auto'}>
        {option.occurrences}
      </span>
    </button>
  )
}
