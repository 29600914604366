import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { Text } from 'filters/components/traits-button/text'
import { Count } from 'filters/components/traits-button/count'
import 'filters/components/traits-button/index.scss'

export const TraitsButton = ({ label, count, onClick, disabled }) => (
  <button type={'button'} className={`btn traits-button me-2`}
          onClick={() => onClick()} disabled={disabled}>
    <FontAwesomeIcon icon={faFilter} />
    <Text label={label} />
    {!!count && <Count count={count} />}
  </button>
)

TraitsButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

TraitsButton.defaultProps = { onClick: () => {} }
