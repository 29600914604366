import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'
import { uniqWith } from 'lodash/array'

const initialState = {
  loading: false,
  errors: {},
  data: { campaigns: { edges: [], pageInfo: { hasNextPage: false, endCursor: '' } } },
  list: [],
  cursor: '',
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload.data })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload.errors) })

const append = (state, { payload }) => ({ ...state, list: uniqWith([...state.list, ...payload], comparator) })

const comparator = (x, y) => (x.node.mintAddress === y.node.mintAddress && x.node.campaignId === y.node.campaignId)

const reset = () => initialState

export const campaigns = createSlice({
  name: 'campaigns/get',
  initialState,
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})

export const { actions } = campaigns
