import 'sidebar/my-wallet/balance/balance.scss'
import { useEgldSidebarStats } from 'sidebar/my-wallet/balance/hooks/use-egld-sidebar-stats'
import { Content } from 'sidebar/my-wallet/balance/content'
import { useTokenSidebarStats } from 'sidebar/my-wallet/balance/hooks/use-token-sidebar-stats'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { get } from 'lodash'

export const Balance = () => {
  const acceptedPaymentToken = useFirstAcceptedPaymentToken()
  const identifier = get(acceptedPaymentToken, 'identifier', 'EGLD')
  const symbol = get(acceptedPaymentToken, 'symbol')
  const egldStats = useEgldSidebarStats()
  const tokenStats = useTokenSidebarStats(identifier, symbol)
  const stats = identifier === 'EGLD' ? egldStats : tokenStats

  return <div className={'sidebar-balance d-flex justify-content-between'}>
    <Content balance={stats.balance} biddingBalance={stats.biddingBalance} />
  </div>
}
