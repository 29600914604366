import match from '@nerds.sh/js-pattern'
import { useSwipeable } from 'react-swipeable'
import { useNextElement } from 'nft-page/controls/hooks/use-next-element'
import { useOnLeftClick } from 'nft-page/controls/hooks/use-on-left-click'
import { useOnRightClick } from 'nft-page/controls/hooks/use-on-right-click'
import { usePreviousElement } from 'nft-page/controls/hooks/use-previous-element'

export const useSwipeControls = () => {
  const onRightClick = useOnRightClick()
  const onLeftClick = useOnLeftClick()
  const canGoRight = useNextElement().identifier
  const canGoLeft = usePreviousElement().identifier

  const onSwiped = event => match(event)
    .like({ dir: 'Left' }, () => canGoRight && onRightClick())
    .like({ dir: 'Right' }, () => canGoLeft && onLeftClick())
    .otherwise(() => false)

  return useSwipeable({ onSwiped })
}
