import { useNavigate } from 'react-router-dom'
import { useLayeredLocation } from './use-layered-location'
import { useCallback } from 'react'

export const useLayeredReplaceNavigate = () => {
  const navigate = useNavigate()
  const layeredLocation = useLayeredLocation()

  return useCallback((path, layerName, previewSource) => {
    const { pathname, state } = layeredLocation(path, layerName, previewSource)
    navigate(pathname, { state, replace: true })
  }, [navigate, layeredLocation])
}
