import { SftActualOwners } from 'nft-page/details/other-details/owners/sft-actual-owners'
import { useOwners } from 'owners/hooks/use-owners'
import { get } from 'lodash'
import { SftAuctionOwners } from 'nft-page/details/other-details/owners/sft-auction-owners'

export const SftOwners = ({ asset }) => {
  const owners = useOwners()
  const assetMarketplaceAddress = get(asset, 'marketplaces[0].address', '')
  const actualOwners = owners.filter(item => get(item, 'node.address', '') !== assetMarketplaceAddress)

  return actualOwners.length === 0 ? <SftAuctionOwners /> : <SftActualOwners actualOwners={actualOwners} />
}
