import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'trending/slices/trending'

export const useFetchAuctions = () => {
  const dispatch = useDispatch()

  return useCallback(input => {
    dispatch(actions.attempt(input))
  }, [dispatch])
}
