import { Button } from 'components/player-controls/button'
import { useContext } from 'components/player-controls/context'
import { useMuteCallback } from 'components/player-controls/hooks/use-mute-callback'

export const Mute = () => {
  const { muted } = useContext()
  const type = muted ? 'mute' : 'unmute'

  return <Button type={type} onClick={useMuteCallback()} />
}
