import { followers } from 'repositories/followers'
import { mergeMap, of, map } from 'rxjs'
import { settings } from 'settings/fetching'
import { suggestionTypes } from 'common/suggestion-types'

const getType = count => count >= settings().suggestionFolloweesThreshold ? suggestionTypes.additional : suggestionTypes.initial

export const meta = ({ address }) => followers.get.followeesCount({ address })
  .pipe(mergeMap(res => of(res.data)))
  .pipe(map(({ count }) => count))
  .pipe(map(count => ({ followees: count, type: getType(count) })))
