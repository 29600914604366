import { get } from 'lodash'
import { ReactComponent as Arrow } from 'startup/assets/long-arrow-down.svg'
import './index.scss'

export const Hero = ({ data }) => {
  const title = get(data, 'title', '')
  const teaser = get(data, 'teaser', '')
  const logoUrl = get(data, 'logo.data.attributes.formats.thumbnail.url', '')
  const backgroundUrl = get(data, 'background.data.attributes.url', '')

  return <div className={'subdomain-hero'} style={{ backgroundImage: `url(${backgroundUrl})` }}>
    <div className={'subdomain-hero-gradient'} />
    <div className={'container'}>
      <div className={'me-5'}>
        <div className={'mb-2'}>
          <img className={'subdomain-hero-logo'} src={logoUrl} alt={'...'} />
        </div>
        <h1>{title}</h1>
        <div className={'d-flex justify-content-start'}>{teaser}</div>
      </div>
      <Arrow className={'subdomain-hero-arrow'} />
    </div>
  </div>
}
