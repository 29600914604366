import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

export const useCloseLayerNavigate = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const location = useCloseLayerLocation()

  return useCallback(path => {
    if (!state?.hydrated) navigate(-1)
    else navigate(path || location, { replace: true })
  }, [state?.hydrated, navigate, location])
}
