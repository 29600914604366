import { faSignIn } from '@fortawesome/pro-light-svg-icons'
import { Empty } from 'components/empty'
import { Action } from 'feed/action'

const stateProps = {
  icon: faSignIn,
  delayedDisplay: true,
  message: 'Login to see your feed.',
  fullscreen: true,
  Action,
}

export const NotLoggedIn = () => <Empty {...stateProps} />
