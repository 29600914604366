import { useParams } from 'react-router-dom'
import { useIsCollectionWhitelisted } from 'startup/hooks/use-is-collection-whitelisted'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { CollectionContent } from 'collections/collection-content'
import { NotFound } from 'not-found'

export const Content = () => {
  const { identifier } = useParams()
  const isWhiteListed = useIsCollectionWhitelisted(identifier)
  const isSubdomain = useIsSubdomain()

  return isWhiteListed || !isSubdomain ? <CollectionContent /> : <NotFound />
}
