import React from 'react'

const href = '/holoride-terms-of-sale.pdf'

export const ConsentLabel = () => (
  <div>
    You should agree
    {' '}
    <a href={href} target={'_blank'} rel={'noreferrer noopener'}>Terms of Sale</a>
  </div>
)
