import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'
import { uniqBy } from 'lodash'

const initialState = {
  attempt: undefined,
  data: {
    orders: {
      edges: [],
      pageInfo: '',
      pageData: '',
    },
  },
  list: [],
  failure: undefined,
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, input: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload.data })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

const append = (state, { payload }) => ({ ...state, list: uniqBy([...state.list, ...payload], 'node.id') })

const reset = () => initialState

export const orders = createSlice({
  initialState,
  name: 'nft-page/orders',
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})

export const { actions } = orders
