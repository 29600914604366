import { Empty as EmptyComponent } from 'components/empty'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { useList } from 'subdomain/modals/account/my-offers/hooks/use-list'
import { useIsLoading } from 'subdomain/modals/account/my-offers/hooks/use-is-loading'

const emptyProps = {
  icon: faGavel,
  message: 'No offers found.',
}

export const Empty = () => {
  const items = useList()
  const isLoading = useIsLoading()
  const shouldShow = items.length === 0 && !isLoading

  return shouldShow ? <EmptyComponent {...emptyProps} /> : ''
}
