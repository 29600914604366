import { faListCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { Trait } from 'nft-page/details/other-details/properties/trait/index'
import { get } from 'lodash'
import 'nft-page/details/other-details/properties/index.scss'

const makeTraitProps = trait => {
  const type = get(trait, 'attribute[0].value')
  const value = get(trait, 'attribute[1].value')

  return {
    type,
    value,
  }
}

export const Content = ({ traits }) => <>
  <KeyPair icon={<FontAwesomeIcon className={'me-2'} icon={faListCheck} />} title={'Properties'}>
    <div className={'d-inline-flex align-items-center'}>
      <span className={'accent'}>
        <strong>{traits.length}</strong>
        {' '}
        Properties
      </span>
    </div>
  </KeyPair>
  <div className={'nft-page-traits row'}>
    {traits.map((trait, index) => <div key={index} className={'nft-page-trait-col '}>
      <Trait {...makeTraitProps(trait)} />
    </div>)}
  </div>
</>
