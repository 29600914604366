import 'auction/actions/button/button.scss'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const ActionButton = ({ onClick, text, className = 'btn-primary', disabled, tooltipPrice }) => {
  const priceRef = useRef(null)
  useTooltip(priceRef)

  return (
    <button className={`btn action-button btn-lg ${className}`} type={'button'} onClick={onClick} disabled={disabled}
            data-bs-toggle={'tooltip'} data-bs-classname={'tooltip-inner'} data-bs-placement={'top'}
            title={tooltipPrice} ref={priceRef}>
      {text}
    </button>
  )
}
