import { Partner } from 'subdomain/home/partners/partner'
import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section'

export const Partners = ({ data }) => {
  const partners = get(data, 'partners', [])
  const title = get(data, 'title', '')

  return <HomeSection title={title}>
    <div className={'row justify-content-center'}>
      {partners.map((item, index) => <Partner partner={item} key={index} />)}
    </div>
  </HomeSection>
}
