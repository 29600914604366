import match from '@nerds.sh/js-pattern'
import { ReactComponent as Heart } from 'startup/assets/heart.svg'
import { ReactComponent as Cup } from 'startup/assets/cup.svg'
import { ReactComponent as Eyes } from 'startup/assets/eyes.svg'
import { ReactComponent as Finger } from 'startup/assets/finger.svg'
import { ReactComponent as Picture } from 'startup/assets/picture.svg'
import { ReactComponent as Diamond } from 'startup/assets/diamond.svg'
import { ActionTypes } from 'components/feed-card/action-types'
import 'components/feed-card/action-icon/index.scss'

export const ActionIcon = ({ actionType }) => {
  const Icon = match(actionType)
    .with(actionType === ActionTypes.Like, () => Heart)
    .with(actionType === ActionTypes.Won, () => Cup)
    .with(actionType === ActionTypes.Bid, () => Finger)
    .with(actionType === ActionTypes.Follow, () => Eyes)
    .with(actionType === ActionTypes.MintNft, () => Picture)
    .with(actionType === ActionTypes.StartAuction, () => Diamond)
    .with(actionType === ActionTypes.PhotoChanged, () => Picture)
    .with(actionType === ActionTypes.Buy, () => Cup)
    .with(actionType === ActionTypes.SendOffer, () => Diamond)
    .with(actionType === ActionTypes.AcceptOffer, () => Diamond)
    .execute()

  return <div className={'feed-card-action-icon'}>
    <Icon />
  </div>
}
