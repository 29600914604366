import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useMarketplaceAcceptedPaymentTokens } from 'marketplaces/hooks/use-marketplace-accepted-payment-tokens'
import { useMyToken } from 'my-tokens/hooks/use-my-token'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const useBalance = paymentToken => {
  const { account } = useGetAccountInfo()
  const identifier = get(paymentToken, 'identifier')
  const paymentTokens = useMarketplaceAcceptedPaymentTokens()
  const { balance, decimals } = useMyToken(identifier)
  const formattedMyTokenBalance = denominateAmount(balance, decimals)
  const formattedEgldBalance = account.balance !== '...' ? denominateAmount(account.balance) : '0'

  return paymentTokens ? formattedMyTokenBalance : formattedEgldBalance
}
