import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from 'account/tabs/assets/liked/slices/get'

export const useResetLiked = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.cleanUp({}))
  }, [dispatch])
}
