const TYPES = Object.freeze({
  navigate: 'network/navigate',
  navigateSucceeded: 'network/navigateSucceeded',
})

const navigate = (path, state = {}) => ({
  type: TYPES.navigate,
  payload: { path, state },
})

const navigateSuccess = () => ({
  type: TYPES.navigateSucceeded,
  payload: {},
})

export const actions = {
  TYPES,
  navigate,
  navigateSuccess,
}
