import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { flagNft } from 'admin/slices'

export const useClearNftNsfw = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    if (window.confirm('Are you sure?') === true) {
      dispatch(flagNft.actions.attempt({ input: { identifier, nsfwFlag: 0.11 } }))
    }
  }, [dispatch, identifier])
}
