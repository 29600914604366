import { gql } from 'graphql-tag'

export const CLAIMABLE_COUNT = gql`
    query ($pagination: ConnectionArgs) {
        myClaimableAuctions(pagination: $pagination) {
            pageData {
                count
            }
        }
    }
`
