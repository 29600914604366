import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: '',
  input: '',
  errors: [],
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload), loading: false })

const reset = () => initialState

export const endAuction = createSlice({
  initialState,
  name: 'modals/auction/end',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = endAuction
