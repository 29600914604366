import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'notifications/account/slices'
import { useIsPrinceCharming } from 'auth/mega-authorized'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { usePayload } from 'notifications/account/hooks/use-payload'

export const usePoolNotifications = () => {
  const dispatch = useDispatch()
  const isPrinceCharming = useIsPrinceCharming()
  const marketplaceKey = useCmsMarketplaceKey()
  const payload = usePayload({ marketplaceKey })

  useEffect(() => {
    isPrinceCharming && dispatch(actions.attempt(payload))
    const interval = setInterval(() => {
      isPrinceCharming && dispatch(actions.attempt(payload))
    }, 30000)

    return () => clearInterval(interval)
  }, [isPrinceCharming, dispatch, payload])
}
