import { useCallback } from 'react'
import { Loading } from 'loading/index'
import { settings } from 'settings/fetching'

export const useShowGhosts = (factory = Loading.ghosts.assets) => {
  const showLoadings = Loading.useShowLoadings()

  return useCallback((id, count = settings().listRequestSize) => {
    const ghosts = factory(count)
    const metadata = { id, variant: Loading.VARIANT.ghostCard }
    showLoadings(ghosts, metadata)
  }, [factory, showLoadings])
}
