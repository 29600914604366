import { useCallback } from 'react'
import { useNftCollectionIdentifier } from 'startup/hooks/use-subdomain-hydrate-location/use-nft-collection-identifier'

const getModalRoute = () => window.location.pathname.split('/modal')[1] ? window.location.pathname : ''

const getForegroundRoute = () => window.location.pathname.split('/modal')[0]

const getBackgroundRoute = identifier => identifier ? `/collections/${identifier}` : '/'

const makeState = collectionIdentifier => ({
  layerLink: {
    modal: getModalRoute(),
    foreground: getForegroundRoute(),
    background: getBackgroundRoute(collectionIdentifier),
  },
})

export const useMakeNftState = () => {
  const collectionIdentifier = useNftCollectionIdentifier()

  return useCallback(() => makeState(collectionIdentifier), [collectionIdentifier])
}
