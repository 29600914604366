import { useSelector } from 'react-redux'
import { get } from 'lodash'

const path = 'subdomainContent.get.data.data.attributes.subdomain'

export const useCmsMarketplaceKey = () => {
  const subdomain = useSelector(state => get(state, path, ''))

  return subdomain.split('.')[0]
}
