import { get, split } from 'lodash'
import { useMatch } from 'react-router-dom'
import { routeNames } from 'startup/routes'

export const useNftCollectionIdentifier = () => {
  const nftPageRoute = useMatch({ path: routeNames.nftPage })
  const nftPageWithAuctionRoute = useMatch({ path: routeNames.nftPageWithAuction })
  const identifier = get(nftPageRoute, 'params.identifier', '')
  const auctionIdentifier = get(nftPageWithAuctionRoute, 'params.identifier', '')

  return split(identifier || auctionIdentifier, '-', 2).join('-')
}

