import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { get } from 'feed/suggestions/slices'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useSuggestionsType } from 'feed/hooks'

export const useGetSuggestions = () => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()
  const type = useSuggestionsType()

  useEffect(() => {
    type && dispatch(get.actions.attempt({ address, type }))
  }, [dispatch, address, type])
}
