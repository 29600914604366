import { rest } from 'network'
import { of, map, mergeMap } from 'rxjs'
import { settings } from 'settings/fetching'
import { identity } from 'repositories/identity'

const prefix = () => settings().tools

const suffix = () => 'feed-api/v1/accounts/suggested'

const makeUrl = () => `${prefix()}/${suffix()}`

const hydrate = ({ address }) => identity().users(address)
  .pipe(mergeMap(res => of(res.data)))
  .pipe(map(result => ({ ...result, address })))

export const raw = () => rest().get(makeUrl(), {}, true)
  .pipe(mergeMap(res => of(res.data)))
  .pipe(mergeMap(value => value))
  .pipe(mergeMap(hydrate))
