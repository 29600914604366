import moment from 'moment'
import { useEffect } from 'react'
import { useContext } from 'nft-page/offers/content/context'
import { get } from 'lodash'

export const useInitializeTimeLeft = offer => {
  const { setTimeLeft } = useContext()
  const endDate = get(offer, 'endDate')

  useEffect(() => {
    if (endDate) {
      const eventDate = moment(endDate * 1000)
      setTimeLeft(moment.duration(eventDate.diff(moment(), 'milliseconds', true)))
    }
  }, [endDate, setTimeLeft])
}
