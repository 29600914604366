import { useFlagNft, useFlagCollection } from 'admin/hooks/actions'
import { useSetScamNft } from 'admin/hooks/actions/use-set-scam-nft'
import { useSetPotentialScamNft } from 'admin/hooks/actions/use-set-potential-scam-nft'
import { useClearNftScam } from 'admin/hooks/actions/use-clear-nft-scam'
import { useClearNftReport } from 'admin/hooks/actions/use-clear-nft-report'
import { useSetScamCollection } from 'admin/hooks/actions/use-set-scam-collection'
import { useClearCollectionScam } from 'admin/hooks/actions/use-clear-collection-scam'
import { useOnClickAddFeaturedCollection } from 'admin/hooks/actions/use-on-click-add-featured-collection'
import { useAddBlacklistedCollection } from 'admin/hooks/actions/use-add-blacklisted-collection'
import { useOnClickBlacklistedCollection } from 'admin/hooks/actions/use-on-click-blacklisted-collection'
import { useClearReportCollection } from 'admin/hooks/actions/use-clear-report-collection'
import { useClearNftNsfw } from 'admin/hooks/actions/use-clear-nft-nsfw'

// eslint-disable-next-line max-lines-per-function
export const useActionConfig = () => {
  const flagNft = useFlagNft()
  const clearNftNsfw = useClearNftNsfw()
  const flagCollection = useFlagCollection()
  const setScamNft = useSetScamNft()
  const setPotentialScamNft = useSetPotentialScamNft()
  const clearNftScam = useClearNftScam()
  const clearNftReport = useClearNftReport()
  const setScamCollection = useSetScamCollection()
  const clearCollectionScam = useClearCollectionScam()
  const addOrRemoveFeaturedCollection = useOnClickAddFeaturedCollection()
  const addBlacklistedCollection = useAddBlacklistedCollection()
  const addOrRemoveBlacklistedCollection = useOnClickBlacklistedCollection()
  const clearReportCollection = useClearReportCollection()

  return {
    markNftNsfw: { label: 'Mark NFT NSFW', onClick: flagNft },
    clearNftNsfw: { label: 'Clear NFT NSFW', onClick: clearNftNsfw },
    markCollectionNsfw: { label: 'Mark Collection NSFW', onClick: flagCollection },
    setNftScam: { label: 'Mark Scam NFT', onClick: setScamNft },
    setNftPotentialScam: { label: 'Mark Potential Scam NFT', onClick: setPotentialScamNft },
    clearNftScamInfo: { label: 'Clear scam NFT', onClick: clearNftScam },
    clearNftReport: { label: 'Clear report NFT', onClick: clearNftReport },
    setCollectionScam: { label: 'Mark Scam Collection', onClick: setScamCollection },
    clearCollectionScamInfo: { label: 'Clear scam Collection', onClick: clearCollectionScam },
    addFeaturedCollection: { label: 'Add/Remove Featured Collection', onClick: addOrRemoveFeaturedCollection },
    addBlacklistedCollection: { label: 'Blacklist collection', onClick: addBlacklistedCollection },
    addOrRemoveBlacklistedCollection: {
      label: 'Add/Remove Blacklisted Collection',
      onClick: addOrRemoveBlacklistedCollection,
    },
    clearReportCollection: { label: 'Clear Report Collection', onClick: clearReportCollection },
  }
}
