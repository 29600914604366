import { useField } from 'formik'
import { get } from 'lodash'
import { BN } from 'bn.js'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { denominateAmount } from 'common/conversion/denominate-amount'

const calculate = (minBid, quantity) => minBid.mul(new BN(`${Math.floor(quantity)}`))
  .toString()

export const useTotalValue = auction => {
  const [{ value: quantity }, { error }] = useField('quantity')
  const decimals = useDenominationDecimals()
  const minBidAmount = new BN(get(auction, 'minBid.amount', '0'))
  const minBidTokenDecimals = get(auction, 'minBid.tokenData.decimals', 18)
  const totalValue = denominateAmount(calculate(minBidAmount, quantity), minBidTokenDecimals)

  return error ? 0 : parseFloat(totalValue).toFixed(decimals)
}
