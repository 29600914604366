import { useDispatch } from 'react-redux'
import { actions } from 'collections/auctions/slices/get'
import { useNext } from 'collections/auctions/hooks/use-next'
import { useIsNftPageOpened } from 'common/hooks/use-is-nft-page-opened'
import { useCallback } from 'react'

export const useOnChange = () => {
  const dispatch = useDispatch()
  const getInitialPage = useNext('')
  const isNftPageOpened = useIsNftPageOpened()

  return useCallback(() => {
    if (isNftPageOpened) {
      getInitialPage()
      dispatch(actions.reset({}))
    }
  }, [isNftPageOpened, getInitialPage, dispatch])
}
