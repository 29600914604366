import { Button } from 'components/button'
import { useOpenSidebar } from 'feed/hooks/use-open-sidebar'

export const Connect = () => {
  const onClick = useOpenSidebar()

  return <div className={'text-center'}>
    <Button buttonType={'primary'} onClick={onClick}>Connect</Button>
  </div>
}
