import { graph } from 'network'
import { CAMPAIGNS, CAMPAIGN, PRIMARY_SALE, IS_WHITELISTED, MY_TICKETS } from 'repositories/campaigns/queries'
import { HAS_CLAIMED_TICKETS } from 'repositories/campaigns/queries/has-claimed-tickets'
import { BUY_CAMPAIGN } from './mutations/buy-campaign'
import { BUY_TICKETS } from 'repositories/campaigns/mutations/buy-tickets'
import { CLAIM_TICKET } from 'repositories/campaigns/mutations/claim-ticket'

export const campaigns = {
  getCampaigns: payload => graph().query({ query: CAMPAIGNS, variables: payload }),
  getCampaign: payload => graph().query({ query: CAMPAIGN, variables: payload }),
  buyCampaign: payload => graph().mutate({ mutation: BUY_CAMPAIGN, variables: { input: payload } }, true),
  getPrimarySale: payload => graph().query({ query: PRIMARY_SALE, variables: payload }),
  getIsWhitelisted: () => graph().query({ query: IS_WHITELISTED }, true),
  getMyTickets: payload => graph().query({ query: MY_TICKETS, variables: payload }, true),
  buyTickets: payload => graph().mutate({ mutation: BUY_TICKETS, variables: { input: payload } }, true),
  claimTicket: payload => graph().mutate({ mutation: CLAIM_TICKET, variables: { input: payload } }, true),
  hasClaimedTickets: payload => graph().query({ query: HAS_CLAIMED_TICKETS, variables: payload }, true),
}
