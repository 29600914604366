import { ProfileCard } from 'components/profile-card'
import * as _ from 'lodash'

const fromArtist = artist => (path, defaultValue = '') => _.get(artist, path, defaultValue)

export const Item = ({ creator }) => {
  const isGhost = _.get(creator, 'isGhost')
  const get = fromArtist(creator.node)
  const isFollowed = get('isFollowed', false)

  return <ProfileCard address={get('address')} description={get('description')}
                      herotag={get('herotag')} cover={{ url: get('cover') }}
                      profile={{ url: get('profile') }} isGhost={isGhost} isFollowed={isFollowed} />
}
