import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import { useGetViews } from 'nft-page/social-stats/views/hooks/use-get-views'
import { humanize } from 'components/conversion/humanize/index'
import 'nft-page/social-stats/views/index.scss'

export const Views = () => <div className={'nft-page-views'}>
  <FontAwesomeIcon icon={faEye} />
  <span className={'nft-page-views--count'}>{humanize(useGetViews()?.toString())}</span>
  <span className={'nft-page-views--label d-none d-sm-flex'}>views</span>
</div>
