import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { addFeaturedCollection } from 'admin/slices'

export const useAddFeaturedCollection = () => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    dispatch(addFeaturedCollection.actions.attempt({ input: { collection: payload.collection, type: 'Featured' } }))
  }, [dispatch])
}
