import { gql } from 'graphql-tag'

export const ACCEPT = gql`
    mutation acceptOffer ($input: AcceptOfferArgs!) {
        acceptOffer(input: $input){
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
