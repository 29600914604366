import { createContext, useContext as useReactContext, useState } from 'react'

export const Context = createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultContext = () => {
  const [selected, setSelected] = useState('')
  const [shouldReset, setShouldReset] = useState(false)

  return { selected, setSelected, shouldReset, setShouldReset }
}
