import { SubdomainContent } from 'subdomain/cms-content/subdomain-content'
import { useFetchingCms } from 'subdomain/cms-content/hooks/use-fetching-cms'
import match from '@nerds.sh/js-pattern'
import { NotFound } from 'not-found'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const SubdomainWrapper = ({ children }) => {
  useFetchingCms()
  const cmsData = useCmsData()

  return match(cmsData)
    .with(({ errors }) => !!errors, () => <NotFound />)
    .with(({ data }) => !!data, () => <SubdomainContent>{children}</SubdomainContent>)
    .otherwise(() => '')
}
