import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions as searchActions } from 'search/slices/index'
import { actions as dynamicActions } from 'search/slices/dynamic'

export const useSearchReset = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(searchActions.reset({}))
    dispatch(dynamicActions.reset({}))
  }, [dispatch])
}
