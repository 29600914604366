import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppContext } from 'startup/context'

export const useCloseSidebar = () => {
  const { setSidebarOpened } = useAppContext()
  const location = useLocation()

  useEffect(() => {
    setSidebarOpened(false)
  }, [location, setSidebarOpened])
}
