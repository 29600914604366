import SwiperCore, { FreeMode } from 'swiper'
import { Divider } from 'account/divider'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { subdomainTabs } from 'account/tabs/subdomain-tabs'
import { publicTabs } from 'account/tabs/public-tabs'
import { PageTabs } from 'components/page-tabs'
import { useParams } from 'react-router-dom'
import { useRefreshCounters } from 'account/tabs/hooks/use-refresh-counters'
import 'account/tabs/tabs.scss'
import { useIsActive } from 'components/page-tabs/hooks/use-is-active'
import { Filters } from 'account/tabs/filters'

SwiperCore.use([FreeMode])

const makePageTabs = tabs => (
  Object.entries(tabs).map(([_, { hash, label, selector }]) => ({ name: hash, label, count: selector() }))
)

export const List = () => {
  useRefreshCounters()
  const { address } = useParams()
  const tabs = useIsSubdomain() ? subdomainTabs : publicTabs
  const isActive = useIsActive()

  return <>
    <PageTabs tabs={makePageTabs(tabs)}
              baseUrl={`/${address}`}
              omitFirstTabUrl
              isActive={isActive}
              filters={<Filters />} />
    <Divider />
  </>
}
