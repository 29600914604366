import { graph } from 'network'
import { ORDERS, COUNT } from 'repositories/orders/queries'
import { AUCTION_ORDERS } from 'repositories/orders/queries/auction-orders'

const get = payload => graph().query({ query: ORDERS, variables: payload })

const count = variables => graph().query({ query: COUNT, variables })

const auctionOrders = payload => graph().query({ query: AUCTION_ORDERS, variables: payload })

export const orders = { get, count, auctionOrders }
