import { gql } from 'graphql-tag'

export const PRICE = gql`
    query auctionPrice($filters: FiltersExpression) {
        auctions(filters: $filters) {
            edges {
                node {
                    topBid {
                        amount
                        usdAmount
                        token
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBid {
                        amount
                        usdAmount
                        token
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    type
                }
            }
        }
    }
`
