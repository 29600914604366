import { combineEpics } from 'redux-observable'

import { collections as root } from 'collections/epics/collections'
import { assets } from 'collections/assets/epics'
import { auctions } from 'collections/auctions/epics'
import { lookup } from 'collections/epics/look-up-transaction'
import { stats } from 'collections/stats/epics'
import { get as paymentTokens } from 'collections/payment-tokens/epics/get'

export const collections = combineEpics(root, assets, auctions, lookup, stats, paymentTokens)
