import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { Empty } from 'components/empty'

const stateProps = {
  icon: faTimes,
  message: 'Page not found.',
  fullscreen: true,
}

export const NotFound = () => <Empty {...stateProps} />
