import { useCallback } from 'react'
import { STEP } from 'sidebar/connect/constants'
import { useContext } from 'sidebar/connect/context'

export const useSetStep = (step = STEP.loginButtons) => {
  const { setStep } = useContext()

  return useCallback(() => {
    setStep(step)
  }, [setStep, step])
}
