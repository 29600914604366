import { humanize } from 'components/conversion/humanize/index'
import 'components/followers/index.scss'

const tabProp = onClick => onClick ? { tabIndex: 0 } : {}

export const Followers = ({ count, children, label, onClick }) => {
  const btnClass = onClick ? 'btn btn-button' : ''

  return <span onClick={onClick} className={`${btnClass} profile-followers d-flex`} {...tabProp}>
    <div className={'profile-followers-inner'}>
      <span className={'profile-followers-count'}>{humanize(count?.toString())}</span>
      <span className={'profile-followers-label'}>{label || 'Followers'}</span>
    </div>
    {children}
  </span>
}
