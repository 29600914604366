import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: '',
  data: {
    cursor: '',
    items: [],
    hasMore: true,
  },
  list: [],
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const append = (state, { payload }) => ({ ...state, list: [...state.list, ...payload] })

const failure = (state, { payload }) => ({ ...state, errors: payload })

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'feed/get',
  reducers: {
    attempt,
    success,
    append,
    failure,
    reset,
  },
})
