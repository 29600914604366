import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { useNavigate } from 'react-router-dom'

/* eslint-disable */

export const useRedirectableLogin = (callback, dependencies) => {
  const { isLoggedIn } = useGetLoginInfo()
  const [done, setDone] = useState(false)
  const callbackRoute = useCallbackRoute()
  const navigate = useNavigate()

  useEffect(() => {
    if (done && isLoggedIn) {
      setDone(false)
      navigate(callbackRoute)
    }
  }, [callbackRoute, done, isLoggedIn, navigate])

  return useCallback(() => {
    callback()
    setDone(true)
  }, [callback, setDone, ...dependencies])
}

/* eslint-enable */
