import { useRootProps } from '../hooks/use-root-props'
import { useInputProps } from '../hooks/use-input-props'
import { Icon } from './icon'
import { Error } from './error'
import { FileTypes } from './file-types'
import { Button } from './button'
import { DropMessage } from './drop-message'
import { Preview } from './preview'

export const Content = () => <div className={'dropzone'} {...useRootProps()}>
  <div className={'dropzone-field d-flex justify-content-center align-items-center flex-column'}>
    <Icon />
    <Preview />
    <FileTypes />
    <input {...useInputProps()} />
    <Button />
    <DropMessage />
    <Error />
  </div>
</div>

