import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isValidAddress } from 'viewer/is-valid-address'
import { address as addressSlice } from 'viewer/slices/address'
import { privacy as privacySlice } from 'viewer/slices/privacy'

export const isHeroTag = address => !isValidAddress(address)

export const useViewerFetching = () => {
  const { address } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addressSlice.actions.attempt(address))
    dispatch(privacySlice.actions.attempt(address))
  }, [dispatch, address])
}
