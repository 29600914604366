import { useContent } from 'auction-flows/hooks/use-content'
import { statuses } from 'nft-page/auction/actions/statuses'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { Wrapper } from 'nft-page/auction/wrapper'
import { ContractWrapper } from 'components/contract-wrapper'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { get } from 'lodash'

export const Auction = () => {
  const { address } = useGetAccountInfo()
  const { asset, assetOwner } = useAssetWithOwner()
  const assetType = get(asset, 'type')
  const auction = useNftDefaultAuction()
  const marketplaceKey = useCmsMarketplaceKey()

  const Content = useContent({ auction, asset, assetOwner, address, marketplaceKey, statuses: statuses() })

  return <ContractWrapper fullscreen={false} isEmpty>
    <Wrapper name={Content.displayName || Content.name}>
      <Content auction={auction} asset={asset} assetOwner={assetOwner} address={address} assetType={assetType} />
    </Wrapper>
  </ContractWrapper>
}
