import { Image } from './image'
import { useCampaign } from './hooks/use-campaign'

export const Media = () => {
  const campaign = useCampaign()

  return <div className={'campaign-image-col col'}>
    <Image campaign={campaign} />
  </div>
}
