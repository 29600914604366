import { DropdownFilter } from 'filters/components/dropdown-filter'
import { useSetLocationFilters } from 'filters/hooks/use-set-location-filters'
import { isEmpty } from 'lodash'

export const Type = ({ id = 'type-filter', options, className }) => {
  const setLocationFilters = useSetLocationFilters()

  if (isEmpty(options)) return ''

  return <DropdownFilter label={'Type'} options={options} id={id} name={'type'} className={className}
                         onChange={option => setLocationFilters({ name: 'type', value: option.name })} />
}
