import { useEffect } from 'react'
import { Failure } from 'failure/index'
import { failure } from 'failure/slices'
import { useDispatch } from 'react-redux'

export const useStateCleanup = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(failure.actions.hide({ variant: Failure.VARIANT.absolute }))
  }, [dispatch])
}
