import { graph } from 'network'
import { SEND, ACCEPT, WITHDRAW } from 'repositories/offers/mutations'
import { GET } from 'repositories/offers/queries'

export const offers = {
  send: variables => graph().mutate({ mutation: SEND, variables }),

  accept: variables => graph().mutate({ mutation: ACCEPT, variables }),

  withdraw: variables => graph().mutate({ mutation: WITHDRAW, variables }),

  get: variables => graph().query({ query: GET, variables }),
}
