import { gql } from 'graphql-tag'

export const BUY_TICKETS = gql`
    mutation buyTickets($input: BuyTicketsArgs!) {
        buyTickets(input: $input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            options
            receiver
            sender
            value
        }
    }
`
