import 'campaign/index.scss'
import { Behaviour } from 'campaign/behaviour'
import { Media } from 'campaign/media'
import { Content } from 'campaign/content'
import { Row } from 'components/row'
import { Container } from '../components/container'
import { LoadingWrapper } from './loading-wrapper'
import { Seo } from 'components/seo'
import { useSeoProps } from 'campaign/hooks/use-seo-props'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount/index'

export const Campaign = () => <div className={'campaign'}>
  <Container>
    <ScrollToTopOnMount />
    <Seo {...useSeoProps()} />
    <Behaviour />
    <LoadingWrapper>
      <Row>
        <Media />
        <Content />
      </Row>
    </LoadingWrapper>
  </Container>
</div>

