import { SelectedTraitButton } from 'components/dropdown-traits/selected-trait-button'

const stopPropagation = event => event.stopPropagation()

export const SelectedTraits = ({ selectedOptions, deselectOption }) => (
  <div className={'selected-traits'} onClick={stopPropagation}>
    {selectedOptions?.map((selectedOption, index) => <SelectedTraitButton selectedTrait={selectedOption}
                                                                          key={index}
                                                                          deselectOption={deselectOption} />)}
  </div>
)
