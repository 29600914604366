import { gql } from 'graphql-tag'

export const TAGS = gql`
    query getTags($pagination: ConnectionArgs, $filters: TagsFilter!){
        tags(pagination: $pagination, filters: $filters) {
            edges {
                cursor
                node{
                    tag
                    count
                }
            }
        }
    }
`
