import { get } from 'lodash'

export const Item = ({ item }) => {
  const link = get(item, 'link', '')
  const logoUrl = get(item, 'logo.data.attributes.url', '')

  return <a href={link} target={'_blank'} rel={'noreferrer'}>
    <div className={'marketplaces-carousel-item'}>
      <img className={'marketplaces-carousel-item-logo d-flex justify-content-center'} src={logoUrl} alt={'...'} />
    </div>
  </a>
}
