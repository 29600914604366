import { RenderIf } from 'account/common/render-if'
import { FeaturedCollection } from 'components/featured-collection/index'
import { useFeaturedCollections } from 'home/featured-collections/hooks/use-featured-collections'
import { useIsLoading } from 'home/featured-collections/hooks/use-is-loading'

import 'home/featured-collections/index.scss'

export const Content = () => {
  const collections = useFeaturedCollections()
  const isLoading = useIsLoading()

  return <RenderIf isTrue={!isLoading}>
    {collections?.map((collection, index) => <FeaturedCollection key={index} collection={collection} />)}
  </RenderIf>
}
