import { useProfilePhotoErrors } from 'modals/account/photo/hooks/use-profile-photo-errors'
import { useOnSubmit } from 'modals/account/photo/hooks/use-on-submit'
import { useMemo } from 'react'
import { Behaviour } from 'modals/account/photo/behaviour'
import { Dropzone } from 'modals/account/dropzone'

const useDropzoneProps = () => {
  const errors = useProfilePhotoErrors()
  const onSubmit = useOnSubmit()

  return useMemo(() => ({
    id: 'profile-photo-dropzone',
    errors,
    onSubmit,
    maxSize: 2000000,
    dimensions: {
      width: 600,
      height: 600,
    },
    fileTypeInfo: 'PNG, JPG or JPEG. Max 2mb.',
  }), [errors, onSubmit])
}

export const Photo = () => <>
  <Behaviour />
  <Dropzone {...useDropzoneProps()} />
</>
