import { useHadSuccess } from 'modals/account/edit/hooks/use-act-on-privacy-success/use-had-success'
import { useCallback } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useActOnPrivacySuccess = () => {
  const hadSuccess = useHadSuccess()
  const address = useViewerAddress()

  return useCallback(() => {
    if (hadSuccess) {
      window.location.href = `/${address}`
    }
  }, [address, hadSuccess])
}
