import { useAuctions } from 'trending/hooks/use-auctions'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'trending/slices/list'

export const useAppendData = () => {
  const data = JSON.stringify(useAuctions())
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.append(JSON.parse(data)))
  }, [dispatch, data])
}
