import { Link } from 'react-router-dom'
import { Image } from 'subdomain/home/collections/image'

export const Images = ({ assets, link }) => <div className={'subdomain-collection-images'}>
  {assets.map((item, index) => <Image thumbnailUrl={item.thumbnailUrl} key={index} />)}
  <div className={'subdomain-collection-blurry'}>
    {assets.map((item, index) => <Image thumbnailUrl={item.thumbnailUrl} key={index} />)}
  </div>
  <Link className={'subdomain-collection-link'} to={link} />
</div>
