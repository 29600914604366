import { useActiveTab } from 'modals/auction/start/hooks/use-active-tab'
import { useOnTabClick } from 'modals/auction/start/hooks/use-on-tab-click'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

export const Tab = ({ tab, title, icon }) => {
  const isActiveTab = useActiveTab(tab)

  return <button onClick={useOnTabClick(tab)}
                 className={`auctions-tab d-flex flex-column align-content-center ${isActiveTab ? 'active' : ''}`}>
    <div className={'auctions-tab-check'}>
      <FontAwesomeIcon icon={faCheck} />
    </div>
    <div className={'auctions-tab-title'}>{title}</div>
    <div className={'auctions-tab-icon'}>{icon}</div>
  </button>
}
