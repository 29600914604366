import Cropper from 'react-easy-crop'
import { useContext } from 'modals/account/dropzone/context'
import { useCallback, useState } from 'react'

export const ImageCropper = () => {
  const { image, dimensions, setCroppedArea } = useContext()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1.2)

  const onCropComplete = useCallback((_, croppedArea) => {
    setCroppedArea(croppedArea)
  }, [setCroppedArea])

  return image && <>
    <div className={'position-relative w-100 flex-auto'} style={{ flex: 'auto' }}>
      <Cropper image={image} crop={crop} zoom={zoom} aspect={dimensions.width / dimensions.height}
               onCropChange={setCrop} onCropComplete={onCropComplete}
               onZoomChange={setZoom} objectFit={'horixontal-cover'}
               showGrid={false} />

    </div>
    <div className={'controls mt-3 w-100'}>
      <input type={'range'} className={'w-100'} value={zoom} min={1} max={3} step={0.1}
             aria-labelledby={'Zoom'} onChange={e => setZoom(e.target.value)} />
    </div>
  </>
}
