import { FilterComponent } from 'components/data-card/header/filter-component'
import { Title } from 'components/data-card/header/title'
import { ViewAll } from 'components/data-card/header/view-all'
import { Action } from 'components/data-card/header/action'
import { ExploreLink } from 'components/data-card/header/explore-link'

const className = 'data-card-header d-flex justify-content-start align-items-center'

const pickClassName = ({ hiddenHeader }) => hiddenHeader ? 'd-none' : className

export const Header = props => <div className={pickClassName(props)}>
  <Title {...props} />
  <ExploreLink {...props} />
  <div className={'d-flex align-items-center ms-auto'}>
    <FilterComponent filterComponent={props.filterComponent} />
    {props.showAllLink && <ViewAll showAllLink={props.showAllLink} showAllLinkText={props.showAllLinkText} />}
    {props.headerAction && <Action headerAction={props.headerAction} />}
  </div>
</div>
