import { Link } from 'react-router-dom'

export const Image = ({ thumbnailUrl, link }) => <div className={'subdomain-campaign-image'}>
  <div className={'subdomain-campaign-image-inner'}>
    <div className={'subdomain-campaign-image-wrapper'}>
      <img src={thumbnailUrl} className={'subdomain-campaign-image-img'} alt={'...'} />
    </div>
    <img src={thumbnailUrl} className={'subdomain-campaign-image-blurred'} alt={'...'} />
  </div>
  <Link className={'subdomain-campaign-image-link'} to={link} />
</div>
