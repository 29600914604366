import { every } from 'lodash'
import { extract } from 'components/conversion/humanize/extract'

export const decimals = denominatedValue => {
  const decimals = extract.decimals(denominatedValue)
  const shouldHide = every(decimals, digit => digit === '0')
  const delimiter = extract.delimiter(denominatedValue)

  return shouldHide ? '' : `${delimiter}${decimals}`
}

export const decide = { decimals }
