import React from 'react'
import { Provider } from 'react-redux'
import { useStore } from './store'
import { PersistGate } from 'redux-persist/integration/react'

export const ReduxWrapper = ({ children }) => {
  const { persistor, store } = useStore()

  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
}
