import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { get } from 'lodash'

export const useIsAssetOwner = () => {
  const { asset, assetOwner } = useAssetWithOwner()
  const { address } = useGetAccountInfo()
  const sftOwnerAddress = get(assetOwner, 'ownerAddress')
  const nftOwnerAddress = get(asset, 'ownerAddress')
  const assetType = get(asset, 'type', '')
  const isNft = assetType === 'NonFungibleESDT'

  return isNft ? address === nftOwnerAddress : address === sftOwnerAddress
}
