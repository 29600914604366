import { STEP } from 'sidebar/connect/constants'
import { useContext } from 'sidebar/connect/context'
import { WalletConnectLogin } from 'sidebar/connect/walletconnect-login'
import { WalletLogin } from 'sidebar/connect/wallet-login'
import { ExtensionLogin } from 'sidebar/connect/extension-login'
import { LedgerLogin } from 'sidebar/connect/ledger-login'

export const Buttons = () => {
  const { step } = useContext()

  return step === STEP.loginButtons && <>
    <ExtensionLogin />
    <WalletConnectLogin.Button />
    <LedgerLogin.Button />
    <WalletLogin />
  </>
}

