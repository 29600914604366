import { Copy } from 'components/share-button/list/copy'
import { Facebook } from 'components/share-button/list/facebook'
import { Twitter } from 'components/share-button/list/twitter'

export const List = () => (
  <div className={`share-button-list dropdown-menu`} aria-labelledby={'shareMenuButton'}>
    <Copy />
    <Twitter />
    <Facebook />
  </div>
)
