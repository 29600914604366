import { NerdsForm } from 'components/forms/nerds-form'
import { useConfig } from 'components/forms/buy-now-form/hooks/use-config'
import { Fields } from 'components/forms/buy-now-form/fields'

const useFormProperties = props => ({
  loading: props.loading,
  config: useConfig({
    onSubmit: props.onSubmit,
    initialValues: props.initialValues,
    auction: props.auction,
    paymentToken: props.paymentToken,
  }),
  submitErrors: props.submitErrors,
  callbackUrl: props.callbackUrl,
})

export const BuyNowForm = props => <NerdsForm {...useFormProperties(props)}>
  <Fields callbackUrl={props.callbackUrl} submitErrors={props.submitErrors} initialValues={props.initialValues}
          auction={props.auction} paymentToken={props.paymentToken} />
</NerdsForm>
