import { ButtonSecondary } from 'components/button-secondary'
import { useOnClick } from 'account/create-collection/hooks/use-on-click'
import 'account/create-collection/button/button.scss'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

const CLASS_NAME = 'create-collection-button justify-content-center'

export const Button = ({ disabled, tooltipMessage }) => {
  const onClick = useOnClick()
  const messageRef = useRef(null)
  useTooltip(messageRef)

  return <div data-bs-toggle={'tooltip'} data-bs-classname={'tooltip-inner'} className={'account-create-button-wrapper'}
              data-bs-placement={'top'} title={disabled ? tooltipMessage : undefined} ref={messageRef}>
    <ButtonSecondary icon={'add'} onClick={onClick} className={CLASS_NAME} disabled={disabled}>
      Create Collection
    </ButtonSecondary>
  </div>
}
