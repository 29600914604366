/* eslint-disable max-lines-per-function */
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions as listActions } from 'nft-page/details/other-listings/slices/list'
import { actions as selectedAuction } from 'nft-page/auction/slices/selected-auction'
import { actions as ordersList } from 'nft-page/auction/bids/slices/orders'
import { get as offersList } from 'offers/slices/get'
import { useFetchPrice } from 'auction/price/hooks/use-fetch-price'
import { useExecute as useFetchNft } from 'nft-page/hooks/use-fetch-nft'
import { useExecute as useFetchSelectedAuction } from 'nft-page/auction/hooks/use-fetch-selected-auction'
import { useExecute as useFetchRunning } from 'nft-page/details/other-listings/hooks/use-fetch-running-auctions'
import { useExecute as useFetchOrders } from 'nft-page/auction/bids/hooks/use-fetch-asset-orders'
import { useFetchNotifications } from 'notifications/account/hooks/use-fetch-notifications'
import { useExecute as useFetchOffers } from 'offers/hooks/use-fetch-offers'

const useReset = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(listActions.reset({}))
    dispatch(selectedAuction.reset({}))
    dispatch(ordersList.reset({}))
    dispatch(offersList.actions.reset({}))
  }, [dispatch])
}

export const useActionCallback = () => {
  const reset = useReset()
  const fetchPrice = useFetchPrice()
  const fetchNft = useFetchNft()
  const fetchSelectedAuction = useFetchSelectedAuction()
  const fetchRunningAuctions = useFetchRunning()
  const fetchOrders = useFetchOrders()
  const fetchNotifications = useFetchNotifications()
  const fetchOffers = useFetchOffers()

  return useCallback(() => {
    reset()
    fetchNft()
    fetchSelectedAuction()
    fetchRunningAuctions()
    fetchPrice()
    fetchOrders()
    fetchNotifications()
    fetchOffers()
  }, [
    reset,
    fetchNft,
    fetchSelectedAuction,
    fetchRunningAuctions,
    fetchPrice,
    fetchOrders,
    fetchNotifications,
    fetchOffers,
  ])
}

