import { get } from 'lodash'
import { AssetCard } from 'components/asset-card'

export const Item = ({ item }) => {
  const asset = get(item, 'node.asset', {})
  const auction = get(item, 'node', {})
  const isGhost = get(item, 'isGhost')

  return <div className={'col-12 col-sm-6 col-lg-4 card-item'}>
    <AssetCard previewSource={'explore-nfts-auctions'} asset={asset} auction={auction} className={'explore-nfts-card'}
               isGhost={isGhost} showEndedBadge />
  </div>
}
