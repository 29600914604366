import * as Bid from 'feed/hooks/use-actionable-feed/actions/bid'
import * as Follow from 'feed/hooks/use-actionable-feed/actions/follow'
import * as Like from 'feed/hooks/use-actionable-feed/actions/like'
import * as Won from 'feed/hooks/use-actionable-feed/actions/won'
import * as MintNft from 'feed/hooks/use-actionable-feed/actions/mint-nft'
import * as StartAuction from 'feed/hooks/use-actionable-feed/actions/start-auction'
import * as PhotoChanged from 'feed/hooks/use-actionable-feed/actions/photo-changed'
import * as Buy from 'feed/hooks/use-actionable-feed/actions/buy'
import * as SendOffer from 'feed/hooks/use-actionable-feed/actions/send-offer'
import * as AcceptOffer from 'feed/hooks/use-actionable-feed/actions/accept-offer'

export const actions = {
  bid: Bid,
  follow: Follow,
  like: Like,
  won: Won,
  mintNft: MintNft,
  startAuction: StartAuction,
  photoChanged: PhotoChanged,
  buy: Buy,
  sendOffer: SendOffer,
  acceptOffer: AcceptOffer,
}
