import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'

const isSft = () => ({ type: 'SFT', description: 'Semi-Fungible Token' })

const isNft = () => ({ type: 'NFT', description: 'Non-Fungible Token' })

const useExtractType = () => {
  const type = useNftSelector('nftPage.data.assets.edges[0].node.type')

  return type === 'NonFungibleESDT' ? isNft() : isSft()
}

export const Value = () => {
  const extractType = useExtractType()

  return <div>
    <span className={'accent'}>{`${extractType.type}`}</span>
    {' '}
    <span>{`(${extractType.description})` }</span>
  </div>
}
