import { gql } from 'graphql-tag'

export const MY_TICKETS = gql`
    query getMyTickets($collectionIdentifier: String!){
        myTickets(collectionIdentifier: $collectionIdentifier) {
            buyer
            ticketNumber
            winningNonce
        }
    }
`
