import * as Icons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Icon = ({ type }) => {
  const choices = {
    play: <FontAwesomeIcon icon={Icons.faPlay} />,
    mute: <FontAwesomeIcon icon={Icons.faVolumeSlash} />,
    unmute: <FontAwesomeIcon icon={Icons.faVolume} />,
  }

  return choices[type] ?? ''
}
