import { Price } from 'components/price'
import { Icon } from 'components/labeled-price/icon'
import 'components/labeled-price/index.scss'

const CLASS_NAME = 'labeled-price d-flex align-items-center w-100'

export const LabeledPrice = ({ icon, label, price }) => <div className={CLASS_NAME}>
  {icon && <Icon icon={icon} />}
  <span className={'labeled-price-text'}>{label}</span>
  <Price price={price} />
</div>
