import { Title } from 'holoride-campaign/action/title'
import { Button } from './button'
import match from '@nerds.sh/js-pattern'
import { Context, useDefaultContext } from 'holoride-campaign/action/redeem/context'
import { Description } from 'holoride-campaign/action/description'
import { Counter } from 'holoride-campaign/action/redeem/counter'
import './index.scss'

const makeData = (ticketsCount, winningCount) => {
  const ticket = winningCount !== 1 ? 'tickets' : 'ticket'

  return ({
    win: {
      title: `CONGRATULATIONS, You won ${winningCount} ${winningCount !== 1 ? 'NFTs' : 'NFT'}! 🏆`,
      class: 'win',
      text: `You got ${winningCount} winning ${ticket} out of ${ticketsCount} bought tickets.`,
    },
    lost: {
      title: 'SORRY, YOU DIDN’T WIN ANY NFTs 😔',
      class: 'lost',
      text: 'Better luck on the next campaign! You can claim your bought tickets now.',
    },
    nothing: {
      title: 'NO TICKETS BOUGHT',
      class: 'nothing',
      text: 'Looks like you didn’t buy any tickets for this campaign. Try again in the next one!',
    },
  })
}

const getData = (ticketsCount, winningCount) => {
  const data = makeData(ticketsCount, winningCount)

  return match({})
    .with(winningCount, () => data.win)
    .with(ticketsCount && !winningCount, () => data.lost)
    .otherwise(() => data.nothing)
}

export const Redeem = ({ myTickets, primarySale }) => {
  const winningTickets = myTickets.filter(item => item.winningNonce !== '0')
  const data = getData(myTickets?.length, winningTickets.length)

  return <Context.Provider value={useDefaultContext()}>
    <div className={`holoride-action-inner holoride-campaign-redeem ${data.class}`}>
      <Title>{data.title}</Title>
      <Counter primarySale={primarySale} />
      <Description>{data.text}</Description>
      {myTickets?.length ? <Button>Claim</Button> : ''}
    </div>
  </Context.Provider>
}
