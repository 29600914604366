import { useEffect, useRef } from 'react'

export const useModalElement = id => {
  const mount = useRef(document.getElementById('portal-modal'))
  const el = useRef(document.createElement('div'))
  const existingDuplicate = useRef(null)

  useEffect(() => {
    existingDuplicate.current = document.getElementById(id)
    const mountCurrent = mount.current
    const currentElement = el.current

    if (!existingDuplicate.current) {
      mountCurrent.appendChild(currentElement)
    }

    return () => {
      if (!existingDuplicate.current) {
        mountCurrent.removeChild(currentElement)
      }
    }
  }, [id])

  return el
}
