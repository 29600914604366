import { Behaviour } from 'holoride-campaign/behaviour'
import { Action } from 'holoride-campaign/action'
import { LoadingWrapper } from 'holoride-campaign/loading-wrapper/index'

export const ActionContent = () => <>
  <Behaviour />
  <LoadingWrapper>
    <Action />
  </LoadingWrapper>
</>
