import { BootstrapModal } from 'components/bootstrap-modal'
import { Behaviour } from 'subdomain/modals/account/my-bids/behaviour'
import { ModalContent } from 'subdomain/modals/account/my-bids/modal-content'
import { Title } from 'subdomain/modals/account/my-bids/title'
import { List } from 'subdomain/modals/account/my-bids/list'
import { Failure } from 'failure/index'
import { Empty } from 'subdomain/modals/account/my-bids/empty'
import 'subdomain/modals/account/my-bids/style.scss'

export const MyBids = () => <BootstrapModal id={'my-bids'} className={'my-bids-modal'} easyClose>
  <Behaviour />
  <ModalContent>
    <Title />
    <Failure.Partial id={'modal.account.myBids'}>
      <List />
      <Empty />
    </Failure.Partial>
  </ModalContent>
</BootstrapModal>

export { reducer } from 'subdomain/modals/account/my-bids/reducer'
export { epic } from 'subdomain/modals/account/my-bids/epic'
