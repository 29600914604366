import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions } from 'modals/account/edit/description/slices/update-description'

export const useUpdateDescription = () => {
  const dispatch = useDispatch()

  return useCallback(({ description }) => description && dispatch(actions.attempt(description)), [dispatch])
}
