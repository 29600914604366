import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'feed/suggestions/slices'

export const useDecrementFollowees = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(get.actions.decrementFollowees({}))
  }, [dispatch])
}
