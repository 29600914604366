import { humanize } from 'components/conversion/humanize'
import { settings } from 'settings/fetching'
import { get } from 'lodash'

const decimals = () => settings().dapp.decimals

export const Description = ({ assetFees, paymentToken }) => {
  const priceUsd = get(paymentToken, 'priceUsd')
  const symbol = get(paymentToken, 'symbol')
  const fixedAssetFees = assetFees.toFixed(decimals())
  const usdTotal = (fixedAssetFees * priceUsd).toFixed(decimals())

  return <div>
    <span>Total cash in after fees will be</span>
    {` `}
    <span className={'example-fee-item-text'}>{`~${humanize(fixedAssetFees)} ${symbol}`}</span>
    {` `}
    {priceUsd && <span>{`(~${humanize(usdTotal)} USD).`}</span>}
  </div>
}
