import { Wrapper } from 'explore-nfts/tabs/nfts/filters/wrapper'
import { FacetsSelector } from 'filters/components/facets-selector'
import { useIsLoading } from 'explore-nfts/tabs/nfts/assets/hooks/use-is-loading'
import { useFacets } from 'explore-nfts/tabs/nfts/filters/hooks/use-facets'
import { useCurrentPaymentTokens } from 'explore-nfts/current-payment-tokens/hooks/use-current-payment-tokens'
import { PriceRangeToken } from 'filters/components/price-range-token'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { useExploreAuctionsPriceRange } from 'explore-nfts/tabs/nfts/auctions/hooks/use-explore-auctions-price-range'
import { useExploreAuctionsActualCount } from 'explore-nfts/tabs/nfts/auctions/hooks/use-explore-auction-actual-count'

export const Filters = () => {
  const isLoading = useIsLoading()
  const facets = useFacets()
  const paymentTokens = useCurrentPaymentTokens()
  const filter = useFilterValue('filter')
  const shouldShow = filter === 'live-auction' || filter === 'buy-now'
  const { minBid, maxBid } = useExploreAuctionsPriceRange()
  const actualCount = useExploreAuctionsActualCount()

  return <Wrapper>
    <FacetsSelector facets={facets} isLoading={isLoading} resetOthers />
    {shouldShow && <PriceRangeToken maxBid={maxBid} minBid={minBid} paymentTokens={paymentTokens}
                                    actualCount={actualCount} />}
  </Wrapper>
}
