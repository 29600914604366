import { useSetup } from 'components/player/behaviour/hooks/use-setup'
import { useTeardown } from 'components/player/behaviour/hooks/use-teardown'
import { usePlayback } from 'components/player/behaviour/hooks/use-playback'

export const Behaviour = () => {
  useSetup()
  useTeardown()
  usePlayback()

  return <div />
}
