import { useCallback } from 'react'
import { testNumber } from 'components/forms/place-bid-form/hooks/use-tests'
import { useMaximum } from 'components/forms/place-bid-form/hooks/use-maximum'
import { get } from 'lodash'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const useShouldThrowMaximum = auction => {
  const maximum = useMaximum(auction)
  const tokenDecimals = get(auction, 'maxBid.tokenData.decimals', 18)

  return useCallback(value => {
    if (!testNumber(value)) return false
    const formattedAmount = denominateAmount(maximum, tokenDecimals)

    return parseFloat(formattedAmount) !== 0 ? value > parseFloat(formattedAmount) : false
  }, [maximum, tokenDecimals])
}
