import { useOnClick } from 'feed/hooks/use-on-click'

export const ExploreLink = () => {
  const onClick = useOnClick()

  return <button onClick={onClick} className={'btn btn-primary'}>
    Explore now
  </button>
}

