import { useMakeNftState } from 'startup/hooks/use-subdomain-hydrate-location/use-make-nft-state'
import { useMakeDefaultState } from 'startup/hooks/use-subdomain-hydrate-location/use-make-default-state'
import match from '@nerds.sh/js-pattern'
import { useCallback } from 'react'
import { useShouldUseNftState } from 'startup/hooks/use-subdomain-hydrate-location/use-should-use-nft-state'
import { useShouldUseAuctionState } from 'startup/hooks/use-subdomain-hydrate-location/use-should-use-auction-state'
import { useMakeAuctionState } from 'startup/hooks/use-subdomain-hydrate-location/use-make-auction-state'
import { useShouldUseTransactionState } from 'startup/hooks/use-subdomain-hydrate-location/use-should-use-transaction-state'
import { useMakeTransactionState } from 'startup/hooks/use-subdomain-hydrate-location/use-make-transaction-state'
import { useShouldUseCollectionState } from 'startup/hooks/use-subdomain-hydrate-location/use-should-use-collection-state'
import { useMakeCollectionState } from 'startup/hooks/use-subdomain-hydrate-location/use-make-collection-state'
import { useShouldUseAccountState } from 'startup/hooks/use-subdomain-hydrate-location/use-should-hydrate-account-state'
import { useMakeAccountState } from 'startup/hooks/use-subdomain-hydrate-location/use-make-account-state'

export const useMakeTargetState = () => {
  const shouldUseNftState = useShouldUseNftState()
  const makeNftState = useMakeNftState()

  const makeDefaultState = useMakeDefaultState()

  const shouldUseAuctionState = useShouldUseAuctionState()
  const makeAuctionState = useMakeAuctionState()

  const shouldUseAccountState = useShouldUseAccountState()
  const makeAccountState = useMakeAccountState()

  const shouldUseCollectionState = useShouldUseCollectionState()
  const makeCollectionState = useMakeCollectionState()

  const shouldUseTransactionState = useShouldUseTransactionState()
  const makeTransactionState = useMakeTransactionState()

  return useCallback(() => match({})
    .with(() => shouldUseNftState, makeNftState)
    .with(() => shouldUseAuctionState, makeAuctionState)
    .with(() => shouldUseAccountState, makeAccountState)
    .with(() => shouldUseCollectionState, makeCollectionState)
    .with(() => shouldUseTransactionState, makeTransactionState)
    .otherwise(makeDefaultState), [makeNftState, makeAuctionState, makeAccountState, makeCollectionState, makeTransactionState, makeDefaultState, shouldUseNftState, shouldUseAuctionState, shouldUseAccountState, shouldUseCollectionState, shouldUseTransactionState])
}
