import { useIssueServer } from 'collections/hooks/use-fetch-collection'
import { useIssueServer as useGetAssets } from 'collections/assets/hooks/use-next'
import { useCallback } from 'react'
import { useFetchNotifications } from '../../notifications/account/hooks/use-fetch-notifications'

export const useFetchPageDependencies = () => {
  const issueServer = useIssueServer()
  const getInitialPage = useGetAssets('')
  const fetchNotifications = useFetchNotifications()

  return useCallback(() => {
    issueServer()
    getInitialPage()
    fetchNotifications()
  }, [issueServer, getInitialPage, fetchNotifications])
}
