import { gql } from 'graphql-tag'

export const CLAIM_TICKET = gql`
    mutation claimTicket($input: ClaimTicketsArgs!) {
        claimTicket(input: $input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            options
            receiver
            sender
            value
        }
    }
`
