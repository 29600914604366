import { Traits } from 'filters/components/traits/index'
import { isEmpty } from 'lodash'
import { ReportCollection } from 'collections/report-collection/index'

import './index.scss'

export const AssetsFilter = ({ traits }) => <div className={'collection-filters-assets'}>
  <ReportCollection />

  {!isEmpty(traits) && <Traits />}
</div>
