import { combineReducers } from 'redux'
import { follow, unfollow, followers, followees, follows } from 'account/profile/follow/slices'

export const reducer = combineReducers({
  follow: follow.reducer,
  unfollow: unfollow.reducer,
  followers: followers.reducer,
  followees: followees.reducer,
  follows: follows.reducer,
})
