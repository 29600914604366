import { get } from 'lodash'

export const isAssetOwner = input => {
  const sftOwnerAddress = get(input, 'assetOwner.ownerAddress')
  const nftOwnerAddress = get(input, 'asset.ownerAddress')
  const assetType = get(input, 'asset.type', '')
  const address = get(input, 'address')
  const isNft = assetType === 'NonFungibleESDT'

  return isNft ? address === nftOwnerAddress : address === sftOwnerAddress
}
