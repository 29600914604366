import { useAllCollectionsCount } from 'explore-nfts/tabs/collections/stats/hooks/use-all-collections-count'
import { useVerifiedCount } from 'explore-nfts/tabs/collections/stats/hooks/use-verified-count'
import { useActiveLastMonthCount } from 'explore-nfts/tabs/collections/stats/hooks/use-active-last-month-count'

export const useFacets = () => {
  const allCollectionsCount = useAllCollectionsCount()
  const verifiedCount = useVerifiedCount()
  const activeLastMonthCount = useActiveLastMonthCount()

  return [{ label: 'Verified', name: 'verified', value: 'verified', count: verifiedCount, selected: true },
    { label: 'All', name: 'all', value: 'all', count: allCollectionsCount },
    {
      label: 'Active last 30 days',
      name: 'active-last-30-days',
      value: 'active-last-30-days',
      count: activeLastMonthCount,
    }]
}
