import { gql } from 'graphql-tag'

export const COLLECTION_PAYMENT_TOKENS = gql`
    query collectionPaymentTokens($filters: CurrentPaymentTokensFilters) {
        currentPaymentTokens(filters: $filters) {
            activeAuctions
            symbol
            identifier
            decimals
        }
    }
`
