import { useEffect, useRef } from 'react'

export const useAutoFocus = autofocus => {
  const ref = useRef(null)

  useEffect(() => {
    autofocus && setTimeout(() => ref.current?.focus(), 0)
  }, [ref, autofocus])

  return ref
}
