import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: { herotag: '', socialLinks: [{}], description: '', cover: '', profile: '', privacy: '' },
  input: '',
  errors: [],
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true, errors: [] })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false, errors: [] })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload), loading: false })

const cleanUp = () => initialState

export const profile = createSlice({
  initialState,
  name: 'profile',
  reducers: {
    attempt,
    success,
    failure,
    cleanUp,
  },
})

export const { actions } = profile
