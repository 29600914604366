import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'components/dropdown-tokens/button'
import { useState } from 'react'
import { findOption } from 'components/dropdown-tokens/find-option'
import { useSelectItem } from 'components/dropdown-tokens/hooks/use-select-item'
import { useSetSelected } from 'components/dropdown-tokens/hooks/use-set-selected'
import { humanize } from 'components/conversion/humanize'
import 'components/dropdown-tokens/index.scss'

const makeClassName = (active = '') => `dropdown-item ${active ? 'active' : ''}`

export const DropdownTokens = ({ options, onChange, id, initial, disabled, actualCount }) => {
  const [selected, setSelected] = useState(findOption(options, initial) || options[0])
  const selectItem = useSelectItem(setSelected, onChange)
  useSetSelected({ setSelected, options })

  return <div className={`btn-group dropdown-tokens`}>
    <Button id={id} selected={selected} disabled={disabled} actualCount={actualCount} />
    <div className={'dropdown-menu'}>
      {options.map(option => <button key={option.name + option.value} onClick={() => selectItem(option)}
                                     disabled={disabled} className={makeClassName(selected.name === option.name)}>
        <div className={'d-flex'}>
          <div>{option.name}</div>
          {option.count && <div className={'dropdown-count mx-2'}>{humanize(option.count?.toString())}</div>}
          <div className={'ms-auto'}>{selected.value === option.value && <FontAwesomeIcon icon={faCheck} />}</div>
        </div>
      </button>)}
    </div>
  </div>
}
