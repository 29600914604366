import { from } from 'rxjs'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { actions } from 'owners/slices/owners'
import { failure as failureSlice } from 'failure/slices'
import { history } from 'network'
import { owners as repository } from 'repositories/owners/index'

export const owners = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.get(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = payload => from([
  actions.failure(payload),
  failureSlice.actions.show({ route: history.location.pathname }),
])
