import { Form } from 'modals/collection/set-roles/lookup/nft/form'
import { Errors } from 'modals/collection/set-roles/errors'
import { Behaviour } from 'modals/collection/set-roles/behaviour'
import { useCollectionIdentifier } from 'modals/collection/set-roles/lookup/hooks/use-collection-identifier'
import { Content } from 'modals/collection/create/content'
import { Suspense } from 'modals/collection/create/suspense'

export const SetRoles = () => {
  const collection = useCollectionIdentifier()

  return <div className={'modal-content p-4'}>
    <Behaviour />
    <Suspense after={collection}>
      <Content collection={collection}>
        <Form />
      </Content>
    </Suspense>
    <Errors />
  </div>
}

