import { useCallback } from 'react'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'

export const useOnClick = () => {
  const navigate = useLayeredNavigate()

  return useCallback(() => {
    navigate('/nfts', 'background')
  }, [navigate])
}
