import { useIsOnSale } from 'collections/actions/on-sale-filter/hooks/use-is-on-sale'
import { useCollectionTraits } from 'collections/hooks/use-collection-traits'
import { formatTraitsList } from 'filters/collection-filters/filters-logic/traits/format-traits-list'
import { TraitsButton } from 'filters/components/traits-button'
import { useOnFiltersClick } from 'filters/components/traits/hooks/use-on-filters-click'
import { useFilterValue } from 'filters/hooks/use-filter-value'
import { isEmpty } from 'lodash'

export const Traits = () => {
  const onClick = useOnFiltersClick()
  const urlTraits = useFilterValue('traits')
  const traitsList = formatTraitsList(urlTraits)

  const hasTraits = !isEmpty(useCollectionTraits())
  const isOnSale = useIsOnSale()
  const shouldShow = hasTraits && !isOnSale

  return shouldShow ? <TraitsButton label={'Filters'} count={traitsList?.length} onClick={onClick} /> : ''
}
