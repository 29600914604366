import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as primarySale } from 'holoride-campaign/slices/primary-sale'
import { actions as isWhitelisted } from 'holoride-campaign/slices/is-whitelisted'
import { actions as myTickets } from 'holoride-campaign/slices/my-tickets'
import { actions as claimTickets } from 'holoride-campaign/action/redeem/slices/claim-ticket'

export const useResetState = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(primarySale.reset({}))
    dispatch(isWhitelisted.reset({}))
    dispatch(myTickets.reset({}))
    dispatch(claimTickets.reset({}))
  }, [dispatch])
}
