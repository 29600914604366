import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useAssetLikesCount, useLikesCount } from 'nft-page/likes/hooks'

export const useCount = () => {
  const { address } = useGetAccountInfo()
  const likesCount = useLikesCount()
  const assetLikesCount = useAssetLikesCount()

  return address ? likesCount : assetLikesCount
}
