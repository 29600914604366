import { gql } from 'graphql-tag'

export const CREATE_NFT = gql`
    mutation CreateNFT($file: Upload!, $input: CreateNftArgs!) {
        createNft(file: $file, input: $input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
