import { get } from 'lodash'
import { targetedStatuses } from 'nft-page/hooks/use-invalid-redirect/target-statuses'
import { useWasRequestExecuted } from 'nft-page/hooks/use-invalid-redirect/use-was-request-executed'
import { useSelectedAuction } from 'nft-page/hooks/use-invalid-redirect/use-selected-auction'
import { useSelectedErrors } from 'nft-page/hooks/use-invalid-redirect/use-selected-errors'

export const useIsInvalid = () => {
  const currentAuction = useSelectedAuction()
  const hasErrors = useSelectedErrors()
  const wasRequestExecuted = useWasRequestExecuted()

  if (!currentAuction) {
    return !hasErrors && wasRequestExecuted
  }

  return targetedStatuses.includes(get(currentAuction, 'node.status', ''))
}
