import { useFeed } from 'feed/hooks/use-feed'
import { actions } from 'feed/hooks/use-actionable-feed/actions'

const addLabels = event => {
  const action = actions[event.action.type]

  return {
    ...event,
    action: {
      type: event.action.type,
      actionLabel: <action.ActionLabel whom={event.whom} />,
      suffixLabel: <action.SuffixLabel whom={event.whom} />,
    },
  }
}

export const useActionableFeed = () => useFeed().map(addLabels)
