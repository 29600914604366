import { useRouterContext } from 'components/layered-router/context'
import { useEffect } from 'react'

export const useInitLayersNames = layers => {
  const { setLayersNames } = useRouterContext()
  const names = JSON.stringify(layers.map(layer => layer.name))

  useEffect(() => {
    const parsed = JSON.parse(names)
    setLayersNames(parsed)
  }, [setLayersNames, names])

  return ''
}
