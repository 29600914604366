import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../slices'
import { useStatusesByPrefix } from 'action-statuses/hooks/use-statuses-by-prefix'

const extractSessionIds = statuses => statuses.map(({ sessionId }) => sessionId)

const targetStatuses = [
  'success',
  'fail',
  'timedOut',
]

export const useWatchAction = ({ prefix, onChange }) => {
  const statuses = JSON.stringify(useStatusesByPrefix(prefix, targetStatuses))
  const dispatch = useDispatch()

  useEffect(() => {
    const parsed = JSON.parse(statuses)

    if (parsed.length > 0) {
      dispatch(actions.clearSessions(extractSessionIds(parsed)))
      onChange()
    }
  }, [dispatch, onChange, statuses])
}
