import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useFilters } from 'filters/hooks/use-filters'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { usePriceRange } from 'collections/auctions/hooks/use-price-range'
import { buildFilters } from 'filters/collection-filters/auctions/filters-logic'

export const useFetchPayloadPreview = () => {
  const { identifier: asset } = useParams()
  const collection = asset.split('-')
  const collectionIdentifier = `${collection[0]}-${collection[1]}`
  const filters = useFilters()
  const hash = JSON.stringify(filters.background)
  const marketplaceKey = useCmsMarketplaceKey()
  const priceRange = usePriceRange()
  const priceRangeHash = JSON.stringify(priceRange)

  return useCallback(({ cursor }) => {
    const parsed = JSON.parse(hash)
    const parsedPriceRang = JSON.parse(priceRangeHash)
    const defPriceRange = { min: parsedPriceRang.amount, max: parsedPriceRang.amount }

    return buildFilters({ ...parsed, cursor }, collectionIdentifier, marketplaceKey, defPriceRange)
  }, [collectionIdentifier, hash, priceRangeHash, marketplaceKey])
}
