import { get } from 'lodash'
import { usePreviousElement } from 'nft-page/controls/hooks/use-previous-element'

export const usePreviousElementIdentifier = () => {
  const previousElement = usePreviousElement()

  const assetIdentifier = get(previousElement, 'identifier', undefined)
  const auctionIdentifier = get(previousElement, 'auction.identifier', undefined)

  return assetIdentifier ?? auctionIdentifier
}
