import { Partial } from 'failure/partial'
import { Absolute } from 'failure/absolute'
import { useShowFailure } from 'failure/hooks/use-show-failure'

const VARIANT = Object.freeze({
  partial: 'partial',
  absolute: 'absolute',
})

export const Failure = {
  Partial,
  Absolute,
  VARIANT,
  useShowFailure,
}
