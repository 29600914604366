import { Issue } from 'modals/collection/create/issue'
import { SetRoles } from 'modals/collection/set-roles/lookup/nft'
import { SetSftRoles } from 'modals/collection/set-roles/lookup/sft'
import match from '@nerds.sh/js-pattern'
import { useParams } from 'react-router-dom'

export const useComponent = () => {
  const params = useParams()

  return match(params)
    .like({ step: 'issue' }, () => Issue)
    .like({ step: 'setRoles' }, () => SetRoles)
    .like({ step: 'setSftRoles' }, () => SetSftRoles)
    .otherwise(() => 'Not implemented...')
}

