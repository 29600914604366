import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useLocation } from 'react-router-dom'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'
import { useCollectionOwnerAddress } from 'collections/hooks/use-collection-owner-address'

const matchAddresses = (viewerAddress, address) => viewerAddress && viewerAddress === address

export const useRedirectCollectionModals = () => {
  const ownerAddress = useCollectionOwnerAddress()
  const { address } = useGetAccountInfo()
  const { state } = useLocation()

  const closeLayer = useCloseLayerNavigate()

  if (!matchAddresses(ownerAddress, address) && state?.hydrated) {
    closeLayer()
  }
}
