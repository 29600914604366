import { from } from 'rxjs'
import { history } from 'network'
import { ofType } from 'redux-observable'
import { marketplaces as slice } from 'cms-content/slices/marketplaces'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { fetchMarketplaces } from 'cms-content/repositories/fetch-marketplaces'

export const marketplaces = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = () => fetchMarketplaces()
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  slice.actions.success(payload),
])

const failure = payload => from([
  slice.actions.failure(payload),
  failureSlice.actions.show({ route: history.location.pathname }),
])
