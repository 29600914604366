import { useActOnSuccess } from 'modals/account/edit/hooks/use-act-on-success'
import { BootstrapModal } from 'components/bootstrap-modal'
import { Body } from 'modals/account/edit/body'

export const Edit = () => {
  useActOnSuccess()

  return <BootstrapModal id={'editProfile'}>
    <Body />
  </BootstrapModal>
}

export * from 'modals/account/edit/reducers'
export * from 'modals/account/edit/epics'
