import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'home/trending-collections/slices'
import { settings } from 'settings/fetching'

export const useFetchTrendingCollections = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.attempt({
      pagination: { first: settings().home.trendingCollectionsColumns },
      sorting: 'Trending',
    }))
  }, [dispatch])
}
