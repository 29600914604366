import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'modals/account/followees/slices/get'

export const useResetState = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(get.actions.reset({}))
  }, [dispatch])
}
