import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  current: [],
  info: {},
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, current: payload.info })

const failure = (state, { payload }) => ({ ...state, errors: payload, loading: false })

const append = (state, { payload }) => ({ ...state, info: { ...state.info, ...payload.info }, loading: false })

export const getUsers = createSlice({
  initialState,
  name: 'modals/account/followees/getUsers',
  reducers: {
    attempt,
    success,
    failure,
    append,
  },
})
