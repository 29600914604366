import { useOnClick } from 'auction/actions/place-bid/bid/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { actionTypes } from 'action-statuses/actions-types'
import { WatchableAction } from '../watchable-action'
import { get } from 'lodash'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const Bid = ({ auction, address }) => {
  const bidClick = useOnClick(auction)
  const onClick = usePermittedAction(bidClick, address)
  const minBidTokenSymbol = get(auction, 'minBid.tokenData.symbol')
  const amount = useMinimum(auction)
  const minBidDecimals = get(auction, 'minBid.tokenData.decimals')
  const priceFormatted = denominateAmount(amount, minBidDecimals, true)
  const price = `Miniumum bid ${priceFormatted} ${minBidTokenSymbol}`

  return <WatchableAction entity={auction} actionType={actionTypes.bid} text={'Bid'}
                          onClick={onClick} className={'btn-secondary'} price={price} />
}
