import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { Buy } from 'holoride-campaign/modals/buy/form'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { actions } from 'holoride-campaign/modals/buy/slices/buy-tickets'
import { usePrimarySale } from 'holoride-campaign/hooks/use-primary-sale'
import { useData } from 'holoride-campaign/modals/buy/hooks/use-data'
import { useIsLoading } from 'holoride-campaign/modals/buy/hooks/use-is-loading'
import { useErrors } from 'holoride-campaign/modals/buy/hooks/use-errors'
import { useBuyTickets } from 'holoride-campaign/modals/buy/hooks/use-buy-tickets'
import { useMyTickets } from 'holoride-campaign/hooks/use-my-tickets'

export const Form = () => {
  const primarySale = usePrimarySale()
  const myTickets = useMyTickets()
  const data = useData()
  const loading = useIsLoading()
  const errors = useErrors()
  const buyTickets = useBuyTickets(primarySale)
  const callbackUrl = useCloseLayerLocation('/launch')

  const actionIdentifier = createActionIdentifier(primarySale, actionTypes.buyTickets)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <Buy onSubmit={buyTickets} submitErrors={errors} loading={loading} primarySale={primarySale}
              initialValues={{ ticketsNumber: 1, consent: false }}
              nftsBought={myTickets.length} callbackUrl={callbackUrl} />
}
