import PropTypes from 'prop-types'
import { NerdsForm } from 'components/forms/nerds-form'
import { Fields } from 'components/forms/create-sft-auctions/single-form/fields'
import { useConfig } from 'components/forms/create-sft-auctions/single-form/hooks/use-config'

const useFormProperties = props => ({
  loading: props.loading,
  config: useConfig({ onSubmit: props.onSubmit, initialValues: props.initialValues }),
  submitErrors: props.submitErrors,
  callbackUrl: props.callbackUrl,
})

export const SingleForm = props => <NerdsForm {...useFormProperties(props)}>
  <Fields callbackUrl={props.callbackUrl} asset={props.asset} assetOwner={props.assetOwner}
          submitErrors={props.submitErrors} paymentToken={props.paymentToken} />
</NerdsForm>

SingleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.any.isRequired,
  callbackUrl: PropTypes.object.isRequired,
}
