import { BootstrapModal } from 'components/bootstrap-modal'
import { Form } from 'modals/account/admin/featured-collection/form'
import { useFetch as useFetchFeaturedCollections } from 'home/featured-collections/hooks/use-fetch'

export const FeaturedCollection = () => {
  useFetchFeaturedCollections()

  return <BootstrapModal id={'add-featured-collection'} title={'Add or remove Featured Collection'}>
    <Form />
  </BootstrapModal>
}
