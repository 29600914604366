import { useDecrementFollowees } from 'feed/initial/hooks/use-decrement-followees'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { unfollow } from 'feed/suggestions/slices'

export const useUnfollowAccount = (setFollowed, account) => {
  const decrementFollowees = useDecrementFollowees()
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(unfollow.actions.attempt({ address: account.address }))
    decrementFollowees()
    setFollowed(false)
  }, [account, decrementFollowees, dispatch, setFollowed])
}
