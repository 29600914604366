import { Item } from 'sidebar/my-wallet/balance/item'

const useGetTitle = amount => {
  const hasBiddingBalance = amount && parseFloat(amount) !== 0

  return hasBiddingBalance ? { title: 'Balance' } : { title: '' }
}

export const TokenBalance = ({ amount, usdAmount, symbol, biddingBalanceAmount }) => {
  const { title } = useGetTitle(biddingBalanceAmount)

  return <Item title={title} amount={amount} usdAmount={usdAmount} symbol={symbol} />
}
