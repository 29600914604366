import { useExploreNftsList } from 'explore-nfts/tabs/nfts/assets/hooks/use-explore-nfts-list'
import { useIsLoading } from 'explore-nfts/tabs/nfts/assets/hooks/use-is-loading'
import { Behaviour } from 'explore-nfts/tabs/nfts/assets/behaviour'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useNext } from 'explore-nfts/tabs/nfts/assets/hooks/use-next'
import { useHasMore } from 'explore-nfts/tabs/nfts/assets/hooks/use-has-more'
import { List } from 'explore-nfts/tabs/nfts/assets/list'
import { Empty } from 'explore-nfts/tabs/nfts/assets/empty'
import { RenderIf } from 'account/common/render-if'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const Assets = () => {
  const nfts = useExploreNftsList()
  const isLoading = useIsLoading()
  const filterValue = useFilterValue('filter')
  const shouldRender = filterValue === 'all' || filterValue === ''

  return <RenderIf isTrue={shouldRender}>
    <Behaviour />
    <InfiniteScroll next={useNext()} hasMore={useHasMore()} isLoading={isLoading}>
      <List assets={nfts} />
    </InfiniteScroll>
    <Empty />
  </RenderIf>
}
