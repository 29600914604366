import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { accept } from 'offers/slices/accept'

export const useResetState = () => {
  const dispatch = useDispatch()

  return useEffect(() => {
    dispatch(accept.actions.reset({}))
  }, [dispatch])
}
