import { gql } from 'graphql-tag'

export const COUNT = gql`
    query ($pagination: ConnectionArgs, $filters: FiltersExpression) {
        auctions(pagination: $pagination, filters: $filters) {
            pageData {
                count
            }
        }
    }
`
