import { useEffect } from 'react'
import { useIntervalPooling } from 'nft-page/hooks/use-auction-pooling/use-interval-pooling'
import { useFetchPrice } from 'auction/price/hooks/use-fetch-price'
import { useIsAuctionEnded } from 'nft-page/hooks/use-is-auction-ended'

export const useAuctionPooling = () => {
  const intervalPooling = useIntervalPooling()
  const fetchPrice = useFetchPrice()
  const isAuctionEnded = useIsAuctionEnded()

  useEffect(() => {
    let interval

    if (intervalPooling && !isAuctionEnded) {
      interval = setInterval(fetchPrice, intervalPooling)
    }

    return () => clearInterval(interval)
  }, [fetchPrice, intervalPooling, isAuctionEnded])
}
