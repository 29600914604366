import { useEffect } from 'react'
import { useMakePayload } from 'nft-page/tracking/hooks/use-make-payload'

export const useTrackAsset = () => {
  const makePayload = useMakePayload()

  useEffect(() => {
    makePayload()
  }, [makePayload])
}
