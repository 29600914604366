import { raw } from 'repositories/feed/raw'
import { mergeMap, defaultIfEmpty } from 'rxjs'
import { populateData } from 'repositories/feed/populate-data'

export const feed = input => raw(input)
  .pipe(mergeMap(populateData))
  .pipe(defaultIfEmpty({ items: [], hasMore: false }))

export * from 'repositories/feed/latest-timestamp'
export * from 'repositories/feed/suggestions'
