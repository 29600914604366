import { get } from 'lodash'
import { useIsAssetOwner } from 'nft-page/offers/hooks/use-is-asset-owner'
import { useIsOfferOwner } from 'nft-page/offers/hooks/use-is-offer-owner'
import match from '@nerds.sh/js-pattern'
import { Accept } from 'nft-page/offers/actions/accept'
import { Withdraw } from 'nft-page/offers/actions/withdraw'
import { useIsAuctionOwner } from 'nft-page/offers/hooks/use-is-auction-contract-owner'

const useIsAssetOrAuctionOwner = () => {
  const isAuctionOwner = useIsAuctionOwner()
  const isAssetOwner = useIsAssetOwner()

  return isAuctionOwner || isAssetOwner
}

const useCanAcceptOffer = offer => {
  const status = get(offer, 'status')
  const isAssetOrAuctionOwner = useIsAssetOrAuctionOwner()

  return isAssetOrAuctionOwner && status !== 'Expired'
}

export const Action = ({ offer }) => {
  const ownerAddress = get(offer, 'ownerAddress', '')
  const isOfferOwner = useIsOfferOwner(ownerAddress)
  const canAcceptOffer = useCanAcceptOffer(offer)

  return match({})
    .with(canAcceptOffer && !isOfferOwner, () => <Accept offer={offer} />)
    .with(isOfferOwner, () => <Withdraw offer={offer} />)
    .otherwise(() => '')
}
