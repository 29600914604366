import { gql } from 'graphql-tag'

export const STATS = gql`
    query accountStats($filters: AccountStatsFilter!) {
        accountStats(filters:  $filters) {
            address
            auctions
            biddings {
                amount
                token
                usdAmount
                tokenData{
                    decimals
                    symbol
                }
            }
            claimable
            collected
            collections
            creations
            orders
            likes
            offers
        }
    }`
