import { Background, Foreground, Middleground, Modal } from 'components/surfaces'
import { routes } from './routes'

export const layers = [
  {
    name: 'background',
    zIndex: 0,
    routes,
    surface: Background,
  },
  {
    name: 'middleground',
    zIndex: 10,
    routes,
    surface: Middleground,
  },
  {
    name: 'foreground',
    zIndex: 20,
    routes,
    surface: Foreground,
  },
  {
    name: 'modal',
    zIndex: 40,
    routes,
    surface: Modal,
  },
]
