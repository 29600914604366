import { NerdsForm } from 'components/forms/nerds-form'
import { useConfig } from 'holoride-campaign/modals/buy/form/hooks/use-config'
import { Fields } from 'holoride-campaign/modals/buy/form/fields'

const useFormProperties = props => ({
  loading: props.loading,
  config: useConfig({
    onSubmit: props.onSubmit,
    initialValues: props.initialValues,
    primarySale: props.primarySale,
    nftsBought: props.nftsBought,
  }),
  submitErrors: props.submitErrors,
  callbackUrl: props.callbackUrl,
})

export const Buy = props => <NerdsForm {...useFormProperties(props)}>
  <Fields callbackUrl={props.callbackUrl} submitErrors={props.submitErrors}
          nftsBought={props.nftsBought} primarySale={props.primarySale} />
</NerdsForm>
