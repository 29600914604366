import { Label } from 'components/forms/nerds-form/fields/label'
import { RolesErrors } from 'components/forms/nerds-form/fields/collection-field/roles-errors'
import { CollectionSelector } from 'components/forms/nerds-form/fields/collection-field/collection-selector'
import { CollectionErrors } from 'components/forms/nerds-form/fields/collection-field/collection-errors'

export const CollectionField = ({ name }) => <div className={'nerds-form-field mb-3'}>
  <Label label={'Collection'} />
  <CollectionSelector name={name} />
  <CollectionErrors name={name} />
  <RolesErrors name={name} />
</div>

