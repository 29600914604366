import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: '',
  input: '',
  errors: '',
  loading: false,
  cursor: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload), loading: false })

const reset = () => initialState

const setCursor = (state, { payload }) => ({ ...state, cursor: payload })

export const trending = createSlice({
  initialState,
  name: 'trending',
  reducers: {
    attempt,
    success,
    failure,
    reset,
    setCursor,
  },
})

export const { actions } = trending
