import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section/index'
import { Campaign } from 'subdomain/home/campaigns/campaign'
import './index.scss'

export const Campaigns = ({ data }) => {
  const title = get(data, 'title', '')

  return <HomeSection title={title} centeredTitle>
    <Campaign data={data} />
  </HomeSection>
}
