import { NftOwner } from 'nft-page/details/other-details/owners/nft-owner'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { faWallet } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SftOwners } from 'nft-page/details/other-details/owners/sft-owners'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'

export const Owners = () => {
  const { asset } = useAssetWithOwner()
  const isNft = asset.type === 'NonFungibleESDT'
  const title = isNft ? 'Owner' : 'Some Owners'

  return <KeyPair icon={<FontAwesomeIcon icon={faWallet} />} title={title}>
    {isNft ? <NftOwner asset={asset} /> : <SftOwners asset={asset} /> }
  </KeyPair>
}
