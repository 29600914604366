import { UserImage } from 'components/feed-card/user-image'
import { Username } from 'components/username'
import moment from 'moment'
import { ReferenceName } from 'components/feed-card/reference-name'
import 'components/feed-card/infos/index.scss'
import { useHerotag } from 'components/feed-card/hooks/use-herotag'

export const Infos = props => <div className={'feed-card-infos flex-grow-1'}>
  <div className={'d-flex align-items-start align-items-md-center'}>
    <UserImage {...props} />
    <div className={'flex-grow-1'}>
      <div className={'feed-card-infos-p'}>
        <Username herotag={useHerotag(props.who.herotag)} address={props.who.address} color={'primary'} openNewLayer />
        {' '}
        {props.action.actionLabel}
        {' '}
        {!props.shouldHideReference && <ReferenceName {...props} />}
        {' '}
        {props.action.suffixLabel}
      </div>
      <div className={'feed-card-time'}>{moment(props.when).fromNow()}</div>
    </div>
  </div>
</div>
