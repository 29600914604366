import { ofType } from 'redux-observable'
import { actions } from 'nft-page/details/other-listings/slices/running'
import { catchError, mergeMap } from 'rxjs/operators'
import { auctions as otherAuctions } from 'repositories/auctions'
import { actions as listActions } from 'nft-page/details/other-listings/slices/list'
import { from } from 'rxjs'
import { loading } from 'loading/slices'
import { Loading } from 'loading'
import { failure as failureSlice } from 'failure/slices'
import { history } from 'network'
import { get } from 'lodash'

export const running = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = action => otherAuctions.getRunningAuction(action.payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  listActions.append(get(payload, 'data.auctions.edges')),
  loading.actions.hide({ id: Loading.ID.nft.auctions, variant: Loading.VARIANT.partial }),
])

const failure = errors => from([
  actions.failure(errors),
  failureSlice.actions.show({ route: history.location.pathname }),
  loading.actions.hide({ id: Loading.ID.nft.auctions, variant: Loading.VARIANT.partial }),
])
