import { useDispatch } from 'react-redux'
import { actions as nft } from 'nft-page/slices/nft'
import { actions as owner } from 'nft-page/owner/slices/owner'
import { actions as owners } from 'owners/slices/owners'
import { useCallback } from 'react'
import { actions as assetHistory } from 'asset-history/slices/asset-history-slice'
import { actions as listHistory } from 'asset-history/slices/list'
import { actions as nftPageSelectedAuction } from 'nft-page/auction/slices/selected-auction'
import { actions as nftPageAuctionOrdersList } from 'nft-page/auction/bids/slices/orders'
import { actions as nftPageAuctionList } from 'nft-page/details/other-listings/slices/list'
import { actions as runningAuctions } from 'nft-page/details/other-listings/slices/running'
import { actions as priceActions } from 'auction/price/slices'
import { actions as reportActions } from 'report/slices'
import { get as offers } from 'offers/slices'

export const useStateCleanup = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(nft.reset({}))
    dispatch(owner.reset({}))
    dispatch(owners.reset({}))
    dispatch(assetHistory.reset({}))
    dispatch(listHistory.reset({}))
    dispatch(nftPageSelectedAuction.reset({}))
    dispatch(nftPageAuctionOrdersList.reset({}))
    dispatch(nftPageAuctionList.reset({}))
    dispatch(runningAuctions.reset({}))
    dispatch(priceActions.reset({}))
    dispatch(reportActions.reset({}))
    dispatch(offers.actions.reset({}))
  }, [dispatch])
}
