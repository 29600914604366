import { useAppContext } from 'startup/context'
import { ReactComponent as MexIcon } from 'startup/assets/mex-icon.svg'
import './connect-link.scss'

export const ConnectLink = () => {
  const { sidebarOpened, setSidebarOpened } = useAppContext()

  const handleClick = () => {
    setSidebarOpened(!sidebarOpened)
  }

  return (
    <button className={`nav-link-outline btn connect-link btn-primary align-items-center`} onClick={handleClick}>
      <MexIcon />
      <span className={'d-none d-md-inline'}>Connect</span>
    </button>
  )
}
