import { useCallback } from 'react'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'

export const useOnClick = auction => {
  const navigate = useLayeredNavigate()

  return useCallback(() => {
    const path = `/nfts/${auction.identifier}/auction/${auction.id}/modal/withdraw`
    navigate(path, 'modal')
  }, [auction, navigate])
}
