import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { get } from 'lodash'
import { BN } from 'bn.js'

export const useMaxBidReached = () => {
  const auction = useNftDefaultAuction()
  const maxBidAmount = get(auction, 'maxBid.amount', '')
  const topBidAmount = get(auction, 'topBid.amount', '')

  return new BN(String(maxBidAmount)).eq(new BN(String(topBidAmount)))
}
