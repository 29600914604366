import { useIsActivePrinceCharming } from 'account/header/hooks/use-is-active-prince-charming'
import { useCurrentProfileSelector } from 'current-profile/hooks/use-current-profile-selector'
import { useProfileSelector } from 'account/profile/hooks/use-profile-selector'
import { get } from 'lodash'

export const useProfileData = () => {
  const isActivePrinceCharming = useIsActivePrinceCharming()
  const currentProfileSelector = useCurrentProfileSelector()
  const loggedInProfile = get(currentProfileSelector, 'data', {})
  const otherProfileSelector = useProfileSelector()
  const otherProfile = get(otherProfileSelector, 'data', {})

  return isActivePrinceCharming ? loggedInProfile : otherProfile
}
