import { get } from 'lodash'
import { useIsBidsMenuVisible } from 'nft-page/hooks/use-is-bids-menu-visible'
import { Orders } from 'nft-page/auction/bids/orders'
import { useAssetOrdersList } from 'nft-page/auction/bids/hooks/use-asset-orders-list'

export const Bids = () => {
  const isBidsMenuVisible = useIsBidsMenuVisible()
  const orders = useAssetOrdersList()
  const ordersLength = get(orders, 'length', [])

  return isBidsMenuVisible && ordersLength ? <Orders /> : ''
}
