import { filter } from 'failure/slices/filter'
import { createSlice } from '@reduxjs/toolkit'
import { factory } from 'failure/slices/factory'

const initialState = []

const show = (state, action) => ([
  ...state,
  { ...action.payload, ...factory.make(action.payload) },
])

const hide = (state, action) => state
  .filter(filter.byVariant(action))
  .filter(filter.byId(action))

export const failure = createSlice({
  name: 'failure',
  initialState,
  reducers: { show, hide },
})
