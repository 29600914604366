import { useSubdomainCollections } from 'subdomain/home/collections/hooks/use-subdomain-collections'
import { filter, get } from 'lodash'

export const useSubdomainCollectionAssets = collection => {
  const subdomainCollections = useSubdomainCollections()
  const collections = subdomainCollections.map(collection => collection.node)
  const actualCollection = filter(collections, { collection: collection.identifier })

  return get(actualCollection, '[0].collectionAsset.assets', [])
}
