import { Before } from 'holoride-campaign/action/before'
import { NotWhitelisted } from 'holoride-campaign/action/not-whitelisted'
import { Buy } from 'holoride-campaign/action/buy'
import { Redeem } from 'holoride-campaign/action/redeem'
import { Ended } from 'holoride-campaign/action/ended'
import { Between } from 'holoride-campaign/action/between'
import { Redeemed } from 'holoride-campaign/action/redeemed'

export const statuses = () => ({
  startingIn: Before,
  notWhitelisted: NotWhitelisted,
  buy: Buy,
  redeem: Redeem,
  ended: Ended,
  between: Between,
  redeemed: Redeemed,
})
