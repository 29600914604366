import { useRoyalties } from 'nft-page/details/other-details/royalties/hooks'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/pro-light-svg-icons'

export const Royalties = () => {
  const royalties = useRoyalties()

  return <KeyPair icon={<FontAwesomeIcon icon={faCrown} />} title={'Royalties'}>
    {royalties ? `${royalties}%` : '0%'}
  </KeyPair>
}
