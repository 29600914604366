import { get, isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'

export const useCurrentLayer = () => {
  const { pathname, state } = useLocation()
  const layerLink = get(state, 'layerLink', {})

  if (isEmpty(layerLink)) return

  return Object.keys(layerLink).find(key => layerLink[key] && layerLink[key].startsWith(pathname))
}
