import { useFormikContext } from 'formik'
import { Loader } from 'components/forms/nerds-form/fields/loader'
import { useIsLoading } from 'components/forms/nerds-form/fields/submit-button/hooks/use-is-loading'

export const SubmitButton = ({ label }) => {
  const { isValid } = useFormikContext()
  const isLoading = useIsLoading()

  return <button type={'submit'} data-testid={'submit-form'}
                 className={'btn btn-lg btn-primary w-100'}
                 disabled={!isValid || isLoading}>
    <Loader label={label || 'Save'} loading={isLoading} />
  </button>
}
