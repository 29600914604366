import { useState } from 'react'
import { AdminSidebarEnabled } from 'components/layered-router/hooks/use-render-route/admin-sidebar-enabled'
import match from '@nerds.sh/js-pattern'
import { generatePath, Navigate, useLocation } from 'react-router-dom'
import { useIsPrinceCharming } from 'auth/mega-authorized'
import { useParamsGlobal } from 'components/layered-router/hooks/use-params-global'
import { useResetKey } from 'components/layered-router/hooks/use-render-route/use-reset-key'

const checkNotAuthenticated = (route, isPrinceCharming) => (route.authenticatedRoute && !isPrinceCharming)

const checkDirectNavigation = (route, state) => (route.preventDirectNavigation && !state)

const redirectPath = (route, params) => route.redirectPath && generatePath(route.redirectPath, params)

export const RenderRoute = ({ route, ...props }) => {
  const isPrinceCharming = useIsPrinceCharming()

  const params = useParamsGlobal()
  const location = useLocation()
  const [key, setKey] = useState(Math.random())

  useResetKey(setKey)

  const redirectNotAuthenticated = checkNotAuthenticated(route, isPrinceCharming)
  const redirectDirectNavigation = checkDirectNavigation(route, location.state)
  const shouldRedirect = redirectNotAuthenticated || redirectDirectNavigation

  return match(route)
    .with(shouldRedirect, () => <Navigate to={redirectPath(route, params)} replace />)
    .with(route.adminSidebar && !shouldRedirect, () => <AdminSidebarEnabled route={route} {...props} />)
    .otherwise(() => <route.element key={key} />)
}

