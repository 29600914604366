import { useSearchInput } from 'search/hooks/use-search-input'
import { useSearchResults } from 'search/hooks/use-search-results'

const isHerotag = (item, input) => item.kind === 'account' && item?.item.name?.split('.')[0] === input.replace(/@/g, '')

const isTag = (item, input) => item.kind === 'tag' && item?.item?.identifier === input.replace(/#/g, '')

const matchInput = (item, input) => item?.item?.identifier === input

export const useFirstExactMatch = () => {
  const results = useSearchResults()
  const input = useSearchInput()
  const exactMatches = results.filter(item => isHerotag(item, input) || matchInput(item, input) || isTag(item, input))

  return exactMatches[0]
}
