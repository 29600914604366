const removeQuery = path => path.split('?')[0]

export const computeLocation = (layer, location, layerLink) => {
  let computedLocation = location

  if (layerLink) {
    if (layerLink[layer.name]) {
      computedLocation = { pathname: removeQuery(layerLink[layer.name]) }
    }
  }

  return computedLocation
}
