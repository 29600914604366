import { Loading } from 'loading'
import { useCallback } from 'react'
import { loading } from 'loading/slices'
import { useDispatch } from 'react-redux'

export const useHideLoadings = () => {
  const dispatch = useDispatch()

  return useCallback((id, variant = Loading.VARIANT.ghostCard) => {
    dispatch(loading.actions.hide({ id, variant }))
  }, [dispatch])
}
