import { Icon } from 'components/featured-collection/header/icon'
import { Title } from 'components/featured-collection/header/title'
import { Description } from 'components/featured-collection/header/description'

export const Header = ({ thumbnailUrl, description, name }) => <div className={'featured-collection-header'}>
  <Icon thumbnailUrl={thumbnailUrl} />
  <div>
    <Title title={name} />
    <Description description={description} />
  </div>
</div>
