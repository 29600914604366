import { VerifiedBadge } from 'components/verified-badge'
import './title.scss'

const makeTitleClassname = smallTitle => {
  const initial = 'data-card-header-title me-2'

  return smallTitle ? `${initial} data-card-header-title-small` : initial
}

export const Title = ({ titlePrefix, title, verified, smallTitle }) => <div className={makeTitleClassname(smallTitle)}>
  <div className={'data-card-header-title-inner'}>
    <span className={'data-card-header-title-prefix'}>
      {titlePrefix}
      {' '}
    </span>
    {title}
    {' '}
  </div>
  {verified && <VerifiedBadge /> }
</div>
