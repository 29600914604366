import moment from 'moment'
import { get } from 'lodash'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'

export const useDeadlineReached = () => {
  const auction = useNftDefaultAuction()
  const endDate = get(auction, 'endDate', '')

  return endDate !== 0 && moment(endDate * 1000) <= moment()
}
