import { useUpdateInput } from 'modals/account/edit/description/hooks/use-update-input'
import { useUpdateErrors } from 'modals/account/edit/description/hooks/use-update-errors'
import { useUpdateLoading } from 'modals/account/edit/description/hooks/use-update-loading'

export const useHadSuccess = () => {
  const input = useUpdateInput()
  const errors = useUpdateErrors()
  const loading = useUpdateLoading()

  return input && !errors.length && !loading
}
