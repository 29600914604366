import { SortBy } from 'filters/components/sort-by'
import { IsActive } from 'explore-nfts/tabs/is-active'
import { sortByOptions } from 'filters/explore-filters/collections/sort-by-options'
import { sortByOptions as nftsSortByOptions } from 'filters/explore-filters/nfts/sort-by-options'
import { sortByOptions as creatorsSortByOption } from 'filters/explore-filters/creators/sort-by-options'

export const ExploreFilters = () => <>
  <IsActive tabName={'collections'}>
    <SortBy options={sortByOptions} id={'explore-collections-sortby-filter'} />
  </IsActive>
  <IsActive tabName={'nfts'}>
    <SortBy options={nftsSortByOptions} id={'explore-nfts-sortby-filter'} />
  </IsActive>
  <IsActive tabName={'creators'}>
    <SortBy options={creatorsSortByOption} id={'explore-creators-sortby-filter'} />
  </IsActive>
</>
