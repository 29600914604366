import { useBadgeVisibility } from 'subdomain/components/my-asset-card/badges/hooks/use-badge-visibility'
import { Badge, BadgeType } from 'subdomain/components/my-asset-card/badge'
import { TimeLeftBadge } from 'subdomain/components/my-asset-card/badges/time-left-badge'
import 'subdomain/components/my-asset-card/badges/index.scss'

export const Badges = props => {
  const { timeLeft, outBidded, auctionEnded, topBidder, offerExpired, offerActive } = useBadgeVisibility(props)

  return <div className={'my-asset-card-badges'}>
    <TimeLeftBadge auctionEnded={auctionEnded} auction={props.auction} timeLeft={timeLeft} />
    <Badge label={'Outbidded'} type={BadgeType.Outbidded} condition={outBidded} />
    <Badge label={'Ended'} type={BadgeType.Ended} condition={auctionEnded} />
    <Badge label={'Top Bidder'} type={BadgeType.TopBidder} condition={topBidder} />
    <Badge label={'Offer Expired'} type={BadgeType.OfferExpired} condition={offerExpired} />
    <Badge label={'Offer Active'} type={BadgeType.OfferActive} condition={offerActive} />
  </div>
}
