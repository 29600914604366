import { of, map, from } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { sftCollections } from 'repositories/sft-collections'
import { sft as slice } from 'modals/collection/create/issue/slices/sft'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'
import { throwOnInsufficientFunds } from 'modals/throw-on-insufficient-funds'

export const issueSft = actions$ => actions$
  .pipe(ofType(slice.actions.attempt.type))
  .pipe(map(action => ({ action })))
  .pipe(mergeMap(execute))

const execute = context => invokeRepository(context)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.issueSftCollection, factor: 2 })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const invokeRepository = context => sftCollections.issue(context.action.payload)

const success = payload => from([
  slice.actions.success(payload),
])

const failure = result => of(slice.actions.failure(extractGraphqlErrors(result)))
