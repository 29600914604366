import { DropdownButton } from 'components/dropdown-traits/dropdown-button'
import { SelectedTraits } from 'components/dropdown-traits/selected-traits'
import { OptionButton } from 'components/dropdown-traits/option-button'
import { useSelectOption } from 'components/dropdown-traits/hooks/use-select-option'
import { useIsContainingOption } from 'components/dropdown-traits/hooks/use-is-containing-option'
import { useDeselectOption } from 'components/dropdown-traits/hooks/use-deselect-option'
import 'components/dropdown-traits/index.scss'
import { ClearButton } from 'components/dropdown-traits/dropdown-button/clear-button'
import { isEmpty } from 'lodash'

export const DropdownTraits = ({ options, id, name, onChange, selectedOptions }) => {
  const selectOption = useSelectOption({ onChange, name, selectedOptions })
  const deselectOption = useDeselectOption({ selectedOptions, name, onChange })
  const isContainingOption = useIsContainingOption(selectedOptions)
  const isSelectedOptionEmpty = isEmpty(selectedOptions)

  return <div className={`btn-group dropdown-traits w-100`}>
    <DropdownButton id={id} name={name} selectedOptions={selectedOptions} onChange={onChange} />
    <ClearButton isSelectedOptionEmpty={isSelectedOptionEmpty} name={name} onChange={onChange} />
    <div className={'dropdown-menu'}>
      {!isSelectedOptionEmpty && <SelectedTraits selectedOptions={selectedOptions} name={name}
                                                 deselectOption={deselectOption} />}
      {options.map((option, index) => !isContainingOption(option) && <OptionButton option={option} key={index}
                                                                                   selectOption={selectOption} />)}
    </div>
  </div>
}
