import { Routes } from '../routes'
import { computeLocation } from './compute-location'
import { useLocation } from 'react-router-dom'
import { DefaultSurface } from '../default-surface'
import { useComputeZIndex } from '../hooks/use-compute-z-index'

export const RoutesSurface = ({ layer, layerLink }) => {
  const location = useLocation()
  const Surface = layer.surface || DefaultSurface
  const zIndexedLayer = useComputeZIndex(layer, layerLink)

  return <Surface layer={zIndexedLayer}>
    <Routes location={computeLocation(zIndexedLayer, location, layerLink)} layer={zIndexedLayer} />
  </Surface>
}
