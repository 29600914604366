import { useHerotag } from 'nft-page/artist-account/hooks/use-herotag'
import { useNftArtistSelector } from 'nft-page/hooks/nft/use-nft-artist-selector'
import defaultAvatar from 'common/default-avatar'
import { get } from 'lodash'
import { UserInfo } from 'components/user-info'
import 'nft-page/artist-account/artist/index.scss'
import { Title } from 'nft-page/artist-account/title'

export const Artist = () => {
  const artist = useNftArtistSelector()
  const address = get(artist, 'address', '')
  const image = get(artist, 'profile', defaultAvatar) || defaultAvatar

  return <div className={'artist-account-artist mb-4'}>
    <Title>Creator:</Title>
    <UserInfo image={image} herotag={useHerotag()} address={address} color={'primary'} />
  </div>
}
