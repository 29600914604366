import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import moment from 'moment'
import { actions } from 'modals/auction/start/slices/create-auction'
import { nominateAmount } from 'common/conversion/nominate-amount'

export const useCreateBundleAuction = (asset, paymentToken) => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    const variables = {
      identifier: asset.identifier,
      quantity: `${payload.quantity}`,
      minBid: nominateAmount(payload.minBid, paymentToken.decimals),
      maxBid: nominateAmount(payload.maxBid || 0, paymentToken.decimals),
      deadline: moment(payload.deadline).unix()
        .toString(),
      paymentToken: paymentToken.identifier,
      maxOneSftPerPayment: false,
    }

    return dispatch(actions.attempt(variables))
  }, [asset.identifier, dispatch, paymentToken.decimals, paymentToken.identifier])
}
