import { useCallback } from 'react'
import { actions } from 'modals/account/create-nft/collections/slices/collections'
import { useDispatch } from 'react-redux'

export const useResetList = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.reset())
  }, [dispatch])
}
