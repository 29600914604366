import { useCollectionAuctionsList } from './use-collection-auctions-list'
import { useFilters } from '../../../filters/hooks/use-filters'
import { difference, isEmpty, keys } from 'lodash'

export const useShouldHideFilters = () => {
  const items = useCollectionAuctionsList()
  const { search } = useFilters()
  const hasNoFilters = isEmpty(difference(keys(search), ['on-sale']))

  return isEmpty(items) && hasNoFilters
}
