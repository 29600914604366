import { FollowButton } from 'components/follow-button'
import { UnfollowButton } from 'components/unfollow-button'
import { useState } from 'react'
import { useOnClick } from 'explore-nfts/tabs/creators/hooks/use-on-click'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'

export const Action = ({ isFollowed, address }) => {
  const [isFollowedLocal, setIsFollowedLocal] = useState(isFollowed)
  const onClick = useOnClick(setIsFollowedLocal, address, isFollowedLocal)
  const { address: currentUserAddress } = useGetAccountInfo()
  const isMe = currentUserAddress === address

  if (isMe) return ''

  return isFollowedLocal ? <UnfollowButton onClick={onClick} /> : <FollowButton onClick={onClick} />
}
