import 'nft-page/details/other-listings/actions/action-button/action-button.scss'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

const makeProps = ({ onClick, className, disabled }) => ({
  className: disabled ? `${className} other-auction-action-disabled` : className,
  onClick: disabled ? () => {} : onClick,
})

const defaultClassName = 'other-auction-action btn btn-secondary'

export const ActionButton = ({ onClick, text, className = defaultClassName, disabled, tooltipPrice }) => {
  const priceRef = useRef(null)
  useTooltip(priceRef)

  return (
    <button {...makeProps({ onClick, className, disabled, tooltipPrice, priceRef })}
            data-bs-toggle={'tooltip'} data-bs-classname={'tooltip-inner'} data-bs-placement={'top'}
            title={tooltipPrice} ref={priceRef}>
      {text}
    </button>
  )
}
