import { useCallback } from 'react'
import { loading } from 'loading/slices'
import { useDispatch } from 'react-redux'

const METADATA = Object.freeze({
  id: '',
  variant: '',
})

export const useShowLoading = () => {
  const dispatch = useDispatch()

  return useCallback((payload, metadata = METADATA) => {
    dispatch(loading.actions.show([{ ...metadata, payload }]))
  }, [dispatch])
}
