import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { Link } from 'nft-page/header/collection/link'

export const Collection = () => {
  const collection = useNftSelector('nftPage.data.assets.edges[0].node.collection')

  return <div className={'nft-page-collection'}>
    {collection ? <Link collection={collection} /> : ''}
  </div>
}
