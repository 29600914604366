import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import * as hooks from 'feed/initial/hooks'

export const Right = () => {
  const current = hooks.useFolloweesCount()
  const threshold = hooks.useFolloweesThreshold()

  return <div className={'d-flex align-items-center mb-3 align-self-center align-self-md-end me-3 md-md-0'}>
    <div className={'me-3 initial-progress-container'}>
      <CircularProgressbarWithChildren value={current} maxValue={threshold}>
        <span className={'initial-bold'}>
          {current}
          /
          {threshold}
        </span>
      </CircularProgressbarWithChildren>
    </div>
    <button className={'btn btn-primary launch-feed-button'}
            disabled={hooks.useIsLaunchDisabled()} onClick={hooks.useOnLaunchClick()}>
      Launch your feed
    </button>
  </div>
}
