import { get, find, includes } from 'lodash'
import { useSelector } from 'react-redux'

const path = 'collections.lookup.data.results'

export const useCollectionIdentifier = () => {
  const results = useSelector(state => get(state, path, []))
  const result = find(results, ({ data }) => includes(data, 'ok'))

  return get(result, 'data[1]', '')
}
