import { useFormikContext } from 'formik'
import { get } from 'lodash'

export const useMinOrMaxBidValue = () => {
  const { values } = useFormikContext()
  const minBid = get(values, 'minBid', 1)
  const maxBid = get(values, 'maxBid', 1)

  return values.checkedMaxBid ? maxBid : minBid
}
