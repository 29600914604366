import { from } from 'rxjs'
import { Loading } from 'loading'
import { loading } from 'loading/slices'
import { ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import { assets as repository } from 'repositories/assets'
import { actions } from 'account/tabs/assets/liked/slices/get'
import { actions as listActions } from 'account/tabs/assets/liked/slices/list'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repository.getAccountLiked(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  listActions.append(payload.data.assets.edges),
  loading.actions.hide({ id: Loading.ID.account.tab.liked, variant: Loading.VARIANT.ghostCard }),
])

const failure = payload => from([
  actions.failure(payload),
  loading.actions.hide({ id: Loading.ID.account.tab.liked, variant: Loading.VARIANT.ghostCard }),
])
