import { useDropzoneContext } from '../../context'
import { useIsPreview } from '../../hooks/use-is-preview'
import './file-types.scss'

export const FileTypes = () => {
  const { isDragActive, fileTypeInfo } = useDropzoneContext()
  const isPreview = useIsPreview()

  return !isDragActive && !isPreview && <p className={'dropzone-field-filetypes mb-4'}>{fileTypeInfo}</p>
}
