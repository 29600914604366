import { useCallback } from 'react'
import { actions } from 'notifications/transactions/slices/transactions'
import { useDispatch } from 'react-redux'

export const useOnHide = id => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.seen(id))
  }, [dispatch, id])
}
