import { useNftsStats } from 'explore-nfts/stats/hooks/use-nfts-stats'
import { useCollectionsStats } from 'explore-nfts/stats/hooks/use-collections-stats'
import { useArtistsStats } from 'explore-nfts/stats/hooks/use-artists-stats'

export const useTabs = () => {
  const nftsCount = useNftsStats()
  const collectionsCount = useCollectionsStats()
  const creatorsCount = useArtistsStats()

  return [
    { name: 'nfts', label: 'NFTs', count: nftsCount },
    { name: 'collections', label: 'Collections', count: collectionsCount },
    { name: 'creators', label: 'Creators', count: creatorsCount },
  ]
}
