import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'collections/slices/collections'

export const makePayload = ({ ownerAddress, collection }) => ({ filters: { ownerAddress, collection } })

export const useFetchCollections = () => {
  const dispatch = useDispatch()

  return useCallback(input => {
    dispatch(actions.attempt(makePayload(input)))
  }, [dispatch])
}
