import { matchPath, useLocation } from 'react-router-dom'
import { routeNames } from 'startup/routes'

export const useIsNftPageOpened = () => {
  const location = useLocation()

  return matchPath({
    path: routeNames.nftPage,
    exact: false,
  }, location.pathname)
}
