import { ghosts } from 'loading/ghosts'
import { VARIANT, ID } from 'loading/constants'
import { usePayloads } from 'loading/hooks/use-payloads'
import { useShowGhosts } from 'loading/hooks/use-show-ghosts'
import { useHideGhosts } from 'loading/hooks/use-hide-ghosts'
import { useShowLoading } from 'loading/hooks/use-show-loading'
import { useShowLoadings } from 'loading/hooks/use-show-loadings'
import { useHideLoadings } from 'loading/hooks/use-hide-loadings'

export const Loading = {
  ID,
  VARIANT,
  ghosts,
  useHideLoadings,
  useShowLoading,
  useShowLoadings,
  useHideGhosts,
  useShowGhosts,
  usePayloads,
}
