import 'account/tabs/tab-header/style.scss'
import { headers } from 'account/tabs/headers'
import { useActiveTab } from 'account/tabs/hooks/use-active-tab'
import { Action } from 'account/tabs/tab-header/action'
import { Filters } from 'account/tabs/tab-header/filters'

export const TabHeader = () => {
  const header = headers[useActiveTab()]

  return <div className={'tab-header row align-items-center justify-content-between'}>
    <div className={'tab-header-filters col'}>
      <Filters header={header} />
    </div>
    <div className={'col-12 col-md-auto'}>
      <Action header={header} />
    </div>
  </div>
}
