import * as Yup from 'yup'
import * as minBidPrice from 'components/forms/create-sft-auctions/single-form/hooks/use-min-bid-schema'
import moment from 'moment'
import { maxBidSchema } from 'components/forms/create-sft-auctions/single-form/hooks/max-bid-schema'
import { useQuantityTest } from 'components/forms/create-sft-auctions/single-form/hooks/use-quantity-test'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const useValidationSchema = () => Yup.object().shape({
  deadline: Yup.date()
    .required('Required')
    .default(moment().add(1, 'd')
      .toDate())
    .min(new Date(), 'Deadline cannot be in the past'),
  minBid: minBidPrice.useMinBidSchema('Required'),
  checkedMaxBid: Yup.boolean().default(false),
  maxBid: maxBidSchema(),
  identifier: Yup.string().required(),
  paymentToken: Yup.string().required()
    .default(egldLabel()),
  quantity: useQuantityTest(),
  maxOneSftPerPayment: Yup.boolean().default(false),
})
