import match from '@nerds.sh/js-pattern'

import { handleSftAll } from 'auction-flows/handlers/handle-sft-all'
import { handleSftOnePerPayment } from 'auction-flows/handlers/handle-sft-one-per-payment'

const isSftAll = ({ auction }) => auction.type === 'SftAll'

const isSftOnePerPayment = ({ auction }) => auction.type === 'SftOnePerPayment'

export const handleSftAuctionOpen = input => match(input)
  .with(isSftAll, handleSftAll)
  .with(isSftOnePerPayment, handleSftOnePerPayment)
  .execute()
