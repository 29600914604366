import { Link } from 'subdomain/home/media/link'
import { get } from 'lodash'
import { HomeSection } from 'subdomain/components/home-section/index'
import 'subdomain/home/media/index.scss'

export const Media = ({ data }) => {
  const tiles = get(data, 'tiles', [])
  const title = get(data, 'title', '')

  return <HomeSection title={title} className={'subdomain-home-media'}>
    <div className={'row justify-content-center subdomain-home-media-links'}>
      {tiles.map((item, index) => <Link title={item.title} link={item.link} image={item.background} key={index} />)}
    </div>
  </HomeSection>
}
