import { useCallback } from 'react'
import { useSettings } from '../../../../settings/hooks/use-settings'
import { FIREFOX_ADDON_LINK, CHROME_EXTENSION_LINK } from '@multiversx/sdk-dapp/constants'

const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') !== -1

const makeUrl = extensionId => {
  if (isFirefox()) {
    return FIREFOX_ADDON_LINK
  } else {
    return CHROME_EXTENSION_LINK
  }
}

export const useNavigateToStore = () => {
  const { extensionId } = useSettings()

  return useCallback(() => {
    window.open(makeUrl(extensionId), '_blank')
  }, [extensionId])
}
