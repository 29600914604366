import { useEffect } from 'react'
import { Toast } from 'bootstrap'

const DELAY = 7000

const shouldHide = status => status === 'success'

export const useAutohideToast = (id, status) => {
  useEffect(() => {
    const element = document.getElementById(id)
    const toastInstance = Toast.getInstance(element)
    let timer

    if (toastInstance && shouldHide(status)) {
      timer = setTimeout(() => {
        toastInstance.hide()
      }, DELAY)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [id, status])
}
