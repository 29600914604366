import { useDispatch } from 'react-redux'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { actions } from 'modals/account/create-nft/collections/slices/collections'
import { useCallback, useEffect } from 'react'

export const useIssueServer = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()

  return useCallback(() => {
    const payload = { filters: { ownerAddress: address }, pagination: { first: 100 } }

    return dispatch(actions.attempt(payload))
  }, [address, dispatch])
}

export const useFetchAccountCollections = () => {
  const issueServer = useIssueServer()

  useEffect(() => {
    issueServer()
  }, [issueServer])
}
