import { MultiRange } from 'filters/components/multi-range'

const calcValue = (position, minVal, scale) => Math.exp(position * scale + Math.log(minVal))

const calcPosition = (value, minVal, scale) => (Math.log(value) - Math.log(minVal)) / scale

const calcScale = (max, min) => (Math.log(max || 10000) - Math.log(min || 1)) / 100

const getCalcValue = ({ value, onChange, min, max }) => onChange(({
  name: value.name,
  value: calcValue(value.value, min, calcScale(max, min)).toFixed(value.name === 'max' ? 2 : 4),
}))

export const MultiRangeLogarithmic = ({ step, initialMin, initialMax, min, max, onChange }) => {
  const initialMinPos = calcPosition(initialMin, min, calcScale(max, min))
  const initialMaxPos = calcPosition(initialMax, min, calcScale(max, min))

  const formatLabel = (position, precision = 2) => calcValue(position, min, calcScale(max, min)).toFixed(precision)

  return initialMin && initialMax && <MultiRange step={step} initialMin={initialMinPos} min={'0'} max={'100'}
                                                 initialMax={initialMaxPos}
                                                 onChange={value => getCalcValue({ value, onChange, min, max })}
                                                 formatLabel={formatLabel} />
}
