import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { reportCollection } from 'admin/slices'

export const useReportCollection = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  return useCallback(() => {
    dispatch(reportCollection.actions.attempt({ input: { collectionIdentifier: identifier } }))
  }, [dispatch, identifier])
}
