import { combineReducers } from 'redux'
// import { collections } from 'account/tabs/assets/collections/slices'
import { get as auctions } from 'account/tabs/assets/auctions/slices/get'
import { profile as getProfile } from 'account/profile/slices/profile'
import { collectionAssets } from 'account/collection-assets/slices/collection-assets'
import { socialLinks as getSocialLinks } from 'account/profile-social-links/slices/social-links'
import { tabs } from 'account/tabs/reducers'
import { get as collected } from 'account/tabs/assets/collected/slices/get'
import { creations } from 'account/tabs/assets/creations/slices'
import { get as claimable } from 'account/tabs/assets/claimable/slices/get'
import { get as liked } from 'account/tabs/assets/liked/slices/get'
import { bids } from 'account/tabs/assets/bids/slices/get'
import { get as stats } from 'account/stats/slices/get'
import { reducer as follow } from 'account/profile/follow/reducer'

export const account = combineReducers({
  // collections,
  auctions: auctions.reducer,
  profile: getProfile.reducer,
  socialLinks: getSocialLinks.reducer,
  collectionAssets: collectionAssets.reducer,
  tabs,
  collected: collected.reducer,
  creations,
  claimable: claimable.reducer,
  liked: liked.reducer,
  bids: bids.reducer,
  stats: stats.reducer,
  follow,
})

