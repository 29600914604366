import { useIsFollowed } from 'account/profile/follow/hooks/use-is-followed'
import { useFollow } from 'account/profile/follow/hooks/use-follow'
import { useUnfollow } from 'account/profile/follow/hooks/use-unfollow'
import { useAuthorizedAction } from 'common/hooks/use-authorized-action'

export const useOnClick = () => {
  const follow = useFollow()
  const unfollow = useUnfollow()
  const isFollowed = useIsFollowed()

  return useAuthorizedAction(isFollowed ? unfollow : follow)
}
