import { ReactComponent as FeeRoyaltiesIcon } from 'startup/assets/fee-royalties.svg'
import 'components/forms/nerds-form/fields/final-fee-field/royalties-information/index.scss'
import { useRoyalties } from 'components/forms/nerds-form/fields/hooks/use-royalties'

const royaltiesProps = 'royalties-information-item final-fee-item d-flex align-items-center'

const lastPart = `of this auction final price.`

export const RoyaltiesInformation = ({ asset }) => <div className={royaltiesProps}>
  <FeeRoyaltiesIcon className={'final-fee-icon'} />
  <div>
    <div className={'royalties-information-item-title d-flex justify-content-start'}>
      {`${useRoyalties(asset)}% Royalties`}
    </div>
    <div>
      <span>Creator will get</span>
      {` `}
      <span className={'royalties-information-item-text'}>{`${useRoyalties(asset)}% royalties`}</span>
      {` `}
      <span>{lastPart}</span>
    </div>
  </div>
</div>
