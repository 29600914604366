export const VARIANT = Object.freeze({
  ghostCard: 'LOADING_VARIANT.GHOST_CARD',
  partial: 'LOADING_VARIANT.PARTIAL',
})

export const ID = Object.freeze({
  account: {
    profile: 'LOADING_ID.ACCOUNT.PROFILE',
    tab: {
      collected: 'LOADING_ID.ACCOUNT.TAB.COLLECTED',
      creations: 'LOADING_ID.ACCOUNT.TAB.CREATIONS',
      auctions: 'LOADING_ID.ACCOUNT.TAB.AUCTIONS',
      bids: 'LOADING_ID.ACCOUNT.TAB.BIDS',
      collections: 'LOADING_ID.ACCOUNT.TAB.COLLECTIONS',
      claimable: 'LOADING_ID.ACCOUNT.TAB.CLAIMABLE',
      liked: 'LOADING_ID.ACCOUNT.TAB.LIKED',
    },
  },
  explore: { page: 'LOADING_ID.EXPLORE.PAGE' },
  market: { page: 'LOADING_ID.MARKET.PAGE' },
  trending: { page: 'LOADING_ID.TRENDING.PAGE' },
  collection: { page: 'LOADING_ID.COLLECTION.PAGE' },
  transaction: { pending: 'LOADING_ID.PENDING_TRANSACTION' },
  nft: {
    media: 'LOADING_ID.NFT_PAGE.MEDIA',
    auctions: 'LOADING_ID.NFT_PAGE.AUCTIONS',
    selectedAuction: 'LOADING_ID.NFT_PAGE.SELECTED_AUCTION',
  },
  campaigns: { page: 'LOADING_ID.CAMPAIGNS.PAGE' },
  campaign: { page: 'LOADING_ID.CAMPAIGN.PAGE' },
  search: { search: 'LOADING_ID.SEARCH.SEARCH' },
})

