import { LayeredLink } from 'components/layered-router/layered-link'

export const SuffixLabel = ({ whom }) => <>
  {whom.count === 1 && 'NFT '}
  in the
  {' '}
  <LayeredLink path={`/collections/${whom.collection.id}`} layer={'middleground'}>
    {whom.collection.name}
  </LayeredLink>
  {' '}
  collection
</>
