import { useCollectionPaymentTokens } from 'collections/payment-tokens/hooks/use-collection-payment-tokens'
import { get } from 'lodash'
import { useFilterValue } from 'filters/hooks/use-filter-value'

export const useTokenDecimals = () => {
  const filterTokenValue = useFilterValue('token')
  const collectionPaymentTokens = useCollectionPaymentTokens()
  const token = collectionPaymentTokens.find(item => item.identifier === filterTokenValue)

  return get(token, 'decimals')
}
