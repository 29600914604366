import * as Yup from 'yup'

export const editProfileSchema = () => Yup.object().shape({
  description: Yup.string()
    .max(100, 'Too Long!')
    .min(3, 'Too Short!')
    .nullable(),
  urls: Yup.array().of(Yup.string()),
  privacy: Yup.boolean(),
})
