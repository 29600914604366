import * as Yup from 'yup'
import { useTestHasAuction } from 'components/forms/accept-offer-form/sft/hooks/use-test-has-auction'
import { useTestHasEnoughTokens } from 'components/forms/accept-offer-form/sft/hooks/use-test-has-enough-tokens'

const useWithAuctions = (balance, quantity, auctions) => Yup.string()
  .test('auctionId', useTestHasAuction(auctions))
  .test('auctionId', useTestHasEnoughTokens(balance, quantity, auctions))
  .default('')

export const useAuctionSchema = (balance, quantity, auctions) => {
  const withAuctions = useWithAuctions(balance, quantity, auctions)

  return auctions.length ? withAuctions : Yup.string()
}
