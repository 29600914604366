import { CampaignStatus } from 'components/campaign-status'
import { LabeledProgress } from 'components/labeled-progress'
import { CardWithHeader } from 'components/card-with-header'
import { Header } from 'components/card-with-header/header'
import { Content } from 'components/card-with-header/content'
import 'campaign/infos/index.scss'
import { useCampaign } from '../hooks/use-campaign'
import { FastBuyInfo } from './fast-buy-info'

export const Infos = () => {
  const campaign = useCampaign()

  return <CardWithHeader className={'campaign-infos'}>
    <Header>
      <CampaignStatus campaign={campaign} />
    </Header>
    <Content>
      <LabeledProgress status={campaign.status} total={campaign.totalNfts} available={campaign.availableNfts} />
      {campaign.status === 'Running' && <FastBuyInfo tiers={campaign.tiers} />}
    </Content>
  </CardWithHeader>
}
