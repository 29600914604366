import * as Yup from 'yup'

const auctionSchema = () => Yup.string()
  .when('auctions', {
    is: auctions => auctions.length > 0,
    then: schema => schema.required('Please select an active auction'),
    otherwise: schema => schema.notRequired(),
  })
  .default('')

export const useValidationSchema = () => Yup.object().shape({
  offerId: Yup.string().required(),
  auctionId: auctionSchema(),
})
