import './index.scss'
import { get } from 'lodash'

export const Partner = ({ partner }) => {
  const logoUrl = get(partner, 'logo.data.attributes.url', '')

  return <div className={'col-12 col-md-4 mb-2'}>
    <div className={'d-flex justify-content-center flex-column h-100 text-center partner'}>
      <div className={'partner-logo'}>
        <img src={logoUrl} alt={'...'} />
      </div>
      <h3>{partner.title}</h3>
      <p className={'flex-grow-1'}>{partner.description}</p>
    </div>
  </div>
}
