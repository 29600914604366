import { useFetchAuctions } from 'trending/hooks/use-fetch-auctions'
import { useFetchingPayload } from 'trending/hooks/use-fetching-payload'
import { useTag } from 'trending/hooks/use-tag'
import { useEffect } from 'react'
import { Loading } from 'loading'
import { settings } from 'settings/fetching'

export const useFetchTrending = () => {
  const fetchAuctions = useFetchAuctions()
  const payload = useFetchingPayload()
  const tag = useTag()
  const showGhosts = Loading.useShowGhosts(Loading.ghosts.auctions)
  const hideGhosts = Loading.useHideGhosts()

  useEffect(() => {
    hideGhosts(Loading.ID.trending.page)
    showGhosts(Loading.ID.trending.page, settings().listRequestSize)
    fetchAuctions(payload)
  }, [tag, fetchAuctions, payload, hideGhosts, showGhosts])
}
