import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: '',
  data: '',
  errors: '',
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, input: payload, loading: true })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: payload, loading: false })

const reset = () => initialState

export const get = createSlice({
  initialState,
  name: 'subdomain/get',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})
