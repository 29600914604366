import { List } from 'components/collection-card/images/list'
import { Empty } from 'components/collection-card/empty'
import { isEmpty } from 'lodash'
import { ReactComponent as CollectionIcon } from 'startup/assets/collection-icon.svg'
import 'components/collection-card/images/index.scss'

export const Images = ({ thumbs }) => <div className={'collection-card-images d-flex flex-wrap'}>
  <div className={'gradient'} />
  <div className={'collection-card-icon'}>
    <CollectionIcon />
    Collection
  </div>
  {isEmpty(thumbs) ? <Empty /> : <List thumbs={thumbs} /> }
</div>
