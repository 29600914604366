import { usePrice } from 'auction/price/hooks/use-price'
import { getPriceDetails } from 'common/get-price-details'
import { Content } from 'nft-page/auction/price/content'
import 'nft-page/auction/price/index.scss'

export const Price = () => {
  const price = usePrice()
  const priceDetails = getPriceDetails(price)

  return priceDetails.amount !== '0' ? <Content {...priceDetails} /> : ''
}
