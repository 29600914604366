import { useCallback } from 'react'
import { failure } from 'failure/slices'
import { useDispatch } from 'react-redux'

export const useShowFailure = () => {
  const dispatch = useDispatch()

  return useCallback(({ id, variant, route } = {}) => {
    dispatch(failure.actions.show({ id, variant, route }))
  }, [dispatch])
}
