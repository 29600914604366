import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'collections/payment-tokens/slices/get'
import { useParams } from 'react-router-dom'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useFetchPaymentTokens = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()
  const isSubdomain = useIsSubdomain()
  const cmsMarketplaceKey = useCmsMarketplaceKey()
  const marketplaceKey = isSubdomain ? cmsMarketplaceKey : ''

  useEffect(() => {
    dispatch(actions.attempt({ filters: { collectionIdentifier: identifier, marketplaceKey } }))
  }, [dispatch, identifier, marketplaceKey])
}
