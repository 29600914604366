import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import moment from 'moment'
import { get } from 'lodash'

export const useTimeDiff = () => {
  const auction = useNftDefaultAuction()
  const endDate = get(auction, 'endDate')

  return moment.unix(endDate).diff(moment())
}
