import { Price } from 'components/price'
import { useHeroTag } from 'nft-page/auction/bids/accounts/hooks/use-hero-tag'
import { useProfileImage } from 'nft-page/auction/bids/accounts/hooks/use-profile-image'
import 'nft-page/auction/bids/accounts/index.scss'
import { UserDetails } from 'components/user-details'
import moment from 'moment'
import * as _ from 'lodash'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'

const fromOrder = order => (path, defaultValue = '') => _.get(order, path, defaultValue)

const getDate = date => moment(date * 1000).format('DD/MM/YYYY, hh:mm')

const useIsTopBidder = address => {
  const auction = useNftDefaultAuction()
  const topBidderAddress = _.get(auction, 'topBidder.address', '')

  return topBidderAddress === address
}

export const Order = ({ order, index }) => {
  const get = fromOrder(order)
  const date = getDate(get('creationDate'))
  const price = get('price', {})
  const address = get('ownerAddress')
  const isTopBidder = useIsTopBidder(address)
  const showTopBidderBadge = index === 0 ? isTopBidder : false

  return <div className={'d-flex mt-3 align-items-center order-accounts'}>
    <UserDetails herotag={useHeroTag(order)} image={useProfileImage(order)} address={address} date={date}
                 showTopBidderBadge={showTopBidderBadge} />
    <Price price={price} />
  </div>
}
