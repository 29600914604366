import { follow } from 'repositories/followers/follow'
import { unfollow } from 'repositories/followers/unfollow'
import { followersCount } from 'repositories/followers/followers-count'
import { follows } from 'repositories/followers/follows'
import { followeesCount } from 'repositories/followers/followees-count'
import { followees } from 'repositories/followers/followees'
import { followers as getFollowers } from 'repositories/followers/followers'

export const followers = {
  post: {
    follow,
    unfollow,
  },
  get: {
    followersCount,
    follows,
    followeesCount,
    followees,
    followers: getFollowers,
  },
}
