import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: '',
  input: '',
  errors: '',
  loading: false,
  cursor: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload) })

const setCursor = (state, { payload }) => ({ ...state, cursor: payload })

const reset = state => ({ ...state, cursor: '' })

const cleanUp = () => initialState

export const get = createSlice({
  initialState,
  name: 'account/claimable/get',
  reducers: {
    attempt,
    success,
    failure,
    setCursor,
    reset,
    cleanUp,
  },
})

export const { actions } = get
