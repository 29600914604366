import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'home/featured-collections/slices'

export const useResetState = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(actions.reset({}))
  }, [dispatch])
}
