import { useResetLiked } from 'account/tabs/assets/liked/behaviour/hooks/use-reset-liked'
import { useResetList } from 'account/tabs/assets/liked/hooks/use-reset-list'
import { useActiveTab } from 'account/tabs/hooks/use-active-tab'
import { useEffect } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useResetState = () => {
  const resetLiked = useResetLiked()
  const resetList = useResetList()
  const address = useViewerAddress()
  const activeTab = useActiveTab()

  useEffect(() => {
    resetList()
    resetLiked()
  }, [resetLiked, resetList, address, activeTab])
}
