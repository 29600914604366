import ReactMarkdown from 'react-markdown'

export const Box = ({ box, bootstrapColumns }) => (
  <div className={`boxes-section-item col-12 col-md-${bootstrapColumns}`}>
    <div className={'boxes-section-item-caption mb-4'}>
      <div className={'boxes-section-item-image-wrapper'}>
        <img className={'boxes-section-item-image'} src={box.image.data.attributes.formats.medium.url} />
      </div>
      <div className={'mt-3 text-center'}>
        <ReactMarkdown>{box.caption}</ReactMarkdown>
      </div>
    </div>
  </div>
)
