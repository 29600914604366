import { useOnClick } from 'auction/actions/withdraw/hooks/use-on-click'
import { FlexRow } from 'components/flex-row'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/details/other-listings/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'

export const Withdraw = ({ auction, address }) => {
  const withdrawClick = useOnClick(auction)
  const onClick = usePermittedAction(withdrawClick, address)

  return <FlexRow>
    <WatchableAction entity={auction} actionType={actionTypes.withdraw} text={'Withdraw'} onClick={onClick}
                     className={'btn btn-secondary other-auction-action'} />
  </FlexRow>
}
