import { Button } from 'components/button'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { humanize } from 'components/conversion/humanize/index'
import 'components/unlike-button/style.scss'

const label = count => count === 1 ? 'like' : 'likes'

const useProps = ({ onClick, icon, setIcon }) => ({
  className: 'unlike-button',
  icon: 'heart-filled',
  onClick,
  buttonType: 'secondary',
})

export const UnlikeButton = props => {
  const [icon, setIcon] = useState('heart-filled')

  return <Button {...useProps({ ...props, icon, setIcon })}>
    {humanize(props.count?.toString())}
    {' '}
    <span className={'d-none d-sm-inline'}>{label(props.count)}</span>
  </Button>
}

UnlikeButton.propTypes = { onClick: PropTypes.func.isRequired }
