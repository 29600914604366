import './multi-range.scss'
import { MultiRangeContext, useValue } from 'filters/components/multi-range/context'
import { ContentLarge } from 'filters/components/multi-range/content-large'
import { ContentSmall } from 'filters/components/multi-range/content-small'

const defaultFormatLabel = value => value

export const MultiRange = ({
  max, min, initialMax, initialMin, step,
  onChange = () => {}, formatLabel = defaultFormatLabel,
}) => {
  const value = useValue({ onChange, initialMin, initialMax })

  return <div className={'multi-range align-items-center flex-grow-1'}>
    <MultiRangeContext.Provider value={value}>
      <ContentLarge initialMax={initialMax} min={min} max={max} initialMin={initialMin} step={step}
                    formatLabel={formatLabel} />
      <ContentSmall step={step} initialMin={initialMin} min={min} max={max} initialMax={initialMax}
                    formatLabel={formatLabel} />
    </MultiRangeContext.Provider>
  </div>
}
