import PropTypes from 'prop-types'
import { Context, useDefaultContext } from 'components/collection-card/context'
import { Content } from 'components/collection-card/content'

export const Component = props => <Context.Provider value={useDefaultContext()}>
  <Content {...props} />
</Context.Provider>

Component.defaultProps = { className: '', collection: {} }

Component.propTypes = {
  collection: PropTypes.object,
  className: PropTypes.string,
}
