import { useCallback } from 'react'
import match from '@nerds.sh/js-pattern'

export const useOnShare = (network = 'facebook') => {
  const url = window.location.href

  const windowUrl = match(network)
    .with(() => network === 'twitter', () => `http://twitter.com/intent/tweet?&url=${encodeURIComponent(url)}`)
    .with(() => network === 'facebook', () => `http://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`)
    .execute()

  return useCallback(() => {
    window.open(windowUrl, 'sharer', 'toolbar=0,status=0,width=626,height=436')
  }, [windowUrl])
}
