import { Counter } from 'sidebar/my-wallet/menu/counter'
import { LayeredLink } from 'components/layered-router/layered-link'

const defaultLinkClassname = 'sidebar-menu-link'
const alignClass = 'd-flex align-items-center justify-content-center'

const makeLinkClassname = () => `${defaultLinkClassname} ${alignClass}`

export const Link = ({ to, label, counter, modal }) => <li className={'sidebar-menu-item'}>
  <LayeredLink path={to} layer={modal ? 'modal' : ''} className={makeLinkClassname()}>
    {label}
    {counter !== undefined && <Counter counter={counter} />}
  </LayeredLink>
</li>
