import { useAllNftsCount } from 'explore-nfts/tabs/nfts/stats/hooks/use-all-nfts-count'
import { useLiveAuctionsActualCount } from 'explore-nfts/tabs/nfts/stats/hooks/use-live-auctions-actual-count'
import { useBuyNowActualCount } from 'explore-nfts/tabs/nfts/stats/hooks/use-buy-now-actual-count'

export const useFacets = () => {
  const allNftsCount = useAllNftsCount()
  const liveAuctionsCount = useLiveAuctionsActualCount()
  const buyNowCount = useBuyNowActualCount()

  return [{ label: 'All', name: 'all', value: 'all', count: allNftsCount, selected: true },
    { label: 'Live Auction', name: 'live-auction', value: 'live-auction', count: liveAuctionsCount },
    { label: 'Buy Now', name: 'buy-now', value: 'buy-now', count: buyNowCount },
  ]
}
