import 'featured-pages/components/featured-boxes/box/index.scss'

export const Box = ({ title, subtitle, icon }) => <div className={'featured-boxes-box'}>
  <div className={'featured-boxes-box-icon'}>
    <img src={icon} />
  </div>
  <div>
    {title && <h3 className={'featured-boxes-box-title'}>{title}</h3>}
    {subtitle && <p className={'featured-boxes-box-subtitle'}>{subtitle}</p>}
  </div>
</div>

