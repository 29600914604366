import { search } from 'repositories/search'
import { map } from 'rxjs/operators'
import { get } from 'lodash'

const format = (list, kind) => list.map(item => ({ item, kind }))

const buildResponse = ({ data }) => ({
  searchTerm: get(data, 'search.searchTerm', ''),
  results: [...format(get(data, 'search.collections', []), 'collection'),
    ...format(get(data, 'search.accounts', []), 'account'),
    ...format(get(data, 'search.nfts', []), 'asset'),
    ...format(get(data, 'search.tags', []), 'tag')],
})

export const repository = input => search.doSearch(input)
  .pipe(map(buildResponse))

