import { useCallback } from 'react'
import match from '@nerds.sh/js-pattern'
import { get } from 'lodash'

const auctionPath = 'node.id'

const assetPath = 'node.lowestAuction.id'

const orderPath = 'node.auction.id'

export const useAuctionsMatch = auctionId => useCallback(item => match(item)
  .like({ __typename: 'AuctionEdge' }, () => get(item, auctionPath) === auctionId)
  .like({ __typename: 'AssetEdge' }, () => get(item, assetPath) === auctionId)
  .like({ __typename: 'OrderEdge' }, () => get(item, orderPath) === auctionId)
  .execute(), [auctionId])
