import { EditProfileForm } from 'components/forms/edit-profile-form'
import { useOnSubmit } from 'modals/account/edit/hooks/use-on-submit'
import { useIsLoading } from 'modals/account/edit/hooks/use-is-loading'
import { useErrors } from 'modals/account/edit/hooks/use-errors'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

export const Form = () => {
  const loading = useIsLoading()
  const errors = useErrors()
  const location = useCloseLayerLocation()

  return <EditProfileForm onSubmit={useOnSubmit()} submitErrors={errors} loading={loading} callbackUrl={location} />
}
