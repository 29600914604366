import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'modals/auction/bid/slices/place-bid'
import { nominateAmount } from 'common/conversion/nominate-amount'

export const usePlaceBid = (auction, paymentToken) => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    const variables = {
      input: {
        auctionId: parseInt(auction.id),
        identifier: auction.identifier,
        price: nominateAmount(payload.price, paymentToken.decimals),
        paymentTokenIdentifier: paymentToken.identifier,
      },
    }

    return dispatch(actions.attempt(variables))
  }, [auction.id, auction.identifier, dispatch, paymentToken.decimals, paymentToken.identifier])
}
