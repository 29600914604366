import { ReactComponent as UsersIcon } from 'startup/assets/gradient-users-icon.svg'
import { OnboardingModal } from 'components/onboarding-modal'

export const Follow = () => <OnboardingModal id={'onboarding-follow'} buttonText={'Follow Creators'} showCancel>
  <UsersIcon className={'onboarding-modal-icon'} />
  <h4 className={'onboarding-modal-title'}>
    True Ownership + Snazzy
    Media = Web3 Culture
  </h4>
  <div className={'onboarding-modal-content'}>
    <p>
      Digital trends become viral overnight. Don’t miss anything from your favorite creators.
      Discover new ones.
    </p>
    <p>Follow them and get the scoop on what they like, buy or put up for sale.</p>
    <p>
      <strong>PS:</strong>
      {' '}
      They can follow you back as well!
    </p>
  </div>
</OnboardingModal>
