import * as Yup from 'yup'
import * as auction from 'components/forms/accept-offer-form/sft/hooks/use-auction-schema'

const auctionSchema = (balance, quantity, auctions) => Yup.string()
  .when('checkedAuction', {
    is: true,
    then: auction.useAuctionSchema(balance, quantity, auctions),
    otherwise: Yup.string()
      .default(undefined),
  })
  .default('')

export const useValidationSchema = (balance, quantity, auctions) => Yup.object().shape({
  offerId: Yup.string().required(),
  checkedAuction: Yup.boolean().default(false),
  auctionId: auctionSchema(balance, quantity, auctions),
})
