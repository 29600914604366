import { gql } from 'graphql-tag'

export const WITHDRAW = gql`
    mutation Withdraw($auctionId: Int!) {
      withdraw(auctionId: $auctionId) {
          chainID
          data
          gasLimit
          gasPrice
          nonce
          receiver
          sender
          value
          version
      }
    }
`
