import { useCallback } from 'react'
import { useTraitsContext } from 'modals/traits/context'
import { useSetLocationFilters } from 'filters/hooks/use-set-location-filters'
import { isEmpty } from 'lodash'

export const useOnChange = () => {
  const { selectedTraits } = useTraitsContext()
  const setLocationFilters = useSetLocationFilters()

  return useCallback(trait => {
    const traitIndex = selectedTraits.findIndex(item => trait.name === item.name)

    if (traitIndex !== -1) {
      const newSelectedTraits = [...selectedTraits]

      if (isEmpty(trait.values)) {
        delete newSelectedTraits[traitIndex]
      } else {
        newSelectedTraits[traitIndex] = trait
      }

      setLocationFilters({ name: 'traits', value: newSelectedTraits })
    } else {
      const newSelectedTraits = [...selectedTraits, trait]
      setLocationFilters({ name: 'traits', value: newSelectedTraits })
    }
  }, [selectedTraits, setLocationFilters])
}
