import { Empty as EmptyState } from 'components/empty'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { useList } from 'modals/account/followees/hooks/use-list'
import { isEmpty } from 'lodash'
import { useIsLoading } from 'modals/account/followees/hooks/use-is-loading'
import { ListContent } from 'modals/account/followees/list-content'

const stateProps = {
  icon: faUser,
  message: 'You are not following anyone yet.',
}

export const List = () => {
  const list = useList()
  const isLoading = useIsLoading()

  return isEmpty(list) && !isLoading ? <EmptyState {...stateProps} /> : <ListContent />
}
