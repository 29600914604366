import { useEgldBalance } from 'sidebar/my-wallet/balance/hooks/use-egld-balance'
import { useTokenBiddingBalance } from 'sidebar/my-wallet/balance/hooks/use-token-bidding-balance'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const useEgldSidebarStats = () => {
  const balance = useEgldBalance()
  const biddingBalance = useTokenBiddingBalance('EGLD', egldLabel())

  return { balance, biddingBalance }
}
