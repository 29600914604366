import { Behaviour } from 'holoride-campaign/action/buy/countdown/behaviour'
import { DurationLabel } from 'components/duration-label'
import { useContext } from 'holoride-campaign/action/buy/context'
import './index.scss'

export const Countdown = ({ endDate }) => {
  const { timeLeft } = useContext()

  return <div className={'holoride-campaign-countdown'}>
    <Behaviour date={endDate} />

    <div className={'d-flex align-items-center'}>
      <DurationLabel duration={timeLeft} />
    </div>
  </div>
}
