import { DropdownTraits } from 'components/dropdown-traits'
import { useOnChange } from 'modals/traits/hooks/use-on-change'
import { useTraitsContext } from 'modals/traits/context'
import { get } from 'lodash'

export const Body = ({ traits }) => {
  const { selectedTraits } = useTraitsContext()
  const onChange = useOnChange()

  return traits.map((trait, index) => {
    const selectedTrait = selectedTraits.find(item => item.name === trait.name)
    const selectedOptions = get(selectedTrait, 'values', [])

    return <DropdownTraits key={index} selectedOptions={selectedOptions}
                           options={trait.values} name={trait.name} onChange={onChange} />
  })
}
