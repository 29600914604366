import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { useMemo } from 'react'
import { subdomainFilters } from 'nft-page/details/other-listings/hooks/subdomain-filters'
import { mainFilters } from 'nft-page/details/other-listings/hooks/main-filters'

export const useMakePayload = ({ identifier, after, status }) => {
  const isSubdomain = useIsSubdomain()
  const marketplaceKey = useCmsMarketplaceKey()

  return useMemo(() => {
    const subdomain = subdomainFilters({ identifier, after, status, marketplaceKey })
    const main = mainFilters({ identifier, after, status })

    return isSubdomain ? subdomain : main
  }, [after, identifier, isSubdomain, marketplaceKey, status])
}
