import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Seo } from 'components/seo'
import { Content } from 'subdomain/home/content'
import { Behaviour } from 'subdomain/home/behaviour'
import { useSeoProps } from 'subdomain/home/use-seo-props'

export const HomePage = () => <div className={'subdomain-home'}>
  <ScrollToTopOnMount />
  <Seo {...useSeoProps()} />
  <Behaviour />
  <Content />
</div>
