import { useCloseModal } from 'components/bootstrap-modal/hooks/use-close-modal'
import { useCallback } from 'react'

export const useOnCancel = id => {
  const closeModal = useCloseModal(id)

  return useCallback(() => {
    closeModal()
  }, [closeModal])
}
