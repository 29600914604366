import { Price as PriceComponent } from 'components/price'
// import { ReactComponent as Logo } from 'startup/assets/egld-price-logo.svg'
import 'components/campaign-card/price/index.scss'
import { startingPrice } from './starting-price'
import { useRealTimeUsdPrice } from 'common/hooks/use-real-time-price'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const Price = ({ tiers }) => {
  const price = {
    amount: startingPrice(tiers),
    token: egldLabel(),
    usdAmount: useRealTimeUsdPrice(startingPrice(tiers)),
  }

  return <div className={'campaign-card-price d-flex align-items-center'}>
    {/* <Logo className={'campaign-card-price-logo'} /> */}
    <span className={'campaign-card-price-text flex-grow-1'}>Starting Price</span>
    <PriceComponent price={price} />
  </div>
}

