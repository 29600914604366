import { useCurrentPrice } from 'components/asset-card/hooks/use-current-price'
import { getPriceDetails } from 'common/get-price-details'
import { get } from 'lodash'
import { Icon } from 'components/asset-card/price/icon'
import 'components/asset-card/price/price.scss'

export const Price = ({ auction }) => {
  const bid = useCurrentPrice(auction)
  const { token, millyfiedAmount, usdAmount } = getPriceDetails(bid)
  const iconUrl = get(auction, 'marketplace.iconUrl')

  return <div className={'asset-card-price justify-content-between'}>
    <div className={`asset-card-price-inner ${iconUrl ? 'has-icon' : ''}`}>
      <Icon iconUrl={iconUrl} />
      <div className={'asset-card-prices'}>
        <div className={'asset-card-price-value'}>
          <b>{`${millyfiedAmount}`}</b>
          {` ${token}`}
        </div>
        {usdAmount && <div className={'asset-card-price-usd'}>{`$${usdAmount}`}</div>}
      </div>
    </div>
  </div>
}
