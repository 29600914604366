import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/claimable/slices/get'

export const useResetClaimable = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.cleanUp())
  }, [dispatch])
}
