import { get } from 'lodash'
import { useSelector } from 'react-redux'

const NFT_PATH = 'modals.collection.create.issue.nft.errors'
const SFT_PATH = 'modals.collection.create.issue.sft.errors'

export const useServerErrors = () => {
  const nft = useSelector(state => get(state, NFT_PATH, []))
  const sft = useSelector(state => get(state, SFT_PATH, []))

  return [...nft, ...sft]
}
