import { actions as exploreActions } from 'home/explore-nfts/slices/get'
import { actions as trendingActions } from 'home/trending/slices/get'
import { actions as tagFilterActions } from 'home/trending/slices/tag'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const actions = [
  exploreActions.reset,
  trendingActions.reset,
  tagFilterActions.reset,
]

export const useResetState = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    actions.forEach(action => dispatch(action({})))
  }, [dispatch])
}
