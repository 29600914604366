import { BuyOrBid } from 'nft-page/details/other-listings/actions/buy-or-bid'
import { ClaimAuction } from 'nft-page/details/other-listings/actions/claim'
import { PlaceBid } from 'nft-page/details/other-listings/actions/place-bid'
import { EndAuction } from 'nft-page/details/other-listings/actions/end-auction'
import { BuySft } from 'nft-page/details/other-listings/actions/buy-sft'
import { Withdraw } from 'nft-page/details/other-listings/actions/withdraw'
import { SftWithdraw } from 'nft-page/details/other-listings/actions/sft-withdraw'
import { BuyNft } from 'nft-page/details/other-listings/actions/buy-nft'
import { ViewOnOtherMarket } from 'nft-page/details/other-listings/actions/view-on-other-market'

export const statuses = () => ({
  notForSale: () => '',
  displayPrice: () => '',
  endedAuction: () => '',
  sell: () => '',
  topBidder: () => '',
  claim: ClaimAuction,
  withdraw: Withdraw,
  end: EndAuction,
  buySft: BuySft,
  buyOrBid: BuyOrBid,
  bid: PlaceBid,
  sftWithdraw: SftWithdraw,
  buyNft: BuyNft,
  viewOnOtherMarket: ViewOnOtherMarket,
})
