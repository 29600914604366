import { useSidebarBiddingsStats } from 'sidebar/stats/hooks/use-sidebar-biddings-stats'
import { head } from 'lodash'
import { getMillifiedAmount } from 'sidebar/my-wallet/balance/hooks/get-millified-amount'
import { getMillifiedUsdAmount } from 'sidebar/my-wallet/balance/hooks/get-millified-usd-amount'

const getData = (balance, symbol) => ({
  amount: getMillifiedAmount(balance.amount, balance.tokenData.decimals),
  usdAmount: getMillifiedUsdAmount(balance.usdAmount),
  symbol,
})

export const useTokenBiddingBalance = (tokenIdentifier, symbol) => {
  const biddings = useSidebarBiddingsStats()
  const tokenBiddingBalance = head(biddings.filter(item => item.token === tokenIdentifier))

  return tokenBiddingBalance ? getData(tokenBiddingBalance, symbol) : ''
}
