import { useCallback } from 'react'
import { buildFilters } from 'filters/explore-filters/nfts/auctions/filters-logic'
import { useLastCursor } from 'explore-nfts/tabs/nfts/auctions/hooks/use-last-cursor'
import { useFilters } from 'filters/hooks/use-filters'

export const useFetchPayloadPreview = () => {
  const cursor = useLastCursor()
  const { background } = useFilters()

  return useCallback(() => buildFilters({ ...background, cursor }), [background, cursor])
}
