import React from 'react'
import { CenteredContent } from 'components/centered-content'
import { UserImage } from 'components/user-image'
import { Edit } from 'account/profile-photo/edit'
import 'account/profile-photo/style.scss'
import { useProfileData } from 'account/header/hooks/use-profile-data'
import { get } from 'lodash'
import defaultAvatar from 'common/default-avatar'

const wrapperClassname = 'profile-photo-container col col-xl-auto'

export const ProfilePhoto = () => {
  const currentProfileData = useProfileData()
  const profilePhoto = get(currentProfileData, 'profile.url', defaultAvatar) || defaultAvatar
  const isNft = get(currentProfileData, 'profile.isNftOwned')

  return <CenteredContent className={wrapperClassname}>
    <UserImage image={profilePhoto} isNft={isNft} />
    <Edit />
  </CenteredContent>
}
