import { useCopyClick } from 'components/toasts/transaction-toast/hooks/use-copy-click'
import { trimmer } from 'components/toasts/transaction-toast/transaction-item/trimmer'
import { useState } from 'react'
import { copyIcons } from 'components/toasts/transaction-toast/transaction-item/copy-icons'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { settings } from 'settings/fetching'

const getBaseUrl = () => settings().explorer

export const buildUrl = txHash => `${getBaseUrl()}/transactions/${txHash}`

export const TransactionItem = ({ hash }) => {
  const [icon, setIcon] = useState(copyIcons.copy)

  return <div className={'toast-transaction-item d-flex justify-content-center align-items-center'}>
    <span className={'toast-transaction-item-address'}>{trimmer(hash)}</span>
    <div className={'flex-grow-1'}>
      <button className={'border-0 bg-transparent ms-2'} onClick={useCopyClick(hash, setIcon)}>
        {icon}
      </button>
    </div>
    <a href={buildUrl(hash)} target={'_blank'} rel={'noreferrer'}>
      <FontAwesomeIcon icon={faExternalLink} color={'#838C9A'} />
    </a>
  </div>
}
