import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { settings } from 'settings/fetching'

export const useFetchPayloadPreview = () => {
  const { identifier: asset } = useParams()
  const collection = asset.split('-')
  const collectionIdentifier = `${collection[0]}-${collection[1]}`

  return useCallback(({ cursor }) => ({
    pagination: { first: settings().listRequestSize, after: cursor },
    filters: { collection: collectionIdentifier },
  }), [collectionIdentifier])
}
