import { useCallback } from 'react'
import { loading } from 'loading/slices'
import { useDispatch } from 'react-redux'

export const useShowLoadings = () => {
  const dispatch = useDispatch()

  return useCallback((payloads, metadata) => {
    const loadings = payloads.map(payload => ({ payload, ...metadata }))
    dispatch(loading.actions.show(loadings))
  }, [dispatch])
}
