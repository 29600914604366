import { Failure } from 'failure'
import { Content } from 'collections/assets/content'
import { RenderIf } from 'account/common/render-if'
import { useIsOnSale } from 'collections/actions/on-sale-filter/hooks/use-is-on-sale'
import { Behaviour } from 'collections/assets/behaviour'
import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'
import { AssetsFilter } from 'filters/collection-filters/assets'
import { useCollectionTraits } from 'collections/hooks/use-collection-traits'

export const Assets = () => {
  const [collection] = useCollectionsSelector()
  const traits = useCollectionTraits()
  const isOnSale = useIsOnSale()

  return <RenderIf isTrue={collection && !isOnSale}>
    <Behaviour />
    <AssetsFilter traits={traits} />
    <Failure.Partial id={'collection.assets'}>
      <Content />
    </Failure.Partial>
  </RenderIf>
}
