import { createSlice } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'

const initialState = { data: [] }

const append = ({ data }, { payload }) => ({ data: uniqBy([...data, ...payload], 'node.identifier') })

const reset = () => ({ data: [] })

export const list = createSlice({
  initialState,
  name: 'trending/list',
  reducers: {
    append,
    reset,
  },
})

export const { actions } = list
