import { faLayerGroup } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import 'nft-page/auction/bundle/index.scss'

const tokensAndTitle = auction => {
  const type = get(auction, 'type', '')
  const tokens = get(auction, 'availableTokens', 0)

  return type === 'SftOnePerPayment' ? { tokens: 1, title: 'Piece' } : { tokens, title: 'Bundle Auction' }
}

export const Bundle = ({ auction }) => {
  const { tokens, title } = tokensAndTitle(auction)
  const pieces = tokens === 1 ? 'edition' : 'editions'

  return <div className={'nft-page-bundle'}>
    <div className={'nft-page-bundle-title d-flex align-items-center'}>
      <FontAwesomeIcon icon={faLayerGroup} className={'nft-page-bundle-image me-2'} />
      {`x${tokens} ${title}`}
    </div>
    <div className={'nft-page-bundle-subtitle'}>
      {`You will get ${tokens} ${pieces} of the collectible in this auction.`}
    </div>
  </div>
}
