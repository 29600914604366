import { useField } from 'formik'

export const ConsentField = ({ name, label, disabled }) => {
  const [field, meta, { setTouched }] = useField(name)

  return <div className={'nerds-form-field d-flex form-check p-0 pb-1 align-items-center'}>
    <div>
      <input className={'form-check-input clickable mt-0 ms-0 me-3'}
             type={'checkbox'}
             id={name} name={name}
             disabled={disabled}
             onChange={field.onChange}
             onInput={() => setTouched()}
             value={field.value}
             checked={field.value}
             onBlur={field.onBlur} />
      {meta.touched && meta.error && <small className={'text-danger'}>{meta.error}</small>}
    </div>
    <div>
      <label className={'form-check-label'} htmlFor={name}>{label}</label>
    </div>
  </div>
}
