import { gql } from 'graphql-tag'

export const BUY_SFT = gql`
    mutation BuySft($input: BuySftActionArgs!) {
        buySft(input: $input) {
            chainID
            data
            gasLimit
            gasPrice
            nonce
            receiver
            sender
            value
            version
        }
    }
`
