import { SliderLabel } from 'filters/components/multi-range/slider-label'
import { SliderValues } from 'filters/components/multi-range/slider-values'
import { Sliders } from 'filters/components/multi-range/sliders'

const className = 'd-flex flex-column flex-md-row align-items-md-center justify-content-between d-md-none'

export const ContentSmall = props => <div className={className}>
  <div className={'d-flex justify-content-between align-items-center mb-2'}>
    <SliderLabel />
    <SliderValues max={props.initialMax} min={props.initialMin} formatLabel={props.formatLabel} />
  </div>
  <div className={'my-2'}>
    <Sliders max={props.max} min={props.min} step={props.step} formatLabel={props.formatLabel} />
  </div>
</div>
