import { ButtonSecondary } from 'components/button-secondary'
import { useOnClick } from 'account/tabs/create-nft-button/hooks/use-on-click'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const Button = ({ disabled, tooltipMessage }) => {
  const onClick = useOnClick()
  const messageRef = useRef(null)
  useTooltip(messageRef)

  return <div data-bs-toggle={'tooltip'} data-bs-classname={'tooltip-inner'} data-bs-placement={'top'}
              title={disabled ? tooltipMessage : undefined} ref={messageRef}
              className={'account-create-button-wrapper'}>
    <ButtonSecondary icon={'add'} onClick={onClick} disabled={disabled}> Create Nft </ButtonSecondary>
  </div>
}
