import React from 'react'
import { SocialLink, coerceUrlType } from 'components/social-link'
import { get } from 'lodash'
import 'components/social-links/index.scss'
import { useProfileData } from 'account/header/hooks/use-profile-data'

export const SocialLinks = () => {
  const profileData = useProfileData()
  const links = get(profileData, 'socialLinks', [])

  return <div className={'social-links-container row justify-content-center'}>
    {links.map(link => <div key={link.type - 1} className={'col-auto'}>
      <SocialLink url={link.url || ''} type={coerceUrlType(link)} />
    </div>)}
  </div>
}
