import { useEffect } from 'react'
import match from '@nerds.sh/js-pattern'
import { useAppContext } from 'startup/context'
import { get } from 'lodash'
import { useIsNftView } from 'startup/hooks/use-is-nft-view'
import { useIsModalOpen } from 'startup/hooks/use-is-modal-open'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'

export const useKeysControls = () => {
  const { sidebarOpened, setSidebarOpened } = useAppContext()
  const hasModalOpen = useIsModalOpen()
  const isNftView = useIsNftView()
  const closeLayer = useCloseLayerNavigate()

  useEffect(() => {
    const onEscapeSidebar = () => setSidebarOpened(false)

    const onDocumentKeyDown = event => {
      if (get(event, 'key', '') === 'Escape') {
        match({})
          .with(isNftView, () => closeLayer())
          .with(sidebarOpened, onEscapeSidebar)
          .with(hasModalOpen, () => {})
          .otherwise(() => {})
      }
    }

    document.addEventListener('keydown', onDocumentKeyDown)

    return () => document.removeEventListener('keydown', onDocumentKeyDown)
  }, [sidebarOpened, setSidebarOpened, hasModalOpen, isNftView, closeLayer])
}

