import 'featured-pages/components/header/visuals/index.scss'

const makeStyle = item => (
  {
    fontSize: item?.width,
    top: item?.top,
    left: item?.left,
  }
)

export const Visuals = ({ visuals }) => <div className={'featured-page-visuals'}>
  {visuals && visuals.map((item, index) => (
    <img key={index} srcSet={item?.img} className={`featured-page-visual featured-page-visual-${index}`}
         style={makeStyle(item)} />
  ))}
</div>
