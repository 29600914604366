import { CreateNft } from 'modals/collection/asset'
import { OwnerWrapper } from 'modals/collection/owner-wrapper'
import { Behaviour } from 'modals/collection/behaviour'
import { SetRoles } from './set-roles'
import { useFetchCollection } from 'collections/hooks/use-fetch-collection'

export * from 'modals/collection/reducers'
export * from 'modals/collection/epics'

export { CreateNft } from 'modals/collection/asset'

export const CollectionModals = () => {
  useFetchCollection()

  return <OwnerWrapper>
    <Behaviour />
    <CreateNft />
    <SetRoles />
  </OwnerWrapper>
}
