import { Empty as EmptyState } from 'components/empty'
import { faCubes } from '@fortawesome/pro-light-svg-icons'
import { useActualList } from 'collections/auctions/hooks/use-actual-list'
import { useCollectionAuctionsLoading } from 'collections/auctions/hooks/use-collection-auctions-loading'

const makeProps = () => ({
  icon: faCubes,
  message: 'No auctions found.',
  fullscreen: true,
})

export const Empty = () => {
  const auctionsCount = useActualList().length
  const isLoading = useCollectionAuctionsLoading()

  return !auctionsCount && !isLoading && <EmptyState {...makeProps()} />
}
