import { Details } from 'nft-page/auction/details'
import { useOnClick } from 'auction/actions/withdraw/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { actionTypes } from 'action-statuses/actions-types'
import { WatchableAction } from '../watchable-action'

export const Withdraw = ({ auction, address, assetType }) => {
  const withdrawClick = useOnClick(auction)
  const action = usePermittedAction(withdrawClick, address)

  return <div className={'nft-page-auction-inner'}>
    <Details auction={auction} assetType={assetType} />
    <WatchableAction entity={auction} actionType={actionTypes.withdraw} text={'Withdraw'} onClick={action} />
  </div>
}

Withdraw.displayName = 'Withdraw'
