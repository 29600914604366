import { Empty as EmptyState } from 'components/empty'
import { faLightbulbSlash } from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'

const useProps = fullscreen => ({
  icon: faLightbulbSlash,
  message: 'No suggestions yet. Try again later.',
  fullscreen,
})

export const Empty = ({ fullscreen }) => <div className={'d-flex justify-content-center align-items-center'}>
  <EmptyState {...useProps(fullscreen)} />
</div>

Empty.defaultProps = { fullscreen: true }

Empty.propTypes = { fullscreen: PropTypes.bool }
