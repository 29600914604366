import { auth } from 'network/rest/auth'
import { mergeMap } from 'rxjs/operators'
import { internal } from './internal'
import { from } from 'rxjs'
import axios from 'axios'

const authorizedPut = (url, options) => auth.makePayload(options)
  .pipe(mergeMap(payload => from(axios.put(url, payload.body, { headers: payload.headers }))))

const nonAuthorizedPut = (url, options) => internal.makePayload(options)
  .pipe(mergeMap(payload => from(axios.put(url, payload.body, { headers: payload.headers }))))

export const put = () => (url, options = {}, authorize = false) => {
  if (authorize) return authorizedPut(url, options)

  return nonAuthorizedPut(url, options)
}
