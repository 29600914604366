import { useFetchPrice } from 'auction/price/hooks/use-fetch-price'
import { useEffect } from 'react'

export const useFetchAuctionPrice = () => {
  const fetchPrice = useFetchPrice()

  useEffect(() => {
    fetchPrice()
  }, [fetchPrice])
}
