import { isHeroTag } from 'viewer/hooks/use-viewer-fetching'

import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { usePrivacyStatus } from 'viewer/hooks/use-viewer-privacy-status'
import { useViewerUsername } from 'viewer/hooks/use-viewer-username'
import { useParams } from 'react-router-dom'

const useAddressFound = () => {
  const { address } = useParams()
  const addressIsHerotag = isHeroTag(address)
  const addressFound = useViewerAddress()

  return addressFound && !addressIsHerotag
}

export const useUsernameFound = () => {
  const usernameFound = useViewerUsername()
  const isPrivate = usePrivacyStatus()

  return usernameFound && !isPrivate
}

export const useFound = () => {
  const usernameFound = useUsernameFound()
  const addressFound = useAddressFound()

  return usernameFound || addressFound
}
