import { gql } from 'graphql-tag'

export const ASSET_COUNT = gql`
    query assets($filters: AssetsFilter) {
        assets(filters: $filters) {
            pageData {
                count
            }
        }
    }
`
