import { useHasMore, useList, useNext } from 'subdomain/modals/account/my-nfts/hooks'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useIsLoading } from 'subdomain/modals/account/my-nfts/hooks/use-is-loading'
import { Loader } from 'subdomain/modals/account/my-nfts/loader'
import { MyAssetCard } from 'subdomain/components/my-asset-card'

export const List = () => {
  const list = useList()
  const hasMore = useHasMore()
  const next = useNext()
  const isLoading = useIsLoading()

  return <InfiniteScroll next={next} className={'my-nfts-modal-list'} hasMore={hasMore} isLoading={isLoading}>
    {list.map(({ node }) => <MyAssetCard key={node.identifier}
                                         auction={node.lowestAuction} asset={node} />)}
    <Loader />
  </InfiniteScroll>
}
