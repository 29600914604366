import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  input: '',
  data: '',
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, input: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: payload })

const reset = () => initialState

export const get = createSlice({
  name: 'feed/latestTimestamp/get',
  initialState,
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

