import { Balance } from 'sidebar/my-wallet/balance'
import { Avatar } from 'sidebar/my-wallet/avatar'
import { Menu } from 'sidebar/my-wallet/menu'
import { Username } from 'components/username'
import { useCurrentProfileHerotag } from 'current-profile/hooks/use-current-profile-herotag'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useCurrentProfilePrivacy } from 'current-profile/hooks/use-current-profile-privacy'

export const Content = () => {
  const { address } = useGetAccountInfo()
  const herotag = useCurrentProfileHerotag()
  const privacy = useCurrentProfilePrivacy()
  const isPrivate = privacy === 'private'

  return <div className={'sidebar-mywallet'}>
    <Avatar address={address} herotag={isPrivate ? '' : herotag} />
    <Username herotag={isPrivate ? '' : herotag} address={address} showExplorerLink />
    <Balance />
    <Menu />
  </div>
}
