import { gql } from 'graphql-tag'

export const COUNT = gql`
    query ($filters: FiltersExpression) {
        orders(filters: $filters) {
            pageData {
                count
            }
        }
    }

`
