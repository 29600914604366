import { useGetInitialPage, useRedirectOnNotActiveUser } from 'modals/account/followees/hooks'
import { useResetState } from 'modals/account/followees/hooks/use-reset-state'

export const Behaviour = () => {
  useResetState()
  useRedirectOnNotActiveUser()
  useGetInitialPage()

  return ''
}
