import 'components/collection-stats/item/index.scss'
import { humanize } from 'components/conversion/humanize'
import { denominateAmount } from 'common/conversion/denominate-amount'

const featuredClass = featured => featured ? 'featured' : ''

const classname = 'collection-stats-item col-6 col-sm-4 col-lg-3 col-xl-auto'

const makeClassName = featured => `${classname} ${featuredClass(featured)}`

const suffix = (isPrice, symbol) => isPrice ? symbol : ''

export const Item = ({ label, value, isPrice, featured, symbol, isLoading, decimals }) => {
  const itemValue = isPrice ? denominateAmount(value, decimals, true) : humanize(value.toString())

  return (
    <div className={makeClassName(featured)}>
      <span className={'collection-stats-item-value'}>
        <span className={'collection-stats-item-value-value'}>{!isLoading ? itemValue : '...'}</span>
        {' '}
        {suffix(isPrice, symbol)}
      </span>
      <span className={'collection-stats-item-label'}>{label}</span>
    </div>
  )
}
