import { CancelButton } from 'components/forms/nerds-form/fields/cancel-button'
import { SubmitButton } from 'components/forms/nerds-form/fields/submit-button'

const className = 'd-flex justify-content-around default-actions flex-column flex-sm-row'

export const DefaultActions = ({ callbackUrl, label }) => <div className={className}>
  <CancelButton callbackUrl={callbackUrl} />
  <div className={'me-2 my-2'} />
  <SubmitButton label={label} />
</div>
