export const FooterLinks = ({ links }) => <div className={'subdomain-footer-links container'}>
  <div className={'d-flex justify-content-center'}>
    {links.map((link, index) => <div key={index}>
      <a href={link.address} target={'_blank'} rel={'noreferrer noopener'}>
        {link.text}
      </a>
      {index < links.length - 1 ? <span>|</span> : '' }
    </div>)}
  </div>
</div>
