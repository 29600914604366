import { gql } from 'graphql-tag'

export const GET = gql`
    query offers($filters: OffersFilters, $pagination: ConnectionArgs) {
        offers(filters: $filters, pagination: $pagination) {
            edges {
                cursor
                node {
                    asset{
                        lowestAuction{
                            collection
                            endDate
                            id
                            identifier
                            marketplaceKey
                            maxBid {
                                amount
                                token
                                usdAmount
                                tokenData{
                                    decimals
                                    symbol
                                }
                            }
                            minBid {
                                amount
                                token
                                usdAmount
                                tokenData{
                                    decimals
                                    symbol
                                }
                            }
                            startDate
                            status
                            topBid {
                                amount
                                token
                                usdAmount
                                tokenData{
                                    decimals
                                    symbol
                                }
                            }
                            topBidder{
                                address
                            }
                            type
                        }
                        artist{
                            address
                            herotag
                            profile
                        }
                        identifier
                        media {
                            fileType
                            thumbnailUrl
                            url
                        }
                        name
                        ownerAddress
                        owner{
                            address
                            herotag
                            profile
                        }
                        type
                        isNsfw
                        scamInfo{
                            info
                            type
                        }
                        verified
                    }
                    creationDate
                    endDate
                    id
                    identifier
                    marketplaceKey
                    marketplace{
                        key
                        name
                        url
                    }
                    owner{
                        cover
                        herotag
                        profile
                    }
                    ownerAddress
                    price{
                        amount
                        nonce
                        timestamp
                        token
                        tokenData{
                            decimals
                            identifier
                            symbol
                        }
                        usdAmount
                    }
                    quantity
                    status
                }
            }
            pageData{
                count
            }
            pageInfo{
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
