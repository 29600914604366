/* eslint-disable complexity */
import { useUpdateErrors as useDescriptionErrors } from 'modals/account/edit/description/hooks/use-update-errors'
import { useUpdateErrors as useLinksErrors } from 'modals/account/edit/social-links/hooks/use-update-errors'
import { useUpdateErrors as usePrivacyErrors } from 'modals/account/edit/privacy/hooks/use-update-errors'
import { isArray } from 'lodash'

export const useErrors = () => {
  const descriptionErrors = useDescriptionErrors()
  const linksErrors = useLinksErrors()
  const privacyErrors = usePrivacyErrors()

  const descriptionArr = isArray(descriptionErrors) ? descriptionErrors : []
  const linksArr = isArray(linksErrors) ? linksErrors : []
  const privacyArr = isArray(privacyErrors) ? privacyErrors : []

  return [...descriptionArr, ...linksArr, ...privacyArr]
}
