import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'feed/slices'

export const useGetInitialPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(get.actions.attempt({ }))
  }, [dispatch])
}
