import { FooterEnabled } from './footer-enabled'

import './footer.scss'
import { useIsFooterVisible } from 'components/footer/hooks/use-is-footer-visible'
import { useRouteAllowsFooter } from 'components/footer/hooks/use-route-allows-footer'

export const Footer = () => {
  const routeAllowsFooter = useRouteAllowsFooter()
  const isFooterVisible = useIsFooterVisible()

  return routeAllowsFooter && isFooterVisible ? <FooterEnabled /> : ''
}
