import { gql } from 'graphql-tag'

export const FEATURED_COLLECTIONS = gql`
    query featuredCollections($filters: FeaturedCollectionsFilter, $pagination: ConnectionArgs,) {
        featuredCollections(filters: $filters, pagination: $pagination) {
            edges {
                cursor
                node {
                    name
                    collection
                    pngUrl
                    svgUrl
                    description
                    assets{
                       edges {
                           cursor
                           node{
                               lowestAuction{
                                   collection
                                   endDate
                                   id
                                   identifier
                                   marketplaceKey
                                   marketplace {
                                       iconUrl
                                   }
                                   maxBid {
                                       amount
                                       token
                                       usdAmount
                                       tokenData{
                                           decimals
                                           symbol
                                       }
                                   }
                                   minBid {
                                       amount
                                       token
                                       usdAmount
                                       tokenData{
                                           decimals
                                           symbol
                                       }
                                   }
                                   startDate
                                   status
                                   topBid {
                                       amount
                                       token
                                       usdAmount
                                       tokenData{
                                           decimals
                                           symbol
                                       }
                                   }
                                   topBidder{
                                       address
                                   }
                                   type
                               }
                               artist{
                                   address
                                   herotag
                                   profile
                               }
                               identifier
                               media {
                                   fileType
                                   thumbnailUrl
                                   url
                               }
                               name
                               ownerAddress
                               owner{
                                   address
                                   herotag
                                   profile
                               }
                               type
                               isNsfw
                               scamInfo{
                                   info
                                   type
                               }
                               verified
                           }
                       }
                        pageData{
                            count
                        }
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                    }
                }
            }
            pageData {
                count
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
