import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { campaigns as repository } from 'repositories/campaigns'
import { actions } from 'holoride-campaign/modals/buy/slices/buy-tickets'
import { extractGraphqlErrors } from 'repositories/extract-graphql-errors'
import { throwOnInsufficientFunds } from 'modals/throw-on-insufficient-funds'
import { throwOnTokenInsufficientFunds } from 'modals/throw-on-token-insufficient-funds'

export const buyTickets = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(({ payload }) => execute(payload.payload, payload.paymentToken)))

const execute = (payload, paymentToken) => repository.buyTickets(payload)
  .pipe(map(({ data }) => data))
  .pipe(mergeMap(data => throwOnInsufficientFunds({ data: data.buyTickets })))
  .pipe(mergeMap(data => throwOnTokenInsufficientFunds({ data, payload, paymentToken })))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
])

const failure = result => of(actions.failure(extractGraphqlErrors(result)))
