import PropTypes from 'prop-types'

export const FlexColumn = ({ className, children }) => <div className={`${className} d-flex flex-column`}>
  {children}
</div>

FlexColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

FlexColumn.defaultProps = { className: '', children: '' }
