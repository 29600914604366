import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions } from 'modals/account/edit/social-links/slices/update-social-links'

const formatUrl = url => {
  if (url.startsWith('http')) {
    return url
  }

  return `https://${url}`
}

export const useUpdateSocialLinks = () => {
  const dispatch = useDispatch()

  return useCallback(({ urls }) => {
    urls.forEach((element, index) => {
      dispatch(actions.attempt({ url: element && formatUrl(element), type: parseInt(index) }))
    })
  }, [dispatch])
}
