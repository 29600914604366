import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  attempt: '',
  data: '',
  errors: [],
  loading: false,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, attempt: payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

const reset = () => initialState

export const accept = createSlice({
  initialState,
  name: 'offers/accept',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})
