import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { usePreviousElement } from 'nft-page/controls/hooks/use-previous-element'
import { useLayeredPath } from 'nft-page/controls/hooks/use-layered-path'

export const useOnLeftClick = () => {
  const navigate = useNavigate()
  const previousElement = usePreviousElement()
  const layerPath = useLayeredPath()

  return useCallback(() => {
    const { pathname, state } = layerPath(previousElement)

    if (previousElement) {
      navigate(pathname, { state, replace: true })
    }
  }, [layerPath, previousElement, navigate])
}
