import { get } from 'lodash'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useFavicon = () => {
  const data = useCmsData()

  const url = get(data, 'data.data.attributes.favicon.data.attributes.url')

  const faviconElements = document.querySelectorAll('link[rel=icon]')

  if (url) {
    faviconElements.forEach((element, index) => {
      if (index === 0) {
        element.setAttribute('href', url)
      } else {
        element.remove()
      }
    })
  }
}
