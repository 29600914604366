import { useIsPrinceCharming } from 'auth/mega-authorized'
import { ConnectLink } from 'subdomain/components/top-navigation/connect/connect-link'
import { DisconnectLink } from 'subdomain/components/top-navigation/connect/disconnect-link'

export const Connect = () => {
  const isPrinceCharming = useIsPrinceCharming()

  return <>
    {isPrinceCharming && <DisconnectLink />}
    {!isPrinceCharming && <ConnectLink />}
  </>
}
