import { useEffect, useState } from 'react'
import { internalAccessApiKey } from 'repositories/extras'

export const useInternalAccessToken = () => {
  const [token, setToken] = useState(window.localStorage.getItem(internalAccessApiKey))

  useEffect(() => {
    const storageChanged = ({ key, newValue }) => {
      if (key === internalAccessApiKey) setToken(newValue)
    }

    window.addEventListener('storage', storageChanged)

    return () => window.removeEventListener('storage', storageChanged)
  }, [])

  return token ?? window.localStorage.getItem(internalAccessApiKey)
}
