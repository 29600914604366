import { useCallback } from 'react'
import { get } from 'lodash'
import { BN } from 'bn.js'
import { actions } from '../slices/buy-campaign'
import { useDispatch } from 'react-redux'

export const useBuyCampaign = (minterAddress, campaignId, tier) => {
  const dispatch = useDispatch()

  return useCallback(({ quantity }) => {
    const unitPrice = get(tier, 'mintPrice.amount')
    const price = new BN(unitPrice).mul(new BN(String(quantity)))
      .toString()
    const payload = {
      campaignId,
      minterAddress,
      tier: tier.tierName,
      price,
      quantity: quantity.toString(),
    }
    dispatch(actions.attempt(payload))
  }, [dispatch, minterAddress, campaignId, tier])
}
