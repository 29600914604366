import { useResetClaimable } from 'account/tabs/assets/claimable/behaviour/hooks/use-reset-claimable'
import { useResetList } from 'account/tabs/assets/claimable/behaviour/hooks/use-reset-list'
import { useEffect } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useResetState = () => {
  const resetClaimable = useResetClaimable()
  const resetList = useResetList()
  const address = useViewerAddress()

  useEffect(() => {
    resetList()
    resetClaimable()
  }, [resetClaimable, resetList, address])
}
