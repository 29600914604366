/* eslint-disable max-lines-per-function */
import { Header } from 'featured-pages/components/header'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { FeaturedBoxes } from 'featured-pages/components/featured-boxes'
import { boxes } from 'featured-pages/master/boxes'
import { FeaturedTwitter } from 'components/featured-twitter'
import visual0 from 'startup/assets/featured-page-visual-4-1.png'
import visual1 from 'startup/assets/featured-page-visual-4-2.png'
import visual2 from 'startup/assets/featured-page-visual-4-3.png'

const visuals = [
  { img: visual0, width: '410px', top: '55%', left: '5%' },
  { img: visual1, width: '720px', top: '15%', left: '90%' },
  { img: visual2, width: '200px', top: '90%', left: '30%' },
]

export const Master = () => <>
  <ScrollToTopOnMount />
  <Header label={'Are you a creator?'} visuals={visuals}>
    <h1>Be a Beacon of Light</h1>
    <p>
      You can now submit your project to be featured on xSpotlight.com! Before you do,
      however, please take a close look at the selection criteria below. We aim to identify
      and recognize the most impactful, well-executed, and innovative projects from the
      MultiversX ecosystem.
    </p>
  </Header>
  <FeaturedBoxes boxes={boxes} />
  <FeaturedTwitter />
</>
