import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'nft-page/auction/bids/slices/orders'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { get } from 'lodash'
import { useMakePayload } from 'nft-page/auction/bids/hooks/use-make-payload'

export const useExecute = () => {
  const dispatch = useDispatch()
  const auction = useNftDefaultAuction()
  const id = get(auction, 'id', '')
  const payload = useMakePayload()
  const isSftOnePerPayment = get(auction, 'type', '') === 'SftOnePerPayment'

  return useCallback(() => {
    id && !isSftOnePerPayment && dispatch(actions.attempt(payload()))
  }, [dispatch, id, isSftOnePerPayment, payload])
}

export const useFetchAssetOrders = () => {
  const execute = useExecute()
  useEffect(() => execute(), [execute])
}
