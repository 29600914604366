import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  data: undefined,
  errors: undefined,
  loading: false,
  attempt: undefined,
}

const attempt = (state, { payload }) => ({ ...state, loading: true, attempt: payload })

const success = (state, { payload }) => ({ ...state, data: payload, loading: false })

const failure = (state, { payload }) => ({ ...state, errors: serializeErrors(payload), loading: false })

const cleanUp = () => initialState

export const collections = createSlice({
  initialState,
  name: 'account/creations/collectionsFilters',
  reducers: {
    attempt,
    success,
    failure,
    cleanUp,
  },
})

export const { actions } = collections
