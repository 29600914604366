import { PercentField } from 'components/forms/nerds-form/fields/percent-field'
import { InputField } from 'components/forms/nerds-form/fields/input-field'
import { useFormikContext } from 'formik'

export const Details = () => {
  const { values } = useFormikContext()

  return <>
    {values.collection.type === 'SemiFungibleESDT' && <InputField label={'How many?'} name={'quantity'} />}
    <PercentField label={'Royalties'} name={'royalties'} />
  </>
}
