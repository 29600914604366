import { useContext } from 'account/profile/follow/mutate-follow/context'
import { useOnClick } from 'account/profile/follow/mutate-follow/hooks'
import { FollowButton } from 'components/follow-button'
import { UnfollowButton } from 'components/unfollow-button'

export const MutateFollow = () => {
  const { isFollowed } = useContext()
  const onClick = useOnClick()

  return isFollowed ? <UnfollowButton onClick={onClick} /> : <FollowButton onClick={onClick} />
}
