import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { settings } from 'settings/fetching'
import { useEffect } from 'react'

const getSentryDsn = () => settings().sentryDsn

export const useInitializeSentry = () => {
  useEffect(() => {
    Sentry.init({
      dsn: getSentryDsn(),
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_NODE_ENV,
      tracesSampleRate: 10,
    })
  }, [])
}
