import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from '../../common/serialize-errors'

const initialState = {
  data: {},
  loading: false,
  errors: [],
}

const set = (state, { payload }) => ({ ...state, loading: true, data: { ...state.data, ...payload } })

const save = (state, { payload }) => ({ ...state, loading: true, data: { ...state.data, ...payload } })

const load = state => ({ ...state, loading: true })

const saved = state => ({ ...state, loading: false })

const loaded = (state, { payload }) => ({ ...state, loading: false, data: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, errors: serializeErrors(payload) })

export const preferences = createSlice({
  initialState,
  name: 'preferences-set',
  reducers: {
    load,
    set,
    save,
    saved,
    loaded,
    failure,
  },
})

export const { actions } = preferences
