import { gql } from 'graphql-tag'

export const ACCOUNT_COLLECTIONS_FILTERS = gql`
    query accountCollectionsFilters($filters: CollectionsFilter, $pagination: ConnectionArgs, $sorting: CollectionsSortingEnum) {
        collections(filters: $filters, pagination: $pagination, sorting: $sorting) {
            edges {
                node {
                    collection
                    name
                    nftsCount
                }
            }
            pageData {
                count
                offset
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
