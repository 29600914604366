import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'campaign/slices/campaign'

export const useResetState = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.reset({}))
  }, [dispatch])
}
