import { get } from 'lodash'
import { settings } from 'settings/fetching'

const decimals = () => settings().dapp.decimals

const maxMaxBid = () => settings().maxBidLimit

const maxMinBid = () => settings().minBidLimit

const testDecimals = value => get(value?.split('.'), '[1].length', 0) <= decimals()

const testComma = value => !value?.includes(',')

const testStartDot = value => !value?.startsWith('.')

const testEndDot = value => !value?.endsWith('.')

const testValue = value => value > 0

const testMaxMaxBidValue = value => parseFloat(value) <= maxMaxBid()

const testMaxMinBidValue = value => parseFloat(value) <= maxMinBid()

const testLeadingZeros = (value, context) => {
  const leadingZerosCondition = !context.originalValue.startsWith('0') || context.originalValue.startsWith('0.')

  return context.originalValue && leadingZerosCondition
}

export const testNumber = value => value?.match(/^\d*\.?\d*$/)

export const useTests = () => ({
  testValue,
  testDecimals,
  testComma,
  testStartDot,
  testEndDot,
  testMaxMaxBidValue,
  testMaxMinBidValue,
  testLeadingZeros,
  testNumber,
})
