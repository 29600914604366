import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useCurrentTag } from 'trending/tags-filter/hooks/use-current-tag'

const wrapperProps = disabled => ({
  className: 'dropdown-toggle tags-filter-button',
  id: 'trendingTabDropdownMenu',
  'data-bs-toggle': 'dropdown',
  'aria-expanded': 'false',
  disabled,
})

export const CurrentTag = ({ disabled }) => <div {...wrapperProps(disabled)}>
  {useCurrentTag()}
  <FontAwesomeIcon className={'tags-filter-button-arrow'} icon={faChevronDown} />
</div>
