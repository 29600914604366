import { useRootProps } from 'modals/account/dropzone/hooks/use-root-props'
import { Column } from 'components/column'
import { ButtonOk } from 'modals/account/dropzone/button-ok'
import { ButtonReset } from 'modals/account/dropzone/button-reset'
import { CloseButton } from 'modals/account/dropzone/close-button'
import { useContext } from 'modals/account/dropzone/context'
import match from '@nerds.sh/js-pattern'
import { useCallback } from 'react'
import { DefaultContent } from 'modals/account/dropzone/default-content'
import { ImageCropper } from 'modals/account/dropzone/image-cropper'
import { useInputProps } from 'modals/account/dropzone/hooks/use-input-props'

const useContentClassName = () => {
  const defaultClassname = 'd-flex align-items-center justify-content-center flex-column'
  const { isDragActive, error, image } = useContext()

  const getClassname = useCallback(() => match({})
    .with(isDragActive, () => 'dropzone-content active')
    .with(error, () => 'dropzone-content-error')
    .with(image, () => 'dropzone-content crop-image')
    .otherwise(() => 'dropzone-content'), [image, error, isDragActive])

  return `${getClassname()} ${defaultClassname}`
}

export const Content = () => <Column className={'dropzone-outer h-100 col-12'}>
  <div className={'modal-content rounded'}>
    <CloseButton />
    <div {...useRootProps()}>
      <div className={useContentClassName()}>
        <input {...useInputProps()} />
        <DefaultContent />
        <ImageCropper />
      </div>
    </div>

    <ButtonOk />
    <ButtonReset />
  </div>
</Column>
