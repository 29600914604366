import { useMultiRangeProps } from 'filters/components/price-range/hooks/use-multi-range-props'
import { MultiRangeLogarithmic } from 'filters/components/multi-range-logarithmic'
import { useIsEqual } from 'filters/components/price-range/hooks/use-is-equal'

export const PriceRange = ({ minBid, maxBid }) => {
  const props = useMultiRangeProps(minBid, maxBid)
  const isMinBidEqualMaxBid = useIsEqual(minBid, maxBid)

  return !isMinBidEqualMaxBid && <div className={`price-range d-flex`}>
    <MultiRangeLogarithmic {...props()} />
  </div>
}
