import { useParams } from 'react-router-dom'
import { useWatchAction } from 'action-statuses/hooks/use-watch-action'
import { useOnChange } from 'account/tabs/assets/auctions/behaviour/hooks/use-on-change'

export const useTransactionWatch = () => {
  const { identifier, auctionId } = useParams()
  const onChange = useOnChange()

  useWatchAction({ prefix: `${identifier}_Asset_${identifier}`, onChange })
  useWatchAction({ prefix: `${identifier}_Auction_${auctionId}`, onChange })
}
