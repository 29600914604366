import { usePlaceBid } from 'modals/auction/bid/hooks/use-place-bid'
import { usePlaceBidSelector } from 'modals/auction/bid/hooks/use-place-bid-selector'
import { BuyNowForm } from 'components/forms/buy-now-form'
import { actions } from 'modals/auction/bid/slices/place-bid'
import { get } from 'lodash'
import { useDependenciesLoading } from 'modals/auction/hooks/use-dependecies-loading'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { denominateAmount } from 'common/conversion/denominate-amount'

const makeInitialValues = auction => {
  const amount = get(auction, 'maxBid.amount', '0')
  const tokenDecimals = get(auction, 'maxBid.tokenData.decimals', 18)

  return ({
    price: denominateAmount(amount, tokenDecimals) * 1,
    identifier: get(auction, 'identifier', ''),
    auctionId: parseInt(get(auction, 'id', '0')),
  })
}

export const BuyNow = ({ auction }) => {
  const paymentToken = useFirstAcceptedPaymentToken()
  const placeBid = usePlaceBid(auction, paymentToken)
  const { loading, errors, data } = usePlaceBidSelector()
  const callbackUrl = useCloseLayerLocation()
  const dependenciesLoading = useDependenciesLoading()

  const actionIdentifier = createActionIdentifier(auction, actionTypes.bid)
  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <BuyNowForm onSubmit={placeBid} submitErrors={errors} loading={loading || dependenciesLoading} key={auction.id}
                     callbackUrl={callbackUrl} initialValues={makeInitialValues(auction)} auction={auction}
                     paymentToken={paymentToken} />
}
