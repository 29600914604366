import { denominateAmount } from 'common/conversion/denominate-amount'
import millify from 'millify'

export const getMillifiedAmount = (amount, decimals) => {
  const denominatedAmount = denominateAmount(amount, decimals)
  const parsed = parseFloat(denominatedAmount)
  const millifiedAmount = millify(parsed, { precision: 2, lowercase: true })

  return parsed >= 10000 ? millifiedAmount : denominateAmount(amount, decimals, true)
}
