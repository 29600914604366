import { Loading } from 'loading'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'explore-nfts/tabs/nfts/assets/slices/get'
import { settings } from 'settings/fetching'
import { useFetchPayload } from 'explore-nfts/tabs/nfts/assets/hooks/use-fetch-payload'

const useIssueServer = customCursor => {
  const dispatch = useDispatch()
  const payload = useFetchPayload(customCursor)

  return useCallback(() => {
    dispatch(actions.attempt(payload()))
  }, [dispatch, payload])
}

export const useNext = customCursor => {
  const hideGhosts = Loading.useHideGhosts()
  const showGhosts = Loading.useShowGhosts()
  const issueServer = useIssueServer(customCursor)

  return useCallback(() => {
    hideGhosts(Loading.ID.explore.page)
    showGhosts(Loading.ID.explore.page, settings().listRequestSize)
    issueServer()
  }, [hideGhosts, issueServer, showGhosts])
}
