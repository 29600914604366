/* eslint-disable */
import { actions as exploreNftsAssets } from 'explore-nfts/tabs/nfts/assets/slices/get'
import { actions as exploreNftsAuctions } from 'explore-nfts/tabs/nfts/auctions/slices/get'
import { actions as trending } from 'trending/slices/cursor'
import { actions as collectionAssetsActions } from 'collections/assets/slices/get'
import { actions as collectionAuctionsActions } from 'collections/auctions/slices/get'
import { actions as accountCreations } from 'account/tabs/assets/creations/slices/get'
import { actions as accountCollected } from 'account/tabs/assets/collected/slices/get'
import { actions as accountAuctions } from 'account/tabs/assets/auctions/slices/get'
import { actions as accountBids } from 'account/tabs/assets/bids/slices/get'
import { actions as accountClaimable } from 'account/tabs/assets/claimable/slices/get'
import { actions as accountLiked } from 'account/tabs/assets/liked/slices/get'
import { useExploreNftsEdges } from 'home/explore-nfts/nfts/hooks/use-explore-nfts-edges'
import { useAuctions as useHomeTrending } from 'home/trending/hooks/use-auctions'
import { useActualList as useTrendingAuctions } from 'trending/hooks/use-actual-list'
import { useActualList as useExploreNftsAssetsList } from 'explore-nfts/tabs/nfts/assets/hooks/use-actual-list'
import { useActualList as useExploreNftsAuctionsList } from 'explore-nfts/tabs/nfts/auctions/hooks/use-actual-list'
import { useCollectionAssetsList } from 'collections/assets/hooks/use-collection-assets-list'
import { useCollectionAuctionsList } from 'collections/auctions/hooks/use-collection-auctions-list'
import { useActualItems as useAccountCreations } from 'account/tabs/assets/creations/hooks/use-actual-items'
import { useActualItems as useAccountCollected } from 'account/tabs/assets/collected/hooks/use-actual-items'
import { useActualItems as useAccountAuctions } from 'account/tabs/assets/auctions/hooks/use-actual-items'
import { useActualItems as useAccountBids } from 'account/tabs/assets/bids/hooks/use-actual-items'
import { useActualItems as useAccountClaimable } from 'account/tabs/assets/claimable/hooks/use-actual-items'
import { useActualItems as useAccountLiked } from 'account/tabs/assets/liked/hooks/use-actual-items'
import { useHasMore as accountCreationsHasMore } from 'account/tabs/assets/creations/hooks/use-has-more'
import { useHasMore as accountCollectedHasMore } from 'account/tabs/assets/collected/hooks/use-has-more'
import { useHasMore as accountAuctionsHasMore } from 'account/tabs/assets/auctions/hooks/use-has-more'
import { useHasMore as accountBidsHasMore } from 'account/tabs/assets/bids/hooks/use-has-more'
import { useHasMore as accountClaimableHasMore } from 'account/tabs/assets/claimable/hooks/use-has-more'
import { useHasMore as accountLikedHasMore } from 'account/tabs/assets/liked/hooks/use-has-more'
import { useHasMore as exploreNftsAssetsHasMore } from 'explore-nfts/tabs/nfts/assets/hooks/use-has-more'
import { useHasMore as exploreNftsAuctionsHasMore } from 'explore-nfts/tabs/nfts/auctions/hooks/use-has-more'
import { useHasMore as trendingHasMore } from 'trending/hooks/use-has-more'
import { useHasMore as collectionAssetsHasMore } from 'collections/assets/hooks/use-has-more'
import { useHasMore as collectionAuctionsHasMore } from 'collections/auctions/hooks/use-has-more'
import { useFetchPayload as useFetchCreationsPayload } from 'account/tabs/assets/creations/hooks/use-fetch-payload'
import { useFetchPayloadPreview as useFetchAssetsPayload } from 'collections/assets/hooks/use-fetch-payload-preview'
import { useFetchPayloadPreview as useFetchAuctionsPayload } from 'collections/auctions/hooks/use-fetch-payload-preview'
import { useFetchPayload as useFetchCollectedPayload} from 'account/tabs/assets/collected/hooks/use-fetch-payload'
import { useFetchPayloadPreview as useFetchExploreNftsAssetsPayload } from 'explore-nfts/tabs/nfts/assets/hooks/use-fetch-payload-preview'
import { useFetchPayloadPreview as useFetchExploreNftsAuctionsPayload } from 'explore-nfts/tabs/nfts/auctions/hooks/use-fetch-payload-preview'
import { useFeaturedCollectionAssets } from 'home/featured-collections/hooks/use-featured-collection-assets'

export const usePreviewSources = () => [
  {
    name: 'home-trending',
    hasMore: false,
    selector: useHomeTrending(),
  },
  {
    name: 'home-explore-nfts',
    hasMore: false,
    selector: useExploreNftsEdges(),
  },
  {
    name: 'collection-assets',
    hasMore: collectionAssetsHasMore(),
    action: collectionAssetsActions.attempt,
    selector: useCollectionAssetsList(),
    payload: useFetchAssetsPayload(),
  },
  {
    name: 'collection-auctions',
    hasMore: collectionAuctionsHasMore(),
    action: collectionAuctionsActions.attempt,
    selector: useCollectionAuctionsList(),
    payload: useFetchAuctionsPayload(),
  },
  {
    name: 'trending',
    hasMore: trendingHasMore(),
    action: trending.set,
    selector: useTrendingAuctions(),
    payload: ({ cursor }) => cursor,
  },
  {
    name: 'explore-nfts-assets',
    hasMore: exploreNftsAssetsHasMore(),
    action: exploreNftsAssets.attempt,
    selector: useExploreNftsAssetsList(),
    payload: useFetchExploreNftsAssetsPayload(),
  },
  {
    name: 'explore',
    hasMore: false,
    selector: [],
  },
  {
    name: 'explore-nfts-auctions',
    hasMore: exploreNftsAuctionsHasMore(),
    action: exploreNftsAuctions.attempt,
    selector: useExploreNftsAuctionsList(),
    payload: useFetchExploreNftsAuctionsPayload(),
  },
  {
    name: 'account-creations',
    hasMore: accountCreationsHasMore(),
    action: accountCreations.attempt,
    selector: useAccountCreations(),
    payload: useFetchCreationsPayload()
  },
  {
    name: 'account-collected',
    hasMore: accountCollectedHasMore(),
    action: accountCollected.attempt,
    selector: useAccountCollected(),
    payload: useFetchCollectedPayload()
  },
  {
    name: 'account-auctions',
    hasMore: accountAuctionsHasMore(),
    action: accountAuctions.setCursor,
    selector: useAccountAuctions(),
    payload: ({ cursor }) => cursor
  },
  {
    name: 'account-bids',
    hasMore: accountBidsHasMore(),
    action: accountBids.setCursor,
    selector: useAccountBids(),
    payload: ({ cursor }) => cursor
  },
  {
    name: 'account-claimable',
    hasMore: accountClaimableHasMore(),
    action: accountClaimable.setCursor,
    selector: useAccountClaimable(),
    payload: ({ cursor }) => cursor
  },
  {
    name: 'account-liked',
    hasMore: accountLikedHasMore(),
    action: accountLiked.setCursor,
    selector: useAccountLiked(),
    payload: ({ cursor }) => cursor
  },
  {
    name: 'home-featured-collections',
    hasMore: false,
    selector: useFeaturedCollectionAssets(),
  },
]

/* eslint-enable */
