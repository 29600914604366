import { get, indexOf } from 'lodash'

import { useCurrentPreviewSource } from 'nft-page/controls/hooks/use-current-preview-source'
import { useCurrentElement } from 'nft-page/controls/hooks/use-current-element'

export const usePreviousElement = () => {
  const currentPreview = useCurrentPreviewSource()
  const currentElement = useCurrentElement()
  const previousElementIndex = indexOf(currentPreview.selector, currentElement) - 1

  return get(currentPreview, `selector[${previousElementIndex}].node`, '')
}
