import { Dropzone } from 'modals/account/dropzone'
import { useCoverErrors } from 'modals/account/cover/hooks/use-cover-errors'
import { useOnSubmit } from 'modals/account/cover/hooks/use-on-submit'
import { useMemo } from 'react'
import { Behaviour } from 'modals/account/cover/behaviour'

const useDropzoneProps = () => {
  const errors = useCoverErrors()
  const onSubmit = useOnSubmit()

  return useMemo(() => ({
    id: 'cover-photo-dropzone',
    errors,
    onSubmit,
    maxSize: 2000000,
    dimensions: {
      width: 1440,
      height: 300,
    },
    fileTypeInfo: 'PNG, JPG or JPEG. Max 2mb.',
  }), [errors, onSubmit])
}

export const Cover = () => <>
  <Behaviour />
  <Dropzone {...useDropzoneProps()} />
</>
