import { Behaviour } from 'feed/active/behaviour'
import { Refresh, VisibilityWrapper } from 'feed/refresh'
import { Container } from 'feed/container'
import { DataCard } from 'components/data-card'
import { EmptyWrapper } from 'feed/empty-wrapper'
import { List } from 'feed/list'
import { Suggestions } from 'feed/suggestions'

const cardProps = () => ({ title: 'My Feed' })

export const Active = () => <>
  <Behaviour />
  <Container>
    <div className={'col-12 col-lg-7 col-xl-8'}>
      <DataCard {...cardProps()}>
        <VisibilityWrapper>
          <Refresh />
        </VisibilityWrapper>
        <EmptyWrapper>
          <List />
        </EmptyWrapper>
      </DataCard>
    </div>
    <div className={'col-lg-5 col-xl-4 mt-3'}>
      <Suggestions />
    </div>
  </Container>
</>
