import match from '@nerds.sh/js-pattern'
import { useDropzoneContext } from 'components/forms/nerds-form/fields/dropzone-field/context'
import { ReactComponent as Audio } from 'startup/assets/audio.svg'
import { ReactComponent as Video } from 'startup/assets/video.svg'
import { ImagePreview } from 'components/forms/nerds-form/fields/dropzone-field/components/preview/image-preview'
import { Empty } from 'components/forms/nerds-form/fields/dropzone-field/components/preview/empty'
import { get } from 'lodash'

export const usePreviewComponent = () => {
  const { field } = useDropzoneContext()

  const mimeType = get(field, 'value.type', '')

  return match(mimeType)
    .with(mimeType.includes('video'), () => Video)
    .with(mimeType.includes('audio'), () => Audio)
    .with(mimeType.includes('image'), () => ImagePreview)
    .otherwise(() => Empty)
}
