import { useFetchNotifications } from '../../notifications/account/hooks/use-fetch-notifications'
import { useCallback } from 'react'
import { useFetchCallback } from './use-fetch-callback'

export const useOnChange = () => {
  const fetch = useFetchCallback()
  const fetchNotifications = useFetchNotifications()

  return useCallback(() => {
    fetch()
    fetchNotifications()
  }, [fetch, fetchNotifications])
}
