import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sessionId: '',
  callbackUrl: '',
  previousLocation: {},
}

export const transaction = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    set: (_, { payload }) => payload,
    reset: () => initialState,
  },
})

export const { actions } = transaction
