import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/creations/slices/get'

export const useResetCreations = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.resetCursor({}))
    dispatch(actions.cleanUp())
  }, [dispatch])
}
