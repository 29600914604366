import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/bids/slices/get'

export const useResetBids = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.cleanUp({}))
  }, [dispatch])
}
