import { Views } from 'nft-page/social-stats/views'
import { Likes } from 'nft-page/social-stats/likes'
import { ShareButton } from 'components/share-button'
import 'nft-page/social-stats/index.scss'

export const SocialStats = () => <div className={'nft-page-social-stats'}>
  <ShareButton />
  <div className={'d-flex flex-grow-1 justify-content-end'}>
    <Views />
    <Likes />
  </div>
</div>
