import { useCloseModal } from 'components/bootstrap-modal/hooks/use-close-modal'
import { useCallback } from 'react'

export const useOnClick = (id, callback = () => {}) => {
  const closeModal = useCloseModal(id)

  return useCallback(() => {
    closeModal()
    callback()
  }, [closeModal, callback])
}
