import { useCampaigns } from './hooks/use-campaigns'
import { Item } from './item'

export const List = () => {
  const campaigns = useCampaigns()

  return <div className={'row'}>
    {campaigns.map((item, index) => <div className={'col-12 col-md-6 col-lg-4 card-item'} key={index}>
      <Item campaign={item} />
    </div>)}
  </div>
}
