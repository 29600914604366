import { ExplorerLink } from 'components/explorer-link'
import { useParams } from 'react-router-dom'
import { useAssetName } from 'nft-page/header/title/hooks/use-asset-name'
import { useIsVerified } from 'nft-page/hooks/nft/use-is-verified'
import { VerifiedBadge } from 'components/verified-badge'
import 'nft-page/header/title/index.scss'
import { useRef } from 'react'
import { useTooltip } from 'common/hooks/use-tooltip'

export const Title = () => {
  const assetName = useAssetName()
  const isVerified = useIsVerified()
  const { identifier } = useParams()
  const link = `/nfts/${identifier}`
  const ref = useRef(null)
  useTooltip(ref)

  return <h1 className={'nft-title text-wrap'}>
    <span className={'nft-title-text'} data-bs-toggle={'tooltip'} ref={ref} data-bs-placement={'left'}
          title={assetName}>
      {assetName}
    </span>
    {isVerified && <VerifiedBadge />}
    <ExplorerLink link={link} />
  </h1>
}
