import { Address } from '@multiversx/sdk-core/out'

export const isValidAddress = address => {
  try {
    Address.fromBech32(address)

    return true
  } catch {
    return false
  }
}
