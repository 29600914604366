import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { useWrapperProps } from 'components/social-link/hooks/use-wrapper-props'
import { useIconProps } from 'components/social-link/hooks/use-icon-props'

import './style.scss'

export const SocialLink = props => <a {...useWrapperProps(props)}>
  <FontAwesomeIcon {...useIconProps(props)} />
</a>

SocialLink.defaultProps = {
  type: { type: '', url: '' },
  wrapperClassName: '',
  iconClassName: '',
}

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.object,
  wrapperClassName: PropTypes.string,
  iconClassName: PropTypes.string,
}

export { coerceUrlType } from 'components/social-link/coerce-url-type'
