import { get } from 'lodash'
import { getPriceDetails } from 'common/get-price-details'
import { Errors, NerdsForm } from 'components/forms/nerds-form'
import { useConfig } from 'components/forms/accept-offer-form/nft/hooks/use-config'
import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Subtitle } from 'components/forms/nerds-form/subtitle'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { AuctionsField } from 'components/forms/nerds-form/fields/auctions-field'

export const Nft = ({ loading, onSubmit, submitErrors, callbackUrl, initialValues, offer, auctions }) => {
  const offerPrice = get(offer, 'price')
  const { millyfiedAmount, token } = getPriceDetails(offerPrice)
  const quantity = get(offer, 'quantity')
  const pieces = quantity === '1' ? 'piece' : 'pieces'

  return <NerdsForm loading={loading} submit_errors={submitErrors}
                    config={useConfig({ onSubmit, initialValues, auctions, initialTouched: { auctionId: true } })}>
    <div className={'modal-content'}>
      <Title icon={faGavel} title={'Accept Offer'} margins={'mb-2'} />
      <Subtitle align={'center'} subtitle={`Offer amount ${millyfiedAmount} ${token} for ${quantity} ${pieces}`} />
      {auctions.length > 0 ? <AuctionsField name={'auctionId'} auctions={auctions} /> : ''}
      <DefaultActions callbackUrl={callbackUrl} label={'Accept Offer'} />
      <Errors values={submitErrors} />
    </div>
  </NerdsForm>
}
