import { useIsOnSale } from 'collections/actions/on-sale-filter/hooks/use-is-on-sale'
import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'
import { useCollectionPaymentTokens } from 'collections/payment-tokens/hooks/use-collection-payment-tokens'
import { useIsPaymentTokensLoading } from 'collections/payment-tokens/hooks/use-is-payment-tokens-loading'

const checkDataFetched = (collection, paymentTokens, isPaymentTokensLoading) => (
  collection && (paymentTokens.length || !isPaymentTokensLoading)
)

export const useShouldRender = () => {
  const [collection] = useCollectionsSelector()
  const isOnSale = useIsOnSale()
  const paymentTokens = useCollectionPaymentTokens()
  const isPaymentTokensLoading = useIsPaymentTokensLoading()
  const isDataFetched = checkDataFetched(collection, paymentTokens, isPaymentTokensLoading)

  return isOnSale && isDataFetched
}
