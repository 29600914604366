import { gql } from 'graphql-tag'

export const SEARCH = gql`
    query search($filters: SearchFilter!) {
        search(filters: $filters) {
            accounts {
                identifier
                name
            }
            collections {
                identifier
                name
                verified
            }
            nfts {
                identifier
                name
                verified
            }
            searchTerm
            tags {
                identifier
                name
            }
        }
    }

`
