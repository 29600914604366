import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { get, includes } from 'lodash'
import { useList as useRunningAuctions } from 'nft-page/details/other-listings/hooks/use-list'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'

const useHasEnoughSftTokens = offerQuantity => {
  const runningAuctions = useRunningAuctions()
  const auctions = runningAuctions.filter(({ node }) => node.orders?.edges?.length === 0)
  const auctionedTokens = auctions.map(({ node }) => node.nrAuctionedTokens)

  return includes(auctionedTokens, offerQuantity)
}

const useHasEnoughNftTokens = offerQuantity => {
  const auction = useNftDefaultAuction()
  const nrAuctionedTokens = get(auction, 'nrAuctionedTokens')
  const orders = get(auction, 'orders.edges', [])

  return nrAuctionedTokens === parseInt(offerQuantity) && !orders.length
}

const useHasAuctionEnoughTokens = offerQuantity => {
  const hasEnoughSftTokens = useHasEnoughSftTokens(offerQuantity)
  const hasEnoughNftTokens = useHasEnoughNftTokens(offerQuantity)

  return hasEnoughSftTokens || hasEnoughNftTokens
}

export const useCanAcceptOffer = offer => {
  const { asset, assetOwner } = useAssetWithOwner()
  const assetOwnerAddress = get(asset, 'ownerAddress')
  const { address } = useGetAccountInfo()
  const isNftOwner = assetOwnerAddress === address
  const ownerBalance = parseInt(get(assetOwner, 'balance', '0'))
  const offerQuantity = parseInt(get(offer, 'quantity'))
  const hasEnoughBalance = ownerBalance >= offerQuantity
  const hasAuctionEnoughTokens = useHasAuctionEnoughTokens(offerQuantity)

  return isNftOwner || hasEnoughBalance || hasAuctionEnoughTokens
}
