import { settings } from 'settings/fetching'
import { compact } from 'lodash'
import { sorting } from 'filters/explore-filters/nfts/auctions/filters-logic/sorting'
import { filters } from 'filters/explore-filters/nfts/auctions/filters-logic/filters'
import { customFilters } from 'filters/explore-filters/nfts/auctions/filters-logic/custom-filters'
import { paymentTokenFilter } from 'filters/explore-filters/nfts/auctions/filters-logic/token'

export const buildFilters = ({ cursor, sort, filter, token, min, max }, dPriceRange) => ({
  customFilters: customFilters({ min, max, sort }, dPriceRange),
  pagination: { first: settings().listRequestSize, after: cursor },
  sorting: compact([...sorting(sort)]),
  grouping: { groupBy: 'IDENTIFIER' },
  filters: {
    operator: 'AND',
    filters: [
      ...filters({ filter }),
      ...paymentTokenFilter(token),
    ],
  },
})
