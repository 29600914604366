import { get } from 'lodash'

const getTargetDisplay = whom => {
  const target = get(whom, 'target', '')

  return target === 'cover' ? 'cover image' : 'profile picture'
}

export const ActionLabel = ({ whom }) => <>
  changed their
  {' '}
  {getTargetDisplay(whom)}
</>
