import { Behaviour } from 'modals/auction/end/behaviour'
import { Form } from 'modals/auction/end/form'
import { BootstrapModal } from 'components/bootstrap-modal'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'

export const End = () => {
  const auction = useSelectedAuction()

  return <BootstrapModal id={'end-auction'}>
    <Behaviour />
    <Form auction={auction} />
  </BootstrapModal>
}

export * from 'modals/auction/end/slices/end-auction'
export * from 'modals/auction/end/epics/end'
